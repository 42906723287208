import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme, useMediaQuery } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";

import { Loader } from "../common/loader";
import UploadDocuments from "./uploadDocuments";
import DocumentsListsTable from "./DocumentsListsTable";
import { getObjLength, reactPixelEventOnlyForCustomer } from "../helpers/utils";
import PaymentFailed from "../userProfile/Dashboard/PaymentFailed";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";
import { ROLES } from "../helpers/constants";

const Documents = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));
  const [openPopup, setOpenPopup] = useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);

  const { documentsData, loader, propertiesData, propertyListLoader } =
    useSelector((state) => state?.documents);
  const {
    loggedInEmail,
    loginAsSpouse: isSecondaryUser,
    paymnetDoneDetails,
    isPaymentPending,
  } = useSelector((state) => state?.userProfile);

  useEffect(() => {
    let URL = `property-list`;
    // let URL = `customer/properties`;
    // if (
    //   decryptPayload(localStorage.getItem("roles"))?.includes(
    //     ROLES.serviceProvider
    //   )
    // ) {
    //   URL = `service-provider/properties`;
    // }
    dispatch(
      documentsAction.getCustomerPropertiesData({
        url: URL,
        data: {},
        token: true,
      })
    );
    dispatch(documentsAction.clearData({}));
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  const closeDialogBox = () => {
    setOpenPopup(false);
  };

  const uploadDocuments = () => {
    setOpenPopup(true);
  };

  const columns = [
    {
      id: "no",
      disablePadding: true,
      label: "No.",
      minWidth: 70,
    },
    {
      id: "address",
      disablePadding: true,
      label: "Property Address",
      minWidth: 200,
    },
    {
      id: "filename",
      disablePadding: true,
      label: "Document",
      minWidth: 320,
    },
    // {
    //   id: "uploaded_at",
    //   disablePadding: true,
    //   label: "Uploaded",
    // },
    {
      id: "tags",
      disablePadding: true,
      label: "Tags",
      minWidth: 180,
    },
    {
      id: "created_at",
      disablePadding: true,
      label: "Uploaded At",
      minWidth: 120,
    },
    {
      id: "setting",
      disablePadding: true,
      label: "Action",
      minWidth: 60,
    },
  ];

  // for react pixel events
  useEffect(() => {
    if (loggedInEmail?.length > 0) {
      reactPixelEventOnlyForCustomer("Documents Page", {
        email: loggedInEmail,
      });
    }
  }, [loggedInEmail]);
  return (
    <>
      {documentsData?.data &&
      documentsData?.data?.message &&
      (documentsData?.data?.message).includes("token") ? (
        <Box className="text-center">
          <Button
            className="text-danger text-capitalize"
            // onClick={clearUserData}
          >
            {documentsData?.data?.message ? (
              <>
                {`${documentsData?.data?.message}`}
                <Link to={"/login"} className="bold text-decoration-none">
                  &nbsp;Click here to Login again.
                </Link>
              </>
            ) : null}
          </Button>
        </Box>
      ) : (
        <>
          <Box className={`${isPadding && "apex-padding"}`}>
            {/* Filtering Component Section */}
            <Paper
              elevation={0}
              className=" d-flex justify-content-between"
              sx={{ background: "none", zIndex:"13000001" }}
            >
              <Box className="" sx={{ mb: 1 }}>
                <Typography variant="h6" className="card-title fw-600">
                  Your document vault
                </Typography>
              </Box>
              {!isSecondaryUser && (
                <>
                  <Box className="documents d-none d-sm-block">
                    {/* <Button className="orderDocs">order documents</Button> */}
                    <Button className="uploadDocs" onClick={uploadDocuments}>
                      Upload new
                    </Button>
                  </Box>
                  <Box className="d-block d-sm-none">
                    <IconButton
                      className=""
                      sx={{
                        background: "#0395ff",
                        color: "#FFF",
                        p: "9px 10px !important",
                      }}
                      onClick={uploadDocuments}
                    >
                      <UploadFileIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Paper>

            {Object.keys(documentsData).length > 0 &&
            !documentsData?.success ? (
              <Typography className="text-danger text-center" variant="h6">
                {documentsData?.data?.message}, Please try again.
              </Typography>
            ) : (
              <DocumentsListsTable
                columns={columns}
                rowsData={
                  Object.keys(documentsData).length > 0
                    ? documentsData?.data
                    : []
                }
                openUploadDocPopup={setOpenPopup}
              />
            )}
          </Box>
          {/* upload Document */}
          {openPopup && (
            <UploadDocuments
              openPopup={openPopup}
              closeDialogBox={closeDialogBox}
              setOpenPopup={setOpenPopup}
              propertiesData={propertiesData}
            />
          )}
        </>
      )}
      {/* Paymnet failed Popup */}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
        )}

      <Loader open={loader || propertyListLoader} />
    </>
  );
};

export default Documents;
