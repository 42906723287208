import { put, call, takeLatest } from "redux-saga/effects";
import { postAPIPayload } from "../../../../apis/postApi";
import { countyRecordersAction } from "../../../slices/admin/county-recorder/countyRecorderSlice";

export function* fetchCountyRecordersListSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchCountyRecordersListData(response));
}
export function* fetchRecordingFeesListDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  // console.log("listed response=> ", response);
  console.log("called......3",response);
  yield put(countyRecordersAction.fetchRecordingFeesListData(response));
}
export function* fetchCountyRecordersSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchCountyRecordersData(response));
}
export function* fetchStatewiseRecordingFeesSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchStatewiseRecordingFeesData(response));
}
export function* fetchDeleteRecordingFeesSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("deleteFees  response => ", response);
  yield put(countyRecordersAction.fetchDeleteRecordingFees(response));
}

export function* fetchEditedPersonalDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchEditedPersonalDetailsData(response));
}

export function* fetchactivateInactivateDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchActivateInactivateCounty(response));
}

export function* fetchactivateInactivateFeeSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchActivateInactivateFee(response));
}

export function* fetchRegisteredCountyListDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(countyRecordersAction.fetchRegisteredCountyList(response));
}

export function* watchCountyRecordersSagaAsync() {
  yield takeLatest(
    countyRecordersAction.countyRecordersListData,
    fetchCountyRecordersListSaga
  );
  yield takeLatest(
    countyRecordersAction.recordingFeesListData,
    fetchRecordingFeesListDataSaga
  );
  yield takeLatest(
    countyRecordersAction.createCountyRecordersData,
    fetchCountyRecordersSaga
  );
  yield takeLatest(
    countyRecordersAction.createStatewiseRecordingFeesData,
    fetchStatewiseRecordingFeesSaga
  );
  yield takeLatest(
    countyRecordersAction.deleteRecordingFees,
    fetchDeleteRecordingFeesSaga
  );
  yield takeLatest(
    countyRecordersAction.editedPersonalDetailsData,
    fetchEditedPersonalDataSaga
  );
  yield takeLatest(
    countyRecordersAction.activateInactivateCounty,
    fetchactivateInactivateDataSaga
  );
  yield takeLatest(
    countyRecordersAction.activateInactivateRecordingFee,
    fetchactivateInactivateFeeSaga
  );
  yield takeLatest(
    countyRecordersAction.registeredCountyList,
    fetchRegisteredCountyListDataSaga
  );
}
