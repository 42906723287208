import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import risk_indicator from "../../assets/properties/risk_indicator.webp";
import analysis from "../../assets/properties/analysis.webp";
import verified_monitoring from "../../assets/properties/verified_monitoring.webp";
import risk_score from "../../assets/properties/risk_score.webp";
import "../PropertyRiskManagement/LeadGenDesktop.scss";

const EvaluateRisk = () => {
  return (
    <>
      <Container fluid className="property-protected-container ">
        <div>
          <h3 className="pp_title ">How We Evaluate Risk:</h3>
        </div>

        <Row className="mt-4 mb-2 text-start risk_points">
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={3}
            className="content-spacing "
          >
            <img className="mb-3" src={analysis} alt="Data Analysis" />
            <p className="protection_title">Data Analysis</p>
            <p className="protection_desc pe-0 pe-lg-5">
              We cross-reference numerous data points, including ownership
              patterns, property values, equity percentages, and property data
              changes to identify discrepancies or suspicious activity that
              could signal potential fraud.
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={3}
            className="content-spacing "
          >
            <img className="mb-3" src={risk_indicator} alt="Risk Indicators" />
            <p className="protection_title">Risk Indicators</p>
            <p className="protection_desc pe-0 pe-lg-5">
              Changes in mailing addresses, unexpected vacancies, or rental
              listings can be red flags for fraudulent activity. Our report
              pinpoints these indicators for proactive intervention.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={3} className="content-spacing">
            <img
              className="mb-3"
              src={verified_monitoring}
              alt="Verification and Monitoring"
            />
            <p className="protection_title">Verification and Monitoring</p>
            <p className="protection_desc pe-0 pe-lg-5">
              We rigorously validate contact information and use advanced dark
              web monitoring to detect compromised personal details, fraudulent
              schemes, cyber threats, and any activity targeting your property.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={3} className="content-spacing">
            <img className="mb-3" src={risk_score} alt="Risk Scoring" />
            <p className="protection_title">Risk Scoring</p>
            <p className="protection_desc pe-0 pe-lg-5">
              Our sophisticated algorithms combine these data points to generate
              a comprehensive risk score, highlighting the likelihood of equity
              theft or fraud. This allows for targeted protection measures.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EvaluateRisk;
