import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React from "react";
import { getObjLength } from "../helpers/utils";
import { Loader } from "./loader";
import PlainAlertMessage from "./PlainAlertMessage";

const DeleteModal = ({
  open,
  title,
  deleteLoader,
  handleClose,
  handleDeleteBtn,
  isDeleteSuccess,
  successMessage,
  errorMessage,
  fetchedData,
  footer = true,
  item = "item",
  callListingAPI = () => {},
}) => {

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!getObjLength(fetchedData) ? (
          <>
            <DialogContent className="bg-white">
              {!isDeleteSuccess && !errorMessage && (
                <PlainAlertMessage
                  type="Warning"
                  onClose={true}
                  onCloseFun={() => {
                    handleClose();
                  }}
                  message={`Are you sure you want to delete this ${item}? This action can not be undone.`}
                  footer={footer}
                  buttons={
                    <>
                      <Box className="mb-4 alert-modal-btn">
                        <Button
                          className="popup_Btn other_popup_btn mx-2"
                          onClick={() => handleClose()}
                        >
                          No, Close
                        </Button>
                        <Button
                          className="popup_Btn success_popup_btn mx-2"
                          onClick={handleDeleteBtn}
                        >
                          Yes, Delete
                        </Button>
                      </Box>
                    </>
                  }
                />
              )}
            </DialogContent>
            {/* <DialogActions sx={{ paddingRight: "2rem" }}>
              <Stack direction="row" spacing={2} pb={2}>
                {isDeleteSuccess ? null : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={errorMessage}
                      onClick={handleDeleteBtn}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Stack>
            </DialogActions> */}
          </>
        ) : (
          <DialogTitle id="alert-dialog-title"> </DialogTitle>
        )}
        {isDeleteSuccess ? (
          <PlainAlertMessage
            type="Success"
            onClose={true}
            onCloseFun={() => {
              handleClose();
            }}
            message={successMessage}
            footer={footer}
            buttons={
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  handleClose();
                  callListingAPI();
                }}
              >
                Close
              </Button>
            }
          />
        ) : (
          fetchedData?.data && (
            <PlainAlertMessage
              type="Error"
              onClose={true}
              onCloseFun={() => {
                handleClose();
              }}
              message={errorMessage}
              footer={footer}
              buttons={
                <Button
                  className="popup_Btn error_popup_btn my-3"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              }
            />
          )
        )}
        <Loader open={deleteLoader} />
      </Dialog>
    </>
  );
};

export default DeleteModal;
