import React, { useEffect, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import MyMapComponent from "./MapComponent";
import { checkFiniteCoordinate } from "../helpers/utils";

const render = (status) => {
  return <h1>{status}</h1>;
};

const NewMap = ({
  center,
  zoom,
  className,
  coordinates,
  streetView,
  mapTypeControl,
  zoomControl,
  fullscreenControl,
}) => {
  return (
    <>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        render={() => <></>}
      >
        {checkFiniteCoordinate(center) && (
          <MyMapComponent
            center={center}
            zoom={zoom}
            className={className}
            coordinates={coordinates}
            streetView={streetView}
            mapTypeControl={mapTypeControl}
            zoomControl={zoomControl}
            fullscreenControl={fullscreenControl}
          />
        )}
      </Wrapper>
    </>
  );
};

export default NewMap;
