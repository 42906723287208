import { createSlice } from "@reduxjs/toolkit";

const initialPropertyListsState = {
  propertyListData: [],
  propertyListDataCount: 0,
  propertiesLoader: false,
  propertyRequestDocument: {},
  uploadedPropertyDocuments: {},
  replacedPropertyDocuments: {},
  uploadedPropertyGeneralDocuments: {},
  bindProfessionalDetails: {},
  bindProfessionalLoader: false,
  verifyAddressDetails: {},
  verifyAddressLoader: false,
  viewPropertyDetails: {},
  viewPropertyDetailLoader: false,
  cancelSubscriptionLoader: false,
  canceledSubscriptionDetails: {},
  changeSubscriptionLoader: false,
  changeSubscriptionPlanList: {},
  changedSubscriptionDetails: {},
  cancelChangeSubscriptionRequestLoader: false,
  cancelChangeSubscriptionRequestDetails: {},
  refundRecordingFeesDetails: {},
  refundRecordingFeesLoader: false,
  resendPostcardDetails: {},
  resendPostcardLoader: false,
  updateMailingAddressDetails: {},
  mailingAddressLoader: false,
};

const propertyLists = createSlice({
  name: "propertyLists",
  initialState: initialPropertyListsState,
  reducers: {
    getPropertyListData: (state, action) => {
      state.propertiesLoader = true;
      console.log("Get property list slice", action.payload);
    },
    fetchPropertyListData: (state, action) => {
      console.log("Fetching property list slice==>", action.payload?.data);
      state.propertyListData = action.payload;
      state.propertyListDataCount = action.payload?.count;
      state.propertiesLoader = false;
      //expire the cookie
      document.cookie =
        "iosValue =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    clearPropertyRequestDocument(state, action) {
      state.bindProfessionalDetails = {};
      state.propertyRequestDocument = {};
      state.uploadedPropertyDocuments = {};
      state.replacedPropertyDocuments = {};
    },
    getRequestPropertyDocument: (state, action) => {
      state.propertiesLoader = true;
      console.log("In request property document slice", action.payload);
    },
    fetchRequestPropertyDocument: (state, action) => {
      console.log("Fetching property document slice", action.payload?.data);
      state.propertyRequestDocument = action.payload;
      state.propertiesLoader = false;
    },
    getUploadedPropertyDocuments: (state, action) => {
      state.propertiesLoader = true;
      console.log("Getting uploaded docs", action.payload);
    },
    fetchUploadedPropertyDocuments: (state, action) => {
      console.log("Fetching uploaded documents", action.payload?.data);
      state.uploadedPropertyDocuments = action.payload;
      state.propertiesLoader = false;
    },
    getReplacedPropertyDocuments: (state, action) => {
      state.propertiesLoader = true;
      console.log("Getting replaced docs", action.payload);
    },
    fetchReplacedPropertyDocuments: (state, action) => {
      console.log("Fetching replaced documents", action.payload?.data);
      state.replacedPropertyDocuments = action.payload;
      state.propertiesLoader = false;
    },
    getUploadedPropertyGeneralDocuments: (state, action) => {
      state.propertiesLoader = true;
      console.log("Getting uploaded docs", action.payload);
    },
    fetchUploadedPropertyGeneralDocuments: (state, action) => {
      console.log("Fetching uploaded documents", action.payload?.data);
      state.uploadedPropertyGeneralDocuments = action.payload;
      state.propertiesLoader = false;
    },
    clearBindProfessionalDetails: (state, action) => {
      state.bindProfessionalDetails = {};
      state.bindProfessionalLoader = false;
    },
    bindProfessionalsToProperty: (state, action) => {
      state.bindProfessionalLoader = true;
    },
    setBindProfessionalDetails: (state, action) => {
      state.bindProfessionalDetails = action.payload;
      state.bindProfessionalLoader = false;
    },
    verifyAddress: (state, action) => {
      state.verifyAddressLoader = true;
    },
    setVerifyAddressDetails: (state, action) => {
      state.verifyAddressLoader = false;
      state.verifyAddressDetails = action.payload;
    },
    getAdminViewPropertyDetails: (state, action) => {
      state.viewPropertyDetailLoader = true;
      state.viewPropertyDetails = {};
    },
    setAdminViewPropertyDetails: (state, action) => {
      state.viewPropertyDetails = action.payload;
      state.viewPropertyDetailLoader = false;
    },
    cancelSubscription: (state, action) => {
      state.cancelSubscriptionLoader = true;
    },
    setCanceledSubscription: (state, action) => {
      state.canceledSubscriptionDetails = action.payload;
      state.cancelSubscriptionLoader = false;
    },
    getChangeSubscriptionPlanList(state, action) {
      state.changeSubscriptionLoader = true;
    },
    setChangeSubscriptionPlanList(state, action) {
      state.changeSubscriptionPlanList = action?.payload;
      state.changeSubscriptionLoader = false;
    },
    changeSubscriptionPlan(state, action) {
      state.changeSubscriptionLoader = true;
    },
    setChangedSubscriptionPlan(state, action) {
      state.changedSubscriptionDetails = action?.payload;
      state.changeSubscriptionLoader = false;
    },
    cancelChangeSubscriptionRequest(state, action) {
      state.cancelChangeSubscriptionRequestLoader = true;
    },
    setCancelChangeSubscriptionRequest(state, action) {
      state.cancelChangeSubscriptionRequestDetails = action?.payload;
      state.cancelChangeSubscriptionRequestLoader = false;
    },
    sendRefundRecordingFees: (state, action) => {
      state.refundRecordingFeesLoader = true;
    },
    setRefundRecordingFeesDetails: (state, action) => {
      state.refundRecordingFeesDetails = action?.payload;
      state.refundRecordingFeesLoader = false;
    },
    resendPostcard(state, action) {
      state.resendPostcardLoader = true;
    },
    setResendPostcardDetails(state, action) {
      state.resendPostcardDetails = action?.payload;
      state.resendPostcardLoader = false;
    },
    getUpdateMailingAddress(state, action) {
      state.mailingAddressLoader = true;
    },
    fetchUpdateMailingAddressDetails(state, action) {
      state.updateMailingAddressDetails = action?.payload;
      state.mailingAddressLoader = false;
    },
  },
});
export const propertyListAction = propertyLists.actions;
export default propertyLists.reducer;
