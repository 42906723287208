import { createSlice } from "@reduxjs/toolkit";
import {
  decryptPayload,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const initialLogsTableDetails = {
  logsListData: {},
  logsListLoader: false,
};

const logsList = createSlice({
  name: "logTableDetails",
  initialState: initialLogsTableDetails,
  reducers: {
    fetchLogsListData(state, action) {
      state.logsListLoader = true;
    },
    setLogsListData(state, action) {
      state.logsListData = action.payload;
      state.logsListLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Customer Logs", data);
      }
    },
  },
});
export const logsListAction = logsList.actions;
export default logsList.reducer;
