import React from "react";

const TwoIconSVG = () => {
  return (
    <>
      <svg
        width="32"
        height="50"
        viewBox="0 0 32 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="1"
          d="M31.96 50H0.6V45.73L17.68 29.35C19.9667 27.11 21.8333 24.8233 23.28 22.49C24.7267 20.11 25.45 17.6833 25.45 15.21V13.88C25.45 10.9867 24.61 8.63 22.93 6.81C21.25 4.99 18.73 4.08 15.37 4.08C12.1033 4.08 9.60667 4.89666 7.88 6.53C6.15333 8.16333 4.89333 10.2867 4.1 12.9L0.32 11.5C0.786667 10.0067 1.41667 8.58333 2.21 7.23C3.00333 5.87666 4.00667 4.68666 5.22 3.66C6.48 2.63333 7.95 1.81667 9.63 1.21C11.31 0.603331 13.27 0.299997 15.51 0.299997C17.7967 0.299997 19.8267 0.649997 21.6 1.35C23.42 2.00333 24.9367 2.93666 26.15 4.15C27.41 5.36333 28.3433 6.83333 28.95 8.56C29.6033 10.24 29.93 12.13 29.93 14.23C29.93 16.0033 29.6967 17.6833 29.23 19.27C28.7633 20.81 28.11 22.3033 27.27 23.75C26.43 25.15 25.4033 26.55 24.19 27.95C22.9767 29.3033 21.6467 30.6567 20.2 32.01L5.15 46.29H31.96V50Z"
          fill="#02a8e2"
        />
      </svg>
    </>
  );
};

export default TwoIconSVG;
