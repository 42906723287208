import React from "react";

import { Box } from "@mui/material";

import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import forbes from "../../assets/leadpage/forbes.WebP";
import tv_icon from "../../assets/leadpage/tv-icon.svg";
import usa_today from "../../assets/leadpage/usa_today.WebP";

import "./AsSeenOnSection.scss";

const AsSeenOnSection = () => {
  return (
    <div className="container-as-seen-on">
      <Box className="as_seen_on_logos">
        <div class="logo-container as_seen_on">
          <span className="px-2 pt-1 fw-700">As Seen on</span>
          <img className="as_seen_on_img" src={tv_icon} alt="Tv_icon" />
        </div>
        <div className="logo-container">
          <img src={nbc} alt="NBC" />
        </div>
        <div className="logo-container">
          <img src={forbes} alt="Forbes" />
        </div>
        <div className="logo-container">
          <img src={fox} alt="FOX" />
        </div>
        <div className="logo-container">
          <img src={cbs} alt="CBS" />
        </div>
        <div className="logo-container">
          <img src={usa_today} alt="USA Today" />
        </div>
        <div className="logo-container">
          <img src={cnn} alt="CNN" />
        </div>
      </Box>
    </div>
  );
};

export default AsSeenOnSection;
