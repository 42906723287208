import React, { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../common/CustomTextField";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const ResendToEmailForm = ({
  openResendMailModal,
  setOpenResendMailModal = () => {},
  setResendMailModal = () => {},
  handleClose = () => {},
  selectedRow,
  onSubmit,
}) => {
  const [formValues, setFormValues] = useState({});

  const formSubmit = (values) => {
    setOpenResendMailModal(true);
    setFormValues(values);
  };
  const handleResendEmail = () => {
    onSubmit(formValues);
  };

  return (
    <>
      {" "}
      {!openResendMailModal ? (
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            name: selectedRow?.name || "",
            email: selectedRow?.email || "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string("Please enter your User Name.")
              .trim("The User name cannot include leading and trailing spaces")
              .strict(true)
              .required("User Name is required"),

            email: Yup.string("Please enter your Email.")
              .trim("The Email cannot include leading and trailing spaces")
              .strict(true)
              .email("Please enter a valid email address")
              .required("Email is required"),
          })}
          onSubmit={(values) => formSubmit(values)}
        >
          {({ values, setFieldValue, touched, errors, initialValues }) => {
            return (
              <Form className="w-100 bg-white px-3 pt-4">
                <div className="">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="bg-white"
                  >
                    <>
                      {/* User name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <CustomTextField
                          label="User name"
                          name="name"
                          placeholder="User Name"
                          validation={{
                            values,
                            errors,
                            touched,
                            setFieldValue,
                          }}
                        />
                      </Grid>
                      {/* email */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <CustomTextField
                          label="Email"
                          name="email"
                          validation={{
                            values,
                            errors,
                            touched,
                            setFieldValue,
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>
                </div>

                <>
                  <Stack
                    direction="row"
                    pb={2}
                    justifyContent="flex-end"
                    spacing={1}
                    mt={3}
                  >
                    <Button
                      className="popup_Btn other_popup_btn"
                      // variant="contained"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn"
                      type="submit"
                      // disabled={JSON.stringify(initialValues) === JSON.stringify(values)}
                      variant="contained"
                    >
                      Send Mail
                    </Button>
                  </Stack>
                </>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <PlainAlertMessage
          type={"Warning"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setResendMailModal(false);
            setOpenResendMailModal(false);
          }}
          footer={false}
          message={
            <>
              <span className="fw-600">
                Are you sure you want to Re-send the property report for{" "}
                {selectedRow?.address} to {formValues?.name} at{" "}
                {formValues?.email}?
              </span>
              <br />
              <br />
              <b>Note</b>: Please verify that the recipient and email address
              are correct before proceeding.
            </>
          }
          buttons={
            <>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={handleResendEmail}
              >
                Yes, Send
              </Button>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setOpenResendMailModal(false)}
              >
                No, Edit
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default ResendToEmailForm;
