import { put, call, takeLatest } from "redux-saga/effects";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { getAPIPayload } from "../../../../apis/getApi";
import { postAPIPayload } from "../../../../apis/postApi";
import { promocodeAction } from "../../../slices/admin/promocodes/promocodesSlice";

export function* fetchPromocodeListDataSaga({ payload }) {
  console.log("asdasd", payload);
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(promocodeAction.setPromocodeListData(response));
}

export function* addPromocodeDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set data in redux store
  yield put(promocodeAction.setPromocodeDetails(response));
}

export function* deletePromocodeDetailsSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(deleteAPIPayload, payload);

  // set data in redux store
  // yield put(promocodeAction.setPromocodeDetails(response));
  yield put(promocodeAction.setDeletedPromocodeDetails(response));
}

export function* fetchPromocodeTypesSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(getAPIPayload, payload);

  // set data in redux store
  yield put(promocodeAction.setPromocodeTypes(response));
}

export function* watchPromocodeSagaAsync() {
  yield takeLatest(
    promocodeAction.fetchPromocodeListData,
    fetchPromocodeListDataSaga
  );
  yield takeLatest(
    promocodeAction.addPromocodeDetails,
    addPromocodeDetailsSaga
  );
  yield takeLatest(
    promocodeAction.deletePromocodeDetails,
    deletePromocodeDetailsSaga
  );
  yield takeLatest(
    promocodeAction.fetchPromocodeTypes,
    fetchPromocodeTypesSaga
  );
}
