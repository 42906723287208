import React from "react";

import { Col, Row } from "react-bootstrap";

import LazyIframe from "./LazyIframe";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/realStories.scss";

const EquityProtectAssetsVideos = () => {
  return (
    <>
      <Row className="">
        <Col>
          <div className=" text-center">
            <div className="mb-5">
              <p className="notify_header">Videos</p>
              <h3 className="sub_header">Videos on title fraud</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-center align-items-center"
      >
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2 ">
          <div className="realStoryWrapper">
            <LazyIframe caption="Equity Protect May (0:44)" id="q75Tsqzww4I" />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2">
          <div className="realStoryWrapper">
            <LazyIframe caption="Equity Protect (0:29)" id={"uZnK4lq3SW4"} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2">
          <div className="realStoryWrapper">
            <LazyIframe
              caption="Equity Protect - Hacker (0:12)"
              id={"WKkZdtG6CCE"}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2 ">
          <div className="realStoryWrapper">
            <LazyIframe
              caption="Equity Protect - Cyber Security (0:10)"
              id="16zkwkQXMso"
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2 ">
          <div className="realStoryWrapper">
            <LazyIframe
              caption="Equity Protect - Shield (0:10)"
              id="DqyzAftdzyw"
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className=" p-2">
          <div className="realStoryWrapper">
            <LazyIframe
              caption="EquityProtect protecting you (1:30)"
              id={"uuslWbcjIlY"}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default React.memo(EquityProtectAssetsVideos);
