import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, DialogActions, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogBox({
  width,
  openPopup,
  mesage,
  onClose,
  setOpenPopup,
  dividers,
  title,
  content,
  footer,
  contentClassName,
  fullScreenModal,
}) {
  const [open, setOpen] = React.useState(openPopup);
  return (
    <div>
      <Dialog
        maxWidth={width ? width : "sm"}
        sx={{ textAlign: "center" }}
        fullWidth={true}
        open={openPopup}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={fullScreenModal ? "zoom-modal-wrapper" : ""}
      >
        <DialogTitle id="alert-dialog-title" className="p-0">
          {title && <p className="m-3">{title}</p>}
          {onClose && (
            <Button
              onClick={() => onClose()}
              className={`modal-close-btn ${fullScreenModal && "close-text-a"}`}
              style={{
                position: "absolute",
                right: 10,
                top: 15,
                background: "transparent",
                textTransform: "none",
              }}
            >
              {fullScreenModal && "Close"}
              <CloseIcon sx={{ color: "#9CB8A6" }} />
            </Button>
          )}
        </DialogTitle>
        <DialogContent className={contentClassName} dividers={dividers}>
          {content}
        </DialogContent>
        {footer && <DialogActions>{footer}</DialogActions>}
      </Dialog>
    </div>
  );
}
