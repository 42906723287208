import { put, call, takeEvery } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { userDetailsFormAction } from "../../slices/user/userDetailsFormSlice";

export function* fetchUserTokenVerificationSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("fetchUserProfessional response => ", response);
  yield put(userDetailsFormAction.fetchUserTokenVarificationData(response));
}

export function* fetchUserFormDetailsSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("fetchUserProfessional response => ", response);
  yield put(userDetailsFormAction.fetchUserFormDetails(response));
}

export function* watchUserDetailsFormSagaAsync() {
  yield takeEvery(
    userDetailsFormAction.getUserTokenVarificationData,
    fetchUserTokenVerificationSaga
  );
  yield takeEvery(
    userDetailsFormAction.getUserFormDetails,
    fetchUserFormDetailsSaga
  );
}
