import { useState } from "react";
import { useEffect } from "react";
import React, { useRef } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Cropper } from "react-cropper";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import FileUpload from "../../common/FileUpload";
import { createFileUrl, getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import {
  closeDeleteIconStyle,
  fieldsValidations,
} from "../../Service-Provider/signup/SPSignupForm";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import pdfPlaceholder from "../../../assets/service-provider/pdf_placeholder.webp";
import wordDocPlaceholder from "../../../assets/service-provider/document_placeholder.webp";

const CreateServiceProviderForm = ({
  setScreen,
  from = "",
  setIsOTPByPass,
  isOTPByPass,
}) => {
  const dispatch = useDispatch();
  const signUpFormRef = useRef();
  const cropperRef = useRef();

  const [openPopup, setOpenPopup] = useState(false);
  const [inputParams, setInputParams] = useState({});
  const [isOtpConfirmationModalOpen, setIsOtpConfirmationModalOpen] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadedServiceAgreement, setUploadedServiceAgreement] =
    useState(null);

  const stateData = useSelector((state) => state.signUpUser.stateList);
  const { spSignupLoader, createAccResponse } = useSelector(
    (state) => state.serviceProviderSignup
  );

  const { confirm_password, password, ...SpSchema } = fieldsValidations;

  const onSubmit = (values, errors) => {
    console.log(isOTPByPass, "values=>", values);

    setInputParams(values);
    setIsOtpConfirmationModalOpen(true);
    // setScreen();
  };

  // handle otp confirmation modal
  const handleCreateUser = (isOTPByPass) => {
    setIsOTPByPass(isOTPByPass);
    setIsOtpConfirmationModalOpen(false);
    let data = { ...inputParams, is_bypass: isOTPByPass };
    if (isOTPByPass) {
      data = { ...data, step_key: "VERIFY_EMAIL_OTP", step: 1 };
    } else {
      data = { ...data, step_key: "SEND_OTP", step: 0 };
    }
    console.log("final data==>>", data);

    const formData = new FormData();

    Object.keys(data)?.map((key) => formData.append(key, data[key]));

    // return console.log("final form data==>", formData);
    // let url = "service-provider/send-otp";
    // if (from === "Admin") {
    let url = "admin/service-provider/create";
    // }
    dispatch(
      serviceProviderSignupAction.spSignupData({
        url: url,
        data: formData,
        token: true,
        isFile: true,
      })
    );
  };

  // when user select the image
  const onChange = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  // get cropped image
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      signUpFormRef?.current?.setFieldValue(
        "upload_logo",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      console.log(
        "croppedImage: ",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };

  // clear service agreement data
  const clearServiceAgreementData = () => {
    signUpFormRef?.current?.setFieldValue("document", "");
    setUploadedServiceAgreement("");
  };

  useEffect(() => {
    console.log("states api");
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
  }, []);

  useEffect(() => {
    console.log("createAccResponse==>", createAccResponse);
    if (getObjLength(createAccResponse)) {
      if (createAccResponse?.success) {
        if (!isOTPByPass) {
          setScreen("token");
        } else {
          setScreen("Professional info");
        }
        // setScreen();
      } else {
        setOpenPopup(true);
      }
    }
  }, [createAccResponse]);

  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <Box>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          first_name: "",
          last_name: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          email: "",
          phone_number: "",
          phone: "",
          upload_logo: "",
          document: "",
        }}
        innerRef={signUpFormRef}
        validationSchema={Yup.object().shape({
          ...SpSchema,
          upload_logo: Yup.string(),
          document: Yup.string(),
        })}
        // onSubmit={onSubmit}
        onSubmit={(values, validation) => {
          if (!isValidPhoneNumber(values?.phone)) {
            return;
          }

          // return console.log("values", values);
          onSubmit(values);
        }}
      >
        {({ values, setFieldValue, touched, errors }) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // id="outlined-basic"
                    label="First name"
                    name="first_name"
                    value={values?.first_name}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"First Name"}
                    onChange={(event) => {
                      setFieldValue("first_name", event.target.value);
                    }}
                    helperText={touched.first_name && errors.first_name}
                    error={touched.first_name && Boolean(errors.first_name)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // id="outlined-basic"
                    label="Last name"
                    name="last_name"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"Last Name"}
                    value={values?.last_name}
                    onChange={(event) => {
                      setFieldValue("last_name", event.target.value);
                    }}
                    helperText={touched.last_name && errors.last_name}
                    error={touched.last_name && Boolean(errors.last_name)}
                  />
                </Grid>

                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // id="outlined-basic"
                    label="Address"
                    name="address"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"Address"}
                    value={values?.address}
                    onChange={(event) => {
                      setFieldValue("address", event.target.value);
                    }}
                    helperText={touched.address && errors.address}
                    error={touched.address && Boolean(errors.address)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // id="outlined-basic"
                    label="City"
                    name="city"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"City"}
                    value={values?.city}
                    onChange={(event) => {
                      setFieldValue("city", event.target.value);
                    }}
                    helperText={touched.city && errors.city}
                    error={touched.city && Boolean(errors.city)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <FormControl
                    fullWidth
                    className="sign-textField"
                    error={touched.state && Boolean(errors.state)}
                  >
                    <InputLabel
                      shrink
                      // id="demo-simple-select-placeholder"
                      style={{ color: "#00000075" }}
                      label="State"
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      value={values?.state}
                      label="State"
                      name="state"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("state", event.target.value);
                      }}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <span style={{ color: "#00000075" }}>State</span>
                      </MenuItem>
                      {stateData?.map((state) => (
                        <MenuItem value={state.name}>{state.name}</MenuItem>
                      ))}
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.state && errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // length={6}
                    // id="outlined-basic"
                    label="Zip"
                    name="zip"
                    type="number"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"Zip"}
                    value={values?.zip}
                    onChange={(event) => {
                      setFieldValue("zip", event.target.value);
                    }}
                    helperText={touched.zip && errors.zip}
                    error={touched.zip && Boolean(errors.zip)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    // id="outlined-basic"
                    label="Email Address"
                    name="email"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#00000075",
                      },
                      shrink: true,
                    }}
                    placeholder={"Email Address"}
                    value={values?.email}
                    onChange={(event) => {
                      setFieldValue("email", event.target.value);
                    }}
                    onBlur={(event) =>
                      setFieldValue("email", event.target.value)
                    }
                    helperText={touched.email && errors.email}
                    error={touched.email && Boolean(errors.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <CustomPhoneNumberInput
                    name="phone"
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    otherProps={{
                      onChange: (event) => {
                        setFieldValue("phone", event);
                        setFieldValue("phone_number", event);
                        if (!event) {
                          setFieldValue("phone", "");
                          setFieldValue("phone_number", "");
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  {/* When cropper will crop the image then we will have data in the croppedImage state so code will transfer to else condition */}
                  {!croppedImage ? (
                    selectedFile ? (
                      <>
                        {/* When service provider selects the image then this cropper will be displayed */}
                        <Cropper
                          ref={cropperRef}
                          style={{ height: "280px", width: "100%" }}
                          zoomTo={0}
                          initialAspectRatio={1}
                          src={selectedFile}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          guides={false}
                        />
                        <Box className="d-flex justify-content-start" mt={2}>
                          <Button
                            onClick={getCropData}
                            variant="contained"
                            className="success-btn"
                            // color="primary"
                          >
                            Crop Logo
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                        <span className="servicer-signup-form">
                          <FileUpload
                            name="upload_doc"
                            accept={{
                              "image/png": [".png", ".jpeg", ".jpg"],
                            }}
                            multiple={false}
                            onFileUpload={async (file) => {
                              onChange(file);
                            }}
                            dragDropText={`Drag and drop Company Logo or `}
                            greenText="Upload a Company Logo"
                            subText="Only .png, .jpeg, .jpg files are allowed"
                          />
                        </span>
                      </>
                    )
                  ) : (
                    <>
                      {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                      <Box
                        className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                        sx={{
                          backgroundColor: "#F6F9FC",
                          border: croppedImage ? "1px solid #E2E8EB" : "",
                        }}
                      >
                        <img
                          src={croppedImage}
                          style={{
                            minHeight: "150px",
                            maxHeight: "230px",
                          }}
                          alt="cropped"
                          id="croppedImage"
                          className="rounded"
                        />

                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            cursor: "pointer",
                            color: "#FC2125",
                            fontSize: "1.4rem",
                          }}
                          onClick={() => {
                            setCroppedImage(null);
                            setSelectedFile(null);
                            signUpFormRef?.current?.setFieldValue(
                              "upload_logo",
                              ""
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <span
                    className={`${
                      uploadedServiceAgreement
                        ? "d-flex justify-content-center align-items-center flex-column rounded py-4"
                        : ""
                    }`}
                    style={{
                      backgroundColor: "#F6F9FC",
                      height: "100%",
                      border: uploadedServiceAgreement
                        ? "1px solid #E2E8EB"
                        : "",
                      gap: uploadedServiceAgreement ? "10px" : "",
                    }}
                  >
                    {/* This condition will be true if servicer selects any file which type is either .pdf or .doc/.docx */}
                    {uploadedServiceAgreement ? (
                      <>
                        {/* This is displayed for the file type pdf */}
                        {uploadedServiceAgreement?.type ===
                        "application/pdf" ? (
                          <>
                            <span
                              className="position-relative p-2 bg-white rounded"
                              sx={{ backgroundColor: "#F6F9FC" }}
                              onClick={() => {
                                window.open(
                                  createFileUrl(uploadedServiceAgreement),
                                  "_blank"
                                );
                              }}
                            >
                              <img
                                src={pdfPlaceholder}
                                alt="Document Preview"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                              <span
                                style={closeDeleteIconStyle}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearServiceAgreementData();
                                }}
                              >
                                <CloseOutlinedIcon />
                              </span>
                            </span>
                            <Typography className="mw-100 m-0">
                              <CustomLinkWrapper
                                link={
                                  uploadedServiceAgreement
                                    ? createFileUrl(uploadedServiceAgreement)
                                    : "#"
                                }
                                title={uploadedServiceAgreement?.path}
                                key={uploadedServiceAgreement?.path}
                                classes="preview-url"
                              />
                            </Typography>
                          </>
                        ) : uploadedServiceAgreement?.type ===
                            "application/msword" ||
                          uploadedServiceAgreement?.type ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                          <>
                            {/* This will display for the file type document */}
                            <span
                              className="position-relative p-2 bg-white rounded"
                              sx={{ backgroundColor: "#F6F9FC" }}
                              onClick={() => {
                                window.open(
                                  createFileUrl(uploadedServiceAgreement),
                                  "_blank"
                                );
                              }}
                            >
                              <img
                                src={wordDocPlaceholder}
                                alt="Document Preview"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                              <span
                                style={closeDeleteIconStyle}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearServiceAgreementData();
                                }}
                              >
                                <CloseOutlinedIcon />
                              </span>
                            </span>
                            <Typography className="mw-100 m-0 w-100 text-center px-2">
                              <CustomLinkWrapper
                                link={
                                  uploadedServiceAgreement
                                    ? createFileUrl(uploadedServiceAgreement)
                                    : "#"
                                }
                                title={uploadedServiceAgreement?.path}
                                key={uploadedServiceAgreement?.path}
                                classes="preview-url"
                              />
                            </Typography>
                          </>
                        ) : (
                          <>
                            {/* It will show the selected file if servicer selects the file which is neither in the pdf nor in the doc category */}
                            <Box
                              className="preview-file bg-white"
                              padding="3px 15px"
                              mb={0.9}
                            >
                              <CustomLinkWrapper
                                link={
                                  uploadedServiceAgreement
                                    ? createFileUrl(uploadedServiceAgreement)
                                    : "#"
                                }
                                title={uploadedServiceAgreement?.path}
                                key={uploadedServiceAgreement?.path}
                                classes="preview-url"
                              />
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  clearServiceAgreementData();
                                }}
                              >
                                <CloseOutlinedIcon />
                              </span>
                            </Box>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* when there is no file selected then it will display the file upload field */}
                        <span className="servicer-signup-form">
                          <FileUpload
                            name="document"
                            accept={{
                              "application/pdf": [".pdf"],
                              "application/msword": [".doc"],
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                [".docx"],
                            }}
                            multiple={false}
                            onFileUpload={async (file) => {
                              // onChange(file);
                              if (file?.length > 0) {
                                setUploadedServiceAgreement(file[0]);
                                setFieldValue("document", file[0]);
                              }
                            }}
                            dragDropText={`Drag and drop Service Agreement or `}
                            greenText="Upload a Service Agreement"
                            subText="Only .pdf, .doc, .docx files are allowed"
                          />
                        </span>
                      </>
                    )}
                  </span>
                </Grid>

                <Grid xs={12} md={12} px={1} pb={1}>
                  <div className="text-center createAcBtn mt-5">
                    <Button
                      type="submit"
                      className="next_button"
                      variant="contained"
                    >
                      Create your account
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {getObjLength(createAccResponse) && openPopup && (
        <AlertDialog
          type="Error"
          openPopup={openPopup}
          onClose={true}
          mesage={
            // {<p className="text-center text-danger m-0">
            !createAccResponse?.data?.success &&
            createAccResponse?.data?.message
          }
          onCloseFun={() => setOpenPopup(false)}
          // </p>}
        />
      )}
      <Loader open={spSignupLoader} />
      {/* otp confirmation modal */}
      {isOtpConfirmationModalOpen && (
        <>
          <AlertDialog
            type="Info"
            openPopup={isOtpConfirmationModalOpen}
            onClose={true}
            onCloseFun={() => {
              handleCreateUser(false);
            }}
            mesage={
              "Do you want this user to bypass the Token confirmation process?"
            }
            footer={false}
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn mx-2"
                  onClick={() => handleCreateUser(false)}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => handleCreateUser(true)}
                >
                  Yes
                </Button>
              </>
            }
          />
        </>
      )}
    </Box>
  );
};

export default CreateServiceProviderForm;
