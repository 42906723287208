import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TableContainer from "@mui/material/TableContainer";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { ApplyOnList } from "./AddPromocodeModal";
import DeleteModal from "../../common/DeleteModal";
import AlertDialog from "../../common/AlertDialog";
import { PER_PAGE } from "../../helpers/constants";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
} from "../../helpers/utils";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { promocodeAction } from "../../../redux/slices/admin/promocodes/promocodesSlice";
import {
  currencyFormatter,
  getLocalFormatedDateForExpireDate,
  getObjLength,
} from "../../helpers/utils";

import "../AdminDashboard/propertyTable.scss";

const columns = [
  { key: 1, id: "no.", disablePadding: true, label: "No.", minWidth: 50 },
  { key: 2, id: "name", disablePadding: true, label: "Name", minWidth: 100 },
  { key: 3, id: "type", disablePadding: true, label: "Type", minWidth: 145 },
  {
    key: 4,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 115,
  },
  {
    key: 5,
    id: "no_of_time_redeem",
    disablePadding: true,
    label: "Maximum Redemption",
    minWidth: 60,
  },
  {
    key: 6,
    id: "no_of_time_redeemed",
    disablePadding: true,
    label: "Redeemed",
    minWidth: 80,
  },
  {
    key: 7,
    id: "expire_at",
    disablePadding: true,
    label: "Expiry Date",
    minWidth: 125,
    sorting: true,
  },
  {
    key: 8,
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    minWidth: 127,
    sorting: true,
  },
  {
    key: 9,
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 80,
  },
];

function Row({
  row,
  open,
  handleMenuClick,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
}) {
  const monthly = row?.monthly;
  const yearly = row?.yearly;
  return (
    <>
      <TableRow
        hover
        onClick={() => handleCollapsibleRow(row)}
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="left">{`${row?.name}`}</TableCell>
        <TableCell align="left">
          {!row?.is_individual ? "Individuals" : "Common"}
        </TableCell>
        <TableCell
          align="left"
          className={row?.status === "active" ? "text-success" : "text-danger"}
        >
          {row?.status.toUpperCase()}
        </TableCell>
        <TableCell align="left">
          {row?.no_of_time_redeem ? row?.no_of_time_redeem : "Unlimited"}
        </TableCell>
        <TableCell align="left">
          {row?.no_of_time_redeemed ? row?.no_of_time_redeemed : "-"}
        </TableCell>
        <TableCell align="left" width={120}>
          {row?.expire_at
            ? getLocalFormatedDateForExpireDate(row?.expire_at, "23, 59, 59")
            : "-"}
        </TableCell>
        <TableCell align="left" width={120}>
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left" width={120}>
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleMenuClick(e, row);
              }}
              size="small"
              className="settings-button"
              aria-controls={open ? "settings-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <SettingsIconSVG />
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      {!row?.is_individual ? (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          ></TableCell>

          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Value
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    {row?.type === "percent"
                      ? `${row?.value}%`
                      : currencyFormatter(parseFloat(row?.value))}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>

          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={8}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Applied on
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.on_subscription &&
                    row?.on_recording_charge &&
                    row?.on_setup_fees
                      ? ApplyOnList[0]?.label
                      : row?.on_subscription
                      ? ApplyOnList[1]?.label
                      : row?.on_recording_charge
                      ? ApplyOnList[2]?.label
                      : row?.on_setup_fees
                      ? ApplyOnList[3]?.label
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {monthly && (
            <TableRow
              className="collapsible-row"
              sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
            >
              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              ></TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Applied on
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        Monthly Plan
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Subscription Amount
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {monthly?.["subscription"]?.type === "percent"
                          ? `${monthly?.["subscription"]?.value}%`
                          : currencyFormatter(
                              parseFloat(monthly?.["subscription"]?.value)
                            ) || "-"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Recording fees
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {monthly?.["recording charge"]?.type === "percent"
                          ? `${monthly?.["recording charge"]?.value}%`
                          : currencyFormatter(
                              parseFloat(monthly?.["recording charge"]?.value)
                            ) || "-"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Setup Fees
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {monthly?.["setup fee"]?.type === "percent"
                          ? `${monthly?.["setup fee"]?.value}%`
                          : currencyFormatter(
                              parseFloat(monthly?.["setup fee"]?.value)
                            ) || "-"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={5}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Is Subscription Renewal?
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {row?.apply_on_monthly_recuring ? "Yes" : "No"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}

          {yearly && (
            <TableRow
              className="collapsible-row"
              sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
            >
              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              ></TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Applied on
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        Yearly Plan
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Subscription Amount
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {yearly?.["subscription"]?.type === "percent"
                          ? `${yearly?.["subscription"]?.value}%`
                          : currencyFormatter(
                              parseFloat(yearly?.["subscription"]?.value)
                            ) || "-"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Recording fees
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {yearly?.["recording charge"]?.type === "percent"
                          ? `${yearly?.["recording charge"]?.value}%`
                          : currencyFormatter(
                              parseFloat(yearly?.["recording charge"]?.value)
                            ) || "-"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={1}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Setup Fees
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        No Set-up fee
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
                colSpan={5}
              >
                <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      minHeight: "100px",
                      maxHeight: "200px",
                      overflow: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="h6"
                        className="content-heading"
                        component="div"
                      >
                        Is Subscription Renewal?
                      </Typography>
                      <Typography className="content-text m-0" component="div">
                        {row?.apply_on_yearly_recuring ? "Yes" : "No"}
                      </Typography>
                    </Stack>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
}

export default function PromocodeListTable({ handleEditButton, handleClose }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [isDeletePromocodeModalOpen, setIsDeletePromocodeModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedValue, setSelectedValue] = useState("");
  const [applyOn, setApplyOn] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isFilterApiCalled, setIsFilterApiCalled] = useState(false);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(10); // updated_at key for sorting
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const open = Boolean(anchorEl);

  // store data
  console.log("===state?.promocodes===");
  const {
    promocodeListLoader,
    promocodeData,
    addPromocodeDetails,
    deletedPromocodeDetails,
    promocodeDataCount,
  } = useSelector((state) => state?.promocodes);

  const count = Math.ceil(promocodeDataCount / PER_PAGE);
  console.log(count);
  // const _DATA = usePagination(
  //   promocodeData?.data ? promocodeData?.data : [],
  //   PER_PAGE,
  //   page,
  //   setPage
  // );

  const _DATA = promocodeData?.data ? promocodeData?.data : [];

  const generatePromocodeURL = (page = 1) => {
    let url, selectedDateRangePayload;
    if (selectedValue || applyOn || selectedDateRange) {
      const type =
        selectedValue || applyOn || selectedDateRange
          ? `&type=${selectedKey}`
          : "";
      const term = selectedValue
        ? `&term=${encodeURIComponent(selectedValue)}`
        : "";
      const appliedOn = applyOn && `&applied_on=${applyOn}`;

      // const expire_date = selectedDateRange
      //   ? `&expire_date=${getDateInYYYYMMDDFormat(new Date(selectedDateRange))}`
      //   : "";
      if (selectedDateType === "created_at") {
        selectedDateRangePayload = selectedDateRange
          ? `&from_date=${
              getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) ||
              ""
            } &to_date=${
              getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) ||
              ""
            }`
          : "";
      } else {
        selectedDateRangePayload = selectedDateRange
          ? `&expiry_from_date=${
              getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) ||
              ""
            } &expiry_to_date=${
              getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) ||
              ""
            }`
          : "";
      }

      url = `promocodes?limit=${PER_PAGE}&offset=${
        (page - 1) * PER_PAGE
      }${type}${term}${appliedOn}${selectedDateRangePayload}`;
    } else {
      url = `promocodes?limit=${PER_PAGE}&offset=${(page - 1) * PER_PAGE}`;
    }
    return url;
  };

  const handlePageChange = (e, p) => {
    setPage(p);
    dispatch(
      promocodeAction.fetchPromocodeListData({
        url: generatePromocodeURL(p),
        data: {},
        token: true,
      })
    );
  };

  // handle open delete modal
  const handleDeleteButton = (selectedItem) => {
    setIsDeletePromocodeModalOpen(true);
    setSelectedItem(selectedItem);
  };

  //   handleClosing delete modal
  const handleCloseDeleteModal = () => {
    setIsDeletePromocodeModalOpen(false);
    setSelectedItem(null);
    dispatch(promocodeAction.clearData());
  };

  // dispatch action to delete promocode
  const handleDeletePromocode = () => {
    if (selectedItem && selectedItem.id) {
      dispatch(
        promocodeAction.deletePromocodeDetails({
          url: `promocodes/${selectedItem?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle filter property in promocodes
  const handleFilterProperty = () => {
    if (selectedValue || applyOn || selectedDateRange) {
      setPage(1);
      dispatch(
        promocodeAction.fetchPromocodeListData({
          url: generatePromocodeURL(1),
          data: {},
          token: true,
        })
      );
      setIsFilterApiCalled(true);
    }
  };

  // handle reset function in promocodes
  const resetFilterOption = () => {
    if (isFilterApiCalled) {
      setPage(1);
      getPromocodeDetails();
      setIsFilterApiCalled(false);
      setDirectionFlow(true);
      setSelectedColumn(10);
    }
    setSelectedKey("all");
    setSelectedValue("");
    setApplyOn("");
    setSelectedDateRange(null);
    setSelectedDateType("created_at");
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    dispatch(
      promocodeAction.fetchPromocodeListData({
        url: `${generatePromocodeURL(
          page
        )}&order_by_column=${column}&sorting_order=${
          !directionFlow ? `desc` : `asc`
        }`,
        data: {},
        token: true,
      })
    );
  };

  // for action column
  const handleMenuClick = (event, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };

  //for collapsible Row
  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getPromocodeDetails = useCallback(() => {
    dispatch(
      promocodeAction.fetchPromocodeListData({
        url: `promocodes?limit=${PER_PAGE}&offset=${0}`,
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getPromocodeDetails();
  }, [dispatch, getPromocodeDetails]);
  console.log("selectedRow==>", selectedRow);
  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>
      {/* Filtering Options */}
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(event) => {
                        setSelectedKey(event.target.value);
                        setSelectedValue("");
                        setApplyOn("");
                        setSelectedDateRange(null);
                        setSelectedDateType("created_at");
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.id !== "no." &&
                          cell.id !== "no_of_time_redeem" &&
                          cell.id !== "no_of_time_redeemed" &&
                          cell.id !== "action" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "created_at" &&
                  selectedKey !== "applied_on" &&
                  selectedKey !== "expire_at" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {(selectedKey === "all" || selectedKey === "applied_on") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Applied on"
                        value={applyOn}
                        onChange={(e) => setApplyOn(e.target.value)}
                      >
                        {ApplyOnList?.map((data) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {selectedKey === "all" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Expired At", value: "expire_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedKey === "all" ||
                  selectedKey === "created_at" ||
                  selectedKey === "expire_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={resetFilterOption}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card className="d-none d-md-block filter-options-carddiv">
              <Grid container className="filter-options-box">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        onChange={(event) => {
                          setSelectedKey(event.target.value);
                          setSelectedValue("");
                          setApplyOn("");
                          setSelectedDateRange(null);
                          setSelectedDateType("created_at");
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.id !== "no." &&
                            cell.id !== "no_of_time_redeem" &&
                            cell.id !== "no_of_time_redeemed" &&
                            cell.id !== "action" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                  </Stack>
                </Grid>
                {selectedKey !== "created_at" &&
                  selectedKey !== "applied_on" &&
                  selectedKey !== "expire_at" && (
                    <Grid item>
                      <FormControl className="filter-search" variant="outlined">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {(selectedKey === "all" || selectedKey === "applied_on") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Applied on"
                        value={applyOn}
                        onChange={(e) => setApplyOn(e.target.value)}
                      >
                        {ApplyOnList?.map((data) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {selectedKey === "all" && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Expired At", value: "expire_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedKey === "all" ||
                  selectedKey === "created_at" ||
                  selectedKey === "expire_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterProperty}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetFilterOption}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>

      {/* Property Listing Table */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                columns={columns}
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <>
                      <Row
                        key={(page - 1) * 10 + index + 1}
                        row={row}
                        open={open}
                        handleMenuClick={handleMenuClick}
                        page={page}
                        index={index}
                        handleCollapsibleRow={handleCollapsibleRow}
                        isCollapsible={
                          selectedCollapsibleRow?.id === row?.id &&
                          isCollapsibleRow
                        }
                      />
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {!promocodeData?.data?.length > 0 && !promocodeListLoader && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
        {promocodeDataCount > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>

      {/* table options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="view"
          onClick={() => {
            dispatch(promocodeAction.clearAddPromocodeDetailsData());
            handleEditButton(selectedRow);
          }}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <ModeOutlinedIcon />
            <Typography className="setting-menu-text">
              Edit Promocode
            </Typography>
          </Box>
        </MenuItem>

        <MenuItem
          key="view"
          onClick={() => {
            dispatch(promocodeAction.clearAddPromocodeDetailsData());
            handleDeleteButton(selectedRow);
          }}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <DeleteOutlineIcon />
            <Typography className="setting-menu-text">
              Delete Promocode
            </Typography>
          </Box>
        </MenuItem>
      </Menu>

      <DeleteModal
        open={isDeletePromocodeModalOpen}
        title="Delete Promocode"
        item="Promocode"
        deleteLoader={promocodeListLoader}
        handleClose={handleCloseDeleteModal}
        handleDeleteBtn={handleDeletePromocode}
        fetchedData={deletedPromocodeDetails}
        isDeleteSuccess={deletedPromocodeDetails?.success}
        successMessage={deletedPromocodeDetails?.message}
        errorMessage={deletedPromocodeDetails?.data?.message}
        callListingAPI={() => {
          if (
            promocodeData?.data?.length === 1 &&
            promocodeDataCount > PER_PAGE &&
            isDeletePromocodeModalOpen
          ) {
            dispatch(
              promocodeAction.fetchPromocodeListData({
                url: generatePromocodeURL(page - 1),
                token: true,
              })
            );
            setPage(page - 1);
          } else {
            dispatch(
              promocodeAction.fetchPromocodeListData({
                url: generatePromocodeURL(page),
                token: true,
              })
            );
          }
        }}
        footer={false}
      />

      {getObjLength(addPromocodeDetails) ? (
        addPromocodeDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              handleClose();
              dispatch(promocodeAction.clearAddPromocodeDetailsData());
              dispatch(
                promocodeAction.fetchPromocodeListData({
                  url: generatePromocodeURL(page),
                  token: true,
                })
              );
            }}
            mesage={addPromocodeDetails?.message}
            footer={false}
          />
        ) : null
      ) : null}
      <Loader open={promocodeListLoader} />
    </>
  );
}
