import React from "react";
import { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";

import { Loader } from "../../common/loader";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";

import "../../common/common.scss";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { getObjLength } from "../../helpers/utils";
import { sendInvitationAction } from "../../../redux/slices/professional/send-invitation/sendInvitationSlice";

const FieldStyle = {
  borderRadius: "3px",
};

const SendInvitationModal = ({ open, selectedItem, handleClose, onSubmit, setAlertMsg }) => {
  const dispatch = useDispatch();
  const { sendInvitationDetails, sendInvitationListLoader } = useSelector(
    (state) => state?.sendInvitations
  );

  // clear add/edit/delete professional's existing data
  useEffect(() => {
    if (open) {
      dispatch(professionalAction.clearAddProfessionalDetailsData());
    }
  }, [dispatch, open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={sendInvitationDetails?.success ? "sm" : "md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!sendInvitationDetails?.success &&
        <>
        <DialogTitle id="alert-dialog-title">
          Send Invitation
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers sx={{ m: 0, p: 0 }}>
          <Box className="d-flex justify-content-center bg-white">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                name: selectedItem?.name || "",
                phone: selectedItem?.phone || "",
                email: selectedItem?.email || "",
                promo_code: selectedItem?.promo_code || "",
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string("Please enter your Name.")
                  .trim("The name cannot include leading and trailing spaces")
                  .strict(true)
                  .required("Name is required"),
                phone: Yup.string("")
                  .trim()
                  .required("Please enter phone number"),
                email: Yup.string("Please enter your email.")
                  .trim("The email cannot include leading and trailing spaces")
                  .strict(true)
                  .email("Please enter a valid email address")
                  .required("Email is required"),
              })}
              onSubmit={(values) => {
                if (!isValidPhoneNumber(values?.phone)) {
                  return;
                }
                onSubmit(values);
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                resetForm,
                initialValues,
              }) => {
                return (
                  <Form className="beneficiary-form w-100">
                    <DialogContent className="bg-white">
                      {/* {!addProfessionalDetails?.success && ( */}
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        className="bg-white"
                      >
                        <>
                          {/* first name */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Name"
                              name="name"
                              value={values?.name}
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              onChange={(event) => {
                                setFieldValue("name", event.target.value);
                              }}
                              helperText={touched.name && errors.name}
                              error={touched.name && Boolean(errors.name)}
                            />
                          </Grid>
                          {/* last name */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <CustomPhoneNumberInput
                              name="phone"
                              values={values}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              disabled={selectedItem?.id ? true : false}
                            />
                          </Grid>
                          {/* email */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Email"
                              name="email"
                              disabled={selectedItem?.id ? true : false}
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.email}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                              }}
                              helperText={touched.email && errors.email}
                              error={touched.email && Boolean(errors.email)}
                            />
                          </Grid>
                          <Grid xs={12} md={6} px={1} pb={1}>
                            <TextField
                              className="sign-textField "
                              label="Promo Code"
                              name="promo_code"
                              disabled={selectedItem?.id ? true : false}
                              value={values?.promo_code}
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                                shrink: true,
                              }}
                              placeholder={"Optional"}
                              onChange={(event) => {
                                setFieldValue("promo_code", event.target.value);
                              }}
                            />
                          </Grid>
                        </>
                      </Grid>
                      {/* )}
                        <Typography
                          className={`${
                            addProfessionalDetails?.success
                              ? "text-success"
                              : "text-danger"
                          } text-center w-100`}
                        >
                          {addProfessionalDetails?.success
                            ? addProfessionalDetails?.message
                            : addProfessionalDetails?.data?.message}
                        </Typography> */}
                    </DialogContent>
                    {getObjLength(sendInvitationDetails) && !sendInvitationDetails?.success && (
                    <p className="text-danger text-center p-2">{sendInvitationDetails?.data?.message}</p>
                  )} 
                    <DialogActions className="alert-modal-btn">
                      <Stack direction="row" spacing={2} pb={2}>
                        {sendInvitationDetails?.success ? (
                          <Button
                            className="popup_Btn other_popup_btn m-1"
                            // variant="contained"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Close
                          </Button>
                        ) : (
                          <>
                            <Button
                              className="popup_Btn other_popup_btn m-1"
                              // variant="contained"
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="popup_Btn success_popup_btn m-1"
                              type="submit"
                              disabled={
                                JSON.stringify(initialValues) ===
                                JSON.stringify(values)
                              }
                              variant="contained"
                            >
                              Save
                            </Button>
                          </>
                        )}
                      </Stack>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
        </>}
        <Loader open={sendInvitationListLoader} />
        {getObjLength(sendInvitationDetails) ? (
        <>
          {sendInvitationDetails?.success && (
            <PlainAlertMessage
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                handleClose();
                dispatch(sendInvitationAction.clearSendInvitationDetailsData());
                // onSuccess();
              }}
              message={sendInvitationDetails?.message}
              footer={false}
            />
          )}
        </>
      ) : null}
      </Dialog>
    </>
  );
};

export default SendInvitationModal;
