import React, { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";

import { encryptData, getObjLength } from "../../helpers/utils";
import { FieldStyle, secretKeyEncrypt } from "../../helpers/constants";

const CountyAdminForm = ({ selectedItem, onSubmit, handleClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { addCountyAdminDetails } = useSelector((state) => state?.admins);
  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  const handleCountyChange = (e, newValue, setFieldValue) => {
    const selectedCounty = registeredCountyListData?.data.find(
      (county) => county.county_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setFieldValue("county", selectedCounty.id);
      setFieldValue("county_name", selectedCounty.county_name);
    }
  };

  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {" "}
      <div className="d-flex justify-content-center bg-white">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            first_name: selectedItem?.first_name || "",
            last_name: selectedItem?.last_name || "",
            email: selectedItem?.email || "",
            county: selectedItem?.county_admin?.county_id || "",
            county_name: selectedItem?.county_admin?.county?.county_name || "",
            password: "",
            password_confirmation: "",
            // is_active: 1,
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string("Please enter your First Name.")
              .trim("The First name cannot include leading and trailing spaces")
              .strict(true)
              .required("First Name is required"),
            last_name: Yup.string("Please enter your Last name.")
              .trim("The Last name cannot include leading and trailing spaces")
              .strict(true)
              .required("Last Name is required"),
            county_name: Yup.string("Please Select County Name.").required(
              "County name is required"
            ),
            email: Yup.string("Please enter your Email.")
              .trim("The Email cannot include leading and trailing spaces")
              .strict(true)
              .email("Please enter a valid email address")
              .required("Email is required"),
            password: selectedItem
              ? Yup.string("Please enter Password.")
                  //.required("Password is required")
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
                  )
                  .test(
                    "no-spaces",
                    "Password cannot contain any spaces",
                    (value) => !/\s/.test(value)
                  )
              : Yup.string("Please enter Password.")
                  .required("Password is required")
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
                  )
                  .test(
                    "no-spaces",
                    "Password cannot contain any spaces",
                    (value) => !/\s/.test(value)
                  ),
            password_confirmation: selectedItem
              ? Yup.string("Please enter Confirm Password.")
                  //.required("Confirm Password is required")
                  .when("password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string()
                      .oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same"
                      )
                      .test(
                        "no-spaces",
                        "Password cannot contain any spaces",
                        (value) => !/\s/.test(value)
                      ),
                  })
              : Yup.string("Please enter Confirm Password.")
                  .required("Confirm Password is required")
                  .when("password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string()
                      .oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same"
                      )
                      .test(
                        "no-spaces",
                        "Password cannot contain any spaces",
                        (value) => !/\s/.test(value)
                      ),
                  }),
          })}
          onSubmit={(values) => {
            const payload = JSON.parse(JSON.stringify(values));
            payload.password = values?.password?.trim();
            payload.password_confirmation =
              values?.password_confirmation?.trim();
            const encryptedData1 = encryptData(
              payload?.password,
              secretKeyEncrypt.toString()
            );
            const encryptedData2 = encryptData(
              payload?.password,
              secretKeyEncrypt.toString()
            );
            payload.password = encryptedData1;
            payload.password_confirmation = encryptedData2;
            onSubmit(payload, "county_admin");
          }}
        >
          {({ values, setFieldValue, touched, errors, initialValues }) => {
            return (
              <Form className="beneficiary-form w-100 bg-white">
                <div className="">
                  {/* {!addAdminDetails?.success && ( */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="bg-white"
                  >
                    <>
                      {/* first name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <TextField
                          className="sign-textField "
                          label="First name"
                          name="first_name"
                          placeholder="First Name"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.first_name}
                          onChange={(event) => {
                            setFieldValue("first_name", event.target.value);
                          }}
                          helperText={touched.first_name && errors.first_name}
                          error={
                            touched.first_name && Boolean(errors.first_name)
                          }
                        />
                      </Grid>
                      {/* last name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <TextField
                          className="sign-textField "
                          label="Last name"
                          name="last_name"
                          placeholder="Last Name"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.last_name}
                          onChange={(event) => {
                            setFieldValue("last_name", event.target.value);
                          }}
                          helperText={touched.last_name && errors.last_name}
                          error={touched.last_name && Boolean(errors.last_name)}
                        />
                      </Grid>
                      {/* county list */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <FormControl
                          disabled={selectedItem?.county_admin?.county_id}
                          fullWidth
                          className="sign-textField "
                          error={
                            touched.county_name && Boolean(errors.county_name)
                          }
                        >
                          <Autocomplete
                            className={`${
                              touched.county_name && Boolean(errors.county_name)
                                ? "autocomplete_error"
                                : ""
                            }`}
                            id="asynchronous-demo"
                            value={values?.county_name}
                            disableClearable
                            options={registeredCountyListData?.data.map(
                              (option) => option?.county_name
                            )}
                            onChange={(e, newValue) =>
                              handleCountyChange(e, newValue, setFieldValue)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="County List" />
                            )}
                          />

                          {touched.county_name &&
                            Boolean(errors.county_name) && (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.county_name && errors.county_name}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      {/* email */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <TextField
                          className="sign-textField "
                          label="Email"
                          name="email"
                          disabled={selectedItem?.id ? true : false}
                          placeholder="Email"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                          helperText={touched.email && errors.email}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <FormControl
                          variant="outlined"
                          className={`w-100 ${
                            touched.password && Boolean(errors.password)
                              ? "password_error"
                              : ""
                          }`}
                        >
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            className={`${
                              touched.password && Boolean(errors.password)
                                ? "error_color"
                                : ""
                            }`}
                          >
                            Create Your Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            className="textfield"
                            type={showPassword ? "text" : "password"}
                            label="Create Your Password"
                            placeholder="Create Your Password"
                            name="password"
                            sx={{ backgroundColor: "#f6f9fc" }}
                            inputProps={{
                              autoComplete: "new-password",
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    handleClickShowPassword(setShowPassword)
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            value={values?.password}
                            fullWidth
                            onChange={(event) => {
                              setFieldValue(
                                "password",
                                event.target.value?.trim()
                              );
                            }}
                            helperText={touched.password && errors.password}
                            error={touched.password && Boolean(errors.password)}
                          />
                          {touched.password && Boolean(errors.password) && (
                            <FormHelperText
                              sx={{
                                color: "rgb(211, 47, 47) !important",
                                fontSize: "12px !important",
                                maxWidth: "100% !important",
                              }}
                            >
                              {touched.password && errors.password}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} px={1} pb={1}>
                        <FormControl
                          variant="outlined"
                          className={` w-100 ${
                            touched.password_confirmation &&
                            Boolean(errors.password_confirmation)
                              ? "password_error"
                              : ""
                          }`}
                        >
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            className={`${
                              touched.password_confirmation &&
                              Boolean(errors.password_confirmation)
                                ? "error_color"
                                : ""
                            }`}
                          >
                            Confirm Your Password
                          </InputLabel>
                          <OutlinedInput
                            className={`textfield `}
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            label="Confirm Your Password"
                            placeholder="Confirm Your Password"
                            name="password_confirmation"
                            sx={{ backgroundColor: "#f6f9fc" }}
                            inputProps={{
                              autoComplete: "new-password_confirmation",
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password_confirmation visibility"
                                  onClick={() =>
                                    handleClickShowPassword(
                                      setShowConfirmPassword
                                    )
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            value={values?.password_confirmation}
                            fullWidth
                            onChange={(event) => {
                              setFieldValue(
                                "password_confirmation",
                                event.target.value?.trim()
                              );
                            }}
                            helperText={
                              touched.password_confirmation &&
                              errors.password_confirmation
                            }
                            error={
                              touched.password_confirmation &&
                              Boolean(errors.password_confirmation)
                            }
                          />

                          {touched.password_confirmation &&
                            Boolean(errors.password_confirmation) && (
                              <FormHelperText
                                sx={{
                                  color: "rgb(211, 47, 47) !important",
                                  fontSize: "12px !important",
                                  maxWidth: "100% !important",
                                }}
                              >
                                {touched.password_confirmation &&
                                  errors.password_confirmation}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </>
                  </Grid>
                </div>

                {addCountyAdminDetails?.success ? (
                  <Button
                    className="popup_Btn other_popup_btn mt-3"
                    // variant="contained"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  <>
                    <Stack
                      direction="row"
                      pb={2}
                      justifyContent="flex-end"
                      spacing={1}
                      mt={3}
                    >
                      <Button
                        className="popup_Btn other_popup_btn"
                        // variant="contained"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="popup_Btn success_popup_btn"
                        type="submit"
                        disabled={
                          JSON.stringify(initialValues) ===
                          JSON.stringify(values)
                        }
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Stack>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CountyAdminForm;
