import { call, put, takeEvery } from "redux-saga/effects";

import { getAPIPayload } from "../../../../apis/getApi";
import { postAPIPayload } from "../../../../apis/postApi";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { addLeadsAction } from "../../../slices/admin/addLeads/addLeadsSlice";

export function* fetchLeadsDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(addLeadsAction.fetchLeadsList(response));
}

export function* fetchAddedLeadsDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(addLeadsAction.fetchAddLeadsData(response));
}

export function* fetchDeletedLeadsDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(deleteAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(addLeadsAction.fetchDeletedLeadsData(response));
}

export function* resendInvitationDetailsSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(addLeadsAction.setResendInvitationDetails(response));
}

export function* watchAddLeadsSagaAsync() {
  yield takeEvery(addLeadsAction.getLeadsData, fetchLeadsDataSaga);
  yield takeEvery(addLeadsAction.addLeadsData, fetchAddedLeadsDataSaga);
  yield takeEvery(addLeadsAction.deleteLeadsData, fetchDeletedLeadsDataSaga);
  yield takeEvery(
    addLeadsAction.resendInvitationDetails,
    resendInvitationDetailsSaga
  );
}
