import { createSlice } from "@reduxjs/toolkit";

const intialAddLeadsState = {
  addleadsLoader: false,
  addLeadsData: {},
  addLeadsDataCount: 0,
  leadsList: {},
  leadDeletedData: {},
  resendInvitationDetails: {},
  sendInvitationListLoader: false,
};

const addLeads = createSlice({
  name: "addLeadsDetails",
  initialState: intialAddLeadsState,
  reducers: {
    getLeadsData: (state, action) => {
      state.addleadsLoader = true;
      console.log("addLeadsData slice", action.payload);
    },
    fetchLeadsList: (state, action) => {
      console.log("fetchAddLeadsData slice", action.payload);
      state.addleadsLoader = false;
      state.leadsList = action.payload;
      state.addLeadsDataCount = action.payload?.count;
    },
    addLeadsData: (state, action) => {
      state.addleadsLoader = true;
      console.log("addLeadsData slice", action.payload);
    },
    fetchAddLeadsData: (state, action) => {
      console.log("fetchAddLeadsData slice", action.payload);
      state.addleadsLoader = false;
      state.addLeadsData = action.payload;
    },
    deleteLeadsData: (state, action) => {
      state.addleadsLoader = true;
      console.log("addLeadsData slice", action.payload);
    },
    fetchDeletedLeadsData: (state, action) => {
      console.log("fetchAddLeadsData slice", action.payload);
      state.addleadsLoader = false;
      state.leadDeletedData = action.payload;
    },
    clearData: (state, action) => {
      console.log("Clear data");
      state.addleadsLoader = false;
      state.addLeadsData = {};
      state.leadDeletedData = {};
    },
    resendInvitationDetails: (state, action) => {
      state.sendInvitationListLoader = true;
    },
    setResendInvitationDetails(state, action) {
      state.resendInvitationDetails = action?.payload;
      state.sendInvitationListLoader = false;
    },
  },
});
export const addLeadsAction = addLeads.actions;
export default addLeads.reducer;
