/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from "react";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import useTimer from "../hooks/useTimer";
import { Loader } from "../common/loader";
import PersonalTokenScreen from "../common/OTPScreen";
import { censorEmail, getObjLength } from "../helpers/utils";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";

const validationSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  password: Yup.string("Please enter Password.")
    .required("Password is required")
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
  // .matches(
  //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //   "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
  // ),

  // phone1: Yup.string("PLease enter your Phone number.")
  //   .typeError("That doesn't look like a phone number")
  //   // .positive()
  //   .matches(/^[0-9]{10}$/, "Please enter valid phone number. Ex:1234567890")
  //   .required("Mobile number is required")
  //   .max(10, "Mobile number is not more then 10 digits"),
});

const ResumeProcess = ({ setOpenPopup, resumetheFlow }) => {
  const dispatch = useDispatch();
  const resumeLoginFormRef = useRef();
  const captchaRef = useRef();

  const [otpErr, SetOTPErr] = useState("");
  const [resumeAPIErr, SetResumeAPIErr] = useState("");
  const [otpSucess, SetOTPSucess] = useState("");
  const [otpScreen, SetOTPScreen] = useState("");
  const [emailOTPScreen, SetEmailOTPScreen] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const [phnVal, setPhnVal] = useState("");
  const [phnOTP, setPhnOTP] = useState("");
  const [emailOTP, setEmailOTP] = useState("");

  //initializes the timer states for hook
  const { stopTimer } = useTimer(120);

  const {
    resumeProcessData,
    resumeProcessLoader,
    resendEmailOtpResponse,
    emailOTPVerificationResponse,
    resumeProcessResponse,
  } = useSelector((state) => state.signUpUser);

  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );

  const onFormSubmit = (values, errors) => {
    console.log("values=>", values);
    // setEmailVal(values.email);
    // setPhnVal(values.phone1?.substring(values.phone1?.length - 4));
    // SetOTPScreen(true);
    let data = { ...values, check_last_step: 1 };
    console.log("final data==>>", data);
    dispatch(
      userSigupAction.userVerifyResumeEmail({
        url: "login",
        data: data,
      })
    );
  };

  const resendOTPSubmit = () => {
    console.log("resendOTPSubmit==>>");
    let inputParam = {
      email: emailVal,
    };
    // dispatch(
    //     userSigupAction.resedEmailOTP({
    //       url: "resend-otp/email",
    //       data: inputParam,
    //     })
    //   );
  };

  const onEmailSubmit = (otp) => {
    setEmailOTP(otp);
    let inputParam = {
      verification_code: otp,
      //   ...userCredentials,
    };
    console.log(otp, "on submit==>.", inputParam);
    // dispatch(userSigupAction.clearResumeProcessData());
    // dispatch(
    //   userSigupAction.userVerifyEmailOTP({
    //     url: "get-users-last-step",
    //     data: inputParam,
    //   })
    // );
  };
  const onMobilesubmit = (otp) => {
    setPhnOTP(otp);
    SetEmailOTPScreen(true);
    let inputParam = {
      verification_code: otp,
      //   ...userCredentials,
    };
    console.log(otp, "on submit==>.", inputParam);
    // dispatch(userSigupAction.clearResumeProcessData());
    // dispatch(
    //   userSigupAction.userVerifyEmailOTP({
    //     url: "get-users-last-step",
    //     data: inputParam,
    //   })
    // );
  };
  const removeResendMsg = () => {
    SetOTPSucess("");
  };
  //   const phoneNumber = "56788888";
  //   const phn_num1 = phoneNumber?.substring(phoneNumber?.length - 4);
  const FieldStyle = {
    borderRadius: "3px",
  };

  const validatedEmail = () => {
    if (!resumeProcessData.success || !resendEmailOtpResponse?.success) {
      SetOTPErr(
        resumeProcessData?.data?.message ||
          resendEmailOtpResponse?.data?.message
      );
      SetOTPSucess("");
    } else {
      SetOTPErr("");
    }
  };

  useEffect(() => {
    if (getObjLength(resumeProcessData) && !resumeProcessData.success) {
      validatedEmail();
    }
  }, [resumeProcessData]);
  useEffect(() => {
    if (
      getObjLength(emailOTPVerificationResponse) &&
      emailOTPVerificationResponse.success
    ) {
      SetOTPScreen(true);
    }
  }, [emailOTPVerificationResponse]);
  useEffect(() => {
    if (getObjLength(resendEmailOtpResponse)) {
      if (resendEmailOtpResponse?.success) {
        SetOTPSucess(resendEmailOtpResponse?.message);
        SetOTPErr("");
      } else {
        validatedEmail();
      }
    }
  }, [resendEmailOtpResponse]);

  useEffect(() => {
    if (getObjLength(resumeProcessResponse)) {
      if (!resumeProcessResponse.success) {
        SetResumeAPIErr(resumeProcessResponse?.data?.message);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        resumeLoginFormRef?.current?.setSubmitting(false);
      } else {
        SetResumeAPIErr("");
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        localStorage.removeItem("CAPTCHA_Validate");
        setOpenPopup(false);
        resumetheFlow();
        stopTimer();
      }
    }
  }, [resumeProcessResponse]);

  // useEffect(() => {
  //   if (
  //     getObjLength(checkReCAPTCHADetails) &&
  //     checkReCAPTCHADetails?.success &&
  //     formValues
  //   ) {
  //     if (localStorage.getItem("CAPTCHA_Validate")) {
  //       onFormSubmit(formValues);
  //     }
  //   }
  // }, [checkReCAPTCHADetails]);

  console.log("phone otp==>.", phnOTP, "=== emal otp===>", emailOTP);
  return (
    <>
      <Box className="text-center otpBoxWrapper">
        {!otpScreen ? (
          <Box className="accountBoxWrapper ">
            <Typography align="center" variant="h3">
              Resume where you left off
            </Typography>
            <Typography className="para" align="center" variant="body1">
              To resume the signup process Please enter your Email and Password
              you've enterd during creating account.
            </Typography>
            <Formik
              className="text-center"
              enableReinitialize
              validateOnChange
              innerRef={resumeLoginFormRef}
              initialValues={{
                email: "",
                password: "",
                // phone: "",
                // phone1: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                // if (!recaptchaToken) {
                //   return;
                // }
                // localStorage.setItem("CAPTCHA_Validate", 1);
                // dispatch(
                //   userSigupAction.checkreCAPTCHA({
                //     url: `validate-captcha?token=${recaptchaToken}`,
                //     data: {},
                //   })
                // );
                // setFormValues(values);

                onFormSubmit(values);
              }}
            >
              {({ values, setFieldValue, touched, errors, isSubmitting }) => {
                return (
                  <Form>
                    <Grid
                      className="d-flex justify-content-center"
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12} md={10} px={1} pb={1} pt={2}>
                        <TextField
                          className="sign-textField "
                          // id="outlined-basic"
                          label="Email Address"
                          name="email"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                            shrink: true,
                          }}
                          placeholder={"Email Address"}
                          value={values?.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                          onBlur={(event) =>
                            setFieldValue("email", event.target.value)
                          }
                          helperText={touched.email && errors.email}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>
                      <Grid xs={12} md={10} px={1} pb={1} pt={2}>
                        <TextField
                          className="sign-textField "
                          // id="outlined-basic"
                          label="Password"
                          type="password"
                          name="password"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                            shrink: true,
                          }}
                          placeholder={"Password"}
                          value={values?.password}
                          onChange={(event) => {
                            setFieldValue(
                              "password",
                              event.target.value?.trim()
                            );
                          }}
                          helperText={touched.password && errors.password}
                          error={touched.password && Boolean(errors.password)}
                        />
                      </Grid>

                      {/* <Grid
                        item
                        xs={12}
                        md={12}
                        className="d-flex justify-content-center my-2"
                      >
                        <FormControl className="textField captcha-field">
                          <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={handleCaptchaChange}
                            className="g-recaptcha"
                            ref={captchaRef}
                          />
                        </FormControl>
                      </Grid>*/}
                      {resumeAPIErr && (
                        <p className="text-danger text-center w-100 mb-3">
                          {resumeAPIErr}
                        </p>
                      )}
                      <Grid xs={12} md={12} px={1} pb={1}>
                        <div className="text-center createAcBtn mt-4">
                          <Button
                            // onClick={() => onClickSubmit(errors)}
                            type="submit"
                            className="next_button"
                            variant="contained"
                          >
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        ) : emailOTPScreen ? (
          <PersonalTokenScreen
            subTitle={` Please enter the 6 digit security code we have sent to your Email
            ID ${censorEmail(emailVal)}.`}
            onsubmit={onEmailSubmit}
            resendOTPSubmit={resendOTPSubmit}
            otpSucess={otpSucess}
            errOTP={otpErr}
            removeResendMsg={removeResendMsg}
          />
        ) : (
          <PersonalTokenScreen
            subTitle={` Please enter the 6 digit security code we have sent to your Mobile number
            * *** *** ${phnVal}.`}
            onsubmit={onMobilesubmit}
            resendOTPSubmit={resendOTPSubmit}
            otpSucess={otpSucess}
            errOTP={otpErr}
            removeResendMsg={removeResendMsg}
          />
        )}
      </Box>
      <Loader open={resumeProcessLoader || checkReCAPTCHALoader} />
    </>
  );
};

export default ResumeProcess;
