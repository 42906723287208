import * as React from "react";
import { useEffect } from "react";

import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import Menu from "@mui/material/Menu";
import Zoom from "@mui/material/Zoom";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Alert, Snackbar, Stack, Tooltip, Typography } from "@mui/material";

import { getLocalFormatedDate, getObjLength } from "../helpers/utils";
import { notificationActions } from "../../redux/slices/notification/notificationSlice";

const ITEM_HEIGHT = 48;

export default function NotificationMenu() {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isReadMore, setIsreadMore] = React.useState(true);
  const [selectedNotification, setSelectedNotification] = React.useState(null);
  const [showNewNotificationMsg, setShowNewNotificationMsg] =
    React.useState(null);

  // store data
  const { notificationData, isNewNotificationArrive } = useSelector(
    (state) => state?.notifications
  );
  const { StannupEmailDetails } = useSelector((state) => state?.userProfile);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 2,
      top: 4,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      background: "#FF006B",
      fontWeight: "700",
      fontSize: "11px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      color: "#FFFFFF",
      width: "20px",
      height: "20px",
      [theme.breakpoints.down("md")]: {
        right: 8,
        top: 5,
      },
    },
  }));

  // get unread notifications
  const getUnreadNotifications = (notificationArr = []) => {
    return notificationArr?.length > 0
      ? notificationArr?.filter(
          (notification) => notification?.read_at === null
        )?.length
      : 0;
  };

  // handle notification clicked
  const handleNotificationClicked = (notification) => {
    if (notification?.read_at === null && notification?.id) {
      dispatch(
        notificationActions.readNotification({
          url: `notifications/update/${notification?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle mark all as read
  const handleMarkAllAsRead = () => {
    dispatch(
      notificationActions.markAllasRead({
        url: `notifications/mark-all-as-read`,
        data: {},
        token: true,
      })
    );
  };

  // useEffect to fetch notification initially
  useEffect(() => {
    dispatch(
      notificationActions.fetchNotificationData({
        url: "notifications",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  // to reset selected notification
  useEffect(() => {
    if (open) {
      setSelectedNotification(null);
    }
  }, [open]);

  useEffect(() => {
    if (isNewNotificationArrive) {
      setShowNewNotificationMsg(true);
      dispatch(notificationActions.setIsNewNotificationArrive(false));
    }
  }, [dispatch, isNewNotificationArrive]);

  useEffect(() => {
    if (
      getUnreadNotifications(notificationData?.data) &&
      localStorage.getItem("stanupPopupCount") === "1"
    ) {
      dispatch(notificationActions?.setIsNewNotificationArrive(true));
      // dispatch(notificationActions?.setNotificationDuration(15000))
    }
  }, [dispatch, notificationData?.data]);

  return (
    <div className="px-1 px-md-3">
      <Tooltip
        title="Notifications"
        TransitionComponent={Zoom}
        placement="top"
        arrow
      >
        <IconButton
          aria-label="notification"
          id="notification-button"
          aria-controls={open ? "notification-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          disableFocusRipple
          disableRipple
        >
          <StyledBadge
            badgeContent={getUnreadNotifications(notificationData?.data)}
            color="primary"
          >
            <NotificationsNoneIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Menu
        id="notification-wrapper"
        MenuListProps={{
          "aria-labelledby": "notification-wrapper",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 8.5,
            maxWidth: "300px",
            wordBreak: "break-all",
          },
        }}
      >
        {getUnreadNotifications(notificationData?.data) > 0 && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            className="mark-all-read"
            p={1}
          >
            <span
              className="mark-all-read-text"
              onClick={() => {
                handleMarkAllAsRead();
              }}
            >
              Mark all as read
            </span>
          </Stack>
        )}
        {notificationData?.data?.length > 0 ? (
          notificationData?.data?.map((notification) => (
            <MenuItem
              key={notification?.id}
              className="notification"
              sx={{
                whiteSpace: "break-spaces",
                wordBreak: "break-word",
                px: 2,
                py: 1.5,
                backgroundColor:
                  selectedNotification?.id === notification?.id
                    ? ""
                    : notification?.read_at === null
                    ? "#6ebd8b !important"
                    : "",
              }}
              onClick={() => {
                // event.stopPropagation();
                handleNotificationClicked(notification);
                setSelectedNotification(notification);
                setIsreadMore(true);
              }}
            >
              {/* {notification?.message} */}
              {notification?.message?.length > 30 ? (
                selectedNotification?.id === notification?.id && isReadMore ? (
                  <>
                    <Stack>
                      <Typography className="notification-message">
                        {notification?.message}
                      </Typography>
                      <Typography className="notification-date">
                        {notification?.created_at
                          ? getLocalFormatedDate(notification?.created_at)
                          : getLocalFormatedDate(new Date())}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <span>
                    {`${notification?.message?.slice(0, 30)}...`}
                    <span className="notification-link">{" more"}</span>
                  </span>
                )
              ) : (
                notification?.message
              )}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={"no-data"} disabled>
            No Notification Available
          </MenuItem>
        )}
      </Menu>
      {showNewNotificationMsg
        ? ReactDOM.createPortal(
            <>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showNewNotificationMsg}
                onClose={() => {
                  setShowNewNotificationMsg(false);
                  if (
                    StannupEmailDetails?.success &&
                    StannupEmailDetails?.data?.length > 0
                  ) {
                    return;
                  } else {
                    localStorage.removeItem("stanupPopupCount");
                  }
                }}
                autoHideDuration={5000}
                key={"top center"}
                sx={{ zIndex: "99999" }}
              >
                <Alert severity="info">You have a new notification!</Alert>
              </Snackbar>
            </>,
            document.body
          )
        : null}
    </div>
  );
}
