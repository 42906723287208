import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { PopupModal } from "react-calendly";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import { getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import logo from "../../assets/logo/equity.WebP";
import icon1 from "../../assets/leadpage/icon1.WebP";
import icon2 from "../../assets/leadpage/icon2.WebP";
import icon3 from "../../assets/leadpage/icon3.WebP";
import soc2 from "../../assets/footer/soc2_logo.WebP";
import forbes from "../../assets/leadpage/forbes.WebP";
import CustomTextField from "../common/CustomTextField";
import OneIconSVG from "../common/customSVGs/OneIconSVG";
import TwoIconSVG from "../common/customSVGs/TwoIconSVG";
import FourIconSVG from "../common/customSVGs/FourIconSVG";
import ProductComparison from "../common/ProductComparison";
import usa_today from "../../assets/leadpage/usa_today.WebP";
import ThreeIconSVG from "../common/customSVGs/ThreeIconSVG";
import lock_icon from "../../assets/leadpage/lock_icon.WebP";
import bulb_icon from "../../assets/leadpage/bulb_icon.WebP";
import msnbc_logo from "../../assets/leadpage/msnbc-logo.png";
import BBB2 from "../../assets/leadpage/family_img_bedge.WebP";
import safety_img1 from "../../assets/leadpage/safety_img1.WebP";
import safety_img2 from "../../assets/leadpage/safety_img2.WebP";
import safety_img3 from "../../assets/leadpage/safety_img3.WebP";
import safety_img4 from "../../assets/leadpage/safety_img4.WebP";
import security_icon from "../../assets/leadpage/security_icon.WebP";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import family_img_bedge from "../../assets/leadpage/family_img_bedge.WebP";
import { tryPageLeadsAction } from "../../redux/slices/tryDomain/tryLeadPageSlice";
import {
  BBB_REVIEW_LINK,
  CALENDLY_MEET_URL,
  COUNTRY_CODE,
  DEFAULT_URL,
  RECAPTCHA_SITE_KEY,
} from "../helpers/constants";

import "../../styles/leadpage.scss";

const SERVER = process.env.REACT_APP_SERVER;

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LeadPage = () => {
  const dispatch = useDispatch();
  const leadFormRef = useRef();
  const captchaRef = useRef();
  const modalCaptchaRef = useRef();

  // states
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [isCalendlyCaptchaModalOpen, setIsCalendlyCaptchaModalOpen] =
    useState(false);
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  // store data
  const { leadPageLoader, addLeadsDetails } = useSelector(
    (state) => state?.tryLeadPage
  );
  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );

  const handleSubmit = (values) => {
    dispatch(
      tryPageLeadsAction.addLead({
        url: "create-lead",
        data: values,
        token: false,
      })
    );
    if (SERVER === "production") {
      if (window && window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-11111779525/-USjCPeppawYEMWZwbIp",
          // 'event_callback': callback
        });
      }
    }
  };

  const handleButtonClick = () => {
    setIsCalendlyCaptchaModalOpen(true);
    modalCaptchaRef?.current?.reset();
    if (SERVER === "production") {
      if (window && window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-11111779525/oD_7CPSppawYEMWZwbIp",
          // 'event_callback': callback
        });
      }
    }
  };

  const handleCloseCalendlyModal = () => {
    setIsCalendlyCaptchaModalOpen(false);
    setIsCalendlyModalOpen(false);
    modalCaptchaRef?.current?.reset();
    dispatch(userSigupAction.setreCAPTCHA({}));
  };

  const handleModalCaptchaChange = (captchaValue) => {
    if (captchaValue) {
      dispatch(
        userSigupAction.checkreCAPTCHA({
          url: `validate-captcha?token=${captchaValue}`,
          data: {},
        })
      );
    }
    // if (captchaValue) {
    //   setIsCalendlyModalOpen(true);
    // }
  };

  const handleCaptchaChange = (captchaValue) => {
    setRecaptchaToken(captchaValue);
  };

  useEffect(() => {
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.data?.success &&
      formValues &&
      !isCalendlyCaptchaModalOpen
    ) {
      handleSubmit(formValues);
    }
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.data?.success &&
      isCalendlyCaptchaModalOpen
    ) {
      setIsCalendlyModalOpen(true);
    }
  }, [checkReCAPTCHADetails, isCalendlyCaptchaModalOpen]);

  useEffect(() => {
    /* Event snippet for Initial Page load conversion page  */

    if (SERVER === "production") {
      if (window && window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-11111779525/W4puCPu6k6wYEMWZwbIp",
          value: 1.0,
          currency: "USD",
        });
      }
    }
    localStorage.removeItem("scrp_hp");
    localStorage.removeItem("county-code");
  }, []);

  return (
    <>
      <div className="lead-page-content">
        <section
          className="d-flex align-items-center background_img first-section"
          // style={{ minHeight: "70vh" }}
        >
          <Container fluid className="text-white" sx={{}}>
            <Row className="justify-content-center align-items-center text-left">
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="content-spacing first-column"
              >
                <h1 className="fw-700 mb-3 main-text">
                  Is your property at risk for equity theft?
                </h1>
                <p className="paragraph_text fw-400 mb-3 mt-2">
                  More than 83 million property owners nationwide are at risk of
                  equity or property fraud. One simple call can assess your
                  risk.
                </p>
                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"column"}
                  spacing={2}
                >
                  <Button
                    className="popup_Btn how-it-works-btn mb-0"
                    onClick={() => {
                      window.location.href = `${DEFAULT_URL}how-it-works`;
                    }}
                  >
                    How it works
                  </Button>
                  <Button
                    className="popup_Btn how-it-works-btn mb-0"
                    onClick={() => {
                      window.location.href = `${DEFAULT_URL}property-risk-management`;
                    }}
                  >
                    Check now to see if you are a victim
                  </Button>
                </Stack>

                <Stack
                  className="logos d-flex justify-content-around d-flex d-md-none"
                  spacing={2}
                >
                  <Stack
                    spacing={{ xs: 2, sm: 3 }}
                    direction="row"
                    justifyContent="center"
                  >
                    <LazyLoadImage src={nbc} alt="nbc" />
                    <LazyLoadImage src={forbes} alt="forbes" />
                    <LazyLoadImage src={fox} alt="fox" />
                  </Stack>
                  <Stack
                    spacing={{ xs: 1.3, sm: 3 }}
                    direction="row"
                    justifyContent="center"
                  >
                    <LazyLoadImage src={cbs} alt="cbs" />
                    <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                    <LazyLoadImage src={usa_today} alt="usa_today" />
                    <LazyLoadImage src={cnn} alt="cnn" />
                  </Stack>
                </Stack>
                <Stack
                  spacing={2}
                  className="logos d-flex justify-content-center d-none d-md-flex"
                >
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-around"
                  >
                    <LazyLoadImage src={nbc} alt="nbc" />
                    <LazyLoadImage src={forbes} alt="forbes" />
                    <LazyLoadImage src={fox} alt="fox" />
                    <LazyLoadImage src={cbs} alt="cbs" />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-evenly"
                  >
                    <LazyLoadImage
                      src={msnbc_logo}
                      alt="msnbc_logo"
                      className="px-1"
                    />
                    <LazyLoadImage
                      src={usa_today}
                      alt="usa_today"
                      className="px-1"
                    />
                    <LazyLoadImage src={cnn} alt="cnn" className="px-1" />
                  </Stack>
                </Stack>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="content-spacing second-column"
                id="lead-page-form"
              >
                <Box className="box-container text-white">
                  <Typography className="request-call-label">
                    Is Your Home Protected?
                  </Typography>
                  <Typography className="request-call-description">
                    Fill out the form below to see if we can protect your home
                    from equity theft.
                  </Typography>
                  <Box className="request-form">
                    <Formik
                      enableReinitialize
                      validateOnChange
                      innerRef={leadFormRef}
                      initialValues={{
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                      }}
                      validationSchema={Yup.object().shape({
                        first_name: Yup.string("Please enter First name")
                          .trim()
                          .required("Please enter First name"),
                        last_name: Yup.string("Please enter Last name")
                          .trim()
                          .required("Please enter Last name"),
                        email: Yup.string("Please enter an email address")
                          .trim()
                          .email("Please enter valid email address")
                          .required("Please enter an email address"),
                        phone: Yup.string("PLease enter your Phone number.")
                          .trim()
                          .required("Phone number is required"),
                      })}
                      onSubmit={(values, validation) => {
                        if (!isValidPhoneNumber(values?.phone)) {
                          return;
                        }

                        if (!recaptchaToken) {
                          return;
                        }

                        dispatch(
                          userSigupAction.checkreCAPTCHA({
                            url: `validate-captcha?token=${recaptchaToken}`,
                            data: {},
                          })
                        );
                        setFormValues(values);

                        // handleSubmit(values, validation);
                      }}
                    >
                      {({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        isSubmitting,
                      }) => {
                        return (
                          <>
                            <Form>
                              <Grid container spacing={1.25}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <CustomTextField
                                    name="first_name"
                                    label="First Name"
                                    validation={{
                                      values,
                                      setFieldValue,
                                      touched,
                                      errors,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <CustomTextField
                                    name="last_name"
                                    label="Last Name"
                                    validation={{
                                      values,
                                      setFieldValue,
                                      touched,
                                      errors,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <CustomTextField
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    validation={{
                                      values,
                                      setFieldValue,
                                      touched,
                                      errors,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Box
                                    className={`phone-number-input ${
                                      (values?.phone &&
                                        !isValidPhoneNumber(values?.phone)) ||
                                      (touched?.phone &&
                                        errors?.phone?.length > 0)
                                        ? "phone-error"
                                        : ""
                                    }`}
                                  >
                                    <PhoneInput
                                      international={false}
                                      placeholder="Phone Number"
                                      name="phone"
                                      value={values?.phone}
                                      countries={["US", "IN"]}
                                      defaultCountry={
                                        COUNTRY_CODE === "+91" ? "IN" : "US"
                                      }
                                      countryOptionsOrder={["US", "IN"]}
                                      addInternationalOption={false}
                                      onChange={(event) => {
                                        setFieldValue("phone", event);
                                      }}
                                    />
                                  </Box>
                                  {((values?.phone &&
                                    !isValidPhoneNumber(values?.phone)) ||
                                    (touched?.phone &&
                                      errors?.phone?.length > 0) ||
                                    (!values?.phone && isSubmitting)) && (
                                    <Typography
                                      sx={{
                                        color: "#d32f2f !important",
                                        fontSize: "12px !important",
                                        margin: "0px !important",
                                      }}
                                    >
                                      Please enter a valid phone number
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className="d-flex justify-content-center my-2"
                                >
                                  {/* <FormControl className="textField captcha-field"> */}
                                  <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={handleCaptchaChange}
                                    className="g-recaptcha"
                                    ref={captchaRef}
                                  />
                                  {/* </FormControl> */}
                                </Grid>
                                {isSubmitting && !recaptchaToken && (
                                  <p className="text-danger text-center w-100 mb-1">
                                    Please verify ReCAPTCHA
                                  </p>
                                )}
                              </Grid>
                              <Stack
                                className="schedule-call-section mb-0 d-flex"
                                sx={{ flexDirection: "column" }}
                                spacing={2}
                              >
                                <Button
                                  variant="contained"
                                  type="submit"
                                  className="popup_Btn request-a-call-btn"
                                >
                                  Request a call
                                </Button>
                                <Button
                                  className="popup_Btn success_popup_btn mx-2"
                                  variant="contained"
                                  onClick={() => {
                                    handleButtonClick();
                                  }}
                                >
                                  Schedule a Meeting
                                </Button>
                                {/* <div onClick={handleButtonClick}>
                                  <PopupButton
                                    url={`${CALENDLY_MEET_URL}`}
                                    rootElement={document.getElementById(
                                      "root"
                                    )}
                                    text="Schedule a Meeting"
                                    styles={{
                                      padding: "12px 35px",
                                      fontWeight: 600,
                                      borderRadius: "29px",
                                      color: "#ffffff",
                                      border: "none",
                                      fontSize: "16px",
                                      lineHeight: "20px",
                                      letterSpacing: "0.05rem",
                                      textTransform: "uppercase",
                                      backgroundColor: "#15be53",
                                    }}
                                  />
                                </div> */}
                              </Stack>
                            </Form>
                            <Typography className="request-call-footer-description">
                              By submitting this form you are giving permission
                              for one of our agents to contact you.
                            </Typography>
                          </>
                        );
                      }}
                    </Formik>
                  </Box>
                </Box>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="d-flex align-items-center pt-150  pb-5"
          style={{ background: "#EBF3FC" }}
        >
          <Container fluid className="lead_table">
            <div className=" text-center">
              <div className="mb-5">
                <p className="notify_header">How we stand out</p>
                <h3 className="sub_header mb-4">
                  Designed to Set a New Grade in Title Security and Service
                </h3>
                <p className="subTitle mb-5">See how we stand out</p>
              </div>
            </div>
            <Row className="mt-5 mb-3 justify-content-center ">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={10}
                className="content-spacing"
              >
                <ProductComparison />
              </Col>
            </Row>
          </Container>
        </section>
        {/* 2nd section */}
        <LazyLoadComponent threshold={150}>
          <section className="second-section content-spacing bg-white">
            <Row className="mt-0 mb-0 text-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="py-5 content-spacing first-col"
              >
                <LazyLoadImage
                  src={icon1}
                  alt="US PATENTED TECHNOLOGY Icon"
                  className="mt-3"
                  width="41.68"
                  height="48px"
                />
                <p className="section-2-title">US PATENTED TECHNOLOGY</p>
                <p className="section-2-description">
                  One-of-a-kind technology developed to not just scan & monitor,
                  but more importantly, protect you from any attempts to tamper
                  with or steal your home equity and ownership.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="py-5 content-spacing second-col"
              >
                <LazyLoadImage
                  src={icon2}
                  alt="24/7 US-BASED PREMIUM SUPPORT Icon"
                  className="mt-3"
                  width="47.99px"
                  height="48px"
                />
                <p className="section-2-title">24/7 US-BASED PREMIUM SUPPORT</p>
                <p className="section-2-description">
                  Expert guidance and customer service at any hour, any day,
                  making sure your home equity and title is adequately
                  protected.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="py-5 content-spacing"
              >
                <LazyLoadImage
                  src={icon3}
                  alt="60-DAY MONEY BACK GUARANTEE Icon"
                  className="mt-3"
                  width="58px"
                  height="48px"
                />
                <p className="section-2-title">60-DAY MONEY BACK GUARANTEE</p>
                <p className="section-2-description">
                  Experience the peace of mind of our home monitoring system.
                  Try it out- if you're not completely satisfied, we offer a
                  60-day money-back guarantee.
                </p>
              </Col>
            </Row>
          </section>
        </LazyLoadComponent>

        {/* 11th section */}
        <LazyLoadComponent threshold={150}>
          <section className="eleventh-section content-spacing text-center">
            <Typography className="heading">
              Rest Easy With Complete
              <br /> Ownership Security
            </Typography>
            <Row className="mt-4 text-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing column"
              >
                <LazyLoadImage
                  src={lock_icon}
                  alt="LOCK Icon"
                  className="mt-3"
                  width="42px"
                  height="50px"
                />
                <p className="title">Take Control</p>
                <Divider className="divider" />
                <p className="description">
                  Don't let fraudsters and scam artists get the upper hand -
                  take control with EquityProtect and make sure your home
                  ownership is safe and secure. Rest Easy with Complete
                  Ownership Security
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing column"
              >
                <LazyLoadImage
                  src={bulb_icon}
                  alt="INOVATION Icon"
                  className="mt-3"
                  width="50px"
                  height="50px"
                />
                <p className="title">Never Worry Again</p>
                <Divider className="divider" />
                <p className="description">
                  With EquityProtect, you can relax knowing that you won't
                  become a victim of equity ownership fraud. Advanced
                  technologies keep a constant eye on your title so that any
                  suspicious activities are detected in real-time, allowing you
                  to take prompt action.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing column"
              >
                <LazyLoadImage
                  src={security_icon}
                  alt="Secure Your Future Icon"
                  className="mt-3"
                  width="42px"
                  height="50px"
                />
                <p className="title">Secure Your Future</p>
                <Divider className="divider" />
                <p className="description">
                  Be sure that you're the only one who owns your home, now and
                  in the future. EquityProtect provides total security so you
                  don't have to worry about the threat of equity theft.
                </p>
                <a href={BBB_REVIEW_LINK} target="_blank" rel="noreferrer">
                  <LazyLoadImage
                    src={family_img_bedge}
                    alt="family img bedge"
                    className="family-img-bedge"
                    width="150px"
                    height="80px"
                  />
                </a>
              </Col>
            </Row>
          </section>
        </LazyLoadComponent>

        {/* 7th section */}
        <LazyLoadComponent threshold={150}>
          <section className="third-section seventh-section content-spacing">
            <Typography className="primary-text">
              All-in-One Digital Safety
            </Typography>

            {/* grid of images and text */}
            <Grid container spacing={4} mb="90px">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 2, sm: 2, md: 1 }}
              >
                <Box className="d-flex align-items-center justify-content-center w-100">
                  <LazyLoadImage
                    src={safety_img1}
                    alt="safety img 1"
                    width="544px"
                    height="420px"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 1, sm: 1, md: 2 }}
                className="d-flex justify-content-center align-items-center"
              >
                <Stack direction="column">
                  <Typography className="safety-primary-text">
                    Early Warning System
                  </Typography>
                  <Typography className="safety-description">
                    Receive up-to-the-minute alerts anytime there is a change in
                    the title of your property. We're your personal private
                    guard, here to monitor your home so you don't have to.
                  </Typography>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 3, sm: 3, md: 3 }}
                className="d-flex justify-content-center align-items-center"
              >
                <Stack direction="column">
                  <Typography className="safety-primary-text">
                    Advanced Security Technologies
                  </Typography>
                  <Typography className="safety-description">
                    Utilize multiple layers of protection against criminals and
                    scams to help prevent your home from becoming a victim of
                    fraud, from monitoring rent & sale listings to detecting
                    tampering with your mortgage.
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 4, sm: 4, md: 4 }}
              >
                <Box className="d-flex align-items-center justify-content-center w-100">
                  <LazyLoadImage
                    src={safety_img2}
                    alt="safety img 2"
                    width="544px"
                    height="420px"
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 6, sm: 6, md: 5 }}
              >
                <Box className="d-flex align-items-center justify-content-center w-100">
                  <LazyLoadImage
                    src={safety_img3}
                    alt="safety img 3"
                    width="544px"
                    height="420px"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 5, sm: 5, md: 6 }}
                className="d-flex justify-content-center align-items-center"
              >
                <Stack direction="column">
                  <Typography className="safety-primary-text">
                    Risk Assessment
                  </Typography>
                  <Typography className="safety-description">
                    With real-time tracking tools to monitor your title,
                    property value, rental rates & more, you have all the
                    information you need to feel secure about your home.
                  </Typography>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 7, sm: 7, md: 7 }}
                className="d-flex justify-content-center align-items-center"
              >
                <Stack direction="column">
                  <Typography className="safety-primary-text">
                    Active Protection
                  </Typography>
                  <Typography className="safety-description">
                    Unlike our competitors, we not only alert the user when
                    there has been an issue, we also physically stop the action
                    from occurring. EquityProtect is the only technology on the
                    market that actively protects you. With our SmartPolicy, you
                    receive a form of Gap Assurance to help safeguard against
                    anything fraudulent occurring on your title.
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                order={{ xs: 8, sm: 8, md: 8 }}
              >
                <Box className="d-flex align-items-center justify-content-center w-100">
                  <LazyLoadImage
                    src={safety_img4}
                    alt="safety img 4"
                    width="544px"
                    height="420px"
                  />
                </Box>
              </Grid>
            </Grid>
          </section>
        </LazyLoadComponent>

        {/* 12th section */}
        <LazyLoadComponent threshold={150}>
          <section className="third-section fourth-section twelveth-section background_img">
            <Box className="featured-explain content-spacing">
              <Typography className="heading">
                Secure Your Future: <span>4 Simple Steps</span>
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Stack direction="column" className="bg-white box">
                    <OneIconSVG />
                    <Typography className="listing-title">
                      Setup Account
                    </Typography>
                    <Typography className="listing-description">
                      Create an account that is validated with two-factor
                      protection.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Stack direction="column" className="bg-white box">
                    <TwoIconSVG />
                    <Typography className="listing-title">
                      Add Property for Protection
                    </Typography>
                    <Typography className="listing-description">
                      Add the property or properties you want EquityProtect to
                      monitor and protect for you.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Stack direction="column" className="bg-white box">
                    <ThreeIconSVG />
                    <Typography className="listing-title">
                      Ownership Validation
                    </Typography>
                    <Typography className="listing-description">
                      We will confirm you are the owner of the property with
                      validation questions only you will know the answers to.
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Stack direction="column" className="bg-white box">
                    <FourIconSVG />
                    <Typography className="listing-title">
                      Select Plan + Begin Protection
                    </Typography>
                    <Typography className="listing-description">
                      Choose the subscription plan that best fits for you, set
                      up biometric validation so only you can access your
                      account, and sign to begin protection.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Stack alignItems="flex-end" className="d-none d-sm-flex">
                <Button
                  className="popup_Btn how-it-works-btn mt-2 mb-0"
                  onClick={() => {
                    window.location.href = `${DEFAULT_URL}pricing`;
                  }}
                >
                  View Pricing Plan
                </Button>
              </Stack>
              <Stack alignItems="center" className="d-flex d-sm-none">
                <Button
                  className="popup_Btn how-it-works-btn mt-4 mb-0"
                  onClick={() => {
                    window.location.href = `${DEFAULT_URL}pricing`;
                  }}
                >
                  View Pricing Plan
                </Button>
              </Stack>
            </Box>
          </section>
        </LazyLoadComponent>

        {/* 13th section */}
        <section className="eleventh-section therteenth-section content-spacing text-center">
          <Typography className="heading">
            Protect your home for mere pennies a day!
            <br /> Don't wait - act now!
          </Typography>

          <Box>
            <Button
              className="popup_Btn other_popup_btn"
              sx={{ border: "0px !important" }}
              onClick={() => {
                window.location.href = `${DEFAULT_URL}create-account`;
              }}
            >
              Get Protection Now
            </Button>
          </Box>
        </section>

        {/* footer */}
        <section className="lead-footer text-center">
          <Container fluid className="mx-150">
            <Link to="/">
              <Box>
                <LazyLoadImage src={logo} alt="logo" />
              </Box>
            </Link>
          </Container>
        </section>

        <LazyLoadComponent threshold={150}>
          <div className="bg-black d-flex justify-content-center">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              // direction={"horizontal"}
              gap={2}
              className="align-items-center justify-content-center my-3"
            >
              <a href={BBB_REVIEW_LINK} target="_blank" rel="noreferrer">
                <LazyLoadImage
                  className="footer_logo"
                  src={BBB2}
                  alt="bbbLogo"
                  style={{ width: "auto", height: "60px" }}
                />
              </a>

              <LazyLoadImage
                className="footer_logo"
                src={soc2}
                alt="soc2Logo"
                style={{
                  width: "auto",
                  height: "60px",
                  borderRadius: "5px",
                }}
              />
            </Stack>
          </div>
        </LazyLoadComponent>
      </div>

      {getObjLength(addLeadsDetails) ? (
        <AlertDialog
          type={addLeadsDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (addLeadsDetails?.success) {
              setFormValues(null);
              leadFormRef?.current?.resetForm();
              localStorage.removeItem("CAPTCHA_Validate");
            }
            setRecaptchaToken(null);
            dispatch(userSigupAction.setreCAPTCHA({}));
            dispatch(tryPageLeadsAction.clearData({}));
            captchaRef?.current?.reset();
            leadFormRef?.current?.setSubmitting(false);
          }}
          mesage={
            addLeadsDetails?.success
              ? addLeadsDetails?.message
              : addLeadsDetails?.data?.message
          }
          footer={false}
        />
      ) : null}

      {/* captcha modal for calendly */}
      <Dialog
        open={isCalendlyCaptchaModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCalendlyModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Verify Yourself"}</DialogTitle>
        <DialogContent>
          <Stack className="pt-0 pt-md-4">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleModalCaptchaChange}
              className="g-recaptcha"
              ref={modalCaptchaRef}
            />
          </Stack>
        </DialogContent>
      </Dialog>

      {/* calendly modal */}
      <PopupModal
        open={isCalendlyModalOpen}
        url={`${CALENDLY_MEET_URL}`}
        onModalClose={() => {
          handleCloseCalendlyModal();
        }}
        rootElement={document.getElementById("root")}
      />

      <Loader open={leadPageLoader || checkReCAPTCHALoader} />
    </>
  );
};

export default LeadPage;
