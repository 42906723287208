import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import PlainAlertMessage from "../../common/PlainAlertMessage"
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { addLeadsAction } from "../../../redux/slices/admin/addLeads/addLeadsSlice";
import { getObjLength } from "../../helpers/utils";

const validationSchema = Yup.object().shape({
  name: Yup.string("Please enter your Name.")
    .trim("The name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  phone1: Yup.string("PLease enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Mobile number is required"),
});
const editValidationSchema = Yup.object().shape({
  name: Yup.string("Please enter your Name.")
    .trim("The name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  phone1: Yup.string("PLease enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Mobile number is required"),
});
const FieldStyle = {
  borderRadius: "3px",
};
const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;
const AddLeadsForm = ({
  openPopup,
  setOpenPopup,
  closeDialogBox,
  from,
  initialData,
}) => {
  const dispatch = useDispatch();
  const {
    addleadsLoader,
    addLeadsData,
  } = useSelector((state) => state?.addLeads);
  function modalTitle() {
    return (
      <Box className="accountBoxWrapper text-start p-3">
        <Button
          onClick={() => closeDialogBox()}
          className="modal-close-btn"
          style={{
            position: "absolute",
            right: 10,
            top: 15,
            background: "transparent",
          }}
        >
          <CloseIcon sx={{ color: "#9CB8A6" }} />
        </Button>
        <Typography variant="h3">Invitation Form</Typography>
        <Typography
          variant="body1"
          className="m-0"
          sx={{ maxWidth: "100% !important" }}
        >
          Add Details of the leads you want to join EquityProtect.
        </Typography>
      </Box>
    );
  }
  function modalContent() {
    return (<>
      {!getObjLength(addLeadsData) ? (
      <Box className="d-flex justify-content-center bg-white py-4 my-3">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            name: initialData?.name || "",
            email: initialData?.email || "",
            phone: initialData?.phone || "",
            phone1: initialData?.phone || "",
            promo_code: initialData?.promocode?.name || "",
          }}
          validationSchema={
            from === "edit" ? editValidationSchema : validationSchema
          }
          onSubmit={(values, validation) => {
            if (!isValidPhoneNumber(values?.phone1)) {
              return;
            }
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue, touched, errors }) => {
            return (
              <Form className="beneficiary-form w-100">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField textField_spacing"
                      // id="outlined-basic"
                      label="Name"
                      name="name"
                      value={values?.name}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Your Name"}
                      onChange={(event) => {
                        setFieldValue("name", event.target.value);
                      }}
                      helperText={touched.name && errors.name}
                      error={touched.name && Boolean(errors.name)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField textField_spacing"
                      disabled={from === "edit" ? true : false}
                      // id="outlined-basic"
                      label="Email Address"
                      name="email"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Email Address"}
                      value={values?.email}
                      onChange={(event) => {
                        setFieldValue("email", event.target.value);
                      }}
                      onBlur={(event) =>
                        setFieldValue("email", event.target.value)
                      }
                      helperText={touched.email && errors.email}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={2} pb={1}>
                    <CustomPhoneNumberInput
                      name="phone1"
                      values={values}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      disabled={from === "edit" ? true : false}
                      otherProps={{
                        onChange: (event) => {
                          setFieldValue("phone1", event);
                          setFieldValue("phone", event);
                          if (!event) {
                            setFieldValue("phone1", "");
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      label="Promo Code"
                      name="promo_code"
                      disabled={from === "edit" ? true : false}
                      value={values?.promo_code}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Optional"}
                      onChange={(event) => {
                        setFieldValue("promo_code", event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>

                <Box className="text-end createAcBtn mt-3">
                  <Button
                    className="popup_Btn other_popup_btn m-1"
                    onClick={() => closeDialogBox()}
                  >
                    Cancel
                  </Button>
                  <Button
                    // onClick={() => onClickSubmit(errors)}
                    type="submit"
                    className="popup_Btn success_popup_btn m-1"
                    // variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Loader open={addleadsLoader} />
      </Box>
      ): <></>}
      {getObjLength(addLeadsData) ? (
        addLeadsData?.success ? (
        <PlainAlertMessage
          type="Success"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setOpenPopup(false);
            // setEditAlert(false);
            dispatch(addLeadsAction.clearData({}));
            dispatch(
              addLeadsAction.getLeadsData({
                url: "leads",
                token: true,
              })
            );
          }}
          message={addLeadsData?.message}
          footer={false}
        />
      ) : (
        <PlainAlertMessage
          type="Error"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(addLeadsAction.clearData({}));
          }}
          message={addLeadsData?.data?.message}
          footer={false}
        />
      )
    ) : null}
    </>
    );
  }

  const handleSubmit = (values, errors) => {
    console.log(from, "values=>", values);
    if (values?.promo_code?.length === 0) {
      delete values?.promo_code;
    }
    if (from === "inital") {
      dispatch(
        addLeadsAction.addLeadsData({
          url: "leads/create",
          data: values,
          token: true,
        })
      );
    }
    if (from === "edit") {
      delete values.email;
      dispatch(
        addLeadsAction.addLeadsData({
          url: "leads/" + initialData?.id + "/edit",
          data: values,
          token: true,
        })
      );
    }
  };
  return (
    <>
      <DialogBox
        width={!getObjLength(addLeadsData)? "md": "sm"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={closeDialogBox}
        dividers={true}
        title={!getObjLength(addLeadsData) && modalTitle()}
        content={modalContent()}
        // footer={modalFooter()}
      />
    </>
  );
};

export default AddLeadsForm;
