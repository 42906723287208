import React from "react";

const ViewIconSVG = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_422_1278"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_422_1278)">
          <path
            d="M10 13.146C10.8747 13.146 11.6177 12.8403 12.229 12.229C12.8403 11.6177 13.146 10.8747 13.146 9.99999C13.146 9.12533 12.8403 8.38233 12.229 7.77099C11.6177 7.15966 10.8747 6.85399 10 6.85399C9.12534 6.85399 8.38234 7.15966 7.77101 7.77099C7.15967 8.38233 6.85401 9.12533 6.85401 9.99999C6.85401 10.8747 7.15967 11.6177 7.77101 12.229C8.38234 12.8403 9.12534 13.146 10 13.146ZM10 12C9.44467 12 8.97234 11.8057 8.58301 11.417C8.19434 11.0277 8.00001 10.5553 8.00001 9.99999C8.00001 9.44466 8.19434 8.97233 8.58301 8.58299C8.97234 8.19433 9.44467 7.99999 10 7.99999C10.5553 7.99999 11.0277 8.19433 11.417 8.58299C11.8057 8.97233 12 9.44466 12 9.99999C12 10.5553 11.8057 11.0277 11.417 11.417C11.0277 11.8057 10.5553 12 10 12ZM10 15.583C8.12534 15.583 6.42034 15.076 4.88501 14.062C3.35034 13.0487 2.20801 11.6947 1.45801 9.99999C2.20801 8.30533 3.35034 6.95133 4.88501 5.93799C6.42034 4.92399 8.12534 4.41699 10 4.41699C11.8747 4.41699 13.5797 4.92399 15.115 5.93799C16.6497 6.95133 17.792 8.30533 18.542 9.99999C17.792 11.6947 16.6497 13.0487 15.115 14.062C13.5797 15.076 11.8747 15.583 10 15.583ZM10 14.5C11.5553 14.5 12.9927 14.0973 14.312 13.292C15.632 12.486 16.646 11.3887 17.354 9.99999C16.646 8.61133 15.632 7.51399 14.312 6.70799C12.9927 5.90266 11.5553 5.49999 10 5.49999C8.44467 5.49999 7.00734 5.90266 5.68801 6.70799C4.36801 7.51399 3.35401 8.61133 2.64601 9.99999C3.35401 11.3887 4.36801 12.486 5.68801 13.292C7.00734 14.0973 8.44467 14.5 10 14.5Z"
            fill="#15BE53"
          />
        </g>
      </svg>
    </>
  );
};

export default ViewIconSVG;
