import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { Loader } from "../common/loader";
import AddSpouseModal from "./AddSpouseModal";
import SpouseListTable from "./SpouseListTable";
import { getObjLength } from "../helpers/utils";
import PaymentFailed from "../userProfile/Dashboard/PaymentFailed";
import { spouseAction } from "../../redux/slices/spouse/spouseSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";

import "../admin/AdminDashboard/propertyTable.scss";

const Spouse = () => {
  const dispatch = useDispatch();
  const [isAddSpouseModalOpen, setIsAddSpouseModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);

  // store data
  const {
    loginAsSpouse: isSecondaryUser,
    paymnetDoneDetails,
    isPaymentPending,
    paymentLoader,
  } = useSelector((state) => state?.userProfile);

  // handle add sopouse modal close
  const handleClose = () => {
    setIsAddSpouseModalOpen(false);
  };

  // handle edit spouse in add spouse modal
  const handleEditSpouse = (item) => {
    setSelectedItem(item);
    setIsAddSpouseModalOpen(true);
  };

  //   handle add/edit save profesional
  const handleSubmitSpouseForm = (values) => {
    if (selectedItem && selectedItem?.user_id) {
      // handle edit dispatch
      dispatch(
        spouseAction?.addSpouseDetails({
          url: `edit-sub-user/${selectedItem?.user_id}`,
          data: values,
          token: true,
        })
      );
    } else {
      // handle add dispatch
      dispatch(
        spouseAction?.addSpouseDetails({
          url: `add-sub-user`,
          data: values,
          token: true,
        })
      );
    }
  };

  useEffect(() => {
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Allow Access
        </Typography>
        {!isSecondaryUser && (
          <>
            <Button
              variant="contained"
              className="success-btn d-none d-sm-block"
              onClick={() => {
                setSelectedItem(null);
                setIsAddSpouseModalOpen(true);
              }}
            >
              Add Access
            </Button>
            <Button
              className="success-btn d-flex d-sm-none"
              variant="outlined"
              onClick={() => {
                setSelectedItem(null);
                setIsAddSpouseModalOpen(true);
              }}
              startIcon={<AddOutlinedIcon />}
              sx={{ color: "#FFF" }}
            >
              Add
            </Button>
          </>
        )}
      </Box>
      <Box>
        <SpouseListTable
          handleEditButton={handleEditSpouse}
          setIsAddSpouseModalOpen={setIsAddSpouseModalOpen}
        />
      </Box>
      <AddSpouseModal
        open={isAddSpouseModalOpen}
        handleClose={handleClose}
        selectedItem={selectedItem}
        onSubmit={handleSubmitSpouseForm}
        isAdmin={false}
      />
      {/* Paymnet failed Popup */}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
        )}
      <Loader open={paymentLoader} />
    </>
  );
};

export default Spouse;
