import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { Loader } from "./loader";
import AlertDialog from "./AlertDialog";
import { ROLES } from "../helpers/constants";
import { getObjLength } from "../helpers/utils";
import TaxComp from "./adminViewPropertyTabsComponents/TaxComp";
import GeneralComp from "./adminViewPropertyTabsComponents/GeneralComp";
import PostCardTab from "./adminViewPropertyTabsComponents/PostCardTab";
import DocumentComp from "./adminViewPropertyTabsComponents/DocumentComp";
import OwnerInfoTab from "./adminViewPropertyTabsComponents/OwnerInfoTab";
import FinanceInfoTab from "./adminViewPropertyTabsComponents/FinanceInfoTab";
import LocationInfoTab from "./adminViewPropertyTabsComponents/LocationInfoTab";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import AdminOrderDocumnet from "../userProfile/OrderDocuments/AdminOrderDocumnet";
import AssignToPeopleTab from "./adminViewPropertyTabsComponents/AssignToPeopleTab";
import ForeClosureInfoTab from "./adminViewPropertyTabsComponents/ForeClosureInfoTab";
import AdminSubscriptionHistory from "./userViewPropertyTabsComponents/SubscriptionHistory";
import { propertyListAction } from "../../redux/slices/admin/admin-dashboard/propertyListSlice";

import "./common.scss";
import { subscriptionAction } from "../../redux/slices/subscription/subscriptionSlice";

const tabContent = [
  {
    label: "General",
    value: 1,
    component: (
      <>
        <GeneralComp />
      </>
    ),
  },
  {
    label: "Documents",
    value: 2,
    component: (
      <>
        <DocumentComp role={ROLES.admin} />
      </>
    ),
  },
  {
    label: "Owners",
    value: 3,
    component: (
      <>
        <OwnerInfoTab />
      </>
    ),
  },
  {
    label: "Location",
    value: 4,
    component: (
      <>
        <LocationInfoTab />
      </>
    ),
  },
  {
    label: "Tax",
    value: 5,
    component: (
      <>
        <TaxComp />
      </>
    ),
  },
  {
    label: "Finance",
    value: 6,
    component: (
      <>
        <FinanceInfoTab />
      </>
    ),
  },
  {
    label: "Foreclosure",
    value: 7,
    component: (
      <>
        <ForeClosureInfoTab />
      </>
    ),
  },
  {
    label: "Post Card Status",
    value: 8,
    component: (
      <>
        <PostCardTab />
      </>
    ),
  },
  {
    label: "Subscription History",
    value: 9,
    component: (
      <>
        <AdminSubscriptionHistory />
      </>
    ),
  },
  {
    label: "Assign To People",
    value: 10,
    component: (
      <>
        <AssignToPeopleTab />
      </>
    ),
  },
];

export const StyledSubTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    background-color: white;
    border-radius: 5px 5px 0 0;
    color: #0395ff;
    border-bottom: 2px solid #d7dfe7;
    text-transform: none;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-top: 1px solid #d7dfe7;
    border-left: 1px solid #d7dfe7;
    border-right: 1px solid #d7dfe7;
    border-bottom: none; /* not working */
    line-height: 18px;
    color: #0d120b;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-scroller {
    overflow-x: auto !important;
  }

  .MuiTabs-scroller::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminPropertyViewDetailsModal = ({
  open,
  selectedProperty,
  propertyDetailLoader,
  handleClose,
  F,
}) => {
  const dispatch = useDispatch();

  // state data
  const [value, setValue] = useState(1);
  const [isOrderDocModalOpen, setIsOrderDocModalOpen] = useState(false);
  const [docActivStep, setDocActivStep] = useState(0);
  const [resetStates, setResetStates] = useState(0);

  // store data
  const { viewPropertyDetails, viewPropertyDetailLoader } = useSelector(
    (state) => state?.propertyLists
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // handle view property detail modal close
  const handleModalClose = () => {
    dispatch(propertiesAction.setViewDetailsPropertyData({}));
    dispatch(propertyListAction.setAdminViewPropertyDetails({}));
    handleClose();
    setValue(1);
  };

  // handle open order document modal
  const handleOpenOrderDocModal = () => {
    setIsOrderDocModalOpen(true);
  };

  // handle close order doc modal
  const handleCloseOrderDocModal = () => {
    setIsOrderDocModalOpen(false);
    setDocActivStep(0);
    setResetStates(false);
    setTimeout(() => {
      dispatch(propertiesAction.clearOrderDoc());
      dispatch(subscriptionAction.clear3dSecurePaymentData({}));
    }, 1500);
  };

  useEffect(() => {
    if (open) {
      dispatch(
        propertiesAction.getDocumentTypes({
          url: "customer/properties/document-types",
          data: "",
          token: true,
        })
      );
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (open && selectedProperty?.id) {
      dispatch(
        propertyListAction.getAdminViewPropertyDetails({
          url: `admin/view-property`,
          data: {
            id: selectedProperty?.id,
          },
          token: true,
        })
      );
    }
  }, [dispatch, open, selectedProperty?.id]);

  return (
    <>
      {getObjLength(viewPropertyDetails) && viewPropertyDetails?.success && (
        <>
          <Dialog
            open={open}
            fullWidth
            maxWidth="xl"
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="property-view-details-modal zoom-modal-wrapper"
          >
            {/* <p className="close-text" style={{top:"8px", right:"48px"}}>Close</p> */}
            <Button
              onClick={handleModalClose}
              className="modal-close-btn close-text"
              variant="link"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                background: "transparent",
                textTransform:"none"
              }}
            >
              Close
              <CloseIcon sx={{ color: "#15be53", fontSize:"2.5rem" }} />
            </Button>
            <DialogTitle id="alert-dialog-title" mt={1}>
              <Stack spacing={1}>
                <Typography className="heading-text">
                  {selectedProperty?.address
                    ? selectedProperty?.address
                    : "View Property Details"}
                </Typography>
                <Box>
                  <Button
                    variant="link"
                    className="order-document-btn d-block "
                    sx={{ position: "relative !important" }}
                    onClick={handleOpenOrderDocModal}
                  >
                    Order Documents
                  </Button>
                </Box>
              </Stack>
            </DialogTitle>
            <DialogContent className="" sx={{ px: 0, position: "relative" }}>
              {/* <Button
                variant="contained"
                className="order-document-btn d-none d-lg-block"
                onClick={handleOpenOrderDocModal}
              >
                Order Documents
              </Button> */}
              <Box className="d-block d-md-none" margin="0 10px">
                <Select
                  value={value}
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                  displayEmpty
                  className="mobile-select-tabs w-100"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {tabContent?.map((tabs, index) => (
                    <MenuItem
                      sx={{
                        color: value === index + 1 ? "#0D120B" : "#0395FF",
                        paddingLeft: value === index + 1 ? "30px" : "",
                      }}
                      value={tabs?.value}
                    >
                      {tabs?.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <StyledSubTabs
                value={value}
                scrollButtons
                onChange={handleChange}
                aria-label="basic tabs example"
                className="bg-white d-none d-md-block"
                sx={{ pl: 3, m: 0 }}
              >
                {tabContent?.map((tabs, index) => (
                  <Tab
                    value={tabs?.value}
                    label={tabs?.label}
                    {...a11yProps(index + 1)}
                  />
                ))}
              </StyledSubTabs>
              <Box sx={{ minHeight: "65vh" }}>
                {tabContent?.map((tabs, index) => (
                  <TabPanel value={value} index={index + 1}>
                    {tabs.component}
                  </TabPanel>
                ))}
              </Box>
            </DialogContent>
            <Loader open={propertyDetailLoader} />
          </Dialog>
          {/* for Order Doc */}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            // sx={DialogStyle}
            open={isOrderDocModalOpen}
            // onClose={handleCloseOrderDocModal}
            scroll="paper"
            className={`dialogWrapper step${docActivStep}`}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <AdminOrderDocumnet
              setDocActivStep={setDocActivStep}
              setDefaultStep={0}
              resetStates={resetStates}
              propertyId={selectedProperty?.id}
              userId={selectedProperty?.owner_id}
              closeModal={setIsOrderDocModalOpen}
              onClose={handleCloseOrderDocModal}
            />
          </Dialog>
        </>
      )}

      {getObjLength(viewPropertyDetails) && !viewPropertyDetails?.success && (
        <>
          <AlertDialog
            type="Error"
            openPopup={!viewPropertyDetails?.success}
            onClose={true}
            onCloseFun={() => {
              handleModalClose();
              dispatch(propertyListAction.setAdminViewPropertyDetails({}));
            }}
            mesage={viewPropertyDetails?.data?.message}
            footer={false}
          />
        </>
      )}

      <Loader open={viewPropertyDetailLoader} />
    </>
  );
};

export default AdminPropertyViewDetailsModal;
