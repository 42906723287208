import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";

import { getObjLength } from "../../helpers/utils";
import CustomTextField from "../../common/CustomTextField";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { MenuProps, USA_STATES } from "../../helpers/constants";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";
import { Loader } from "../../common/loader";

const validationSchema = Yup.object().shape({
  address: Yup.string("Please enter your Address.")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),

  city: Yup.string("Please enter your City.")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
});

const UpdateMailingAddress = ({
  handleClose,
  callListAPI,
  selectedProperty,
}) => {
  const [openPopup, SetOpenPopup] = useState(false);
  const [isSendPostcard, setIsSendPostcard] = useState(true);
  const dispatch = useDispatch();
  console.log("selectedProperty==>", selectedProperty);

  const { updateMailingAddressDetails, mailingAddressLoader } = useSelector(
    (state) => state?.propertyLists
  );

  const handleSendPostcardChecked = (e, checked, setFieldValue) => {
    setIsSendPostcard((prevState) => !prevState);
  };
  console.log("isSendPostcard==>", isSendPostcard);

  const onSubmit = (values, errors) => {
    let data = { ...values, send_postcard: isSendPostcard };
    console.log("Form data", data);

    dispatch(
      propertyListAction.getUpdateMailingAddress({
        url: `admin/properties/${selectedProperty?.user_mailing_address?.id}/update-mailing-address`,
        data,
        token: true,
      })
    );
  };

  const onPopupClose = (type) => {
    SetOpenPopup(false);
    dispatch(propertyListAction.fetchUpdateMailingAddressDetails({}));
    if (type !== "error") {
      callListAPI();
      handleClose();
    }
  };

  useEffect(() => {
    console.log("updateMailingAddressDetails==>", updateMailingAddressDetails);
    if (getObjLength(updateMailingAddressDetails)) {
      SetOpenPopup(true);
    }
  }, [updateMailingAddressDetails]);

  return (
    <>
      {!updateMailingAddressDetails?.success &&
      !updateMailingAddressDetails?.error ? (
        <>
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3" className="pb-2">
              {selectedProperty?.user_mailing_address ? `Update` : "Add"}{" "}
              Mailing Address
            </Typography>
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                address: selectedProperty?.user_mailing_address?.address || "",
                city: selectedProperty?.user_mailing_address?.city || "",
                state: selectedProperty?.user_mailing_address?.state || "",
                zip: selectedProperty?.user_mailing_address?.zip || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, touched, errors, initialValues }) => {
                return (
                  <Form>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="Address"
                          placeholder="Address"
                          name="address"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="City"
                          placeholder="City"
                          name="city"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <FormControl
                          fullWidth
                          className="sign-textField text-start"
                          error={touched.state && Boolean(errors.state)}
                        >
                          <InputLabel label="State">State</InputLabel>
                          <Select
                            value={values?.state}
                            label="State"
                            name="state"
                            variant="outlined"
                            onChange={(event) => {
                              setFieldValue("state", event.target.value);
                            }}
                            MenuProps={MenuProps}
                          >
                            {USA_STATES?.map((state) => (
                              <MenuItem value={state?.code}>
                                {state?.code}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.state && Boolean(errors.state) && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {touched.state && errors.state}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          placeholder="Zip"
                          label="Zip"
                          name="zip"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} mb={3}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={{ xs: 1, sm: 2, md: 10 }}
                          pt={2}
                        >
                          <b className="">Send Postcard?</b>
                          <Stack
                            direction="row"
                            spacing={{ xs: 1, sm: 1, md: 2 }}
                            alignItems="center"
                          >
                            <div>
                              <CustomizedCheckbox
                                checked={isSendPostcard}
                                disabled={isSendPostcard}
                                onChecked={(e) =>
                                  handleSendPostcardChecked(e, 1, setFieldValue)
                                }
                              />
                              <span>Yes</span>
                            </div>
                            <div>
                              <CustomizedCheckbox
                                checked={!isSendPostcard}
                                disabled={!isSendPostcard}
                                onChecked={(e) => {
                                  handleSendPostcardChecked(
                                    e,
                                    0,
                                    setFieldValue
                                  );
                                }}
                              />
                              <span>No</span>
                            </div>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    <div className="text-center stepBtn mt-3">
                      <Button
                        className="popup_Btn other_popup_btn mx-2"
                        type="submit"
                        variant="contained"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        disabled={
                          JSON.stringify(initialValues) ===
                          JSON.stringify(values)
                        }
                        className={`popup_Btn success_popup_btn ${
                          JSON.stringify(initialValues) ===
                            JSON.stringify(values) && "cursor-disable"
                        }`}
                        variant="contained"
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </>
      ) : null}
      {openPopup && getObjLength(updateMailingAddressDetails) && (
        <>
          {updateMailingAddressDetails?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("success")}
              message={updateMailingAddressDetails?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("error")}
              message={updateMailingAddressDetails?.data?.message}
              footer={false}
            />
          )}
        </>
      )}
      <Loader open={mailingAddressLoader} />
    </>
  );
};

export default UpdateMailingAddress;
