import { createSlice } from "@reduxjs/toolkit";

import { ROLES } from "../../../components/helpers/constants";
import { encryptPayload } from "../../../components/helpers/utils";

const intialSPSigninState = {
  signInLoader: false,
  otpLoader: false,
  authLoader: false,
  signInData: {},
  forgetPassData: {},
  resetPassData: {},
  userCredentials: {},
  resetPasswordInfo: {},
  emailForResetPass: {},
  otpVerifyDetails: {},
  signoutData: {},
  sendOTPResponse: {},
  optVerification: {},
  resendOTP: {},
  verifyFace: {},
  selectedOption: "",
};

const signinSP = createSlice({
  name: "ServiceProviderSigninDetails",
  initialState: intialSPSigninState,
  reducers: {
    resetSPSignINState: () => intialSPSigninState,
    userSignInData(state, action) {
      console.log("In slice...", action.payload);
      state.signInLoader = true;
      state.userCredentials = action.payload?.data;
      state.signInData = {};
    },
    fetchSignInSuccess(state, action) {
      console.log("Payload sucewss==>", action.payload);
      state.signInLoader = false;
      state.signInData = action.payload;
      if (action.payload.data?.role?.includes(ROLES.serviceProvider)) {
        localStorage.setItem("authToken", action.payload.data?.AccessToken);
        localStorage.setItem("authorised", action.payload.success);
        // set count for failed payment in professinoal login
        localStorage.setItem("paymentPopup", 0);
        // set count for stannup info modal
        localStorage.setItem("stanupPopupCount", 1);
      }
      localStorage.setItem("roles", encryptPayload(action.payload?.data?.role));
    },
    userForgetPassData(state, action) {
      console.log("In slice...", action.payload);
      state.signInLoader = true;
      state.forgetPassData = {};
      state.resetPassData = {};
      state.emailForResetPass = action.payload?.data;
      state.selectedOption = action.payload?.selectedOption;
    },
    fetchForgetPassData(state, action) {
      console.log(state.resetPassData, "In slice...", action.payload);
      state.signInLoader = false;
      // if (getObjLength(current(state.resetPassData))) {
      // }
      state.forgetPassData = action.payload;
    },
    userResetPassData(state, action) {
      console.log(state.forgetPassData, "In slice...", action.payload);
      state.signInLoader = true;
      state.resetPassData = {};
      state.resetPasswordInfo = action.payload?.data;
      state.forgetPassData = {};
    },
    fetchResetPassData(state, action) {
      console.log("In slice...", action.payload);
      state.signInLoader = false;
      state.resetPassData = action.payload;
    },
    clearSigninData(state, action) {
      state.signInData = {};
      state.forgetPassData = {};
      state.resetPassData = {};
      state.otpVerifyDetails = {};
    },
    clearResetPasswordData(state, action) {
      console.log("In Clear Reset Password state slice...");
      state.resetPassData = {};
      state.forgetPassData = {};
    },
    otpVerifyData(state, action) {
      console.log("In otpVerifyDetails state slice...", action.payload);
      state.otpVerifyDetails = action.payload;
    },
    userSignoutData(state, action) {
      state.signInLoader = true;
      state.signoutData = {};
    },
    fetchSignoutresponse(state, action) {
      console.log("In slice...", action.payload);
      state.signInLoader = false;
      state.signoutData = action.payload;
    },
    sendOTPData(state, action) {
      state.otpLoader = true;
      console.log("send OTP data==>>", action.payload);
      state.optVerification = {};
    },
    sendOTPResponse(state, action) {
      state.otpLoader = false;
      console.log("send OTP responese ==>>", action.payload);
      state.sendOTPResponse = action.payload;
    },
    providerVerifyOTP(state, action) {
      state.authLoader = true;
      console.log("send OTP data==>>", action.payload);
      state.optVerification = {};
    },
    verifyProviderOTPResponse(state, action) {
      state.authLoader = false;
      console.log("send OTP responese ==>>", action.payload);
      state.optVerification = action.payload;
      localStorage.setItem("authToken", action.payload.data?.AccessToken);
      localStorage.setItem("authorised", action.payload.success);
      localStorage.setItem("roles", encryptPayload(action.payload?.data?.role));
    },
    resendOTP(state, action) {
      state.authLoader = true;
      console.log("send OTP data==>>", action.payload);
      // state.optVerification={};
    },
    resendOTPResponse(state, action) {
      state.authLoader = false;
      console.log("send OTP responese ==>>", action.payload);
      state.resendOTP = action.payload;
      state.optVerification = {};
    },
    getVerifyFace(state, action) {
      state.authLoader = true;
      console.log("Verify-face==>>", action.payload);
    },
    fetchVerifyFace(state, action) {
      state.authLoader = false;
      console.log("fetch verify Face slice..", action.payload);
      state.verifyFace = action.payload;
      localStorage.setItem("authToken", action.payload.data?.AccessToken);
      localStorage.setItem("authorised", action.payload.success);
      localStorage.setItem("roles", encryptPayload(action.payload?.data?.role));
    },
  },
});
export const serviceProviderSignInAction = signinSP.actions;
export default signinSP.reducer;
