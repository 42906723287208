import { useRef } from "react";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from '@mui/material';

import { Loader } from "../common/loader";
import { getObjLength } from "../helpers/utils";
import ResetPasswordForm from "../common/ResetPasswordForm";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import SecureConnectionTemplate from "../common/secureConnectionTemplate";
import SendOTPOptionAlertDialog from "../common/SendOTPOptionAlertDialog";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetPasswordFormRef = useRef();
  const captchaRef = useRef();

  const [fromErr, setFormErr] = useState("");
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(true);
  const [selectedOption, setSelectedOption] = useState("email");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));

  const forgotPassDetails = useSelector(
    (state) => state.signInUser.forgotPasswordDetails
  );

  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );

  const validate = () => {
    handleClose();
    setFormErr(forgotPassDetails?.data?.message);
  };

  const onSubmit = (values, errors) => {
    console.log("Form data", values);
    // setFormData(values);
    dispatch(
      userSigInAction.forgotPassword({
        url: "forgot-password/" + selectedOption,
        data: values,
        selectedOption: selectedOption,
      })
    );
    handleToggle();
  };
  const handleClose = () => {
    setOpen(false);
    if (forgotPassDetails?.success) {
      setFormErr("");
      navigate("/create-new-password", { replace: true });
    }
  };
  const handleToggle = () => {
    setOpen(!open);
    console.log("forgotpass details==>.", forgotPassDetails);
  };

  const handleCaptchaChange = (captchaValue) => {
    setRecaptchaToken(captchaValue);
    dispatch(userSigInAction.forgotPasswordData({}));
    setFormErr("");
  };

  useEffect(() => {
    if (getObjLength(forgotPassDetails)) {
      if (forgotPassDetails.success) {
        handleClose();
        setRecaptchaToken(null);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        localStorage.removeItem("CAPTCHA_Validate");
      } else {
        setRecaptchaToken(null);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        resetPasswordFormRef?.current?.setSubmitting(false);
        validate();
      }
    }
  }, [forgotPassDetails]);

  useEffect(() => {
    window.onbeforeunload = function () {
      console.log("refreshed...");
      return true;
    };

    return () => {
      console.log("refreshed stop...");
      // dispatch(userSigupAction.clearData());
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.success &&
      formValues
    ) {
      if (localStorage.getItem("CAPTCHA_Validate")) {
        onSubmit(formValues);
      }
    }
  }, [checkReCAPTCHADetails]);

  function resetPass() {
    return (
      <>
        {openPopup ? (
          <SendOTPOptionAlertDialog
            openPopup={openPopup}
            setSelectedOption={setSelectedOption}
            setOpenPopup={setOpenPopup}
          />
        ) : (
          <Box className="accountBoxWrapper Form_Wrapper">
            <Typography align="center" variant="h3">
              Reset your password
            </Typography>
            <Typography align="center" variant="body1">
              Please enter your{" "}
              {selectedOption === "email" ? "Email address" : "Mobile number"}{" "}
              below. You will receive a verification code to create a password
              via {selectedOption === "email" ? "Email" : "Mobile"}.
            </Typography>
            <ResetPasswordForm
              onSubmit={handleCaptchaChange}
              selectedOption={selectedOption}
              fromErr={fromErr}
              setFormErr={setFormErr}
              goBack={setOpenPopup}
              resetPasswordFormRef={resetPasswordFormRef}
              captchaRef={captchaRef}
              recaptchaToken={recaptchaToken}
              setFormValues={setFormValues}
              handleCaptchaChange={handleCaptchaChange}
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <Grid container>
        <Grid
          xs={12}
          sm={12}
          mdOffset={2}
          md={8}
          lgOffset={2}
          lg={8}
          xlOffset={2}
          xl={8}
        >
          <SecureConnectionTemplate isPadding={isPadding} content={resetPass()} footer={true} />
        </Grid>
      </Grid>
      <Loader open={open || checkReCAPTCHALoader} />
    </>
  );
};

export default ResetPassword;
