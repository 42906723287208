/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import CustomizedRadios from "../../common/customDesignedComponents/customRadiobutton";
import TwoFactorAuth from "./TwoFactorAuth";
import BioMetric from "./BioMetricAuth";
import { useDispatch, useSelector } from "react-redux";
import { userSigInAction } from "../../../redux/slices/user/userSignInSlice";
import { Loader } from "../../common/loader";
import { encryptPayload } from "../../helpers/utils";
// import BiometricForNativeApp from "../../user-signup/biometricForNativeApp";

const Authentication = ({ isBiometric, handleBackBtn }) => {
  const [authWay, setAuthWay] = useState("mobile"); //otp
  const [errMsg, setErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [showScreen, setShowScreen] = useState("");
  const [goAuthScreens, setAuthScreens] = useState(false);

  const dispatch = useDispatch();
  const { userCredentials } = useSelector((state) => state.signInUser);

  const sendOTPResponse = useSelector(
    (state) => state.signInUser.sendOTPResponse
  );

  const params = window.location.search;
  let valuOf = params?.split("=")[0];
  let code = params?.split("=")[1];
  console.log(valuOf, "==valuOf==>", code);
  if (code === "ios" || code === "android") {
    localStorage.setItem("isMobile", encryptPayload(code));
  }

  useEffect(() => {
    console.log("send opt-->", sendOTPResponse);
    handleClose();
  }, [sendOTPResponse]);

  const selectCredit = (e) => {
    console.log("Select creditBalance", e.target.value);
    setAuthWay(e.target.value);
  };

  const goNext = (event) => {
    event.preventDefault();
    console.log("go next...", authWay);
    if (authWay === "mobile") {
      handleToggle();
      dispatch(
        userSigInAction.sendOTPData({
          url: "2fa-send-otp",
          data: userCredentials,
        })
      );
    }
    if (authWay === "email") {
      handleToggle();
      dispatch(
        userSigInAction.sendOTPData({
          url: "2fa-otp-email",
          data: { email: userCredentials?.email },
        })
      );
    }
    if (authWay === "bioMetric") {
      setAuthScreens(true);
      setShowScreen(authWay);
    }
  };
  const backToAuth = () => {
    setAuthScreens(false);
    setShowScreen("");
  };

  const handleClose = () => {
    if (authWay === "mobile" || authWay === "email") {
      if (Object.keys(sendOTPResponse).length > 0) {
        if (sendOTPResponse?.success) {
          setAuthScreens(true);
          setShowScreen("otp");
        } else {
          setErrMsg(sendOTPResponse?.data?.message);
        }
      }
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setErrMsg("");
    setOpen(!open);
  };

  useEffect(() => {
    console.log("isBiometric-->", isBiometric);
    if (!isBiometric) {
      // setAuthScreens(true);
      setShowScreen("otp");
      // dispatch(
      //   userSigInAction.sendOTPData({
      //     url: "2fa-send-otp",
      //     data: userCredentials,
      //   })
      // );
    }
  }, []);

  return (
    <>
      <Box className="accountBoxWrapper Form_Wrapper">
        {!goAuthScreens ? (
          <>
            <Typography align="center" variant="h3">
              Authentication to Log in
            </Typography>
            <Typography align="center" variant="body1">
              Please choose the suitable authentication option to log in to your
              account
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl
                sx={{ width: "100%" }}
                component={"form"}
                onSubmit={goNext}
              >
                <RadioGroup
                  row
                  defaultValue={authWay}
                  aria-labelledby="payment-types"
                  name="payment-modes"
                  onChange={selectCredit}
                >
                  {isBiometric && (
                    <Grid xs={12} sm={12} md={4} px={1} pb={1}>
                      <Paper
                        className="shadow p-3 mb-2 bg-white rounded"
                        sx={{
                          p: 2,
                          my: 1,
                          boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                          borderRadius: "4px",
                        }}
                      >
                        <FormControlLabel
                          value="bioMetric"
                          className="paymentMode w-100"
                          control={<CustomizedRadios />}
                          label={
                            <Typography
                              variant="body1"
                              className="d-flex align-items-center justify-content-between text-dark m-0 fw-600 para"
                            >
                              <span className="mode me-1">
                                BioMetric Authentication
                              </span>
                            </Typography>
                          }
                        />
                      </Paper>
                    </Grid>
                  )}
                  <Grid xs={12} sm={12} md={isBiometric ? 4 : 6} px={1} pb={1}>
                    <Paper
                      className="shadow p-3 mb-2 bg-white rounded"
                      sx={{
                        p: 2,
                        my: 1,
                        boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        value="mobile"
                        className="paymentMode w-100"
                        control={<CustomizedRadios />}
                        label={
                          <Typography
                            variant="body1"
                            className="d-flex align-items-center justify-content-between text-dark m-0 fw-600 para"
                          >
                            <span className="mode me-1">
                              Mobile Authentication
                            </span>
                          </Typography>
                        }
                      />
                    </Paper>
                  </Grid>
                  <Grid xs={12} sm={12} md={isBiometric ? 4 : 6} px={1} pb={1}>
                    <Paper
                      className="shadow p-3 mb-3 bg-white rounded"
                      sx={{
                        p: 2,
                        my: 1,
                        boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        value="email"
                        className="paymentMode w-100"
                        control={<CustomizedRadios />}
                        label={
                          <Typography
                            variant="body1"
                            className="d-flex align-items-center justify-content-between text-dark m-0 fw-600 para"
                          >
                            <span className="mode me-1">
                              Email Authentication
                            </span>
                          </Typography>
                        }
                      />
                    </Paper>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            {errMsg?.length > 0 && (
              <p className="text-danger text-center">{errMsg}</p>
            )}
            <div className="text-center stepBtn">
              <Button
                className="login_button"
                type="submit"
                variant="contained"
                onClick={goNext}
              >
                Next
              </Button>
            </div>
            <Typography m="20px 0 10px 0 !important" position="relative">
              <Button onClick={handleBackBtn}>Back</Button>
            </Typography>
          </>
        ) : showScreen.length > 0 ? (
          showScreen === "otp" ? (
            <TwoFactorAuth
              backToAuth={backToAuth}
              isBiometric={true}
              code={code}
              authWay={authWay}
            />
          ) : showScreen === "bioMetric" ? (
            // code === "ios" ? (
            //   <BiometricForNativeApp />
            // ) :
            <BioMetric backToAuth={backToAuth} code={code} />
          ) : null
        ) : null}
      </Box>
      <Loader open={open} />
    </>
  );
};

export default Authentication;
