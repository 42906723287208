import { useRef } from "react";
import { useState } from "react";
import React, { useEffect } from "react";

import { Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import ReactGoogleAutocomplete from "react-google-autocomplete";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import CallUsNow from "../../common/CallUsNow";
import CustomTextField from "../../common/CustomTextField";
import { validationSchema } from "../../user-signup/signupForm";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import LennarPublicPageFooter from "../../layout/LennarPublicPageFooter";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import LennarCreateAccountNavBar from "../../layout/LennarCreateAccountNavBar";
import {
  getAddressFromGoogleAutoComplete,
  getObjLength,
} from "../../helpers/utils";
import {
  GOOGLE_API_KEY,
  RECAPTCHA_SITE_KEY,
  USA_STATES,
} from "../../helpers/constants";

import "../../../styles/lennar-create-account.scss";

const LennarSignupForm = ({ path }) => {
  const dispatch = useDispatch();
  const signUpFormRef = useRef();
  const captchaRef = useRef();

  // states
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searched_Address, setSearched_Address] = useState({});
  const [formValues, setFormValues] = useState(null);

  const handleCaptchaChange = (captchaValue) => {
    console.log("captcha value: ", captchaValue);
    setRecaptchaToken(captchaValue);
  };
  const handleClickShowPassword = (setState) => setState((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { checkReCAPTCHADetails } = useSelector((state) => state?.signUpUser);
  const { lennarSignUpDetails } = useSelector((state) => state?.lennarSignUp);

  const addresss = (place) => {
    var address = place.formatted_address;
    console.log("Place values==?>", place);
    console.log(
      "Place values filterd==>",
      place.address_components.filter((i, k) =>
        i.types.includes("street_number")
      )
    );
    var value = address?.split(",");
    const count = value.length;
    const country = value[count - 1];
    const stateVal = value[count - 2];
    const city = value[count - 3];
    const streetAdd = value[count - 4] !== undefined ? value[count - 4] : "";
    var stateSplit = stateVal?.split(" ");
    var i = stateSplit?.length;
    var pincode = "";
    const state = stateSplit[1];
    if (i > 2) {
      pincode = stateSplit[2];
    }
    var ss = streetAdd?.split(" ");
    console.log(ss.length, "ss==>.", ss);
    const sn = place.address_components.filter((i, k) =>
      i.types.includes("street_number")
    );
    var streetNum = sn.length > 0 ? sn[0]?.long_name : "";
    const sr = place.address_components.filter((i, k) =>
      i.types.includes("route")
    );
    var site_route = sr.length > 0 ? sr[0]?.long_name : "";
    const condos = place.address_components.filter((i, k) =>
      i.types.includes("subpremise")
    );
    var units = condos.length > 0 ? condos[0]?.long_name : "";
    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    let param = {
      street_number: streetNum,
      site_route: site_route,
      city: city,
      state: state,
      zip: pincode,
      unit_no: units,
    };
    let fixParams = {
      country: country,
      latitude: latitude,
      longitude: longitude,
      address: streetAdd,
    };
    setSearched_Address({
      param: param,
      fixed_params: fixParams,
      full_address: address,
    });
    console.log(fixParams, "map lag==>>", param);
    localStorage.setItem("param", JSON.stringify(param));
    localStorage.setItem("fixParams", JSON.stringify(fixParams));
    localStorage.setItem("searched_Add", address);
    localStorage.setItem("searched_Add_popup", true);
  };

  const onSubmit = (values, errors) => {
    console.log("values=>", values);

    let data = values;

    data = {
      ...values,
      step: 0,
      step_key: "SEND_OTP",
      domain: path,
      searched_address: searched_Address,
      create_by_ep: true,
      skip_verification: false,
    };
    console.log("final data==>>", data);
    dispatch(
      lennarSignUpAction.createLennarUser({
        url: `providers/${path}/users/ep/create`,
        data: data,
      })
    );
  };

  useEffect(() => {
    console.log("cptcha called..");
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.data?.success
    ) {
      if (localStorage.getItem("CAPTCHA_Validate")) {
        onSubmit(formValues);
      }
    }
  }, [checkReCAPTCHADetails]);

  useEffect(() => {
    console.log("lennarSignUpDetails==>", lennarSignUpDetails);
    if (!lennarSignUpDetails?.success) {
      setRecaptchaToken(null);
      dispatch(userSigupAction.setreCAPTCHA({}));
      captchaRef?.current?.reset();
      signUpFormRef?.current?.setSubmitting(false);
    } else {
      captchaRef?.current?.reset();
      dispatch(userSigupAction.setreCAPTCHA({}));
      // setRecaptchaToken(null);
      localStorage.removeItem("CAPTCHA_Validate");
    }
  }, [lennarSignUpDetails]);

  return (
    <Box className="box-container">
      <Box className="bg-white">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            first_name: "",
            last_name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            email: "",
            phone: "",
            phone1: "",
            password: "",
            confirm_password: "",
          }}
          validationSchema={validationSchema}
          innerRef={signUpFormRef}
          onSubmit={(values, validation) => {
            console.log("values", values);
            if (!recaptchaToken) {
              return;
            }

            // window.grecaptcha.ready(() => {
            //   window.grecaptcha
            //     .execute(`${RECAPTCHA_SITE_KEY}`, {
            //       action: "create",
            //     })
            //     .then((token) => {
            localStorage.setItem("CAPTCHA_Validate", 1);
            dispatch(
              userSigupAction.checkreCAPTCHA({
                url: `validate-captcha?token=${recaptchaToken}`,
                data: {},
              })
            );
            setFormValues(values);
            // setScreen("subscription");
          }}
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            setTouched,
            isSubmitting,
          }) => {
            // handle selected address for sign up form
            const handleChange = (address) => {
              console.log("address changed==>", address);
              setFieldValue(
                "address",
                getAddressFromGoogleAutoComplete(address, "street_number") +
                  (getAddressFromGoogleAutoComplete(address, "street_number")
                    ? " "
                    : "") +
                  getAddressFromGoogleAutoComplete(address, "route")
              );
              setFieldValue(
                "city",
                getAddressFromGoogleAutoComplete(address, "locality")
              );
              setFieldValue(
                "state",
                getAddressFromGoogleAutoComplete(
                  address,
                  "administrative_area_level_1"
                )
              );
              setFieldValue(
                "zip",
                getAddressFromGoogleAutoComplete(address, "postal_code")
              );
            };
            return (
              <>
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="First name"
                        name="first_name"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        classes="standard-field"
                        otherProps={{
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Last name"
                        name="last_name"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        classes="standard-field"
                        otherProps={{
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} px={1} py={1}>
                      <ReactGoogleAutocomplete
                        className="lennar-googleAutoComplete mw-100"
                        apiKey={GOOGLE_API_KEY}
                        onPlaceSelected={(address) => {
                          handleChange(address);
                          addresss(address);
                        }}
                        onKeyDown={(event) =>
                          event.key === "Enter" ? event.preventDefault() : null
                        }
                        placeholder="Enter your property address"
                        options={{
                          types: ["address"],
                          componentRestrictions: { country: "us" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Address"
                        name="address"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        classes="standard-field"
                        otherProps={{
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="City"
                        name="city"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        classes="standard-field"
                        otherProps={{
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <TextField
                        id="standard-select-currency"
                        select
                        value={values?.state}
                        label="State"
                        name="state"
                        variant="standard"
                        className="standard-field w-100 standard-select-field"
                        onChange={(event) => {
                          setFieldValue("state", event.target.value);
                        }}
                        helperText={touched?.state && errors?.state}
                        error={touched?.state && Boolean(errors?.state)}
                      >
                        <MenuItem disabled value="">
                          <span style={{ color: "#00000075" }}>State</span>
                        </MenuItem>
                        {USA_STATES?.map((state) => (
                          <MenuItem value={state.name}>{state.name}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Zip"
                        name="zip"
                        type="number"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        classes="standard-field"
                        otherProps={{
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Email Address"
                        name="email"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        classes="standard-field"
                        otherProps={{
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <CustomPhoneNumberInput
                        name="phone1"
                        values={values}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        classes="standard-phone-field"
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("phone1", event);
                            setFieldValue("phone", event);
                            if (!event) {
                              setFieldValue("phone1", "");
                            }
                          },
                          variant: "standard",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <FormControl variant="standard" className="w-100">
                        <InputLabel htmlFor="standard-adornment-password">
                          Password
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Create Your Password"
                          name="password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword(setShowPassword)
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={values?.password}
                          fullWidth
                          onChange={(event) => {
                            setFieldValue("password", event.target.value);
                          }}
                          helperText={touched.password && errors.password}
                          error={touched.password && Boolean(errors.password)}
                        />
                      </FormControl>

                      {touched.password && Boolean(errors.password) && (
                        <FormHelperText
                          sx={{
                            color: "rgb(211, 47, 47) !important",
                            fontSize: "12px !important",
                            m: "0 !important",
                            maxWidth: "100% !important",
                          }}
                        >
                          {touched.password && errors.password}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}>
                      <FormControl variant="standard" className="w-100">
                        <InputLabel htmlFor="standard-adornment-password">
                          Confirm Password
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Your Password"
                          name="confirm_password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirm_password visibility"
                                onClick={() =>
                                  handleClickShowPassword(
                                    setShowConfirmPassword
                                  )
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={values?.confirm_password}
                          fullWidth
                          onChange={(event) => {
                            setFieldValue(
                              "confirm_password",
                              event.target.value
                            );
                          }}
                          helperText={
                            touched.confirm_password && errors.confirm_password
                          }
                          error={
                            touched.confirm_password &&
                            Boolean(errors.confirm_password)
                          }
                        />
                      </FormControl>

                      {touched.confirm_password &&
                        Boolean(errors.confirm_password) && (
                          <FormHelperText
                            sx={{
                              color: "rgb(211, 47, 47) !important",
                              fontSize: "12px !important",
                              m: "0 !important",
                              maxWidth: "100% !important",
                            }}
                          >
                            {touched.confirm_password &&
                              errors.confirm_password}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="d-flex justify-content-center my-2"
                    >
                      <FormControl className="textField captcha-field">
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_SITE_KEY}
                          onChange={handleCaptchaChange}
                          className="g-recaptcha"
                          ref={captchaRef}
                        />
                      </FormControl>
                    </Grid>
                    {isSubmitting && !recaptchaToken && (
                      <p className="text-danger text-center w-100 mb-3">
                        {isSubmitting && !recaptchaToken
                          ? "Please verify reCAPTCHA"
                          : "fromErr"}
                      </p>
                    )}
                    <Grid item xs={12} md={12} px={1} pb={1}>
                      <div className="text-center createAcBtn mt-2">
                        <Button
                          type="submit"
                          className="next_button"
                          variant="contained"
                        >
                          Next Step
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
        <Stack direction="row" justifyContent="center">
          <CallUsNow
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            renderText={
              <Typography className="help-text">
                Need help? Click here now
              </Typography>
            }
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default LennarSignupForm;
