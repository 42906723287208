import { useRef, useLayoutEffect, useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function DonutChart({ chart_data }) {
  console.log("chart_data", chart_data);
  const [series, setSeries] = useState(null);
  let windowWidth = window.innerWidth;

  // Creates the chart, this code only runs one time
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        innerRadius: am5.percent(35),
      })
    );

    // Create series

    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
      })
    );

    series.set(
      "colors",
      am5.ColorSet.new(root, {
        colors: [
          am5.color("#336193"),
          am5.color("#B10835"),
          am5.color("#7248EB"),
          am5.color("#FD8F51"),
          //   am5.color(0xa95a52),
          //   am5.color(0xe35b5d),
          //   am5.color(0xffa446),
        ],
      })
    );

    // var gradient = am5.RadialGradient.new(root, {
    //   stops: [
    //     { color: am5.color(0x000000) },
    //     { color: am5.color(0x000000) },
    //     {},
    //   ],
    // });

    series.slices.template.setAll({
      //   fillGradient: gradient,
      //   strokeWidth: 0,
      stroke: am5.color(0xffffff),
      //   cornerRadius: 10,
      shadowOpacity: 0.1,
      shadowOffsetX: 2,
      shadowOffsetY: 2,
      shadowColor: am5.color(0x000000),

      //   fillPattern: am5.GrainPattern.new(root, {
      //     maxOpacity: 0.2,
      //     density: 0.5,
      //     colors: [am5.color(0x000000)],
      //   }),
    });

    series.slices.template.states.create("hover", {
      shadowOpacity: 1,
      shadowBlur: 10,
    });

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create("hidden", {
      endAngle: -90,
    });

    // Set data
    series.data.setAll([
      {
        category: `Low, ${chart_data?.low_risk_number}`,
        value: chart_data?.low_risk_percent,
      },
      {
        category: `High, ${chart_data?.high_risk_number}`,
        value: chart_data?.high_risk_percent,
      },
      {
        category: `Medium-high, ${chart_data?.medium_high_risk_number}`,
        value: chart_data?.medium_high_risk_percent,
      },
      {
        category: `Medium, ${chart_data?.medium_risk_number}`,
        value: chart_data?.medium_risk_percent,
      },
    ]);

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );
    legend.markerRectangles.template.adapters.add("fillGradient", function () {
      return undefined;
    });
    legend.data.setAll(series.dataItems);

    // to hide the label around the chart
    series.ticks.template.set("forceHidden", true);
    // series.labels.template.set("forceHidden", true);

    // Format label values to float
    // series.labels.template.text = "{value.percent.toFixed(2)}%";
    series.events.on("ready", () => {
      // Loop through labels and set text
      series.labels.each((label) => {
        const dataItem = label.dataItem;
        if (
          dataItem &&
          dataItem.properties &&
          dataItem.properties.value !== undefined
        ) {
          const value = dataItem.properties.value;
          const formattedValue = (value.percent * 100).toFixed(2);
          label.set("text", `${formattedValue}%`);
        }
      });
    });

    series.appear(1000, 100);
    setSeries(series);

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 930 && series) {
      // to hide the label around the chart
      series.ticks.template.set("forceHidden", true);
      series.labels.template.set("forceHidden", true);
    } else if (series) {
      // to hide the label around the chart
      series.ticks.template.set("forceHidden", false);
      series.labels.template.set("forceHidden", false);
    }
  }, [series, windowWidth]);

  return <div id="chartdiv"></div>;
}

export default DonutChart;
