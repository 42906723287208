import React from "react";

const SupportEmailLink = ({ email, target = null, text, className, sx }) => {
  return (
    <a
      href={`mailto:${email}`}
      target={target}
      className={className}
      style={sx}
    >
      {text}
    </a>
  );
};

export default SupportEmailLink;
