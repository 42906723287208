import React, { useEffect, useState } from "react";

import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import PropertyForm from "./PropertyForm";
import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import AlertDialog from "../../common/AlertDialog";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

export default function FindProperty({
  onBtnClick,
  onChangeModalScreen,
  isByPassOptionAvailable = false,
  isAdminCreateUser = false,
  onCloseModalHandler,
  setShowPropertyListing,
  propertyValues,
  setPropertyValues,
  // setQuestionsErrMsg ,
  questionsErrMsg,
  open,
  setErrMsg,
  errMsg,
  handleClose,
  validate,
  handleToggle,
  editProperty = false,
  selectedData = {},
  address = "",
}) {
  const [otherPropertyValues, setOtherPropertyValues] = useState({});
  const [existingAdd, setExistingAdd] = useState(editProperty ? address : "");
  const [editAdd, setEditAdd] = useState({});
  const role = decryptPayload(localStorage.getItem("roles"));
  const [addressPopup, setAddressPopup] = useState(
    role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
      ? localStorage.getItem("searched_Add_popup")
      : false
  );
  console.log("addressPopup==>", addressPopup);

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const stateCodesData = useSelector(
    (state) => state.signUpUser.statesCodeList
  );
  const statesLoader = useSelector((state) => state.signUpUser.statesLoader);
  const { getPropertyData, editPropertiesDetails } = useSelector(
    (state) => state.properties
  );

  const dispatch = useDispatch();

  const handleChange = (place) => {
    setEditAdd({});
    var address = place.formatted_address;
    console.log("Place values==?>", place);
    console.log(
      "Place values filterd==>",
      place.address_components.filter((i, k) =>
        i.types.includes("street_number")
      )
    );
    var value = address?.split(",");
    const count = value.length;
    const country = value[count - 1];
    const stateVal = value[count - 2];
    const city = value[count - 3];
    const streetAdd = value[count - 4] !== undefined ? value[count - 4] : "";
    var stateSplit = stateVal?.split(" ");
    var i = stateSplit?.length;
    var pincode = "";
    const state = stateSplit[1];
    if (i > 2) {
      pincode = stateSplit[2];
    }
    var ss = streetAdd?.split(" ");
    console.log(ss.length, "ss==>.", ss);
    const sn = place.address_components.filter((i, k) =>
      i.types.includes("street_number")
    );
    var streetNum = sn.length > 0 ? sn[0]?.long_name : "";
    const sr = place.address_components.filter((i, k) =>
      i.types.includes("route")
    );
    var site_route = sr.length > 0 ? sr[0]?.long_name : "";
    const condos = place.address_components.filter((i, k) =>
      i.types.includes("subpremise")
    );
    var units = condos.length > 0 ? condos[0]?.long_name : "";

    // if (ss.length > 2) {
    //   streetNum = ss[0];
    //   site_route = ss[1] + " " + ss[2];
    // } else {
    //   streetNum = "";
    //   site_route = streetAdd;
    // }
    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    let param = {
      street_number: streetNum,
      site_route: site_route,
      city: city,
      state: state,
      zip: pincode,
      unit_no: units,
      address: streetAdd,
    };
    let fixParams = {
      country: country,
      latitude: latitude,
      longitude: longitude,
      address: streetAdd,
    };
    console.log(value, "map lag==>>", param);
    setPropertyValues(param);
    setOtherPropertyValues(fixParams);
    // setPropertyForm(true);
    setErrMsg("");
    // setQuestionsErrMsg("");
    setShowPropertyListing(false);
  };

  const gotoQuestions = (data) => {
    console.log("go to questuionss==>", data);
    // onChangeModalScreen();
    dispatch(
      propertiesAction.fetchUserProperty({
        url: "properties",
        data: {
          ...data,
        },
      })
    );
  };

  const addExistingAddress = () => {
    console.log(
      "addExistingAddress called",
      JSON.parse(localStorage.getItem("param"))
    );
    let param = JSON.parse(localStorage.getItem("param"));
    let fixParams = JSON.parse(localStorage.getItem("fixParams"));
    setPropertyValues(param);
    setOtherPropertyValues(fixParams);
    setExistingAdd(localStorage.getItem("searched_Add"));
    // setPropertyForm(true);
    setErrMsg("");
    setShowPropertyListing(false);
    setAddressPopup(false);
  };

  useEffect(() => {
    dispatch(
      userSigupAction.getStateCode({ url: "get-states-code", data: {} })
    );
  }, [dispatch]);

  useEffect(() => {
    if (getObjLength(editPropertiesDetails)) {
      if (editPropertiesDetails?.success && editProperty) {
        setEditAdd(selectedData?.data);
      }
    }
  }, [editPropertiesDetails, editProperty, selectedData?.data]);

  useEffect(() => {
    console.log("Store data 44 ==>", getPropertyData);
    if (getPropertyData?.success) {
      if (getPropertyData?.data?.length === 1) {
        dispatch(
          propertiesAction.selectedUserPropertyData(getPropertyData?.data[0])
        );
        // onBtnClick();
      } else {
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    } else {
      console.log("validate==>");
      validate();
    }
  }, [getPropertyData]);

  console.log(editProperty, "<==editProperty==selectedData==>", selectedData);

  return (
    <>
      <Box
        className="accountBoxWrapper bg-white"
        sx={{ pt: 2, pb: 0, px: "0 !important", mx: "0 !important" }}
      >
        {
          <>
            <>
              <Autocomplete
                className="googleAutoComplete mw-100"
                apiKey={GOOGLE_API_KEY}
                value={existingAdd}
                onChange={(e) => {
                  console.log("add", e);
                  setExistingAdd(e.target.value);
                }}
                onPlaceSelected={(place) => {
                  setExistingAdd(place?.formatted_address);
                  handleChange(place);
                }}
                libraries={["places"]}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "us" },
                }}
                placeholder={`Enter ${
                  role?.includes(ROLES.admin) || role?.includes(ROLES.subAdmin)
                    ? "User's"
                    : "your"
                } property address`}
              />
              <PropertyForm
                propertyValues={propertyValues}
                otherPropertyValues={otherPropertyValues}
                onBtnClick={onBtnClick}
                stateCodesData={stateCodesData}
                gotoQuestions={gotoQuestions}
                handleToggle={handleToggle}
                isAdminCreateUser={isAdminCreateUser}
                isByPassOptionAvailable={isByPassOptionAvailable}
                editProperty={editProperty}
                selectedData={editAdd}
                onCloseModalHandler={onCloseModalHandler}
              />
            </>

            {getObjLength(getPropertyData) && !getPropertyData?.success && (
              <p className="text-danger text-center p-2">{errMsg}</p>
            )}

            {questionsErrMsg?.length > 0 && (
              <p className="text-danger text-center">{questionsErrMsg}</p>
            )}
          </>
        }
      </Box>

      {addressPopup && (
        <AlertDialog
          type="Attention"
          width="sm"
          openPopup={addressPopup}
          onClose={true}
          onCloseFun={() => setAddressPopup(false)}
          mesage={
            "  Would you like to use the address you've provided with the account?"
          }
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => setAddressPopup(false)}
              >
                Nope
              </Button>
              <Button
                className="popup_Btn success_popup_btn "
                onClick={() => addExistingAddress()}
              >
                Yes
              </Button>
            </>
          }
        />
      )}

      <Loader open={statesLoader} />
    </>
  );
}
