import { put, call, takeLatest } from "redux-saga/effects";
import { deleteAPIPayload } from "../../../apis/deleteApi";
import { postAPIPayload } from "../../../apis/postApi";
import { spouseAction } from "../../slices/spouse/spouseSlice";

// fetch spouse details
export function* fetchSpouseListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  yield put(spouseAction.setSpouseListData(response));
}

// add any spouce
export function* addSpouseDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  yield put(spouseAction.setSpouseDetails(response));
}

// edit any spouce
export function* editSpouseDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  yield put(spouseAction.setEditedSpouseDetails(response));
}

// delete any spouse
export function* deleteSpouseDetailsSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);

  yield put(spouseAction.setDeletedSpouseDetails(response));
}

export function* watchSpouseSagaAsync() {
  yield takeLatest(spouseAction.fetchSpouseListData, fetchSpouseListDataSaga);
  yield takeLatest(spouseAction.addSpouseDetails, addSpouseDetailsSaga);
  yield takeLatest(spouseAction.editSpouseDetails, editSpouseDetailsSaga);
  yield takeLatest(spouseAction.deleteSpouseDetails, deleteSpouseDetailsSaga);
}
