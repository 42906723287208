import { createSlice } from "@reduxjs/toolkit";

const intialNotificationState = {
  notificationData: [],
  notificationLoader: false,
  notificationCount: 0,
  isNewNotificationArrive: false,
};

const notification = createSlice({
  name: "NotificationDetails",
  initialState: intialNotificationState,
  reducers: {
    resetNotification(state, action) {
      state.notificationData = [];
    },
    fetchNotificationData(state, action) {
      state.notificationLoader = true;
    },
    setNotificationData(state, action) {
      state.notificationData = action.payload;
      state.notificationCount =
        action.payload?.data?.length > 0
          ? action.payload?.data?.filter(
              (notification) => notification?.read_at === null
            )?.length
          : 0;
      state.notificationLoader = false;
    },
    readNotification(state, action) {
      // return console.log("action", action.payload.url.split("/")[2]);
      state.notificationLoader = true;
      // state.notificationData.data = state?.notificationData?.data?.map(
      //   (notification) => {
      //     if (notification?.id === action.payload.url.split("/")[2]) {
      //       return { ...notification, read_at: "asd" };
      //     } else {
      //       return notification;
      //     }
      //   }
      // );
    },
    setReadNotification(state, action) {
      // console.log("action.payload.data", action?.payload?.data);
      // console.log(
      //   "set read notification",
      //   state?.notificationData?.data?.map((notification) => {
      //     if (notification?.id === action.payload.data.id) {
      //       return { ...notification, read_at: action.payload?.data?.read_at };
      //     } else {
      //       return notification;
      //     }
      //   })
      // );

      state.notificationData.data = state?.notificationData?.data?.map(
        (notification) => {
          if (notification?.id === action.payload.data.id) {
            state.notificationCount = state.notificationCount - 1;
            return { ...notification, read_at: action.payload?.data?.read_at };
          } else {
            return notification;
          }
        }
      );
      state.notificationLoader = false;
    },
    markAllasRead(state, action) {
      state.markAllReadLoader = true;
    },
    setMarkAllasRead(state, action) {
      state.markAllReadLoader = false;
    },
    setIsNewNotificationArrive(state, action) {
      state.isNewNotificationArrive = action.payload;
    },
    appendLiveNotifications(state, action) {
      console.log("action", action);
      state.notificationData.data = [
        action.payload,
        ...state.notificationData.data,
      ];
      state.notificationCount = state.notificationCount + 1;
    },
  },
});
export const notificationActions = notification.actions;
export default notification.reducer;
