import React from "react";
import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../../assets/home/Vector.svg";
import logo from "../../assets/footer/equity black.png";
import { Link, NavLink } from "react-router-dom";
import help_center from "../../assets/icons/help_center.svg";
import CallUsNow from "../common/CallUsNow";
import { decryptPayload } from "../helpers/utils";

const UserAdminNavBar = ({ hideLoginURL = false }) => {
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  return (
    <>
      <Navbar className="bg-white py-3" id="adminHeader">
        <Container fluid className="px-20">
          <Navbar.Brand className="signup_logo">
            <NavLink to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}>
              <img src={logo} alt="logo" className="logo-custom-width" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <div className="supportContact d-flex align-items-center">
              {!hideLoginURL && (
                <NavLink
                  className="text-blue text-decoration-none px-3"
                  to="/login"
                >
                  Login
                </NavLink>
              )}
              <img
                src={help_center}
                alt="help_center"
                className="me-1 d-none d-sm-block"
              />
              <span className="me-1 d-none d-sm-block">Having trouble?</span>
              <CallUsNow
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                renderText={
                  <Link className="text-blue text-decoration-none" to="">
                    Contact support
                  </Link>
                }
              />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default UserAdminNavBar;
