import React, { useCallback, useEffect, useRef, useState } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { COUNTRY_CODE, COUNTY_URL, PER_PAGE } from "../../helpers/constants";
import PopoverTexts from "../../common/PopoverTexts";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  currencyFormatter,
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import DialogBox from "../../common/DialogBox";
import EditCountyDetails from "./EditCountyDetails";
import AlertDialog from "../../common/AlertDialog";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
const columns = [
  {
    key: 1,
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 5,
  },
  {
    key: 2,
    id: "logo",
    disablePadding: true,
    label: "Logo",
    minWidth: 20,
  },
  {
    key: 3,
    id: "name",
    disablePadding: true,
    label: "County Recorder",
    minWidth: 125,
  },
  {
    key: 4,
    id: "email",
    disablePadding: true,
    label: "Email",
    minWidth: 239,
  },
  {
    key: 5,
    id: "phone",
    disablePadding: true,
    label: "Phone",
    minWidth: 148,
  },
  {
    key: 5,
    id: "county",
    disablePadding: true,
    label: "County ",
    minWidth: 10,
  },
  {
    key: 6,
    id: "state",
    disablePadding: true,
    label: "State",
    minWidth: 5,
  },
  {
    key: 7,
    id: "created_at",
    disablePadding: true,
    label: "Created",
    sorting: true,
    minWidth: 115,
  },
  {
    key: 8,
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    sorting: true,
    minWidth: 115,
  },
  {
    key: 9,
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 5,
  },
];
const countyStatusOptions = [
  {
    value: 0,
    label: "Inactive",
  },
  {
    value: 1,
    label: "Active",
  },
];

function Row({
  key,
  row,
  open,
  handleMenuClick,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
}) {
  const [isTruncated, setIsTruncated] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    // Check if content exceeds 5 lines
    console.log(
      contentRef?.current?.clientHeight,
      "current content-->",
      contentRef?.current?.scrollHeight
    );
    if (contentRef?.current?.scrollHeight > contentRef?.current?.clientHeight) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [row?.description, isCollapsible]);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="left" onClick={(e) => e.stopPropagation()}>
          <span className="company-logo">
            <Avatar src={row?.logo_url} />
          </span>
        </TableCell>
        <TableCell align="left">
          {`${row?.first_name} ${row?.last_name}`}
        </TableCell>
        <TableCell align="left" sx={{ wordBreak: "break-all" }}>
          {row?.email || "-"}
        </TableCell>
        <TableCell align="left">
          {getFormatedPhoneNumber(row?.phone) || "-"}
        </TableCell>

        <TableCell align="left">{row?.county_name || "-"}</TableCell>
        <TableCell align="left">{row?.state || "-"}</TableCell>
        <TableCell align="left">
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.created_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>
        <TableCell sx={{ width: 180 }} align="left">
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleMenuClick(e, row);
              }}
              size="small"
              className="settings-button"
              aria-controls={open ? "settings-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <SettingsIconSVG />
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  County Domain
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {COUNTY_URL + row?.county_domain_name ? (
                    <a
                      href={COUNTY_URL + row?.county_domain_name}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {COUNTY_URL + row?.county_domain_name || "-"}
                    </a>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Address line 1
                </Typography>
                <Typography className="content-text m-0" component="div">
                  {row?.address || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={3}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Description
                </Typography>

                <Typography
                  className="content-text mw-380"
                  component="div"
                  ref={contentRef}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {row?.description || "-"}
                </Typography>
                {isTruncated && (
                  <Button
                    className=""
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    Read More
                  </Button>
                )}
                <PopoverTexts
                  id={key}
                  open={openPopover}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  content={row?.description}
                />
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Status
                </Typography>
                <Typography className="content-text m-0 fw-600" component="div">
                  {row?.is_active ? (
                    <span className="text-success">Active</span>
                  ) : (
                    <span className="text-danger">Inactive</span>
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  City
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.city || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Zip
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.zip || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {row?.is_statistics ? (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={1}
          ></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Low Risk
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    Numbers: {row?.low_risk_number || "-"}
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    Percentage:{" "}
                    {parseFloat(row?.low_risk_percent)?.toFixed(2) || "-"}%
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Medium Risk
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    Numbers: {row?.medium_risk_number || "-"}
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    Percentage:{" "}
                    {parseFloat(row?.medium_risk_percent)?.toFixed(2) || "-"}%
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Medium High Risk
                  </Typography>

                  <Typography className="content-text m-0" component="div">
                    Numbers: {row?.medium_high_risk_number || "-"}
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    Percentage:{" "}
                    {parseFloat(row?.medium_high_risk_percent)?.toFixed(2) ||
                      "-"}
                    %
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>

          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={5}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    High Risk
                  </Typography>

                  <Typography className="content-text m-0" component="div">
                    Numbers: {row?.high_risk_number || "-"}
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    Percentage:{" "}
                    {parseFloat(row?.high_risk_percent)?.toFixed(2) || "-"}%
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}

      {row?.plans?.find((plan, i) => plan?.name === "Monthly") && (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={1}
          ></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Plan
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <b>Monthly</b>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? currencyFormatter(
                          parseFloat(
                            row?.plans?.find(
                              (plan, i) =>
                                plan?.name === "Monthly" && plan?.is_default
                            )?.amount
                          )
                          //  +parseFloat(
                          //    row?.plans?.find(
                          //    (plan, i) =>
                          //        plan?.name === "Monthly" && plan?.is_default
                          //    )?.additional_charges?.["setup fees"] || 0
                          //  )
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Renewal Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Monthly" && !plan?.is_default
                          )?.amount
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Trial Period
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? row?.plans?.find(
                          (plan, i) =>
                            plan?.name === "Monthly" && plan?.is_default
                        )?.trial_period?.day + " days"
                      : "-"}{" "}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={3}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Setup Fees
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Monthly" && plan?.is_default
                          )?.additional_charges?.["setup fees"]
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {row?.plans?.find((plan, i) => plan?.name === "Yearly") && (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={1}
          ></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Plan
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <b>Yearly</b>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Yearly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Yearly" && plan?.is_default
                          )?.amount
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Renewal Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Yearly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Yearly" && !plan?.is_default
                          )?.amount
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Trial Period
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Yearly")
                      ? row?.plans?.find(
                          (plan, i) =>
                            plan?.name === "Yearly" && plan?.is_default
                        )?.trial_period?.day + " days"
                      : "-"}{" "}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={3}
          ></TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const CountyRecordersListTable = () => {
  const dispatch = useDispatch();
  const queryString = window.location.pathname;
  const userId = queryString.split("/")?.[2];

  // states
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedTerms, setSelectedTerms] = useState("");
  const [countyStatus, setCountyStatus] = useState("");
  const [countyId, setCountyId] = useState("");
  const [phoneNumberVal, setPhoneNumberVal] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [payload, setPayload] = useState({});
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [userID, setUserID] = useState(userId);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(8); // updated_at key for sorting
  const [editCountyData, setEditCountyData] = useState(false);
  const [editPlans, setEditPlans] = useState(false);
  const [editCharts, setEditCharts] = useState(false);
  const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] =
    useState(false);

  const open = Boolean(anchorEl);

  const {
    countyRecordersLoader,
    countyRecordersList,
    activateInactivateDetails,
    countyListDataList,
    registeredCountyListData,
  } = useSelector((state) => state.countyRecorders);

  const _DATA = countyRecordersList?.success ? countyRecordersList?.data : [];
  const count = Math.ceil(countyRecordersList?.count / PER_PAGE);

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };
  const handleMenuClick = (event, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterProperty = (e) => {
    e.preventDefault();
    console.log("countyStatus==>", countyId);
    if (selectedTerms || countyStatus || selectedDateRange || countyId) {
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        term: selectedTerms || undefined,
        status: countyStatus || undefined,
        county_id: countyId || undefined,
        from_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          selectedKey === "updated_at" || selectedDateType === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE || 10,
        offset: 0,
      }));
    }
  };

  const resetAllFilterOptions = () => {
    // if (selectedTerms || providerStatus || selectedDateRange) {
    setPage(1);
    setSelectedKey("all");
    setSelectedTerms("");
    setCountyStatus("");
    setCountyId("");
    setPayload();
    setSelectedDateRange(null);
    setDirectionFlow(true);
    setSelectedDateType("created_at");
    setSelectedColumn(8);
    setUserID(undefined);
    // }
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  const handleMenuItemClick = (event) => {
    if (event === "edit_personal_details") {
      setEditCountyData(true);
    }
    if (event === "edit_plans_details") {
      setEditPlans(true);
    }
    if (event === "edit_chart_details") {
      setEditCharts(true);
    }
    if (event === "active" || event === "inactive") {
      setIsActivateDeactivateModalOpen(true);
    }
  };

  const closeDialogBox = () => {
    setEditPlans(false);
    setEditCharts(false);
    setEditCountyData(false);
  };

  const handleActivateInactivateProvider = (selectedProvider) => {
    console.log("selectedProvider", selectedProvider);
    if (selectedProvider?.id) {
      dispatch(
        countyRecordersAction.activateInactivateCounty({
          url: `admin/county/${selectedProvider?.id}/update`,
          data: { is_active: selectedProvider?.is_active ? 0 : 1 },
          token: true,
        })
      );
    }
    setIsActivateDeactivateModalOpen(false);
  };

  const handleCountyChange = (e, newValue, setFieldValue) => {
    const selectedCounty = registeredCountyListData?.data.find(
      (county) => county.county_domain_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setSelectedTerms(selectedCounty?.id?.toString());
    }
  };

  // get listing of the property user
  const getCountyListingData = useCallback(() => {
    let URL = "";
    console.log("payload==>.", payload);
    if (userId?.length > 0) {
      URL = `admin/county/list`;
    } else {
      URL = `admin/county/list`;
    }
    dispatch(
      countyRecordersAction.countyRecordersListData({
        url: URL,
        data: {
          type: payload?.type,
          term: payload?.term,
          id: userID,
          county_id: payload?.county_id,
          is_active: payload?.status,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          modified_from_date: payload?.modified_from_date,
          modified_to_date: payload?.modified_to_date,
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          order_by_column: payload?.order_by_column || "updated_at",
          sorting_order: payload?.sorting_order || "desc",
        },
        token: true,
      })
    );
  }, [payload, dispatch]);

  useEffect(() => {
    getCountyListingData();
  }, [getCountyListingData]);

  useEffect(() => {
    dispatch(
      countyRecordersAction.registeredCountyList({
        url: `admin/counties`,
        data: {},
        token: true,
      })
    );
  }, []);
  console.log("registeredCountyListData=>", registeredCountyListData);
  return (
    <>
      {/* Filtering Component Section */}
      <Box
        id="filter"
        onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
      >
        <h3 className="filter-text">Filter your results</h3>
        <ArrowDropDownIcon
          className="filter-arrow"
          sx={{ ms: 1, fontSize: "20px" }}
        />
      </Box>
      {/* Filtering Options */}
      <Box
        sx={{
          // overflow: "hidden",
          // overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}

            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(e) => {
                        setSelectedKey(e.target.value);
                        setSelectedTerms("");
                        setCountyStatus("");
                        setCountyId("");
                        setSelectedDateRange(null);
                        if (e.target.value === "all") {
                          setSelectedDateType("created_at");
                        } else {
                          setSelectedDateType("");
                        }
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.label !== "Logo" &&
                          cell.label !== "Actions" && (
                            <MenuItem key={cell.id} value={cell.value}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "status" &&
                  selectedKey !== "send_postcard" &&
                  selectedKey !== "phone" &&
                  selectedKey !== "county" &&
                  selectedKey !== "is_customer_ep " &&
                  selectedKey !== "updated_at" &&
                  selectedKey !== "created_at" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedTerms}
                          onChange={(e) => setSelectedTerms(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Provider's Status"
                        value={countyStatus}
                        onChange={(e) =>
                          setCountyStatus(e.target.value.toString())
                        }
                      >
                        {countyStatusOptions?.map((status, key) => (
                          <MenuItem value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "county" && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      {/* <TextField
                        id="outlined-select-currency"
                        select
                        label="County List"
                        value={countyId}
                        // onChange={(e) => setCountyId(e.target.value.toString())}
                        onChange={(e) =>
                          setSelectedTerms(e.target.value.toString())
                        }
                      >
                        {registeredCountyListData?.data?.map((county, key) => (
                          <MenuItem value={county?.id}>
                            {county?.county_domain_name}
                          </MenuItem>
                        ))}
                      </TextField> */}
                      <Autocomplete
                        id="asynchronous-demo"
                        disableClearable
                        options={registeredCountyListData?.data.map(
                          (option) => option?.county_domain_name
                        )}
                        onChange={handleCountyChange}
                        renderInput={(params) => (
                          <TextField {...params} label="County List" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "phone" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Box className={`phone-number-input bg-white filter w-100`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={phoneNumberVal}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setPhoneNumberVal(event);
                          setSelectedTerms(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                {selectedKey === "all" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Updated At", value: "updated_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedKey === "all" ||
                  selectedKey === "created_at" ||
                  selectedKey === "updated_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={resetAllFilterOptions}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card
              className="d-none d-md-block filter-options-carddiv"
              // id="filter-options-card"
            >
              <Grid container className="filter-options-box">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        name="type"
                        onChange={(e) => {
                          console.log("selected==>", e.target.value);
                          setSelectedKey(e.target.value);
                          setSelectedTerms("");
                          setCountyStatus("");
                          setCountyId("");
                          setSelectedDateRange(null);
                          if (e.target.value === "all") {
                            setSelectedDateType("created_at");
                          } else {
                            setSelectedDateType("");
                          }
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"status"}>Status</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.label !== "No." &&
                            cell.label !== "Logo" &&
                            cell.label !== "Actions" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                  </Stack>
                </Grid>

                {selectedKey !== "status" &&
                  selectedKey !== "send_postcard" &&
                  selectedKey !== "phone" &&
                  selectedKey !== "county" &&
                  selectedKey !== "is_customer_ep " &&
                  selectedKey !== "updated_at" &&
                  selectedKey !== "created_at" && (
                    <Grid item>
                      <FormControl className="filter-search" variant="outlined">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedTerms}
                          onChange={(e) => setSelectedTerms(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Provider's Status"
                        value={countyStatus}
                        onChange={(e) =>
                          setCountyStatus(e.target.value.toString())
                        }
                      >
                        {countyStatusOptions?.map((status, key) => (
                          <MenuItem value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "county" && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      {/* <TextField
                        id="outlined-select-currency"
                        select
                        label="County List"
                        // value={countyId}
                        // onChange={(e) => setCountyId(e.target.value.toString())}
                        value={selectedTerms}
                        onChange={(e) =>
                          setSelectedTerms(e.target.value.toString())
                        }
                      >
                        {registeredCountyListData?.data?.map((county, key) => (
                          <MenuItem value={county?.id}>
                            {county?.county_domain_name}
                          </MenuItem>
                        ))}
                      </TextField> */}
                      <Autocomplete
                        id="asynchronous-demo"
                        disableClearable
                        options={registeredCountyListData?.data.map(
                          (option) => option?.county_domain_name
                        )}
                        onChange={handleCountyChange}
                        renderInput={(params) => (
                          <TextField {...params} label="County List" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "phone" && (
                  <Grid item>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={phoneNumberVal}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setPhoneNumberVal(event);
                          setSelectedTerms(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                {selectedKey === "all" && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Updated At", value: "updated_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "all" ||
                  selectedKey === "created_at" ||
                  selectedKey === "updated_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterProperty}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetAllFilterOptions}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>

      <Box sx={{ width: "100%", mt: 2 }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                columns={columns}
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <Row
                      key={(page - 1) * 10 + index + 1}
                      row={row}
                      open={open}
                      handleMenuClick={handleMenuClick}
                      page={page}
                      index={index}
                      handleCollapsibleRow={handleCollapsibleRow}
                      isCollapsible={
                        selectedCollapsibleRow?.id === row?.id &&
                        isCollapsibleRow
                      }
                    />
                  );
                })}
              </TableBody>
            </Table>
            {!countyRecordersList?.data?.length > 0 &&
              !countyRecordersLoader && (
                <Typography className="p-5 text-center" variant="h6">
                  No Data Found.
                </Typography>
              )}
          </TableContainer>
        </Paper>
        {countyRecordersList?.count > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
      {/* table options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {selectedRow?.is_active ? (
          <>
            {" "}
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("edit_personal_details")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <ModeOutlinedIcon />
                <Typography className="setting-menu-text">
                  Edit County Details
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("edit_chart_details")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <DonutSmallIcon />
                <Typography className="setting-menu-text">
                  {selectedRow?.is_statistics
                    ? "Edit Chart Data"
                    : "Add Chart Data"}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("edit_plans_details")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <AccountBalanceWalletOutlinedIcon />
                <Typography className="setting-menu-text">
                  Edit Plan Data
                </Typography>
              </Box>
            </MenuItem>
          </>
        ) : null}

        {!selectedRow?.is_active ? (
          <MenuItem
            key="view"
            onClick={() => handleMenuItemClick("active")}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <CheckCircleOutlineOutlinedIcon />
              <Typography className="setting-menu-text">
                Mark as Active
              </Typography>
            </Box>
          </MenuItem>
        ) : (
          <MenuItem
            key="view"
            onClick={() => handleMenuItemClick("inactive")}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <CloseOutlinedIcon />
              <Typography className="setting-menu-text">
                Mark as Inactive
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>

      {/* Edit the County's personal details */}
      {editCountyData ? (
        <DialogBox
          width={"lg"}
          fullScreenModal={true}
          contentClassName={"bg-white"}
          title={"Personal Details"}
          openPopup={editCountyData}
          setOpenPopup={setEditCountyData}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditCountyDetails
              listingAPI={getCountyListingData}
              selectedData={selectedRow}
              personalDetails={true}
              plansDetails={false}
              chartDetails={false}
              closeDialogBox={closeDialogBox}
            />
          }
        />
      ) : null}

      {/* Edit the County's plans details */}
      {editPlans ? (
        <DialogBox
          width={"lg"}
          fullScreenModal={true}
          contentClassName={"bg-white"}
          title={"Plans Details"}
          openPopup={editPlans}
          setOpenPopup={setEditPlans}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditCountyDetails
              listingAPI={getCountyListingData}
              selectedData={selectedRow}
              personalDetails={false}
              plansDetails={true}
              chartDetails={false}
              closeDialogBox={closeDialogBox}
            />
          }
        />
      ) : null}

      {/* Edit the County's chart details */}
      {editCharts ? (
        <DialogBox
          width={"lg"}
          contentClassName={"bg-white"}
          fullScreenModal={true}
          title={"Chart Details"}
          openPopup={editCharts}
          setOpenPopup={setEditCharts}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditCountyDetails
              validateEditChart={false}
              listingAPI={getCountyListingData}
              selectedData={selectedRow}
              personalDetails={false}
              plansDetails={false}
              chartDetails={true}
              closeDialogBox={closeDialogBox}
            />
          }
        />
      ) : null}

      {/* activate/deactivate the Provider */}
      {isActivateDeactivateModalOpen ? (
        <AlertDialog
          type={"Warning"}
          openPopup={isActivateDeactivateModalOpen}
          onClose={true}
          onCloseFun={() => {
            setIsActivateDeactivateModalOpen(false);
          }}
          mesage={`Are you sure you want to ${
            !selectedRow?.is_active ? `activate` : `inactivate`
          } the ${selectedRow?.county_name}  County?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setIsActivateDeactivateModalOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    handleActivateInactivateProvider(selectedRow);
                  }}
                >
                  Yes
                </Button>
              </Stack>
            </>
          }
          footer={false}
          loader={countyRecordersLoader}
        />
      ) : null}

      {/* activate deactivate provider success error modal */}
      {getObjLength(activateInactivateDetails) && (
        <AlertDialog
          type={activateInactivateDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (activateInactivateDetails?.success) {
              setIsActivateDeactivateModalOpen(false);
              getCountyListingData();
            }
            dispatch(countyRecordersAction.fetchActivateInactivateCounty({}));
          }}
          mesage={
            activateInactivateDetails?.success
              ? activateInactivateDetails?.message
              : activateInactivateDetails?.data?.message
          }
          footer={false}
        />
      )}
    </>
  );
};

export default CountyRecordersListTable;
