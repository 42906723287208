import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { IconButton, Stack, Tooltip, Typography, Zoom } from "@mui/material";

import { Loader } from "../../common/loader";
import { PER_PAGE } from "../../helpers/constants";
import {
  getFormatedPhoneNumber,
  getLocalFormatedDate,
} from "../../helpers/utils";
import { subUsersActions } from "../../../redux/slices/admin/subUsers/subUserSlice";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    id: "name",
    disablePadding: true,
    label: "Name",
    minWidth: 140,
  },
  {
    id: "email",
    disablePadding: true,
    label: "Email",
    minWidth: 280,
  },
  {
    id: "phone",
    disablePadding: true,
    label: "Phone Number",
    minWidth: 160,
  },
  {
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    minWidth: 160,
  },
  {
    id: "actions",
    disablePadding: true,
    label: "Actions",
    minWidth: 90,
  },
];

const INITIAL_PAYLOAD = {
  limit: PER_PAGE,
  offset: 0,
};

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const SubUserListTable = ({
  handleEditSubUser = () => {},
  handleOpenDeleteSubUser = () => {},
  setOffset = () => {},
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  // states
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState(INITIAL_PAYLOAD);

  // store data
  const { subUserListLoader, subUsersListdata, subUsersCount } = useSelector(
    (state) => state?.subUsers
  );
  const { selectedProperty } = useSelector((state) => state?.propertyUsers);

  console.log("params", params);

  const count = Math.ceil(subUsersCount / PER_PAGE);
  const _DATA = subUsersListdata?.data ? subUsersListdata?.data : [];

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
    setOffset((p - 1) * PER_PAGE);
  };

  const getSubUserListing = useCallback(() => {
    let URL = "";
    if (
      (params?.userId && !isNaN(params?.userId)) ||
      selectedProperty?.user_id
    ) {
      URL = `admin/sub-users/${params?.userId || selectedProperty?.user_id}`;
    } else {
      URL = `admin/sub-users`;
    }
    dispatch(
      subUsersActions.getSubUsersListData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
        },
        token: true,
      })
    );
  }, [dispatch, params?.userId, payload?.offset, selectedProperty?.user_id]);

  useEffect(() => {
    getSubUserListing();
  }, [getSubUserListing]);

  return (
    <>
      {subUsersListdata?.success ? (
        <>
          {/* Sub User/Allow Access Listing Table */}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {_DATA?.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                          <TableCell align="left">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell
                            className="text-capitalize"
                            align="left"
                          >{`${row?.first_name} ${row?.last_name}`}</TableCell>
                          <TableCell
                            align="left"
                            sx={{ wordBreak: "break-all" }}
                          >
                            {row?.email || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.phone
                              ? getFormatedPhoneNumber(row?.phone)
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.created_at
                              ? getLocalFormatedDate(row?.created_at)
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row">
                              <Tooltip
                                title="Edit"
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  sx={{
                                    color: "#0395FF",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleEditSubUser(row);
                                  }}
                                >
                                  <ModeOutlinedIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title="Delete"
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  sx={{
                                    color: "#FC2125",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleOpenDeleteSubUser(row);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!subUsersListdata?.data?.length > 0 && !subUserListLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
            {subUsersCount > PER_PAGE && (
              <Box id="pagination-container">
                <CustomPagination
                  count={count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Typography className="text-danger text-center">
          {subUsersListdata?.data?.message ||
            subUsersListdata?.message ||
            subUsersListdata?.error}
        </Typography>
      )}

      <Loader open={subUserListLoader} />
    </>
  );
};

export default SubUserListTable;
