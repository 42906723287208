/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import ImageCapture from "./image-capture";
import Info from "../../../assets/icons/info.png";

import GooglePlayeStore from "../../../assets/signin/playstore.webp";
import AppStore from "../../../assets/signin/app_store_img.webp";
import { appStoreURL, playStoreURL } from "../../helpers/constants";

const PhotoIdScreen = ({
  biometric,
  isCamera,
  isCameraResponseChecked,
  faceErr,
  backBtn,
  closeBtn,
  backToAuth,
  removeValidationMsg,
  onsubmit,
  userProfileScreen,
}) => {
  const [btnCLicked, setBtnClicked] = useState(false);
  const [picture, setPicture] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [faceRegisteredErr, setFaceRegisteredErr] = useState("");

  const webcamRef = useRef(null);

  const verifyImg = (e, action, image) => {
    console.log("action taken==>", action);
    setPicture(image);
    if (action === "back") {
      setBtnClicked(true);
    } else {
      setBtnClicked(false);
    }
    setCroppedImage(null);
    setFaceRegisteredErr("");
    removeValidationMsg();
  };

  const capture = () => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    verifyImg("", "back", pictureSrc);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log("sss==>", croppedArea, croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(picture, croppedAreaPixels);
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  });

  const finalCroppedImage = () => {
    console.log("final image==>.", croppedImage);

    // Set the Width and Height you want your resized image to be
    var width = 1920;
    var height = 1080;

    var canvas = document.createElement("canvas"); // Dynamically Create a Canvas Element
    canvas.width = width; // Set the width of the Canvas
    canvas.height = height; // Set the height of the Canvas
    var ctx = canvas.getContext("2d"); // Get the "context" of the canvas
    var img = document.getElementById("finalImage"); // The id of your image container
    ctx.drawImage(img, 0, 0, width, height); // Draw your image to the canvas

    var jpegFile = canvas.toDataURL("image/jpeg");
    console.log("img==>>>", jpegFile);
    onsubmit(jpegFile);
  };

  return (
    <>
      <Box className={`${biometric && isCamera ? "accountBoxWrapper" : ""}`}>
        {biometric && isCamera && (
          <>
            <Typography align="center" variant="h3">
              Place your face in the center
            </Typography>
            <Typography
              sx={{ maxWidth: "100% !important" }}
              align="center"
              variant="body1"
            >
              Make sure you’re in a properly lit room, and you don’t wear
              sunglasses, hats, etc
            </Typography>
          </>
        )}
        {isCamera ? (
          <>
            {!btnCLicked ? (
              <>
                <ImageCapture
                  biometric={biometric}
                  webcamRef={webcamRef}
                  picture={picture}
                />
              </>
            ) : (
              <>
                {croppedImage ? (
                  <Box>
                    <div className="cropImg_center">
                      <img
                        src={croppedImage}
                        alt="croppedImage"
                        id="finalImage"
                      />
                    </div>
                    {faceErr?.length > 0 && (
                      <p className="text-danger text-center p-3 p-0 mb-0">
                        {faceErr}
                      </p>
                    )}
                  </Box>
                ) : (
                  <>
                    <Box
                      p={2}
                      className="cropContainer"
                      // sx={
                      //   userProfileScreen
                      //     ? { height: "300px" }
                      //     : !biometric && { height: 300 }
                      // }
                    >
                      <Cropper
                        image={picture}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 3}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          isCameraResponseChecked && (
            <Box className="my-3 text-center">
              <img className="popup_icon" src={Info} alt={"info"} />
              <br />
              <Typography
                sx={{ maxWidth: "100% !important " }}
                className="fw-600  "
                variant="h6"
              >
                Attention
              </Typography>
              <br />
              <Typography
                sx={{ maxWidth: "100% !important " }}
                className=" fw-400 px-5"
                variant="h6"
              >
                Currently we can not find an active camera. For a better
                experience we suggest you download our mobile application.
              </Typography>

              <Box className="my-3">
                <div className="d-flex justify-content-center mx-3">
                  <img
                    src={GooglePlayeStore}
                    onClick={() => window.open(playStoreURL, "_blank")}
                    style={{
                      marginRight: "10px",
                      width: "100px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    alt="GooglePlayStore"
                  />
                  <img
                    src={AppStore}
                    onClick={() => window.open(appStoreURL, "_blank")}
                    alt="AppStore"
                    style={{ width: "100px", height: "30px" }}
                  />
                </div>
              </Box>
              {/* <Stack
              direction="row"
              spacing={4}
              justifyContent="center"
              mt={2}
              mb={2}
            >
              <Box
                onClick={() =>
                  window.open(`https://www.apple.com/app-store/`, "_blank")
                }
                sx={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={AppstoreSVG}
                  alt="appstore"
                  style={{ width: "50px" }}
                />
              </Box>
              <Box
                onClick={() =>
                  window.open(`https://play.google.com/store/apps`, "_blank")
                }
                sx={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={PlaystoreSVG}
                  alt="playstore"
                  style={{ width: "50px" }}
                />
              </Box>
            </Stack> */}
              <Box className="my-4">
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    backToAuth();
                  }}
                >
                  ok, All Good
                </Button>
              </Box>
            </Box>
          )
          // <PlainAlertMessage
          //   type="Info"
          //   onClose={true}
          //   onCloseFun={() => {
          //     backToAuth();
          //   }}
          //   message={
          //     "To maximize the security experience we strongly suggest the use of a camera. Currently we cannot found any active camera for biometric , you can download the mobile app ."
          //   }
          // />
        )}
      </Box>
      <Box
        className={`d-flex ${
          // (!backBtn && !closeBtn) || btnCLicked ?
          `justify-content-between`
          // : `justify-content-end`
        } align-items-center mx-4 mb-3`}
      >
        {isCamera ? (
          <>
            {btnCLicked ? (
              <>
                <Button
                  onClick={(e) => {
                    verifyImg(e, "retake");
                  }}
                  className="mb-3"
                >
                  retake
                </Button>
              </>
            ) : backBtn ? (
              <Button onClick={backToAuth}>back</Button>
            ) : !closeBtn ? (
              <Button onClick={backToAuth}>close</Button>
            ) : (
              <Button onClick={backToAuth}>Skip</Button>
            )}
            {!btnCLicked ? (
              <>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    capture();
                  }}
                  className="next_button"
                  variant="contained"
                >
                  TAKE PHOTO
                </Button>
              </>
            ) : croppedImage ? (
              <Button
                onClick={finalCroppedImage}
                className="next_button mb-3"
                variant="contained"
              >
                Verify Photo
              </Button>
            ) : (
              <Button
                onClick={showCroppedImage}
                className="next_button mb-3"
                variant="contained"
              >
                Crop Photo
              </Button>
            )}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default PhotoIdScreen;
