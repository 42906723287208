import { Box, Button, Typography } from "@mui/material";
import React from "react";
import SignatureTab from "../../common/SignatureTab";
import SignPad from "../../common/signaturePad";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import { useEffect } from "react";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";

const SignatureScreen = ({ onBtnClick }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sign, setSign] = useState(null);
  const [signUpdatedPopup, setSignUpdatedPopup] = useState(false);
  const [signErr, setSignErr] = useState("");
  const [signRegisteredErr, setSignRegisteredErr] = useState("");
  const userDataFromSession = localStorage.getItem("spSignupDetails")
    ? JSON.parse(localStorage.getItem("spSignupDetails"))
    : "";
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  console.log("userDataFromSession==>", userDataFromSession);

  const { signBase64 } = useSelector((state) => state?.signUpUser);
  const { spSignupLoader, createAccResponse, signatureRegistered } =
    useSelector((state) => state.serviceProviderSignup);

  const signatureDone = (sign) => {
    console.log("Signature==>1", sign);
    setSign(sign);
    setSignErr("");
    if (sign === undefined) {
      setSignRegisteredErr("");
    }
  };

  const removeErrors = () => {
    setSignErr("");
    setSignRegisteredErr("");
  };

  const submitSign = () => {
    console.log(signBase64, "Signature==>next", sign);
    if (sign?.length > 0 || signBase64?.length > 0) {
      setOpen(true);
      setSignErr("");
      // props.onBtnClick();
      let data = {
        signature: sign || signBase64,
        user_id: getObjLength(createAccResponse)
          ? createAccResponse.data?.user_id
          : userIdFromSession,
        step_key: "REGISTER_SIGNATURE",
        step: 4,
      };
      console.log("signature==>", data);

      dispatch(
        serviceProviderSignupAction.getRegisterSignature({
          url: "service-provider/register-signature",
          data: data,
        })
      );
    } else {
      setSignErr("Please Select or Draw a Sign to continue further.");
    }
  };

  const handleClose = () => {
    if (!signatureRegistered?.success) {
      setSignRegisteredErr(signatureRegistered?.data?.message);
    }
    if (signatureRegistered?.success) {
      dispatch(userSigupAction.clearSignBase64Value());
      onBtnClick();
      setSignUpdatedPopup(true);
    }
    setOpen(false);
  };

  useEffect(() => {
    console.log("signatureRegistered==>", signatureRegistered);
    if (Object.keys(signatureRegistered).length > 0) {
      console.log("validate 1");
      handleClose();
    }
  }, [signatureRegistered]);

  function error() {
    return (
      <>
        {(signErr?.length > 0 || signRegisteredErr?.length > 0) && (
          <Typography className="text-danger text-center">
            {signErr || signRegisteredErr}
          </Typography>
        )}
      </>
    );
  }
  return (
    <Box className="">
      <SignatureTab
        signpad={
          <SignPad
            signatureDone={signatureDone}
            submitSign={submitSign}
            error={error()}
          />
        }
        signatureDone={signatureDone}
        submitSign={submitSign}
        removeErrors={removeErrors}
        setSign={setSign}
        userName={
          userDataFromSession?.first_name + " " + userDataFromSession?.last_name
        }
        error={error()}
      />

      {/* <SignPad signatureDone={signatureDone} /> */}

      <Box className="d-flex justify-content-end align-items-center mx-md-4 my-3 px-3">
        <Button
          onClick={submitSign}
          className="next_button"
          variant="contained"
        >
          Next
        </Button>
      </Box>
      <Loader open={open || spSignupLoader} />
    </Box>
  );
};

export default SignatureScreen;
