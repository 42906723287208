import React, { useEffect, useState } from "react";

import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import PropertyForm from "./propertyForm";
import AlertDialog from "../common/AlertDialog";
import ContcatAdminSupport from "../common/ContcatAdminSupport";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { getShortNameAddressFromGoogleAutoComplete } from "../helpers/utils";

export default function FindPropertyByAddress({
  onBtnClick,
  HideSkipBtn,
  errorPopup,
  reduceErrorCount,
  setShowPropertyListing,
  propertyValues,
  setPropertyValues,
  setQuestionsErrMsg,
  questionsErrMsg,
  open,
  setErrMsg,
  errMsg,
  handleClose,
  validate,
  handleToggle,
  setPropertyListScreen,
}) {
  const [propertyForm, setPropertyForm] = useState(false);
  const [otherPropertyValues, setOtherPropertyValues] = useState({});
  const [countyAlert, setCountyAlert] = useState(false);
  const [addressPopup, setAddressPopup] = useState(
    localStorage.getItem("searched_Add_popup")
  );
  const [existingAdd, setExistingAdd] = useState("");
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const stateCodesData = useSelector(
    (state) => state.signUpUser.statesCodeList
  );
  const getPropertyData = useSelector(
    (state) => state.signUpUser.getPropertyData
  );
  const { countyData } = useSelector((state) => state?.propertyRiskManagement);
  const resumeProcessResponse = useSelector(
    (state) => state.signUpUser.resumeProcessResponse
  );

  const dispatch = useDispatch();

  useEffect(() => {
    handleToggle();
    localStorage.setItem("OwnerVerifiedScreen", "0");
    dispatch(
      userSigupAction.getStateCode({ url: "get-states-code", data: {} })
    );
    setPropertyListScreen(false);
  }, []);

  useEffect(() => {
    console.log("stateCodesData ==>", stateCodesData);
    if (Object.keys(stateCodesData).length > 0) {
      console.log("333");
      handleClose();
    }
  }, [stateCodesData]);

  //==========================================

  useEffect(() => {
    console.log("Store data 44 ==>", getPropertyData);
    if (getPropertyData?.success) {
      if (getPropertyData?.data?.length === 1) {
        dispatch(
          userSigupAction.selectedUserPropertyData(getPropertyData?.data[0])
        );
        // onBtnClick();
      } else {
        console.log("55");
        // setTimeout(() => {
        handleClose();
        // }, 3000);
      }
    } else {
      if (Object.keys(stateCodesData).length > 0) {
        validate();
      }
    }
  }, [getPropertyData]);

  const handleChange = (place) => {
    var address = place.formatted_address;
    console.log("Place values==?>", place);
    console.log(
      "Place values filterd==>",
      place.address_components.filter((i, k) => i.types.includes("subpremise"))
    );
    var value = address?.split(",");
    const count = value.length;
    const country = value[count - 1];
    const stateVal = value[count - 2];
    const city = value[count - 3];
    const streetAdd = value[count - 4] !== undefined ? value[count - 4] : "";
    var stateSplit = stateVal?.split(" ");
    var i = stateSplit?.length;
    var pincode = "";
    const state = stateSplit[1];
    if (i > 2) {
      pincode = stateSplit[2];
    }
    var ss = streetAdd?.split(" ");
    console.log(ss.length, "ss==>.", ss);
    const sn = place.address_components.filter((i, k) =>
      i.types.includes("street_number")
    );
    var streetNum = sn.length > 0 ? sn[0]?.long_name : "";
    const sr = place.address_components.filter((i, k) =>
      i.types.includes("route")
    );
    var site_route = sr.length > 0 ? sr[0]?.long_name : "";
    const condos = place.address_components.filter((i, k) =>
      i.types.includes("subpremise")
    );
    var units = condos.length > 0 ? condos[0]?.long_name : "";
    var county = getShortNameAddressFromGoogleAutoComplete(
      place,
      "administrative_area_level_2"
    );
    var county_name = county?.replace("County", "").trim();
    // if (ss.length > 2) {
    //   streetNum = ss[0];
    //   site_route = ss[1] + " " + ss[2];
    // } else {
    //   streetNum = "";
    //   site_route = streetAdd;
    // }
    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    let param = {
      street_number: streetNum,
      site_route: site_route,
      city: city,
      state: state,
      zip: pincode,
      unit_no: units,
      county: county_name,
    };
    let fixParams = {
      country: country,
      latitude: latitude,
      longitude: longitude,
      address: streetAdd,
    };
    console.log(county_name, "county map lag==>>", param);
    setPropertyValues(param);
    setOtherPropertyValues(fixParams);
    setPropertyForm(true);
    setErrMsg("");
    setShowPropertyListing(false);
    HideSkipBtn(true);
  };

  const gotoQuestions = (data) => {
    let countyName =
      data?.county || resumeProcessResponse?.data?.county_details?.county_name;
    console.log(
      countyName,
      "go to questuionss==>",
      countyData?.data?.county_name
    );
    // if (
    //   countyName?.toLowerCase() === countyData?.data?.county_name?.toLowerCase()
    // ) {
    dispatch(
      userSigupAction.getUserProperty({
        url: "properties",
        data: data,
        token: false,
      })
    );
    //   setCountyAlert(false);
    //   console.log("county data in", countyData);
    // } else {
    //   setCountyAlert(true);
    //   console.log("county data out", countyData);
    // }
  };

  const addExistingAddress = () => {
    console.log(
      "addExistingAddress called",
      JSON.parse(localStorage.getItem("param"))
    );
    let userAddress = JSON.parse(localStorage.getItem("userSignupAddress"));
    let userSignupDetails = JSON.parse(
      localStorage.getItem("userSignupDetails")
    );
    // console.log(
    //   userSignupDetails?.searched_address?.param,
    //   "userSignupDetails==>",
    //   userSignupDetails
    // );
    let param = {
      ...JSON.parse(localStorage.getItem("param")),
      county: userSignupDetails?.searched_address?.param?.county,
    };
    let fixParams = JSON.parse(localStorage.getItem("fixParams"));
    let addData = {
      street_number:
        userAddress?.address && !isNaN(userAddress?.address?.split(" ")?.[0])
          ? userAddress?.address?.split(" ")?.[0]
          : "",
      site_route: userAddress?.address
        ? isNaN(userAddress?.address?.split(" ")?.[0])
          ? userAddress?.address
          : userAddress?.address?.split(" ")?.slice(1)?.join(" ")
        : "",
      city: userAddress?.city || "",
      state: userAddress?.state || "",
      unit_no: userAddress?.unit_no || "",
      zip: userAddress?.zip || "",
      county: userSignupDetails?.searched_address?.param?.county,
    };
    console.log(
      isNaN(userAddress?.address?.split(" ")?.[0]),
      "addData==>>",
      addData
    );
    setPropertyValues(param || addData);
    setOtherPropertyValues(fixParams);
    setExistingAdd(localStorage.getItem("searched_Add"));
    setPropertyForm(true);
    setErrMsg("");
    setShowPropertyListing(false);
    HideSkipBtn(true);
    setAddressPopup(false);
  };
  const removeExistingAddress = () => {
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
    localStorage.removeItem("property_search_data");
    localStorage.removeItem("user_details");
    setAddressPopup(false);
  };
  console.log(questionsErrMsg, "errMsg==>", errMsg);
  return (
    <>
      <Box>
        {
          <>
            <>
              <Autocomplete
                className="googleAutoComplete"
                apiKey={GOOGLE_API_KEY}
                value={existingAdd}
                onChange={(e) => {
                  console.log("add", e);
                  setExistingAdd(e.target.value);
                }}
                onPlaceSelected={(place) => {
                  setExistingAdd(place?.formatted_address);
                  handleChange(place);
                }}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "us" },
                }}
                placeholder="Enter your property address"
              />
              {/* {propertyForm && ( */}
              <>
                <PropertyForm
                  propertyValues={propertyValues}
                  otherPropertyValues={otherPropertyValues}
                  onBtnClick={onBtnClick}
                  stateCodesData={stateCodesData}
                  gotoQuestions={gotoQuestions}
                  handleToggle={handleToggle}
                />
                {!getPropertyData?.success && errMsg && (
                  <p className="text-danger text-center p-2">{errMsg}</p>
                )}
              </>
            </>

            {questionsErrMsg?.length > 0 && (
              <p className="text-danger text-center">{questionsErrMsg}</p>
            )}
          </>
        }
      </Box>

      {addressPopup && (
        <AlertDialog
          type="Attention"
          width="sm"
          openPopup={addressPopup}
          onClose={true}
          onCloseFun={() => setAddressPopup(false)}
          mesage={
            "  Would you like to use the address you've provided with the account?"
          }
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => setAddressPopup(false)}
              >
                Nope
              </Button>
              <Button
                className="popup_Btn success_popup_btn "
                onClick={() => addExistingAddress()}
              >
                Yes
              </Button>
            </>
          }
        />
      )}

      {countyAlert && (
        <AlertDialog
          type={"Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setCountyAlert(false);
          }}
          mesage={
            <>
              The address must be within{" "}
              {<b>{countyData?.data?.county_name}</b>} County.
            </>
          }
          footer={false}
        />
      )}

      {errorPopup && (
        <ContcatAdminSupport
          // message={errMsg}
          message={errMsg || questionsErrMsg}
          errorPopup={errorPopup}
          reduceErrorCount={reduceErrorCount}
        />
      )}
    </>
  );
}
