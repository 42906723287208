import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { ROLES } from "../../../components/helpers/constants";
import { decryptPayload } from "../../../components/helpers/utils";
import { propertiesAction } from "../../slices/properties/propertiesSlice";

export function* fetchPropertyListSaga({ payload }) {
  console.log("in sagas--", payload);
  if (
    decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.professional)
  ) {
    const response = yield call(postAPIPayload, payload);
    yield put(propertiesAction.setPropertyList(response));
  } else {
    const response = yield call(postAPIPayload, payload);
    yield put(propertiesAction.setPropertyList(response));
  }
}

export function* fetchUserPropertySaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(propertiesAction.setUserPropertyData(response));
}

export function* getgenerateQuestionsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(propertiesAction.setGeneratedQuestionsData(response));
}

export function* getVerifyAnswersSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user Property response==>.", response);
  yield put(propertiesAction.setVerifedAnswersData(response));
}

export function* getDocumetTypesSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("DocumetType response==>.", response);
  yield put(propertiesAction.fetchDocumentTypeList(response));
}

export function* getReqDocumentSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("fethRequestedDoc response==>.", response);
  yield put(propertiesAction.fethRequestedDoc(response));
}

export function* getGeneratedDocSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("fethGeneratedDoc response==>.", response);
  yield put(propertiesAction.fethGeneratedDoc(response));
}

export function* getPropertyPaymentSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("fethGeneratedDoc response==>.", response);
  yield put(propertiesAction.fethPayment(response));
}

export function* cancelSubscriptionSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertiesAction.setCancelSubscription(response));
}

export function* fetchSubscriptionHistorySaga({ payload }) {
  console.log("subscripion saga payload--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("subscripion saga response==>.", response);
  yield put(propertiesAction.setSubscriptionHistory(response));
}

export function* addPropertySubscriptionSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertiesAction.setAddPropertySubscription(response));
}

export function* editPropertiesDetailsSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertiesAction.setEditPropertiesData(response));
}

export function* securityPropertiesQueDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertiesAction.setSecurityPropertiesQueData(response));
}

export function* verifySecurityInfoDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertiesAction.fetchVerifySecurityInfoData(response));
}

export function* stopSubscriptionSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertiesAction.fetchStopSubscriptionDetails(response));
}

export function* getViewPropertyDetailsSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertiesAction.setViewPropertyDetails(response));
}

export function* watchPropertiesSagaAsync() {
  yield takeLatest(propertiesAction.fetchPropertyList, fetchPropertyListSaga);
  yield takeLatest(propertiesAction.fetchUserProperty, fetchUserPropertySaga);
  yield takeEvery(propertiesAction.generateQuestions, getgenerateQuestionsSaga);
  yield takeEvery(propertiesAction.verifyAnswers, getVerifyAnswersSaga);
  yield takeEvery(propertiesAction.getDocumentTypes, getDocumetTypesSaga);
  yield takeEvery(propertiesAction.reqDocument, getReqDocumentSaga);
  yield takeEvery(propertiesAction.generateDocument, getGeneratedDocSaga);
  yield takeEvery(propertiesAction.paymentForProperty, getPropertyPaymentSaga);
  yield takeEvery(propertiesAction.cancelSubscription, cancelSubscriptionSaga);
  yield takeEvery(
    propertiesAction.fetchSubscriptionHistory,
    fetchSubscriptionHistorySaga
  );
  yield takeEvery(
    propertiesAction.addPropertySubscription,
    addPropertySubscriptionSaga
  );
  yield takeEvery(
    propertiesAction.editPropertiesDetails,
    editPropertiesDetailsSaga
  );
  yield takeEvery(
    propertiesAction.securityPropertiesQueDetails,
    securityPropertiesQueDetailsSaga
  );
  yield takeEvery(
    propertiesAction.verifySecurityInfoDetails,
    verifySecurityInfoDetailsSaga
  );
  yield takeEvery(
    propertiesAction.setStopSubscriptionData,
    stopSubscriptionSaga
  );
  yield takeEvery(
    propertiesAction.getViewPropertyDetails,
    getViewPropertyDetailsSaga
  );
}
