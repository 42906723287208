import React, { useState } from "react";

import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import useTimer from "../hooks/useTimer";
import { autoFocusOnField } from "../helpers/utils";
import { resendOTPSeconds } from "../helpers/constants";

const PersonalTokenScreen = ({
  subTitle,
  phoneNumber,
  onsubmit,
  resendOTPSubmit,
  otpSucess,
  errOTP,
  removeResendMsg,
}) => {
  const [otp, SetOTP] = useState("");
  const [otpErr, SetOTPErr] = useState("");
  // console.log(otpErr, "errOTP===", errOTP, "==otpSucess==", otpSucess);

  //initializes the timer states for hook
  const { timer, isTimerActive, startTimer, formatTimeForResendOTP } =
    useTimer(120);

  const handleChange = (otp) => {
    if (otp?.length === 6) {
      SetOTPErr("");
    }
    // else {
    //   SetOTPErr("Please Enter Valid Token");
    // }
    console.log("otp handled==>>", otp);
    SetOTP(otp);
  };

  const otpValidate = (event) => {
    event.preventDefault();
    console.log("in otp validation", otp?.length);
    // props.onBtnClick();
    if (otp?.length === 6) {
      onsubmit(otp);
      SetOTP("");
      autoFocusOnField();
    } else {
      console.log("else");
      SetOTPErr("Please Enter Valid Token");
    }
    removeResendMsg();
  };

  const resenOTP = () => {
    console.log("resend OTP==>>");
    resendOTPSubmit();
    startTimer(resendOTPSeconds);
    SetOTP("");
  };

  const OTPFieldStyle = {
    background: "#F6F9FC",
    border: "1px solid rgba(52, 75, 91, 0.1)",
    borderRadius: "3px",
    width: "40px",
    height: "55px",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    textAlign: "center",
    color: "#0D120B",
  };

  let phn_num = phoneNumber ? phoneNumber : undefined;
  const phn_num1 = phn_num?.substring(phn_num?.length - 4);
  return (
    <>
      <Box className="accountBoxWrapper">
        <Typography align="center" variant="h3">
          Enter your personal token
        </Typography>
        <Typography className="para" align="center" variant="body1">
          {subTitle}
          {/* Please enter the 6 digit security code we have sent to your mobile
          phone * *** *** {phn_num1 ? phn_num1 : "4321"}. */}
        </Typography>
        <form onSubmit={otpValidate}>
          <Box className="text-center otpBoxWrapper">
            <OtpInput
              className="otp mx-1"
              value={otp}
              isInputNum
              onChange={handleChange}
              numInputs={6}
              shouldAutoFocus
              inputStyle={OTPFieldStyle}
            />
            {otpErr?.length > 0 &&
            otpSucess?.length === 0 &&
            errOTP?.length === 0 ? (
              <Typography variant="caption" className="text-danger 1" p={2}>
                {otpErr}
              </Typography>
            ) : otpSucess?.length !== 0 ? (
              <Typography variant="caption" className="text-success 2" p={2}>
                {otpSucess}
              </Typography>
            ) : (
              <Typography variant="caption" className="text-danger 3" p={2}>
                {errOTP}
              </Typography>
            )}
          </Box>
          <div className="text-center stepBtn mt-5">
            <Button
              onClick={otpValidate}
              className="next_button mb-3"
              variant="contained"
              type="submit"
            >
              Verify
            </Button>
          </div>
        </form>
        <div className="text-center tokenCodeText">
          {isTimerActive ? (
            <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
          ) : (
            <>
              {" "}
              Did not receive the code?
              <Button sx={{ textTransform: "none" }} onClick={resenOTP}>
                <Link className="text-blue text-decoration-none" to="">
                  Get a new one
                </Link>
              </Button>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default PersonalTokenScreen;
