import * as React from "react";
import { useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { Stack, Typography } from "@mui/material";
import Settings from "@mui/icons-material/Settings";
import CachedIcon from "@mui/icons-material/Cached";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Loader } from "../common/loader";
import DialogBox from "../common/DialogBox";
import { ROLES } from "../helpers/constants";
import AlertDialog from "../common/AlertDialog";
import { spouseAction } from "../../redux/slices/spouse/spouseSlice";
import ProfileIcon from "../../assets/dashboard/Dummy_Profile_photo.png";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import {
  capitalizeFirstCharInWord,
  clearLocalStoarge,
  decryptPayload,
  getObjLength,
  reactPixelEventOnlyForCustomer,
  stringAvatar,
} from "../helpers/utils";
import useClearLocalStorage from "../hooks/useClearLocalStorage";

export default function AccountMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clearLocalStorageValues } = useClearLocalStorage();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSpouseChangeModalOpen, setIsSpouseChangeModalOpen] =
    React.useState(false);

  // store data
  const userProfileDetails = useSelector(
    (state) => state.userProfile.userProfileDetails
  );
  const {
    loggedInEmail,
    loginAsSpouse: isSecondaryUser,
    switchAccountLoader,
    switchAccountDetails,
    switchedToParentUser,
  } = useSelector((state) => state?.userProfile);
  const { spouseListLoader, selectSpouseList } = useSelector(
    (state) => state?.spouse
  );

  // handle My Account Button
  const handleMyAccountBtn = () => {
    if (userProfileDetails?.success) {
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.professional
        )
      ) {
        navigate("/professional-profile");
      } else {
        navigate("/user-profile");
      }
    } else {
      navigate("/login");
    }
  };

  // handle sign out button
  const handleSignOut = () => {
    dispatch(
      userProfileAction.logoutUser({ url: "logout", data: {}, token: true })
    );
    dispatch(userSigInAction.successLogout());
    reactPixelEventOnlyForCustomer("Signout", { email: loggedInEmail });

    dispatch(userSigInAction.successLogout());
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("authorised");
    let isIos = false;
    if (JSON.parse(localStorage.getItem("isIos"))) {
      isIos = JSON.parse(localStorage.getItem("isIos"));
    }
    // clearLocalStoarge("check_county");
    clearLocalStorageValues("check_county");
    localStorage.setItem("ivokeNativeMethod", isIos);

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }

    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // handle open spouse change modal
  const handleOpenSpouseChangeModal = () => {
    console.log("console.log");
    setIsSpouseChangeModalOpen(true);
  };

  // handle close spouse change modal
  const handleCloseSpouseChangeModal = () => {
    setIsSpouseChangeModalOpen(false);
  };

  // actions after clicking on any spouse
  const handleLoginToSpouse = (customer) => {
    if (customer?.id) {
      // switch to parent account and then switch isSecondaryUser to true for disabling the action
      dispatch(
        userProfileAction.switchAccount({
          parent_id: customer?.id,
        })
      );
      dispatch(propertiesAction.resetProperties());
      handleCloseSpouseChangeModal();
    }
  };

  // handle back to original user
  const handleLogoutFromSpouse = () => {
    // call an API for changing the token and back to sub user
    // navigate("/user-profile");
    dispatch(propertiesAction.resetProperties());
    dispatch(userProfileAction.logoutFromSpouse());
  };

  // useEffect to fetch spouse list when spouse modal open
  useEffect(() => {
    if (isSpouseChangeModalOpen) {
      // Call API for switching to customer
      dispatch(
        spouseAction.fetchSpouseListData({
          url: `sub-user/parent-list`,
          data: {},
          token: true,
          parentList: true,
        })
      );
    }
  }, [dispatch, isSpouseChangeModalOpen]);

  return (
    <>
      {/* desktop */}
      <div
        className="px-3 account-dropdown d-none d-md-block"
        style={{
          borderLeft: "1px solid #E8ECF0",
          borderRight: "1px solid #E8ECF0",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableFocusRipple
            disableRipple
          >
            <Avatar
              sx={{ width: 40, height: 40, mr: 1 }}
              src={
                userProfileDetails?.data?.profile_img
                  ? userProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            ></Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant="h6" className="userName">
                {userProfileDetails?.data?.first_name}{" "}
                {userProfileDetails?.data?.last_name}
                <ArrowDropDownIcon
                  sx={{ ms: 1, fontSize: "20px", color: "#15BE53" }}
                />
              </Typography>
              {isSecondaryUser ? (
                <Typography variant="caption" className="companyName">
                  Switched User
                </Typography>
              ) : null}
              <Typography variant="caption" className="companyName">
                {
                  userProfileDetails?.data?.default_professional_id
                    ?.company_name
                }
              </Typography>
            </Box>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          className="d-none d-md-block"
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar
              fontSize="medium"
              src={
                userProfileDetails?.data?.profile_img
                  ? userProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            />{" "}
            Profile
          </MenuItem>
          <Divider className="separator" />
          {/* {userProfileDetails?.data?.role?.includes(ROLES.secondaryUser) &&
            !isSecondaryUser && (
              <MenuItem
                className="profile-items"
                onClick={handleOpenSpouseChangeModal}
              >
                <ListItemIcon>
                  <CachedIcon />
                </ListItemIcon>
                Switch Account
              </MenuItem>
            )} */}
          {/* add one more condition that clarify that user switched the account */}
          {/* {isSecondaryUser && (
            <MenuItem
              className="profile-items"
              onClick={handleLogoutFromSpouse}
            >
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              Back to {userProfileDetails?.data?.first_name}{" "}
              {userProfileDetails?.data?.last_name}
            </MenuItem>
          )} */}
          <Link className="text-decoration-none" to="/settings">
            <MenuItem className="profile-items">
              <ListItemIcon>
                <Settings fontSize="medium" />
              </ListItemIcon>
              Settings
            </MenuItem>
          </Link>
          {/* {!isSecondaryUser && ( */}
          <MenuItem className="profile-items" onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
          {/* )} */}
        </Menu>
      </div>

      {/* mobile */}
      <div
        className="px-3 account-dropdown mobile d-block d-md-none"
        style={{ borderBottom: "1px solid #E8ECF0" }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent:
              userProfileDetails?.data?.default_professional_id ||
              isSecondaryUser
                ? "space-between"
                : "flex-end !important",
          }}
        >
          {userProfileDetails?.data?.default_professional_id?.company_name ? (
            <Typography variant="caption" className="companyName">
              {userProfileDetails?.data?.default_professional_id?.company_name}
            </Typography>
          ) : null}
          {isSecondaryUser ? (
            <Typography variant="caption" className="companyName">
              Switched User
            </Typography>
          ) : null}
          <Typography variant="h6">
            <IconButton
              onClick={handleClick}
              size="small"
              className="userName"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableFocusRipple
              disableRipple
            >
              {" "}
              {userProfileDetails?.data?.first_name}{" "}
              {userProfileDetails?.data?.last_name}
              <ArrowDropDownIcon
                sx={{ ms: 1, fontSize: "20px", color: "#15BE53" }}
              />
            </IconButton>
          </Typography>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className="d-block d-md-none"
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar
              fontSize="medium"
              src={
                userProfileDetails?.data?.profile_img
                  ? userProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            />{" "}
            Profile
          </MenuItem>
          <Divider className="separator" />
          {/* {userProfileDetails?.data?.role?.includes(ROLES.secondaryUser) &&
            !isSecondaryUser && (
              <MenuItem
                className="profile-items"
                onClick={handleOpenSpouseChangeModal}
              >
                <ListItemIcon>
                  <CachedIcon />
                </ListItemIcon>
                Switch Account
              </MenuItem>
            )} */}
          {/* add one more condition that clarify that user switched the account */}
          {/*  {isSecondaryUser && (
            <MenuItem
              className="profile-items"
              onClick={handleLogoutFromSpouse}
            >
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              Back to {userProfileDetails?.data?.first_name}{" "}
              {userProfileDetails?.data?.last_name}
            </MenuItem>
          )} */}
          <Link className="text-decoration-none" to="/settings">
            <MenuItem className="profile-items">
              <ListItemIcon>
                <Settings fontSize="medium" />
              </ListItemIcon>
              Settings
            </MenuItem>
          </Link>
          {/* {!isSecondaryUser && ( */}
          <MenuItem className="profile-items" onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
          {/* )} */}
        </Menu>
      </div>

      <DialogBox
        width={"xs"}
        openPopup={isSpouseChangeModalOpen}
        setOpenPopup={setIsSpouseChangeModalOpen}
        onClose={handleCloseSpouseChangeModal}
        dividers={false}
        title={
          <>
            <Box className="d-flex justify-content-left align-items-center py-2 px-3">
              Customer List
            </Box>
          </>
        }
        content={
          <>
            {/* display list of customer whom, sub-user is exist and want to switch in that customer */}
            {selectSpouseList?.data?.length > 0 ? (
              <Box className="" sx={{ maxHeight: "400px", overflowY: "auto" }}>
                {selectSpouseList?.data?.map((customer, index) => {
                  return (
                    customer?.id !== parseInt(localStorage.getItem("uID")) && (
                      <>
                        <Stack
                          key={customer?.id}
                          spacing={2}
                          direction={{ xs: "column", sm: "row" }}
                          alignItems="center"
                          className="bg-white my-2 px-3 py-2 rounded cursor-pointer"
                          onClick={() => {
                            handleLoginToSpouse(customer);
                          }}
                        >
                          <Avatar
                            {...stringAvatar(
                              capitalizeFirstCharInWord(
                                `${customer?.first_name} ${customer?.last_name}`
                              )
                            )}
                          />
                          <Stack
                            direction="column"
                            alignItems={{ sm: "flex-start", xs: "center" }}
                          >
                            <Typography variant="subtitle1" className="fw-600">
                              {capitalizeFirstCharInWord(
                                `${customer?.first_name} ${customer?.last_name}`
                              )}
                            </Typography>
                            <Typography>{customer?.email}</Typography>
                          </Stack>
                        </Stack>
                      </>
                    )
                  );
                })}
              </Box>
            ) : (
              <Typography className="text-black fs-5 p-4 text-center">
                No Data Found
              </Typography>
            )}

            <Loader open={spouseListLoader || switchAccountLoader} />
          </>
        }
        // footer={modalFooter()}
      />

      {getObjLength(switchAccountDetails) &&
        (switchAccountDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.clearSwitchSpouseDetails());
              handleCloseSpouseChangeModal();
            }}
            mesage={switchAccountDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userProfileAction.clearSwitchSpouseDetails());
            }}
            mesage={switchAccountDetails?.data?.message}
            footer={false}
          />
        ))}
    </>
  );
}
