import * as React from "react";
import { useState } from "react";

import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { PER_PAGE } from "../../helpers/constants";
import usePagination from "../../common/PaginationLogic";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  getFormatedPhoneNumber,
  getProfessionalTypeLabel,
} from "../../helpers/utils";

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {props?.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "#0395FF", minWidth: headCell.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProfessionalListTable({
  columns,
  rowsData,
  getPlanRenewed,
}) {
  const [page, setPage] = useState(1);

  const count = Math.ceil(rowsData?.length / PER_PAGE);
  const _DATA = usePagination(rowsData, PER_PAGE, page, setPage);
  // const _DATA = rowsData;

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      {/* Property Listing Table */}
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 sub-user-mobile-table py-4">
        {_DATA?.currentData()?.map((row, index) => {
          return (
            <>
              <Stack direction="column" spacing={1.2} px={3}>
                <Stack direction="column" spacing={0.5}>
                  <Typography
                    className="value fw-500 text-capitalize"
                    sx={{ fontSize: "15px !important" }}
                  >
                    {row?.property_name || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">
                    Assigned Professional:
                  </Typography>
                  <Typography className="value">
                    {row?.assign_professional_name
                      ? row?.assign_professional_name
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Company:</Typography>
                  <Typography className="value">
                    {row?.company_id ? row?.company_id : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Email:</Typography>
                  <Typography className="value">
                    {row?.email ? row?.email : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Phone:</Typography>
                  <Typography className="value">
                    {row?.phone ? getFormatedPhoneNumber(row?.phone) : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Zip Range:</Typography>
                  <Typography className="value">
                    {row?.zip ? row?.zip : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Type:</Typography>
                  <Typography className="value">
                    {row?.professional_type
                      ? getProfessionalTypeLabel(row?.professional_type)
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              {index < _DATA?.currentData()?.length - 1 && (
                <Divider
                  className="my-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {!_DATA?.currentData()?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <EnhancedTableHead
                // numSelected={selected.length}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
                rowCount={rowsData?.length}
                columns={columns}
              />
              <TableBody>
                {_DATA?.currentData()?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="left">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell align="left">
                        {row?.assign_professional_name
                          ? row?.assign_professional_name
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.company_id ? row?.company_id : "-"}
                      </TableCell>
                      <TableCell align="left">{row?.property_name}</TableCell>
                      <TableCell align="left">
                        {row?.email ? row?.email : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.phone ? getFormatedPhoneNumber(row?.phone) : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.zip ? row?.zip : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.professional_type
                          ? getProfessionalTypeLabel(row?.professional_type)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!rowsData.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
      </Box>
      {rowsData.length > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
}
