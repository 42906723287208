import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import ExportDetailsForm from "./exportDetailsForm";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ExportDetailsModal = ({ goBack }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mb={3}
      >
        <Typography variant="h6" className="card-title fw-600">
          Export Payments
        </Typography>
        <Tooltip
          className="d-none d-sm-block"
          title="Go to Export CSV list"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <Button
            variant="contained"
            className="success-btn d-none d-sm-block"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to go back to payment details list?"
                )
              ) {
                goBack();
              }
            }}
          >
            Go Back
          </Button>
        </Tooltip>
        <Tooltip
          className="d-flex d-sm-none"
          title="Go to Property User list"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <IconButton
            className="success-btn d-flex d-sm-none"
            variant="outlined"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to go back to payment details list?"
                )
              ) {
                goBack();
              }
            }}
            sx={{ color: "#FFF" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box pb={5}>
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "white",
            width: "100%",
            marginBottom: "3rem",
            boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
          }}
        >
          <Typography
            align="center"
            variant="subtitle1"
            className="py-1 formHeader"
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
          </Typography>
          <Box className="accountBoxWrapper">
            {/* signup form */}
            <Typography align="center" variant="body1">
              We just need to gather some information to set up Export payments details.
            </Typography>
            <ExportDetailsForm goBack={goBack}/>
            {/* <DurationForm/> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExportDetailsModal;
