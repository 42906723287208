import * as React from "react";

import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { Box, Divider, Stack, styled } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import logo from "../../assets/logo/equity_logo.png";
// import check_img from "../../assets/icons/checkmark.png";
import check_img from "../../assets/icons/checkmark_blk.png";
import dash_img from "../../assets/icons/rectangle_dash.png";

import "../../styles/productComparisionTable.scss";

const rows = [
  {
    key: 1,
    title: "Physically Prevents a financial crime from ever occurring!",
    ep: (
      <Stack direction={"row"} alignItems={"center"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    allOther: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#FFFFFF",
  },
  {
    key: 2,
    title:
      "Uses multi-factor authentication protocols before allowing anyone permission to move forward with a new refinance or purchase transaction.",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    allOther: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#f5f7fb",
  },
  {
    key: 3,
    title:
      "We use our proprietary bio-metrics or facial recognition system as our primary authentication protocol.  ",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    allOther: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#FFFFFF",
  },
  {
    key: 4,
    title:
      "Our protection inherently covers 100% of the costs associated with your defense and recovery (If one should occur)",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text spacing1">Included</span>
      </Stack>
    ),
    allOther: (
      <div className="dash_img_ast">
        <img src={dash_img} alt="dash" />
        <span className="">*</span>
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#f5f7fb",
  },
  {
    key: 5,
    title:
      "Create unlimited sub-users to your profile. Commonly used for properties being manged by someone else or other family members.",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    allOther: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#FFFFFF",
  },
  {
    key: 6,
    title:
      "24/7 Property Specific Risk Assessment Reports and Quarterly Notifications",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    allOther: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#f5f7fb",
  },
  {
    key: 7,
    title: "Convenient Mobile Apps to manage your properties on the go",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    allOther: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#FFFFFF",
  },
  {
    key: 8,
    title: "24/7 Title Monitoring",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text spacing1">Included</span>
      </Stack>
    ),
    allOther: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text">Included</span>
      </Stack>
    ),
    freeCounty: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text">Included</span>
      </Stack>
    ),
    bgColor: "#f5f7fb",
  },
  {
    key: 9,
    title: "Urgent Fraud Alerts",
    ep: (
      <Stack direction={"row"} alignItems={"center"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text spacing1 ">Included</span>
      </Stack>
    ),
    allOther: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text">Included</span>
      </Stack>
    ),
    freeCounty: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    bgColor: "#FFFFFF",
  },
  {
    key: 10,
    title: "Title Restoration",
    ep: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text spacing1">Included</span>
      </Stack>
    ),
    allOther: (
      <Stack direction={"row"}>
        <img src={check_img} className="check_img" alt="check" />
        <span className="include_text ">Included</span>
      </Stack>
    ),
    freeCounty: (
      <div>
        <img src={dash_img} className="dash_img" alt="dash" />
      </div>
    ),
    bgColor: "#f5f7fb",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "black",
    borderColor: "transparent",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    borderColor: "transparent",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
    backgroundImage: "linear-gradient(to right, #eaf2fc,#e2ecf8,#e2ecf8)",
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductComparison = ({ topPadding = null, textClass = null }) => {
  return (
    <>
      {/* for Mobile view */}
      <Box
        // sx={{ width: 200 }}
        // sx={{ background: "#e2ecf8" }}
        className={`d-block d-md-none product_comaprison_table ${textClass}`}
      >
        <Stack
          className="w-100 mobile_headers"
          flex
          spacing={{ xs: 1, sm: 1 }}
          direction="row"
          justifyContent="space-evenly"
          sx={{ padding: "15px 0 " }}
          backgroundColor="white"
          position="sticky"
          top={`${topPadding ? topPadding : "92px"}`}
        >
          <div className="header_line">
            <img className="ep_logo_mobile " src={logo} alt="logo" />
          </div>

          <div className="header_line">All Other Competitors</div>

          <div className="header_line">Free County Services</div>
        </Stack>

        <Stack className="" direction="row" flexWrap="wrap" alignItems="center">
          {rows.map((row) => (
            <Stack
              className="w-100"
              spacing={{ xs: 1, sm: 2 }}
              direction="column"
              sx={{ background: "#e2ecf8" }}
            >
              <div style={{ paddingTop: "15px" }}>
                <p className="info_text text-center">{row.title}</p>
              </div>
              <Stack
                className="w-100"
                flex
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                justifyContent="space-evenly"
                sx={{ padding: "15px 0 " }}
                backgroundColor="white"
              >
                <>
                  <p className="info_text">{row.ep}</p>
                </>
                {/* <Box className="">
                  <Divider className="divider_line" orientation="vertical" />
                </Box> */}
                <>
                  <div className="info_text">{row.allOther}</div>
                </>
                {/* <Box className="m-0">
                  <Divider className="divider_line" orientation="vertical" />
                </Box> */}
                <>
                  <div className="info_text">{row.freeCounty}</div>
                </>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>

      {/* for Desktop view */}
      <div
        className={`product_comaprison_table d-none d-md-block ${textClass}`}
      >
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0",
            backgroundColor: "transparent",
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              padding: "10px",
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                padding: "10px",
              }}
            >
              <TableRow>
                <StyledTableCell className=" text-uppercase column_headers"></StyledTableCell>
                <StyledTableCell
                  width={"170"}
                  className="column_headers table_bottom_border"
                >
                  <img className="ep_logo_table" src={logo} alt="logo" />
                </StyledTableCell>
                <StyledTableCell
                  width={"170"}
                  align="left"
                  className={`column_headers table_bottom_border text-uppercase ${textClass}`}
                >
                  All Other Competitors
                </StyledTableCell>
                <StyledTableCell
                  width={"170"}
                  align="left"
                  className={`column_headers table_bottom_border text-uppercase ${textClass}`}
                >
                  Free County Services
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className="">
              {rows.map((row) => (
                <StyledTableRow key={row.key}>
                  <StyledTableCell className={`row_texts ${textClass}`}>
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell
                    className={`row_texts table_border ${
                      rows.length === row.key && "table_bottom_border"
                    } ${textClass}`}
                    style={{ background: row.bgColor, boxShadow: "10px" }}
                    align="center"
                  >
                    {row.ep}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ background: row.bgColor }}
                    align="center"
                    className={`row_texts table_border ${
                      rows.length === row.key && "table_bottom_border"
                    } ${textClass}`}
                  >
                    {row.allOther}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ background: row.bgColor }}
                    className={`row_texts table_border ${
                      rows.length === row.key && "table_bottom_border"
                    } ${textClass}`}
                    align="center"
                  >
                    {row.freeCounty}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <p className="bottom_table_text">
          *competitors use an E&O policy or insurance of a surplus provider to
          make a claim.
        </p>
      </div>
    </>
  );
};

export default ProductComparison;
