import React from "react";

import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "./loader";
import PlainAlertMessage from "./PlainAlertMessage";
import { currencyFormatter, getObjLength } from "../helpers/utils";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import { useState } from "react";
import SecurePaymentPanel from "./SecurePaymentPanel";

const CancelLennarSubscriptionModal = ({
  open,
  handleClose = () => {},
  handleCancelSubscriptionBtn,
  endDate,
  selectedProperty = {},
  paymentData,
  //   onSuccessFun = () => {},
  onFailedFun = () => {},
  callListingAPI = () => {},
  cardListingAPI = () => {},
  isCardsOpen = false,
  from = false,
  loader = false,
}) => {
  const dispatch = useDispatch();
  //states
  const [isCardListOpen, setIsCardListOpen] = useState(isCardsOpen);
  const [selectedCard, setSelectedCard] = useState(null);

  // store data
  const { cancelSubscriptionDetails, cancelSubscriptionLoader } = useSelector(
    (state) => state.properties
  );

  const handleModalClose = () => {
    dispatch(propertiesAction.clearCancelSubscriptionStates());
    setIsCardListOpen(false);
    handleClose();
  };
  console.log("open 2==>", open);
  const cancelSubscription = () => {
    setIsCardListOpen(true);
  };

  const onSuccessFun = () => {
    console.log("listing api...");
    callListingAPI();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {(!getObjLength(cancelSubscriptionDetails) ||
        cancelSubscriptionDetails?.data?.actionRequired) && (
        <>
          <DialogTitle id="alert-dialog-title">
            Cancel Subscription
            <Button
              onClick={handleModalClose}
              className="modal-close-btn"
              style={{
                position: "absolute",
                right: 10,
                top: 15,
                background: "transparent",
              }}
            >
              <CloseIcon sx={{ color: "#9CB8A6" }} />
            </Button>
          </DialogTitle>

          {!isCardListOpen ? (
            <>
              <DialogContent className="bg-white">
                <Typography sx={{ wordBreak: "break-word" }}>
                  By canceling this subscription you will leave yourself
                  unprotected after <b>{endDate}</b>. We will immediately record
                  a termination notice and further protection will require a new
                  set up fee.
                  <br />
                  <br />
                  {selectedProperty?.payment_required && (
                    <b>
                      The cancellation charge of this property is{" "}
                      {currencyFormatter(selectedProperty?.recording_fees || 0)}
                      .
                    </b>
                  )}
                </Typography>
              </DialogContent>

              <DialogActions sx={{ paddingRight: "2rem" }}>
                <Stack direction="row" spacing={2} pb={2}>
                  {selectedProperty?.payment_required ? (
                    <Button
                      variant="link"
                      className="text-danger"
                      onClick={cancelSubscription}
                    >
                      Cancel Subscription
                    </Button>
                  ) : (
                    <>
                      {selectedProperty?.can_refund && (
                        <Button
                          variant="link"
                          className="text-success"
                          onClick={() =>
                            handleCancelSubscriptionBtn("cancel_and_refund")
                          }
                        >
                          Cancel and Refund
                        </Button>
                      )}

                      <Button
                        variant="link"
                        className="text-danger"
                        onClick={() =>
                          handleCancelSubscriptionBtn("cancel_at_period_end")
                        }
                      >
                        Cancel Subscription
                      </Button>
                    </>
                  )}
                </Stack>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogContent className="bg-white">
                {/* <Elements stripe={stripePromise}> */}
                <SecurePaymentPanel
                  displayLabel={false}
                  cardData={setSelectedCard}
                  paymentData={cancelSubscriptionDetails}
                  onSuccessFun={onSuccessFun}
                  // onFailedFun={onSuccessFun}
                  userId={selectedProperty?.owner_id}
                  is_renew={true}
                  callListingAPI={cardListingAPI}
                  from={from}
                />
                {/* </Elements> */}
              </DialogContent>

              <DialogActions>
                <div className="d-flex align-items-center justify-content-between  mb-3">
                  <Button
                    onClick={handleModalClose}
                    className="popup_Btn other_popup_btn mx-2"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={() => {
                      if (selectedCard?.[0]?.id) {
                        handleCancelSubscriptionBtn(
                          "cancel_at_period_end",
                          selectedCard?.[0]?.id
                        );
                      }
                    }}
                    className={`popup_Btn success_popup_btn mx-2 ${
                      !selectedCard?.[0]?.id ? "cursor-disable" : ""
                    }`}
                    variant="contained"
                  >
                    Pay Securly
                  </Button>
                </div>
              </DialogActions>
            </>
          )}
        </>
      )}

      <Box className="">
        {getObjLength(cancelSubscriptionDetails) &&
          !cancelSubscriptionDetails?.data?.actionRequired && (
            <DialogContent className="bg-white">
              {cancelSubscriptionDetails?.success ? (
                <PlainAlertMessage
                  type="Success"
                  onClose={true}
                  onCloseFun={() => {
                    handleModalClose();
                    callListingAPI();
                  }}
                  message={cancelSubscriptionDetails?.message}
                  buttons={
                    <Button
                      className="popup_Btn success_popup_btn"
                      onClick={() => {
                        handleModalClose();
                        callListingAPI();
                      }}
                    >
                      Close
                    </Button>
                  }
                />
              ) : (
                cancelSubscriptionDetails?.data && (
                  <PlainAlertMessage
                    type="Error"
                    onClose={true}
                    onCloseFun={() => {
                      handleModalClose();
                    }}
                    message={cancelSubscriptionDetails?.data?.message}
                    buttons={
                      <Button
                        className="popup_Btn error_popup_btn my-3"
                        onClick={() => {
                          handleModalClose();
                        }}
                      >
                        Cancel
                      </Button>
                    }
                  />
                )
              )}
            </DialogContent>
          )}
      </Box>

      <Loader open={cancelSubscriptionLoader || loader} />
    </Dialog>
  );
};

export default CancelLennarSubscriptionModal;
