import { call, put, takeLatest } from "redux-saga/effects";

import { postAPIPayload } from "../../../apis/postApi";
import { getAPIPayload } from "../../../apis/getApi";
import { lennarSignUpAction } from "../../slices/lennar/signUp";

export function* createLennarUserSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("createLennarUserSaga==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchCreateLennarUserData(response));
}

export function* createPropertiesSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("createProperties ==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchcreatedPropertiesData(response));
}

export function* phoneVerificationDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("createProperties ==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchPhoneVerificationData(response));
}

export function* emailVerificationDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("createProperties ==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchEmailVerificationData(response));
}

export function* resendPhoneOTPSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("createProperties ==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchResendPhoneOTPData(response));
}

export function* resendEmailOTPSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("createProperties ==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchResendEmailOTPData(response));
}

export function* generateLinkSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("generateLink ==>", payload);

  // set reponse data in store
  yield put(lennarSignUpAction.fetchGenerateLinkData(response));
}

export function* watchLennarSignUpSagaAsync() {
  yield takeLatest(lennarSignUpAction.createLennarUser, createLennarUserSaga);
  yield takeLatest(
    lennarSignUpAction.phoneVerificationData,
    phoneVerificationDataSaga
  );
  yield takeLatest(
    lennarSignUpAction.emailVerificationData,
    emailVerificationDataSaga
  );
  yield takeLatest(lennarSignUpAction.resendPhoneOTP, resendPhoneOTPSaga);
  yield takeLatest(lennarSignUpAction.resendEmailOTP, resendEmailOTPSaga);
  yield takeLatest(lennarSignUpAction.createProperties, createPropertiesSaga);
  yield takeLatest(lennarSignUpAction.generateLink, generateLinkSaga);
}
