import React from "react";
import { Navigate } from "react-router-dom";

import { decryptPayload } from "../components/helpers/utils";

const RoleProtectedRoute = ({ children, accessEntity, isIOS = null }) => {
  return (
    <>
      {/* {userSignInDetails?.data?.role?.includes(accessEntity) ||
      verifyFace?.data?.role?.includes(accessEntity) ||
      otpVerification?.data?.role?.includes(accessEntity) ||
      userProfileDetails?.data?.role?.includes(accessEntity) ? (
        children
      ) : (
        <Navigate to={"/404"} />
      )} */}
      {accessEntity?.includes(decryptPayload(localStorage.getItem("roles"))) ? (
        children
      ) : (
        <Navigate to={"/404"} state={{ removeToken: true }} />
      )}
    </>
  );
};

export default RoleProtectedRoute;
