import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import useTimer from "../../../hooks/useTimer";
import { Loader } from "../../../common/loader";
import PersonalTokenScreen from "../../../common/OTPScreen";
import { censorEmail, getObjLength } from "../../../helpers/utils";
import { serviceProviderSignInAction } from "../../../../redux/slices/serviceProvider/SPSignINSlice";

const TwoFactorAuth = ({
  backToAuth,
  isBiometric = false,
  phone_number,
  authWay,
}) => {
  const [open, setOpen] = useState(false);
  const [otpErr, SetOTPErr] = useState(undefined);
  const [otpSucess, SetOTPSucess] = useState("");

  //initializes the timer states for hook
  const { stopTimer } = useTimer(120);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    authLoader,
    userCredentials,
    resendOTP,
    optVerification,
    sendOTPResponse,
  } = useSelector((state) => state.serviceProviderSignin);

  const onsubmit = (otp) => {
    let inputParam = {
      verification_code: otp,
      ...userCredentials,
      otp_identifier:
        sendOTPResponse?.data?.otp_identifier ||
        resendOTP?.data?.otp_identifier,
    };
    console.log(otp, "on submit==>.", inputParam);
    let url = "service-provider/2fa-verify-otp";
    if (authWay === "email") {
      url = "2fa-verify-otp-email";
    }
    dispatch(
      serviceProviderSignInAction.providerVerifyOTP({
        url: url,
        data: inputParam,
      })
    );
  };

  const resendOTPSubmit = () => {
    console.log("resendOTPSubmit==>>");
    let url = "service-provider/2fa-resend-otp";
    if (authWay === "email") {
      url = "2fa-otp-email";
    }
    dispatch(serviceProviderSignInAction.sendOTPResponse({}));
    dispatch(
      serviceProviderSignInAction.resendOTP({
        url: url,
        data: userCredentials,
      })
    );
  };

  const removeResendMsg = () => {
    SetOTPSucess("");
  };

  const backToAuthScreen = () => {
    setOpen(false);
    SetOTPErr(undefined);
    SetOTPSucess("");
    backToAuth();
  };

  useEffect(() => {
    console.log("send opt-->", optVerification);
    if (getObjLength(optVerification)) {
      console.log("validate 1");

      const previousPath = localStorage.getItem("previousPath");
      if (optVerification?.success) {
        let uID = optVerification?.data?.user?.address?.user_id;
        localStorage.setItem("uID", uID);
        dispatch(serviceProviderSignInAction.resetSPSignINState({}));

        if (
          previousPath &&
          parseInt(uID) === parseInt(localStorage.getItem("previousUserID"))
        ) {
          navigate(previousPath, { replace: false });
          localStorage.removeItem("previousPath");
        } else {
          navigate("/servicer-profile", { replace: false });
        }
        SetOTPErr("");
        stopTimer();
      }

      if (!optVerification?.success) {
        SetOTPErr(optVerification?.data?.message);
      }
    }
  }, [optVerification]);

  useEffect(() => {
    console.log("send opt-->", resendOTP);
    if (getObjLength(resendOTP)) {
      console.log("validate 2");
      if (resendOTP?.success) {
        SetOTPErr("");
        SetOTPSucess(resendOTP?.message);
      }
      if (!resendOTP?.success) {
        SetOTPErr(resendOTP?.data?.message);
      }
    }
  }, [resendOTP]);

  // const phoneNumber = "8745963215";
  const phn_num1 = phone_number?.substring(phone_number?.length - 4);
  const msg =
    authWay === "mobile"
      ? `mobile phone * *** ***${phn_num1 ? phn_num1 : "4321"}`
      : `Email ID ${censorEmail(userCredentials?.email)}`;
  return (
    <>
      <Box>
        <PersonalTokenScreen
          subTitle={`Please enter the 6 digit security code we have sent to your ${msg}`}
          phoneNumber={phone_number}
          onsubmit={onsubmit}
          resendOTPSubmit={resendOTPSubmit}
          otpSucess={otpSucess}
          errOTP={otpErr}
          removeResendMsg={removeResendMsg}
        />
        {isBiometric && <Button onClick={backToAuthScreen}>Back</Button>}
      </Box>
      <Loader open={authLoader} />
    </>
  );
};

export default TwoFactorAuth;
