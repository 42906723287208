import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/system";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Button, Paper, Typography } from "@mui/material";

import PropertyTable from "./PropertyTable";
import { exportCSVDetialsAction } from "../../../redux/slices/export-csv/exportcsvSlice";
import DialogBox from "../../common/DialogBox";
import DownloadCSV from "../exportCSV/downloadCSV";
import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";

const DashBoard = ({ tabComponent = false, offsetNum }) => {
  const { selectedProperty } = useSelector((state) => state?.propertyUsers);
  const queryString = window.location.pathname;
  const userId = queryString.split("/")?.[2];
  console.log(parseInt(userId), "url val== >>", queryString);
  const dispatch = useDispatch();

  const [csv_Payload, setCSV_Payload] = useState([]);

  const { exportCsvLoader, exportCSVFileData } = useSelector(
    (state) => state.exportCSVDetails
  );

  //const handleExportCSV = () => {
  //console.log("payload-->", csv_Payload);
  // dispatch(
  //   exportCSVDetialsAction.exportCSVFile({
  //     url: "url",
  //     data: csv_Payload,
  //     token: true,
  //     isFile: true,
  //   })
  // );
  //};

  const handleCloseDownloadModal = (action) => {
    dispatch(exportCSVDetialsAction.getExportCSVFile({}));
  };

  return (
    <>
      {!tabComponent && (
        <Paper
          elevation={0}
          className=" d-flex justify-content-between"
          sx={{ background: "none" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography variant="h6" className="title">
              {userId ? "Users" : "Latest"} Properties
            </Typography>
          </Box>
          {/* {!tabComponent && (
            <Box className="">
              <Button
                variant="contained"
                className="success-btn d-none d-sm-block"
                style={{
                  padding: "10px 30px",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  handleExportCSV();
                }}
              >
                Export CSV
              </Button>
              <Button
                className="success-btn d-flex d-sm-none"
                variant="outlined"
                onClick={() => {
                  handleExportCSV();
                }}
                startIcon={<IosShareIcon />}
                sx={{ color: "#FFF" }}
              >
                Export
              </Button>
            </Box>
          )} */}
        </Paper>
      )}
      <Box>
        <PropertyTable
          userId={userId || selectedProperty?.user_id}
          tabComponent={tabComponent}
          offsetNum={offsetNum}
          setCSV_Payload={setCSV_Payload}
        />
      </Box>

      {getObjLength(exportCSVFileData) && (
        <DialogBox
          width="md"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          openPopup={true}
          onClose={() => {
            dispatch(exportCSVDetialsAction.clearData({}));
          }}
          content={
            <DownloadCSV
              onClose={(action) => {
                handleCloseDownloadModal(action);
              }}
              exportCsvData={exportCSVFileData}
            />
          }
        />
      )}

      <Loader open={exportCsvLoader} />
    </>
  );
};

export default DashBoard;
