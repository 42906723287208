import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Box, FormHelperText, Stack } from "@mui/material";

import { getObjLength } from "../../helpers/utils";
import { USA_STATES } from "../../helpers/constants";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";

import "../../user-signup/signup.scss";

const validationSchema = Yup.object().shape({
  street_number: Yup.string()
    // .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Street number is required"),
  site_route: Yup.string()
    // .min(2, "Too Short!")
    .required("Street Name is required"),
  city: Yup.string("Please enter your City.")
    .strict(true)
    // .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
});

export default function PropertyForm({
  propertyValues,
  onBtnClick,
  stateCodesData,
  otherPropertyValues,
  gotoQuestions,
  handleToggle,
  isByPassOptionAvailable,
  editProperty = false,
  selectedData = {},
  isAdminCreateUser = false,
  onCloseModalHandler = () => {},
}) {
  const dispatch = useDispatch();

  const { verifiedPropertiesList } = useSelector((state) => state.signUpUser);

  console.log("propertyValues==>.", propertyValues);

  const onSubmit = (values, errors) => {
    let searchedAdd = otherPropertyValues?.address;
    if (
      values.street_number !== propertyValues?.street_number &&
      values.site_route !== propertyValues?.site_route
    ) {
      searchedAdd = values?.street_number + " " + values?.site_route;
      delete otherPropertyValues?.address;
    }
    // const formAdd = values.street_number + " " + values.site_route;
    // if (formAdd.length > 0) {
    // delete otherPropertyValues.address;
    // }

    if (values.unit_no?.length === 0) {
      delete values.unit_no;
    }

    const data = Object.assign(values, otherPropertyValues);
    // gotoQuestions({ ...data, address: formAdd });
    gotoQuestions({ ...data, address: searchedAdd });
    console.log(data, "Form data", values);
    handleToggle();
    // onBtnClick();
  };

  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const ss = Object.values(stateCodesData);
  //   const stateVal = ss.map((i, k) => (i === propertyValues.state ? k : null));
  const stateValue = ss?.filter((e) => e === propertyValues?.state);
  console.log("selectedData==>", selectedData);
  return (
    <Box mt={3}>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          street_number:
            selectedData?.street_number || propertyValues?.street_number || "",
          site_route:
            selectedData?.site_route || propertyValues?.site_route || "",
          city: selectedData?.city || propertyValues?.city || "",
          state:
            selectedData?.state?.toUpperCase() ||
            USA_STATES?.find(
              (state) =>
                state?.code === propertyValues?.state ||
                state?.name?.toLowerCase() ===
                  propertyValues?.state?.toLowerCase()
            )?.code ||
            "",
          zip: selectedData?.zip || propertyValues?.zip || "",
          unit_no: selectedData?.unit_no || propertyValues?.unit_no || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, touched, errors, initialValues }) => {
          return (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Street Number"
                    name="street_number"
                    value={values?.street_number}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    onChange={(event) => {
                      setFieldValue("street_number", event.target.value);
                    }}
                    helperText={touched.street_number && errors.street_number}
                    error={
                      touched.street_number && Boolean(errors.street_number)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Street Name"
                    name="site_route"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.site_route}
                    onChange={(event) => {
                      setFieldValue("site_route", event.target.value);
                    }}
                    helperText={touched.site_route && errors.site_route}
                    error={touched.site_route && Boolean(errors.site_route)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="City"
                    name="city"
                    value={values?.city}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    onChange={(event) => {
                      setFieldValue("city", event.target.value);
                    }}
                    helperText={touched.city && errors.city}
                    error={touched.city && Boolean(errors.city)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <FormControl
                    fullWidth
                    className="sign-textField "
                    error={touched.state && Boolean(errors.state)}
                  >
                    <InputLabel
                      // id="demo-simple-select-placeholder"
                      label="State"
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      value={values?.state}
                      label="State"
                      name="state"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("state", event.target.value);
                      }}
                      MenuProps={MenuProps}
                    >
                      {USA_STATES?.map((state) => (
                        <MenuItem value={state?.code}>{state?.code}</MenuItem>
                      ))}
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.state && errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Zip"
                    name="zip"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.zip}
                    onChange={(event) => {
                      setFieldValue("zip", event.target.value);
                    }}
                    helperText={touched.zip && errors.zip}
                    error={touched.zip && Boolean(errors.zip)}
                  />
                </Grid>
                <Grid item xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Unit"
                    placeholder={"Unit"}
                    name="unit_no"
                    variant="outlined"
                    // type="number"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.unit_no}
                    onChange={(event) => {
                      setFieldValue("unit_no", event.target.value);
                    }}
                    helperText={touched.unit_no && errors.unit_no}
                    error={touched.unit_no && Boolean(errors.unit_no)}
                  />
                </Grid>

                {isByPassOptionAvailable && (
                  <>
                    <Grid item xs={12} md={12} px={1} pb={1}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <span className="pt-0">
                          <CustomizedCheckbox
                            onChecked={(e) => {
                              dispatch(
                                propertiesAction.setIsQuestionByPass(
                                  e.target.checked
                                )
                              );
                              // setIsQuestionByPass(e.target.checked);
                            }}
                          />
                        </span>
                        <span>
                          Do you want to bypass security questions for this
                          property?
                        </span>
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
              <div className="text-center stepBtn mt-5">
                {isAdminCreateUser &&
                  verifiedPropertiesList?.data?.length > 0 && (
                    <Button
                      className=" other_popup_btn next_button mb-3 mx-2"
                      variant="contained"
                      onClick={() => onCloseModalHandler("propertyList")}
                    >
                      Property List
                    </Button>
                  )}
                {getObjLength(selectedData) &&
                JSON.stringify(initialValues) === JSON.stringify(values) ? (
                  <Button
                    className="cursor-disable next_button mb-3"
                    variant="contained"
                  >
                    Next Step
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="next_button mb-3"
                    variant="contained"
                    // disabled={
                    //   JSON.stringify(initialValues) === JSON.stringify(values)
                    // }
                  >
                    Next Step
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
