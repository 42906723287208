import { put, call, takeLatest } from "redux-saga/effects";
import { getAPIPayload } from "../../../apis/getApi";
import { notificationActions } from "../../slices/notification/notificationSlice";

export function* fetchNotificationDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(notificationActions.setNotificationData(response));
}

export function* readNotificationSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(notificationActions.setReadNotification(response));
}

export function* markAllasReadSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(notificationActions.setMarkAllasRead(response));
  // call listing of notification again
  if (response?.success) {
    yield put(
      notificationActions.fetchNotificationData({
        data: {},
        token: true,
        url: "notifications",
      })
    );
  }
}

export function* watchNotificationSagaAsync() {
  yield takeLatest(
    notificationActions.fetchNotificationData,
    fetchNotificationDataSaga
  );
  yield takeLatest(notificationActions.readNotification, readNotificationSaga);
  yield takeLatest(notificationActions.markAllasRead, markAllasReadSaga);
}
