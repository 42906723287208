import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Typography } from "@mui/material";

import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import PersonalTokenScreen from "../../common/OTPScreen";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const validationSchema = Yup.object().shape({
  phone: Yup.string("Please enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Phone number is required"),
});

const EditPhoneNumber = ({
  uid,
  onClose,
  closePopup,
  phoneNumber,
  json_last_step,
  isEditPhoneNumber = false,
  callListingAPI,
}) => {
  const [phoneVerificationByPass, setPhoneVerificationByPass] = useState(false);
  const [otpVerificationScreen, setOTPVerificationScreen] = useState(false);
  const [updatedPhone, SetUpdatedPhone] = useState("");
  const [otpErr, SetOTPErr] = useState("");
  const [otpSucess, SetOTPSucess] = useState("");
  const [openPopup, SetOpenPopup] = useState(false);

  const dispatch = useDispatch();

  //initializes the timer states for hook
  const { stopTimer } = useTimer(120);

  const { editDataLodaer, upatedPhoneResponse } = useSelector(
    (state) => state.propertyUsers
  );

  const onSubmit = (values, errors) => {
    console.log(phoneVerificationByPass, "Form data", values);
    SetUpdatedPhone(values.phone);
    let inputParam = {
      value: values.phone,
      user_id: uid,
    };
    console.log(uid, "input params==>", inputParam);

    // if (phoneVerificationByPass) {
    dispatch(
      propertyUsersAction.getUpdatedPhone({
        url: "admin/user-update/phone",
        data: inputParam,
        token: true,
      })
    );
    // } else {
    //   setOTPVerificationScreen(true);
    // }
  };

  const onOTPSubmit = (otp) => {
    console.log(otp, "on submit==>.", otp);
  };

  const resendOTPSubmit = () => {
    console.log("resendOTPSubmit==>>");
    let inputParam = {
      value: updatedPhone,
      user_id: uid,
    };
  };

  const removeResendMsg = () => {
    SetOTPSucess("");
  };

  const onPopupClose = (type) => {
    SetOpenPopup(false);
    dispatch(propertyUsersAction.clearEditData());
    if (type !== "error") {
      callListingAPI();
      onClose();
      stopTimer();
    }
  };

  useEffect(() => {
    console.log("upatedEmailResponse==>", upatedPhoneResponse);
    if (getObjLength(upatedPhoneResponse)) {
      SetOpenPopup(true);
    }
  }, [upatedPhoneResponse]);

  const phn_num1 = phoneNumber?.substring(phoneNumber?.length - 4);

  return (
    <Box className="">
      {!otpVerificationScreen &&
      !upatedPhoneResponse?.success &&
      !upatedPhoneResponse?.error ? (
        <>
          <Button
            onClick={() => onClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3">
              {json_last_step?.step_key === "SEND_OTP" && !isEditPhoneNumber
                ? "Verify Mobile Number"
                : "Edit and Verify Mobile Number"}
            </Typography>
            <Box>
              <Box mt={4}>
                <Formik
                  enableReinitialize
                  validateOnChange
                  initialValues={{ phone: phoneNumber || "" }}
                  validationSchema={validationSchema}
                  // onSubmit={onSubmit}
                  onSubmit={(values, validation) => {
                    if (!isValidPhoneNumber(values?.phone)) {
                      return;
                    }
                    onSubmit(values);
                  }}
                >
                  {({ values, setFieldValue, touched, errors }) => {
                    return (
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          textAlign={"center"}
                          alignItems={"center"}
                        >
                          <Grid xs={12} md={12}>
                            <CustomPhoneNumberInput
                              name="phone"
                              values={values}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              disabled={
                                json_last_step?.step_key === "SEND_OTP" &&
                                !isEditPhoneNumber
                              }
                            />
                          </Grid>
                          {/* <Grid xs={12} md={12} px={2} mt={1}>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <span className="pt-1">
                                <CustomizedCheckbox
                                  onChecked={(e) => {
                                    setPhoneVerificationByPass(
                                      e.target.checked
                                    );
                                  }}
                                />
                              </span>
                              <span>
                                Do you want to bypass Mobile Number verification
                                ?
                              </span>
                            </Stack>
                          </Grid> */}

                          {/* {fromErr?.length > 0 && (
                          <p className="text-danger text-center">{fromErr}</p>
                        )} */}
                        </Grid>
                        <div className="text-center stepBtn mt-5">
                          <Button
                            className="popup_Btn other_popup_btn mx-2"
                            type="submit"
                            variant="contained"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className={`popup_Btn success_popup_btn `}
                            variant="contained"
                          >
                            Verify
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        !upatedPhoneResponse?.success &&
        !upatedPhoneResponse?.error && (
          <>
            <Box>
              <PersonalTokenScreen
                subTitle={`Please enter the 6 digit security code we have sent to your mobile
             phone * *** *** ${phn_num1 ? phn_num1 : "4321"}`}
                //   phoneNumber={phoneNumber}
                onsubmit={onOTPSubmit}
                resendOTPSubmit={resendOTPSubmit}
                otpSucess={otpSucess}
                errOTP={otpErr}
                removeResendMsg={removeResendMsg}
              />
            </Box>
          </>
        )
      )}
      {openPopup && getObjLength(upatedPhoneResponse) && (
        <>
          {upatedPhoneResponse?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("success")}
              message={upatedPhoneResponse?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("error")}
              message={upatedPhoneResponse?.data?.message}
              footer={false}
            />
          )}
        </>
      )}

      <Loader open={editDataLodaer} />
    </Box>
  );
};

export default EditPhoneNumber;
