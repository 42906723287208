import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogTitle, Typography } from "@mui/material";
import PropertySearchType from "../../userProfile/add-property/PropertySearchType";
import SecurityInfoAndCardPayment from "../../userProfile/add-property/SecurityInfoAndCardPayment";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../common/loader";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";

const EditPropertyDetails = ({
  openPopup,
  closePopup,
  selectedProperty,
  callListingAPI = () => {},
}) => {
  const [screen, setScreen] = useState("security");
  const dispatch = useDispatch();
  const { editPropertiesDetails, propertyLoader } = useSelector(
    (state) => state.properties
  );

  const handleCloseAddPropertyModal = (event) => {
    console.log("event==>", event);
    if (event === "success") {
      callListingAPI();
    }
    dispatch(propertiesAction.clearGeneratedQuestionsData({}));
    dispatch(propertiesAction.clearPropertiesList());
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
    setScreen("security");
    closePopup(false);
  };

  const isPaymnetDone = (event) => {
    if (
      !selectedProperty?.subscription &&
      selectedProperty?.subscription === null
    ) {
      setScreen("subscriptions");
    } else {
      handleCloseAddPropertyModal(event);
    }
  };
  console.log("editPropertiesDetails==>", editPropertiesDetails);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openPopup}
        onClose={() => {
          closePopup(false);
          handleCloseAddPropertyModal();
        }}
        // scroll={scroll}
        className={`dialogWrapper zoom-modal-wrapper`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box sx={{ p: 4, position: "relative" }} className="dialog-header">
        {/* <p className="close-text">Close</p> */}
          <Button
          className="close-text hide-outer-close"
          sx={{ textTransform: 'none' }}
            onClick={() => {
              closePopup(false);
              handleCloseAddPropertyModal();
            }}
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              color: "black",
              background: "transparent",
            }}
          >
            Close
            <CloseIcon sx={{ color: "#15be53", fontSize:"2.5rem" }} />
          </Button>
          <Box className="zoom-container">
            {screen === "security" ? (
              <PropertySearchType
                onBtnClick={(e) => {
                  // e.preventDefault();
                  console.log("submitted");
                }}
                handleCloseAddPropertyModal={handleCloseAddPropertyModal}
                isByPassOptionAvailable={true}
                onChangeModalScreen={(scr = "questions") => setScreen(scr)}
                // setIsSubscriptionScreenRequired={setIsSubscriptionScreenRequired}
                onCloseModalHandler={(event) => isPaymnetDone(event)}
                editProperty={true}
                isAdminCreateUser={false}
                selectedData={selectedProperty}
                prop_id={selectedProperty?.id}
              />
            ) : (
              <SecurityInfoAndCardPayment
                screen={screen}
                onChangeModalScreen={setScreen}
                handleCloseAddPropertyModal={handleCloseAddPropertyModal}
                backToSearchProperty={setScreen}
                onCloseModalHandler={(event) => {
                  handleCloseAddPropertyModal("success");
                }}
                editProperty={true}
                isByPassOptionAvailable={true}
                selectedData={selectedProperty}
                from={"add-property"}
                addPromoCode={true}
              />
            )}
          </Box>
        </Box>
        <Loader open={propertyLoader} />
      </Dialog>
    </>
  );
};

export default EditPropertyDetails;
