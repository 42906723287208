import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MODAL_PAGE } from "../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { seviceProvidersAction } from "../../../redux/slices/admin/serviceProviders/seviceProvidersSlice";
import {
  getLocalFormatedDate,
  getObjLength,
  getURLName,
} from "../../helpers/utils";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { Stack } from "react-bootstrap";

const HistoryServiceAgreements = ({ selectedItem, selectedCollapsibleRow }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState({});
  const { historyServiceAgreements } = useSelector(
    (state) => state.seviceProviders
  );
  const count = Math.ceil(historyServiceAgreements?.count / MODAL_PAGE);

  const columns = [
    {
      id: "no",
      disablePadding: true,
      label: "No.",
    },
    {
      id: "serviceagreement",
      disablePadding: true,
      label: "Service Agreement",
    },
    {
      id: "uploadedby",
      disablePadding: true,
      label: "Uploaded By",
    },
    {
      id: "date",
      disablePadding: true,
      label: "Uploaded at",
    },
  ];

  const trimUrl = (url) => {
    const underscoreIndex = url.indexOf("_");
    if (underscoreIndex !== -1) {
      return url.substring(underscoreIndex + 1);
    }
    return url;
  };

  const pageChangeHandler = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * MODAL_PAGE }));
  };

  const getSeviceProvidersData = () => {
    let url = `admin/service-provider/get-service-agreement/${selectedCollapsibleRow?.id}`;

    dispatch(
      seviceProvidersAction.getHistoryAgreementsData({
        url: url,
        data: {
          limit: MODAL_PAGE,
          offset: payload?.offset || 0,
          order_by_column: payload?.order_by_column || "updated_at",
          sorting_order: payload?.sorting_order || "desc",
        },
        token: true,
      })
    );
  };
  useEffect(() => {
    getSeviceProvidersData();
  }, [payload]);

  function EnhancedTableHead(props) {
    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell key={headCell.id} align={"left"} padding={"normal"}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 py-4">
        {historyServiceAgreements?.data?.map((row, index) => {
          return (
            <>
              <Stack direction="column" spacing={1.2} px={2}>
                <Stack direction="column" spacing={0.5}>
                  <Typography className="key" spacing={0.5}>
                    Service Agreement:
                  </Typography>
                  <Typography className="value text-capitalize">
                    <CustomLinkWrapper
                      link={row?.doc_url ? row?.doc_url : "#"}
                      title={trimUrl(row?.doc_url)}
                      key={row?.doc_url}
                      classes="preview-url"
                    />
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Uploaded By:</Typography>
                  <Typography className="value">
                    {row?.uploaderName ? row?.uploaderName : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Uplaoded at:</Typography>
                  <Typography className="value">
                    {row?.created_at
                      ? getLocalFormatedDate(row?.created_at)
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              {index < historyServiceAgreements?.data?.length - 1 && (
                <Divider
                  className="my-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {getObjLength(historyServiceAgreements) &&
          !historyServiceAgreements?.data?.length > 0 && (
            <Typography
              className="d-block d-sm-none p-5 text-center"
              variant="h6"
            >
              No Data Found.
            </Typography>
          )}
      </Box>

      {/* Desktop View */}
      <Paper sx={{ width: "100%", mb: 2 }} className="d-none d-sm-block">
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              // onRequestSort={handleRequestSort}
              rowCount={
                historyServiceAgreements?.success &&
                historyServiceAgreements?.data?.length
              }
              columns={columns}
            />
            <TableBody>
              {historyServiceAgreements?.success &&
                historyServiceAgreements?.data?.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell align="left">
                        {(page - 1) * MODAL_PAGE + index + 1}
                      </TableCell>

                      <TableCell align="left">
                        <CustomLinkWrapper
                          link={row?.doc_url ? row?.doc_url : "#"}
                          title={trimUrl(row?.doc_url)}
                          key={row?.doc_url}
                          classes="preview-url"
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row?.uploaderName ? row?.uploaderName : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.created_at)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!historyServiceAgreements?.data?.length > 0 && (
            <Typography className="p-5 text-center" variant="h6">
              No Data Found.
            </Typography>
          )}
        </TableContainer>
      </Paper>
      {
        //   historyServiceAgreements?.data?.length
        historyServiceAgreements?.count > MODAL_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={pageChangeHandler}
            />
          </Box>
        )
      }
    </>
  );
};

export default HistoryServiceAgreements;
