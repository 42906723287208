import { useEffect, useState } from "react";

const useCloseCameraStream = () => {
  const [stream, setStream] = useState(null);
  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  const updateCameraState = (newStream) => {
    setStream(newStream);
  };
  return { updateCameraState };
};

export default useCloseCameraStream;
