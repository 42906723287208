import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../helpers/constants";
import ProviderPropertyForm from "./ProviderPropertyForm";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../common/AlertDialog";

const FindPropertyByAddress = ({ setScreen = () => {} }) => {
  const [otherPropertyValues, setOtherPropertyValues] = useState({});
  const [propertyValues, setPropertyValues] = useState({});
  const [addressPopup, setAddressPopup] = useState(
    localStorage.getItem("searched_Add_popup")
  );
  const [existingAdd, setExistingAdd] = useState("");
  const [editAdd, setEditAdd] = useState({});

  const { selectedPropertyData, getPropertyData, verifiedProperties } =
    useSelector((state) => state.signUpUser);

  const dispatch = useDispatch();

  const dataValues = (data) => {
    console.log("go to questuionss==>", data);
    dispatch(
      userSigupAction.getUserProperty({
        url: "properties",
        data: data,
        token: false,
      })
    );
  };

  const handleChange = (place) => {
    var address = place.formatted_address;
    console.log("Place values==?>", place);
    console.log(
      "Place values filterd==>",
      place.address_components.filter((i, k) =>
        i.types.includes("street_number")
      )
    );
    var value = address?.split(",");
    const count = value.length;
    const country = value[count - 1];
    const stateVal = value[count - 2];
    const city = value[count - 3];
    const streetAdd = value[count - 4] !== undefined ? value[count - 4] : "";
    var stateSplit = stateVal?.split(" ");
    var i = stateSplit?.length;
    var pincode = "";
    const state = stateSplit[1];
    if (i > 2) {
      pincode = stateSplit[2];
    }
    var ss = streetAdd?.split(" ");
    console.log(ss.length, "ss==>.", ss);
    const sn = place.address_components.filter((i, k) =>
      i.types.includes("street_number")
    );
    var streetNum = sn.length > 0 ? sn[0]?.long_name : "";
    const sr = place.address_components.filter((i, k) =>
      i.types.includes("route")
    );
    var site_route = sr.length > 0 ? sr[0]?.long_name : "";
    const condos = place.address_components.filter((i, k) =>
      i.types.includes("subpremise")
    );
    var units = condos.length > 0 ? condos[0]?.long_name : "";

    // if (ss.length > 2) {
    //   streetNum = ss[0];
    //   site_route = ss[1] + " " + ss[2];
    // } else {
    //   streetNum = "";
    //   site_route = streetAdd;
    // }
    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    let param = {
      street_number: streetNum,
      site_route: site_route,
      city: city,
      state: state,
      zip: pincode,
      unit_no: units,
      address: streetAdd,
    };
    let fixParams = {
      country: country,
      latitude: latitude,
      longitude: longitude,
      address: streetAdd,
    };
    console.log(value, "map lag==>>", param);
    setPropertyValues(param);
    setOtherPropertyValues(fixParams);
    // setShowPropertyListing(false);
  };

  const addExistingAddress = () => {
    console.log(
      "addExistingAddress called",
      JSON.parse(localStorage.getItem("param"))
    );
    let userAddress = JSON.parse(localStorage.getItem("userSignupAddress"));
    let param = JSON.parse(localStorage.getItem("param"));
    let fixParams = JSON.parse(localStorage.getItem("fixParams"));
    let addData = {
      street_number:
        userAddress?.address && !isNaN(userAddress?.address?.split(" ")?.[0])
          ? userAddress?.address?.split(" ")?.[0]
          : "",
      site_route: userAddress?.address
        ? isNaN(userAddress?.address?.split(" ")?.[0])
          ? userAddress?.address
          : userAddress?.address?.split(" ")?.slice(1)?.join(" ")
        : "",
      city: userAddress?.city || "",
      state: userAddress?.state || "",
      unit_no: userAddress?.unit_no || "",
      zip: userAddress?.zip || "",
    };
    console.log(
      isNaN(userAddress?.address?.split(" ")?.[0]),
      "addData==>>",
      addData
    );
    setPropertyValues(param || addData);
    setOtherPropertyValues(fixParams);
    setExistingAdd(localStorage.getItem("searched_Add"));
    setAddressPopup(false);
  };

  useEffect(() => {
    console.log("Store data 44 ==>", getPropertyData);
    if (getPropertyData?.success) {
      if (getPropertyData?.data?.length === 1) {
        dispatch(
          userSigupAction.selectedUserPropertyData(getPropertyData?.data[0])
        );
        // onBtnClick();
      }
    }
  }, [getPropertyData]);

  useEffect(() => {
    console.log("selected property 22 ==>", selectedPropertyData);
    console.log("selected property ==>", getPropertyData);
  }, [selectedPropertyData, getPropertyData]);

  return (
    <Box
      className="accountBoxWrapper box-container bg-white"
      sx={{ pt: 2, pb: 0, px: "0 !important", mx: "0 !important" }}
    >
      {
        <>
          <>
            <ReactGoogleAutocomplete
              className="lennar-googleAutoComplete mw-100"
              apiKey={GOOGLE_API_KEY}
              value={existingAdd}
              onPlaceSelected={(address) => {
                console.log("address==>", address);
                setExistingAdd(address?.formatted_address);
                handleChange(address);
              }}
              onChange={(e) => {
                console.log("add", e);
                setExistingAdd(e.target.value);
              }}
              onKeyDown={(event) =>
                event.key === "Enter" ? event.preventDefault() : null
              }
              placeholder="Enter your property address"
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
            />
            <ProviderPropertyForm
              propertyValues={propertyValues}
              otherPropertyValues={otherPropertyValues}
              verifiedPropertiesList={verifiedProperties}
              onBtnClick={() => handleChange()}
              gotoQuestions={dataValues}
              setScreen={setScreen}
              handleToggle={() => {}}
            />
          </>
        </>
      }

      {addressPopup && (
        <AlertDialog
          type="Attention"
          width="sm"
          openPopup={addressPopup}
          onClose={true}
          onCloseFun={() => setAddressPopup(false)}
          mesage={
            "  Would you like to use the address you've provided with the account?"
          }
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => setAddressPopup(false)}
              >
                Nope
              </Button>
              <Button
                className="popup_Btn success_popup_btn "
                onClick={() => addExistingAddress()}
              >
                Yes
              </Button>
            </>
          }
        />
      )}
    </Box>
  );
};

export default FindPropertyByAddress;
