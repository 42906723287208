import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  RadioGroup,
  TextField,
  Box,
  Radio,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import { useRef } from "react";
import { useState } from "react";
import { Loader } from "./loader";
import { getObjLength } from "../helpers/utils";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";

const signatureStyle = {
  letterSpacing: 1.5,
  fontSize: "23px",
  fontWeight: "bold",
  color: "#555",
  textShadow: "1px 1px 1px #ccc",
};

const SignatureLists = ({ userName, error, removeErrors, setSign }) => {
  const dispatch = useDispatch();
  let divRef1 = useRef();
  let divRef2 = useRef();
  let divRef3 = useRef();
  let divRef4 = useRef();
  let divRef5 = useRef();
  let divRef6 = useRef();
  const [signOption, setSignOption] = useState("");
  const [signValue, setSignValue] = useState(userName);
  const [signValue1, setSignValue1] = useState(userName);
  const [loader, setLoader] = useState(false);

  const convertDivToImage = (ref) => {
    if (getObjLength(ref)) {
      //seletedSign
      html2canvas(ref.current, { scale: 5 }).then((canvas) => {
        const dataUrl = canvas.toDataURL();
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
          console.log("Images==>", dataUrl);
          dispatch(userSigupAction.signBase64Value(dataUrl));
          setLoader(false);
        };
      });
    }
  };

  const selectOptions = (e) => {
    if (signValue?.trim()?.length > 0 || signValue1?.trim()?.length > 0) {
      setLoader(true);
      removeErrors();
      setSignOption(e.target.value);
      // convertDivToImage();
    }
  };

  const SignStyle = [
    { fontFamily: "AlexBrush-Regular", option: "one", ref: divRef1 },
    { fontFamily: "Yellowtail-Regular", option: "two", ref: divRef2 },
    { fontFamily: "Sacramento-Regular", option: "three", ref: divRef3 },
    { fontFamily: "Pacifico", option: "four", ref: divRef4 },
    { fontFamily: "DancingScript", option: "five", ref: divRef5 },
    { fontFamily: "Inspiration", option: "six", ref: divRef6 },
  ];

  const FieldStyle = {
    borderRadius: "3px",
  };
  //   useEffect(() => {
  //     setSign("");
  //   }, []);

  return (
    <>
      <Box className="pt-2 pe-3 mb-3 pb-0">
        <TextField
          className="sign-textField "
          label="Signature name"
          variant="outlined"
          sx={FieldStyle}
          value={signValue}
          autoFocus
          InputLabelProps={{
            style: {
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#00000075",
            },
            shrink: true,
          }}
          placeholder={"Enter Your Name for Signature"}
          onChange={(event) => {
            setSignOption("");
            dispatch(userSigupAction.signBase64Value());

            setSignValue(event.target.value);
            setSignValue1(event.target.value);
            if (event.target.value?.length === 0) {
              setSignValue1(userName);
            }
          }}
        />
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        px={0}
        mx={0}
      >
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            defaultValue={signOption}
            value={signOption}
            aria-labelledby="payment-types"
            name="payment-modes"
            onChange={selectOptions}
          >
            {SignStyle.map((i, k) => (
              <Grid xs={12} md={6} px={0} pb={1}>
                <Paper
                  className="shadow  bg-white rounded mx-0 mx-sm-2"
                  sx={{
                    p: 2,
                    my: 1,
                    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                    borderRadius: "4px",
                  }}
                >
                  <FormControlLabel
                    value={i.option}
                    className="paymentMode w-100"
                    control={<Radio />}
                    onClick={() => convertDivToImage(i.ref)}
                    label={
                      <Typography
                        variant="body1"
                        className="d-flex align-items-center justify-content-between text-dark m-0 fw-600"
                      >
                        <div
                          style={{
                            ...signatureStyle,
                            fontFamily: i.fontFamily,
                          }}
                        >
                          <span
                            id={i.id}
                            ref={i.ref}
                            style={{
                              padding: "2px 10px",
                            }}
                          >
                            {signValue1 || signValue}
                          </span>
                        </div>
                      </Typography>
                    }
                  />
                </Paper>
              </Grid>
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      {error}
      <Loader open={loader} />
    </>
  );
};

export default SignatureLists;
