import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Typography,
} from "@mui/material";
import CustomizedRadios from "../../common/customDesignedComponents/customRadiobutton";
import { Loader } from "../../common/loader";
import { useSelector } from "react-redux";

const DocumentTypes = ({ changeDocTypeStep, sendReqErr }) => {
  const documentTypeList = useSelector(
    (state) => state.properties.documentTypes
  );
  const reqLoader = useSelector((state) => state.properties.reqLoader);

  const selectDocumentReport = (e) => {
    console.log("documentTypeList==>.", documentTypeList);
    console.log("Selected documentReport==>", e.target.value);
    let price = documentTypeList.data.filter(
      (i) => (i.type || i.key) === e.target.value
    );
    console.log("filter Doc==>", price);
    changeDocTypeStep({ type: e.target.value, price: price[0].price });
  };

  return (
    <Box className="step1 documentSteps">
      <FormControl>
        <RadioGroup
          defaultValue={documentTypeList?.data?.[0].key}
          aria-labelledby="report-types"
          name="document-report"
          onChange={selectDocumentReport}
        >
          {documentTypeList?.data?.map((i, k) => {
            return (
              <FormControlLabel
                value={i.type || i.key}
                className="docReport"
                control={<CustomizedRadios />}
                label={
                  <Typography variant="body1">
                    <span className="docName me-1">{i.name}</span>-
                    <span className="ms-1">${i.price}.00</span>
                  </Typography>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Loader open={reqLoader} />
    </Box>
  );
};

export default DocumentTypes;
