import React, { useCallback, useEffect, useState } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import AlertDialog from "../../common/AlertDialog";
import EditEmail from "../PropertyUsers/EditEmail";
import EditPhoneNumber from "../PropertyUsers/EditPhoneNumber";
import EditProvidersDetails from "./EditProvidersPersonalDetails";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import {
  BASE_URL,
  COUNTRY_CODE,
  CUSTOM_DOMAIN_URL,
  OTHER_PROVIDERS_URL,
  PER_PAGE,
} from "../../helpers/constants";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { otherProvidersAction } from "../../../redux/slices/admin/other-providers/otherProvidersSlice";
import {
  currencyFormatter,
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

const columns = [
  {
    key: 1,
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 5,
  },
  {
    key: 2,
    id: "logo",
    disablePadding: true,
    label: "Logo",
    minWidth: 85,
  },
  {
    key: 3,
    id: "provider_name",
    disablePadding: true,
    label: "Provider's Name",
    minWidth: 140,
  },
  {
    key: 4,
    id: "email",
    disablePadding: true,
    label: "Email",
    minWidth: 270,
  },
  {
    key: 5,
    id: "phone",
    disablePadding: true,
    label: "Phone",
    minWidth: 155,
  },
  // {
  //   key: 5,
  //   id: "domain",
  //   disablePadding: true,
  //   label: "Domain",
  //   minWidth: 100,
  // },
  {
    key: 6,
    id: "status",
    disablePadding: true,
    label: "Status ",
    minWidth: 90,
  },
  {
    key: 7,
    id: "created_at",
    disablePadding: true,
    label: "Created",
    sorting: true,
    minWidth: 120,
  },
  {
    key: 8,
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    sorting: true,
    minWidth: 120,
  },
  {
    key: 9,
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 20,
  },
];

const providerStatusOptions = [
  {
    value: 0,
    label: "Inactive",
  },
  {
    value: 1,
    label: "Active",
  },
];

function Row({
  row,
  open,
  handleMenuClick,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
}) {
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="left" onClick={(e) => e.stopPropagation()}>
          <span className="company-logo">
            {/* {row?.company_logo ? ( */}
            <Avatar src={row?.logo} />
            {/* ) : (
              <Avatar
                {...stringAvatar(
                  capitalizeFirstCharInWord(
                    `${row?.first_name} ${row?.last_name}`
                  )
                )}
              />
            )} */}
          </span>
        </TableCell>
        <TableCell align="left">
          {`${row?.user?.first_name} ${row?.user?.last_name}`}
        </TableCell>
        <TableCell align="left" sx={{ wordBreak: "break-all" }}>
          {row?.user?.id ? row?.user?.email : "-"}
        </TableCell>
        <TableCell align="left">{getFormatedPhoneNumber(row?.phone)}</TableCell>
        {/* <TableCell align="left">{row?.domain || "-"}</TableCell> */}
        <TableCell align="left">
          {row?.is_active ? (
            <span className="text-success">Active</span>
          ) : (
            <span className="text-danger">In active</span>
          )}
        </TableCell>
        <TableCell align="left">
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.created_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>
        <TableCell sx={{ width: 180 }} align="left">
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleMenuClick(e, row);
              }}
              size="small"
              className="settings-button"
              aria-controls={open ? "settings-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <SettingsIconSVG />
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={2}
        ></TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Benutech Property
                </Typography>
                <Typography className="content-text m-0" component="div">
                  {row?.is_benutech_property ? "Yes" : "No"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Redirection URL
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.redirect_url || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Webhook URL
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.webhook_url || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Send Postcard
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.send_postcard ? "Yes" : "No"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={3}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Single Sing On
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.is_sso ? "Yes" : "No"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={2}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Recording Fees
                </Typography>
                <Typography className="content-text m-0" component="div">
                  {row?.plans?.[0]?.additional_charges?.recording_fees
                    ? currencyFormatter(
                        row?.plans?.[0]?.additional_charges?.recording_fees
                      )
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Domain Name
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.domain || row?.custom_domain || "-"}{" "}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Domain URL
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.domain ? BASE_URL + "providers/" + row?.domain : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={3}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography className="content-heading" component="div">
                  Create Account URL
                </Typography>

                <Typography className="content-text mw-380" component="div">
                  {row?.is_customer_ep
                    ? row?.domain
                      ? OTHER_PROVIDERS_URL + row?.domain
                      : CUSTOM_DOMAIN_URL + row?.custom_domain + ".com/"
                    : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {row?.plans?.find((plan, i) => plan?.name === "Monthly") && (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={2}
          ></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Plan
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <b>Monthly</b>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? currencyFormatter(
                          parseFloat(
                            row?.plans?.find(
                              (plan, i) =>
                                plan?.name === "Monthly" && plan?.is_default
                            )?.amount
                          )
                          //  +parseFloat(
                          //    row?.plans?.find(
                          //    (plan, i) =>
                          //        plan?.name === "Monthly" && plan?.is_default
                          //    )?.additional_charges?.["setup fees"] || 0
                          //  )
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Renewal Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Monthly" && !plan?.is_default
                          )?.amount
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Trial Period
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? row?.plans?.find(
                          (plan, i) =>
                            plan?.name === "Monthly" && plan?.is_default
                        )?.trial_period?.day + " days"
                      : "-"}{" "}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Setup Fees
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Monthly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Monthly" && plan?.is_default
                          )?.additional_charges?.["setup fees"]
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {row?.plans?.find((plan, i) => plan?.name === "Yearly") && (
        <TableRow
          className="collapsible-row"
          sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={2}
          ></TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    Plan
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <b>Yearly</b>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Yearly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Yearly" && plan?.is_default
                          )?.amount
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Renewal Amount
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Yearly")
                      ? currencyFormatter(
                          row?.plans?.find(
                            (plan, i) =>
                              plan?.name === "Yearly" && !plan?.is_default
                          )?.amount
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={2}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <Stack direction="column">
                  <Typography className="content-heading" component="div">
                    Trial Period
                  </Typography>

                  <Typography className="content-text mw-380" component="div">
                    {row?.plans?.find((plan, i) => plan?.name === "Yearly")
                      ? row?.plans?.find(
                          (plan, i) =>
                            plan?.name === "Yearly" && plan?.is_default
                        )?.trial_period?.day + " days"
                      : "-"}{" "}
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          ></TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const ProvidersListTable = ({ listingAPI = () => {} }) => {
  const dispatch = useDispatch();
  const queryString = window.location.pathname;
  const userId = queryString.split("/")?.[2];

  // states
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedTerms, setSelectedTerms] = useState("");
  const [providerStatus, setProviderStatus] = useState(undefined);
  const [sendPostcard, setSendPostcard] = useState(undefined);
  const [phoneNumberVal, setPhoneNumberVal] = useState("");
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [payload, setPayload] = useState({});
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(8); // updated_at key for sorting
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [editProvider, setEditProvider] = useState(false);
  const [editPlans, setEditPlans] = useState(false);
  const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] =
    useState(false);
  const [editMobileNumberAlert, setEditMobileNumberAlert] = useState(false);
  const [editEmailAlert, setEditEmailAlert] = useState(false);
  const [isEditPhoneNumber, setIsEditPhoneNumber] = useState(false);
  const [isEditEmailId, setIsEditEmailId] = useState(false);
  const [userID, setUserID] = useState(userId);
  const open = Boolean(anchorEl);

  // store data
  const {
    otherProvidersLoader,
    otherProvidersList,
    activateInactivateDetails,
    domainListDataList,
  } = useSelector((state) => state?.otherProviders);

  const _DATA = otherProvidersList?.success ? otherProvidersList?.data : [];
  const count = Math.ceil(otherProvidersList?.count / PER_PAGE);

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  const handleFilterProperty = (e) => {
    e.preventDefault();
    console.log("providerStatus==>", providerStatus);
    if (selectedTerms || providerStatus || selectedDateRange || sendPostcard) {
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        term: selectedTerms || undefined,
        status: providerStatus || undefined,
        send_postcard: sendPostcard || undefined,
        from_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          selectedKey === "updated_at" || selectedDateType === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE || 10,
        offset: 0,
      }));
    }
  };

  const resetAllFilterOptions = () => {
    // if (selectedTerms || providerStatus || selectedDateRange) {
    setPage(1);
    setSelectedKey("all");
    setSelectedTerms("");
    setProviderStatus(undefined);
    setSendPostcard(undefined);
    setPayload();
    setSelectedDateRange(null);
    setDirectionFlow(true);
    setSelectedDateType("created_at");
    setSelectedColumn(8);
    setUserID(undefined);
    // }
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  // get listing of the property user
  const getProviderListingData = useCallback(() => {
    let URL = "";
    console.log("payload==>.", payload);
    if (userId?.length > 0) {
      URL = `providers`;
    } else {
      URL = `providers`;
    }
    dispatch(
      otherProvidersAction.otherProvidersListData({
        url: URL,
        data: {
          type:
            payload?.term === "is_customer_ep"
              ? "is_customer_ep"
              : payload?.type,
          is_customer_ep: payload?.term === "is_customer_ep" ? 1 : undefined,
          term: payload?.term !== "is_customer_ep" ? payload?.term : undefined,
          id: userID,
          is_active: payload?.status,
          send_postcard: payload?.send_postcard,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          modified_from_date: payload?.modified_from_date,
          modified_to_date: payload?.modified_to_date,
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          order_by_column: payload?.order_by_column || "updated_at",
          sorting_order: payload?.sorting_order || "desc",
        },
        token: true,
      })
    );
  }, [payload, dispatch]);

  useEffect(() => {
    getProviderListingData();
  }, [getProviderListingData]);

  useEffect(() => {
    dispatch(
      otherProvidersAction.domainListData({
        url: "admin/providers-list",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  const handleMenuClick = (event, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event) => {
    if (event === "edit_personal_details") {
      setEditProvider(true);
    }
    if (event === "edit_plans_details") {
      setEditPlans(true);
    }
    if (event === "active" || event === "inactive") {
      setIsActivateDeactivateModalOpen(true);
    }
    if (event === "edit_phone") {
      setEditMobileNumberAlert(true);
    }
    if (event === "edit_email") {
      setEditEmailAlert(true);
    }
  };

  const handleActivateInactivateProvider = (selectedProvider) => {
    console.log("selectedProvider", selectedProvider);
    if (selectedProvider?.id) {
      dispatch(
        otherProvidersAction.activateInactivateProvider({
          url: `providers/${selectedProvider?.id}/status`,
          data: { is_active: selectedProvider?.is_active ? 0 : 1 },
          token: true,
        })
      );
    }
  };

  const closeDialogBox = () => {
    setIsEditPhoneNumber(false);
    setEditPlans(false);
    setIsEditEmailId(false);
    setEditProvider(false);
    setEditEmailAlert(false);
    setEditMobileNumberAlert(false);
  };

  return (
    <>
      {/* {otherProvidersList?.success ? ( */}
      <>
        {/* Filtering Component Section */}
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
        {/* Filtering Options */}
        <Box
          sx={{
            // overflow: "hidden",
            // overflowX: "auto",
            mb: 1.5,
          }}
        >
          {toggleFilterOptions && (
            <>
              {/* for mobile view */}

              <Box className="d-block d-md-none">
                <Grid
                  container
                  className="bg-white d-flex justify-content-around align-items-center px-4"
                  spacing={2}
                  pb={2}
                  pt={2}
                >
                  <Grid item xs={6} sm={6} md={4} lg={2}>
                    <Typography className="search-text">Search By</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        onChange={(e) => {
                          setSelectedKey(e.target.value);
                          setSelectedTerms("");
                          setProviderStatus(undefined);
                          setSendPostcard(undefined);
                          setSelectedDateRange(null);
                          if (e.target.value === "all") {
                            setSelectedDateType("created_at");
                          } else {
                            setSelectedDateType("");
                          }
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.label !== "No." &&
                            cell.label !== "Logo" &&
                            cell.value !== "Actions" && (
                              <MenuItem key={cell.id} value={cell.value}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                        <MenuItem key="domain" value="domain">
                          Domain
                        </MenuItem>
                        <MenuItem key="send_postcard" value="send_postcard">
                          Send Postcard
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  {selectedKey !== "status" &&
                    selectedKey !== "send_postcard" &&
                    selectedKey !== "phone" &&
                    selectedKey !== "domain" &&
                    selectedKey !== "is_customer_ep " &&
                    selectedKey !== "updated_at" &&
                    selectedKey !== "created_at" && (
                      <Grid item xs={12} sm={12} md={4} lg={2}>
                        <FormControl
                          className="filter-search w-100"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            value={selectedTerms}
                            onChange={(e) => setSelectedTerms(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  {(selectedKey === "all" || selectedKey === "status") && (
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="filter-select-one w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Provider's Status"
                          value={providerStatus}
                          onChange={(e) =>
                            setProviderStatus(e.target.value.toString())
                          }
                        >
                          {providerStatusOptions?.map((status, key) => (
                            <MenuItem value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {selectedKey === "send_postcard" && (
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="filter-select-one w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Send Postcard"
                          value={sendPostcard}
                          onChange={(e) =>
                            setSendPostcard(e.target.value.toString())
                          }
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {selectedKey === "domain" && (
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="filter-select-one w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Domain Name"
                          value={selectedTerms}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedTerms(selectedValue);
                          }}
                        >
                          <MenuItem key="is_customer_ep" value="is_customer_ep">
                            By Equity Protect
                          </MenuItem>
                          {domainListDataList?.data?.map((val) => {
                            const domain = val.domain || val.custom_domain;
                            return (
                              <MenuItem
                                value={domain}
                              >
                                {domain}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {selectedKey === "phone" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <Box
                        className={`phone-number-input bg-white filter w-100`}
                      >
                        <PhoneInput
                          className="bg-white"
                          international={false}
                          placeholder="Phone Number"
                          value={phoneNumberVal}
                          countries={["US", "IN"]}
                          defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                          countryOptionsOrder={["US", "IN"]}
                          addInternationalOption={false}
                          onChange={(event) => {
                            setPhoneNumberVal(event);
                            setSelectedTerms(event);
                          }}
                        />
                      </Box>
                    </Grid>
                  )}

                  {selectedKey === "all" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl className="filter-select-one w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Date type"
                          value={selectedDateType}
                          onChange={(e) => {
                            setSelectedDateType(e.target.value);
                          }}
                        >
                          {[
                            { label: "Created At", value: "created_at" },
                            { label: "Updated At", value: "updated_at" },
                          ].map((data, key) => (
                            <MenuItem value={data.value}>{data.label}</MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}
                  {(selectedKey === "all" ||
                    selectedKey === "created_at" ||
                    selectedKey === "updated_at") && (
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="filter-search w-100">
                        <CustomDateRangePicker
                          rangeValue={selectedDateRange}
                          onChange={(values) => {
                            setSelectedDateRange(values);
                          }}
                          onClear={() => {
                            setSelectedDateRange(null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <FormControl className="w-100">
                      <Button
                        type="submit"
                        onClick={handleFilterProperty}
                        sx={mobileFilterResultStyle}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Button
                      type="reset"
                      onClick={resetAllFilterOptions}
                      sx={mobileFilterResetBtnStyle}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              {/* for desktop view */}
              <Card
                className="d-none d-md-block filter-options-carddiv"
                // id="filter-options-card"
              >
                <Grid container className="filter-options-box">
                  <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <div className="search-text">Search By</div>
                      <FormControl className="filter-select-one">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          value={selectedKey}
                          name="type"
                          onChange={(e) => {
                            console.log("selected==>", e.target.value);
                            setSelectedKey(e.target.value);
                            setSelectedTerms("");
                            setProviderStatus(undefined);
                            setSendPostcard(undefined);
                            setSelectedDateRange(null);
                            if (e.target.value === "all") {
                              setSelectedDateType("created_at");
                            } else {
                              setSelectedDateType("");
                            }
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          {columns.map(
                            (cell) =>
                              cell.label !== "No." &&
                              cell.label !== "Logo" &&
                              cell.label !== "Actions" && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.label}
                                </MenuItem>
                              )
                          )}
                          <MenuItem key="domain" value="domain">
                            Domain
                          </MenuItem>
                          <MenuItem key="send_postcard" value="send_postcard">
                            Send Postcard
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </Stack>
                  </Grid>

                  {selectedKey !== "status" &&
                    selectedKey !== "send_postcard" &&
                    selectedKey !== "phone" &&
                    selectedKey !== "domain" &&
                    selectedKey !== "is_customer_ep " &&
                    selectedKey !== "updated_at" &&
                    selectedKey !== "created_at" && (
                      <Grid item>
                        <FormControl
                          className="filter-search"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            value={selectedTerms}
                            onChange={(e) => setSelectedTerms(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}

                  {(selectedKey === "all" || selectedKey === "status") && (
                    <Grid item>
                      <FormControl className="filter-select-one">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Provider's Status"
                          value={providerStatus}
                          onChange={(e) =>
                            setProviderStatus(e.target.value.toString())
                          }
                        >
                          {providerStatusOptions?.map((status, key) => (
                            <MenuItem value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {selectedKey === "send_postcard" && (
                    <Grid item>
                      <FormControl className="filter-select-one">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Send Postcard"
                          value={sendPostcard}
                          onChange={(e) =>
                            setSendPostcard(e.target.value.toString())
                          }
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={0}>No</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {selectedKey === "domain" && (
                    <Grid item>
                      <FormControl className="filter-select-one">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Domain Name"
                          value={selectedTerms}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedTerms(selectedValue);
                          }}
                        >
                          <MenuItem key="is_customer_ep" value="is_customer_ep">
                            By Equity Protect
                          </MenuItem>
                          {domainListDataList?.data?.map((val) => {
                            const domain = val.domain || val.custom_domain;
                            return <MenuItem value={domain}>{domain}</MenuItem>;
                          })}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {selectedKey === "phone" && (
                    <Grid item>
                      <Box className={`phone-number-input bg-white filter`}>
                        <PhoneInput
                          className="bg-white"
                          international={false}
                          placeholder="Phone Number"
                          value={phoneNumberVal}
                          countries={["US", "IN"]}
                          defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                          countryOptionsOrder={["US", "IN"]}
                          addInternationalOption={false}
                          onChange={(event) => {
                            setPhoneNumberVal(event);
                            setSelectedTerms(event);
                          }}
                        />
                      </Box>
                    </Grid>
                  )}

                  {selectedKey === "all" && (
                    <Grid item>
                      <FormControl className="filter-select-one">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Date type"
                          value={selectedDateType}
                          onChange={(e) => {
                            setSelectedDateType(e.target.value);
                          }}
                        >
                          {[
                            { label: "Created At", value: "created_at" },
                            { label: "Updated At", value: "updated_at" },
                          ].map((data, key) => (
                            <MenuItem value={data.value}>{data.label}</MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  )}

                  {(selectedKey === "all" ||
                    selectedKey === "created_at" ||
                    selectedKey === "updated_at") && (
                    <Grid item>
                      <FormControl className="filter-search">
                        <CustomDateRangePicker
                          rangeValue={selectedDateRange}
                          onChange={(values) => {
                            setSelectedDateRange(values);
                          }}
                          onClear={() => {
                            setSelectedDateRange(null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <FormControl>
                        <Button
                          className="filter-button"
                          onClick={handleFilterProperty}
                        >
                          Filter Results
                        </Button>
                      </FormControl>
                      <Button
                        className="reset-filters"
                        onClick={resetAllFilterOptions}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </>
          )}
        </Box>

        {/* Providers Listing Table */}
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <TableHeaderForSorting
                  columns={columns}
                  handleSorting={handleSorting}
                  directionFlow={directionFlow}
                  setSelectedColumn={setSelectedColumn}
                  selectedColumn={selectedColumn}
                />
                <TableBody>
                  {_DATA?.map((row, index) => {
                    return (
                      <Row
                        key={(page - 1) * 10 + index + 1}
                        row={row}
                        open={open}
                        handleMenuClick={handleMenuClick}
                        page={page}
                        index={index}
                        handleCollapsibleRow={handleCollapsibleRow}
                        isCollapsible={
                          selectedCollapsibleRow?.id === row?.id &&
                          isCollapsibleRow
                        }
                      />
                    );
                  })}
                </TableBody>
              </Table>
              {!otherProvidersList?.data?.length > 0 &&
                !otherProvidersLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
            </TableContainer>
          </Paper>
          {otherProvidersList?.count > PER_PAGE && (
            <Box id="pagination-container">
              <CustomPagination
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Box>
          )}
        </Box>

        {/* table options */}
        <Menu
          // key={index}
          anchorEl={anchorEl}
          id="settings-menu"
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          {selectedRow?.is_active ? (
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("edit_personal_details")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <ModeOutlinedIcon />
                <Typography className="setting-menu-text">
                  Edit Provider
                </Typography>
              </Box>
            </MenuItem>
          ) : null}

          {selectedRow?.is_active ? (
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("edit_plans_details")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <AccountBalanceWalletOutlinedIcon />
                <Typography className="setting-menu-text">
                  Edit Plans
                </Typography>
              </Box>
            </MenuItem>
          ) : null}

          {/* edit email & phone disable for now */}
          {selectedRow?.is_active ? (
            <MenuItem
              key="edit_email"
              onClick={() => {
                handleMenuItemClick("edit_email");
              }}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <EmailOutlinedIcon color={"#15BE53"} />
                <Typography className="setting-menu-text">
                  Edit and Verify Email Id
                </Typography>
              </Box>
            </MenuItem>
          ) : null}

          {selectedRow?.is_active ? (
            <MenuItem
              key="edit_phone"
              onClick={() => handleMenuItemClick("edit_phone")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <SmartphoneOutlinedIcon color={"#15BE53"} />
                <Typography className="setting-menu-text">
                  Edit and Verify Mobile Number
                </Typography>
              </Box>
            </MenuItem>
          ) : null}

          {!selectedRow?.is_active ? (
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("active")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <CheckCircleOutlineOutlinedIcon />
                <Typography className="setting-menu-text">
                  Mark as Active
                </Typography>
              </Box>
            </MenuItem>
          ) : (
            <MenuItem
              key="view"
              onClick={() => handleMenuItemClick("inactive")}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <CloseOutlinedIcon />
                <Typography className="setting-menu-text">
                  Mark as Inactive
                </Typography>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </>

      {/* Edit the Provider's personal details */}
      {editProvider ? (
        <DialogBox
          width={"lg"}
          fullScreenModal={true}
          contentClassName={"bg-white"}
          title={"Personal Details"}
          openPopup={editProvider}
          setOpenPopup={setEditProvider}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditProvidersDetails
              listingAPI={getProviderListingData}
              selectedData={selectedRow}
              personalDetails={true}
              plansDetails={false}
              closeDialogBox={closeDialogBox}
            />
          }
        />
      ) : null}

      {/* Edit the Provider's plan details */}
      {editPlans ? (
        <DialogBox
          width={"lg"}
          fullScreenModal={true}
          contentClassName={"bg-white"}
          title={"Plans Details"}
          openPopup={editPlans}
          setOpenPopup={setEditPlans}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditProvidersDetails
              listingAPI={getProviderListingData}
              selectedData={selectedRow}
              personalDetails={false}
              plansDetails={true}
              closeDialogBox={closeDialogBox}
            />
          }
        />
      ) : null}

      {/* Edit Mobile number popup */}
      {editMobileNumberAlert && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={editMobileNumberAlert}
          setOpenPopup={setEditMobileNumberAlert}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditPhoneNumber
              uid={selectedRow?.user?.id}
              phoneNumber={selectedRow?.phone}
              isEditPhoneNumber={isEditPhoneNumber}
              onClose={closeDialogBox}
              closePopup={setEditEmailAlert}
              callListingAPI={getProviderListingData}
            />
          }
        />
      )}

      {/* Edit Email popup */}
      {editEmailAlert && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={editEmailAlert}
          setOpenPopup={setEditEmailAlert}
          onClose={() => closeDialogBox()}
          dividers={true}
          content={
            <EditEmail
              uid={selectedRow?.user?.id}
              emailId={selectedRow?.user?.email}
              isEditEmailId={isEditEmailId}
              onClose={closeDialogBox}
              closePopup={setEditEmailAlert}
              callListingAPI={getProviderListingData}
            />
          }
        />
      )}

      {/* activate/deactivate the Provider */}
      {isActivateDeactivateModalOpen ? (
        <AlertDialog
          type={"Warning"}
          openPopup={isActivateDeactivateModalOpen}
          onClose={true}
          onCloseFun={() => {
            setIsActivateDeactivateModalOpen(false);
          }}
          mesage={`Are you sure you want to ${
            !selectedRow?.is_active ? `activate` : `inactivate`
          } the ${selectedRow?.first_name} ${
            selectedRow?.last_name
          }  Provider?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setIsActivateDeactivateModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    handleActivateInactivateProvider(selectedRow);
                  }}
                >
                  Okay
                </Button>
              </Stack>
            </>
          }
          footer={false}
          loader={otherProvidersLoader}
        />
      ) : null}

      {/* activate deactivate provider success error modal */}
      {getObjLength(activateInactivateDetails) && (
        <AlertDialog
          type={activateInactivateDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (activateInactivateDetails?.success) {
              setIsActivateDeactivateModalOpen(false);
              getProviderListingData();
            }
            dispatch(otherProvidersAction.fetchActivateInactivateProvider({}));
          }}
          mesage={
            activateInactivateDetails?.success
              ? activateInactivateDetails?.message
              : activateInactivateDetails?.data?.message
          }
          footer={false}
        />
      )}

      <Loader open={otherProvidersLoader} />
    </>
  );
};

export default ProvidersListTable;
