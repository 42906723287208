import { useEffect } from "react";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import SecurePaymentPanel from "../common/SecurePaymentPanel";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import TotalPyamentPopup from "../user-signup/React-stripe/TotalPyamentPopup";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import { getObjLength, reactPixelEventOnlyForCustomer } from "../helpers/utils";

import "../common/common.scss";
import DrawerFromBottom from "../common/DrawerFromBottom";

const RenewPlanModal = ({
  open = false,
  loader = false,
  isRenewFinalAmtModalOpen = false,
  setIsRenewFinalAmtModalOpen = () => {},
  handleClose = () => {},
  onSubmit = () => {},
  paymnetData = {},
  user_id = null,
  onSuccess = () => {},
  setIsRenewPlanModalOpen = () => {},
  selectedRow = {},
  fromAdmin = false,
  callListAPI = false,
  callListingAPI = () => {},
  pixelEventMessage = "",
  apiCall = () => {},
}) => {
  const dispatch = useDispatch();

  // states
  const [selectedCard, setSelectedCard] = useState(null);

  // store data
  const { cardLoader, loggedInEmail } = useSelector(
    (state) => state?.userProfile
  );
  const { totalPaymentDetails, totalPaymentLoader } = useSelector(
    (state) => state?.signUpUser
  );
  const { metaPixelPropertyData } = useSelector((state) => state?.subscription);

  const handlePaySecureBtn = () => {
    if (selectedCard?.length > 0) {
      onSubmit(selectedCard);
    }
  };

  let data = {
    email: loggedInEmail,
    properties: metaPixelPropertyData,
  };
  const onSuccessFun = () => {
    onSuccess();
    dispatch(propertiesAction.setAddPropertySubscription({}));
    handleClose();
    reactPixelEventOnlyForCustomer(`${pixelEventMessage} successfully`, data);
  };
  const onFailedFun = () => {
    localStorage.setItem("paymentPopup", 0);
    dispatch(propertiesAction.setAddPropertySubscription({}));
    reactPixelEventOnlyForCustomer(`${pixelEventMessage} failed`, data);
    // handleClose();
    apiCall();
  };
  const closeTotalAmountModal = (action) => {
    if (action === "submit") {
      setIsRenewPlanModalOpen(true);
    }
    setIsRenewFinalAmtModalOpen(false);
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
  };
  const cardListAPI = (url) => {
    console.log("url==>", url);
    dispatch(
      userProfileAction.userCardDetails({
        url: url || `admin/users/${user_id}/card`,
        data: {},
        token: true,
      })
    );
  };

  // useEfect
  useEffect(() => {
    let url = "customer/card/list";
    if (fromAdmin) {
      url = `admin/users/${user_id}/card`;
    }
    if (open) {
      cardListAPI(url);
    }
  }, [dispatch, fromAdmin, open, user_id]);

  return (
    <>
      {/* Modal for card payment */}
      {open && (
        <DrawerFromBottom
          width={"720px"}
          open={open}
          onClose={() => {
            setIsRenewPlanModalOpen(false);
          }}
        >
          {/* <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      > */}
          <Typography
            variant="subtitle1"
            className="py-1 formHeader"
            sx={{ px: "10px", position: "relative" }}
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
            <Button
              onClick={() => handleClose()}
              className="modal-close-btn close-text"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                alignSelf: "right",
                background: "transparent",
                textTransform: "none",
                justifyContent: "end",
              }}
            >
              <CloseIcon sx={{ fontSize: 20, color: "white" }} />
            </Button>
          </Typography>
          <DialogTitle id="alert-dialog-title">
            Renew Plan
            {/* <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button> */}
          </DialogTitle>
          <DialogContent dividers>
            {/* <Elements stripe={stripePromise}> */}
            <SecurePaymentPanel
              displayLabel={false}
              cardData={setSelectedCard}
              paymentData={paymnetData}
              onSuccessFun={onSuccessFun}
              onFailedFun={onFailedFun}
              userId={user_id}
              is_renew={true}
              callListingAPI={cardListAPI}
              callListAPI={callListAPI}
              from={fromAdmin ? "add-property" : null}
            />
            {/* </Elements> */}
            {/* <CardList cardData={setSelectedCard} /> */}
          </DialogContent>

          <DialogActions>
            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
              <Button
                onClick={handleClose}
                className="popup_Btn other_popup_btn mx-2"
                disabled={loader}
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  if (selectedCard?.[0]?.id) {
                    handlePaySecureBtn();
                  }
                }}
                className={`popup_Btn success_popup_btn mx-2 ${
                  !selectedCard?.[0]?.id ? "cursor-disable" : ""
                }`}
                variant="contained"
                disabled={loader}
              >
                Pay Securly
              </Button>
            </div>
          </DialogActions>
          <Loader open={cardLoader || loader} />
          {/* </Dialog> */}
        </DrawerFromBottom>
      )}
      {/* modal for showing details of the payment */}
      {isRenewFinalAmtModalOpen && getObjLength(totalPaymentDetails) ? (
        totalPaymentDetails?.success ? (
          <TotalPyamentPopup
            dataflag={true}
            setDataFlag={closeTotalAmountModal}
            alert={true}
            setAlert={closeTotalAmountModal}
            closePopup={closeTotalAmountModal}
            planDetails={selectedRow?.subscription?.plan}
            isClose={true}
            paySecure={closeTotalAmountModal}
          />
        ) : (
          <>
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() =>
                dispatch(userSigupAction.fetchTotalPaymentDetails({}))
              }
              mesage={
                totalPaymentDetails?.data?.message ||
                "An error occured while getting the payment details."
              }
            />
          </>
        )
      ) : null}
      <Loader open={totalPaymentLoader} />
    </>
  );
};

export default RenewPlanModal;
