import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { PER_PAGE } from "../../helpers/constants";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import { logsListAction } from "../../../redux/slices/logsTable/logsTableSlice";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
} from "../../helpers/utils";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    id: "message",
    disablePadding: true,
    label: "Message",
    minWidth: 200,
  },
  {
    id: "user",
    disablePadding: true,
    label: "User Name",
    minWidth: 150,
  },
  {
    id: "email",
    disablePadding: true,
    label: "Email",
    minWidth: 150,
  },
  {
    id: "device_type",
    disablePadding: true,
    label: "Source",
    minWidth: 80,
  },
  {
    id: "created_at",
    disablePadding: true,
    label: "Date",
    minWidth: 200,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AdminLogsListTable = () => {
  const params = useParams();
  const dispatch = useDispatch();

  // states
  const [page, setPage] = useState(1);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedTerms, setSelectedTerms] = useState("");
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [payload, setPayload] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectSource, setSelectSource] = useState(false);
  const [isFilterApiCalled, setIsFilterApiCalled] = useState(false);

  // store data
  const { logsListLoader, logsListData } = useSelector(
    (state) => state?.logsLists
  );

  const _DATA = logsListData?.data ? logsListData?.data : [];
  const count = Math.ceil(logsListData?.count / PER_PAGE);

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  const filterOptions = () => {
    if (selectedTerms.length > 0 || selectedDateRange || selectSource) {
      console.log("selector==>", selectSource);
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        value: selectedTerms || selectSource || undefined,
        from_date: selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
          : undefined,
        to_date: selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
          : undefined,
        limit: PER_PAGE,
        offset: 0,
      }));
      setIsFilterApiCalled(true);
    }
  };

  const resetData = () => {
    if (isFilterApiCalled) {
      setPayload({ limit: PER_PAGE, offset: 0 });
      setPage(1);
      setIsFilterApiCalled(false);
    }
    setSelectedKey("all");
    setSelectedTerms("");
    setSelectedDateRange(null);
    setSelectSource(null);
  };

  console.log("params: ", params);

  //   get logs details
  const getLogsLists = useCallback(() => {
    dispatch(
      logsListAction?.fetchLogsListData({
        url: `get-activities`,
        data: {
          type: payload?.type,
          value: payload?.value,
          user_id: params?.user_id ? params?.user_id : undefined,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          limit: PER_PAGE,
          offset: payload?.offset || 0,
        },
        token: true,
      })
    );
  }, [
    dispatch,
    params?.user_id,
    payload?.from_date,
    payload?.offset,
    payload?.to_date,
    payload?.type,
    payload?.value,
  ]);

  useEffect(() => {
    getLogsLists();
  }, [dispatch, getLogsLists]);

  return (
    <>
      {logsListData?.success ? (
        <>
          {/* Filtering Component Section */}
          <Box
            id="filter"
            onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
          >
            <h3 className="filter-text">Filter your results</h3>
            <ArrowDropDownIcon
              className="filter-arrow"
              sx={{ ms: 1, fontSize: "20px" }}
            />
          </Box>
          {/* Filtering Options */}
          <Box
            sx={{
              overflow: "hidden",
              overflowX: "auto",
              mb: 1.5,
            }}
          >
            {toggleFilterOptions && (
              <>
                {/* for mobile view */}
                <Box className="d-block d-md-none">
                  <Grid
                    container
                    className="bg-white d-flex justify-content-around align-items-center px-4"
                    spacing={2}
                    pb={2}
                    pt={2}
                  >
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Typography className="search-text">Search By</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <FormControl
                        className="filter-select-one"
                        sx={{ width: "100%" }}
                      >
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          name="type"
                          onChange={(event) => {
                            setSelectedKey(event.target.value);
                            setSelectedTerms("");
                            setSelectedDateRange(null);
                            setSelectSource(null);
                          }}
                          value={selectedKey}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          {columns.map(
                            (cell) =>
                              cell.label !== "No." &&
                              cell.label !== "Actions" && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.label}
                                </MenuItem>
                              )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    {selectedKey !== "created_at" &&
                      selectedKey !== "device_type" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl
                            className="filter-search"
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel>Enter search term</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              }
                              label="Enter search term"
                              name="terms"
                              onChange={(event) => {
                                setSelectedTerms(event.target.value);
                                setSelectSource(null);
                              }}
                              value={selectedTerms}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {(selectedKey === "all" ||
                      selectedKey === "created_at") && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-search w-100">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {selectedKey !== "created_at" &&
                      selectedKey === "device_type" && (
                        <Grid iitem xs={12} sm={12} md={4} lg={2}>
                          <FormControl
                            className="filter-search mt-3"
                            sx={{ width: "100%" }}
                          >
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Source"
                              // value={propertyType}
                              onChange={(e) => {
                                setSelectSource(e.target.value);
                                setSelectedDateRange(null);
                              }}
                            >
                              <MenuItem value={"web"}>Web device</MenuItem>
                              <MenuItem value={"ios"}>IOS App</MenuItem>
                              <MenuItem value={"android"}>Android App</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="w-100">
                        <Button
                          onClick={filterOptions}
                          sx={mobileFilterResultStyle}
                        >
                          Filter Results
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        type="reset"
                        onClick={() => {
                          resetData();
                        }}
                        sx={mobileFilterResetBtnStyle}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* for desktop view */}
                <Card id="filter-options-card" className="d-none d-md-block">
                  <Box className="filter-options-box">
                    <div className="search-text mx-2">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        name="type"
                        onChange={(event) => {
                          setSelectedKey(event.target.value);
                          setSelectedTerms("");
                          setSelectedDateRange(null);
                          setSelectSource(null);
                        }}
                        value={selectedKey}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.label !== "No." && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>

                    {selectedKey !== "created_at" &&
                      selectedKey === "device_type" && (
                        <FormControl className="filter-select-one">
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Source"
                              // value={propertyType}
                              onChange={(e) => {
                                setSelectSource(e.target.value);
                                setSelectedDateRange(null);
                              }}
                            >
                              <MenuItem value={"web"}>Web device</MenuItem>
                              <MenuItem value={"ios"}>IOS App</MenuItem>
                              <MenuItem value={"android"}>Android App</MenuItem>
                            </TextField>
                          </FormControl>
                        </FormControl>
                      )}
                    {selectedKey !== "created_at" &&
                      selectedKey !== "device_type" && (
                        <FormControl
                          className="filter-search"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            name="terms"
                            onChange={(event) => {
                              setSelectedTerms(event.target.value);
                              setSelectSource(null);
                            }}
                            value={selectedTerms}
                          />
                        </FormControl>
                      )}
                    {(selectedKey === "all" ||
                      selectedKey === "created_at") && (
                      <FormControl className="filter-search">
                        <CustomDateRangePicker
                          rangeValue={selectedDateRange}
                          onChange={(values) => {
                            setSelectedDateRange(values);
                          }}
                          onClear={() => {
                            setSelectedDateRange(null);
                          }}
                        />
                      </FormControl>
                    )}

                    <FormControl>
                      <Button
                        onClick={filterOptions}
                        className="filter-button "
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      type="reset"
                      onClick={() => {
                        resetData();
                      }}
                      className="reset-filters"
                    >
                      Reset
                    </Button>
                  </Box>
                </Card>
              </>
            )}
          </Box>

          {/* Logs Listing Table */}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {_DATA?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row?.id}
                          style={{ mineight: "20px" }}
                        >
                          <TableCell align="left">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            // sx={{ wordBreak: "break-all" }}
                          >
                            {row?.admin_message?.length > 0
                              ? row?.admin_message
                              : row?.message || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.user?.id
                              ? `${row?.user?.first_name} ${row?.user?.last_name}`
                              : `${row?.report?.name || "-"} `}
                          </TableCell>
                          <TableCell align="left">
                            {row?.user?.id
                              ? row?.user?.email
                              : `${row?.report?.email || "-"}`}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={`${
                              row?.device_type === "ios"
                                ? "text-uppercase"
                                : "text-capitalize"
                            }`}
                          >
                            {row?.device_type || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.created_at
                              ? getLocalFormatedDate(row?.created_at)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!logsListData?.data?.length > 0 && !logsListLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
            {logsListData?.count > PER_PAGE && (
              <Box id="pagination-container">
                <CustomPagination
                  count={count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Typography className="text-center text-danger">
            {logsListData?.data?.message}
          </Typography>
        </>
      )}

      <Loader open={logsListLoader} />
    </>
  );
};

export default AdminLogsListTable;
