import { call, put, takeLatest } from "redux-saga/effects";

import { getAPIPayload } from "../../../../apis/getApi";
import { postAPIPayload } from "../../../../apis/postApi";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { serviceProviderProfileAction } from "../../../slices/serviceProvider/servicerProfile/servicerProfileSlice";

export function* getServiceProviderProfileSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(serviceProviderProfileAction.setServiceProviderProfile(response));
}

export function* editServicerProfileDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(serviceProviderProfileAction.setEditServicerProfileData(response));
}

export function* getServiceProviderProfessionalSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setServiceProviderProfessionalDetails(response)
  );
}

export function* editServicerProfessionalDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setEditServicerProfessionalData(response)
  );
}

export function* switchServiceProviderDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setSwitchServiceProviderData(response)
  );
}

export function* reqDocDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(serviceProviderProfileAction.fetchRequestDocumnetsData(response));
}

export function* biometricDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderProfileAction.fetchSPBiometricData(response));
}

export function* updateBiometricDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderProfileAction.fetchUpdatedSPBiometricData(response));
}

export function* signatureDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderProfileAction.fetchSPSignatureData(response));
}

export function* updateSignatureDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderProfileAction.fetchUpdatedSPSignatureData(response));
}

export function* cancelDocumentReqDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderProfileAction.fetchCancelledDocReqData(response));
}

export function* uploadCompanyLogoSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setUploadedCompanyLogoDetails(response)
  );
}

export function* deleteCompanyLogoSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setDeletedCompanyLogoDetails(response)
  );
}

export function* uploadServiceAgreementSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setUploadedServiceAgreementDetails(response)
  );
}

export function* uploadRequestedDocSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    serviceProviderProfileAction.setUploadRequestedDocDetails(response)
  );
}

export function* watchServiceProviderProfileSagaAsync() {
  yield takeLatest(
    serviceProviderProfileAction.getServiceProviderProfile,
    getServiceProviderProfileSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.editServicerProfileData,
    editServicerProfileDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.getServiceProviderProfessionalDetails,
    getServiceProviderProfessionalSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.editServicerProfessionalData,
    editServicerProfessionalDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.switchServiceProviderData,
    switchServiceProviderDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.requestDocumnetsData,
    reqDocDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.getSPBiometricData,
    biometricDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.updateSPBiometricData,
    updateBiometricDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.getSPSignatureData,
    signatureDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.updateSPSignatureData,
    updateSignatureDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.cancelDocumentReqData,
    cancelDocumentReqDataSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.uploadCompanyLogo,
    uploadCompanyLogoSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.deleteCompanyLogo,
    deleteCompanyLogoSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.uploadServiceAgreement,
    uploadServiceAgreementSaga
  );
  yield takeLatest(
    serviceProviderProfileAction.uploadRequestedDoc,
    uploadRequestedDocSaga
  );
}
