import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import mail from "../../assets/icons/mail.png";
import { getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import CustomTextField from "../common/CustomTextField";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

const validationSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
});

const StayInForm = () => {
  const dispatch = useDispatch();

  // store data
  const { saveEmailDetails } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const closeAlertPopup = () => {
    dispatch(propertyRiskManagementAction.fetchSaveEmailDetails({}));
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        validationSchema={validationSchema}
        initialValues={{
          // name: "",
          email: "",
        }}
        onSubmit={(values, validation) => {
          console.log("vcalues-->>", values);
          validation.resetForm();
          dispatch(
            propertyRiskManagementAction.saveEmailDetails({
              url: `subscribe`,
              data: values,
            })
          );
        }}
      >
        {({ values, setFieldValue, touched, errors, isSubmitting }) => {
          // handle selected address for sign up form

          return (
            <>
              <Form>
                <Grid container spacing={1.25}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    // md={is_PRM ? 12 : 6}
                    // lg={is_PRM ? 12 : 6}
                    className="p-2"
                  >
                    <CustomTextField
                      variant="outlined"
                      name="email"
                      placeholder="Your Email"
                      type="email"
                      validation={{
                        values,
                        setFieldValue,
                        touched,
                        errors,
                      }}
                      otherProps={{
                        onChange: (event) => {
                          setFieldValue("email", event.target.value);
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              className="textbox-icons"
                              src={mail}
                              alt="account"
                            />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true, // Ensures that label doesn't float when value is empty
                      }}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  className={`subscribe-button  mt-4`}
                  variant="contained"
                >
                  Subscribe
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
      {getObjLength(saveEmailDetails) ? (
        <AlertDialog
          type={saveEmailDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          mesage={saveEmailDetails?.message || saveEmailDetails?.data?.message}
          onCloseFun={closeAlertPopup}
        />
      ) : null}
    </>
  );
};

export default StayInForm;
