import Pusher from "pusher-js";
import {
  PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY,
} from "../components/helpers/constants";

const pusher = new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_APP_CLUSTER,
});

export default pusher;
