import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubscriptionHistory from "../common/userViewPropertyTabsComponents/SubscriptionHistory";
import { useTheme, useMediaQuery } from '@mui/material';

const SubscriptionHistoryModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(576));
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${isMobile && 'zoom-modal-wrapper'} subscription-history-modal`}
      >
        <DialogTitle id="alert-dialog-title">
          Subscription History
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          {/* Subscription History Table */}
          <SubscriptionHistory />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionHistoryModal;
