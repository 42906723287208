import React from "react";

import { Box } from "@mui/system";
import { Paper, TableContainer, Typography } from "@mui/material";

const ForeClosureInfoTab = () => {
  return (
    <>
      <Box className="bg-white">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Typography className="p-5 text-center" variant="h6">
              No Data Found.
            </Typography>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default ForeClosureInfoTab;
