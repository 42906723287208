import React, { useState, useEffect } from "react";
import SignPad from "../../common/signaturePad";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { Box, Button, Typography } from "@mui/material";
import AlertDialog from "../../common/AlertDialog";
import { Loader } from "../../common/loader";
import SignatureTab from "../../common/SignatureTab";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { encryptPayload } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const EditSignature = ({
  setOpenPopup,
  displayCloseButton,
  signUpdatedPopup,
  setSignUpdatedPopup,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sign, setSign] = useState(null);
  // const [signUpdatedPopup, setSignUpdatedPopup] = useState(false);
  const [signErr, setSignErr] = useState("");
  const [signRegisteredErr, setSignRegisteredErr] = useState("");

  const { updateSignatureDetails, userProfileDetails } = useSelector(
    (state) => state?.userProfile
  );
  const { signBase64 } = useSelector((state) => state?.signUpUser);
  const signatureDone = (sign) => {
    console.log("Signature==>1", sign);
    setSign(sign);
    setSignErr("");
    if (sign === undefined) {
      setSignRegisteredErr("");
    }
  };

  const removeErrors = () => {
    setSignErr("");
    setSignRegisteredErr("");
  };

  const submitSign = () => {
    console.log(signBase64, "Signature==>next", sign);
    if (sign?.length > 0 || signBase64?.length > 0) {
      setOpen(true);
      setSignErr("");
      // props.onBtnClick();
      let data = {
        signature: sign || signBase64,
      };

      dispatch(
        userProfileAction.updateSignatureData({
          url: "customer/update-biometric/signature",
          data: data,
          token: true,
        })
      );
    } else {
      setSignErr("Please Select or Draw a Sign to continue further.");
    }
  };

  const handleClose = () => {
    if (!updateSignatureDetails?.success) {
      setSignRegisteredErr(updateSignatureDetails?.data?.message);
    }
    if (updateSignatureDetails?.success) {
      setSignUpdatedPopup(true);
    }
    setOpen(false);
  };

  useEffect(() => {
    console.log("sign verify -->", updateSignatureDetails);
    if (Object.keys(updateSignatureDetails).length > 0) {
      console.log("validate 1");
      handleClose();
    }
  }, [updateSignatureDetails]);

  const closePopup = () => {
    setSignUpdatedPopup(false);
    setOpenPopup(false);
    dispatch(
      userProfileAction.getSignatureData({
        url: "customer/get-biometric/signature",
        data: {},
        token: true,
      })
    );
  };

  function error() {
    return (
      <>
        {(signErr?.length > 0 || signRegisteredErr?.length > 0) && (
          <Typography className="text-danger text-center">
            {signErr || signRegisteredErr}
          </Typography>
        )}
      </>
    );
  }

  return (
    <Box className="">
      {/* <SignatureLists /> */}
      {!signUpdatedPopup && (
        <>
          <SignatureTab
            signpad={
              <SignPad
                signatureDone={signatureDone}
                submitSign={submitSign}
                error={error()}
              />
            }
            signatureDone={signatureDone}
            submitSign={submitSign}
            removeErrors={removeErrors}
            setSign={setSign}
            userName={
              userProfileDetails?.data?.first_name +
              " " +
              userProfileDetails?.data?.last_name
            }
            error={error()}
          />

          {/* <SignPad signatureDone={signatureDone} /> */}

          <Box
            className={`d-flex ${
              displayCloseButton
                ? `justify-content-between`
                : "justify-content-center"
            } align-items-center mx-md-4 my-3 px-3`}
          >
            {displayCloseButton && (
              <Button
                className="popup_Btn other_popup_btn"
                onClick={() => setOpenPopup(false)}
              >
                Close
              </Button>
            )}
            <Button
              onClick={submitSign}
              className="next_button"
              variant="contained"
            >
              Update
            </Button>
          </Box>
        </>
      )}
      <Loader open={open} />
      {signUpdatedPopup && (
        <PlainAlertMessage
          type="Success"
          openPopup={signUpdatedPopup}
          onClose={false}
          onCloseFun={closePopup}
          message={updateSignatureDetails?.message}
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={() => {
                closePopup();
                // to close default popup for the sub users
                localStorage.setItem("userRoleCode", encryptPayload("54"));
                dispatch(userSigupAction.signBase64Value());
              }}
            >
              Ok, All Good
            </Button>
          }
        />
      )}
    </Box>
  );
};

export default EditSignature;
