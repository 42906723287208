import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme, useMediaQuery, drawerClasses } from "@mui/material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import DownloadFile from "./DownloadFile";
import DocumentTypes from "./DocumentTypes";
import { ROLES } from "../../helpers/constants";
import SecurePaymentPanel from "../../common/SecurePaymentPanel";
import Aurthorised from "../../../assets/price_F&Q/aurthorize.png";
import {
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../../helpers/utils";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

import "../../../styles/documentmodal.scss";
import DrawerFromBottom from "../../common/DrawerFromBottom";
import DialogBox from "../../common/DialogBox";
import { Padding } from "@mui/icons-material";

export default function OrderDocuments({
  setDocActivStep,
  propertyId,
  resetStates,
  closeModal,
  onClose,
  propertyData,
  setShowDownloadPage,
  user_id = "",
  showDrawer,
  showDownloadPage,
}) {
  const mainContentRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [docType, setDocType] = useState("");
  const [sendReqErr, setSendReqErr] = useState("");
  const [cardData, setCardData] = useState({});
  const [metaData, setMetaData] = React.useState("paper");
  const header1Ref = useRef(null);
  const header2Ref = useRef(null);
  const errorRef = useRef(null);
  const footer1Ref = useRef(null);
  const footer2Ref = useRef(null);

  const [mainContentHeight, setMainContentHeight] =
    useState("calc(80vh - 495px)");
  const dispatch = useDispatch();

  const requestDoc = useSelector((state) => state.properties.requestDoc);
  const generateDoc = useSelector((state) => state.properties.generateDoc);
  const cardDetails = useSelector((state) => state.userProfile.cardDetails);
  const documentTypeList = useSelector(
    (state) => state.properties.documentTypes
  );
  const { loggedInEmail, userProfileDetails } = useSelector(
    (state) => state?.userProfile
  );
  const { threedSecurePayment } = useSelector((state) => state?.subscription);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    console.log("Active taeps ==>.", activeStep);
    if (Object.keys(requestDoc).length > 0) {
      changeSteps("reqDoc");
    }
  }, [requestDoc]);
  // useEffect(() => {
  //   console.log(generateDoc, "Active taeps ==>.", activeStep);
  //   if (Object.keys(generateDoc).length > 0) {
  //     changeSteps("generateDoc");
  //   }
  // }, [generateDoc]);

  useEffect(() => {
    if (resetStates) {
      resetStateValues();
    }
  }, [resetStates]);

  const handleClose = () => {
    console.log("chlid close==>");
    closeModal(false);
    setOpen(false);
    setActiveStep(0);
    setDocActivStep(0);
    setSendReqErr("");
    setDocType("");
    dispatch(propertiesAction.clearOrderDoc());
  };
  const resetStateValues = () => {
    setOpen(false);
    setDocType("");
    setActiveStep(0);
    setSendReqErr("");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleNext = () => {
    console.log("active staeps===>", activeStep);
    if (activeStep + 1 === 1) {
      console.log(propertyId, "Values from changeDocTypeStep==>", docType);
      if (Object.keys(docType).length === 0) {
        let price = documentTypeList.data.filter(
          (i) => (i.type || i.key) === documentTypeList?.data[0].key
        );
        console.log("filter Doc==>", price);
        let docData = {
          type: documentTypeList?.data[0].key,
          price: price[0].price,
        };
        console.log("doctypes==>>", docData);
        dispatch(
          propertiesAction.reqDocument({
            url: "customer/properties/" + propertyId + "/request-document",
            data: docData,
            token: true,
          })
        );
      } else {
        console.log("doctypes==>>", docType);
        dispatch(
          propertiesAction.reqDocument({
            url: "customer/properties/" + propertyId + "/request-document",
            data: docType,
            token: true,
          })
        );
      }
      // setDocActivStep(activeStep + 1);
    } else {
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep + 1 === 2) {
      let requestedDocId = requestDoc?.data?.id;
      if (cardData.length > 0) {
        console.log("card details==>>", cardData);
        dispatch(
          propertiesAction.generateDocument({
            url:
              "customer/properties/" +
              propertyId +
              "/generate-document/" +
              requestedDocId,
            // cardData[0]?.id,
            data: { card_id: cardData[0].id },
            token: true,
          })
        );
      } else {
        let activeCreditId = cardDetails?.data?.filter((i) => i.default === 1);
        let selectedCardData = (cardDetails?.data).filter(
          (i) => i.id === parseInt(activeCreditId[0]?.id)
        );
        console.log(activeCreditId, "selcted card data==>", selectedCardData);
        dispatch(
          propertiesAction.generateDocument({
            url:
              "customer/properties/" +
              propertyId +
              "/generate-document/" +
              requestedDocId,
            // activeCreditId[0]?.id,
            data: { card_id: selectedCardData[0].id },
            token: true,
          })
        );
        // closeModal(false);
      }
    }
  };

  const changeSteps = (step) => {
    console.log("generateDoc==>", generateDoc);
    if (step === "reqDoc") {
      if (requestDoc?.success) {
        console.log(requestDoc, "propertyData==>", propertyData);
        //for fb meta data
        let metadata = {};
        if (propertyData) {
          metadata = {
            propetiesDetails: {
              property_id: propertyData?.property_id,
              property_address: propertyData?.address,
            },
            email: loggedInEmail,
            document_type: requestDoc?.data?.type,
            document_id: requestDoc?.data?.id,
          };
        } else {
          metadata = {
            email: loggedInEmail,
            document_type: requestDoc?.data?.type,
            document_id: requestDoc?.data?.id,
          };
        }
        console.log("metadata==>>", metadata);
        setMetaData(metadata);
        // reactPixelEventOnlyForCustomer("Request Documnet", metadata);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setDocActivStep(activeStep + 1);
        setSendReqErr("");
      } else {
        setSendReqErr(requestDoc?.data?.message);
      }
    }
    if (step === "generateDoc") {
      if (generateDoc?.success) {
        console.log(metaData, "documnet sucess...", generateDoc);
        if (ROLES.customer && !generateDoc?.data?.actionRequired) {
          reactPixelEventOnlyForCustomer("Request Documnet Payment", {
            ...metaData,
            paymnet: "Successful",
          });
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setShowDownloadPage(true);
        closeModal(false);
        setDocActivStep(activeStep + 1);
        setSendReqErr("");
      } else {
        console.log("/generate-document==>", generateDoc);
        if (ROLES.customer && !generateDoc?.data?.actionRequired) {
          reactPixelEventOnlyForCustomer("Request Documnet Payment", {
            ...metaData,
            paymnet: "Failed",
          });
        }
        setSendReqErr(generateDoc?.data?.message);
      }
    }
  };

  const handleBack = () => {
    console.log("Active step=>", activeStep);
    setSendReqErr("");
    setActiveStep(0);
    setDocActivStep(0);
    dispatch(propertiesAction.clearOrderDoc());
  };

  const onSuccessFun = () => {
    if (ROLES.customer) {
      reactPixelEventOnlyForCustomer("Request Documnet Payment", {
        ...metaData,
        paymnet: "Successful",
      });
    }
    dispatch(propertiesAction.fethGeneratedDoc(threedSecurePayment));
  };
  const onFailedFun = () => {
    console.log(requestDoc, "propertyData failed ==>", propertyData);
    if (ROLES.customer) {
      reactPixelEventOnlyForCustomer("Request Documnet Payment", {
        ...metaData,
        paymnet: "Failed",
      });
    }
    dispatch(propertiesAction.setAddPropertySubscription({}));
  };

  console.log("resetStates==>", resetStates);
  const steps = [
    {
      label: "Please select a report type to generate your report",
      description: (
        <DocumentTypes changeDocTypeStep={setDocType} sendReqErr={sendReqErr} />
      ),
    },
    {
      label:
        "This is the payment page, where needed. Choose a payment method first",
      // description: <CardList cardData={setCardData} metaData={metaData} />,
      description: (
        // <Elements stripe={stripePromise}>
        <SecurePaymentPanel
          displayLabel={false}
          cardData={setCardData}
          paymentData={generateDoc}
          onSuccessFun={onSuccessFun}
          onFailedFun={onFailedFun}
          userId={userProfileDetails?.data?.user_id}
          isOpenFromBottom={false}
          onClose={onClose}
        />
        // </Elements>
      ),
    },
    // {
    //   label: null,
    //   description: <DownloadFile onClose={onClose} />,
    // },
  ];
  const maxSteps = steps.length;

  useEffect(() => {
    if (getObjLength(generateDoc)) {
      if (generateDoc?.success) {
        if (generateDoc?.data?.actionRequired) {
          // setValdiateModal(false);
          return;
        }
        if (
          !generateDoc?.data?.actionRequired
          // generateDoc?.data?.length === 0
        ) {
          changeSteps("generateDoc");
          // setValdiateModal(true);
          return;
        }
        setSendReqErr("");
      } else {
        if (ROLES.customer && !generateDoc?.data?.actionRequired) {
          reactPixelEventOnlyForCustomer("Request Documnet Failed", {
            errorMessage: generateDoc?.data?.message,
            paymnet: "Failed",
          });
        }
        // setValdiateModal(true);
        setSendReqErr(generateDoc?.data?.message);
      }
    }
  }, [dispatch, generateDoc]);

  useEffect(() => {
    const updateMainContentHeight = () => {
      const header1Height = header1Ref.current
        ? header1Ref.current.offsetHeight
        : 0;
      const header2Height = header2Ref.current
        ? header2Ref.current.offsetHeight
        : 0;
      const footer1Height = footer1Ref.current
        ? footer1Ref.current.offsetHeight
        : 0;
      const footer2Height = footer2Ref.current
        ? footer2Ref.current.offsetHeight === 32  
          ? (isMobile ? 192 : 92)
          : footer2Ref.current.offsetHeight + (isMobile ? 100 : 0)
        : 0;
      const errorHeight = errorRef.current ? errorRef.current.offsetHeight : 0;

      const totalHeight =
        header1Height +
        header2Height +
        footer1Height +
        footer2Height +
        errorHeight +
        1;
      setMainContentHeight(
        `calc(${isMobile ? "100" : "80"}vh - ${totalHeight}px)`
      );
      // setMainContentHeight(`calc(100vh - ${totalHeight}px)`);

      // setMainContentHeight(`calc(${drawerHeight}px - ${totalHeight}px)`);

      console.log("header", header1Height + header2Height);
      // console.log("header2",header2Height);
      console.log("footer", footer1Height + footer2Height);
      console.log("footer1", footer1Height);
      console.log("footer2", footer2Height);
      console.log("error", errorHeight);
      console.log("total", totalHeight);
    };
    updateMainContentHeight();
    window.addEventListener("resize", updateMainContentHeight());

    return () => {
      window.removeEventListener("resize", updateMainContentHeight());
    };
  }, [activeStep, sendReqErr.length, header1Ref?.current?.offsetHeight]);
  console.log("mainContentHeight", mainContentHeight);
  return (
    <div>
      <DrawerFromBottom
        className="drawer-container"
        width={"720px"}
        height={"80vh"}
        open={showDrawer}
        onClose={onClose}
      >
        <>
          <Typography
            variant="subtitle1"
            ref={header1Ref}
            className="py-1 formHeader header1"
            sx={{ px: "10px", position: "relative" }}
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
            <Button
              onClick={handleClose}
              className="modal-close-btn close-text"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                alignSelf: "right",
                background: "transparent",
                textTransform: "none",
                justifyContent: "end",
              }}
            >
              <CloseIcon sx={{ fontSize: 20, color: "white" }} />
            </Button>
          </Typography>
          <>
            <Box
              ref={header2Ref}
              sx={{ p: 4, position: "relative" }}
              className="dialog-header header2"
            >
              {activeStep > 1 ? null : (
                <>
                  <DialogTitle className="zoom-container" sx={{ p: 0 }}>
                    {activeStep + 1}/{maxSteps} | Order a new document
                  </DialogTitle>
                  <Typography className="zoom-container" variant="body1">
                    {steps[activeStep]?.label}
                  </Typography>
                  <DialogActions sx={{ p: 0 }} className="closeBtn ms-auto">
                    {/* <Button onClick={handleClose} className={`${activeStep===1 && "hide-outer-close"} close-text`} sx={{ textTransform: 'none' , top:`${activeStep===1 && "-30px"}`,right:'-10px'}}>
                  Close 
                  <CloseIcon sx={{fontSize:"2.5rem", color: "#15be53" }} />
                </Button> */}
                  </DialogActions>
                </>
              )}
            </Box>

            <DialogContent
              className={`main-content ${
                activeStep === 0 && "flex-vertically"
              }`}
              dividers={true}
              sx={{ p: 2 }}
              style={{ maxHeight: mainContentHeight }}
            >
              {steps[activeStep]?.description}
            </DialogContent>

            {sendReqErr.length > 0 && (
              <p ref={errorRef} className="p-2 m-0 text-danger text-center">
                {sendReqErr}
              </p>
            )}
            {activeStep <= maxSteps - 1 && (
              <DialogActions
                ref={footer1Ref}
                className="zoom-container footer"
                sx={{ py: 2 }}
              >
                <MobileStepper
                  className="d-flex justify-content-between"
                  variant="dots"
                  steps={maxSteps}
                  position="static"
                  sx={
                    activeStep === 0 && isMobile
                      ? { marginBottom: "210px", width: "100%" }
                      : { width: "100%" }
                  }
                  activeStep={activeStep}
                  nextButton={
                    activeStep === 1 ? (
                      <Button
                        className="popup_Btn success_popup_btn mx-2"
                        variant="contained"
                        onClick={handleNext}
                        disabled={!cardData?.[0]?.id}
                      >
                        Pay Securly
                      </Button>
                    ) : activeStep > 1 ? null : (
                      <Button
                        className="popup_Btn success_popup_btn mx-2"
                        variant="contained"
                        onClick={handleNext}
                      >
                        Next Step
                      </Button>
                    )
                  }
                  backButton={
                    activeStep > 0 && activeStep <= maxSteps - 1 ? (
                      <Button
                        variant="contained"
                        className="popup_Btn other_popup_btn mx-2"
                        onClick={handleBack}
                        // disabled={activeStep === 0}
                      >
                        Back
                      </Button>
                    ) : (
                      <span>&nbsp;</span>
                    )
                  }
                />
              </DialogActions>
            )}
            {activeStep === 1 && (
              <>
                <hr style={{ margin: 0 }} />
                <div style={isMobile ? {marginBottom:"100px"}: {}} ref={footer2Ref} className="text-center w-100 footer2">
                  <img
                    src={Aurthorised}
                    className="pt-3 pb-3"
                    alt="Aurthorised"
                  />
                </div>
              </>
            )}
          </>
        </>
      </DrawerFromBottom>
      {showDownloadPage && (
        <DialogBox
          width="md"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          openPopup={showDownloadPage}
          setOpenPopup={setShowDownloadPage}
          onClose={() => {
            setShowDownloadPage(false);
            // dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <DownloadFile
              onClose={() => {
                setShowDownloadPage(false);
                onClose();
              }}
            />
          }
        />
      )}
    </div>
  );
}
