import { put, call, takeEvery } from "redux-saga/effects";
import { professionalListAction } from "../../../slices/professional/professional-dashboard/propertyListSlice";
import { postAPIPayload } from "../../../../apis/postApi";

export function* fetchProfessionalSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  // const response = {};
  console.log("All properties response => ", response);
  yield put(professionalListAction.fetchProfessionalListData(response));
}

export function* watchProfessionalPropertyListsSagaAsync() {
  yield takeEvery(
    professionalListAction.getProfessionalListData,
    fetchProfessionalSaga
  );
}
