import { call, put, takeEvery } from "redux-saga/effects";

import { postAPIPayload } from "../../../apis/postApi";
import { tryPageLeadsAction } from "../../slices/tryDomain/tryLeadPageSlice";

export function* addLeadSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(tryPageLeadsAction.setAddLeadDetails(response));
}

export function* watchTryLeadPageSagaAsync() {
  yield takeEvery(tryPageLeadsAction.addLead, addLeadSaga);
}
