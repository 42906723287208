import { createSlice } from "@reduxjs/toolkit";

const initialUsersListsState = {
  usersListData: [],
  usersListDataCount: 0,
  propertiesLoader: false,
};

const usersLists = createSlice({
  name: "usersLists",
  initialState: initialUsersListsState,
  reducers: {
    getUsersListData: (state, action) => {
      state.propertiesLoader = true;
      console.log("Get users list slice", action.payload);
    },
    fetchUsersListData: (state, action) => {
      console.log("Fetching users list slice==>", action.payload?.data);
      state.usersListData = action.payload;
      state.usersListDataCount = action.payload?.count;
      state.propertiesLoader = false;
    },
  },
});
export const usersListAction = usersLists.actions;
export default usersLists.reducer;
