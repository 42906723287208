import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../common/common.scss"

export default function DialogBox({
  width,
  openPopup,
  mesage,
  onClose,
  setOpenPopup,
  modalOverApex= false,
}) {
  const [open, setOpen] = React.useState(openPopup);

  const handleClose = () => {
    setOpen(false);
    setOpenPopup(false);
  };
  const handleOpen = () => {
    setOpen(!openPopup);
  };

  console.log(open, "AlertDialog", openPopup);

  return (
    <div className="w-50">
      <Dialog
        maxWidth={width}
        sx={modalOverApex ? { textAlign: "center", zIndex:"13000001" } : { textAlign: "center" }}
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          id="alert-dialog-title mt-5"
          className="bg-white"
          sx={{ padding: "0 !important" }}
        >
          {onClose && (
            <Button
              onClick={onClose}
              className="modal-close-btn"
            >
              <CloseIcon sx={{ color: "#9CB8A6" }} />
            </Button>
          )}
          {mesage}
        </DialogContent>
      </Dialog>
    </div>
  );
}
