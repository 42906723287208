import { createSlice } from "@reduxjs/toolkit";

const initialPropertyListsState = {
  propertyListData: [],
  propertyListDataCount: 0,
  propertiesLoader: false,
};

const professionalLists = createSlice({
  name: "professionalLists",
  initialState: initialPropertyListsState,
  reducers: {
    getProfessionalListData: (state, action) => {
      state.propertiesLoader = true;
      console.log("Get professional list slice", action.payload);
    },
    fetchProfessionalListData: (state, action) => {
      console.log("Fetching professional list slice==>", action.payload);
      state.propertyListData = action.payload?.data;
      state.propertyListDataCount = action.payload?.count;
      //expire the cookie
      document.cookie =
        "iosValue =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      state.propertiesLoader = false;
    },
  },
});
export const professionalListAction = professionalLists.actions;
export default professionalLists.reducer;
