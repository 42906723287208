import React from "react";
import { Stack, Pagination } from "@mui/material";
import "../common.scss";
const CustomPagination = ({ count, page, handlePageChange }) => {
  return (
    <Stack id="custom-pagination" className="mb-3">
      <Pagination
        count={count}
        page={page}
        onChange={(event, p) => {
          if (p === page) {
            return;
          }
          handlePageChange(event, p);
        }}
        variant="outlined"
        size={
          window.innerWidth < 425
            ? "small"
            : window.innerWidth < 665
            ? "medium"
            : "large"
        }
        shape="rounded"
        showFirstButton={count % 100 === 7 ? false : true}
        showLastButton={count % 100 === 7 ? false : true}
      />
    </Stack>
  );
};

export default CustomPagination;
