import { createSlice } from "@reduxjs/toolkit";

const intialState = {
  loaded: false,
};

const autocomplete = createSlice({
  name: "autocompleteAPIStatus",
  initialState: intialState,
  reducers: {
    changeLoadedStatus(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.loaded = action.payload;
    },
  },
});
export const autocompleteAction = autocomplete.actions;
export default autocomplete.reducer;
