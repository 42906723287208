import React from "react";

import { useSelector } from "react-redux";

import { Box, Button, Stack, Typography } from "@mui/material";
import AlertDialog from "./AlertDialog";
import { currencyFormatter } from "../helpers/utils";

const ProviderTotalPyamentPopup = ({
  dataflag = false,
  alert = false,
  setAlert = () => {},
  planDetails,
  closePopup,
  isClose = true,
  paySecure = () => {},
  defaultPlan,
}) => {
  const { totalAmountDetails } = useSelector(
    (state) => state?.lennarSubscription
  );

  return (
    <>
      {alert && dataflag ? (
        <>
          <AlertDialog
            type="Info"
            openPopup={alert}
            onClose={isClose}
            onCloseFun={() => {
              closePopup("cancel");
            }}
            mesage={
              <Box className="provider_fonts">
                <h4>
                  Total Amount Details
                  {planDetails ? ` for ${defaultPlan} Plan` : null}
                </h4>
                <p className="para">
                  Total Properties:{" "}
                  <b>{totalAmountDetails?.data?.no_of_properties} </b>
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span> Plan
                  amount:{" "}
                  <b>
                    {currencyFormatter(
                      totalAmountDetails?.data?.sub_amount || 0, "$0.00"
                      //+parseFloat(
                      //totalAmountDetails?.data?.setup_fees_actual || 0
                      //) || 0
                    )}{" "}
                  </b>
                  <br />
                  {/* Setup Fees:{" "} */}
                  Sales/Local taxes:{" "}
                  <b>
                    {/* {currencyFormatter(
                      totalAmountDetails?.data?.setup_fees_actual || 0
                    )}{" "} */}
                    {currencyFormatter(
                      totalAmountDetails?.data?.sales_tax_amount_actual || 0, "$0.00"
                    )}{" "}
                  </b>{" "}
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span>{" "}
                  Discount:{" "}
                  <b>
                    {currencyFormatter(totalAmountDetails?.data?.discount || 0, "$0.00")}
                  </b>
                  <br />
                  Deposit for recording fees:{" "}
                  <b>
                    {currencyFormatter(
                      totalAmountDetails?.data?.recording_amount_actual || 0, "$0.00"
                    )}{" "}
                  </b>{" "}
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span> Setup
                  fees:{" "}
                  <b>
                    {currencyFormatter(
                      totalAmountDetails?.data?.setup_fees_actual || 0, "$0.00"
                    )}
                  </b>
                  <br />
                  <br className="d-block d-sm-none" />
                  Total Payable Amount:{" "}
                  <b>
                    {currencyFormatter(totalAmountDetails?.data?.total || 0, "$0.00")}{" "}
                  </b>
                </p>
              </Box>
            }
            buttons={
              <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                <Button
                  onClick={() => closePopup("cancel")}
                  type="submit"
                  className=" mx-2 popup_Btn other_popup_btn"
                  variant="contained"
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    closePopup("submit");
                    paySecure();
                  }}
                  type="submit"
                  sx={{ background: "#2d2d2d !important" }}
                  className="mx-2 popup_Btn"
                  variant="contained"
                >
                  Next
                </Button>
              </Stack>
            }
          />
        </>
      ) : (
        <AlertDialog
          type="Error"
          openPopup={alert}
          onClose={true}
          onCloseFun={isClose}
          mesage={totalAmountDetails?.data?.message}
          buttons={
            <Stack spacing={2} direction={"row"} justifyContent={"center"}>
              <Button
                className="popup_Btn error_popup_btn m-2"
                onClick={() => closePopup("cancel")}
              >
                cancel
              </Button>
            </Stack>
          }
        />
      )}
    </>
  );
};

export default ProviderTotalPyamentPopup;
