/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, FormHelperText, Typography } from "@mui/material";
import "../../user-signup/signup.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../common/loader";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { getObjLength } from "../../helpers/utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import AlertDialog from "../../common/AlertDialog";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";

export const spProfessionalValidationSchema = {
  company_name: Yup.string("Please enter your Company Name.")
    .trim("The Name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Company Name is required"),
  ofc_address: Yup.string("Please enter your Office Address.")
    .trim("The Address cannot include leading and trailing spaces")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Office Address is required"),
  // ofc_suite: Yup.string("Please enter your Office Suite.")
  //   .trim("The suite cannot include leading and trailing spaces")
  //   .strict(true)
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Suite is required"),
  ofc_city: Yup.string("Please enter your Office City.")
    .trim("The city cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  ofc_state: Yup.string("Please select the Office State").required(
    "State is required"
  ),
  type: Yup.string("Please select the Professional type").required(
    "Professional type is required"
  ),
  ofc_zip: Yup.string("Please enter your Office zip code.")
    .required("Office zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // .min(6, "Zip needs 6 digits")
  // .max(6, "Zip needs 6 digits"),
  ofc_phone1: Yup.string("Please enter your Office phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Phone number is required"),
};

const SPProfessionalForm = (props) => {
  const dispatch = useDispatch();

  const [fromErr, setFormErr] = useState("err");
  const [openPopup, setOpenPopup] = useState(false);
  const stateData = useSelector((state) => state.signUpUser.stateList);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const {
    spSignupLoader,
    createAccResponse,
    spProfessionalsDetails,
    professionalsList,
  } = useSelector((state) => state.serviceProviderSignup);

  const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;
  console.log("spProfessionalsDetails= >", spProfessionalsDetails);
  const onSubmit = (values, errors) => {
    console.log(createAccResponse, "values=>", values);

    let data = {
      ...values,
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
      step_key: "SAVE_PROFESSIONAL_DETAIL",
      step: 2,
    };
    if (props.from === "Admin") {
      data.created_by = "admin";
    }
    console.log("final data==>>", data);
    dispatch(
      serviceProviderSignupAction.spProfessionalsData({
        url: "service-provider/professional-details",
        data: data,
      })
    );
  };
  const onClickSubmit = (err) => {
    setFormErr("");
    // props.onBtnClick();
  };
  useEffect(() => {
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
    console.log("types==>");
    dispatch(
      serviceProviderSignupAction.getProfessionals({
        url: "service-provider/types",
        data: {},
      })
    );
  }, []);
  useEffect(() => {
    if (spProfessionalsDetails?.success) {
      setFormErr("");
      if (props.from === "Admin") {
        // props.setScreen();
        setOpenPopup(true);
      } else {
        setOpenPopup(false);
        props.onBtnClick();
      }
    } else {
      console.log("type==>", spProfessionalsDetails);
      setFormErr(spProfessionalsDetails?.data?.message);
    }
  }, [spProfessionalsDetails]);
  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <Box className="accountBoxWrapper">
        <Typography align="center" variant="h3">
          Add Professional Details 
        </Typography>
        <Typography align="center" variant="body1">
          We just need to gather some professional information to use it later
          on profile.
        </Typography>
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            company_name: "",
            ofc_address: "",
            ofc_suite: "",
            ofc_city: "",
            ofc_state: "",
            ofc_zip: "",
            ofc_phone: "",
            ofc_phone1: "",
            type: "",
          }}
          validationSchema={Yup.object().shape(spProfessionalValidationSchema)}
          // onSubmit={onSubmit}
          onSubmit={(values, validation) => {
            if (!isValidPhoneNumber(values?.ofc_phone1)) {
              return;
            }
            onSubmit(values);
          }}
        >
          {({ values, setFieldValue, touched, errors }) => {
            return (
              <Form>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Company name"
                      name="company_name"
                      value={values?.company_name}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Company Name"}
                      onChange={(event) => {
                        setFieldValue("company_name", event.target.value);
                      }}
                      helperText={touched.company_name && errors.company_name}
                      error={
                        touched.company_name && Boolean(errors.company_name)
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Office Address"
                      name="ofc_address"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Office Address"}
                      value={values?.ofc_address}
                      onChange={(event) => {
                        setFieldValue("ofc_address", event.target.value);
                      }}
                      helperText={touched.ofc_address && errors.ofc_address}
                      error={touched.ofc_address && Boolean(errors.ofc_address)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Office Suite"
                      name="ofc_suite"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Office Suite"}
                      value={values?.ofc_suite}
                      onChange={(event) => {
                        setFieldValue("ofc_suite", event.target.value);
                      }}  
                      // helperText={touched.ofc_suite && errors.ofc_suite}
                      // error={touched.ofc_suite && Boolean(errors.ofc_suite)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Office City"
                      name="ofc_city"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Office City"}
                      value={values?.ofc_city}
                      onChange={(event) => {
                        setFieldValue("ofc_city", event.target.value);
                      }}
                      helperText={touched.ofc_city && errors.ofc_city}
                      error={touched.ofc_city && Boolean(errors.ofc_city)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <FormControl
                      fullWidth
                      className="sign-textField"
                      error={touched.ofc_state && Boolean(errors.ofc_state)}
                    >
                      <InputLabel
                        shrink
                        // id="demo-simple-select-placeholder"
                        style={{ color: "#00000075" }}
                        label="Office State"
                      >
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-placeholder"
                        id="demo-simple-select"
                        value={values?.ofc_state}
                        label="Office State"
                        name="ofc_state"
                        variant="outlined"
                        onChange={(event) => {
                          setFieldValue("ofc_state", event.target.value);
                        }}
                        MenuProps={MenuProps}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <span style={{ color: "#00000075" }}>State</span>
                        </MenuItem>
                        {stateData?.map((ofc_state) => (
                          <MenuItem value={ofc_state.name}>
                            {ofc_state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.ofc_state && Boolean(errors.ofc_state) && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.ofc_state && errors.ofc_state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // length={6}
                      // id="outlined-basic"
                      label="Office Zip"
                      name="ofc_zip"
                      type="number"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Office Zip"}
                      value={values?.ofc_zip}
                      onChange={(event) => {
                        setFieldValue("ofc_zip", event.target.value);
                      }}
                      helperText={touched.ofc_zip && errors.ofc_zip}
                      error={touched.ofc_zip && Boolean(errors.ofc_zip)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <CustomPhoneNumberInput
                      name="ofc_phone1"
                      values={values}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      otherProps={{
                        onChange: (event) => {
                          setFieldValue("ofc_phone1", event);
                          setFieldValue("ofc_phone", event);
                          if (!event) {
                            setFieldValue("ofc_phone1", "");
                            setFieldValue("ofc_phone", "");
                          }
                        },
                      }}
                    />
                  </Grid>

                  <Grid xs={12} md={6} px={1} pb={1}>
                    <FormControl
                      fullWidth
                      className="sign-textField"
                      error={touched.type && Boolean(errors.type)}
                    >
                      <InputLabel
                        shrink
                        // id="demo-simple-select-placeholder"
                        style={{ color: "#00000075" }}
                        label="Professional type"
                      >
                        Professional type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-placeholder"
                        id="demo-simple-select"
                        value={values?.type}
                        label="Professional type"
                        name="type"
                        variant="outlined"
                        onChange={(event) => {
                          setFieldValue("type", event.target.value);
                        }}
                        MenuProps={MenuProps}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <span style={{ color: "#00000075" }}>Type</span>
                        </MenuItem>
                        {professionalsList?.data?.map((val) => (
                          <MenuItem value={val.id}>{val.type}</MenuItem>
                        ))}
                      </Select>
                      {touched.type && Boolean(errors.type) && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.type && errors.type}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {fromErr?.length > 0 && (
                    <p className="text-danger text-center">{fromErr}</p>
                  )}
                  <Grid xs={12} md={12} px={1} pb={1}>
                    <div className="text-center createAcBtn mt-3">
                      <Button
                        onClick={() => onClickSubmit(errors)}
                        type="submit"
                        className="next_button"
                        variant="contained"
                      >
                        Create your account
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
      {openPopup && (
        <AlertDialog
          type="Success"
          openPopup={openPopup}
          onClose={false}
          mesage={"Service provider created successfully."}
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={() => props.setScreen()}
            >
              Ok, All Good
            </Button>
          }
        />
      )}
      <Loader open={spSignupLoader} />
    </>
  );
};

export default SPProfessionalForm;
