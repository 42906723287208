import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Settings } from "@mui/icons-material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../common/loader";
import { PER_PAGE, ROLES } from "../helpers/constants";
import SettingsIconSVG from "../common/SettingsIconSVG";
import CustomLinkWrapper from "../common/CustomLinkWrapper";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";
import UploadRequestDocModal from "../admin/AdminDashboard/UploadRequestDocModal";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  decryptPayload,
  getLocalFormatedDate,
  getObjLength,
  getURLName,
} from "../helpers/utils";

const columns = [
  {
    id: "number",
    disablePadding: true,
    label: "No.",
    value: "no",
    minWidth: 60,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
    value: "property",
    minWidth: 120,
  },
  {
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 60,
  },
  {
    id: "state",
    disablePadding: true,
    label: "State",
    value: "state",
    minWidth: 60,
  },
  {
    id: "Zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 60,
  },
  {
    id: "waiver_by",
    disablePadding: true,
    label: "Waiver By",
    value: "waiver_by",
  },
  {
    id: "updated_at",
    disablePadding: true,
    label: "Updated at",
    value: "updated_at",
    minWidth: 130,
  },
  {
    id: "uploaded_doc",
    disablePadding: true,
    label: "Uploaded Document",
    value: "uploaded_doc",
    minWidth: 230,
  },
  {
    id: "action",
    disablePadding: true,
    label: "Action",
    value: "action",
    minWidth: 70,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const WaiverProperties = () => {
  const dispatch = useDispatch();

  // states
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [uploadDocPopup, setUplaodDocPopup] = useState(false);

  // store data
  const { terminationDocLoader, waiverPropertiesData, uploadedDocData } =
    useSelector((state) => state?.documents);

  const { loginAsSpouse: isSecondaryUser, parent_id } = useSelector(
    (state) => state?.userProfile
  );

  const _DATA = waiverPropertiesData?.success ? waiverPropertiesData?.data : [];
  const count = Math.ceil(waiverPropertiesData?.count / PER_PAGE);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // upload documentation
  const uploadedPropertyDocuments = (row) => {
    setUplaodDocPopup(true);
  };

  const handleCloseModal = () => {
    dispatch(documentsAction.fetchUploadedDocData({}));
    setUplaodDocPopup(false);
  };

  const handleUploadDocuments = (values) => {
    const uploadedDocs = values.upload_doc;

    const formData = new FormData();

    // formData.append("id", selectedProperty.id.toString());
    // uploadedDocs.map((doc, index) =>
    formData.append(`document`, uploadedDocs);
    // );
    // formData.append("property_id", selectedProperty?.property_id);

    // Display the values

    for (const value of formData.values()) {
      console.log(value);
    }

    dispatch(
      documentsAction.getUploadedDocData({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/upload-document/${selectedProperty?.id}/waiver`,
        data: formData,
        token: true,
        isFile: true,
        message: "Wavier document uploaded",
      })
    );
  };

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  //   get Documnets details
  const getWaiverPropertiesData = useCallback(() => {
    let URL = `${
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
        ? "service-provider"
        : "customer"
    }/waiver-properties`;

    dispatch(
      documentsAction.getWaiverPropertiesData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          user_id: isSecondaryUser ? parseInt(parent_id) : undefined,
        },
        token: true,
      })
    );
  }, [dispatch, isSecondaryUser, parent_id, payload?.offset]);

  useEffect(() => {
    getWaiverPropertiesData();
  }, [getWaiverPropertiesData]);

  return (
    <>
      {getObjLength(waiverPropertiesData) ? (
        waiverPropertiesData?.success ? (
          <>
            {/* mobile view */}
            <Box className="d-block d-lg-none mobile-table bg-white px-0 mb-3 py-3">
              {_DATA?.map((row, index) => {
                return (
                  <>
                    <Stack
                      direction="column"
                      spacing={1.2}
                      px={2}
                      className="position-relative"
                    >
                      <Stack
                        direction="column"
                        spacing={1.2}
                        pr={2}
                        justifyContent="center"
                        className="position-relative"
                      >
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMenuClick(event);
                            setSelectedProperty(row);
                          }}
                          size="small"
                          sx={{
                            color: "#15BE53",
                            position: "absolute",
                            right: "0px",
                          }}
                          className="settings-button"
                        >
                          <Settings />
                        </IconButton>

                        <Typography
                          className="value fw-500"
                          sx={{ fontSize: "15px !important" }}
                        >
                          {row?.property?.address || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">City: </Typography>
                        <Typography className="value">
                          {row?.city || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">State: </Typography>
                        <Typography className="value">
                          {row?.state || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Zip: </Typography>
                        <Typography className="value">
                          {row?.zip || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key"> Waiver By:</Typography>
                        <Typography className="value">
                          {row?.user?.email || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key"> Updated at:</Typography>
                        <Typography className="value">
                          {row?.updated_at
                            ? getLocalFormatedDate(row?.updated_at)
                            : "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">
                          {" "}
                          Uploaded Document:
                        </Typography>
                        <Typography className="value">
                          {row?.uploaded_doc?.length > 0 ? (
                            <CustomLinkWrapper
                              link={row?.uploaded_doc}
                              title={
                                <span style={{ color: "blue" }}>
                                  {getURLName(row?.doc_url)}
                                </span>
                              }
                              classes={"preview-url value"}
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Stack>
                    </Stack>

                    {index < _DATA?.length - 1 && (
                      <Divider
                        className="my-2 mt-3"
                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                      />
                    )}
                  </>
                );
              })}

              {!waiverPropertiesData?.data?.length > 0 &&
                !terminationDocLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found
                  </Typography>
                )}
            </Box>

            {/* desktop view */}
            <Box sx={{ width: "100%" }} className="d-none d-lg-block">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {_DATA?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.id}
                            style={{ mineight: "20px" }}
                          >
                            <TableCell align="left">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.address || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.city || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.state || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.zip || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.user?.email || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.updated_at
                                ? getLocalFormatedDate(row?.updated_at)
                                : "-"}
                            </TableCell>

                            <TableCell align="left">
                              {row?.uploaded_doc?.length > 0 ? (
                                <CustomLinkWrapper
                                  link={row?.uploaded_doc}
                                  title={getURLName(row?.uploaded_doc)}
                                  classes={"preview-url"}
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMenuClick(event);
                                  setSelectedProperty(row);
                                }}
                                size="small"
                                className="settings-button"
                                aria-controls="settings-menu"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <SettingsIconSVG />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!waiverPropertiesData?.data?.length > 0 &&
                    !terminationDocLoader && (
                      <Typography className="p-5 text-center" variant="h6">
                        No Data Found
                      </Typography>
                    )}
                </TableContainer>
              </Paper>
              {waiverPropertiesData?.count > PER_PAGE && (
                <Box id="pagination-container">
                  <CustomPagination
                    count={count}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Typography className="w-100 p-5 text-center text-danger bg-white fs-6">
            {waiverPropertiesData?.data?.message || "Something went wrong!"}
          </Typography>
        )
      ) : null}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {/* <MenuItem
          className="setting-menu-item"
          onClick={() => uploadedPropertyDocuments()}
        >
          <Box className="setting-menu-box">
            <UploadFileOutlinedIcon />
            <Typography className="setting-menu-text">
              Upload Document
            </Typography>
          </Box>
        </MenuItem> */}

        {selectedProperty?.doc_url && (
          <MenuItem
            className="setting-menu-item"
            // onClick={() => setPropertyTerminatePopup(true)}
          >
            <a
              className="custom-link-wrapper text-break"
              href={selectedProperty?.doc_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box className="setting-menu-box">
                <SimCardDownloadOutlinedIcon />
                <Typography className="setting-menu-text">
                  Download Document
                </Typography>
              </Box>
            </a>
          </MenuItem>
        )}
      </Menu>

      {/* Uplaod Document popup */}
      <UploadRequestDocModal
        from="TerminationDocument"
        fileFormates={{
          "image/png": [".png", ".jpeg", ".jpg"],
          "image/gif": [".gif"],
          "application/vnd.ms-powerpoint": [".ppt"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/csv": [".csv"],
        }}
        multiple={false}
        open={uploadDocPopup}
        handleClose={() => {
          handleCloseModal();
        }}
        caption={"Please upload Property wavier document for this property. "}
        isUploadSuccess={uploadedDocData?.success}
        uploadedDocData={uploadedDocData}
        successMessage={uploadedDocData?.message}
        errorMessage={uploadedDocData?.data?.message}
        uploadLoader={terminationDocLoader}
        handleUploadDoc={handleUploadDocuments}
        fetchPropertyListingData={() => {
          getWaiverPropertiesData();
        }}
      />

      <Loader open={terminationDocLoader} />
    </>
  );
};

export default WaiverProperties;
