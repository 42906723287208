import React from "react";

import { useSelector } from "react-redux";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import SupportEmailLink from "../SupportEmailLink";
import { getFormatedPhoneNumber } from "../../helpers/utils";

const contactsValueStyle = {
  color: "#15BE53 !important;",
  textDecoration: "none !important",
  "&:hover": { color: "#15be53 !important" },
};

const AssignToPeopleTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );

  const Callto = ({ phone, children, className }) => {
    return (
      <a href={`tel:${phone}`} className={className}>
        {children}
      </a>
    );
  };

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={3}>
          {propertyData?.professionals?.length > 0 ? (
            propertyData?.professionals?.map((professional) => (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={2} lg={2} className="label-text">
                    <Typography>Name: </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={7}
                    md={10}
                    lg={10}
                    className="value-text"
                  >
                    <Typography className="professional_name">
                      {professional?.user?.full_name || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={2} lg={2} className="label-text">
                    <Typography>Type: </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={7}
                    md={10}
                    lg={10}
                    className="value-text"
                  >
                    <Typography className="professional_role">
                      {professional?.type || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={2} lg={2} className="label-text">
                    <Typography>Email: </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={7}
                    md={10}
                    lg={10}
                    className="value-text"
                  >
                    <Typography>
                      <SupportEmailLink
                        email={professional?.user?.email}
                        text={professional?.user?.email}
                        className="professional_email cursor-pointer"
                        sx={contactsValueStyle}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={2} lg={2} className="label-text">
                    <Typography>Phone: </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={7}
                    md={10}
                    lg={10}
                    className="value-text"
                  >
                    {professional?.user?.phone ? (
                      <Callto
                        phone={professional?.user?.phone}
                        className={"professional_phone"}
                      >
                        <Typography sx={{ color: "#15BE53 !important" }}>
                          {getFormatedPhoneNumber(professional?.user?.phone)}
                        </Typography>
                      </Callto>
                    ) : (
                      "-"
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography
              className="bg-white p-5 text-center w-100"
              sx={{
                color: "#0d120b",
                fontWeight: 500,
                fontSize: "1.25rem",
                lineHeight: "1.6rem",
                ml: 2,
                mr: 1,
                mt: 2,
              }}
            >
              No Professional Assign
            </Typography>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default AssignToPeopleTab;
