import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Info from "../../../assets/icons/info.png";

const DisplayImage = ({
  imgSrc,
  name,
  setOpenImage,
  updateImage,
  style = {},
  displayCloseButton,
}) => {
  console.log(name, "img==>>", imgSrc);
  return (
    <Box className="accountBoxWrapper ">
      {imgSrc ? (
        <>
          <Typography align="center" variant="h3">
            Your Registerd {name}.
          </Typography>
          <Box
            className="p-3 d-flex  justify-content-between align-items-center card"
            sx={style}
          >
            <img height="300" src={imgSrc} alt={name} style={style} />
          </Box>
          <Button onClick={() => setOpenImage(false)}>Close</Button>
        </>
      ) : (
        <>
          <img className="popup_icon" src={Info} alt={"Info"} />
          <h3 className="text-center fw-600 m-0 my-2">Attention</h3>
          <Typography className="mt-3" align="center" variant="h6">
            {displayCloseButton
              ? `  Registerd ${name} is not found, Please add New ${name}.`
              : "Kindly register your digital signature, it is mandatory to proceed further."}
          </Typography>
          <Box className="my-4">
            {displayCloseButton && (
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => setOpenImage(false)}
              >
                Close
              </Button>
            )}
            <Button
              className="popup_Btn success_popup_btn"
              onClick={() => updateImage(name)}
            >
              Add
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DisplayImage;
