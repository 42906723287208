import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "./loader";
import { Box } from "@mui/system";
import FileUpload from "./FileUpload";
import CropImage from "./CropeImage";
import { useEffect } from "react";
import "./common.scss";

const UploadProfilePictureModal = ({
  open,
  handleClose,
  handleUploadProfilePicture,
  loader,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  // // handle upload profile picture button
  const handleUploadBtn = (blobImage) => {
    const width = 1920;
    const height = 1080;

    const canvas = document.createElement("canvas"); // Dynamically Create a Canvas Element
    canvas.width = width; // Set the width of the Canvas
    canvas.height = height; // Set the height of the Canvas
    const ctx = canvas.getContext("2d"); // Get the "context" of the canvas
    const img = document.getElementById("croppedImage"); // The id of your image container
    ctx.drawImage(img, 0, 0, width, height); // Draw your image to the canvas

    const jpegFile = canvas.toDataURL("image/jpeg");

    // console.log("jpegFile", jpegFile);
    handleUploadProfilePicture(jpegFile);
  };

  useEffect(() => {
    return () => {
      if (open) {
        setSelectedFile(null);
      }
    };
  }, [open]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" className="title">
            Upload Profile Picture
          </Typography>
          <Button onClick={() => handleClose()} className="modal-close-btn">
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <Box className="bg-white">
          <DialogContent dividers>
            {selectedFile ? (
              <CropImage
                file={selectedFile}
                setFile={setSelectedFile}
                handleUploadBtn={handleUploadBtn}
              />
            ) : (
              <FileUpload
                name="upload_doc"
                accept={{
                  "image/png": [".png", ".jpeg", ".jpg"],
                }}
                multiple={false}
                onFileUpload={async (file) => {
                  if (file?.length > 0) {
                    setSelectedFile(file[0]);
                  }
                }}
                dragDropText={`Drag and drop picture or `}
                greenText="upload a picture"
                subText="Only .png, .jpeg, .jpg files are allowed"
              />
            )}
          </DialogContent>
        </Box>
        <Loader open={loader} />
      </Dialog>
    </>
  );
};

export default UploadProfilePictureModal;
