import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CustomizedRadios from "../../../common/customDesignedComponents/customRadiobutton";
import { useState } from "react";
import { Box } from "@mui/system";
import TwoFactorAuth from "./TwoFactorAuth";
import { BioMetricAuth } from "./BioMetricAuth";
import { useEffect } from "react";
import { serviceProviderSignInAction } from "../../../../redux/slices/serviceProvider/SPSignINSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../common/loader";
import { getObjLength } from "../../../helpers/utils";

const Authentication = ({ isBiometric, handleBackBtn }) => {
  const [authWay, setAuthWay] = useState("mobile");
  const [errMsg, setErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [showScreen, setShowScreen] = useState("");
  const [goAuthScreens, setAuthScreens] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { otpLoader, userCredentials, sendOTPResponse, signInData } =
    useSelector((state) => state.serviceProviderSignin);

  const selectOptions = (e) => {
    console.log("Select creditBalance", e.target.value);
    setAuthWay(e.target.value);
  };

  const goNext = () => {
    console.log("go next...", authWay);
    if (authWay === "mobile") {
      dispatch(
        serviceProviderSignInAction.sendOTPData({
          url: "2fa-send-otp",
          data: userCredentials,
        })
      );
    }
    if (authWay === "email") {
      dispatch(
        serviceProviderSignInAction.sendOTPData({
          url: "2fa-otp-email",
          data: { email: userCredentials?.email },
        })
      );
    }
    if (authWay === "bioMetric") {
      setAuthScreens(true);
      setShowScreen(authWay);
    }
  };
  const backToAuth = () => {
    setAuthScreens(false);
    setShowScreen("");
  };

  useEffect(() => {
    console.log("isBiometric-->", isBiometric);
    if (!isBiometric) {
      // setAuthScreens(true);
      setShowScreen("otp");
      // dispatch(
      //   serviceProviderSignInAction.sendOTPData({
      //     url: "service-provider/2fa-send-otp",
      //     data: userCredentials,
      //   })
      // );
    }
  }, []);

  useEffect(() => {
    console.log("send opt-->", sendOTPResponse);
    if (authWay === "mobile" || authWay === "email") {
      if (getObjLength(sendOTPResponse)) {
        if (sendOTPResponse?.success) {
          setAuthScreens(true);
          setShowScreen("otp");
        } else {
          setErrMsg(sendOTPResponse?.data?.message);
        }
      }
    }
  }, [sendOTPResponse]);

  console.log(goAuthScreens, "==showScreen==>", showScreen);

  return (
    <>
      <Box className="accountBoxWrapper Form_Wrapper">
        {!goAuthScreens ? (
          <>
            <Typography align="center" variant="h3">
              Authentication to Log in
            </Typography>
            <Typography align="center" variant="body1">
              Please choose the suitable authentication option to log in to your
              account
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  row
                  defaultValue={authWay}
                  aria-labelledby="payment-types"
                  name="payment-modes"
                  onChange={selectOptions}
                >
                  {isBiometric && (
                    <Grid xs={12} sm={12} md={4} px={1} pb={1}>
                      <Paper
                        className="shadow p-3 mb-2 bg-white rounded"
                        sx={{
                          p: 2,
                          my: 1,
                          boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                          borderRadius: "4px",
                        }}
                      >
                        <FormControlLabel
                          value="bioMetric"
                          className="paymentMode w-100"
                          control={<CustomizedRadios />}
                          label={
                            <Typography
                              variant="body1"
                              className="d-flex align-items-center justify-content-between text-dark m-0 fw-600 para"
                            >
                              <span className="mode me-1">
                                BioMetric Authentication
                              </span>
                            </Typography>
                          }
                        />
                      </Paper>
                    </Grid>
                  )}
                  <Grid xs={12} sm={12} md={isBiometric ? 4 : 6} px={1} pb={1}>
                    <Paper
                      className="shadow p-3 mb-2 bg-white rounded"
                      sx={{
                        p: 2,
                        my: 1,
                        boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        value="mobile"
                        className="paymentMode w-100"
                        control={<CustomizedRadios />}
                        label={
                          <Typography
                            variant="body1"
                            className="d-flex align-items-center justify-content-between text-dark m-0 fw-600 para"
                          >
                            <span className="mode me-1">
                              Mobile Authentication
                            </span>
                          </Typography>
                        }
                      />
                    </Paper>
                  </Grid>
                  <Grid xs={12} sm={12} md={isBiometric ? 4 : 6} px={1} pb={1}>
                    <Paper
                      className="shadow p-3 mb-3 bg-white rounded"
                      sx={{
                        p: 2,
                        my: 1,
                        boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        value="email"
                        className="paymentMode w-100"
                        control={<CustomizedRadios />}
                        label={
                          <Typography
                            variant="body1"
                            className="d-flex align-items-center justify-content-between text-dark m-0 fw-600 para"
                          >
                            <span className="mode me-1">
                              Email Authentication
                            </span>
                          </Typography>
                        }
                      />
                    </Paper>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            {errMsg?.length > 0 && (
              <p className="text-danger text-center">{errMsg}</p>
            )}
            <div className="text-center stepBtn">
              <Button
                className="login_button"
                type="submit"
                variant="contained"
                onClick={goNext}
              >
                Next
              </Button>
            </div>
            <Typography m="20px 0 10px 0 !important" position="relative">
              <Button onClick={handleBackBtn}>Back</Button>
            </Typography>
          </>
        ) : showScreen.length > 0 ? (
          showScreen === "otp" ? (
            <TwoFactorAuth
              backToAuth={backToAuth}
              isBiometric={true}
              phone_number={signInData?.data?.phone_number}
              authWay={authWay}
            />
          ) : showScreen === "bioMetric" ? (
            <BioMetricAuth backToAuth={backToAuth} />
          ) : null
        ) : null}
      </Box>
      <Loader open={otpLoader} />
    </>
  );
};

export default Authentication;
