import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";

import {
  Box,
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import { MenuProps } from "../../helpers/constants";
import CustomTextField from "../../common/CustomTextField";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import {
  ApplyOnList,
  promocodeStatusList,
  promocodeTypeList,
} from "./AddPromocodeModal";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDateForExpireDate,
} from "../../helpers/utils";

const BothPlanIncludedForm = ({
  selectedItem,
  onSubmit,
  tomorrow,
  handleClose,
}) => {
  return (
    <Box className="d-flex justify-content-center  m-0">
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          name: selectedItem?.name || "",
          type: selectedItem?.type || "",
          value: selectedItem?.value || "",
          status: selectedItem?.status || "",
          applied_on: selectedItem?.id
            ? selectedItem?.on_subscription &&
              selectedItem?.on_recording_charge &&
              selectedItem?.on_setup_fees
              ? ApplyOnList[0]?.value
              : selectedItem?.on_subscription
              ? ApplyOnList[1]?.value
              : selectedItem?.on_recording_charge
              ? ApplyOnList[2]?.value
              : selectedItem?.on_setup_fees
              ? ApplyOnList[3]?.value
              : ApplyOnList[1]?.value
            : "",
          max_redemptions: selectedItem?.no_of_time_redeem || "",
          is_promocode_unlimited:
            selectedItem?.id && !selectedItem?.no_of_time_redeem ? true : false,
          expire_date:
            selectedItem?.id && selectedItem?.expire_at
              ? getLocalFormatedDateForExpireDate(
                  selectedItem?.expire_at,
                  "23, 59, 59"
                )
              : "",
        }}
        validationSchema={Yup.object().shape(
          {
            name: Yup.string("Please enter Name.")
              .trim("The Name cannot include leading and trailing spaces")
              .strict(true)
              .required("Name is required"),
            type: Yup.string("Please enter Type.")
              .trim("The Type cannot include leading and trailing spaces")
              .strict(true)
              .required("Type is required"),
            value: Yup.number("Please enter Value.")
              .moreThan(0, `The value should be more than Zero.`)
              .required("Value is required"),
            status: Yup.string("Please enter Status.")
              .trim("The Status cannot include leading and trailing spaces")
              .strict(true)
              .required("Status is required"),
            applied_on: Yup.string("Please enter Status.")
              .trim(
                "The applied on field cannot include leading and trailing spaces"
              )
              .strict(true)
              .required("Applied on is required"),
            max_redemptions: Yup.number(
              "Please enter how may times promocode can be used."
            ).when("is_promocode_unlimited", {
              is: (a) => !a,
              then: Yup.number(
                "Please enter how may times promocode can be used."
              )
                .integer("Please enter integer value")
                .moreThan(0, `The Maximum Redemption should be more than Zero.`)
                .required(
                  "Please fill maximum redeem field or select the checkbox."
                ),
              otherwise: Yup.number(
                "Please enter how may times promocode can be used."
              )
                .optional()
                .nullable(),
            }),
            is_promocode_unlimited: Yup.boolean().when("max_redemptions", {
              is: (a) => !a || a?.length === 0,
              then: Yup.boolean()
                .required()
                .oneOf(
                  [true],
                  "Please select the checkbox or fill maximum redeem field."
                ),
              otherwise: Yup.boolean(),
            }),
            expire_date: Yup.string(
              "Select the expire date of the promo code"
            ).required("Select the expire date of the promo code"),
          },
          ["max_redemptions", "is_promocode_unlimited"]
        )}
        onSubmit={(values) => {
          if (values?.max_redemptions?.length > 0) {
            values.is_promocode_unlimited = false;
          }
          const copiedPayload = JSON.parse(JSON.stringify(values));

          if (values?.applied_on === ApplyOnList[0]?.value) {
            copiedPayload.on_subscription = 1;
            copiedPayload.on_recording_charge = 1;
            copiedPayload.on_setup_fees = 1;
          } else if (values?.applied_on === ApplyOnList[1]?.value) {
            copiedPayload.on_subscription = 1;
            copiedPayload.on_recording_charge = 0;
            copiedPayload.on_setup_fees = 0;
          } else if (values?.applied_on === ApplyOnList[2]?.value) {
            copiedPayload.on_subscription = 0;
            copiedPayload.on_recording_charge = 1;
            copiedPayload.on_setup_fees = 0;
          } else if (values?.applied_on === ApplyOnList[3]?.value) {
            copiedPayload.on_subscription = 0;
            copiedPayload.on_recording_charge = 0;
            copiedPayload.on_setup_fees = 1;
          }

          if (values?.expire_date) {
            copiedPayload.expire_date = getDateInYYYYMMDDFormat(
              new Date(values.expire_date),
              "-"
            );
          }

          if (!values?.max_redemptions) {
            delete copiedPayload.max_redemptions;
          }

          const { applied_on, ...payload } = copiedPayload;
          // return console.log("payload", payload);
          onSubmit({ ...payload, is_individual: false });
        }}
      >
        {({ values, setFieldValue, touched, errors, initialValues }) => {
          console.log("values", values);
          return (
            <Form className="beneficiary-form w-100">
              <DialogContent className="bg-white">
                {/* {!addPromocodeDetails?.success && ( */}
                <Grid container spacing={2} className="bg-white">
                  <>
                    {/* Name */}
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label="Name"
                        name="name"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                      />
                    </Grid>
                    {/* Type */}
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        className="sign-textField "
                        error={touched.type && Boolean(errors.type)}
                      >
                        <InputLabel
                          // id="demo-simple-select-placeholder"
                          style={{ color: "#00000075" }}
                          label="type"
                        >
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          disabled={selectedItem?.id ? true : false}
                          value={values?.type}
                          label="Type"
                          name="type"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("type", event.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {promocodeTypeList?.map((type) => (
                            <MenuItem value={type?.value}>
                              {type?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.type && Boolean(errors.type) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.type && errors.type}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {/* Value */}
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label="Value"
                        name="value"
                        type="number"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          disabled: selectedItem?.id ? true : false,
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        className="sign-textField "
                        error={touched.status && Boolean(errors.status)}
                      >
                        <InputLabel
                          style={{ color: "#00000075" }}
                          label="status"
                        >
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.status}
                          label="Status"
                          name="status"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("status", event.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {promocodeStatusList?.map((status) => (
                            <MenuItem value={status?.value}>
                              {status?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.status && Boolean(errors.status) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.status && errors.status}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {/* on which promocode apply */}
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        className="sign-textField "
                        error={touched.applied_on && Boolean(errors.applied_on)}
                      >
                        <InputLabel
                          style={{ color: "#00000075" }}
                          label="Applied on"
                        >
                          Applied on
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.applied_on}
                          disabled={selectedItem?.id ? true : false}
                          label="Status"
                          name="applied_on"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("applied_on", event.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {ApplyOnList?.map((applied_on) => (
                            <MenuItem value={applied_on?.value}>
                              {applied_on?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.applied_on && Boolean(errors.applied_on) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.applied_on && errors.applied_on}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* set expire date of the promocode */}
                    <Grid item xs={12} md={6}>
                      <FormControl className="sign-textField filter-search w-100">
                        <CustomDateRangePicker
                          rangeValue={values?.expire_date || ""}
                          onChange={(values) => {
                            setFieldValue("expire_date", values);
                          }}
                          onClear={() => {
                            setFieldValue("expire_date", "");
                          }}
                          className=""
                          sx={{ backgroundColor: "#f6f9fc" }}
                          label="Expire Date"
                          type="text"
                          name="expire_date"
                          values={values?.expire_date}
                          touched={touched}
                          errors={errors}
                          settings={{
                            singleDatePicker: true,
                            minDate: new Date(tomorrow.toString()),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {/* no. of times promocode can be used */}
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label="Max Redeem"
                        name="max_redemptions"
                        type="number"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            if (event.target.value?.length > 0) {
                              setFieldValue("is_promocode_unlimited", false);
                            }
                            setFieldValue(
                              "max_redemptions",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>

                    {/* Selection if the promocode is unlimited */}
                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <CustomizedCheckbox
                            checked={values?.is_promocode_unlimited}
                            onChecked={(event) => {
                              if (event.target.checked) {
                                setFieldValue("max_redemptions", "");
                              }
                              setFieldValue(
                                "is_promocode_unlimited",
                                event.target.checked
                              );
                            }}
                            name="is_promocode_unlimited"
                            helperText={
                              touched?.is_promocode_unlimited &&
                              errors?.is_promocode_unlimited
                            }
                            error={
                              touched?.is_promocode_unlimited &&
                              Boolean(errors?.is_promocode_unlimited)
                            }
                            sx={{ ml: 1 }}
                          />
                        }
                        label="Do you want user to use this promocode unlimited?"
                        // Would you like to allow the user to use this promo code without any limitations?
                      />
                      {touched.is_promocode_unlimited &&
                        Boolean(errors.is_promocode_unlimited) && (
                          <FormHelperText sx={{ color: "#d32f2f", ml: 2 }}>
                            {touched.is_promocode_unlimited &&
                              errors.is_promocode_unlimited}
                          </FormHelperText>
                        )}
                    </Grid>
                  </>
                </Grid>
              </DialogContent>

              {/* <DialogActions> */}

              <>
                <Stack
                  direction="row"
                  pb={2}
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Button
                    className="popup_Btn other_popup_btn"
                    // sx={{
                    //   padding: "10px 20px !important",
                    //   margin: "0 !important",
                    // }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn"
                    // sx={{
                    //   padding: "10px 20px !important",
                    //   margin: "0 !important",
                    // }}
                    type="submit"
                    disabled={
                      JSON.stringify(initialValues) === JSON.stringify(values)
                    }
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </>

              {/* </DialogActions> */}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default BothPlanIncludedForm;
