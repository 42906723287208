import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import mailImg from "../../assets/signin/Mail.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLocalStoarge,
  encryptPayload,
  getObjLength,
} from "../helpers/utils";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { Loader } from "../common/loader";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import { ROLES } from "../helpers/constants";

const FinalStepCompleted = ({ isIOS }) => {
  const { userEmail, sendMailLoader, userSignInDetails, userLoginLoader } =
    useSelector((state) => state.signUpUser);
  // const { userSignInDetails } = useSelector((state) => state.signInUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDataFromSession = JSON.parse(
    localStorage.getItem("userSignupDetails")
  );
  let loginToken = localStorage.getItem("setLoginUserToken");
  console.log(sendMailLoader, "signup email==>", userEmail);
  console.log("signup email==>", loginToken?.length);

  useEffect(() => {
    if (userSignInDetails?.success) {
      sessionStorage.removeItem("userSignupDetails");
      localStorage.removeItem("userSignupDetails");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("user_Id");
      localStorage.removeItem("errorCount");
      localStorage.removeItem("skip");
      localStorage.removeItem("completed");
      localStorage.removeItem("promoCode");
      dispatch(userSigupAction.clearData());
      dispatch(userSigupAction.clearResumeProcessData());
      dispatch(userSigInAction?.clearStoreData());

      if (getObjLength(userSignInDetails) && userSignInDetails?.success) {
        let uID = userSignInDetails?.data?.user?.id;
        localStorage.setItem("uID", uID);
        console.info(
          userSignInDetails,
          "============Login Details============"
        );
        // for the stanup details popup
        localStorage.setItem("roles", encryptPayload(ROLES.customer));
        localStorage.setItem("stanupPopupCount", 1);
        localStorage.setItem("paymentPopup", 0);

        // Temporary hack to not auto login when the user comes from IOS
        if (localStorage.getItem("authToken")) {
          if (isIOS === "ios") {
            console.log("if device is ios !!");
            navigate("/user-profile?from=ios", { replace: false });
          } else {
            console.log("if device is web !!");
            navigate("/user-profile", { replace: false });
          }
        }
      }
    }
    // else {

    // }
  }, [userSignInDetails]);
  console.log(":userSignInDetails==>", userSignInDetails);

  return (
    <Box className="accountBoxWrapper">
      <Typography align="center" variant="h3">
        Success
      </Typography>
      <Typography align="center" variant="body1">
        This is a success message. We sent an email to&nbsp;
        {userEmail ? (
          <b>{userEmail}</b>
        ) : getObjLength(userDataFromSession) ? (
          <b>{userDataFromSession?.email}</b>
        ) : (
          "Your registered email"
        )}
        &nbsp; with the confirmation and the details of your account.
      </Typography>
      <Box className="text-center" py={2}>
        <img src={mailImg} alt="sign" />
      </Box>
      <div className="text-center stepBtn mt-5">
        <Link className="text-white text-decoration-none" to="/login">
          <Button
            onClick={() => {
              clearLocalStoarge("check_county");
              dispatch(userSigupAction.clearData());
            }}
            className="next_button mt-3"
            variant="contained"
          >
            Log In Now
          </Button>
        </Link>
      </div>
      <Loader open={sendMailLoader || userLoginLoader} />
    </Box>
  );
};

export default FinalStepCompleted;
