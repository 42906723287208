import { put, takeLatest, call } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { getAPIPayload } from "../../../apis/getApi";
import { serviceProviderSignInAction } from "../../slices/serviceProvider/SPSignINSlice";
import { serviceProviderSignupAction } from "../../slices/serviceProvider/SPSignUpSlice";

export function* getUserSignInSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  if (response?.data?.status === "pending") {
    yield put(serviceProviderSignupAction.fetchResumeData(response));
  }
  yield put(serviceProviderSignInAction.fetchSignInSuccess(response));
}

export function* getUserForgetPassSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignInAction.fetchForgetPassData(response));
}

export function* getUserResetPassSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignInAction.fetchResetPassData(response));
}

export function* getUserSignoutSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignInAction.fetchSignoutresponse(response));
}

export function* sendOTPSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignInAction.sendOTPResponse(response));
}

export function* verifyOTPSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignInAction.verifyProviderOTPResponse(response));
}

export function* resendotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignInAction.resendOTPResponse(response));
}

export function* getVerifyFaceSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(serviceProviderSignInAction.fetchVerifyFace(response));
}

export function* watchSigninServiceProviderAsync() {
  yield takeLatest(
    serviceProviderSignInAction.userSignInData,
    getUserSignInSaga
  );
  yield takeLatest(
    serviceProviderSignInAction.userForgetPassData,
    getUserForgetPassSaga
  );
  yield takeLatest(
    serviceProviderSignInAction.userResetPassData,
    getUserResetPassSaga
  );
  yield takeLatest(
    serviceProviderSignInAction.userSignoutData,
    getUserSignoutSaga
  );
  yield takeLatest(serviceProviderSignInAction.sendOTPData, sendOTPSaga);
  yield takeLatest(
    serviceProviderSignInAction.providerVerifyOTP,
    verifyOTPSaga
  );
  yield takeLatest(serviceProviderSignInAction.resendOTP, resendotpSaga);
  yield takeLatest(
    serviceProviderSignInAction.getVerifyFace,
    getVerifyFaceSaga
  );
}
