import React from "react";
import file_icon from "../../assets/documents/file_present.png";

const CustomLinkWrapper = ({ link, title, classes, img }) => {
  return (
    <>
      <a
        className={`custom-link-wrapper text-break ${classes ? classes : ""}`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        // title={title}
      >
        {img && <img className="px-2 pb-1" src={file_icon} alt="file_icon" />}
        {title}
      </a>
    </>
  );
};

export default CustomLinkWrapper;
