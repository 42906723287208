import React from "react";

const SendIconSVG = () => {
  return (
    <>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 15.25V0.75L17.7 8L0.5 15.25ZM2 13L13.85 8L2 3V6.7L7.425 8L2 9.3V13ZM2 13V3V9.3V13Z"
          fill="#757575"
        />
      </svg>
    </>
  );
};

export default SendIconSVG;
