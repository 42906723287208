import { put, call, takeLatest } from "redux-saga/effects";
import { getAPIPayload } from "../../../../apis/getApi";
import { seviceProvidersAction } from "../../../slices/admin/serviceProviders/seviceProvidersSlice";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { postAPIPayload } from "../../../../apis/postApi";

export function* fetchSeviceProvidersDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(seviceProvidersAction.fetchSeviceProvidersList(response));
}
export function* fetchHistoryAgreementsListSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(seviceProvidersAction.fetchHistoryAgreementsList(response));
}

export function* fetchActivateSeviceProvidersDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(seviceProvidersAction.fetchActivateSeviceProviders(response));
}

export function* fetchEditSeviceProvidersDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(seviceProvidersAction.fetchEditSeviceProviders(response));
}

export function* fetchDeleteSeviceProvidersDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(deleteAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(seviceProvidersAction.fetchDeleteSeviceProviders(response));
}

export function* fetchActivateDeactivateServiceProviderDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(
    seviceProvidersAction.fetchActivateDeactivateServiceProvider(response)
  );
}

export function* uploadServiceAgreementSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(seviceProvidersAction.setUploadServiceAgreementDetails(response));
}

export function* uploadCompanyLogoSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(seviceProvidersAction.setUploadedCompanyLogoDetails(response));
}

export function* watchSeviceProvidersSagaAsync() {
  yield takeLatest(
    seviceProvidersAction.getSeviceProvidersData,
    fetchSeviceProvidersDataSaga
  );
  yield takeLatest(
    seviceProvidersAction.getHistoryAgreementsData,
    fetchHistoryAgreementsListSaga
  );
  yield takeLatest(
    seviceProvidersAction.getSeviceProvidersActivate,
    fetchActivateSeviceProvidersDataSaga
  );
  yield takeLatest(
    seviceProvidersAction.getEditSeviceProvidersData,
    fetchEditSeviceProvidersDataSaga
  );
  yield takeLatest(
    seviceProvidersAction.getDeleteSeviceProvidersData,
    fetchDeleteSeviceProvidersDataSaga
  );
  yield takeLatest(
    seviceProvidersAction.activateDeactivateServiceProvider,
    fetchActivateDeactivateServiceProviderDataSaga
  );
  yield takeLatest(
    seviceProvidersAction.uploadServiceAgreement,
    uploadServiceAgreementSaga
  );
  yield takeLatest(
    seviceProvidersAction.uploadCompanyLogo,
    uploadCompanyLogoSaga
  );
}
