import { lazy } from "react";
import { Suspense } from "react";
import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ReactGoogleAutocomplete from "react-google-autocomplete";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { GOOGLE_API_KEY, REQUEST_ACCESS_ACTION } from "../helpers/constants";
import { serviceProviderSignupAction } from "../../redux/slices/serviceProvider/SPSignUpSlice";
import {
  getObjLength,
  getShortNameAddressFromGoogleAutoComplete,
  urlPatternValidation,
} from "../helpers/utils";

import "../../styles/forprofessionals.scss";
import { Loader } from "../common/loader";
import { useNavigate } from "react-router-dom";

const WaiverDocumentFormModal = lazy(() => import("./WaiverDocumentFormModal"));
const TerminateDocumentFormModal = lazy(() =>
  import("./TerminateDocumentFormModal")
);
const RestrictionDocumentFormModal = lazy(() =>
  import("./RestrictionDocumentFormModal")
);
const CustomTextField = lazy(() => import("../common/CustomTextField"));
const CallUsNow = lazy(() => import("../common/CallUsNow"));
const AlertDialog = lazy(() => import("../common/AlertDialog"));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RequestDocumentForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState([]);
  const [propertyList, setPropertyList] = useState(false);

  // store data
  const {
    requestAccessLoader,
    requestAccessDetails,
    validateUUIDDetails,
    validateUUIDLoader,
  } = useSelector((state) => state?.serviceProviderSignup);

  // handle request access btn for validating/searching property
  const handleValidateUUID = (values) => {
    dispatch(
      serviceProviderSignupAction.getValidateRequestDetails({
        url: "service-provider/search-property",
        data: {
          transaction_id: values?.transaction_id || undefined,
          address: values?.address || undefined,
          request_action: values?.request_action + " request",
        },
        token: true,
      })
    );
  };

  // useEffect to download UCC form
  useEffect(() => {
    if (
      getObjLength(requestAccessDetails) &&
      requestAccessDetails?.success &&
      urlPatternValidation(requestAccessDetails?.data?.doc_link)
    ) {
      const link = document.createElement("a");
      link.href = requestAccessDetails?.data?.doc_link;
      link.target = "_blank";
      link.click();
    }
  }, [requestAccessDetails]);

  useEffect(() => {
    dispatch(
      userSigupAction.getStateCode({ url: "get-states-code", data: {} })
    );
  }, [dispatch]);

  useEffect(() => {
    if (validateUUIDDetails?.data?.length > 1) {
      setSelectedItem(validateUUIDDetails?.data);
      setPropertyList(true);
    } else {
      setPropertyList(false);
    }
    if (validateUUIDDetails?.data?.length === 1)
      setSelectedItem(validateUUIDDetails?.data?.[0]);
  }, [validateUUIDDetails]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
        className="my-3"
      >
        <Typography variant="h6" className="title">
          Reuqest Document
        </Typography>
      </Box>
      <Box className="d-flex justify-content-center align-items-center professional-content">
        <Box
          sx={{
            bgcolor: "white",
            paddingTop: "14px",
            width: window.innerWidth < 768 ? "100%" : "80%",
            boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
            borderRadius: "15px",
          }}
          className="bg-white mt-5"
        >
          <Box className="accountBoxWrapper">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                transaction_id: "",
                address: "",
                searched_address: "",
                request_action: "",
              }}
              validationSchema={Yup.object().shape(
                {
                  transaction_id: Yup.string(
                    "Please enter UUID/Transaction ID."
                  )
                    .trim(
                      "The UUID/Transaction ID cannot include leading and trailing spaces"
                    )
                    .when("address", {
                      is: (a) => !a || a.length === 0,
                      then: Yup.string().required(
                        "UUID/Transaction ID is required"
                      ),
                      otherwise: Yup.string().optional().nullable(),
                    }),
                  address: Yup.string("Please enter address")
                    .trim()
                    .when("transaction_id", {
                      is: (a) => !a || a.length === 0,
                      then: Yup.string().required("Address is required"),
                      otherwise: Yup.string().optional().nullable(),
                    }),
                  request_action: Yup.string(
                    "Please select any Action"
                  ).required("Please select any Action"),
                },
                [["transaction_id", "address"]]
              )}
              onSubmit={(values, validation) => {
                handleValidateUUID(values);
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                setTouched,
                isSubmitting,
              }) => {
                const clearForm = () => {
                  setFieldValue("transaction_id", "");
                  setFieldValue("address", "");
                  setFieldValue("searched_address", "");
                  setFieldValue("request_action", "");
                  setTouched({
                    request_action: false,
                    transaction_id: false,
                    address: false,
                  });
                };

                const handleCloseBtn = () => {
                  clearForm();
                };

                const handleRequestAccessBtn = (payload) => {
                  let url =
                    values?.request_action === REQUEST_ACCESS_ACTION[0]?.value
                      ? "service-provider/waiver-document"
                      : values?.request_action ===
                        REQUEST_ACCESS_ACTION[1]?.value
                      ? "service-provider/terminate-document"
                      : // : values?.request_action ===
                        //  REQUEST_ACCESS_ACTION[2]?.value
                        // ? "service-provider/restriction-document"
                        "";

                  if (selectedItem?.id) {
                    dispatch(
                      serviceProviderSignupAction?.getRequestAccessDetails({
                        url: url,
                        data: {
                          property_id: selectedItem?.id,
                          ...payload,
                        },
                        token: true,
                      })
                    );
                  }
                };

                return (
                  <>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
                          <Suspense fallback={<div>Loading...</div>}>
                            <CustomTextField
                              label="Enter your UUID/Transaction ID here"
                              name="transaction_id"
                              validation={{
                                values,
                                errors,
                                setFieldValue,
                                touched,
                              }}
                              otherProps={{
                                onChange: (event) => {
                                  if (values?.address) {
                                    setFieldValue("address", "");
                                    setFieldValue("searched_address", "");
                                  }
                                  setFieldValue(
                                    "transaction_id",
                                    event.target.value
                                  );
                                },
                              }}
                            />
                          </Suspense>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex justify-content-center"
                        >
                          <Stack justifyContent="center">OR</Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
                          <ReactGoogleAutocomplete
                            className={`googleAutoComplete mw-100 br-5 ${
                              touched?.address && errors?.address?.length > 0
                                ? "autocomplete-error"
                                : ""
                            } `}
                            apiKey={GOOGLE_API_KEY}
                            value={values?.searched_address}
                            onKeyDown={(event) =>
                              event.key === "Enter"
                                ? event.preventDefault()
                                : null
                            }
                            onChange={(event) => {
                              if (values?.transaction_id) {
                                setFieldValue("transaction_id", "");
                              }
                              setFieldValue(
                                "searched_address",
                                event.target.value
                              );
                              if (!event) {
                                setFieldValue("address", "");
                                setFieldValue("searched_address", "");
                              }
                            }}
                            onPlaceSelected={(place) => {
                              if (values?.transaction_id) {
                                setFieldValue("transaction_id", "");
                              }
                              setFieldValue(
                                "address",
                                getShortNameAddressFromGoogleAutoComplete(
                                  place,
                                  "street_number"
                                ) +
                                  (getShortNameAddressFromGoogleAutoComplete(
                                    place,
                                    "street_number"
                                  )
                                    ? " "
                                    : "") +
                                  getShortNameAddressFromGoogleAutoComplete(
                                    place,
                                    "route"
                                  )
                              );
                            }}
                            libraries={["places"]}
                            options={{
                              types: ["address"],
                              componentRestrictions: { country: "us" },
                            }}
                            placeholder={`Enter User's property address`}
                          />
                          {touched?.address && errors?.address?.length > 0 && (
                            <Typography
                              sx={{
                                color: "#d32f2f !important",
                                fontSize: "12px !important",
                                margin: "5px 0px 0px 15px !important",
                                maxWidth: "100% !important",
                                textAlign: "left",
                              }}
                            >
                              {errors?.address}
                            </Typography>
                          )}
                          {/* <CustomTextField
                        label="Enter property address"
                        name="address"
                        validation={{
                          values,
                          errors,
                          setFieldValue,
                          touched,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            if (values?.transaction_id) {
                              setFieldValue("transaction_id", "");
                            }
                            setFieldValue(
                              "address",
                              event.target.value
                            );
                          },
                        }}
                      /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControl
                            fullWidth
                            className="sign-textField "
                            error={
                              touched.request_action &&
                              Boolean(errors.request_action)
                            }
                          >
                            <InputLabel
                              // id="demo-simple-select-placeholder"
                              style={{ color: "#00000075" }}
                              label="request_action"
                            >
                              Select what you want to do
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              value={values?.request_action}
                              // label="Select what you want to do"
                              name="request_action"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue(
                                  "request_action",
                                  event.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {REQUEST_ACCESS_ACTION?.map((option) => (
                                <MenuItem value={option?.value}>
                                  {option?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.request_action &&
                              Boolean(errors.request_action) && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {touched.request_action &&
                                    errors.request_action}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box className="text-center request-access-box ">
                        <Button
                          variant="contained"
                          type="submit"
                          className="request-access-btn mt-4"
                        >
                          Request Access
                        </Button>
                      </Box>
                    </Form>
                    {getObjLength(validateUUIDDetails) ? (
                      validateUUIDDetails?.success ? (
                        <>
                          {/* open form based on action */}
                          {values?.request_action ===
                          REQUEST_ACCESS_ACTION[0]?.value ? (
                            <Suspense fallback={<div>Loading...</div>}>
                              <WaiverDocumentFormModal
                                open={validateUUIDDetails?.success}
                                selectedData={validateUUIDDetails?.data}
                                loader={requestAccessLoader}
                                handleClose={() => {
                                  dispatch(
                                    serviceProviderSignupAction?.clearValidateRequestDetails()
                                  );
                                  handleCloseBtn();
                                }}
                                onSubmit={(payload) =>
                                  handleRequestAccessBtn(payload)
                                }
                                
                                setSelectedItem={setSelectedItem}
                                selectedItem={selectedItem}
                                setPropertyList={setPropertyList}
                                propertyList={propertyList}
                              />
                            </Suspense>
                          ) : values?.request_action ===
                            REQUEST_ACCESS_ACTION[1]?.value ? (
                            <Suspense fallback={<div>Loading...</div>}>
                              <TerminateDocumentFormModal
                                open={validateUUIDDetails?.success}
                                selectedData={validateUUIDDetails?.data}
                                loader={requestAccessLoader}
                                handleClose={() => {
                                  dispatch(
                                    serviceProviderSignupAction?.clearValidateRequestDetails()
                                  );
                                  handleCloseBtn();
                                }}
                                onSubmit={(payload) =>
                                  handleRequestAccessBtn(payload)
                                }
                                setSelectedItem={setSelectedItem}
                                selectedItem={selectedItem}
                                setPropertyList={setPropertyList}
                                propertyList={propertyList}
                              />
                            </Suspense>
                          ) : values?.request_action ===
                            REQUEST_ACCESS_ACTION[2]?.value ? (
                            <Suspense fallback={<div>Loading...</div>}>
                              <RestrictionDocumentFormModal
                                open={validateUUIDDetails?.success}
                                selectedItem={validateUUIDDetails?.data}
                                loader={requestAccessLoader}
                                handleClose={() => {
                                  dispatch(
                                    serviceProviderSignupAction?.clearValidateRequestDetails()
                                  );
                                  handleCloseBtn();
                                }}
                                onSubmit={(payload) =>
                                  handleRequestAccessBtn(payload)
                                }
                              />
                            </Suspense>
                          ) : null}
                        </>
                      ) : (
                        <Suspense fallback={<div>Loading...</div>}>
                          <AlertDialog
                            type="Error"
                            openPopup={true}
                            onClose={true}
                            onCloseFun={() => {
                              dispatch(
                                serviceProviderSignupAction?.clearValidateRequestDetails()
                              );
                              // handleCloseBtn();
                            }}
                            mesage={
                              <>
                                {validateUUIDDetails?.data?.errors
                                  ?.show_error ? (
                                  <Typography px={3}>
                                    {validateUUIDDetails?.data?.message}
                                  </Typography>
                                ) : (
                                  <Typography px={3}>
                                    Please ensure you've used the correct code
                                    and that the sender has granted full access
                                    to this resource. Also, confirm that the
                                    searched property has an active
                                    subscription.
                                  </Typography>
                                )}
                              </>
                            }
                            buttons={
                              <Button
                                className="popup_Btn error_popup_btn mx-2"
                                onClick={() => {
                                  dispatch(
                                    serviceProviderSignupAction?.clearValidateRequestDetails()
                                  );
                                }}
                              >
                                Close this window
                              </Button>
                            }
                          />
                        </Suspense>
                      )
                    ) : null}

                    {/* {getObjLength(requestAccessDetails) ? (
                      requestAccessDetails?.success ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <AlertDialog
                            type="Success"
                            openPopup={true}
                            onClose={true}
                            onCloseFun={() => {
                              dispatch(
                                serviceProviderSignupAction?.clearRequestAccessDetails()
                              );
                              dispatch(
                                serviceProviderSignupAction?.clearValidateRequestDetails()
                              );
                              handleCloseBtn();
                              navigate("/documents");
                            }}
                            mesage={requestAccessDetails?.message}
                          />
                        </Suspense>
                      ) : (
                        <Suspense fallback={<div>Loading...</div>}>
                          <AlertDialog
                            type="Error"
                            openPopup={true}
                            onClose={true}
                            onCloseFun={() => {
                              dispatch(
                                serviceProviderSignupAction?.clearRequestAccessDetails()
                              );
                              // dispatch(
                              //   serviceProviderSignupAction?.clearValidateRequestDetails()
                              // );
                              // handleCloseBtn();
                            }}
                            mesage={
                              requestAccessDetails?.data?.message ||
                              "Something went wrong"
                            }
                          />
                        </Suspense>
                      )
                    ) : null} */}
                  </>
                );
              }}
            </Formik>
          </Box>
          <Divider />
          <Stack direction={"row"} justifyContent="center" my={3.5}>
            <span className="me-1">Having trouble?</span>
            <Suspense fallback={<div>Loading...</div>}>
              <CallUsNow
                type="text"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                renderText={
                  <span
                    className="text-blue text-decoration-none"
                    style={{ cursor: "pointer" }}
                  >
                    Contact support
                  </span>
                }
              />
            </Suspense>
          </Stack>
        </Box>
      </Box>
      <Loader open={validateUUIDLoader} />
    </>
  );
};

export default RequestDocumentForm;
