import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";

import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";

import { Loader } from "../common/loader";
import mail from "../../assets/icons/mail.png";
import { getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import CustomTextField from "../common/CustomTextField";
import account from "../../assets/icons/account_circle.png";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { userDetailsFormAction } from "../../redux/slices/user/userDetailsFormSlice";

import "./SiteMap.scss";

export const validationSchema = Yup.object().shape({
  name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),

  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),

  phone: Yup.string("Please enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Phone number is required"),
});

const AddUserDetailsFromMail = () => {
  const dispatch = useDispatch();
  const { userToken } = useParams();
  const navigate = useNavigate();

  const { userTokenVarification, userTokenLoader, userFormDetails } =
    useSelector((state) => state.userDetailsForm);

  const handleSubmit = (values) => {
    dispatch(
      userDetailsFormAction.getUserFormDetails({
        url: `send-user-details`,
        data: { ...values, ...userTokenVarification?.data },
      })
    );
  };

  useEffect(() => {
    if (userToken) {
      dispatch(
        userDetailsFormAction.getUserTokenVarificationData({
          url: `verify-user-token`,
          data: { token: userToken },
        })
      );
    } else {
      navigate("/404");
    }
  }, [userToken]);

  useEffect(() => {
    if (getObjLength(userTokenVarification)) {
      if (!userTokenVarification?.success) {
        navigate("/404");
      }
    }
  }, [userTokenVarification]);

  return (
    <>
      <div className="add-user-details ">
        <Box className="add_user_block">
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={{
              name: "",
              email: "",
              phone: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, validation) => {
              if (!isValidPhoneNumber(values?.phone)) {
                return;
              }
              handleSubmit(values);
            }}
          >
            {({ values, setFieldValue, touched, errors }) => {
              return (
                <Form className="beneficiary-form w-100">
                  <Typography className="header_text text-center fw-600">
                    Add User Details
                  </Typography>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid xs={12} md={12} px={1} pb={2}>
                      <CustomTextField
                        placeholder="Your Name"
                        name="name"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("name", event.target.value);
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="textbox-icons"
                                src={account}
                                alt="account"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={12} px={1} pb={2}>
                      <CustomTextField
                        name="email"
                        placeholder="Your Email"
                        type="email"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("email", event.target.value);
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="textbox-icons"
                                src={mail}
                                alt="mail"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={12} px={1} pb={1}>
                      <CustomPhoneNumberInput
                        name="phone"
                        values={values}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("phone", event);
                            if (!event) {
                              setFieldValue("phone", "");
                            }
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box className="text-center createAcBtn mt-3">
                    <Button
                      type="submit"
                      className="popup_Btn success_popup_btn m-1 w-50"
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </div>

      {getObjLength(userFormDetails) && (
        <AlertDialog
          type={userFormDetails?.success ? "Success" : "Error"}
          width={"sm"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(userDetailsFormAction.fetchUserFormDetails({}));
            navigate("/");
          }}
          mesage={
            <p className="pt-3">
              {userFormDetails?.success
                ? userFormDetails?.message
                : userFormDetails?.data?.message}
            </p>
          }
          footer={false}
        />
      )}

      <Loader open={userTokenLoader} />
    </>
  );
};

export default AddUserDetailsFromMail;
