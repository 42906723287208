import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ProfileIcon from "../../assets/dashboard/Dummy_Profile_photo.png";
import { Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import { clearLocalStoarge } from "../helpers/utils";

export default function AdminAccountMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userProfileDetails = useSelector(
    (state) => state.userProfile.userProfileDetails
  );

  // handle My Account Button
  const handleMyAccountBtn = () => {
    if (userProfileDetails?.success) {
      navigate("/admin-profile");
    } else {
      navigate("/login");
    }
  };

  // handle sign out button
  const handleSignOut = () => {
    dispatch(
      userProfileAction.logoutUser({ url: "logout", data: {}, token: true })
    );
    dispatch(userSigInAction.successLogout());
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("authorised");
    // localStorage.removeItem("param");
    // localStorage.removeItem("fixParams");
    // localStorage.removeItem("searched_Add");
    // localStorage.removeItem("searched_Add_popup");
    clearLocalStoarge();

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }

    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {/* desktop */}
      <div
        className="px-3 account-dropdown d-none d-md-block"
        style={{
          borderLeft: "1px solid #E8ECF0",
          borderRight: "1px solid #E8ECF0",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableFocusRipple
            disableRipple
          >
            <Avatar
              sx={{ width: 40, height: 40, mr: 1 }}
              src={
                userProfileDetails?.data?.profile_img
                  ? userProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            ></Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography variant="h6" className="userName">
                {userProfileDetails?.success ? (
                  <>
                    {userProfileDetails?.data?.first_name}{" "}
                    {userProfileDetails?.data?.last_name}
                    <ArrowDropDownIcon
                      sx={{ ms: 1, fontSize: "20px", color: "#15BE53" }}
                    />
                  </>
                ) : (
                  "-"
                )}
              </Typography>
              <Typography variant="caption" className="companyName">
                {userProfileDetails?.data?.company_name}
              </Typography>
            </Box>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          className="d-none d-md-block"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar
              fontSize="medium"
              src={
                userProfileDetails?.data?.profile_img
                  ? userProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            />{" "}
            Profile
          </MenuItem>
          {/* <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar /> My account
          </MenuItem> */}
          {/* <MenuItem className="profile-items">
            <ListItemIcon>
              <Settings fontSize="medium" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem className="profile-items" onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>

      {/* mobile */}
      <div
        className="px-3 account-dropdown mobile d-block d-md-none"
        style={{ borderBottom: "1px solid #E8ECF0" }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: userProfileDetails?.data?.company_name?.trim()
              ? "space-between"
              : "flex-end !important",
          }}
        >
          <Typography variant="caption" className="companyName">
            {userProfileDetails?.data?.company_name}
          </Typography>
          <Typography variant="h6">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              className="userName"
              aria-expanded={open ? "true" : undefined}
              disableFocusRipple
              disableRipple
            >
              {userProfileDetails?.success ? (
                <>
                  {userProfileDetails?.data?.first_name}{" "}
                  {userProfileDetails?.data?.last_name}
                  <ArrowDropDownIcon
                    sx={{ ms: 1, fontSize: "20px", color: "#15BE53" }}
                  />
                </>
              ) : (
                "-"
              )}
            </IconButton>
          </Typography>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className="d-block d-md-none"
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar
              fontSize="medium"
              src={
                userProfileDetails?.data?.profile_img
                  ? userProfileDetails?.data?.profile_img
                  : ProfileIcon
              }
            />{" "}
            Profile
          </MenuItem>
          {/* <MenuItem className="profile-items" onClick={handleMyAccountBtn}>
            <Avatar /> My account
          </MenuItem> */}
          <Divider className="separator" />
          {/* <MenuItem className="profile-items">
            <ListItemIcon>
              <Settings fontSize="medium" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem className="profile-items" onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
