import { useState } from "react";
import React, { useEffect } from "react";

import { Col, Navbar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Button, Container } from "@mui/material";

import { Loader } from "../../common/loader";
import LennarSignupForm from "./LennarSignupForm";
import {
  customeDomainName,
  decryptPayload,
  getObjLength,
} from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import OwnerVerification from "./ownerVerification";
import logo from "../../../assets/lennar/ep-logo.svg";
import ProviderAddProperty from "./ProviderAddProperty";
import LennarPublicPageFooter from "../../layout/LennarPublicPageFooter";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";
import LennarCreateAccountNavBar from "../../layout/LennarCreateAccountNavBar";
import { lennarSubscriptionAction } from "../../../redux/slices/lennar/subscriptionSlice";

import "../../../styles/lennar-create-account.scss";
import { useNavigate } from "react-router-dom";
import OTPVerificationScreen from "./OTPVerificationScreen";

const LennarCreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = window.location.search;
  let code = params?.split("?")[1];
  let paramData = null;

  if (code) {
    paramData = JSON.parse(decryptPayload(code));
    console.log("adress==>", paramData);
    let data = { phone: paramData?.phone, email: paramData?.email };
    localStorage.setItem("user_Id", paramData?.user_id);
    localStorage.setItem("currentStep", paramData?.step);
    localStorage.setItem("userSignupDetails", JSON.stringify(data));
    if (paramData?.last_step === "VERIFY_PHONE_OTP") {
      localStorage.setItem("phone_verified", 1);
    }
    if (getObjLength(paramData?.searched_address)) {
      localStorage.setItem(
        "fixParams",
        JSON.stringify(paramData?.searched_address?.fixed_params)
      );
      localStorage.setItem(
        "searched_Add",
        paramData?.searched_address?.full_address
      );
      localStorage.setItem(
        "param",
        JSON.stringify(paramData?.searched_address?.param)
      );
      localStorage.setItem("searched_Add_popup", true);
    }
  }

  const currentStep = localStorage.getItem("currentStep");

  // store data
  const { checkReCAPTCHALoader } = useSelector((state) => state?.signUpUser);
  const { lennarSignUpDetails, lennarLoader } = useSelector(
    (state) => state?.lennarSignUp
  );
  const {
    providerDetails,
    lennarLoader: lennarDetailsLoader,
    providerLoader,
  } = useSelector((state) => state?.lennarSubscription);

  // states
  const [screen, setScreen] = useState("create-account");
  const [user_id, setUser_id] = useState();
  const [resumeCount, setResumeCount] = useState(parseInt(currentStep));

  const pathName = window.location.pathname;
  const fetchCustomDomain = customeDomainName(window.location.host);
  let path = null;
  if (!fetchCustomDomain) {
    path = pathName?.split("/")?.[1];
  } else {
    path = fetchCustomDomain;
  }
  console.log("path==>", path);
  console.log(paramData, "paramString 1=>", path);

  const resumetheFlow = () => {
    setUser_id(localStorage.getItem("user_Id"));
    if (parseInt(currentStep) === 0) {
      setScreen("otp-verification");
    }
    if (parseInt(currentStep) === 1) {
      setScreen("add-property");
    }
    if (parseInt(currentStep) > 1) {
      setScreen("owner-verification");
    }
    localStorage.removeItem("resumeCount");
    setResumeCount(undefined);
  };

  useEffect(() => {
    if (path?.length > 0) {
      dispatch(
        lennarSubscriptionAction?.getProviderDetails({
          url: `providers/${path}/detail`,
          data: {},
        })
      );
    }
    localStorage.removeItem("roles");
  }, [dispatch, path]);

  useEffect(() => {
    console.log("providerDetails==>", providerDetails);
    if (getObjLength(providerDetails)) {
      if (
        providerDetails?.success &&
        parseInt(localStorage.getItem("resumeCount")) === 1
      ) {
        setResumeCount(undefined);
        setUser_id(localStorage.getItem("user_Id"));
        if (parseInt(currentStep) === 0) {
          setScreen("otp-verification");
        }
        if (parseInt(currentStep) === 1) {
          setScreen("add-property");
        }
        if (parseInt(currentStep) > 1) {
          setScreen("owner-verification");
        }
        localStorage.removeItem("resumeCount");
      }
      if (!providerDetails?.success) {
        navigate("/404");
      }
      if (providerDetails?.success && !providerDetails?.data?.is_customer_ep) {
        navigate("/404");
      }
    }
  }, [providerDetails]);

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    // Check if the URL contains the query parameter 'abcd'
    if (currentUrl.includes(code)) {
      // Remove the query parameter
      const newUrl = currentUrl.replace(`?${code}`, "");

      // Use react-router-dom to navigate to the new URL
      navigate(newUrl.replace(window.location.origin, ""));
      localStorage.setItem("resumeCount", 1);
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [screen]);

  let org_logo = logo;
  console.log(("user_id==>", user_id));
  return (
    <>
      <>
        {/* <LennarCreateAccountNavBar /> */}
        <div className="create-account">
          <section className=" background_img">
            <Navbar.Brand className="d-flex align-items-center justify-content-center">
              {providerDetails?.data?.logo && (
                <img
                  className="lennar-logo"
                  src={providerDetails?.data?.logo}
                  alt="lennar-logo"
                />
              )}
              <img className="lennar-logo" src={org_logo} alt="lennar-logo" />
            </Navbar.Brand>
            <Container fluid className="align-items-center text-white">
              <Row className="mt-2 mt-sm-2 justify-content-center text-center">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={10}
                  xl={10}
                  className="content-spacing"
                >
                  <h2 className="main-text mt-2">Create Your Account</h2>
                  <p className="paragraph_texts fw-400 mt-2 mx-auto">
                    We just need to gather some information to set up your
                    digital profile.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="reviews_wrapper mb-4">
            <Container fluid className="bg-white main-container mt-5">
              {screen === "create-account" ? (
                <>
                  <LennarSignupForm path={path} />
                </>
              ) : screen === "otp-verification" ? (
                <OTPVerificationScreen
                  path={path}
                  setScreen={setScreen}
                  user_id={user_id}
                />
              ) : screen === "add-property" ? (
                <ProviderAddProperty
                  path={path}
                  setScreen={setScreen}
                  user_id={user_id}
                />
              ) : (
                <OwnerVerification
                  path={path}
                  setScreen={setScreen}
                  user_id={user_id}
                />
              )}
            </Container>
          </section>
        </div>
        <LennarPublicPageFooter />
      </>

      {getObjLength(lennarSignUpDetails) ? (
        !lennarSignUpDetails?.success ? (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(lennarSignUpAction.fetchCreateLennarUserData({}));
            }}
            mesage={lennarSignUpDetails?.data?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              setUser_id(lennarSignUpDetails?.data?.user_id);
              dispatch(lennarSignUpAction.fetchCreateLennarUserData({}));
              setScreen("otp-verification");
            }}
            mesage={lennarSignUpDetails?.message}
            footer={false}
          />
        )
      ) : null}

      {!lennarDetailsLoader && resumeCount + 1 > 0 && (
        <AlertDialog
          openPopup={resumeCount + 1 > 0 ? true : false}
          onClose={false}
          type="Warning"
          mesage={"Do you want to resume from where you left."}
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => {
                  setResumeCount(undefined);
                  localStorage.clear();
                }}
              >
                Nope
              </Button>
              <Button
                className="popup_Btn success_popup_btn "
                onClick={resumetheFlow}
              >
                Yes
              </Button>
            </>
          }
        />
      )}

      <Loader
        open={
          checkReCAPTCHALoader ||
          lennarLoader ||
          lennarDetailsLoader ||
          providerLoader
        }
      />
    </>
  );
};
export default LennarCreateAccount;
