import React from "react";
import { useEffect } from "react";
import {getObjLength} from "../../helpers/utils";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import { Loader } from "../../common/loader";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";

import "../../common/common.scss";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const FieldStyle = {
  borderRadius: "3px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddProfessionalModal = ({
  page,
  open,
  getProfessionalUrl,
  selectedItem,
  handleClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const {
    addProfessionalDetails,
    professionalListLoader,
    professionalTypeList,
  } = useSelector((state) => state.professionals);

  // clear add/edit/delete professional's existing data
  useEffect(() => {
    if (open) {
      dispatch(professionalAction.clearAddProfessionalDetailsData());
    }
  }, [dispatch, open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={addProfessionalDetails?.success ? "sm": "md" }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        {!addProfessionalDetails?.success && <>
        <DialogTitle id="alert-dialog-title">
          {selectedItem ? "Edit Professional" : "Add Professional"}
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <Box className="d-flex justify-content-center bg-white">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                first_name: selectedItem?.first_name || "",
                last_name: selectedItem?.last_name || "",
                email: selectedItem?.email || "",
                phone_number: selectedItem?.phone_number || "",
                company_name: selectedItem?.company_name || "",
                zip_code: selectedItem?.zip_code || "",
                zip_code2: selectedItem?.zip_code2 || "",
                professional_type:
                  professionalTypeList?.data?.filter(
                    (type) => type?.id === selectedItem?.professional_type?.id
                  )?.length > 0
                    ? selectedItem?.professional_type?.id
                    : "",
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string("Please enter your First Name.")
                  .trim(
                    "The First name cannot include leading and trailing spaces"
                  )
                  .strict(true)
                  .required("First Name is required"),
                last_name: Yup.string("Please enter your Last name.")
                  .trim(
                    "The Last name cannot include leading and trailing spaces"
                  )
                  .strict(true)
                  .required("Last Name is required"),
                email: Yup.string("Please enter your Email.")
                  .trim("The Email cannot include leading and trailing spaces")
                  .strict(true)
                  .email("Please enter a valid email address")
                  .required("Email is required"),
                phone_number: Yup.string("PLease enter your Phone number.")
                  .typeError("That doesn't look like a phone number")
                  .required("Phone number is required"),
                company_name: Yup.string("Please enter your company name.")
                  .trim(
                    "The company name cannot include leading and trailing spaces"
                  )
                  .strict(true)
                  .required("Company name is required"),
                zip_code: Yup.string("Please enter your Zip code 1.")
                  .required("Zipcode 1 is required")
                  .matches(/^[0-9]{5}$/, "Zipcode 1 must be exactly 5 digits"),
                zip_code2: Yup.string("Please enter your Zip code 2.")
                  .required("Zipcode 2 is required")
                  .matches(/^[0-9]{5}$/, "Zipcode 2 must be exactly 5 digits")
                  .test(
                    "zip_code2-not-greater",
                    "Zipcode 2 must be greater or equal to Zipcode 1",
                    function (value) {
                      const zip1 = this.resolve(Yup.ref("zip_code")); // Get the value of zip1
                      return value >= zip1; // Check if zip2 is less than zip1
                    }
                  ),
                professional_type: Yup.string(
                  "Please enter your Professional type."
                ).required("Professional type is required"),
              })}
              onSubmit={(values) => {
                if (!isValidPhoneNumber(values?.phone_number)) {
                  return;
                }
                onSubmit(values);
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                resetForm,
                initialValues,
              }) => {
                return (
                  <Form className="beneficiary-form w-100">
                    <DialogContent className="bg-white">
                      {/* {!addProfessionalDetails?.success && ( */}
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        className="bg-white"
                      >
                        <>
                          {/* first name */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="First name"
                              name="first_name"
                              value={values?.first_name}
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              onChange={(event) => {
                                setFieldValue("first_name", event.target.value);
                              }}
                              helperText={
                                touched.first_name && errors.first_name
                              }
                              error={
                                touched.first_name && Boolean(errors.first_name)
                              }
                            />
                          </Grid>
                          {/* last name */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Last name"
                              name="last_name"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.last_name}
                              onChange={(event) => {
                                setFieldValue("last_name", event.target.value);
                              }}
                              helperText={touched.last_name && errors.last_name}
                              error={
                                touched.last_name && Boolean(errors.last_name)
                              }
                            />
                          </Grid>
                          {/* email */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Email"
                              name="email"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.email}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                              }}
                              helperText={touched.email && errors.email}
                              error={touched.email && Boolean(errors.email)}
                            />
                          </Grid>
                          {/* phone number */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <CustomPhoneNumberInput
                              name="phone_number"
                              values={values}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              errors={errors}
                            />
                          </Grid>
                          {/* company */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Company"
                              name="company_name"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.company_name}
                              onChange={(event) => {
                                setFieldValue(
                                  "company_name",
                                  event.target.value
                                );
                              }}
                              helperText={
                                touched.company_name && errors.company_name
                              }
                              error={
                                touched.company_name &&
                                Boolean(errors.company_name)
                              }
                            />
                          </Grid>
                          {/* professional type */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <FormControl
                              fullWidth
                              className="sign-textField "
                              error={
                                touched.professional_type &&
                                Boolean(errors.professional_type)
                              }
                            >
                              <InputLabel
                                // id="demo-simple-select-placeholder"
                                style={{ color: "#00000075" }}
                                label="professional_type"
                              >
                                Professional Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-placeholder"
                                id="demo-simple-select"
                                value={values?.professional_type}
                                disabled={selectedItem?.id ? true : false}
                                label="Professional type"
                                name="professional_type"
                                variant="outlined"
                                onChange={(event) => {
                                  setFieldValue(
                                    "professional_type",
                                    event.target.value
                                  );
                                }}
                                MenuProps={MenuProps}
                              >
                                {professionalTypeList?.data?.map((data) => (
                                  <MenuItem value={data?.id}>
                                    {data?.type}
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.professional_type &&
                                Boolean(errors.professional_type) && (
                                  <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {touched.professional_type &&
                                      errors.professional_type}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                          {/* zip code 1 */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Zip Code 1"
                              name="zip_code"
                              type="number"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.zip_code}
                              onChange={(event) => {
                                setFieldValue("zip_code", event.target.value);
                              }}
                              helperText={touched.zip_code && errors.zip_code}
                              error={
                                touched.zip_code && Boolean(errors.zip_code)
                              }
                            />
                          </Grid>
                          {/* zip code 2 */}
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Zip Code 2"
                              name="zip_code2"
                              type="number"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.zip_code2}
                              onChange={(event) => {
                                setFieldValue("zip_code2", event.target.value);
                              }}
                              helperText={touched.zip_code2 && errors.zip_code2}
                              error={
                                touched.zip_code2 && Boolean(errors.zip_code2)
                              }
                            />
                          </Grid>
                        </>
                      </Grid>
                      {/* )}
                      <Typography
                        className={`${
                          addProfessionalDetails?.success
                            ? "text-success"
                            : "text-danger"
                        } text-center w-100`}
                      >
                        {addProfessionalDetails?.success
                          ? addProfessionalDetails?.message
                          : addProfessionalDetails?.data?.message}
                      </Typography> */}
                    </DialogContent>

                    <DialogActions className="alert-modal-btn">
                      <Stack direction="row" spacing={2} pb={2}>
                        {addProfessionalDetails?.success ? (
                          <Button
                            className="popup_Btn other_popup_btn m-1"
                            // variant="contained"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Close
                          </Button>
                        ) : (
                          <>
                            <Button
                              className="popup_Btn other_popup_btn m-1"
                              // variant="contained"
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="popup_Btn success_popup_btn m-1"
                              type="submit"
                              disabled={
                                JSON.stringify(initialValues) ===
                                JSON.stringify(values)
                              }
                              variant="contained"
                            >
                              Save
                            </Button>
                          </>
                        )}
                        
                      </Stack>
                      
                    </DialogActions>
                    {getObjLength(addProfessionalDetails) && !addProfessionalDetails?.success && (
                        <p className="text-danger text-center p-2">{addProfessionalDetails?.data?.message}</p>
                      )}
                  </Form>
                  
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
        </>}
        {addProfessionalDetails?.success && (
        <PlainAlertMessage
          type={addProfessionalDetails?.success && "Success"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (addProfessionalDetails?.success) {
              dispatch(professionalAction.clearAddProfessionalDetailsData());
              handleClose(false);
              dispatch(
                professionalAction.fetchProfessionalListData({
                  url: "professional",
                  data: {},
                  token: true,
                })
              );
            } else {
              dispatch(professionalAction.clearAddProfessionalDetailsData());
            }
          }}
          message={
            addProfessionalDetails?.success
              ? addProfessionalDetails?.message
              : addProfessionalDetails?.data?.message
          }
          footer={false}
        />
      )}
        <Loader open={professionalListLoader} />
      </Dialog>
    </>
  );
};

export default AddProfessionalModal;
