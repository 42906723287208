import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import AlertDialog from "../common/AlertDialog";
import ContactSupport from "../common/ContactSupport";
import { Loader } from "../common/loader";
import { autoFocusOnField, getObjLength } from "../helpers/utils";

const SecurityPinGeneration = ({
  onBtnClick,
  errorPopup,
  reduceErrorCount,
}) => {
  const [pin, SetPIN] = useState("");
  const [FinalPIN, SetFinalPIN] = useState("");
  const [FinalPINScreen, SetFinalPINScreen] = useState(false);
  const [pinErr, SetPINErr] = useState(undefined);
  const [pin2Err, SetPIN2Err] = useState(undefined);
  const [pinSucess, SetPINSucess] = useState("");
  const [pin2Sucess, SetPIN2Sucess] = useState("");
  const [diableMobileBtn, setdiableMobileBtn] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  const accCreated = useSelector((state) => state.signUpUser.createAccResponse);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  const dispatch = useDispatch();
  const { userSecurityPin, sendMailLoader } = useSelector(
    (state) => state.signUpUser
  );

  // const queryString = window.location.search;
  // let paramString = queryString.split("?")[1];
  // let source = paramString?.split("=");
  // let isIOS = source?.[1]?.split("&")?.[0];

  console.log("userSecurityPin===>>", userSecurityPin);

  const handlePIN1Change = (pin) => {
    SetPINSucess("");
    if (pin.length === 5) {
      SetPINErr("");
    }
    console.log("pin handled==>>", pin);
    SetPIN(pin);
  };
  const handlePIN2Change = (pin) => {
    SetPIN2Sucess("");
    if (pin.length === 5) {
      SetPIN2Err("");
    }
    console.log("pin handled==>>", pin);
    SetFinalPIN(pin);
  };

  const pinValidate = (event) => {
    event.preventDefault();
    console.log("in pin validation", pin.length);
    SetPINSucess("");
    // SetEmailScreen(true);
    // props.onBtnClick();
    if (pin.length === 5) {
      SetFinalPINScreen(true);
      autoFocusOnField();
    } else {
      SetPINErr("Please Enter 5 digit Pin to continue further.");
    }
  };
  const matchPINValidate = (event) => {
    event.preventDefault();
    console.log("in pin validation", pin.length);
    SetPIN2Sucess("");
    // props.onBtnClick();
    if (FinalPIN.length === 5) {
      if (pin === FinalPIN) {
        SetPIN2Err("");
        let inputParam = {
          user_id: getObjLength(accCreated)
            ? accCreated.data?.user_id
            : userIdFromSession,
          security_pin: pin,
          step: 5,
          step_key: "SECURITY_PIN",
        };
        dispatch(
          userSigupAction.userSecurityPin({
            url: "security-pin",
            data: inputParam,
          })
        );
      } else {
        SetPIN2Err("Pin does not match. Please try again.");
      }
    } else {
      SetPIN2Err("Please Enter 5 digit Pin to continue further.");
    }
  };
  const retryPIN = () => {
    SetPIN("");
    SetFinalPIN("");
    SetFinalPINScreen(false);
    SetPIN2Err("");
    autoFocusOnField();
  };

  const onCloseFunction = () => {
    setAlertMsg(false);
    // if (isIOS === "ios") {
    //   console.log("Native App got called.. ");
    //   if (
    //     window?.webkit &&
    //     window?.webkit?.messageHandlers &&
    //     window?.webkit?.messageHandlers?.invokeNativeBiometric
    //   ) {
    //     window.webkit.messageHandlers.invokeNativeBiometric.postMessage({
    //       user_id: getObjLength(accCreated)
    //         ? accCreated.data?.user_id
    //         : userIdFromSession,
    //       step: 6,
    //       step_key: "REGISTER_FACE",
    //     });
    //   }
    // }

    onBtnClick();
  };

  useEffect(() => {
    localStorage.removeItem("promoCode");
    localStorage.removeItem("referralCode");
    if (getObjLength(userSecurityPin)) {
      setAlertMsg(true);
      if (userSecurityPin?.success) {
        setSuccessMsg(userSecurityPin?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(userSecurityPin?.data?.message);
      }
    }
  }, [userSecurityPin]);

  const PINFieldStyle = {
    background: "#F6F9FC",
    border: "1px solid rgba(52, 75, 91, 0.1)",
    borderRadius: "3px",
    width: "40px",
    height: "55px",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    textAlign: "center",
    color: "#0D120B",
  };
  return (
    <Box className="accountBoxWrapper">
      <Typography align="center" variant="h3">
        {!FinalPINScreen ? "Create Security Pin" : "Confirm Security Pin"}
      </Typography>
      {!FinalPINScreen ? (
        <form onSubmit={pinValidate}>
          <Typography className="para" align="center" variant="body1">
            In order to protect your account from unauthorized access we need to
            create a PIN that is only known to you. This will prevent others
            from calling our customer service and attempting to make
            unauthorized changes or gather information. <br />
            <br />
            Please create a 5 digit pin. Please be prepared to confirm this
            number on the next screen.
          </Typography>
          <Box className="text-center otpBoxWrapper">
            <OtpInput
              className="otp mx-1"
              value={pin}
              isInputNum
              onChange={handlePIN1Change}
              numInputs={5}
              shouldAutoFocus
              disabled={diableMobileBtn}
              inputStyle={PINFieldStyle}
            />
            {pinErr?.length > 0 && pinSucess?.length === 0 ? (
              <Typography variant="caption" className="text-danger 1" p={2}>
                {pinErr}
              </Typography>
            ) : (
              <Typography variant="caption" className="text-success 3" p={2}>
                {pinSucess}
              </Typography>
            )}
          </Box>
          <div className="text-center stepBtn mt-2">
            <Button
              type="submit"
              onClick={pinValidate}
              className="next_button mb-3"
              variant="contained"
              disabled={diableMobileBtn}
            >
              Next
            </Button>
          </div>
        </form>
      ) : (
        <form onSubmit={matchPINValidate}>
          <Typography align="center" variant="body1">
            Confirm the 5 digits pin which you can use to connect to us.
          </Typography>
          <Box className="text-center otpBoxWrapper">
            <OtpInput
              className="otp mx-1"
              value={FinalPIN}
              isInputNum
              onChange={handlePIN2Change}
              numInputs={5}
              shouldAutoFocus
              disabled={diableMobileBtn}
              inputStyle={PINFieldStyle}
            />
            {pin2Err?.length > 0 && pin2Sucess?.length === 0 ? (
              <Typography variant="caption" className="text-danger 1" p={2}>
                {pin2Err}
              </Typography>
            ) : (
              <Typography variant="caption" className="text-success 3" p={2}>
                {pin2Sucess}
              </Typography>
            )}
          </Box>
          <div className="text-center stepBtn mt-2">
            <Button
              onClick={retryPIN}
              className="popup_Btn mx-3 other_popup_btn"
              variant="contained"
              disabled={diableMobileBtn}
            >
              Retry
            </Button>
            <Button
              type="submit"
              onClick={matchPINValidate}
              className="popup_Btn success_popup_btn"
              variant="contained"
              disabled={diableMobileBtn}
            >
              Next
            </Button>
          </div>
        </form>
      )}

      {alertMsg && getObjLength(userSecurityPin) && (
        <>
          {getObjLength(userSecurityPin) && userSecurityPin?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={false}
              onCloseFun={() => {
                onCloseFunction();
              }}
              mesage={successMsg}
              buttons={
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => onCloseFunction()}
                >
                  Next step
                </Button>
              }
            />
          ) : (
            <AlertDialog
              type={"Error"}
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                reduceErrorCount();
              }}
              mesage={
                <>
                  <p className={errorPopup ? `text-danger` : ""}> {errMsg}</p>
                  {errorPopup && (
                    <p>
                      <ContactSupport />
                    </p>
                  )}
                </>
              }
            />
          )}
        </>
      )}

      <Loader open={sendMailLoader} />
    </Box>
  );
};

export default SecurityPinGeneration;
