/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

import { Loader } from "../common/loader";
import { PER_PAGE, ROLES } from "../helpers/constants";
import SubscriptionListTable from "./SubscriptionListTable";
import {
  decryptPayload,
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../helpers/utils";
import PaymentFailed from "../userProfile/Dashboard/PaymentFailed";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { subscriptionAction } from "../../redux/slices/subscription/subscriptionSlice";

import "./subscription.scss";
import "../../styles/dashboard.scss";

const columns = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    id: "renew_plan1",
    disablePadding: true,
    label: "Renew Plan",
    minWidth: 90,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
    minWidth: 150,
  },
  {
    id: "name",
    disablePadding: true,
    label: "Subscription Plan",
    minWidth: 100,
  },
  {
    id: "amount",
    disablePadding: true,
    label: "Subscription Amount",
    minWidth: 100,
  },
  // {
  //   id: "discount",
  //   disablePadding: true,
  //   label: "Discount",
  //   minWidth: 100,
  // },
  // {
  //   id: "local_tax",
  //   disablePadding: true,
  //   label: "Sales/Local Tax",
  //   minWidth: 100,
  // },
  // {
  //   id: "recording_fees",
  //   disablePadding: true,
  //   label: "Deposit for recording fees",
  //   minWidth: 130,
  // },
  // {
  //   id: "setup_fees",
  //   disablePadding: true,
  //   label: "Setup Fees",
  //   minWidth: 100,
  // },
  // {
  //   id: "estimated_amount",
  //   disablePadding: true,
  //   label: "Estimated Amount",
  //   minWidth: 100,
  // },
  {
    id: "paid_amount",
    disablePadding: true,
    label: "Paid Amount",
    minWidth: 90,
  },
  {
    id: "start_at",
    disablePadding: true,
    label: "Start Date",
    minWidth: 100,
  },
  {
    id: "end_at",
    disablePadding: true,
    label: "End Date",
    minWidth: 100,
  },
  {
    id: "is_active",
    disablePadding: true,
    label: "Status",
    minWidth: 80,
  },
  {
    id: "renew_plan",
    disablePadding: true,
    label: "Purchase Plan",
    minWidth: 110,
  },
  {
    id: "history",
    disablePadding: true,
    label: "History",
  },
];

const Subscriptions = () => {
  const dispatch = useDispatch();

  const [metaData, setMetData] = useState({});
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);

  const { subscriptionData, subscriptionLoader } = useSelector(
    (state) => state.subscription
  );

  const {
    loggedInEmail,
    paymnetDoneDetails,
    isPaymentPending,
    paymentLoader,
    loginAsSpouse: isSecondaryUser,
    parent_id,
  } = useSelector((state) => state?.userProfile);

  // get url for customer or sub user
  const getUrl = () => {
    let url;
    if (isSecondaryUser) {
      url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "customer"
      }/property-subscription/${parent_id}`;
    } else {
      url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "customer"
      }/property-subscription`;
    }
    return url;
  };

  const subscriptionApiCall = () => {
    let plan_renewal = localStorage.getItem("plan_renewal");
    let url = `${getUrl()}?limit=${PER_PAGE}&offset=${0}`;
    if (plan_renewal) {
      url = `${getUrl()}?limit=${PER_PAGE}&offset=${0}&id=${plan_renewal}`;
    }
    dispatch(
      subscriptionAction.getSubscriptionData({
        url: url,
        data: {},
        token: true,
      })
    );
  };

  // for react pixel events
  useEffect(() => {
    if (loggedInEmail?.length > 0) {
      reactPixelEventOnlyForCustomer("Subscription Page", {
        email: loggedInEmail,
      });
    }
  }, [loggedInEmail]);

  // useEffect(() => {
  //   if (getObjLength(subscriptionRenewed)) {
  //     if (
  //       subscriptionRenewed?.success &&
  //       !subscriptionRenewed?.actionRequired
  //     ) {
  //       reactPixelEventOnlyForCustomer("Subscription Renwed", {
  //         ...metaData,
  //         renwed: "successful",
  //       });
  //     }
  //   }
  // }, [subscriptionRenewed]);

  useEffect(() => {
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  return (
    <>
      <Box className="profile-wrapper subscription">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Typography variant="h6" className="title">
            Subscriptions
          </Typography>
        </Box>

        <SubscriptionListTable
          columns={columns}
          rowsData={
            subscriptionData?.data?.length > 0 ? subscriptionData?.data : []
          }
          setMetData={setMetData}
        />
      </Box>
      {/* Paymnet failed Popup */}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed
            isPaymentDonePopup={isPaymentDonePopup}
            setIsPaymentDonePopup= {setIsPaymentDonePopup}
            callListAPI={true}
            apiCall={subscriptionApiCall}
          />
        )}
      <Loader open={subscriptionLoader || paymentLoader} />
    </>
  );
};

export default Subscriptions;
