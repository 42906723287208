import React from "react";

import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { getURLName } from "../../helpers/utils";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";

const DocumnetListModal = ({ open, handleClose, files }) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="d-flex justify-content-between align-items-center"
      >
        {/* Document List */}
        <Typography variant="h6">Document List</Typography>
        <Button
          onClick={handleClose}
          className="modal-close-btn"
          style={{
            position: "absolute",
            right: 10,
            top: 15,
            background: "transparent",
          }}
        >
          <CloseIcon sx={{ color: "#9CB8A6" }} />
        </Button>
      </DialogTitle>
      <DialogContent dividers mb={2}>
        <Box pt={0} className="dropzone-items">
          {files?.map((file) => (
            <Box className="preview-file bg-white" padding="5px 15px" m={0.5}>
              <CustomLinkWrapper
                link={file ? file?.path + file?.filename : "#"}
                title={getURLName(file?.filename)}
                key={file?.path}
                classes="preview-url"
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DocumnetListModal;
