import React, { useCallback, useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { ROLES, secretKeyEncrypt } from "../../helpers/constants";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { encryptData } from "../../helpers/utils";

const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  address: Yup.string("Please enter your Address.")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),
  // .required("Address is required")
  city: Yup.string("Please enter your City.")
    .trim("The city cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),

  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // .min(6, "Zip needs 6 digits")
  // .max(6, "Zip needs 6 digits"),
  phone1: Yup.string("PLease enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Mobile number is required"),
  old_password: Yup.string("Please enter Password.")
    // .required("Password is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    )
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    )
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().required("Old Password is required"),
    }),
  password: Yup.string("Please enter Password.")
    // .required("Password is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    ),
  password_confirmation: Yup.string("Please enter Confirm Password.")
    // .required("Confirm Password is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf([Yup.ref("password")], "Both password need to be the same")
        .required("Confirm Password is required"),
    }),
});

export const DashboadrForm = ({
  userProfileDetails,
  stateData,
  edit,
  setEdit,
  formRef,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [successPopup, setsuccessPopup] = useState(false);
  const [failedPopup, setfailedPopup] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const editedData = useSelector(
    (state) => state.userProfile.editedUserProfileDetails
  );

  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values, validation) => {
    const payload = JSON.parse(JSON.stringify(values));
    payload.password = values?.password?.trim();
    const encryptedOldPassword = encryptData(
      payload?.old_password,
      secretKeyEncrypt.toString()
    );
    const encryptedPassword = encryptData(
      payload?.password,
      secretKeyEncrypt.toString()
    );
    payload.old_password = encryptedOldPassword;
    payload.password = encryptedPassword;
    payload.password_confirmation = encryptedPassword;
    handleToggle();
    dispatch(
      userProfileAction.editUserProfileData({
        url: "profile",
        data: payload,
        token: true,
      })
    );
    validation.resetForm();
    console.log("validation", validation);
  };

  const handleClose = useCallback(() => {
    if (editedData?.success) {
      setsuccessPopup(true);
      setfailedPopup(false);
    } else {
      setsuccessPopup(false);
      setfailedPopup(true);
    }
    setOpen(false);
  }, [editedData?.success]);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    console.log("Edited Data==>>", editedData);
    if (Object.keys(editedData).length > 0) {
      handleClose();
    }
  }, [editedData, handleClose]);

  // const verifyStannupEmail = () => {
  //   console.log("verifyStannupEmail==>");
  //   setStannupPop(true);
  // };
  // const closeStanupPopup = () => {
  //   setStannupPop(false);
  // };

  useEffect(() => {
    dispatch(userSigupAction.getState({ url: "states" }));
  }, [dispatch]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          first_name: userProfileDetails?.data?.first_name || "",
          last_name: userProfileDetails?.data?.last_name || "",
          email: userProfileDetails?.data?.email || "",
          phone1: userProfileDetails?.data?.phone_number || "",
          address: userProfileDetails?.data?.address?.address || "",
          city: userProfileDetails?.data?.address?.city || "",
          state: userProfileDetails?.data?.address?.state || "",
          zip: userProfileDetails?.data?.address?.zip || "",
          unit_no: userProfileDetails?.data?.address?.unit_no || "",
          password: "",
          password_confirmation: "",
          old_password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {({ values, setFieldValue, touched, errors, resetForm }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield text-dark"
                    label="First Name"
                    placeholder="First Name"
                    name="first_name"
                    value={values?.first_name}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("first_name", event.target.value);
                    }}
                    helperText={touched.first_name && errors.first_name}
                    error={touched.first_name && Boolean(errors.first_name)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    label="Last Name"
                    placeholder="Last Name"
                    variant="outlined"
                    name="last_name"
                    value={values?.last_name}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("last_name", event.target.value);
                    }}
                    helperText={touched.last_name && errors.last_name}
                    error={touched.last_name && Boolean(errors.last_name)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomPhoneNumberInput
                    name="phone1"
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    disabled={
                      userProfileDetails?.data?.role?.includes(ROLES?.admin) ||
                      userProfileDetails?.data?.role?.includes(ROLES?.subAdmin)
                        ? !edit
                        : true
                    }
                    edit={edit}
                    otherProps={{
                      onChange: (event) => {
                        setFieldValue("phone1", event);
                        setFieldValue("phone", event);
                        if (!event) {
                          setFieldValue("phone1", "");
                          setFieldValue("phone", "");
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={true}
                    className="textfield"
                    variant="outlined"
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    value={values?.email}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("email", event.target.value);
                    }}
                    helperText={touched.email && errors.email}
                    error={touched.email && Boolean(errors.email)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    label="Address"
                    placeholder="Address"
                    name="address"
                    value={values?.address}
                    fullWidth
                    // InputProps={
                    //   pending && {
                    //     endAdornment: (
                    //       <InputAdornment
                    //         className="prefixInput"
                    //         position="start"
                    //       >
                    //         <Button
                    //           onClick={verifyStannupEmail}
                    //           disabled={edit}
                    //           color="success"
                    //         >
                    //           {!edit ? "Verify" : "Pending"}
                    //         </Button>
                    //       </InputAdornment>
                    //     ),
                    //   }
                    // }
                    onChange={(event) => {
                      setFieldValue("address", event.target.value);
                    }}
                    helperText={touched.address && errors.address}
                    error={touched.address && Boolean(errors.address)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    label="City"
                    placeholder="City"
                    name="city"
                    value={values?.city}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("city", event.target.value);
                    }}
                    helperText={touched.city && errors.city}
                    error={touched.city && Boolean(errors.city)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl
                    fullWidth
                    className="sign-textField "
                    error={touched.state && Boolean(errors.state)}
                  >
                    <InputLabel label="State">State</InputLabel>
                    <Select
                      disabled={!edit}
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      value={values?.state}
                      label="State"
                      name="state"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("state", event.target.value);
                      }}
                      MenuProps={MenuProps}
                    >
                      {stateData?.map((state) => (
                        <MenuItem value={state.name}>{state.name}</MenuItem>
                      ))}
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.state && errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    label="Zip"
                    placeholder="Zip"
                    name="zip"
                    value={values?.zip}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("zip", event.target.value);
                    }}
                    helperText={touched.zip && errors.zip}
                    error={touched.zip && Boolean(errors.zip)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    label="Unit Number"
                    placeholder="Unit Number"
                    name="unit_no"
                    value={values?.unit_no}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("unit_no", event.target.value);
                    }}
                    helperText={touched.unit_no && errors.unit_no}
                    error={touched.unit_no && Boolean(errors.unit_no)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OutlinedInput
                    disabled={!edit}
                    className="textfield"
                    label="Old Password"
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Old Password"
                    name="old_password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!edit}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword(setShowOldPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={edit ? values?.old_password : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("old_password", event.target.value?.trim());
                    }}
                    helperText={touched.old_password && errors.old_password}
                    error={touched.old_password && Boolean(errors.old_password)}
                  />
                  {touched.old_password && Boolean(errors.old_password) && (
                    <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>
                      {touched.old_password && errors.old_password}
                    </FormHelperText>
                  )}
                  {/* <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    placeholder="Old Password"
                    name="old_password"
                    inputProps={{
                      autoComplete: "new-password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {}}
                            onMouseDown={() => {}}
                            edge="end"
                          >
                            {true ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={edit ? values?.old_password : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("old_password", event.target.value);
                    }}
                    helperText={touched.old_password && errors.old_password}
                    error={touched.old_password && Boolean(errors.old_password)}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OutlinedInput
                    disabled={!edit}
                    className="textfield"
                    label="Password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!edit}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword(setShowNewPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={edit ? values?.password : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("password", event.target.value?.trim());
                    }}
                    helperText={touched.password && errors.password}
                    error={touched.password && Boolean(errors.password)}
                  />
                  {touched.password && Boolean(errors.password) && (
                    <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>
                      {touched.password && errors.password}
                    </FormHelperText>
                  )}
                  {/* <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    placeholder="Password"
                    type="password"
                    name="password"
                    // autoComplete="off"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    value={edit ? values?.password : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue("password", event.target.value);
                    }}
                    helperText={touched.password && errors.password}
                    error={touched.password && Boolean(errors.password)}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OutlinedInput
                    disabled={!edit}
                    className="textfield"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!edit}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword(setShowConfirmPassword)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={edit ? values?.password_confirmation : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue(
                        "password_confirmation",
                        event.target.value?.trim()
                      );
                    }}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    error={
                      touched.password_confirmation &&
                      Boolean(errors.password_confirmation)
                    }
                  />
                  {touched.password_confirmation &&
                    Boolean(errors.password_confirmation) && (
                      <FormHelperText sx={{ color: "rgb(211, 47, 47)" }}>
                        {touched.password_confirmation &&
                          errors.password_confirmation}
                      </FormHelperText>
                    )}
                  {/* <TextField
                    disabled={!edit}
                    className="textfield"
                    variant="outlined"
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    type="password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    value={edit ? values?.password_confirmation : null}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue(
                        "password_confirmation",
                        event.target.value
                      );
                    }}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    error={
                      touched.password_confirmation &&
                      Boolean(errors.password_confirmation)
                    }
                  /> */}
                </Grid>
                <Grid xs={12} md={12} px={1} pb={1}>
                  {failedPopup && (
                    <div className="text-center mt-2">
                      <p className="text-danger m-0">
                        {editedData?.data?.message}
                      </p>
                    </div>
                  )}
                </Grid>
                {edit && (
                  <Grid xs={12} md={12} px={1} pb={1}>
                    <div className="text-center createAcBtn mt-5 ">
                      <Button
                        onClick={() => {
                          setShowOldPassword(false);
                          setShowNewPassword(false);
                          setShowConfirmPassword(false);
                          resetForm();
                          setEdit(false);
                        }}
                        className="popup_Btn other_popup_btn mx-2"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          setfailedPopup(false);
                          setShowConfirmPassword(false);
                          setShowNewPassword(false);
                          setShowOldPassword(false);
                        }}
                        type="submit"
                        className="popup_Btn success_popup_btn mx-2"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {/* {editedData?.success && successPopup && (
        <AlertDialog
          type="Success"
          openPopup={successPopup}
          onClose={true}
          onCloseFun={() => {
            setsuccessPopup(false);
            setShowConfirmPassword(false);
            setShowNewPassword(false);
            setShowOldPassword(false);
          }}
          mesage={editedData?.message}
        />
      )} */}
      {/* {stannupPopup && (
        <DialogBox
          width="md"
          openPopup={stannupPopup}
          onClose={closeStanupPopup}
          mesage={<StannupEmailVerification />}
        />
      )} */}

      <Loader open={open} />
    </>
  );
};
