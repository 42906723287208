import { useState, useEffect, useRef } from "react";

const useTimer = (initialTime) => {
  const [timer, setTimer] = useState(initialTime);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const intervalRef = useRef(null); // Use ref to store the interval ID

  useEffect(() => {
    if (isTimerActive) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalRef.current);
            setIsTimerActive(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else if (!isTimerActive && timer !== 0) {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isTimerActive, timer]);

  const startTimer = (time) => {
    setTimer(time);
    setIsTimerActive(true);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsTimerActive(false);
  };

  const formatTimeForResendOTP = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return {
    timer,
    isTimerActive,
    startTimer,
    stopTimer,
    formatTimeForResendOTP,
  };
};

export default useTimer;
