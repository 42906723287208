import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getObjLength } from "../helpers/utils";
import { Autocomplete, Box, Button, TextField } from "@mui/material";

const CountyListDropDown = ({
  handleCountyData = () => {},
  hideEPFromList = false,
  defaultcounty = "Equity Protect",
}) => {
  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  const [countyData, setCountyData] = useState({});

  const combinedCountyList = [
    {
      county_domain_name: "Equity Protect",
      county_name: "Equity Protect",
      id: "ep",
      hideFromList: hideEPFromList,
    },
    ...(registeredCountyListData?.data || []),
  ];

  // filter county
  const handleCountyChange = (event, newValue) => {
    const selectedCounty = combinedCountyList.find(
      (county) => county.county_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setCountyData(selectedCounty);
    }
  };
  return (
    <div>
      <Box
        className={`accountBoxWrapper p-0 px-4 d-flex justify-content-center`}
      >
        <Autocomplete
          className="w-50 mt-2"
          id="asynchronous-demo"
          disableClearable
          defaultValue={defaultcounty}
          options={combinedCountyList
            .filter((option) => !option.hideFromList)
            .map((option) => option?.county_name)}
          onChange={handleCountyChange}
          renderInput={(params) => <TextField {...params} label="Source" />}
        />
      </Box>
      <Button
        disabled={!getObjLength(countyData)}
        className={`popup_Btn success_popup_btn mt-3 ${
          !getObjLength(countyData) && "cursor-disable"
        }`}
        onClick={() => handleCountyData(countyData)}
      >
        Submit
      </Button>
    </div>
  );
};

export default CountyListDropDown;
