import * as React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import DocumnetsTable from "./DocumnetsTable";

const ServicerReqDocumnets = () => {
  const { reqDocLoader, requestDocumentsDetails, cancelDocumentReqDataLoader } =
    useSelector((state) => state.servicerProfile);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Your Document vault
        </Typography>
      </Box>
      <Box>
        <DocumnetsTable data={requestDocumentsDetails?.data} />
      </Box>
      <Loader open={reqDocLoader || cancelDocumentReqDataLoader} />
    </>
  );
};

export default ServicerReqDocumnets;
