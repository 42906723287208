import React, { useEffect } from "react";

import { Navbar } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Button, Stack, Typography } from "@mui/material";
import LennarPublicPageFooter from "../../layout/LennarPublicPageFooter";
import CallUsNow from "../../common/CallUsNow";
import logo from "../../../assets/lennar/ep-logo.svg";

import "../../../styles/lennarPaymentDone.scss";
import { customeDomainName, decryptPayload } from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { lennarSubscriptionAction } from "../../../redux/slices/lennar/subscriptionSlice";
import { Loader } from "../../common/loader";

const CancellationPolicy = () => {
  const dispatch = useDispatch();

  const pathName = window.location.pathname;
  const fetchCustomDomain = customeDomainName(window.location.host);
  let path = null;
  if (!fetchCustomDomain) {
    path = pathName?.split("/")?.[1];
  } else {
    path = fetchCustomDomain;
  }
  console.log("path==>", path);
  // store data
  const { providerDetails, providerLoader } = useSelector(
    (state) => state?.lennarSubscription
  );

  const goBack = () => {
    // This navigates back one step
    const previousURL = localStorage.getItem("previousURL");
    if (previousURL) {
      window.location.href = decryptPayload(previousURL);
      localStorage.removeItem("previousURL");
    }
  };

  useEffect(() => {
    dispatch(
      lennarSubscriptionAction?.getProviderDetails({
        url: `providers/${path}/detail`,
        data: {},
      })
    );
  }, [dispatch, path]);

  return (
    <>
      <Box className="lennar-wrapper">
        <div className=" paymentDone_bg_img text-center mb-3">
          <Navbar.Brand>
            {providerDetails?.data?.logo && (
              <img
                className="lennar-logo"
                src={providerDetails?.data?.logo}
                alt="lennar-logo"
              />
            )}
            <img src={logo} alt="lennar-logo" className="lennar-logo" />
          </Navbar.Brand>
          <section className="content-spacing">
            <Row className="mt-3 d-flex justify-content-center align-items-center text-white mx-0">
              <Col xs={12} sm={12} md={12} lg={10} xl={10}>
                <h1 className="property_heading fw-400 mb-3 mt-0 mt-sm-4">
                  Cancellation Policy
                </h1>
                <p className="paragraph_texts fw-400 mt-2 mx-auto">
                  We back our product wholeheartedly and pledge to provide the
                  industry's utmost security. Our satisfaction is incomplete
                  unless you are completely content and assured in your decision
                  to choose our biometric property locks.
                </p>
              </Col>
            </Row>
          </section>
        </div>

        <section className="paymentDone_wrapper mb-4 content-specing">
          <Box className="overlap-div-spacing">
            <Container fluid className="bg-white main-container1">
              <Box className="box-container mb-5">
                <Box className="bg-white">
                  <Stack
                    direction={"column"}
                    justifyContent="center"
                    className="content-spacing"
                  >
                    <Typography variant="h5" className="pd-header mt-3 mb-2">
                      What are you obligated to pay
                    </Typography>
                    <Stack
                      spacing={2}
                      className="my-4 "
                      direction={"row"}
                      justifyContent={"start"}
                    >
                      <Typography variant="body1" className="pd-answers ">
                        If you are a California resident, in accordance with
                        Cal. Civ. Code $1789.3, you may report complaints to the
                        Complaint Assistance Unit of the Division of Consumer
                        Services of the California Department of Consumer
                        Affairs by contacting them in writing at 1625 North
                        Market Blvd., Suite N 112 Sacramento, CA 95834, or by
                        telephone at (800) 952.5210 or (916) 445-1254.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"column"}
                    justifyContent="center"
                    className="content-spacing"
                  >
                    <Typography variant="h5" className="pd-header mt-3 mb-2">
                      EquityProtect &{" "}
                      <span className="text-capitalize">{path}</span> Homebuyer
                      Cancellation Policy
                    </Typography>
                    <Stack
                      spacing={2}
                      className="mt-4"
                      direction={"column"}
                      justifyContent={"start"}
                    >
                      <Typography variant="body1" className="pd-answers ">
                        Subscriber understands that obtaining the protection by
                        EquityProtect involves a patented process that includes
                        documents recorded in your chain of title, including a
                        UCC* Statement. This protection is designed for a 5-year
                        period, which is the length of time the documents
                        recorded on your property remain valid. The monthly
                        subscription fee is a convenience for you to pay the
                        5-year protection subscription through equal monthly
                        payments instead of 4 larger annual fee renewals. This
                        specific program is also waiving the security deposit
                        requirement for your intent to continue the protection
                        on your new property for the full 5-year term with the
                        option to renew
                      </Typography>
                      <ol>
                        <li>
                          <Typography variant="body1" className="pd-answers ">
                            Monthly Subscriptions: You may cancel your monthly
                            subscription at any time during the first 60 days
                            following the initial subscription period start
                            date, date of recorded UCC* Statement, to receive a
                            refund of the monthly charges only. The initial
                            sign-up fee is non-refundable in any situation, as
                            further documents will have to be prepared anc
                            recorded with your county recorder's office to
                            terminate the protection Equityrrotect has placed on
                            your property. Please allow up to 5 days for the
                            credit to appear on the credit card originally
                            charged.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1" className="pd-answers ">
                            Should you wish to cancel your monthly subscription
                            after the 60-day trial period:
                          </Typography>
                          <Typography variant="body1" className="pd-answers ">
                            <ol type="A">
                              <li className="alphabate-list">
                                your subscription will continue through the end
                                of the month during which you request the
                                cancellation. but it wal not renew for the next
                                month
                              </li>
                              <li className="alphabate-list">
                                you will be charged at the date of cancellation
                                notice, a onetime $125.00 cancellation fee to
                                prepare and record the documents necessary to
                                release the protection placed by EquityProtect
                                on your property.
                              </li>
                            </ol>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1" className="pd-answers ">
                            Cancellation through non-payment: your monthly
                            payment program is an auto monthly charge on the
                            date your subscription bean. If the charge is
                            returned by the bank. You will be notified and have
                            14 days to update your payment information or
                            request the payment attempt to be made again on the
                            same payment method without penalty.
                          </Typography>
                          <Typography variant="body1" className="pd-answers ">
                            <ol type="A">
                              <li className="alphabate-list">
                                your subscription will continue through the end
                                of the month during which you request the
                                cancellation. but it wal not renew for the next
                                month
                              </li>
                              <li className="alphabate-list">
                                you will be charged at the date of cancellation
                                notice, a onetime $125.00 cancellation fee to
                                prepare and record the documents necessary to
                                release the protection placed by EquityProtect
                                on your property.
                              </li>
                            </ol>
                          </Typography>
                        </li>
                      </ol>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Container>
          </Box>
        </section>

        <section className="text-center my-5 content-spacing">
          <Stack direction={"column"} spacing={1}>
            <div>
              {/* <Button className="continue_payment_btn mt-2">
              Cancel subscription
            </Button> */}
              <Button onClick={goBack} className="continue_payment_btn mt-2">
                Back
              </Button>
            </div>
            <p className="contact-support p-1">
              <CallUsNow
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                renderText={
                  <u className="cursor-pointer">Need help? Click here now</u>
                }
              />
            </p>
          </Stack>
        </section>
      </Box>
      <LennarPublicPageFooter />
      <Loader open={providerLoader} />
    </>
  );
};

export default CancellationPolicy;
