import { useEffect } from "react";
import { useCallback } from "react";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import PropertyListing from "./propertyListing";
import { getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import FindPropertyByAddress from "./findProperty";
import FindPropertyByParcel from "./findPropertyByParcel";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PropertySearchTabs = ({
  onBtnClick,
  handleBack,
  HideSkipBtn,
  reduceErrorCount,
  errorPopup,
  setPropertyListScreen,
}) => {
  const [value, setValue] = useState(1);
  const [showPropertyListing, setShowPropertyListing] = useState(false);
  const [propertyValues, setPropertyValues] = useState({});
  const [questionsErrMsg, setQuestionsErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [countyAlert, setCountyAlert] = useState(false);

  const dispatch = useDispatch();
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  const getPropertyData = useSelector(
    (state) => state.signUpUser.getPropertyData
  );

  const selectedPropertyData = useSelector(
    (state) => state.signUpUser.selectedProperty
  );

  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );

  const { propertyQuestion, propertyLoader } = useSelector(
    (state) => state.signUpUser
  );
  const { countyData } = useSelector((state) => state?.propertyRiskManagement);

  const handleChange = (event, newValue) => {
    dispatch(userSigupAction.addNewProperty({}));
    setQuestionsErrMsg("");
    setPropertyValues("");
    setErrMsg("");
    setValue(newValue);
  };

  const handleClose = () => {
    if (getPropertyData?.data?.length > 1) {
      setShowPropertyListing(true);
      HideSkipBtn(false);
      //   setPropertyForm(false);
    }

    if (!propertyQuestion?.success) {
      setQuestionsErrMsg(propertyQuestion?.data?.message);
    }
    console.log("falssee=>>", propertyQuestion);
    setOpen(false);
  };

  const handleToggle = () => {
    setQuestionsErrMsg("");
    setOpen(!open);
  };

  const validate = () => {
    console.log("222");
    handleClose();
    setErrMsg(getPropertyData?.data?.message);
  };

  const onBackClick = () => {
    // setPropertyForm(true);
    dispatch(userSigupAction.addNewProperty({}));
    setPropertyValues({});
    HideSkipBtn(true);
    setShowPropertyListing(false);
    setQuestionsErrMsg("");
    setErrMsg("");
  };

  const selectProperty = (data) => {
    console.log("selectProperty==>", data);
    // setOpen(true);
    dispatch(userSigupAction.selectedUserPropertyData(data));
  };

  //============== for call security question api ==============
  useEffect(() => {
    console.log(
      Object.keys(selectedPropertyData)?.length,
      "selected property 22 ==>",
      selectedPropertyData
    );
    if (Object.keys(selectedPropertyData)?.length > 0) {
      // onBtnClick();
      // handleToggle();
      let propertyData = selectedPropertyData?.json_response;
      console.log(selectedPropertyData, "security questions==>", propertyData);
      let inputParams = {
        state_code: propertyData.mm_fips_state_code,
        property_id: selectedPropertyData?.property_id,
        muni_code: propertyData.mm_fips_muni_code,
        sa_y_coord: propertyData.sa_y_coord,
        sa_x_coord: propertyData.sa_x_coord,
        user_id: getObjLength(crerateAccData)
          ? crerateAccData.data?.user_id
          : userIdFromSession,
        // crerateAccData?.data?.user_id,
        address: selectedPropertyData.address,
        country: selectedPropertyData.country || "USA",
        city: selectedPropertyData.city,
        state: selectedPropertyData.state,
        zip: selectedPropertyData.zip,
        latitude: selectedPropertyData.latitude,
        longitude: selectedPropertyData.longitude,
        bed_rooms: selectedPropertyData.bed_rooms,
        baths: selectedPropertyData.baths,
        sqft: selectedPropertyData.sqft,
        year_built: selectedPropertyData.year_built,
        json_response: propertyData,
        price: selectedPropertyData.price,
        county: selectedPropertyData.county,
        step: 2,
        step_key: "GENERATE_QUESTIONS",
      };

      setTimeout(() => {
        delete inputParams.step;
        delete inputParams.step_key;
        dispatch(propertiesAction.generateQuestionsInputParams(inputParams));
      }, 3000);
      if (getObjLength(countyData)) {
        if (
          selectedPropertyData?.county?.toLowerCase() ===
          countyData?.data?.county_name?.toLowerCase()
        ) {
          dispatch(
            userSigupAction.generateQuestions({
              url: "properties/generate-questions",
              data: inputParams,
              toekn: true,
            })
          );
          setCountyAlert(false);
          console.log("county data in", countyData);
        } else {
          setCountyAlert(true);
          setOpen(false);
          console.log("county data out", countyData);
        }
      } else {
        dispatch(
          userSigupAction.generateQuestions({
            url: "properties/generate-questions",
            data: inputParams,
            toekn: true,
          })
        );
      }
    }
  }, [selectedPropertyData]);

  useEffect(() => {
    console.log("propertyQuestion 33 ==>", propertyQuestion);
    if (Object.keys(propertyQuestion).length > 0) {
      handleClose();
      console.log("444");
      if (propertyQuestion?.success) {
        dispatch(userSigupAction.showSecurityInfoScreen(true));
        onBtnClick();
        localStorage.setItem("button", "back");
      } else {
        dispatch(userSigupAction.clearPropertyQuestion());
      }
    }
  }, [propertyQuestion]);

  const tabContent = [
    {
      label: "Address",
      value: 1,
      component: (
        <>
          <FindPropertyByAddress
            onBtnClick={onBtnClick}
            handleBack={handleBack}
            HideSkipBtn={HideSkipBtn}
            reduceErrorCount={reduceErrorCount}
            errorPopup={errorPopup}
            setShowPropertyListing={setShowPropertyListing}
            propertyValues={propertyValues}
            setPropertyValues={setPropertyValues}
            validate={validate}
            handleToggle={handleToggle}
            handleClose={handleClose}
            questionsErrMsg={questionsErrMsg}
            open={open}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
            setPropertyListScreen={setPropertyListScreen}
          />
        </>
      ),
      icon: <LocationOnIcon />,
    },
    {
      label: "Parcel/Land",
      value: 2,
      icon: <BrightnessLowIcon />,
      component: (
        <>
          <FindPropertyByParcel
            onBtnClick={onBtnClick}
            handleBack={handleBack}
            HideSkipBtn={HideSkipBtn}
            reduceErrorCount={reduceErrorCount}
            errorPopup={errorPopup}
            propertyValues={propertyValues}
            setShowPropertyListing={setShowPropertyListing}
            setPropertyValues={setPropertyValues}
            validate={validate}
            handleToggle={handleToggle}
            handleClose={handleClose}
            questionsErrMsg={questionsErrMsg}
            open={open}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
          />
        </>
      ),
    },
  ];
  console.log("showPropertyListing=>", showPropertyListing);
  return (
    <>
      {!showPropertyListing ? (
        <Box
          className="accountBoxWrapper findPropertyBoxWrapper"
          // className="d-flex justify-content-center align-items-center"
        >
          <Typography align="center" variant="h3">
            Property Search
          </Typography>
          <Typography align="center" variant="body1">
            Please provide us with the address of the property you would like to
            have monitored and protected.
          </Typography>
          <Box className="d-block d-md-none" margin="0 10px">
            <Select
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              displayEmpty
              className="mobile-select-tabs w-100"
              inputProps={{ "aria-label": "Without label" }}
            >
              {tabContent?.map((tabs, index) => (
                <MenuItem
                  sx={{
                    color: value === index + 1 ? "#0D120B" : "#0395FF",
                    paddingLeft: value === index + 1 ? "30px" : "",
                  }}
                  value={tabs?.value}
                >
                  {tabs?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* <StyledSubTabs
        value={value}
        // scrollButtons
        onChange={handleChange}
        aria-label="basic tabs example"
        className="bg-white d-none d-md-block"
        sx={{ pl: 3, m: 0 }}
      > */}
          <Box className="d-flex justify-content-center align-items-center ">
            <Tabs
              value={value}
              className="bg-white d-none d-md-block"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              {tabContent?.map((tabs, index) => (
                <Tab
                  icon={tabs?.icon}
                  iconPosition="start"
                  value={tabs?.value}
                  label={tabs?.label}
                  {...a11yProps(index + 1)}
                />
              ))}
            </Tabs>
          </Box>
          <Box>
            {tabContent?.map((tabs, index) => (
              <TabPanel value={value} index={index + 1}>
                {tabs.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
      ) : (
        // {/* )} */}
        // {/* {showPropertyListing &&  */}
        <Box className="accountBoxWrapper findPropertyBoxWrapper">
          <Typography align="center" variant="h3">
            Property List
          </Typography>
          <Typography align="center" variant="body1">
            Please select your relevant property from the property list.
          </Typography>
          {getPropertyData?.data?.map((data, key) => (
            <PropertyListing
              data={data}
              selectProperty={selectProperty}
              handleToggle={handleToggle}
            />
          ))}
          <div className="text-center stepBtn">
            <Button
              onClick={onBackClick}
              className="backButton"
              color="primary"
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          </div>
          {questionsErrMsg?.length > 0 && (
            <p className="text-danger text-center">{questionsErrMsg}</p>
          )}
        </Box>
      )}

      {countyAlert && (
        <AlertDialog
          type={"Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setCountyAlert(false);
          }}
          mesage={
            <>
              The address must be within{" "}
              {<b>{countyData?.data?.county_name}</b>} County.
            </>
          }
          footer={false}
        />
      )}
      <Loader open={open || propertyLoader} />
    </>
  );
};

export default PropertySearchTabs;
