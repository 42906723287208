import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import EditIconSVG from "../../common/customSVGs/EditIconSVG";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { PER_PAGE } from "../../helpers/constants";
import DeleteModal from "../../common/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import AlertDialog from "../../common/AlertDialog";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";

const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const RecordingFeesList = ({
  //   getRecordingfeesListingData,
  rowsData,
  columns,
  selectedRow,
  setSelectedRow,
  recordingFeesList,
  handleEditModal,
  payload = {},
  setPayload = () => {},
}) => {
  let _DATA = rowsData;
  const count = Math.ceil(recordingFeesList?.count / PER_PAGE);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  // const [payload, setPayload] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteFeesModalOpen, setIsDeleteFeesModalOpen] = useState(false);
  const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] =
    useState(false);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [status, setStatus] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(4); // updated_at key for sorting

  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedTerms, setSelectedTerms] = useState("");
  const open = Boolean(anchorEl);
  const {
    recordingFeesLoader,
    deleteRecordingFeesData,
    recordingFeesActiveStatus,
  } = useSelector((state) => state.countyRecorders);

  console.log("selected-row", selectedRow);

  const handleMenuClick = (event, data) => {
    console.log("selected row==>", data);
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  const handleDeleteButton = () => {
    setIsDeleteFeesModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteFeesModalOpen(false);
    setSelectedRow(null);
    dispatch(countyRecordersAction.fetchDeleteRecordingFees({}));
  };

  const getRecordingfeesListingData = useCallback(() => {
    let URL = "";
    console.log("payload==>.", payload);
    URL = `admin/recording-charge/list`;
    dispatch(
      countyRecordersAction.recordingFeesListData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          order_by_column: payload?.order_by_column || "updated_at",
          sorting_order: payload?.sorting_order || "desc",
          type: payload?.type,
          term: payload?.term,
          is_active: payload?.status,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          modified_from_date: payload?.modified_from_date,
          modified_to_date: payload?.modified_to_date,
        },
        token: true,
      })
    );
  }, [payload, dispatch]);

  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  const handleActivateInactivateFees = () => {
    if (selectedRow?.id) {
      dispatch(
        countyRecordersAction.activateInactivateRecordingFee({
          url: `admin/recording-charge/${selectedRow.id}/update`,
          data: { is_active: selectedRow?.is_active ? 0 : 1 },
          token: true,
        })
      );
    }
    setIsActivateDeactivateModalOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {
    getRecordingfeesListingData();
  }, [getRecordingfeesListingData]);

  const handleDeleteFees = () => {
    if (selectedRow && selectedRow?.id) {
      dispatch(
        countyRecordersAction.deleteRecordingFees({
          url: `admin/recording-charge/${selectedRow?.id}/delete`,
          data: {},
          token: true,
        })
      );
    }
  };

  const handleFilterRecordingFees = (e) => {
    e.preventDefault();
    if (selectedTerms || status || selectedDateRange) {
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        term: selectedTerms || undefined,
        status: status || undefined,
        from_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          selectedKey === "updated_at" || selectedDateType === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE || 10,
        offset: 0,
      }));
    }
  };

  const resetAllFilterOptions = () => {
    // if (selectedTerms || providerStatus || selectedDateRange) {
    setPage(1);
    setSelectedKey("all");
    setSelectedTerms("");
    setStatus("");
    // setCountyId("");
    setPayload();
    setSelectedDateRange(null);
    setDirectionFlow(true);
    setSelectedDateType("created_at");
    setSelectedColumn(4);
    // }
  };

  return (
    <>
      {/* Filtering Component Section */}
      <Box
        id="filter"
        onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
      >
        <h3 className="filter-text">Filter your results</h3>
        <ArrowDropDownIcon
          className="filter-arrow"
          sx={{ ms: 1, fontSize: "20px" }}
        />
      </Box>
      {/* Filtering Options */}
      <Box
        sx={{
          // overflow: "hidden",
          // overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}

            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(e) => {
                        setSelectedKey(e.target.value);
                        setSelectedTerms("");
                        setStatus("");
                        // setCountyId("");
                        setSelectedDateRange(null);
                        if (e.target.value === "all") {
                          setSelectedDateType("created_at");
                        } else {
                          setSelectedDateType("");
                        }
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.label !== "Logo" &&
                          cell.label !== "Actions" &&
                          cell.label !== "Recording Fees" && (
                            <MenuItem key={cell.id} value={cell.value}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "status" &&
                  selectedKey !== "updated_at" &&
                  selectedKey !== "created_at" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedTerms}
                          onChange={(e) => setSelectedTerms(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value.toString())}
                      >
                        {statusOptions?.map((status, key) => (
                          <MenuItem value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "all" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Updated At", value: "updated_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {(selectedKey === "all" ||
                  selectedKey === "created_at" ||
                  selectedKey === "updated_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterRecordingFees}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={resetAllFilterOptions}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card
              className="d-none d-md-block filter-options-carddiv"
              // id="filter-options-card"
            >
              <Grid container className="filter-options-box">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        name="type"
                        onChange={(e) => {
                          console.log("selected==>", e.target.value);
                          setSelectedKey(e.target.value);
                          setSelectedTerms("");
                          setStatus("");
                          // setCountyId("");
                          setSelectedDateRange(null);
                          if (e.target.value === "all") {
                            setSelectedDateType("created_at");
                          } else {
                            setSelectedDateType("");
                          }
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.label !== "No." &&
                            cell.label !== "Logo" &&
                            cell.label !== "Actions" &&
                            cell.label !== "Recording Fees" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                  </Stack>
                </Grid>

                {selectedKey !== "status" &&
                  selectedKey !== "updated_at" &&
                  selectedKey !== "created_at" && (
                    <Grid item>
                      <FormControl className="filter-search" variant="outlined">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedTerms}
                          onChange={(e) => setSelectedTerms(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value.toString())}
                      >
                        {statusOptions?.map((status, key) => (
                          <MenuItem value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "all" && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Date type"
                        value={selectedDateType}
                        onChange={(e) => {
                          setSelectedDateType(e.target.value);
                        }}
                      >
                        {[
                          { label: "Created At", value: "created_at" },
                          { label: "Updated At", value: "updated_at" },
                        ].map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "all" ||
                  selectedKey === "created_at" ||
                  selectedKey === "updated_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterRecordingFees}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetAllFilterOptions}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                columns={columns}
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.address}
                    >
                      <TableCell align="left">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>

                      <TableCell align="left" className="text-capitalize">
                        {row?.state?.name || "-"}
                      </TableCell>
                      <TableCell align="left">
                        $ {parseFloat(row?.amount).toFixed(2) || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.created_at)
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.updated_at)
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.is_active ? (
                          <Typography sx={{ color: "green" }}>
                            Active
                          </Typography>
                        ) : (
                          <Typography sx={{ color: "red" }}>
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: 180 }} align="left">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={(e) => handleMenuClick(e, row)}
                            size="small"
                            className="settings-button"
                            aria-controls={open ? "settings-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            <SettingsIconSVG />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!rowsData.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
        {recordingFeesList?.count > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>

      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={() => {
          setSelectedRow();
          handleMenuClose();
        }}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {selectedRow?.is_active === 1 && (
          <MenuItem
            key="edit"
            onClick={handleEditModal}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <EditIconSVG />
              <Typography className="setting-menu-text">Edit</Typography>
            </Box>
          </MenuItem>
        )}

        {/* {selectedRow.status === "active" && ( */}
        <MenuItem
          key="delete"
          onClick={() => {
            handleDeleteButton();
          }}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <DeleteOutlineIcon />
            <Typography className="setting-menu-text">Delete</Typography>
          </Box>
        </MenuItem>
        {/* )} */}
        {!selectedRow?.is_active ? (
          <MenuItem
            key="view"
            onClick={() => setIsActivateDeactivateModalOpen(true)}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <CheckCircleOutlineOutlinedIcon />
              <Typography className="setting-menu-text">
                Mark as Active
              </Typography>
            </Box>
          </MenuItem>
        ) : (
          <MenuItem
            key="view"
            onClick={() => setIsActivateDeactivateModalOpen(true)}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <CloseOutlinedIcon />
              <Typography className="setting-menu-text">
                Mark as Inactive
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
      <DeleteModal
        open={isDeleteFeesModalOpen}
        title="Delete Fees"
        item="Recording Fees"
        deleteLoader={recordingFeesLoader}
        handleClose={handleCloseDeleteModal}
        handleDeleteBtn={handleDeleteFees}
        fetchedData={deleteRecordingFeesData}
        isDeleteSuccess={deleteRecordingFeesData?.success}
        successMessage={deleteRecordingFeesData?.message}
        errorMessage={deleteRecordingFeesData?.data?.message}
        callListingAPI={() => {
          getRecordingfeesListingData();
        }}
        footer={false}
      />
      {isActivateDeactivateModalOpen ? (
        <AlertDialog
          type={"Warning"}
          openPopup={isActivateDeactivateModalOpen}
          onClose={true}
          onCloseFun={() => {
            setIsActivateDeactivateModalOpen(false);
          }}
          mesage={`Are you sure you want to ${
            !selectedRow?.is_active ? `activate` : `inactivate`
          } the ${selectedRow?.state.name}  Recording Fee?`}
          buttons={
            <>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="popup_Btn other_popup_btn"
                  onClick={() => {
                    setIsActivateDeactivateModalOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    handleActivateInactivateFees();
                  }}
                >
                  Yes
                </Button>
              </Stack>
            </>
          }
          footer={false}
          loader={recordingFeesLoader}
        />
      ) : null}

      {/* activate deactivate Fees success error modal */}
      {getObjLength(recordingFeesActiveStatus) && (
        <AlertDialog
          type={recordingFeesActiveStatus?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(countyRecordersAction.fetchActivateInactivateFee({}));
            if (recordingFeesActiveStatus?.success) {
              getRecordingfeesListingData();
              setIsActivateDeactivateModalOpen(false);
              setSelectedRow(null);
            }
          }}
          mesage={
            recordingFeesActiveStatus?.success
              ? recordingFeesActiveStatus?.message
              : recordingFeesActiveStatus?.data?.message
          }
          footer={false}
        />
      )}
    </>
  );
};

export default RecordingFeesList;
