import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import ResendInvitationModal from "./ResendInvitationModal";
import { COUNTRY_CODE, PER_PAGE } from "../../helpers/constants";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../../admin/AddLeads";
import { sendInvitationAction } from "../../../redux/slices/professional/send-invitation/sendInvitationSlice";
import {
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 70,
  },
  {
    id: "name",
    disablePadding: true,
    label: "Name",
    minWidth: 120,
  },
  {
    id: "email",
    disablePadding: true,
    label: "Email",
    minWidth: 120,
  },
  {
    id: "phone",
    disablePadding: true,
    label: "Phone",
    minWidth: 160,
  },
  {
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 120,
  },
  {
    id: "promo_code",
    disablePadding: true,
    label: "Promo Code",
    minWidth: 120,
  },
  {
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    minWidth: 120,
  },
  {
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 120,
  },
];

const INVITATION_STATUS = [
  {
    id: "Invited",
    label: "Pending",
  },
  {
    id: "Active",
    label: "Active",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SendInvitationListTable({ handleClose, setAlertMsg }) {
  const dispatch = useDispatch();

  // states
  const [page, setPage] = React.useState(1);
  const [toggleFilterOptions, setToggleFilterOptions] = React.useState(false);
  const [isResendInvitationModalOpen, setIsResendInvitationModalOpen] =
    React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedKey, setSelectedKey] = React.useState("all");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedInvitationStatus, setSelectedInvitationStatus] =
    React.useState("");
  const [payload, setPayload] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  // store data
  const {
    sendInvitationListData,
    sendInvitationListLoader,
    resendInvitationDetails,
    sendInvitationListDataCount,
    sendInvitationDetails,
  } = useSelector((state) => state?.sendInvitations);

  const count = Math.ceil(sendInvitationListDataCount / PER_PAGE);
  const _DATA =
    sendInvitationListData?.data?.length > 0
      ? sendInvitationListData?.data
      : [];

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({
      ...prevState,
      limit: PER_PAGE,
      offset: (p - 1) * PER_PAGE,
    }));
  };

  // handle filter property in professionals
  const handleFilterProperty = () => {
    setPage(1);
    setPayload((prevState) => ({
      ...prevState,
      type: selectedKey || undefined,
      term: selectedValue || undefined,
      status: selectedInvitationStatus || undefined,
      from_date: selectedDateRange
        ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
        : undefined,
      to_date: selectedDateRange
        ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
        : undefined,
      limit: PER_PAGE,
      offset: 0,
    }));
  };

  // handle reset function in invitations
  const resetFilterOption = () => {
    setSelectedKey("all");
    setSelectedValue("");
    setSelectedInvitationStatus("");
    setSelectedDateRange(null);
    if (
      payload?.term ||
      payload?.status ||
      payload?.from_date ||
      payload?.to_date
    ) {
      setPayload({ type: "all", limit: PER_PAGE, offset: 0 });
    }
  };

  const handleCloseResendModal = () => {
    setIsResendInvitationModalOpen(false);
  };

  const handleResendInvitation = () => {
    if (selectedItem && selectedItem.id) {
      dispatch(
        sendInvitationAction.resendInvitationDetails({
          url: `invitations/${selectedItem?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  const onSuccess = () => {
    fetchInvitationLists();
  };

  //   fetch invitations
  const fetchInvitationLists = useCallback(() => {
    dispatch(
      sendInvitationAction.fetchSendInvitationListData({
        url: `professional/invitation-list`,
        data: {
          type: payload?.type,
          term: payload?.term,
          status: payload?.status,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          limit: PER_PAGE,
          offset: payload?.offset || 0,
        },
        token: true,
      })
    );
  }, [
    dispatch,
    payload?.from_date,
    payload?.offset,
    payload?.status,
    payload?.term,
    payload?.to_date,
    payload?.type,
  ]);

  useEffect(() => {
    fetchInvitationLists();
  }, [fetchInvitationLists]);

  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>

      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(event) => {
                        setSelectedKey(event.target.value);
                        setSelectedValue("");
                        setSelectedInvitationStatus("");
                        setSelectedDateRange(null);
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.id !== "no." &&
                          cell.id !== "action" &&
                          cell.id !== "type" &&
                          cell.id !== "zip" && (
                            <MenuItem
                              key={cell.id}
                              value={
                                cell.id === "company_name" ? "company" : cell.id
                              }
                            >
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "status" &&
                  selectedKey !== "phone" &&
                  selectedKey !== "created_at" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Invitation Status"
                        value={selectedInvitationStatus}
                        onChange={(event) =>
                          setSelectedInvitationStatus(event.target.value)
                        }
                      >
                        {INVITATION_STATUS?.map((status) => (
                          <MenuItem value={status?.id}>
                            {status?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {selectedKey === "phone" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={selectedValue}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setSelectedValue(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {selectedKey === "created_at" && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={() => {
                      resetFilterOption();
                    }}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card id="filter-options-card" className="d-none d-md-block">
              <Box className="filter-options-box">
                <div className="search-text">Search By</div>
                <FormControl className="filter-select-one">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="All Types"
                    value={selectedKey}
                    onChange={(event) => {
                      setSelectedKey(event.target.value);
                      setSelectedValue("");
                      setSelectedInvitationStatus("");
                      setSelectedDateRange(null);
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {columns.map(
                      (cell) =>
                        cell.id !== "no." &&
                        cell.id !== "action" &&
                        cell.id !== "type" &&
                        cell.id !== "zip" && (
                          <MenuItem key={cell.id} value={cell.id}>
                            {cell.label}
                          </MenuItem>
                        )
                    )}
                  </TextField>
                </FormControl>
                {selectedKey !== "status" &&
                  selectedKey !== "phone" &&
                  selectedKey !== "created_at" && (
                    <FormControl className="filter-search" variant="outlined">
                      <InputLabel>Enter search term</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        }
                        label="Enter search term"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                      />
                    </FormControl>
                  )}
                {(selectedKey === "all" || selectedKey === "status") && (
                  <FormControl className="filter-select-one">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Invitation Status"
                      value={selectedInvitationStatus}
                      onChange={(event) =>
                        setSelectedInvitationStatus(event.target.value)
                      }
                    >
                      {INVITATION_STATUS?.map((status) => (
                        <MenuItem value={status?.id}>{status?.label}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
                {selectedKey === "phone" && (
                  <Box className={`phone-number-input bg-white filter`}>
                    <PhoneInput
                      className="bg-white"
                      international={false}
                      placeholder="Phone Number"
                      value={selectedValue}
                      countries={["US", "IN"]}
                      defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                      countryOptionsOrder={["US", "IN"]}
                      addInternationalOption={false}
                      onChange={(event) => {
                        setSelectedValue(event);
                      }}
                    />
                  </Box>
                )}
                {selectedKey === "created_at" && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <FormControl>
                  <Button
                    className="filter-button"
                    onClick={handleFilterProperty}
                  >
                    Filter Results
                  </Button>
                </FormControl>
                <Button className="reset-filters" onClick={resetFilterOption}>
                  Reset
                </Button>
              </Box>
            </Card>
          </>
        )}
      </Box>
      {/* Invite Listing Table */}
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 py-2">
        {_DATA?.map((row, index) => {
          return (
            <>
              <Stack
                direction="column"
                spacing={1.2}
                px={2}
                className="position-relative"
              >
                <Tooltip
                  title="Resend"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <IconButton
                    sx={{
                      color: "#0395FF",
                      fontSize: "1.4rem",
                      cursor: "pointer",
                      position: "absolute",
                      top: "0px",
                      right: "10px",
                    }}
                    disabled={row?.status === "Active"}
                    onClick={() => {
                      dispatch(
                        sendInvitationAction.clearSendInvitationDetailsData()
                      );
                      setSelectedItem(row);
                      setIsResendInvitationModalOpen(true);
                    }}
                  >
                    <ScheduleSendIcon
                      sx={{
                        color: row?.status !== "Active" ? "#15be53" : "#69746d",
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Stack direction="column" spacing={0.5}>
                  <Typography
                    className="value fw-500 text-capitalize"
                    sx={{ fontSize: "15px !important" }}
                  >
                    {`${row?.name}`?.toLowerCase() || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Email:</Typography>
                  <Typography className="value">{row?.email || "-"}</Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Phone:</Typography>
                  <Typography className="value">
                    {row?.phone ? getFormatedPhoneNumber(row?.phone) : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Status:</Typography>
                  {row?.status === "Invited" ? (
                    <Typography className="value text-danger">
                      Pending
                    </Typography>
                  ) : (
                    <Typography className="value text-success">
                      Active
                    </Typography>
                  )}
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Promocode:</Typography>
                  <Typography className="value">
                    {row?.promo_code || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Created At:</Typography>
                  <Typography className="value">
                    {row?.created_at
                      ? getLocalFormatedDate(row?.created_at)
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              {index < _DATA?.length - 1 && (
                <Divider
                  className="my-2 mt-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {getObjLength(sendInvitationListData) && !_DATA?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <EnhancedTableHead />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row?.id}>
                      <TableCell align="left">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell
                        className="text-capitalize"
                        align="left"
                      >{`${row?.name}`}</TableCell>
                      <TableCell align="left">{row?.email}</TableCell>
                      <TableCell align="left">
                        {row?.phone ? getFormatedPhoneNumber(row?.phone) : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.status === "Invited" ? (
                          <Typography className="text-danger">
                            Pending
                          </Typography>
                        ) : (
                          <Typography className="text-success">
                            Active
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row?.promo_code || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.created_at)
                          : "-"}
                      </TableCell>
                      <TableCell align="left" width={120}>
                        <Stack direction="row">
                          <Tooltip
                            title="Resend"
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "#0395FF",
                                fontSize: "1.4rem",
                                cursor: "pointer",
                              }}
                              disabled={row?.status === "Active"}
                              onClick={() => {
                                dispatch(
                                  sendInvitationAction.clearSendInvitationDetailsData()
                                );
                                setSelectedItem(row);
                                setIsResendInvitationModalOpen(true);
                              }}
                            >
                              <ScheduleSendIcon
                                sx={{
                                  color:
                                    row?.status !== "Active"
                                      ? "#15be53"
                                      : "#69746d",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!sendInvitationListData?.data?.length > 0 &&
              !sendInvitationListLoader && (
                <Typography className="p-5 text-center" variant="h6">
                  No Data Found.
                </Typography>
              )}
          </TableContainer>
        </Paper>
      </Box>
      {sendInvitationListDataCount > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}

      <ResendInvitationModal
        open={isResendInvitationModalOpen}
        handleClose={handleCloseResendModal}
        resendInvitaionHandler={handleResendInvitation}
        loader={sendInvitationListLoader}
      />

      {getObjLength(resendInvitationDetails) ? (
        <>
          {resendInvitationDetails?.success ? (
            <AlertDialog
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                dispatch(sendInvitationAction.setResendInvitationDetails());
                handleCloseResendModal();
                onSuccess();
              }}
              mesage={resendInvitationDetails?.message}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                dispatch(sendInvitationAction.setResendInvitationDetails());
              }}
              mesage={
                resendInvitationDetails?.data?.message || "Something went wrong"
              }
              footer={false}
            />
          )}
        </>
      ) : null}
      {/* {getObjLength(sendInvitationDetails) ? (
        <>
          {sendInvitationDetails?.success ? (
            <AlertDialog
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                handleClose();
                dispatch(sendInvitationAction.clearSendInvitationDetailsData());
                onSuccess();
              }}
              mesage={sendInvitationDetails?.message}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                dispatch(sendInvitationAction.clearSendInvitationDetailsData());
              }}
              mesage={
                sendInvitationDetails?.data?.message || "Something went wrong"
              }
              footer={false}
            />
          )}
        </>
      ) : null} */}

      <Loader open={sendInvitationListLoader} />
    </>
  );
}
