import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { Transition } from "../../lead-page";

import "../common.scss";
import ScrollLock from "../ScrollLock";

const SubscriptionDetailsDialog = ({
  isSubscriptionDetailsModalOpen = false,
  handleClose = () => {},
  subscriptionDetails = {},
  isFromHistory = false,
}) => {
  console.log("subscriptionDetails", subscriptionDetails);
  return (
    <>
      {/* {isSubscriptionDetailsModalOpen && <ScrollLock />} */}
      <Dialog
        open={isSubscriptionDetailsModalOpen}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={() => {
          handleClose();
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Subscription Details"}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="mobile-table bg-white">
          <Grid container>
            <Grid item xs={6}>
              <Stack direction="column" spacing={1.2} px={1}>
                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Discount:</Typography>
                  <Typography className="value">
                    {subscriptionDetails?.discount || "$0.00"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">
                    {" "}
                    Deposit for recording fees:
                  </Typography>
                  <Typography className="value">
                    {subscriptionDetails?.recording_fees || "$0.00"}
                  </Typography>
                </Stack>

                {isFromHistory ? (
                  <Stack direction="column" spacing={0.5}>
                    <Typography className="key"> Refund Amount:</Typography>
                    <Typography className="value">
                      {subscriptionDetails?.refund || "$0.00"}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="column" spacing={0.5}>
                    <Typography className="key">Estimated Amount:</Typography>
                    <Typography className="value">
                      {subscriptionDetails?.estimated_amount || "$0.00"}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column" spacing={1.2} px={1}>
                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Sales/Local Tax:</Typography>
                  <Typography className="value">
                    {subscriptionDetails?.sales_tax || "$0.00"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Setup Fees:</Typography>
                  <Typography className="value">
                    {subscriptionDetails?.setup_fees || "$0.00"}
                  </Typography>
                </Stack>
               {!isFromHistory && <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Refund Amount:</Typography>
                  <Typography className="value">
                    {subscriptionDetails?.refund || "$0.00"}
                  </Typography>
                </Stack>}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionDetailsDialog;
