import { createSlice } from "@reduxjs/toolkit";

const intialSPSignupState = {
  initialActiveStep: 0,
  completedStep: null,
  spEmail: "",
  spSignupDetails: {},
  spProfessionalsDetails: {},
  step1Mobile: false,
  step1Email: false,
  stateList: [],
  professionalsList: [],
  createAccResponse: {},
  otpVerificationResponse: {},
  emailOTPVerificationResponse: {},
  newPhoneOTP: {},
  newEmailOTP: {},
  faceRegistered: {},
  signatureRegistered: {},
  sendMailDetails: {},
  spSignupLoader: false,
  requestAccessLoader: false,
  requestAccessDetails: {},
  validateUUIDLoader: false,
  validateUUIDDetails: {},
  resumeProcessLoader: false,
  resendPhone_OTP: false,
  resendEmail_OTP: false,
  resumeProcessResponse: {},
};

const signupSP = createSlice({
  name: "ServiceProviderSignupDetails",
  initialState: intialSPSignupState,
  reducers: {
    resetSPState: () => intialSPSignupState,
    spSignupData(state, action) {
      console.log("In slice...", action.payload);
      state.spSignupLoader = true;
      state.spSignupDetails = action.payload;
      // localStorage.setItem(
      //   "spSignupDetails",
      //   JSON.stringify(action.payload?.data)
      // );
      state.spEmail = action.payload?.data?.email;
      state.resendEmail_OTP = false;
      state.resendPhone_OTP = false;
    },
    fetchSuccess(state, action) {
      console.log("Payload sucewss==>", action.payload);
      state.spSignupLoader = false;
      state.createAccResponse = action.payload;
      state.user_id = action.payload.data?.user_id;
      localStorage.setItem(
        "user_Id",
        JSON.stringify(action.payload?.data?.user_id)
      );
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "0");
        localStorage.setItem("emailScreen", "0");
      }
    },
    spVerifyOTP(state, action) {
      state.spSignupLoader = true;
    },
    fetchOtpData(state, action) {
      state.spSignupLoader = false;
      console.log("Payload sucewss otp data fetched==>", action.payload);
      state.otpVerificationResponse = action.payload;
      state.step1Mobile = action.payload.success;
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "0");
        localStorage.setItem("emailScreen", "1");
      } else {
        localStorage.removeItem("currentStep");
        localStorage.setItem("emailScreen", "0");
      }
    },
    resedOTP(state, action) {
      state.spSignupLoader = true;
    },
    fetchNewOtpData(state, action) {
      state.spSignupLoader = false;
      console.log("Payload sucewss new otp data fetched==>", action.payload);
      state.newPhoneOTP = action.payload;
      state.step1Mobile = false;
      state.otpVerificationResponse = {};
      // state.step1 = action.payload.success;
    },
    spVerifyEmailOTP(state, action) {
      state.spSignupLoader = true;
    },
    fetchEmailOtpData(state, action) {
      state.spSignupLoader = false;
      console.log("Payload sucewss otp data fetched==>", action.payload);
      state.emailOTPVerificationResponse = action.payload;
      state.step1Email = action.payload.success;
      if (action.payload?.success) {
        // localStorage.removeItem("emailScreen");
        localStorage.setItem("currentStep", "1");
      } else {
        localStorage.setItem("currentStep", "0");
      }
    },
    resedEmailOTP(state, action) {
      state.spSignupLoader = true;
    },
    fetchNewEmailOtpData(state, action) {
      state.spSignupLoader = false;
      console.log("Payload sucewss new otp data fetched==>", action.payload);
      state.newEmailOTP = action.payload;
      state.step1Email = false;
      state.emailOTPVerificationResponse = {};
      // state.step1 = action.payload.success;
    },
    clearResedOTP(state, action) {
      state.spSignupLoader = false;
      state.newPhoneOTP = {};
      state.newEmailOTP = {};
      state.otpVerificationResponse = {};
      state.emailOTPVerificationResponse = {};
    },
    spProfessionalsData(state, action) {
      console.log("In slice...", action.payload);
      state.spSignupLoader = true;
      state.step1Email = false;
      state.step1Mobile = false;
      state.resendEmail_OTP = false;
      state.resendPhone_OTP = false;
    },
    fetchProfessionalsSuccess(state, action) {
      console.log("Payload sucewss==>", action.payload);
      state.spSignupLoader = false;
      state.spProfessionalsDetails = action.payload;
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "2");
      }
    },
    getRegisterFace(state, action) {
      state.spSignupLoader = true;
      console.log("register-face==>>", action.payload);
    },
    fetchRegisterFace(state, action) {
      state.spSignupLoader = false;
      console.log("fetchRegisterFace slice..", action.payload);
      state.faceRegistered = action.payload;
      if (action.payload?.success) {
        localStorage.setItem("currentStep", "3");
        localStorage.removeItem("skip");
      } else {
        localStorage.setItem("currentStep", "2");
        localStorage.setItem("skip", "1");
      }
    },
    getRegisterSignature(state, action) {
      state.spSignupLoader = true;
      console.log("register-Signature==>>", action.payload);
    },
    fetchRegisterSignature(state, action) {
      state.spSignupLoader = false;
      console.log("fetchRegisterSignature slice..", action.payload);
      state.signatureRegistered = action.payload;
    },
    sendSignUpMail(state, action) {
      state.spSignupLoader = true;
    },
    setSendMailDetails(state, action) {
      state.sendMailDetails = action.payload;
      state.spSignupLoader = false;
    },
    getProfessionals(state, action) {
      state.spSignupLoader = true;
    },
    fetchProfessionalsData(state, action) {
      state.professionalsList = action.payload;
      state.spSignupLoader = false;
    },
    getRequestAccessDetails(state, action) {
      state.requestAccessLoader = true;
    },
    setRequestAccessDetails(state, action) {
      state.requestAccessDetails = action.payload;
      state.requestAccessLoader = false;
    },
    clearRequestAccessDetails(state, action) {
      state.requestAccessDetails = {};
      state.requestAccessLoader = false;
    },
    getValidateRequestDetails(state, action) {
      state.validateUUIDLoader = true;
    },
    setValidateRequestDetails(state, action) {
      state.validateUUIDDetails = action.payload;
      state.validateUUIDLoader = false;
    },
    clearValidateRequestDetails(state, action) {
      state.validateUUIDDetails = {};
      state.validateUUIDLoader = false;
    },
    getSendWelcomeEmailDetails(state, action) {
      state.welcomeEmailDetails = action.payload;
      state.welcomeEmailLoader = true;
    },
    sendWelcomeEmailDetails(state, action) {
      state.welcomeEmailDetails = {};
      state.welcomeEmailLoader = false;
    },
    fetchResumeData(state, action) {
      console.log("Payload sucewss otp data fetched==>", action.payload);
      state.resumeProcessLoader = false;
      state.resumeProcessResponse = action.payload;
      if (action.payload?.success) {
        localStorage.setItem(
          "currentStep",
          JSON.stringify(action.payload?.data?.json_last_step?.step)
        );
        localStorage.setItem(
          "user_Id",
          JSON.stringify(action.payload?.data?.id)
        );
        localStorage.setItem(
          "spSignupDetails",
          JSON.stringify(action.payload?.data?.user_signup_details)
        );

        if (action.payload?.data?.json_last_step?.step === 0) {
          if (action.payload?.data?.json_last_step?.step_key === "SEND_OTP") {
            state.resendPhone_OTP = true;
          }
          if (
            action.payload?.data?.json_last_step?.step_key ===
            "VERIFY_PHONE_OTP"
          ) {
            localStorage.setItem("emailScreen", 1);
            state.resendEmail_OTP = true;
          }
        } else {
          state.resendEmail_OTP = false;
          state.resendPhone_OTP = false;
        }

        // if (getObjLength(action.payload?.data?.token_details)) {
        //   console.log("getObjLength==", action.payload?.data?.token_details);
        //   localStorage.setItem(
        //     "setLoginUserToken",
        //     action.payload?.data?.token_details?.AccessToken
        //   );
        // }
      }
      // document.cookie =
      //   "iosValue =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    clearData(state, action) {
      state.initialActiveStep = 0;
      state.completedStep = null;
      state.spEmail = "";
      state.spSignupDetails = {};
      state.spProfessionalsDetails = {};
      state.step1Mobile = false;
      state.step1Email = false;
      state.stateList = [];
      state.professionalsList = [];
      state.createAccResponse = {};
      state.otpVerificationResponse = {};
      state.emailOTPVerificationResponse = {};
      state.newPhoneOTP = {};
      state.newEmailOTP = {};
    },
  },
});
export const serviceProviderSignupAction = signupSP.actions;
export default signupSP.reducer;
