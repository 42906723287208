import React, { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import CloseIcon from "@mui/icons-material/Close";
import { Popover, Typography } from "@mui/material";

import QuoteEnd from "../../assets/testimonials/quotation_end.svg";
import QuoteStart from "../../assets/testimonials/quotation_start.svg";

import "../../styles/realStories.scss";

const ReviewsBreakDown = ({ content, playSlider, pauseSlider }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTruncated, setIsTruncated] = useState(true);
  const contentRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    pauseSlider();
  };

  const handleClose = () => {
    setAnchorEl(null);
    playSlider();
  };

  useEffect(() => {
    // Check if content exceeds 5 lines
    if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [content]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <img
        src={QuoteStart}
        // className="QuoteStart"
        className="d-inline mb-3"
        alt="Quotation Start"
      />
      <p
        className={`text px-4 ${!isTruncated && "text-spacing"}`}
        ref={contentRef}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: isTruncated ? 5 : "unset",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      {/* {content}
      </p> */}
      {isTruncated && (
        <Button
          className="read_more_testimonial text-uppercase mt-2 mb-3"
          onClick={handleClick}
        >
          Read More
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // style={{ height: "400px" }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div className="popover_modal">
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              border: 0,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
          <img
            src={QuoteStart}
            style={{ width: "50px", height: "auto" }}
            className="QuoteStart"
            alt="Quotation Start"
          />
          {/* <Typography>{content}</Typography> */}
          <Typography
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />

          <img
            src={QuoteEnd}
            style={{ width: "50px", height: "auto" }}
            className="QuoteEnd"
            alt="Quotation End"
          />
        </div>
      </Popover>
      {/* <LazyLoadImage src={QuoteEnd} className="QuoteEnd" alt="Quotation End" /> */}
    </>
  );
};

export default ReviewsBreakDown;
