import { put, call, takeLatest } from "redux-saga/effects";
import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { deleteAPIPayload } from "../../../apis/deleteApi";
import { documentsAction } from "../../slices/userDocuments/documentsSlice";

export function* fetchDocumentsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fethDocumentsDataDoc(response));
}

export function* fetchPropertiesSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fethPropertiesData(response));
}

export function* fetchUploadedDocSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fethUploadedDocument(response));
}

export function* editeDocumentSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fethEditedDocument(response));
}

export function* deleteDocumentSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(deleteAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fethDeletedDocument(response));
}

export function* terminatedDocRequestDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchTerminatedDocRequestData(response));
}

export function* terminatedPropertySaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchTerminatedPropertyData(response));
}

export function* uploadedDocSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchUploadedDocData(response));
}

export function* terminatedPropertiesSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchTerminatedPropertiesData(response));
}

export function* renewedPropertiesSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchRenewedPropertiesData(response));
}

export function* waiverPropertiesSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchWaiverPropertiesData(response));
}

export function* resubscribePropertySaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(documentsAction.fetchResubscribePropertyData(response));
}

export function* propertyRestrictedSaga({ payload }) {
  console.log("in sagas--", payload);
  // const response = yield call(postAPIPayload, payload);
  const response = {};
  console.log("response==>.", response);
  yield put(documentsAction.fetchPropertyRestrictedData(response));
}

export function* watchUserDocumentsSagaAsync() {
  yield takeLatest(documentsAction.getDocumentsData, fetchDocumentsSaga);
  yield takeLatest(
    documentsAction.getCustomerPropertiesData,
    fetchPropertiesSaga
  );
  yield takeLatest(documentsAction.uploadDocument, fetchUploadedDocSaga);
  yield takeLatest(documentsAction.editDocument, editeDocumentSaga);
  yield takeLatest(documentsAction.deleteDocument, deleteDocumentSaga);
  yield takeLatest(
    documentsAction.getTerminatedDocRequestData,
    terminatedDocRequestDataSaga
  );
  yield takeLatest(
    documentsAction.getTerminatedPropertyData,
    terminatedPropertySaga
  );
  yield takeLatest(documentsAction.getUploadedDocData, uploadedDocSaga);
  yield takeLatest(
    documentsAction.getTerminatedPropertiesData,
    terminatedPropertiesSaga
  );
  yield takeLatest(
    documentsAction.getRenewedPropertiesData,
    renewedPropertiesSaga
  );
  yield takeLatest(
    documentsAction.getWaiverPropertiesData,
    waiverPropertiesSaga
  );
  yield takeLatest(
    documentsAction.getResubscribePropertyData,
    resubscribePropertySaga
  );
  yield takeLatest(
    documentsAction.getPropertyRestrictedData,
    propertyRestrictedSaga
  );
}
