import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import CustomTextField from "../../common/CustomTextField";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const validationSchema = Yup.object().shape({
  referral: Yup.string("Please enter your Referral Code.")
    .min(10, "Referral code must be at least 10 characters long")
    .max(12, "Referral code must be at most 12 characters long")
    .required("Referral code is required"),
});

const AddEditReferralCode = ({ rowData, onClose, callListingAPI }) => {
  const [openPopup, SetOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const { propertyUsersLoader, updateReferralCodeDetails } = useSelector(
    (state) => state.propertyUsers
  );

  const onSubmit = (values, errors) => {
    console.log("Form data", values);
    let inputParam = {
      referral_code: values.referral,
    };
    console.log(rowData?.user_id, "input params==>", inputParam);
    dispatch(
      propertyUsersAction.updateReferralCode({
        url: `admin/users/${rowData?.user_id}/update-referral-code`,
        data: inputParam,
        token: true,
      })
    );
  };

  const onPopupClose = (type) => {
    SetOpenPopup(false);
    dispatch(propertyUsersAction.fetchUpdateReferralCodeData({}));
    if (type !== "error") {
      callListingAPI();
      onClose();
    }
  };

  useEffect(() => {
    console.log("updateReferralCodeDetails==>", updateReferralCodeDetails);
    if (getObjLength(updateReferralCodeDetails)) {
      SetOpenPopup(true);
    }
  }, [updateReferralCodeDetails]);

  return (
    <Box className="">
      {!updateReferralCodeDetails?.success &&
      !updateReferralCodeDetails?.error ? (
        <>
          <Button
            onClick={() => onClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>

          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3">
              {rowData?.referred_link?.promo_code ? "Update" : "Add"} Referral
              Code
            </Typography>
            <Box>
              <Box mt={4}>
                <Formik
                  enableReinitialize
                  validateOnChange
                  initialValues={{
                    referral: rowData?.referred_link?.promo_code || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, touched, errors }) => {
                    return (
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          textAlign={"center"}
                          alignItems={"center"}
                        >
                          <Grid item xs={12} md={12}>
                            <CustomTextField
                              label="Referral Code"
                              name="referral"
                              validation={{
                                values,
                                setFieldValue,
                                touched,
                                errors,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <div className="text-center stepBtn mt-3">
                          <Button
                            className="popup_Btn other_popup_btn mx-2"
                            type="submit"
                            variant="contained"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </Button>

                          <Button
                            type="submit"
                            disabled={
                              values?.referral ===
                              rowData?.referred_link?.promo_code
                            }
                            className={`popup_Btn success_popup_btn ${
                              values?.referral ===
                                rowData?.referred_link?.promo_code &&
                              "cursor-disable"
                            }`}
                            variant="contained"
                          >
                            Verify
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Box>
        </>
      ) : null}

      {openPopup && getObjLength(updateReferralCodeDetails) && (
        <>
          {updateReferralCodeDetails?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("success")}
              message={updateReferralCodeDetails?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("error")}
              message={updateReferralCodeDetails?.data?.message}
              footer={false}
            />
          )}
        </>
      )}

      <Loader open={propertyUsersLoader} />
    </Box>
  );
};

export default AddEditReferralCode;
