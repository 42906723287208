import { createSlice } from "@reduxjs/toolkit";

const intialRequestDocumentsState = {
  requestDocumentsData: {},
  requestDocumentsDataCount: 0,
  requestDocumentListLoader: false,
  cancelRequestedDocumentDetails: {},
  cancelRequestedDocumentLoader: false,
  editRequestedDocumentDetails: {},
  editRequestedDocumentLoader: false,
  requestedDocumentDetails: {},
  requestedDocumentLoader: false,
  historyOfRequestedDocumentDetails: {},
  uploadedDocDetails: {},
  docLoader: false,
};

const requestDocuments = createSlice({
  name: "requestDocumentsDetails",
  initialState: intialRequestDocumentsState,
  reducers: {
    resetRequestDocumentListState(state, action) {
      state.requestDocumentsData = {};
      state.requestDocumentListLoader = false;
    },

    getRequestDocumentListData(state, action) {
      state.requestDocumentListLoader = true;
    },
    setRequestDocumentListData(state, action) {
      state.requestDocumentsData = action?.payload;
      state.requestDocumentsDataCount = action?.payload?.count;
      state.requestDocumentListLoader = false;
    },
    cancelRequestedDocument(state, action) {
      state.cancelRequestedDocumentLoader = true;
    },
    setCancelRequestedDocumentDetails(state, action) {
      state.cancelRequestedDocumentDetails = action?.payload;
      state.cancelRequestedDocumentLoader = false;
    },
    requestedDocument(state, action) {
      console.log("action: ", action.payload);
      state.requestedDocumentLoader = true;
    },
    setRequestedDocumentDetails(state, action) {
      state.requestedDocumentDetails = action?.payload;
      state.requestedDocumentLoader = false;
    },
    editRequestedDocument(state, action) {
      state.editRequestedDocumentLoader = true;
    },
    setEditRequestedDocumentDetails(state, action) {
      state.editRequestedDocumentDetails = action?.payload;
      state.editRequestedDocumentLoader = false;
    },
    historyOfRequestedDocument(state, action) {
      state.editRequestedDocumentLoader = true;
    },
    setHistoryOfDocumentDetails(state, action) {
      state.historyOfRequestedDocumentDetails = action?.payload;
      state.editRequestedDocumentLoader = false;
    },
    getUploadedDocData(state, action) {
      state.docLoader = true;
    },
    fetchUploadedDocData(state, action) {
      state.uploadedDocDetails = action.payload;
      state.docLoader = false;
    },
  },
});
export const requestDocumentsAction = requestDocuments.actions;
export default requestDocuments.reducer;
