import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";

import { Box, Button, FormHelperText, Grid, Stack } from "@mui/material";

import { GOOGLE_API_KEY } from "../helpers/constants";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";
import {
  decryptPayload,
  encryptPayload,
  getAddressFromGoogleAutoComplete,
  getShortNameAddressFromGoogleAutoComplete,
} from "../helpers/utils";

const validationSchema = Yup.object().shape({
  address: Yup.string("Please enter your Address.")
    .trim("The Address cannot include leading and trailing spaces")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),
});

export const SearchPropertyForm = () => {
  const dispatch = useDispatch();
  const [propertyData, setPropertyData] = useState({});

  const { riskManagementPayload } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const onSubmit = (values) => {
    let paylaod = { ...values, ...propertyData };
    let user_data = decryptPayload(localStorage.getItem("user_details"));
    let user_details = riskManagementPayload || JSON.parse(user_data);
    const data = user_details;
    const finalPaylaod = {
      ...paylaod,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      county_id: data?.county_id,
      source: data?.source,
      for_county: 1,
    };
    console.log(data, "<=== values ==>", finalPaylaod);
    localStorage.setItem(
      "user_details",
      encryptPayload(JSON.stringify(finalPaylaod))
    );
    dispatch(
      propertyRiskManagementAction.propertyRiskManagementData({
        url: `risk-level`,
        data: finalPaylaod,
      })
    );
  };

  // useEffect(() => {
  //   if (!window.google) {
  //     console.log("123-> in");
  //     const script = document.createElement("script");
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = () => {
  //       // Handle API loaded
  //     };
  //     document.body.appendChild(script);
  //   } else {
  //     console.log("123-> out");
  //     // Handle API already loaded
  //   }
  // }, []);

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        validationSchema={validationSchema}
        initialValues={{
          address: "",
          city: "",
          state: "",
          zip: "",
        }}
        onSubmit={(values, validation) => {
          console.log("vcalues-->>", values);
          onSubmit(values);
        }}
      >
        {({ values, setFieldValue, touched, errors, isSubmitting }) => {
          const handleChange = (address) => {
            console.log("address changed==>", address);
            const condos = address.address_components.filter((i, k) =>
              i.types.includes("subpremise")
            );
            var units = condos.length > 0 ? condos[0]?.long_name : "";
            setFieldValue(
              "address",
              getShortNameAddressFromGoogleAutoComplete(
                address,
                "street_number"
              ) +
                (getShortNameAddressFromGoogleAutoComplete(
                  address,
                  "street_number"
                )
                  ? " "
                  : "") +
                getShortNameAddressFromGoogleAutoComplete(address, "route")
            );
            var county = getShortNameAddressFromGoogleAutoComplete(
              address,
              "administrative_area_level_2"
            );
            var county_name = county?.replace("County", "").trim();
            let data = {
              searched_Add: address?.formatted_address,
              street_number: getAddressFromGoogleAutoComplete(
                address,
                "street_number"
              ),
              site_route: getAddressFromGoogleAutoComplete(address, "route"),
              country: getShortNameAddressFromGoogleAutoComplete(
                address,
                "country"
              ),
              latitude: address.geometry.location.lat(),
              longitude: address.geometry.location.lng(),
              city: getAddressFromGoogleAutoComplete(address, "locality"),
              county: county_name,
              state: getShortNameAddressFromGoogleAutoComplete(
                address,
                "administrative_area_level_1"
              ),
              state_name: getAddressFromGoogleAutoComplete(
                address,
                "administrative_area_level_1"
              ),
              zip: getAddressFromGoogleAutoComplete(address, "postal_code"),
              unit_no: units,
            };
            setPropertyData(data);
          };

          return (
            <>
              <Form>
                <Grid
                  container
                  spacing={1.25}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Grid xs={12} sm={9} md={8} lg={8} xl={8}>
                    <Stack
                      className="bg-white m-0"
                      spacing={2}
                      direction={{ sm: "column", md: "row" }}
                      justifyContent={"space-around"}
                      mt={4}
                      textAlign={"center"}
                    >
                      <>
                        <Autocomplete
                          className={`googleAutoComplete mw-100 ${
                            touched.address && Boolean(errors.address)
                              ? "error_border"
                              : ""
                          }`}
                          apiKey={GOOGLE_API_KEY}
                          onPlaceSelected={(address) => {
                            handleChange(address);
                          }}
                          onBlur={(event) => {
                            console.log("onChange==>", event.target.value);

                            setFieldValue("address", event.target.value);
                          }}
                          onKeyDown={(event) =>
                            event.key === "Enter"
                              ? event.preventDefault()
                              : null
                          }
                          placeholder="Enter your property address"
                          options={{
                            types: ["address"],
                            componentRestrictions: { country: "us" },
                          }}
                        />
                      </>
                      <Box className="report_btn-div text-center">
                        <Button type="submit" className="button1">
                          Get Report
                        </Button>
                      </Box>
                    </Stack>
                    {touched.address && Boolean(errors.address) && (
                      <FormHelperText
                        className="m-0 "
                        sx={{
                          color: "#d32f2f !important",
                          maxWidth: "none !important",
                          fontSize: "12px !important",
                        }}
                      >
                        {touched.address && errors.address}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
