import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";
import { lennarSubscriptionAction } from "../../../redux/slices/lennar/subscriptionSlice";
import { getObjLength } from "../../helpers/utils";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";

const OwnerCardStyle = {
  background: "rgb(21 190 83 / 5%)",
  boxShadow: "0px 17.0825px 39.8592px -5.69417px rgba(0, 0, 0, 0.15);",
  borderRadius: "3px",
  py: 2,
  px: 4,
  // border: '1px solid #15be53',
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "55px",
  color: "#0D120B",
};

const OwnerVerification = ({
  path = null,
  setScreen = () => {},
  user_id = null,
}) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  const { verifiedPropertiesList, signupLoader } = useSelector(
    (state) => state.signUpUser
  );
  // store data
  const { lennarGenerateLinkDetails, lennarLoader } = useSelector(
    (state) => state?.lennarSignUp
  );

  const addedNewProperty = () => {
    if (
      getObjLength(verifiedPropertiesList) &&
      verifiedPropertiesList?.data?.length > 0
    ) {
      localStorage.removeItem("fixParams");
      localStorage.removeItem("searched_Add");
      localStorage.removeItem("param");
      localStorage.removeItem("searched_Add_popup");
      localStorage.removeItem("property_search_data");
      localStorage.removeItem("user_details");
    }
    setScreen("add-property");
    dispatch(lennarSignUpAction.fetchcreatedPropertiesData({}));
    dispatch(lennarSignUpAction.fetchGenerateLinkData({}));
    dispatch(userSigupAction.fetchUserPropertyData({}));
    dispatch(userSigupAction.selectedUserPropertyData({}));
  };
  const goToPaymentScreen = () => {
    dispatch(
      lennarSignUpAction.generateLink({
        url: `providers/${path}/${user_id}/ep/generate-link`,
        data: { domain: path, create_by_ep: true },
        toekn: true,
      })
    );
    setIsLoader(true);
  };

  // set alert on the page relaod
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const message =
  //       "Changes you made may not be saved. Are you sure you want to leave?";

  //     if (window.confirm(message)) {
  //       console.log("refreshed...1");
  //       // User clicked "OK", allow navigation
  //       return null;
  //     } else {
  //       // User clicked "Cancel", prevent navigation
  //       event.preventDefault();
  //       return (event.returnValue = message);
  //     }
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Remove the event listener when the component is unmounted
  //   return () => {
  //     console.log("refreshed stop...");
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    let data = {
      user_id: user_id,
    };
    dispatch(
      userSigupAction.getPropertiesList({
        url: "customer/property-list",
        data: data,
      })
    );
    localStorage.removeItem("resumeCount");
  }, []);

  useEffect(() => {
    console.log("lennarGenerateLinkDetails==>", lennarGenerateLinkDetails);
    if (
      getObjLength(lennarGenerateLinkDetails) &&
      lennarGenerateLinkDetails?.success
    ) {
      window.location.href = lennarGenerateLinkDetails?.data?.link;
      localStorage.clear();
      dispatch(lennarSignUpAction?.resetLennarSignUpState({}));
    }
  }, [dispatch, lennarGenerateLinkDetails]);

  return (
    <Box className="accountBoxWrapper">
      <Typography align="center" variant="h3">
        Owner Verified
      </Typography>
      <Typography align="center" variant="body1">
        Your Ownership of the below property has been verified by EquityProtect.
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent={`${
          verifiedPropertiesList?.data?.length > 1 ? "" : "center"
        }`}
      >
        {verifiedPropertiesList?.success ? (
          verifiedPropertiesList?.data?.map((data, key) => (
            <>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{ position: "relative", mb: 3 }}>
                  <Paper className="verifiedOwnerCard" sx={OwnerCardStyle}>
                    <Box>
                      <Typography variant="h3" sx={{ minHeight: "52px" }}>
                        Property: {data?.address}
                      </Typography>
                      <Typography variant="h3">City: {data?.city}</Typography>
                      <Typography variant="h3">State: {data?.state}</Typography>
                      <Typography variant="h3">
                        Country: {data?.country || "USA"}
                      </Typography>
                      <Typography variant="h3">Zip: {data?.zip}</Typography>
                    </Box>
                  </Paper>
                  {data?.is_verified === 1 ? (
                    <>
                      <div className="verifiedOwnerbadge">
                        <VerifiedUserIcon
                          color="success"
                          sx={{ textAlign: "center" }}
                        />
                      </div>
                    </>
                  ) : null}
                </Box>
              </Grid>
            </>
          ))
        ) : verifiedPropertiesList?.data?.message?.length > 0 ? (
          <p className="text-danger">{verifiedPropertiesList?.data?.message}</p>
        ) : (
          <Loader open={lennarLoader} />
        )}
      </Grid>

      <Box align="center" mt={3}>
        <Button
          className="popup_Btn other_popup_btn"
          variant="contained"
          onClick={addedNewProperty}
        >
          Add Another Property
        </Button>
        <Button
          onClick={goToPaymentScreen}
          type="submit"
          className="popup_Btn success_popup_btn m-2"
          variant="contained"
        >
          Next
        </Button>

        {lennarGenerateLinkDetails?.data?.message?.length > 0 ? (
          <p className="text-danger">
            {lennarGenerateLinkDetails?.data?.message}
          </p>
        ) : null}
      </Box>
      <Loader open={lennarLoader || signupLoader || isLoader} />
    </Box>
  );
};

export default OwnerVerification;
