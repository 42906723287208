import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import CustomTextField from "../../common/CustomTextField";
import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import { getObjLength } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { PER_PAGE } from "../../helpers/constants";

const RecordingFeesModal = ({
  stateData,
  validationSchema,
  handleSubmit,
  setOpenPopup,
  selectedRow,
  setSelectedRow,
  getRecordingfeesListingData,
  payload = {},
}) => {
  const dispatch = useDispatch();
  const { createRecordingFees, recordingFeesList } = useSelector(
    (state) => state.countyRecorders
  );
  const onSuccess = () => {
    // if (
    //   recordingFeesList?.data?.length === 1 &&
    //   recordingFeesList?.count > PER_PAGE
    // ) {
    dispatch(
      countyRecordersAction.recordingFeesListData({
        url: "admin/recording-charge/list",
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          order_by_column: "updated_at",
          sorting_order: "desc",
        },
        token: true,
      })
    );
    // }
    // else {
    //   dispatch(
    //     countyRecordersAction.recordingFeesListData({
    //       url: "admin/recording-charge/list",
    //       data: {},
    //       token: true,
    //     })
    //   );
    // }
  };
  return (
    <>
      {!getObjLength(createRecordingFees) && (
        <Box className="accountBoxWrapper professional-content">
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={{
              state_id: selectedRow?.state_id || "",
              amount: selectedRow?.amount,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, validation) => {
              // handleValidateUUID(values);
              handleSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              setTouched,
              isSubmitting,
            }) => {
              const clearForm = () => {
                setFieldValue("state_id", "");
                setFieldValue("amount", "");
                setTouched({
                  request_action: false,
                  transaction_id: false,
                  address: false,
                });
              };

              const handleCloseBtn = () => {
                clearForm();
              };

              return (
                <>
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl
                          fullWidth
                          className="sign-textField text-start"
                          error={touched.state_id && Boolean(errors.state_id)}
                        >
                          <InputLabel
                            // id="demo-simple-select-placeholder"
                            label="State"
                          >
                            State
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-placeholder"
                            id="demo-simple-select"
                            value={values?.state_id}
                            label="State"
                            name="state_id"
                            variant="outlined"
                            disabled={selectedRow?.id ? true : false}
                            onChange={(event) => {
                              setFieldValue("state_id", event.target.value);
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 300, // Set the desired maximum height in pixels
                                },
                              },
                            }}
                            //   MenuProps={MenuProps}
                          >
                            {stateData?.map((state, i) => (
                              <MenuItem value={state?.id} key={i}>
                                {state?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.state_id && Boolean(errors.state_id) && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {touched.state_id && errors.state_id}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <CustomTextField
                            type="number"
                            label="Enter statewise recording fees here"
                            name="amount"
                            validation={{
                              values,
                              errors,
                              setFieldValue,
                              touched,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("amount", event.target.value);
                              },
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span>$</span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Suspense>
                      </Grid>
                    </Grid>
                    <Box className="text-center request-access-box ">
                      <Button
                        sx={{ marginRight: 0 }}
                        variant="contained"
                        type="submit"
                        className="request-access-btn mt-4"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      )}
      {getObjLength(createRecordingFees) && (
        <>
          {getObjLength(createRecordingFees) && createRecordingFees?.success ? (
            <PlainAlertMessage
              className="bg-white"
              type="Success"
              openPopup={createRecordingFees?.success}
              onClose={true}
              onCloseFun={() => {
                // getRecordingfeesListingData();
                onSuccess();
                dispatch(countyRecordersAction.clearData({}));
                setOpenPopup(false);
              }}
              message={createRecordingFees?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={!createRecordingFees?.success}
              onClose={true}
              onCloseFun={() => {
                // setOpenPopup(false);
                dispatch(countyRecordersAction.clearData({}));
              }}
              message={createRecordingFees?.data?.message}
              footer={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default RecordingFeesModal;
