import React, { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Box, FormHelperText, Stack } from "@mui/material";
import { USA_STATES } from "../../helpers/constants";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import "../../../styles/lennar-create-account.scss";
import CustomTextField from "../../common/CustomTextField";

const validationSchema = Yup.object().shape({
  street_number: Yup.string()
    // .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Street number is required"),
  site_route: Yup.string()
    // .min(2, "Too Short!")
    .required("Street Name is required"),
  city: Yup.string("Please enter your City.")
    .strict(true)
    // .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // unit_no: Yup.string("Please enter your Unit .").required("Unit is required"),
});

export default function ProviderPropertyForm({
  propertyValues,
  onBtnClick,
  stateCodesData,
  otherPropertyValues,
  gotoQuestions,
  handleToggle,
  verifiedPropertiesList = {},
  setScreen = () => {},
}) {
  const [checkTerms, setCheckTerms] = useState(true);
  const [checked1, setChecked1] = useState(true); // set false if t&c needed to uncomment

  const onSubmit = (values, errors) => {
    setCheckTerms(checked1);
    if (checked1) {
      let searchedAdd = otherPropertyValues?.address;
      console.log(otherPropertyValues, "search add", values);
      if (
        values.street_number !== propertyValues?.street_number &&
        values.site_route !== propertyValues?.site_route
      ) {
        searchedAdd = values?.street_number + " " + values?.site_route;
        delete otherPropertyValues?.address;
      }
      // if (formAdd.length > 0) {
      //   delete otherPropertyValues?.address;
      // }

      if (values.unit_no?.length === 0) {
        delete values?.unit_no;
      }

      const data = Object.assign(values, otherPropertyValues);
      gotoQuestions({ ...data, address: searchedAdd });
      handleToggle();
    }
    // onBtnClick();
  };

  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };
  return (
    <Box mt={3} className="">
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          street_number: propertyValues?.street_number || "",
          site_route: propertyValues?.site_route || "",
          city: propertyValues?.city || "",
          state:
            USA_STATES?.find(
              (state) =>
                state?.code === propertyValues?.state ||
                state?.name?.toLowerCase() ===
                  propertyValues?.state?.toLowerCase()
            )?.code || "",
          zip: propertyValues?.zip || "",
          unit_no: propertyValues?.unit_no || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => {
          return (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Street Number"
                    name="street_number"
                    validation={{
                      values,
                      setFieldValue,
                      touched,
                      errors,
                    }}
                    classes="standard-field"
                    otherProps={{
                      variant: "standard",
                    }}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Street Name"
                    name="site_route"
                    validation={{
                      values,
                      setFieldValue,
                      touched,
                      errors,
                    }}
                    classes="standard-field"
                    otherProps={{
                      variant: "standard",
                    }}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="City"
                    name="city"
                    validation={{
                      values,
                      setFieldValue,
                      touched,
                      errors,
                    }}
                    classes="standard-field"
                    otherProps={{
                      variant: "standard",
                    }}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    id="standard-select-currency"
                    select
                    value={values?.state}
                    label="State"
                    name="state"
                    variant="standard"
                    className="standard-field w-100 standard-select-field"
                    onChange={(event) => {
                      setFieldValue("state", event.target.value);
                    }}
                    helperText={touched?.state && errors?.state}
                    error={touched?.state && Boolean(errors?.state)}
                  >
                    <MenuItem disabled value="">
                      <span style={{ color: "#00000075" }}>State</span>
                    </MenuItem>
                    {USA_STATES?.map((state) => (
                      <MenuItem value={state.code}>{state.code}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Zip"
                    name="zip"
                    type="number"
                    validation={{
                      values,
                      setFieldValue,
                      touched,
                      errors,
                    }}
                    classes="standard-field"
                    otherProps={{
                      variant: "standard",
                    }}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <CustomTextField
                    label="Unit"
                    placeholder={"Unit"}
                    name="unit_no"
                    validation={{
                      values,
                      setFieldValue,
                      touched,
                      errors,
                    }}
                    classes="standard-field"
                    otherProps={{
                      variant: "standard",
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Box
                className={!checkTerms && "border border-danger"}
                textAlign={"start"}
                py={1}
                px={2}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"flex-start"}
                  alignItems={"start"}
                >
                  <span className="pt-1">
                    <CustomizedCheckbox onChecked={onChecked} />
                  </span>
                  <span>
                    For security reasons, I understand that I will be asked
                    challenge questions and/or my customer service PIN to verify
                    my identity. If I receive a call and I am NOT asked the
                    proper challenge questions, I will hang up and call Customer
                    Support immediately.
                  </span>
                </Stack>
              </Box>
              {!checkTerms && (
                <p className="text-danger m-3">
                  Please select given terms to proceed further.
                </p>
              )} */}
              <div className="text-center stepBtn mt-5">
                {parseInt(verifiedPropertiesList) >= 1 && (
                  <Button
                    className="popup_Btn other_popup_btn mb-3 mx-2"
                    variant="contained"
                    onClick={() => setScreen("owner-verification")}
                  >
                    Go To Property List
                  </Button>
                )}
                <Button
                  type="submit"
                  className="popup_Btn success_popup_btn mb-3"
                  variant="contained"
                >
                  Next Step
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
