import React from "react";
import { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { MenuProps } from "../../helpers/constants";
import CustomTextField from "../../common/CustomTextField";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { spProfessionalValidationSchema } from "../signup/SPProfessionalForm";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { serviceProviderProfileAction } from "../../../redux/slices/serviceProvider/servicerProfile/servicerProfileSlice";

export const ServicerProfessionalForm = ({
  servicerProfessionalDetails,
  edit,
  setEdit,
}) => {
  const dispatch = useDispatch();

  // store data
  const { stateList: stateData } = useSelector((state) => state?.signUpUser);
  const { professionalsList } = useSelector(
    (state) => state.serviceProviderSignup
  );

  const onSubmit = (values, validation) => {
    console.log("values", values);

    dispatch(
      serviceProviderProfileAction.editServicerProfessionalData({
        url: "service-provider/edit-professional-details",
        data: values,
        token: true,
      })
    );

    validation.resetForm();
  };

  useEffect(() => {
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
    dispatch(
      serviceProviderSignupAction.getProfessionals({
        url: "service-provider/types",
        data: {},
      })
    );
  }, [dispatch]);
  console.log("servicerProfessionalDetails=>", servicerProfessionalDetails);
  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          company_name: servicerProfessionalDetails?.data?.company_name || "",
          ofc_address: servicerProfessionalDetails?.data?.ofc_address || "",
          ofc_suite: servicerProfessionalDetails?.data?.ofc_suite || "",
          ofc_city: servicerProfessionalDetails?.data?.ofc_city || "",
          ofc_state: servicerProfessionalDetails?.data?.ofc_state || "",
          ofc_zip: servicerProfessionalDetails?.data?.ofc_zip || "",
          ofc_phone: servicerProfessionalDetails?.data?.ofc_phone || "",
          ofc_phone1: servicerProfessionalDetails?.data?.ofc_phone || "",
          type: servicerProfessionalDetails?.data?.type?.[0]?.id || "",
        }}
        validationSchema={Yup.object().shape(spProfessionalValidationSchema)}
        onSubmit={(values, validation) => {
          if (!isValidPhoneNumber(values?.ofc_phone1)) {
            return;
          }
          onSubmit(values, validation);
        }}
      >
        {({ values, setFieldValue, touched, errors, resetForm }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    label="Company Name"
                    name="company_name"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      disabled: !edit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    label="Office Address"
                    name="ofc_address"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      disabled: !edit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    label="Office Suite"
                    name="ofc_suite"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      disabled: !edit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    label="Office City"
                    name="ofc_city"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      disabled: !edit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl
                    fullWidth
                    className="sign-textField "
                    error={touched.ofc_state && Boolean(errors.ofc_state)}
                  >
                    <InputLabel label="State">State</InputLabel>
                    <Select
                      disabled={!edit}
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      value={values?.ofc_state}
                      label="State"
                      name="ofc_state"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("ofc_state", event.target.value);
                      }}
                      MenuProps={MenuProps}
                    >
                      {stateData?.map((state) => (
                        <MenuItem value={state.name}>{state.name}</MenuItem>
                      ))}
                    </Select>
                    {touched.ofc_state && Boolean(errors.ofc_state) && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.ofc_state && errors.ofc_state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    label="Office Zip"
                    name="ofc_zip"
                    type="number"
                    validation={{ values, setFieldValue, touched, errors }}
                    otherProps={{
                      disabled: !edit,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomPhoneNumberInput
                    name="ofc_phone1"
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    disabled={!edit}
                    edit={edit}
                    otherProps={{
                      onChange: (event) => {
                        setFieldValue("ofc_phone", event);
                        setFieldValue("ofc_phone1", event);
                        if (!event) {
                          setFieldValue("ofc_phone", "");
                          setFieldValue("ofc_phone1", "");
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl
                    fullWidth
                    className="sign-textField"
                    error={touched.type && Boolean(errors.type)}
                  >
                    <InputLabel
                      shrink
                      // id="demo-simple-select-placeholder"
                      style={{ color: "#00000075" }}
                      label="Professional type"
                    >
                      Professional type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      disabled={!edit}
                      value={values?.type}
                      label="Professional type"
                      name="type"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("type", event.target.value);
                      }}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <span style={{ color: "#00000075" }}>Type</span>
                      </MenuItem>
                      {professionalsList?.data?.map((val) => (
                        <MenuItem value={val.id}>{val.type}</MenuItem>
                      ))}
                    </Select>
                    {touched.type && Boolean(errors.type) && (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.type && errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {edit && (
                  <Grid xs={12} md={12} px={1} pb={1}>
                    <div className="text-center createAcBtn mt-5">
                      <Button
                        onClick={() => {
                          resetForm();
                          setEdit(false);
                        }}
                        className="popup_Btn other_popup_btn mx-2"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="popup_Btn success_popup_btn mx-2"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
