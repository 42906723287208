import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Typography,
} from "@mui/material";

import CustomizedRadios from "./customDesignedComponents/customRadiobutton";

const SendOTPOptionAlertDialog = ({
  openPopup,
  setOpenPopup,
  setSelectedOption,
  callAPI,
}) => {
  const [authWay, setAuthWay] = useState("email");
  const navigate = useNavigate();

  const selectVerification = (e) => {
    console.log("Select creditBalance", e.target.value);
    setAuthWay(e.target.value);
  };

  const goNext = () => {
    console.log("go next...", authWay);
    setOpenPopup(false);
    setSelectedOption(authWay);
  };
  return (
    <>
      <Box className="accountBoxWrapper Form_Wrapper">
        <Typography align="center" variant="h3">
          Authentication to Log in
        </Typography>
        <Typography align="center" variant="body1" className="my-2">
          Please choose the suitable authentication option to send verification
          code for reset password.
        </Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={3}
          className="px-2"
        >
          <FormControl sx={{ width: "100%" }}>
            <RadioGroup
              row
              defaultValue={authWay}
              aria-labelledby="payment-types"
              name="payment-modes"
              onChange={selectVerification}
            >
              <Grid xs={12} md={6} px={1} pb={1}>
                <Paper
                  className="shadow p-3 mb-5 bg-white rounded"
                  sx={{
                    p: 2,
                    my: 1,
                    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                    borderRadius: "4px",
                  }}
                >
                  <FormControlLabel
                    value="email"
                    className="paymentMode w-100"
                    control={<CustomizedRadios />}
                    label={
                      <Typography
                        variant="body1"
                        className="d-flex align-items-center justify-content-between text-dark m-0 fw-600"
                      >
                        Send on Email
                      </Typography>
                    }
                  />
                </Paper>
              </Grid>
              <Grid xs={12} md={6} px={1} pb={1}>
                <Paper
                  className="shadow p-3 mb-5 bg-white rounded"
                  sx={{
                    p: 2,
                    my: 1,
                    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                    borderRadius: "4px",
                  }}
                >
                  <FormControlLabel
                    value="phone"
                    className="paymentMode w-100"
                    control={<CustomizedRadios />}
                    label={
                      <Typography
                        variant="body1"
                        className="d-flex align-items-center justify-content-between text-dark m-0 fw-600"
                      >
                        Send on Mobile
                      </Typography>
                    }
                  />
                </Paper>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>

        <div className="text-center stepBtn">
          <Button
            className="popup_Btn other_popup_btn mt-2 mx-1"
            type="submit"
            variant="contained"
            onClick={() => navigate("/login")}
          >
            Back
          </Button>
          <Button
            className="popup_Btn success_popup_btn mt-2 mx-1"
            type="submit"
            variant="contained"
            onClick={goNext}
          >
            Next
          </Button>
        </div>
      </Box>
    </>
  );
};

export default SendOTPOptionAlertDialog;
