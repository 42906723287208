import React from "react";

import { Cropper } from "react-cropper";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton } from "@mui/material";
import FileUpload from "./FileUpload";

const closeDeleteIconStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  cursor: "pointer",
};

const UploadAndCropImage = ({
  croppedImage,
  setCroppedImage,
  selectedFile,
  setSelectedFile,
  cropperRef,
  signUpFormRef,
  name,
  fieldName,
}) => {
  // when user select the image
  const onChange = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  // get cropped image
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      signUpFormRef?.current?.setFieldValue(
        fieldName,
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      console.log(
        "croppedImage: ",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };
  return (
    <>
      {/* When cropper will crop the image then we will have data in the croppedImage state so code will transfer to else condition */}
      {!croppedImage ? (
        selectedFile ? (
          <>
            {/* When service provider selects the image then this cropper will be displayed */}
            <Cropper
              ref={cropperRef}
              style={{ height: "280px", width: "100%" }}
              zoomTo={0}
              initialAspectRatio={1}
              src={selectedFile}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={false}
            />
            <Box className="d-flex justify-content-start" mt={2}>
              <Button
                onClick={getCropData}
                variant="contained"
                className="success-btn"
                // color="primary"
              >
                Crop {name}{" "}
              </Button>
            </Box>
          </>
        ) : (
          <>
            {/* Initially there is neither cropped image nor selected image so upload image field will show */}
            <span className="servicer-signup-form">
              <FileUpload
                name="upload_doc"
                accept={{
                  "image/png": [".png", ".jpeg", ".jpg"],
                }}
                multiple={false}
                onFileUpload={async (file) => {
                  onChange(file);
                }}
                dragDropText={`Drag and drop ${name} or `}
                greenText={`Upload a ${name}`}
                subText="Only .png, .jpeg, .jpg files are allowed"
              />
            </span>
          </>
        )
      ) : (
        <>
          {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
          <Box
            className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
            sx={{
              backgroundColor: "#F6F9FC",
              border: croppedImage ? "1px solid #E2E8EB" : "",
            }}
          >
            <img
              // height="100%"
              // width="100%"
              src={croppedImage}
              style={{
                minHeight: "150px",
                maxHeight: "230px",
              }}
              alt="cropped"
              id="croppedImage"
              className="rounded"
            />

            <IconButton
              sx={{
                ...closeDeleteIconStyle,
                color: "#FC2125",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                setCroppedImage(null);
                setSelectedFile(null);
                signUpFormRef?.current?.setFieldValue(fieldName, "");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </>
      )}
    </>
  );
};

export default UploadAndCropImage;
