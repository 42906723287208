import { put, call, takeEvery } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { userSettingsAction } from "../../slices/user/userSettingSlice";

export function* fetchMarketingMailSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Add leads  response => ", response);
  yield put(userSettingsAction.fetchMarketingMailData(response));
}

export function* getSecurityPinSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(userSettingsAction.fetchSecurityPinData(response));
}

export function* watchUserSettingsSagaAsync() {
  yield takeEvery(
    userSettingsAction.getMarketingMailData,
    fetchMarketingMailSaga
  );
  yield takeEvery(userSettingsAction.getSecurityPinData, getSecurityPinSaga);
}
