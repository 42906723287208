import React from "react";

import { Col, Row } from "react-bootstrap";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { getDateSeperated, getUrlLinkandFileIcon } from "../../helpers/utils";

const TitalChainSection = ({
  isSecondaryUser = false,
  propertiesData = [],
  activeStep = 0,
  handleClickOpen,
  showRequestDocumentBtn = true,
}) => {
  return (
    <>
      <Box
        className="d-flex align-items-center justify-content-between"
        sx={{ mb: 4, mt: 3 }}
      >
        <Typography
          variant="h6"
          className="mt-0 chainTitle"
          // sx={{ mb: 4, mt: 5 }}
        >
          Title Chain
        </Typography>
        {!isSecondaryUser && showRequestDocumentBtn && (
          <Button
            variant="link"
            className="requestDocBtn"
            // className="requestDocBtn"
            onClick={handleClickOpen("paper")}
          >
            Request Documents
          </Button>
        )}
      </Box>
      {propertiesData?.length > 0 && (
        <Stack direction="column" spacing={2} mb={8}>
          {/* mortgage */}
          <Box className="w-100">
            <Stack direction="row" position="relative">
              <Box
                mr={1.5}
                mt={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Typography className="title-chain-icons">
                  <MonetizationOnOutlinedIcon />
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: "100% !important",
                    width: "2px",
                    borderColor: "#E8ECF0",
                  }}
                />
              </Box>
              <Box className="w-100">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}

                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                  >
                    <Stack className="accordian-title" spacing={2}>
                      {(propertiesData[activeStep]?.property_details
                        ?.FinanceHistory ||
                        (!propertiesData[activeStep]?.property_details
                          ?.FinanceHistory &&
                          !propertiesData[activeStep]?.property_details
                            ?.ForeclosureInfo)) && (
                        // && (propertiesData[activeStep]?.property_details
                        //   ?.FinanceHistory).length === 1
                        <>
                          <Chip label="Mortgage" />
                          {propertiesData[activeStep]?.property_details
                            ?.FinanceHistory && (
                            <Typography className="title-chain-dates">
                              {getDateSeperated(
                                propertiesData[activeStep]?.property_details
                                  ?.FinanceHistory?.[0]?.["Loan Date"]
                              )}
                              {propertiesData[activeStep]?.property_details
                                ?.FinanceHistory?.[0]?.[
                                "Loan Recording Date"
                              ] &&
                              propertiesData[activeStep]?.property_details
                                ?.FinanceHistory?.[0]?.[
                                "Loan Recording Date"
                              ] !== "-"
                                ? "-"
                                : null}{" "}
                              {getDateSeperated(
                                propertiesData[activeStep]?.property_details
                                  ?.FinanceHistory?.[0]?.["Loan Recording Date"]
                              )}
                            </Typography>
                          )}
                        </>
                      )}
                      {!propertiesData[activeStep]?.property_details
                        ?.FinanceHistory &&
                        propertiesData[activeStep]?.property_details
                          ?.ForeclosureInfo && (
                          <>
                            <Chip label="Foreclose" />
                            <Typography className="title-chain-dates">
                              {getDateSeperated(
                                propertiesData[activeStep]?.property_details
                                  ?.ForeclosureInfo?.[0]?.["Record Date"]
                              )}
                            </Typography>
                          </>
                        )}
                    </Stack>
                  </AccordionSummary>

                  {propertiesData[activeStep]?.property_details
                    ?.FinanceHistory &&
                    propertiesData[
                      activeStep
                    ]?.property_details?.FinanceHistory?.map((i, key) => (
                      <AccordionDetails key={key}>
                        {/* <Typography className="title-chain-dates">
                  January 12, 2023 - December 24, 2023
                </Typography> */}
                        <Grid container mt={3} spacing={1}>
                          <Grid item xs={12} sm={6} md={6} lg={6} mb={2}>
                            <Row>
                              <Col className="label-text">
                                <Typography>Borrower Name(s):</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {propertiesData[
                                    activeStep
                                  ]?.property_details?.OwnerInfo?.[
                                    "Owner Name"
                                  ]?.trim() ||
                                    propertiesData[
                                      activeStep
                                    ]?.property_details?.OwnerInfo?.[
                                      "Owner Name 2"
                                    ]?.trim() ||
                                    "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Borrower Ownership:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {i?.["Borrower Ownership"] || "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Loan Type:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {i?.["Loan Type"] || "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Mortgage Term:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {i?.["Loan Term"] || "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Loan Amount:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {!isNaN(i?.["Loan Amount"])
                                    ? i?.["Loan Amount"]
                                    : "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Loan Flag's:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {i?.["Loan Flag's"] || "-"}
                                </Typography>
                              </Col>
                            </Row>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Row>
                              <Col className="label-text">
                                <Typography>Lender Name: </Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>{i?.["Lender"] || "-"}</Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Doc #:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {getUrlLinkandFileIcon(
                                    i?.["Doc #"],
                                    i?.["Doc_link"]
                                  )}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Borrower Occupancy:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {i?.["Borrower Occupancy"] || "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Type of Rate:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {i?.["Interest Rate Type"] || "-"}
                                </Typography>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="label-text">
                                <Typography>Lender Address:</Typography>
                              </Col>
                              <Col className="value-text">
                                <Typography>
                                  {propertiesData[
                                    activeStep
                                  ]?.property_details?.OwnerInfo?.[
                                    "Mailing City & State"
                                  ]?.trim()
                                    ? [
                                        propertiesData[
                                          activeStep
                                        ]?.property_details?.OwnerInfo?.[
                                          "Mailing Address"
                                        ]?.trim(),
                                        propertiesData[
                                          activeStep
                                        ]?.property_details?.OwnerInfo?.[
                                          "Mailing City & State"
                                        ]?.trim(),
                                      ]?.join(", ")
                                    : propertiesData[
                                        activeStep
                                      ]?.property_details?.OwnerInfo?.[
                                        "Mailing Address"
                                      ]?.trim() || "-"}
                                </Typography>
                              </Col>
                            </Row>
                          </Grid>
                        </Grid>
                        {/* <hr /> */}
                        {propertiesData[activeStep]?.property_details
                          ?.FinanceHistory?.length -
                          1 >
                        key ? (
                          <hr />
                        ) : null}
                      </AccordionDetails>
                    ))}
                  {!propertiesData[activeStep]?.property_details
                    ?.FinanceHistory &&
                    propertiesData[activeStep]?.property_details
                      ?.ForeclosureInfo && (
                      <AccordionDetails>
                        <p className="text-center text-danger">
                          This property is Foreclose.{" "}
                        </p>
                      </AccordionDetails>
                    )}
                  {!propertiesData[activeStep]?.property_details
                    ?.FinanceHistory &&
                    !propertiesData[activeStep]?.property_details
                      ?.ForeclosureInfo && (
                      <AccordionDetails>
                        <p className="text-center text-danger">
                          No finance history.
                        </p>
                      </AccordionDetails>
                    )}
                </Accordion>
              </Box>
            </Stack>
          </Box>
          {/* purcase */}
          <Box className="w-100">
            <Stack direction="row" position="relative">
              <Box
                mr={1.5}
                mt={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Typography className="title-chain-icons">
                  <AccountBalanceWalletOutlinedIcon />
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: "100% !important",
                    width: "2px",
                    borderColor: "#E8ECF0",
                  }}
                />
              </Box>
              <Box className="w-100">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack className="accordian-title" spacing={2}>
                      <Chip label="Purchase" />
                      {propertiesData[activeStep]?.property_details
                        ?.SalesHistory && (
                        <Typography className="title-chain-dates">
                          {getDateSeperated(
                            propertiesData[activeStep]?.property_details
                              ?.SalesHistory?.[0]?.["Recording Date"]
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6} mb={2}>
                        <Row>
                          <Col className="label-text">
                            <Typography>Transfer Amount:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              ${" "}
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Sale Price"] || "0"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Type of Sale:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Deed Type"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>First TD:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["First TD"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Title Co:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Title Co"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Mortgage Doc #:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Mortgage Doc #"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Buyer Name(s):</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[
                                activeStep
                              ]?.property_details?.SalesHistory?.[0]?.[
                                "Buyer / Seller"
                              ].split("/")[0] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Seller Name(s): </Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[
                                activeStep
                              ]?.property_details?.SalesHistory?.[0]?.[
                                "Buyer / Seller"
                              ].split("/")[1] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Transaction Flag's:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Transaction Flag's"] ||
                                "-"}
                            </Typography>
                          </Col>
                        </Row>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Row>
                          <Col className="label-text">
                            <Typography>Lender Name: </Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.FinanceHistory?.[0]?.["Lender"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Doc #:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {getUrlLinkandFileIcon(
                                propertiesData[activeStep]?.property_details
                                  ?.SalesHistory?.[0]?.["Doc #"],
                                propertiesData[activeStep]?.property_details
                                  ?.SalesHistory?.[0]?.["Doc_link"]
                              )}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Doc Type:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Doc Type"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Rate Type:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.[0]?.["Rate Type"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Lender Address:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[
                                activeStep
                              ]?.property_details?.OwnerInfo?.[
                                "Mailing City & State"
                              ]?.trim()
                                ? [
                                    propertiesData[
                                      activeStep
                                    ]?.property_details?.OwnerInfo?.[
                                      "Mailing Address"
                                    ]?.trim(),
                                    propertiesData[
                                      activeStep
                                    ]?.property_details?.OwnerInfo?.[
                                      "Mailing City & State"
                                    ]?.trim(),
                                  ]?.join(", ")
                                : propertiesData[
                                    activeStep
                                  ]?.property_details?.OwnerInfo?.[
                                    "Mailing Address"
                                  ]?.trim() || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Buyer Vesting:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.OwnerInfo?.["Vesting"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
          </Box>
          {/* title change */}
          <Box className="w-100">
            <Stack direction="row" position="relative">
              <Box
                mr={1.5}
                mt={0} //3
                // sx={{ display: "flex", flexDirection: "column" }}
                // justifyContent="center"
                alignItems="start"
              >
                <Typography className="title-chain-icons">
                  <AutorenewOutlinedIcon />
                </Typography>
                {/* <Divider
          orientation="vertical"
          sx={{
            height: "100% !important",
            width: "2px",
            borderColor: "#E8ECF0",
          }}
        /> */}
              </Box>
              <Box className="w-100 apex-padding">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack className="accordian-title" spacing={2}>
                      <Chip label="Title Change" />
                      {propertiesData[activeStep]?.property_details
                        ?.SalesHistory && (
                        <Typography className="title-chain-dates">
                          {getDateSeperated(
                            (propertiesData[activeStep]?.property_details
                              ?.SalesHistory).length > 1
                              ? propertiesData[activeStep]?.property_details
                                  ?.SalesHistory?.[1]?.["Recording Date"]
                              : propertiesData[activeStep]?.property_details
                                  ?.SalesHistory?.[0]?.["Recording Date"]
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6} mb={2}>
                        <Row>
                          <Col className="label-text">
                            <Typography>Transfer Amount:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              ${" "}
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["Sale Price"] || "0"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["Sale Price"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Type of Sale:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["Deed Type"] || "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["Deed Type"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>First TD:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["First TD"] || "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["First TD"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Title Co:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["Title Co"] || "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["Title Co"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Mortgage Doc #:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["Mortgage Doc #"] ||
                                  "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["Mortgage Doc #"] ||
                                  "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Buyer Name(s):</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[
                                    activeStep
                                  ]?.property_details?.SalesHistory?.[1]?.[
                                    "Buyer / Seller"
                                  ].split("/")[0] || "-"
                                : propertiesData[
                                    activeStep
                                  ]?.property_details?.SalesHistory?.[0]?.[
                                    "Buyer / Seller"
                                  ].split("/")[0] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Seller Name(s): </Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[
                                    activeStep
                                  ]?.property_details?.SalesHistory?.[1]?.[
                                    "Buyer / Seller"
                                  ].split("/")[1] || "-"
                                : propertiesData[
                                    activeStep
                                  ]?.property_details?.SalesHistory?.[0]?.[
                                    "Buyer / Seller"
                                  ].split("/")[1] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Transaction Flag's:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.[
                                    "Transaction Flag's"
                                  ] || "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.[
                                    "Transaction Flag's"
                                  ] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Row>
                          <Col className="label-text">
                            <Typography>Lender Name: </Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.FinanceHistory?.[0]?.["Lender"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Doc #:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? getUrlLinkandFileIcon(
                                    propertiesData[activeStep]?.property_details
                                      ?.SalesHistory?.[1]?.["Doc #"],
                                    propertiesData[activeStep]?.property_details
                                      ?.SalesHistory?.[1]?.["Doc_link"]
                                  )
                                : getUrlLinkandFileIcon(
                                    propertiesData[activeStep]?.property_details
                                      ?.SalesHistory?.[0]?.["Doc #"],
                                    propertiesData[activeStep]?.property_details
                                      ?.SalesHistory?.[0]?.["Doc_link"]
                                  )}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Doc Type:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["Doc Type"] || "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["Doc Type"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Rate Type:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.SalesHistory?.length > 1
                                ? propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[1]?.["Rate Type"] || "-"
                                : propertiesData[activeStep]?.property_details
                                    ?.SalesHistory?.[0]?.["Rate Type"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Lender Address:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[
                                activeStep
                              ]?.property_details?.OwnerInfo?.[
                                "Mailing City & State"
                              ]?.trim()
                                ? [
                                    propertiesData[
                                      activeStep
                                    ]?.property_details?.OwnerInfo?.[
                                      "Mailing Address"
                                    ]?.trim(),
                                    propertiesData[
                                      activeStep
                                    ]?.property_details?.OwnerInfo?.[
                                      "Mailing City & State"
                                    ]?.trim(),
                                  ]?.join(", ")
                                : propertiesData[
                                    activeStep
                                  ]?.property_details?.OwnerInfo?.[
                                    "Mailing Address"
                                  ]?.trim() || "-"}
                            </Typography>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="label-text">
                            <Typography>Buyer Vesting:</Typography>
                          </Col>
                          <Col className="value-text">
                            <Typography>
                              {propertiesData[activeStep]?.property_details
                                ?.OwnerInfo?.["Vesting"] || "-"}
                            </Typography>
                          </Col>
                        </Row>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default TitalChainSection;
