import { put, call, takeLatest } from "redux-saga/effects";
import { requestDocumentsAction } from "../../../slices/admin/requestDocuments/requestDocumentsSlice";
import { postAPIPayload } from "../../../../apis/postApi";
import { getAPIPayload } from "../../../../apis/getApi";

export function* getRequestDocumentListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(requestDocumentsAction.setRequestDocumentListData(response));
}

export function* cancelRequestedDocumentSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(requestDocumentsAction.setCancelRequestedDocumentDetails(response));
}

export function* requestedDocumentSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(requestDocumentsAction.setRequestedDocumentDetails(response));
}

export function* editRequestedDocumentSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(requestDocumentsAction.setEditRequestedDocumentDetails(response));
}

export function* historyOfRequestedDocumentSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(requestDocumentsAction.setHistoryOfDocumentDetails(response));
}

export function* uploadedDocSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(requestDocumentsAction.fetchUploadedDocData(response));
}

export function* watchRequestDocumentsSagaAsync() {
  yield takeLatest(
    requestDocumentsAction.getRequestDocumentListData,
    getRequestDocumentListDataSaga
  );
  yield takeLatest(
    requestDocumentsAction.cancelRequestedDocument,
    cancelRequestedDocumentSaga
  );
  yield takeLatest(
    requestDocumentsAction.requestedDocument,
    requestedDocumentSaga
  );
  yield takeLatest(
    requestDocumentsAction.editRequestedDocument,
    editRequestedDocumentSaga
  );
  yield takeLatest(
    requestDocumentsAction.historyOfRequestedDocument,
    historyOfRequestedDocumentSaga
  );
  yield takeLatest(requestDocumentsAction.getUploadedDocData, uploadedDocSaga);
}
