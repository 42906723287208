import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import PersonalTokenScreen from "../../common/OTPScreen";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { censorEmail, getObjLength } from "../../helpers/utils";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const validationSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
});

const EditEmail = ({
  uid,
  onClose,
  closePopup,
  emailId,
  json_last_step,
  isEditEmailId = false,
  callListingAPI,
}) => {
  const [emailVerificationByPass, setEmailVerificationByPass] = useState(false);
  const [otpVerificationScreen, setOTPVerificationScreen] = useState(false);
  const [updatedEmail, SetUpdatedEmail] = useState("");
  const [otpErr, SetOTPErr] = useState("");
  const [otpSucess, SetOTPSucess] = useState("");
  const [openPopup, SetOpenPopup] = useState(false);

  //initializes the timer states for hook
  const { stopTimer } = useTimer(120);

  const dispatch = useDispatch();

  const { editDataLodaer, upatedEmailResponse } = useSelector(
    (state) => state.propertyUsers
  );

  const onSubmit = (values, errors) => {
    console.log(emailVerificationByPass, "Form data", values);
    SetUpdatedEmail(values.email);
    let inputParam = {
      value: values.email,
      user_id: uid,
    };
    console.log(uid, "input params==>", inputParam);
    // if (emailVerificationByPass) {
    dispatch(
      propertyUsersAction.getUpdatedEmail({
        url: "admin/user-update/email",
        data: inputParam,
        token: true,
      })
    );
    // }
    // else {
    //   setOTPVerificationScreen(true);
    // }
  };

  const onOTPSubmit = (otp) => {
    console.log(otp, "on submit==>.", otp);
  };

  const resendOTPSubmit = () => {
    console.log("resendOTPSubmit==>>");
    let inputParam = {
      value: updatedEmail,
      user_id: uid,
    };
  };

  const removeResendMsg = () => {
    SetOTPSucess("");
  };

  const onPopupClose = (type) => {
    SetOpenPopup(false);
    dispatch(propertyUsersAction.clearEditData());
    if (type !== "error") {
      callListingAPI();
      onClose();
      stopTimer();
    }
  };

  useEffect(() => {
    console.log("upatedEmailResponse==>", upatedEmailResponse);
    if (getObjLength(upatedEmailResponse)) {
      SetOpenPopup(true);
    }
  }, [upatedEmailResponse]);

  console.log("openPopup==>", openPopup);

  return (
    <Box className="">
      {!otpVerificationScreen &&
      !upatedEmailResponse?.success &&
      !upatedEmailResponse?.error ? (
        <>
          <Button
            onClick={() => onClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>

          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3">
              {(json_last_step?.step_key === "SEND_OTP" ||
                json_last_step?.step_key === "VERIFY_PHONE_OTP") &&
              !isEditEmailId
                ? "Verify Email Id"
                : "Edit and Verify Email Id"}
            </Typography>
            <Box>
              <Box mt={4}>
                <Formik
                  enableReinitialize
                  validateOnChange
                  initialValues={{ email: emailId || "" }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, touched, errors }) => {
                    return (
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          textAlign={"center"}
                          alignItems={"center"}
                        >
                          <Grid item xs={12} md={12}>
                            <TextField
                              // className="textField"
                              label="Email address"
                              name="email"
                              variant="outlined"
                              fullWidth
                              disabled={
                                (json_last_step?.step_key === "SEND_OTP" ||
                                  json_last_step?.step_key ===
                                    "VERIFY_PHONE_OTP") &&
                                !isEditEmailId
                              }
                              // sx={{ width: "320px" }}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#0D120B",
                                },
                                shrink: true,
                              }}
                              placeholder={"Email address"}
                              value={values?.email}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                              }}
                              helperText={touched.email && errors.email}
                              error={touched.email && Boolean(errors.email)}
                            />
                          </Grid>
                          {/* <Grid xs={12} md={12} px={2} mt={1}>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <span className="pt-1">
                                <CustomizedCheckbox
                                  onChecked={(e) => {
                                    setEmailVerificationByPass(
                                      e.target.checked
                                    );
                                  }}
                                />
                              </span>
                              <span>
                                Do you want to bypass Email verification ?
                              </span>
                            </Stack>
                          </Grid> */}

                          {/* {fromErr?.length > 0 && (
                          <p className="text-danger text-center">{fromErr}</p>
                        )} */}
                        </Grid>
                        <div className="text-center stepBtn mt-5">
                          <Button
                            className="popup_Btn other_popup_btn mx-2"
                            type="submit"
                            variant="contained"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </Button>

                          <Button
                            type="submit"
                            className={`popup_Btn success_popup_btn `}
                            variant="contained"
                          >
                            Verify
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        !upatedEmailResponse?.success &&
        !upatedEmailResponse?.error && (
          <>
            <Box>
              <PersonalTokenScreen
                subTitle={`Please enter the 6 digit security code we have sent to User's Email Id
         ${emailId ? censorEmail(emailId) : "abc***@gmail.com"}`}
                //   phoneNumber={phoneNumber}
                onsubmit={onOTPSubmit}
                resendOTPSubmit={resendOTPSubmit}
                otpSucess={otpSucess}
                errOTP={otpErr}
                removeResendMsg={removeResendMsg}
              />
            </Box>
          </>
        )
      )}
      {openPopup && getObjLength(upatedEmailResponse) && (
        <>
          {upatedEmailResponse?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("success")}
              message={upatedEmailResponse?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={openPopup}
              onClose={true}
              onCloseFun={() => onPopupClose("error")}
              message={upatedEmailResponse?.data?.message}
              footer={false}
            />
          )}
        </>
      )}

      <Loader open={editDataLodaer} />
    </Box>
  );
};

export default EditEmail;
