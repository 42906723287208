import { createSlice } from "@reduxjs/toolkit";
import {
  decryptPayload,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const intialUserProfessionalState = {
  userProfessionalDetails: {},
  professionalLoader: false,
};

const userProfessional = createSlice({
  name: "userProfessionalDetails",
  initialState: intialUserProfessionalState,
  reducers: {
    getUserProfessionalData: (state, action) => {
      state.professionalLoader = true;
      console.log("userProfessional slice", action.payload);
    },
    fetchUserProfessionallData: (state, action) => {
      console.log("userProfessionalDetails slice", action.payload);
      state.professionalLoader = false;
      state.userProfessionalDetails = action.payload;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // data: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Professionals", data);
      }
    },
  },
});

export const userProfessionalAction = userProfessional.actions;
export default userProfessional.reducer;
