import React from "react";

import { Stack } from "@mui/system";
import { Box, Typography } from "@mui/material";

import SupportEmailLink from "./SupportEmailLink";
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";

const Callto = ({ phone, children, className }) => {
  return (
    <a href={`tel:${phone}`} className={className}>
      {children}
    </a>
  );
};
const contactsValueStyle = {
  cursor: "pointer",
};

const ContactSupport = ({ hideMsg = false, spacing }) => {
  return (
    <Stack spacing={2}>
      {!hideMsg && (
        <Typography>
          Are you stuck here? Please contact our support team for any help.
        </Typography>
      )}
      <Box className={`d-flex justify-content-center ${spacing}`}>
        <Stack justifyContent="center" spacing={1}>
          <Stack direction="row" spacing={2}>
            <Typography>Email:</Typography>
            <SupportEmailLink
              email={SUPPORT_EMAIL}
              className="green-link"
              sx={contactsValueStyle}
              text={SUPPORT_EMAIL}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography>By Phone:</Typography>
            <Typography>
              <Callto phone={`${SUPPORT_PHONE}`} className="green-link">
                {SUPPORT_PHONE_FORMAT}
              </Callto>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ContactSupport;
