import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Label } from "@mui/icons-material";
import { Typography } from "@mui/material";

export default function PropertySelection({
  propertiesData,
  propertySelected,
  propetySelectErr,
  setPropetySelectErr,
}) {
  const [propertyId, setPropertyId] = useState("");

  const handleChange = (event) => {
    setPropertyId(event.target.value);
    propertySelected(event.target.value);
    setPropetySelectErr("");
    console.log("property id==>", event.target.value);
  };
  console.log("propertiesData==>", propertiesData);
  return (
    <Box
      className="accountBoxWrapper text-start bg-white"
      sx={{ width: "100%" }}
    >
      <Typography variant="body1" className="fw-500 m-0 pb-4">
        {" "}
        Please select the properties
      </Typography>

      <FormControl fullWidth>
        <InputLabel
          placeholder="Select properties"
          // id="demo-simple-select-label"
        >
          {propertiesData?.length > 0 ? " Properties" : "No properties found"}
        </InputLabel>
        <Select
          //   labelId="demo-simple-select-label"
          //   id="demo-simple-select"
          disabled={propertiesData?.length > 0 ? false : true}
          value={propertyId}
          placeholder="Select properties"
          label="Properties"
          onChange={handleChange}
        >
          {propertiesData?.length > 0 ? (
            propertiesData.map((properties, key) => (
              <MenuItem
                key={key}
                value={properties?.id}
                sx={{ whiteSpace: "normal" }}
              >
                {properties?.address}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              // key={key}
              value={"-"}
              sx={{ whiteSpace: "normal" }}
            >
              No properties found
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {propetySelectErr.length > 0 && (
        <p className="mb-0 mt-4 text-center text-danger">{propetySelectErr}</p>
      )}
    </Box>
  );
}
