import React from "react";

import WaiverProperties from "./WaiverProperties";
import RenewedProperties from "./RenewedProperties";
import RequestedDocuments from "./RequestedDocuments";
import TerminatedProperties from "./TerminatedProperties";
import CustomTabComponent from "../common/CustomTabComponent";

const DocumentRequest = () => {
  const tabContent = [
    {
      label: "Requested Document List",
      value: 1,
      component: <RequestedDocuments />,
    },
    {
      label: "Waiver - Refiance",
      value: 2,
      component: <WaiverProperties />,
    },
    // {
    //   label: "Restricted",
    //   value: 3,
    //   component: <RestrictedProperties />,
    // },
    {
      label: "Terminated",
      value: 3,
      component: <TerminatedProperties />,
    },
    {
      label: "Renewed",
      value: 4,
      component: <RenewedProperties />,
    },
  ];

  return (
    <div>
      <CustomTabComponent tabContent={tabContent} />
    </div>
  );
};

export default DocumentRequest;
