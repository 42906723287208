import React from "react";
import CreateProvidersForm from "./CreateProvidersForm";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const EditProvidersDetails = ({
  listingAPI = () => {},
  selectedData = {},
  personalDetails,
  plansDetails,
  closeDialogBox = () => {},
}) => {
  const [plansData, setPlanData] = useState({});

  useEffect(() => {
    if (plansDetails) {
      let recording_fees = selectedData?.plans?.[0]?.additional_charges
        ?.recording_fees
        ? selectedData?.plans?.[0]?.additional_charges?.recording_fees
        : null;

      let monthly_plans = {
        name: "monthly",
        is_active: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly"
        )
          ? true
          : false,
        amount: selectedData?.plans?.find((plan, i) => plan?.name === "Monthly")
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Monthly" && plan?.is_default
            )?.amount
          : null,
        setup_fees: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly"
        )
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Monthly" && plan?.is_default
            )?.additional_charges?.["setup fees"]
          : null,
        trial_period: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly"
        )
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Monthly" && plan?.is_default
            )?.trial_period?.day
          : null,
        monthly_renewal_plans: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly"
        )
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Monthly" && !plan?.is_default
            )?.amount
          : null,
      };

      let yearly_plans = {
        name: "yearly",
        is_active: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Yearly"
        )
          ? true
          : false,
        amount: selectedData?.plans?.find((plan, i) => plan?.name === "Yearly")
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Yearly" && plan?.is_default
            )?.amount
          : null,
        trial_period: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Yearly"
        )
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Yearly" && plan?.is_default
            )?.trial_period?.day
          : null,
        yearly_renewal_plans: selectedData?.plans?.find(
          (plan, i) => plan?.name === "Yearly"
        )
          ? selectedData?.plans?.find(
              (plan, i) => plan?.name === "Yearly" && !plan?.is_default
            )?.amount
          : null,
      };

      const data = { recording_fees, monthly_plans, yearly_plans };
      setPlanData(data);
      console.log("Subscription plans==>", data);
    }
  }, [plansDetails, selectedData?.plans]);

  return (
    <Box
      className={`accountBoxWrapper p-0 px-4 ${
        personalDetails && `pt-3`
      } zoom-container no-box-shadow`}
    >
      <CreateProvidersForm
        listingAPI={listingAPI}
        personalDetails={personalDetails}
        plansDetails={plansDetails}
        selectedData={selectedData}
        plansData={plansData}
        closeDialogBox={closeDialogBox}
        isEdit={true}
      />
    </Box>
  );
};

export default EditProvidersDetails;
