import { call, put, takeLatest } from "redux-saga/effects";

import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { lennarSubscriptionAction } from "../../slices/lennar/subscriptionSlice";

export function* addCardSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(lennarSubscriptionAction.setCardDetails(response));
}

export function* userCardDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(lennarSubscriptionAction.fetchUserCardDetails(response));
}

export function* getProviderDetailsSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(lennarSubscriptionAction.fetchProviderDetails(response));
}

export function* getPropertyDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(lennarSubscriptionAction.fetchPropertyDetails(response));
}

export function* getTotalAmountDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(lennarSubscriptionAction.fetchTotalAmountDetails(response));
}

export function* watchLennarSubscriptionSagaAsync() {
  yield takeLatest(lennarSubscriptionAction.addCard, addCardSaga);
  yield takeLatest(lennarSubscriptionAction.userCardList, userCardDetailsSaga);
  yield takeLatest(
    lennarSubscriptionAction.getProviderDetails,
    getProviderDetailsSaga
  );
  yield takeLatest(
    lennarSubscriptionAction.getPropertyDetails,
    getPropertyDetailsSaga
  );
  yield takeLatest(
    lennarSubscriptionAction.getTotalAmountDetails,
    getTotalAmountDetailsSaga
  );
}
