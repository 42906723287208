import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Dialog,
  MobileStepper,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import { PaperStyle } from "../../userProfile/properties";
import AddPropertyPopup from "../../userProfile/add-property";
import OrderDocuments from "../../userProfile/OrderDocuments";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import AddSubscriptionModal from "../../common/AddSubscriptionModal";
import PaymentFailed from "../../userProfile/Dashboard/PaymentFailed";
import MapSection from "../../common/userPropertiesSections/MapSection";
import CancelSubscriptionModal from "../../common/CancelSubscriptionModal";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import UserPropertyViewDetailsModal from "../../common/UserPropertyViewDetailsModal";
import TitalChainSection from "../../common/userPropertiesSections/TitalChainSection";

import "../../../styles/properties.scss";

const PER_PAGE = 3;

const ProfessionalProperties = () => {
  const dispatch = useDispatch();
  console.log("location.pathname", window.location.pathname);

  // states
  const [maxSteps, setMaxSteps] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [showDownloadPage, setShowDownloadPage] = useState(false);
  const [isViewPropertyDetailModalOpen, setIsViewPropertyDetailModalOpen] =
    useState(false);
  const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] =
    useState(false);
  const [selectedProperty, setSelectedProperty] = useState(false);
  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] =
    useState(false);
  const [isRequestDocumentModalOpen, setIsRequestDocumentModalOpen] =
    useState(false);
  const [docActivStep, setDocActivStep] = useState(0);
  const [resetState, setResetState] = useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);
  const [page, setPage] = useState(1);
  const [isMapDisplay, setIsMapDisplay] = useState(true); // for restrict MAP API calls when add property popup is active

  // store data
  const {
    cardDetails,
    userProfileDetails: userData,
    paymnetDoneDetails,
    isPaymentPending,
    loginAsSpouse: isSecondaryUser,
    parent_id,
  } = useSelector((state) => state?.userProfile);

  const {
    propertiesData,
    propertiesCount,
    propertiesLoader,
    cancelSubscriptionDetails,
    cancelSubscriptionLoader,
  } = useSelector((state) => state.properties);

  // move 1 step further
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(propertiesAction.clearOrderDoc());
    if (
      activeStep + 1 === propertiesData?.length &&
      propertiesData?.length < propertiesCount
    ) {
      setPage((prevState) => prevState + 1);
      getPropertyList(page + 1);
    }
  };
  // console.log(
  //   "activeStep: ",
  //   activeStep,
  //   " propertiesData?.length: ",
  //   propertiesData?.length
  // );

  // move 1 step backward
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(propertiesAction.clearOrderDoc());
  };

  // handle open view property modal
  const handleOpenViewPropertyDetailModal = () => {
    setIsViewPropertyDetailModalOpen(true);
  };

  // handle close view property modal
  const handleCloseViewPropertyDetailModal = () => {
    setIsViewPropertyDetailModalOpen(false);
  };

  // handle open cancel subscription modal
  const handleOpenAddSubscriptionModal = () => {
    setIsAddSubscriptionModalOpen(true);
  };

  // handle close cancel subscription modal
  const handleCloseAddSubscriptionModal = () => {
    setIsAddSubscriptionModalOpen(false);
    setSelectedProperty(null);
  };

  // handle open cancel subscription modal
  const handleOpenCancelSubscriptionModal = () => {
    setCancelSubscriptionModalOpen(true);
  };

  // handle close cancel subscription modal
  const handleCloseCancelSubscriptionModal = () => {
    setCancelSubscriptionModalOpen(false);
  };

  // handle cancel subscription modal
  const handleCancelSubscriptionBtn = (type) => {
    // setCancelSubscriptionModalOpen(false);

    // call cancel subscription API for professional
    let data = { type: type };
    let url = "";
    if (
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
    ) {
      url =
        "service-provider/cancel-subscription/" +
        propertiesData?.[activeStep]?.subscription?.id;
    } else {
      url =
        "professional/cancel-subscription/" +
        propertiesData?.[activeStep]?.subscription?.id;
    }
    dispatch(
      propertiesAction.cancelSubscription({
        url: url,
        data: data,
        token: true,
      })
    );
  };

  // open request document modal
  const handleRequestDocumentModalOpen = (scrollType) => () => {
    setIsRequestDocumentModalOpen(true);
    setResetState(true);
  };

  const handleClose = () => {
    setIsRequestDocumentModalOpen(false);
    setDocActivStep(0);
    setResetState(false);
    setTimeout(() => dispatch(propertiesAction.clearOrderDoc()), 1500);
  };

  // get property list fo professional
  // dispatch action for getting property list
  const getPropertyList = useCallback(
    (page = 1) => {
      // setPropertiesLoader(true);
      let url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "professional"
      }/properties-v1`;
      if (isSecondaryUser) {
        url = `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "professional"
        }/properties-v1/${parent_id}`;
      }

      localStorage.removeItem("planDetails");
      localStorage.removeItem("subscriptionScreen");
      sessionStorage.removeItem("userSignupDetails");
      localStorage.removeItem("userSignupDetails");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("user_Id");
      dispatch(
        propertiesAction.fetchPropertyList({
          url: url,
          data: {
            user_id: userData?.user_id,
            limit: PER_PAGE,
            offset: (page - 1) * PER_PAGE,
          },
          token: true,
        })
      );
    },
    [dispatch, isSecondaryUser, parent_id, userData?.user_id]
  );

  // // to get property list for logged in user
  useEffect(() => {
    dispatch(propertiesAction.resetProperties());

    dispatch(
      userSigupAction.getSubscriptionPlan({
        url: "get-plans",
        data: {},
        type: "post",
      })
    );
    dispatch(
      propertiesAction.getDocumentTypes({
        url: "customer/properties/document-types",
        data: "",
        token: true,
      })
    );

    if (
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
    ) {
      dispatch(
        userProfileAction.userCardDetails({
          url: "service-provider/card/list",
          data: {},
          token: true,
        })
      );
    } else {
      dispatch(
        userProfileAction.userCardDetails({
          url: "professional/card/list",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getPropertyList(1);
  }, [getPropertyList]);

  useEffect(() => {
    setMaxSteps(propertiesCount);
    if (getObjLength(propertiesData)) {
      if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
        dispatch(
          userProfileAction.paymnetDoneDetails({
            url: "payment-status",
            data: {},
            token: true,
          })
        );
      }
    }
  }, [dispatch, propertiesCount, propertiesData]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  return (
    <>
      <Box className="properties-wrapper">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" className="title">
              My properties (
              {propertiesData?.length > 0
                ? `${activeStep + 1}/${propertiesCount}`
                : `0/0`}
              )
            </Typography>
            {/* stepper for changing properties */}
            <MobileStepper
              variant="dots"
              steps={maxSteps ? maxSteps : 0}
              position="static"
              activeStep={activeStep}
              sx={{ backgroundColor: "transparent" }}
              nextButton={
                <Button
                  onClick={handleNext}
                  disabled={
                    !propertiesCount || activeStep + 1 >= propertiesCount
                  }
                >
                  <ArrowForwardIcon />
                </Button>
              }
              backButton={
                <Button
                  onClick={handleBack}
                  disabled={!propertiesCount || activeStep === 0}
                >
                  <ArrowBackIcon />
                </Button>
              }
            />
          </Stack>
          <AddPropertyPopup
            classes={`addBtn ${
              propertiesData?.length > 0
                ? "d-none d-lg-block"
                : "d-none d-sm-block"
            }`}
            getPropertyList={() => getPropertyList(page)}
            setIsMapDisplay={setIsMapDisplay}
          />
        </Stack>
        {!propertiesData?.length > 0 && (
          <>
            <AddPropertyPopup
              classes={"addBtn w-100 d-block d-sm-none mt-2"}
              getPropertyList={() => getPropertyList(page)}
              setIsMapDisplay={setIsMapDisplay}
            />
          </>
        )}
        {propertiesData?.length > 0 && !propertiesLoader ? (
          <Box className="propertyBox_wrapper">
            <>
              <Paper className="mainPaper" sx={PaperStyle}>
                <MapSection
                  propertiesData={propertiesData}
                  activeStep={activeStep}
                  handleOpenViewPropertyDetailModal={
                    handleOpenViewPropertyDetailModal
                  }
                  isSecondaryUser={false}
                  handleOpenCancelSubscriptionModal={
                    handleOpenCancelSubscriptionModal
                  }
                  setSelectedProperty={setSelectedProperty}
                  handleOpenAddSubscriptionModal={
                    handleOpenAddSubscriptionModal
                  }
                  cardDetails={cardDetails}
                  getPropertyList={() =>
                    getPropertyList(parseInt(activeStep / 3) + 1)
                  }
                  callPaginationAPI={() => getPropertyList(page)}
                  isMapDisplay={isMapDisplay}
                  setIsMapDisplay={setIsMapDisplay}
                />
                <Paper className="gridpaper title-chain" elevation={0}>
                  <TitalChainSection
                    isSecondaryUser={false}
                    activeStep={activeStep}
                    handleClickOpen={handleRequestDocumentModalOpen}
                    propertiesData={propertiesData}
                    showRequestDocumentBtn={true}
                  />
                </Paper>
              </Paper>
            </>
          </Box>
        ) : propertiesLoader ? null : (
          <Typography className="p-5 text-center" variant="h6">
            No Properties Found.
          </Typography>
        )}
      </Box>

      {/* view user property detail modal */}
      <UserPropertyViewDetailsModal
        open={isViewPropertyDetailModalOpen}
        // open={false}
        handleClose={handleCloseViewPropertyDetailModal}
        selectedProperty={propertiesData?.[activeStep]}
      />

      {/* add subscription modal */}
      <AddSubscriptionModal
        open={isAddSubscriptionModalOpen}
        selectedProperty={selectedProperty}
        closeModalHandler={handleCloseAddSubscriptionModal}
        addPromoCode={true}
        getPropertyList={() => getPropertyList(parseInt(activeStep / 3) + 1)}
      />

      {/* cancel subscription modal */}
      <CancelSubscriptionModal
        open={cancelSubscriptionModalOpen}
        cancelLoader={cancelSubscriptionLoader}
        isCancelSubscriptionSuccess={cancelSubscriptionDetails?.success}
        successMessage={cancelSubscriptionDetails?.message}
        errorMessage={cancelSubscriptionDetails?.data?.message}
        handleClose={handleCloseCancelSubscriptionModal}
        handleCancelSubscriptionBtn={handleCancelSubscriptionBtn}
        endDate={propertiesData?.[activeStep]?.subscription?.end_at}
        cancelSubscriptionDetails={cancelSubscriptionDetails}
        getPropertyList={() => getPropertyList(parseInt(activeStep / 3) + 1)}
        selectedProperty={propertiesData?.[activeStep]}
      />

      {/* for Order Doc */}
      {/* <Dialog
        fullWidth={true}
        maxWidth="md"
        // sx={DialogStyle}
        open={isRequestDocumentModalOpen}
        onClose={handleClose}
        scroll="paper"
        className={`dialogWrapper step${docActivStep}`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      > */}
      {(isRequestDocumentModalOpen || showDownloadPage) && (
        <OrderDocuments
          showDrawer={isRequestDocumentModalOpen}
          setDocActivStep={setDocActivStep}
          resetStates={resetState}
          propertyId={propertiesData?.[activeStep]?.id}
          propertyData={propertiesData?.[activeStep]}
          closeModal={setIsRequestDocumentModalOpen}
          onClose={handleClose}
          user_id={userData?.data?.user_id}
          showDownloadPage={showDownloadPage}
          setShowDownloadPage={setShowDownloadPage}
        />
      )}
      {/* </Dialog> */}

      {/* open modal if there is no card available for currenyt user */}
      {/* {openAlertModal && !cardDetails?.data?.length > 0 && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={openAlertModal}
            onClose={true}
            onCloseFun={() => {
              setOpenAlertModal(false);
            }}
            buttons={
              <>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  sx={{
                    backgroundColor: "#ffffff !important",
                    color: "black !important",
                    border: "1px #9cb8a6 solid !important",
                  }}
                  onClick={() => {
                    setOpenAlertModal(false);
                  }}
                >
                  ok, close
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => {
                    setOpenAlertModal(false);
                    navigate("/professional-profile", {
                      replace: true,
                      state: { isAddPaymentModal: true },
                    });
                  }}
                >
                  ok, let's go
                </Button>
              </>
            }
            mesage={
              "You don't have any cards added. Please add the card first from the user profile page."
            }
            footer={false}
          />
        </>
      )} */}

      {/* Paymnet failed Popup */}
      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed
            isPaymentDonePopup={isPaymentDonePopup}
            callListAPI={true}
            apiCall={() => getPropertyList(page)}
          />
        )}

      <Loader open={propertiesLoader} />
    </>
  );
};

export default ProfessionalProperties;
