import { useEffect } from "react";
import { useCallback } from "react";
import React, { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { getObjLength } from "../../helpers/utils";
import CustomTextField from "../../common/CustomTextField";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { spProfessionalValidationSchema } from "../../Service-Provider/signup/SPProfessionalForm";
import { serviceProviderProfileAction } from "../../../redux/slices/serviceProvider/servicerProfile/servicerProfileSlice";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SwitchToServiceProvider = ({
  selectedItem,
  handleClose,
  callListingAPI,
  setIsSwitchFormSubmit,
  setSwitchToServiceProvider,
}) => {
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { professionalsList } = useSelector(
    (state) => state.serviceProviderSignup
  );
  const { switchServiceProviderDetails, editProfessionalLoader } = useSelector(
    (state) => state?.servicerProfile
  );
  const stateData = useSelector((state) => state.signUpUser.stateList);

  //   handle switch to  serviceProvider
  const onSubmit = (values, validation) => {
    console.log("values", values);

    dispatch(
      serviceProviderProfileAction.switchServiceProviderData({
        url: `admin/switch-customer-to-provider/${selectedItem?.user_id}`,
        data: values,
        token: true,
      })
    );

    // validation.resetForm();
  };

  const handleOpenResponseModal = () => {
    setIsResponseModalOpen(true);
  };
  const handleCloseResponseModal = () => {
    // clear success or error message response
    dispatch(serviceProviderProfileAction?.setSwitchServiceProviderData({}));
    setIsResponseModalOpen(false);
  };

  const getProfessionalTypes = useCallback(() => {
    dispatch(
      serviceProviderSignupAction.getProfessionals({
        url: "service-provider/types",
        data: {},
      })
    );
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
  }, [dispatch]);

  useEffect(() => {
    getProfessionalTypes();
  }, [getProfessionalTypes]);

  useEffect(() => {
    if (getObjLength(switchServiceProviderDetails)) {
      handleOpenResponseModal();
    }
    if(switchServiceProviderDetails?.success){
      setIsSwitchFormSubmit(true);
    }
  }, [switchServiceProviderDetails]);

  return (<>
    {!switchServiceProviderDetails?.success && <Box className="accountBoxWrapper p-0">
      {" "}
      <b>Add Professional Details</b>
      <Box className="d-flex justify-content-center bg-white">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            company_name: "",
            ofc_address: "",
            ofc_suite: "",
            ofc_city: "",
            ofc_state: "",
            ofc_zip: "",
            ofc_phone: "",
            ofc_phone1: "",
            type: "",
          }}
          validationSchema={Yup.object().shape(spProfessionalValidationSchema)}
          onSubmit={(values, validation) => {
            if (!isValidPhoneNumber(values?.ofc_phone1)) {
              return;
            }
            onSubmit(values, validation);
          }}
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            resetForm,
            initialValues,
          }) => {
            return (
              <Form>
                <DialogContent className="bg-white">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomTextField
                        label="Company Name"
                        name="company_name"
                        validation={{ values, setFieldValue, touched, errors }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomTextField
                        label="Office Address"
                        name="ofc_address"
                        validation={{ values, setFieldValue, touched, errors }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomTextField
                        label="Office Suite"
                        name="ofc_suite"
                        validation={{ values, setFieldValue, touched, errors }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomTextField
                        label="Office City"
                        name="ofc_city"
                        validation={{ values, setFieldValue, touched, errors }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl
                        fullWidth
                        className="sign-textField "
                        error={touched.ofc_state && Boolean(errors.ofc_state)}
                        sx={{ textAlign: "start" }}
                      >
                        <InputLabel label="State">State</InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.ofc_state}
                          label="State"
                          name="ofc_state"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("ofc_state", event.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {stateData?.map((state) => (
                            <MenuItem value={state.name}>{state.name}</MenuItem>
                          ))}
                        </Select>
                        {touched.ofc_state && Boolean(errors.ofc_state) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.ofc_state && errors.ofc_state}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomTextField
                        label="Office Zip"
                        name="ofc_zip"
                        type="number"
                        validation={{ values, setFieldValue, touched, errors }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CustomPhoneNumberInput
                        name="ofc_phone1"
                        values={values}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("ofc_phone", event);
                            setFieldValue("ofc_phone1", event);
                            if (!event) {
                              setFieldValue("ofc_phone", "");
                              setFieldValue("ofc_phone1", "");
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl
                        fullWidth
                        className="sign-textField"
                        sx={{ textAlign: "start" }}
                        error={touched.type && Boolean(errors.type)}
                      >
                        <InputLabel
                          shrink
                          // id="demo-simple-select-placeholder"
                          style={{ color: "#00000075" }}
                          label="Professional type"
                        >
                          Professional type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.type}
                          label="Professional type"
                          name="type"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("type", event.target.value);
                          }}
                          MenuProps={MenuProps}
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            <span style={{ color: "#00000075" }}>Type</span>
                          </MenuItem>
                          {professionalsList?.data?.map((val) => (
                            <MenuItem value={val.type}>{val.type}</MenuItem>
                          ))}
                        </Select>
                        {touched.type && Boolean(errors.type) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.type && errors.type}
                          </FormHelperText>
                        )}
                      </FormControl>
                      
                    </Grid>
                    {getObjLength(switchServiceProviderDetails) && !switchServiceProviderDetails?.success && (
                    <p className="text-danger text-center p-2">{switchServiceProviderDetails?.data?.message}</p>
                  )} 
                    {/* <Grid xs={12} md={12} px={1} pb={1}>
                    <div className="text-center createAcBtn mt-5">
                      <Button
                        onClick={() => {
                          resetForm();
                        }}
                        className="popup_Btn other_popup_btn mx-2"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="popup_Btn success_popup_btn mx-2"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid> */}
                  </Grid>
                </DialogContent>
                <DialogActions className="alert-modal-btn">
                  <Stack direction="row" spacing={2} pb={2}>
                    <Button
                      className="popup_Btn other_popup_btn m-1"
                      // variant="contained"
                      onClick={() => {
                        resetForm();
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn m-1"
                      type="submit"
                      disabled={
                        JSON.stringify(initialValues) === JSON.stringify(values)
                      }
                      variant="contained"
                    >
                      Switch
                    </Button>
                  </Stack>
                </DialogActions>
              </Form>
            );
          }}
          
        </Formik>
      </Box>
    </Box>}
      {/* success or error message */}
    {isResponseModalOpen && switchServiceProviderDetails?.success && (
        <PlainAlertMessage
          type={switchServiceProviderDetails?.success ? "Success" : "Error"}
          openPopup={isResponseModalOpen}
          onClose={true}
          onCloseFun={()=>{
            handleCloseResponseModal()
            setIsSwitchFormSubmit(false)
            if(switchServiceProviderDetails?.success){
              setSwitchToServiceProvider(false);
            }
          }}
          message={
            switchServiceProviderDetails?.success
              ? switchServiceProviderDetails?.message
              : switchServiceProviderDetails?.data?.message ||
                "Something went wrong"
          }
          footer={false}
          buttons={
            switchServiceProviderDetails?.success ? (
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    handleCloseResponseModal();
                    handleClose();
                    callListingAPI();
                    setIsSwitchFormSubmit(false);
                  }}
                  className="next_button"
                  variant="contained"
                >
                  ok, All Good
                </Button>
              </div>
            ) : (
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={()=>{
                    handleCloseResponseModal();
                    setIsSwitchFormSubmit(false);
                  }}
                  className="next_button error_popup_btn"
                  variant="contained"
                >
                  ok, Close
                </Button>
              </div>
            )
          }
        />
      )}
      <Loader open={editProfessionalLoader} />
    </>
  );
};

export default SwitchToServiceProvider;
