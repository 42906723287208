import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Button } from "@mui/material";

import pana from "../../assets/properties/pana.png";
import pana2 from "../../assets/properties/pana2.png";
import vector from "../../assets/properties/Vector.png";
import vector1 from "../../assets/properties/Vector1.png";
import vector2 from "../../assets/properties/Vector2.png";
import vector3 from "../../assets/properties/Vector3.png";
import vector4 from "../../assets/properties/Vector4.png";
import support from "../../assets/properties/24_7_support.webp";
import money_back from "../../assets/properties/money_back.webp";
import certificate from "../../assets/properties/certificate.webp";

import "../../styles/privateGuard.scss";
import { Link } from "react-router-dom";

const PrivateGuardSection = ({ displayLastSection = true }) => {
  return (
    <section
      className="pt-5 mobile-secions-pt-0 last_section"
      style={{
        position: "relative",
        background: "#F6F9FC",
        // marginBottom: "150px",
      }}
    >
      <Container
        fluid
        className={`property-protected-container ${
          displayLastSection ? "pb-150" : ""
        }`}
      >
        <Row
          className={`mt-4 text-start pp_points ${
            displayLastSection ? "mb-5" : "mb-2 pb-2"
          }`}
        >
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            className="content-spacing "
          >
            <img
              className="mb-3"
              src={certificate}
              alt="US PATENTED TECHNOLOGY"
            />
            <p className="protection_title">US PATENTED TECHNOLOGY</p>
            <p className="protection_desc pe-0 pe-lg-5">
              Criminals can literally remove someone from their home's title.
              Then they take out loans against the home and leave its owner in
              debt.
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            className="content-spacing"
          >
            <img
              className="mb-3"
              src={support}
              alt="24/7 US-BASED PREMIUM SUPPORT"
            />
            <p className="protection_title" style={{ paddingTop: "5px" }}>
              24/7 US-BASED PREMIUM SUPPORT
            </p>
            <p className="protection_desc pe-0 pe-lg-5">
              EquityProtect will continuously monitor for a home’s address to be
              listed on popular vacation rental and classified ad sites.
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            className="content-spacing"
          >
            <img
              className="mb-3"
              src={money_back}
              alt="60-DAY MONEY BACK GUARANTEE"
            />
            <p className="protection_title">60-DAY MONEY BACK GUARANTEE</p>
            <p className="protection_desc pe-0 pe-lg-5">
              Instant alerts if we detect tampering with a title or mortgage -
              we’re the hawk keeping a close watch at all times.
            </p>
          </Col>
        </Row>
      </Container>
      {displayLastSection && (
        <Container fluid className="px-3 px-md-5 card_bottom">
          <Row>
            <Col>
              <div className="card text-center private_guard_bg">
                <div className="card-body p-0">
                  <img src={vector4} className="vector4" alt="Vector" />
                  <img src={vector3} className="vector3" alt="Vector" />
                  <img src={vector2} className="vector2" alt="Vector" />
                  <img src={vector1} className="vector1" alt="Vector" />
                  <img src={vector} className="vector" alt="Vector" />
                  <img src={pana} className="pana" alt="pana" />
                  <img src={pana2} className="pana2" alt="pana" />
                  <p className="private_guard_title card-title text-white text-center">
                    Learn How EquityProtect™ Can Help Protect You
                  </p>
                </div>
                <div className="mt-2">
                  <Link
                    className=" text-white text-decoration-none"
                    to="/create-account"
                  >
                    <Button className="button2">Find Out More</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default PrivateGuardSection;
