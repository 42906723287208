import * as React from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import { Search, Settings } from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import DeleteModal from "../common/DeleteModal";
import AlertDialog from "../common/AlertDialog";
import EditIconSVG from "../common/customSVGs/EditIconSVG";
import { COUNTRY_CODE, PER_PAGE } from "../helpers/constants";
import DeleteIconSVG from "../common/customSVGs/DeleteIconSVG";
import { spouseAction } from "../../redux/slices/spouse/spouseSlice";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../admin/AddLeads";
import {
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../helpers/utils";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    id: "name",
    disablePadding: true,
    label: "Name",
    minWidth: 140,
  },
  {
    id: "email",
    disablePadding: true,
    label: "Email",
    minWidth: 170,
  },
  {
    id: "phone",
    disablePadding: true,
    label: "Phone Number",
    minWidth: 160,
  },
  {
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    minWidth: 200,
  },
  {
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 100,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const INITIAL_PAYLOAD = {
  limit: PER_PAGE,
  offset: 0,
};

export default function SpouseListTable({
  handleEditButton,
  setIsAddSpouseModalOpen,
}) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [toggleFilterOptions, setToggleFilterOptions] = React.useState(false);
  const [isDeleteSpouseModalOpen, setIsDeleteSpouseModalOpen] =
    React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedKey, setSelectedKey] = React.useState("all");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [spousePayload, setSpousePayload] = React.useState(INITIAL_PAYLOAD);
  const [anchorEl, setAnchorEl] = React.useState(false);

  // store data
  const {
    spouseListLoader,
    spouseList,
    deleteSpouseDetails,
    addSpouseDetails,
  } = useSelector((state) => state?.spouse);
  const { loginAsSpouse: isSecondaryUser } = useSelector(
    (state) => state?.userProfile
  );

  const count = Math.ceil(spouseList?.count / PER_PAGE);
  const _DATA = spouseList?.data?.length > 0 ? spouseList?.data : [];

  const handlePageChange = (e, p) => {
    setPage(p);
    // _DATA.jump(p);
    setSpousePayload((prevState) => ({
      ...prevState,
      offset: (p - 1) * PER_PAGE,
    }));
  };

  // handle open delete modal
  const handleDeleteButton = (selectedItem) => {
    setIsDeleteSpouseModalOpen(true);
    setSelectedItem(selectedItem);
  };

  //   handleClosing delete modal
  const handleCloseDeleteModal = () => {
    setIsDeleteSpouseModalOpen(false);
    setSelectedItem(null);
    dispatch(spouseAction.clearData({}));
  };

  // dispatch action to delete professional
  const handleDeleteSpouse = () => {
    console.log("selectedItem", selectedItem);
    if (selectedItem && selectedItem?.user_id) {
      dispatch(
        spouseAction.deleteSpouseDetails({
          url: `delete-sub-user/${selectedItem?.user_id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle filter property in professionals
  const handleFilterProperty = () => {
    if (selectedKey && selectedValue) {
      setPage(1);
      setSpousePayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        value: selectedValue || undefined,
      }));
    }
  };

  // handle reset function in professionals
  const resetFilterOption = () => {
    setPage(1);
    setSelectedKey("all");
    setSelectedValue("");
    setSpousePayload(INITIAL_PAYLOAD);
  };

  const getSpouseDetails = useCallback(() => {
    dispatch(
      spouseAction.fetchSpouseListData({
        url: `sub-users`,
        data: {
          limit: PER_PAGE,
          offset: spousePayload?.offset || 0,
          type: spousePayload?.type,
          value: spousePayload?.value,
        },
        token: true,
      })
    );
  }, [
    dispatch,
    spousePayload?.offset,
    spousePayload?.type,
    spousePayload?.value,
  ]);

  const handleMenuClick = (event, data) => {
    setSelectedItem(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getSpouseDetails();
  }, [getSpouseDetails]);

  const open = Boolean(anchorEl);

  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>

      {/* Filtering Options */}
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(event) => {
                        setSelectedKey(event.target.value);
                        setSelectedValue("");
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.id !== "no." &&
                          cell.id !== "action" &&
                          cell.id !== "type" &&
                          cell.id !== "created_at" &&
                          cell.id !== "zip" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "phone" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl
                      className="filter-search w-100"
                      variant="outlined"
                    >
                      <InputLabel>Enter search term</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        }
                        label="Enter search term"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {selectedKey === "phone" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={selectedValue}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setSelectedValue(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={() => {
                      resetFilterOption();
                    }}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card id="filter-options-card" className="d-none d-md-block">
              <Box className="filter-options-box">
                <div className="search-text">Search By</div>
                <FormControl className="filter-select-one">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="All Types"
                    value={selectedKey}
                    onChange={(event) => {
                      setSelectedKey(event.target.value);
                      setSelectedValue("");
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {columns.map(
                      (cell) =>
                        cell.id !== "no." &&
                        cell.id !== "action" &&
                        cell.id !== "type" &&
                        cell.id !== "created_at" &&
                        cell.id !== "zip" && (
                          <MenuItem key={cell.id} value={cell.id}>
                            {cell.label}
                          </MenuItem>
                        )
                    )}
                  </TextField>
                </FormControl>
                {selectedKey !== "phone" && (
                  <FormControl className="filter-search" variant="outlined">
                    <InputLabel>Enter search term</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      }
                      label="Enter search term"
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}
                    />
                  </FormControl>
                )}
                {selectedKey === "phone" && (
                  <Box className={`phone-number-input bg-white filter`}>
                    <PhoneInput
                      className="bg-white"
                      international={false}
                      placeholder="Phone Number"
                      value={selectedValue}
                      countries={["US", "IN"]}
                      defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                      countryOptionsOrder={["US", "IN"]}
                      addInternationalOption={false}
                      onChange={(event) => {
                        setSelectedValue(event);
                      }}
                    />
                  </Box>
                )}
                <FormControl>
                  <Button
                    className="filter-button"
                    onClick={handleFilterProperty}
                  >
                    Filter Results
                  </Button>
                </FormControl>
                <Button className="reset-filters" onClick={resetFilterOption}>
                  Reset
                </Button>
              </Box>
            </Card>
          </>
        )}
      </Box>
      {/* Sub user Listing Table */}
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 sub-user-mobile-table py-3">
        {_DATA?.map((row, index) => {
          return (
            <>
              <Stack
                direction="column"
                spacing={1.2}
                px={2}
                className="position-relative"
              >
                <Tooltip
                  title="View Subscription History"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={(e) => {
                      if (isSecondaryUser) {
                        return;
                      } else {
                        handleMenuClick(e, row);
                      }
                    }}
                    color={`${isSecondaryUser ? "gray" : ""}`}
                    size="small"
                    sx={{
                      color: `${isSecondaryUser ? "gray" : "#15BE53"}`,
                      position: "absolute",
                      right: "10px",
                    }}
                    className={`${
                      isSecondaryUser
                        ? " cursor-disable settings-button"
                        : "settings-button"
                    }`}
                  >
                    <Settings />
                  </IconButton>
                </Tooltip>

                <Stack direction="column" spacing={0.5}>
                  <Typography
                    className="value fw-500 text-capitalize"
                    sx={{ fontSize: "15px !important" }}
                  >
                    {`${row?.first_name} ${row?.last_name}`.toLowerCase() ||
                      "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Email:</Typography>
                  <Typography className="value">{row?.email || "-"}</Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Phone:</Typography>
                  <Typography className="value">
                    {row?.phone_number
                      ? getFormatedPhoneNumber(row?.phone_number)
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Created At:</Typography>
                  <Typography className="value">
                    {row.created_at
                      ? getLocalFormatedDate(row.created_at)
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              {index < _DATA?.length - 1 && (
                <Divider
                  className="my-2 mt-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {getObjLength(spouseList) && !_DATA?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <EnhancedTableHead />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.address)}
                      tabIndex={-1}
                      key={row?.id}
                    >
                      <TableCell align="left">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell
                        className="text-capitalize"
                        align="left"
                      >{`${row?.first_name} ${row?.last_name}`}</TableCell>
                      <TableCell align="left">{row?.email}</TableCell>
                      <TableCell align="left">
                        {row?.phone_number
                          ? getFormatedPhoneNumber(row?.phone_number)
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.created_at)
                          : "-"}
                      </TableCell>
                      <TableCell align="left" width={120}>
                        <Stack direction="row">
                          <Tooltip
                            title="Edit"
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "#0395FF",
                                fontSize: "1.4rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(
                                  spouseAction.clearAddSpouseDetailsData()
                                );
                                handleEditButton(row);
                              }}
                              disabled={isSecondaryUser}
                            >
                              <ModeOutlinedIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title="Delete"
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "#FC2125",
                                fontSize: "1.4rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(
                                  spouseAction.clearAddSpouseDetailsData()
                                );
                                handleDeleteButton(row);
                              }}
                              disabled={isSecondaryUser}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!spouseList?.data?.length > 0 && !spouseListLoader && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
      </Box>
      {spouseList?.count > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}

      <DeleteModal
        open={isDeleteSpouseModalOpen}
        title="Delete Spouse"
        item="Spouse"
        deleteLoader={spouseListLoader}
        handleClose={handleCloseDeleteModal}
        handleDeleteBtn={handleDeleteSpouse}
        fetchedData={deleteSpouseDetails}
        isDeleteSuccess={deleteSpouseDetails?.success}
        successMessage={deleteSpouseDetails?.message}
        errorMessage={deleteSpouseDetails?.data?.message}
        footer={false}
        callListingAPI={() => {
          if (
            spouseList?.data?.length === 1 &&
            spouseList?.count > PER_PAGE &&
            isDeleteSpouseModalOpen
          ) {
            setSpousePayload((prevState) => ({
              ...prevState,
              offset: (page - 2) * PER_PAGE,
            }));
            setPage(page - 1);
          } else {
            getSpouseDetails();
          }
        }}
      />

      {/* {getObjLength(addSpouseDetails) ? (
        addSpouseDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(spouseAction.clearAddSpouseDetailsData());
              setIsAddSpouseModalOpen(false);
              getSpouseDetails();
            }}
            mesage={addSpouseDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(spouseAction.clearAddSpouseDetailsData());
            }}
            mesage={addSpouseDetails?.data?.message || "Something went wrong"}
            footer={false}
          />
        )
      ) : null} */}

      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            dispatch(spouseAction.clearAddSpouseDetailsData());
            handleEditButton(selectedItem);
          }}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <EditIconSVG />
            <Typography className="setting-menu-text">Edit</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          key="delete"
          onClick={() => {
            dispatch(spouseAction.clearAddSpouseDetailsData());
            handleDeleteButton(selectedItem);
          }}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <DeleteIconSVG />
            <Typography className="setting-menu-text">Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>

      <Loader open={spouseListLoader} />
    </>
  );
}
