import { put, call, takeLatest } from "redux-saga/effects";
import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { marketValueAction } from "../../slices/market-value/marketValueSlice";

export function* fetchSaleAreaMapDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(marketValueAction.setSaleAreaMapData(response));
}

export function* fetchRentAreaMapDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(marketValueAction.setRentAreaMapData(response));
}

export function* fetchSaleAreaChartDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(marketValueAction.setSaleAreaChartData(response));
}

export function* fetchTrueCostOfOwnershipDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(marketValueAction.setTrueCostOfOwnershipData(response));
}

export function* updateOwnershipDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(marketValueAction.setUpdateOwnershipData(response));
}

export function* watchMarketvalueSagaAsync() {
  yield takeLatest(
    marketValueAction.fetchSaleAreaMapData,
    fetchSaleAreaMapDataSaga
  );
  yield takeLatest(
    marketValueAction.fetchRentAreaMapData,
    fetchRentAreaMapDataSaga
  );
  yield takeLatest(
    marketValueAction.fetchSaleAreaChartData,
    fetchSaleAreaChartDataSaga
  );
  yield takeLatest(
    marketValueAction.fetchTrueCostOfOwnershipData,
    fetchTrueCostOfOwnershipDataSaga
  );
  yield takeLatest(
    marketValueAction.updateOwnershipData,
    updateOwnershipDataSaga
  );
}
