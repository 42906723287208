import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { FieldStyle } from "../../helpers/constants";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { getObjLength } from "../../helpers/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SwitchToProfessional = ({
  selectedItem,
  handleClose,
  callListingAPI,
  setIsSwitchFormSubmit,
  setSwitchToProfessional,
}) => {
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    professionalTypeList,
    professionalTypeLoader,
    switchProfessionalData,
  } = useSelector((state) => state.professionals);

  //   handle switch to  profesional
  const handleSubmitProfessionalForm = (values) => {
    dispatch(
      professionalAction?.getSwitchProfessionalData({
        url: `admin/switch-customer/${selectedItem?.user_id}`,
        data: values,
        token: true,
      })
    );
  };

  const handleOpenResponseModal = () => {
    setIsResponseModalOpen(true);
  };
  const handleCloseResponseModal = () => {
    // clear success or error message response
    dispatch(professionalAction?.fetchSwitchProfessionalData({}));
    setIsResponseModalOpen(false);
  };

  const getProfessionalTypes = useCallback(() => {
    dispatch(
      professionalAction.fetchProfessionalTypes({
        url: `professional/types`,
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getProfessionalTypes();
  }, [getProfessionalTypes]);

  useEffect(() => {
    if (getObjLength(switchProfessionalData)) {
      handleOpenResponseModal();
      setIsSwitchFormSubmit(true);
    }
  }, [switchProfessionalData]);

  return (
    <>
      {" "}
      {!isResponseModalOpen &&
      <Box className="d-flex justify-content-center bg-white">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            company_name: "",
            zip_code: "",
            zip_code2: "",
            type: "",
          }}
          validationSchema={Yup.object().shape({
            company_name: Yup.string("Please enter your company name.")
              .trim(
                "The company name cannot include leading and trailing spaces"
              )
              .strict(true)
              .required("Company name is required"),
            zip_code: Yup.string("Please enter your Zip code 1.")
              .required("Zipcode 1 is required")
              .matches(/^[0-9]{5}$/, "Zipcode 1 must be exactly 5 digits"),
            zip_code2: Yup.string("Please enter your Zip code 2.")
              .required("Zipcode 2 is required")
              .matches(/^[0-9]{5}$/, "Zipcode 2 must be exactly 5 digits")
              .test(
                "zip_code2-not-greater",
                "Zipcode 2 must be greater or equal to Zipcode 1",
                function (value) {
                  const zip1 = this.resolve(Yup.ref("zip_code")); // Get the value of zip1
                  return value >= zip1; // Check if zip2 is less than zip1
                }
              ),
            type: Yup.string("Please enter your Professional type.").required(
              "Professional type is required"
            ),
          })}
          onSubmit={(values) => {
            handleSubmitProfessionalForm(values);
          }}
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            resetForm,
            initialValues,
          }) => {
            return (
              <Form className="beneficiary-form w-100">
                <DialogContent className="bg-white">
                  {/* {!addProfessionalDetails?.success && ( */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="bg-white"
                  >
                    <>
                      {/* company */}
                      <Grid xs={12} md={6} px={1} pb={3}>
                        <TextField
                          className="sign-textField "
                          label="Company"
                          name="company_name"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.company_name}
                          onChange={(event) => {
                            setFieldValue("company_name", event.target.value);
                          }}
                          helperText={
                            touched.company_name && errors.company_name
                          }
                          error={
                            touched.company_name && Boolean(errors.company_name)
                          }
                        />
                      </Grid>
                      {/* professional type */}
                      <Grid xs={12} md={6} px={1} pb={3}>
                        <FormControl
                          fullWidth
                          className="sign-textField "
                          error={touched.type && Boolean(errors.type)}
                        >
                          <InputLabel
                            style={{ color: "#00000075" }}
                            label="type"
                          >
                            Professional Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-placeholder"
                            id="demo-simple-select"
                            value={values?.type}
                            // disabled={selectedItem?.id ? true : false}
                            label="Professional type"
                            name="type"
                            variant="outlined"
                            sx={{
                              textAlign: "left !important",
                            }}
                            onChange={(event) => {
                              setFieldValue("type", event.target.value);
                            }}
                            MenuProps={MenuProps}
                          >
                            {professionalTypeList?.data?.map((data) => (
                              <MenuItem value={data?.type}>
                                {data?.type}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.type && Boolean(errors.type) && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {touched.type && errors.type}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {/* zip code 1 */}
                      <Grid xs={12} md={6} px={1} pb={3}>
                        <TextField
                          className="sign-textField "
                          label="Zip Code 1"
                          name="zip_code"
                          type="number"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.zip_code}
                          onChange={(event) => {
                            setFieldValue("zip_code", event.target.value);
                          }}
                          helperText={touched.zip_code && errors.zip_code}
                          error={touched.zip_code && Boolean(errors.zip_code)}
                        />
                      </Grid>
                      {/* zip code 2 */}
                      <Grid xs={12} md={6} px={1} pb={3}>
                        <TextField
                          className="sign-textField "
                          label="Zip Code 2"
                          name="zip_code2"
                          type="number"
                          variant="outlined"
                          sx={FieldStyle}
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.zip_code2}
                          onChange={(event) => {
                            setFieldValue("zip_code2", event.target.value);
                          }}
                          helperText={touched.zip_code2 && errors.zip_code2}
                          error={touched.zip_code2 && Boolean(errors.zip_code2)}
                        />
                      </Grid>
                    </>
                  </Grid>
                </DialogContent>

                <DialogActions className="alert-modal-btn">
                  <Stack direction="row" spacing={2} pb={2}>
                    <Button
                      className="popup_Btn other_popup_btn m-1"
                      // variant="contained"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn m-1"
                      type="submit"
                      disabled={
                        JSON.stringify(initialValues) === JSON.stringify(values)
                      }
                      variant="contained"
                    >
                      Switch
                    </Button>
                  </Stack>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Box>
      }
      {/* success or error message */}
      {isResponseModalOpen && (
        <PlainAlertMessage
          type={switchProfessionalData?.success ? "Success" : "Error"}
          openPopup={isResponseModalOpen}
          onClose={true}
          onCloseFun={()=>{
            handleCloseResponseModal();
            setIsSwitchFormSubmit(false)
            if(switchProfessionalData?.success){
              setSwitchToProfessional(false)
            }
          }}
          message={
            switchProfessionalData?.success
              ? switchProfessionalData?.message
              : switchProfessionalData?.data?.message || "Something went wrong"
          }
          footer={false}
          buttons={
            switchProfessionalData?.success ? (
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    handleCloseResponseModal();
                    handleClose();
                    callListingAPI();
                    setIsSwitchFormSubmit(false);
                  }}
                  className="next_button"
                  variant="contained"
                >
                  ok, All Good
                </Button>
              </div>
            ) : (
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={()=>{
                    handleCloseResponseModal()
                    setIsSwitchFormSubmit(false);
                  }}
                  className="next_button error_popup_btn"
                  variant="contained"
                >
                  ok, Close
                </Button>
              </div>
            )
          }
        />
      )}
      <Loader open={professionalTypeLoader} />
    </>
  );
};

export default SwitchToProfessional;
