import React from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

import { Loader } from "../../common/loader";
import IndividualPlansForm from "./IndividualPlansForm";
import BothPlanIncludedForm from "./BothPlanIncludedForm";
import CustomTabComponent from "../../common/CustomTabComponent";
import { promocodeAction } from "../../../redux/slices/admin/promocodes/promocodesSlice";
import { getObjLength } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";

export const ApplyOnList = [
  {
    id: 1,
    label: "All",
    value: "all",
  },
  {
    id: 2,
    label: "Subscription Amount",
    value: "subscription_amount",
  },
  {
    id: 3,
    label: "Deposit for recording fees",
    value: "recording_fees",
  },
  {
    id: 4,
    label: "Setup Fees",
    value: "setup_fees",
  },
];

export const promocodeTypeList = [
  {
    id: 1,
    label: "Fixed",
    value: "fixed",
  },
  {
    id: 2,
    label: "Percent",
    value: "percent",
  },
];

export const promocodeStatusList = [
  {
    id: 1,
    label: "Active",
    value: "active",
  },
  {
    id: 2,
    label: "In Active",
    value: "inactive",
  },
];

const AddPromocodeModal = ({ open, selectedItem, handleClose, onSubmit }) => {
  const dispatch = useDispatch();

  const { addPromocodeDetails, promocodeListLoader } = useSelector(
    (state) => state.promocodes
  );

  // clear add/edit/delete promocode's existing data
  useEffect(() => {
    if (open) {
      dispatch(promocodeAction.clearAddPromocodeDetailsData());
    }
  }, [dispatch, open]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const tabContent = [
    {
      label: "Individual",
      value: 1,
      component: (
        <BothPlanIncludedForm
          onSubmit={onSubmit}
          selectedItem={selectedItem}
          tomorrow={tomorrow}
          handleClose={handleClose}
        />
      ),
    },
    {
      label: "Custom",
      value: 2,
      component: (
        <IndividualPlansForm
          onSubmit={onSubmit}
          selectedItem={selectedItem}
          tomorrow={tomorrow}
          handleClose={handleClose}
        />
      ),
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={!addPromocodeDetails?.success ? "lg" : "sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        {!addPromocodeDetails?.success && (
          <>
            <DialogTitle id="alert-dialog-title">
              {selectedItem ? "Edit Promocode" : "Add Promocode"}
              <Button
                onClick={() => handleClose()}
                className="modal-close-btn"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  background: "transparent",
                }}
              >
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>
            </DialogTitle>
            <DialogContent dividers>
              <Box className="m-0 bg-white">
                <CustomTabComponent
                  tabContent={tabContent}
                  hideTab={selectedItem ? true : false}
                  margin="0"
                  mClass="m-0"
                />
                {getObjLength(addPromocodeDetails) &&
                  !addPromocodeDetails?.success && (
                    <p className="text-danger text-center p-2">
                      {addPromocodeDetails?.data?.message}
                    </p>
                  )}
              </Box>
            </DialogContent>
          </>
        )}
        <Loader open={promocodeListLoader} />

        {/* {getObjLength(addPromocodeDetails) ? (
        addPromocodeDetails?.success && (
          <PlainAlertMessage
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              handleClose();
              dispatch(promocodeAction.clearAddPromocodeDetailsData());
              dispatch(
                promocodeAction.fetchPromocodeListData({
                  url: 'promocodes',
                  token: true,
                })
              );
            }}
            message={addPromocodeDetails?.message}
            footer={false}
          />
        )): null} */}
      </Dialog>
    </>
  );
};

export default AddPromocodeModal;
