import React from "react";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";

import { currencyFormatter } from "../helpers/utils";
import SubscriptionPlansFeatures from "./SubscriptionPlansFeatures";
import auto_activity1 from "../../assets/home/auto_activity_zone.svg";
import auto_activity from "../../assets/home/simple_auto_activity_zone .svg";

const AddDynamicSubscriptionModal = ({
  open,
  closeModalHandler,
  plansDetails = {},
  selectedPlanDetails = () => {},
}) => {
  // states
  const [selectedPlan, setSelectdPlan] = useState(null);

  console.log("selectedPlan", selectedPlan);
  const handleCloseModal = () => {
    setSelectdPlan(null);
    closeModalHandler();
  };
  const setupFees = plansDetails?.monthly_plans?.setup_fees;
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // sx={{ position: "relative" }}
      >
        <DialogTitle id="alert-dialog-title fw-600">
          Choose Subscription
          <Button
            onClick={() => handleCloseModal()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent className="bg-white provider_fonts" dividers>
          <>
            <Grid container spacing={2} className="market-value-second-box">
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                order={{ xs: 2, sm: 1, md: 1, lg: 1, xl: 1 }}
              >
                <Box
                  className="card"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box className="d-flex align-items-center justify-content-between p-3">
                    <div className="card_inner w-100 break-word">
                      <div className="d-flex align-items-center mb-4">
                        <div
                          className="d-flex align-items-start"
                          style={{ flexDirection: "column" }}
                        >
                          <p className="title mb-0 fw-600">EquityProtect</p>
                          <h5 className="subTitle mb-0 fw-600">Monthly</h5>
                        </div>
                        <div className="ms-auto">
                          <LazyLoadImage
                            src={auto_activity}
                            alt="auto_activity_zone"
                            width="48px"
                            height="48px"
                          />
                        </div>
                      </div>
                      <hr className="seperator" />
                      <div className="pricing_Wrapper text-center mt-5">
                        <h4 className="fw-600 mb-1">
                          {currencyFormatter(
                            parseFloat(plansDetails?.monthly_plans?.amount || 0)
                            //  +  parseFloat(
                            //    plansDetails?.monthly_plans?.setup_fees || 0
                            //  ) || 0
                          )}{" "}
                        </h4>
                        <p className="mb-0">+ applicable sales tax</p>
                        <p className="mb-0">
                          + applicable Deposit for recording fees (One time fee)
                        </p>
                        {parseFloat(setupFees) > 0 && (
                          <h5 className={`mt-3`}>
                            <b>{currencyFormatter(setupFees || 0)}</b>
                          </h5>
                        )}
                        <p className="mb-0 mt-0">Set-up & Activation Fee</p>
                        <h5
                          className={`${
                            parseFloat(setupFees) > 0 ? "mt-2" : "mt-4"
                          } mb-0`}
                        >
                          {currencyFormatter(
                            plansDetails?.monthly_plans
                              ?.monthly_renewal_plans || 0
                          )}{" "}
                          / month
                        </h5>
                        <b className="mb-0">Renewal Fees</b>
                        {/* <p className={`mb-0 mt-2`}>
                          +{" "}
                          <b>
                            {currencyFormatter(
                              plansDetails?.recording_fees || 0
                            )}
                          </b>
                          &nbsp;Deposit for recording fees (One time fee)
                        </p> */}
                      </div>
                    </div>
                  </Box>

                  <Stack
                    direction="column"
                    alignItems="center"
                    className="py-3"
                  >
                    <Button
                      variant="contained"
                      className="provider_plan_btn"
                      onClick={() => selectedPlanDetails("Monthly")}
                    >
                      Activate Plan
                    </Button>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                order={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
              >
                <Box
                  className="card"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box className="d-flex align-items-center justify-content-between p-3">
                    <div className="card_inner w-100 break-word">
                      <div className="d-flex align-items-center mb-4">
                        <div
                          className="d-flex align-items-start"
                          style={{ flexDirection: "column" }}
                        >
                          <p className="title mb-0 fw-600">EquityProtect</p>
                          <h5 className="subTitle mb-0 fw-600">Yearly</h5>
                        </div>
                        <div className="ms-auto">
                          <LazyLoadImage
                            src={auto_activity1}
                            alt="auto_activity_zone"
                            width="48px"
                            height="48px"
                          />
                        </div>
                      </div>
                      <hr className="seperator" />
                      <div className="pricing_Wrapper text-center mt-5">
                        {/* <p className="mb-0 activation_text">
                          No Set-up or Activation Fee
                        </p> */}
                        <h4 className="mb-1 fw-600">
                          {currencyFormatter(
                            plansDetails?.yearly_plans?.amount
                          )}{" "}
                        </h4>
                        {/* <p className="mb-0">for the first Year</p>
                          <h5 className="mt-3">+ ${5656 / 100} / year </h5> */}
                        {/* <p className="mb-0">
                          per year for annual recertification
                        </p> */}

                        <p className="mb-0 mt-0">+ applicable sales tax</p>
                        <p className="mb-0 mt-0">
                          + applicable Deposit for recording fees (One time fee)
                        </p>
                        {parseFloat(setupFees) > 0 && (
                          <p className="mb-0 activation_text pt-2 mt-4">
                            No Set-up or Activation Fee
                          </p>
                        )}
                        <h5
                          className={`${
                            parseFloat(setupFees) > 0 ? "mt-3" : "mt-5"
                          } mb-0`}
                        >
                          {currencyFormatter(
                            plansDetails?.yearly_plans?.yearly_renewal_plans ||
                              0
                          )}{" "}
                          / year
                        </h5>
                        <b className="mb-0">Renewal Fees</b>
                        {/* <p className="mb-0 w-100 mt-2">
                          +{" "}
                          <b>
                            {currencyFormatter(
                              plansDetails?.recording_fees || 0
                            )}
                          </b>
                          &nbsp;Deposit for recording fees (One time fee)
                        </p> */}
                      </div>
                    </div>
                  </Box>

                  <Stack
                    direction="column"
                    alignItems="center"
                    className="py-3"
                  >
                    <Button
                      variant="contained"
                      className="provider_plan_btn"
                      onClick={() => selectedPlanDetails("Yearly")}
                    >
                      Activate Plan
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <section>
              <Container fluid>
                <SubscriptionPlansFeatures fullPage={true} />
              </Container>
            </section>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDynamicSubscriptionModal;
