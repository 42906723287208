import React from "react";

import { useSelector } from "react-redux";

import { Box } from "@mui/system";
import { Grid } from "@mui/material";

import NewMap from "../NewMap";

const AerialMapTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.properties?.userViewDetailsPropertyData
  );

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className="property-image modal-map">
              <NewMap
                className="map-style"
                center={{
                  lat: propertyData?.latitude,
                  lng: propertyData?.longitude,
                }}
                zoom={17}
                coordinates={[]}
                streetView={false}
                fullscreenControl={true}
                mapTypeControl={true}
                zoomControl={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className="property-image modal-map">
              <NewMap
                className="map-style"
                zoom={15}
                center={{
                  lat: parseFloat(propertyData?.latitude),
                  lng: parseFloat(propertyData?.longitude),
                }}
                coordinates={[]}
                streetView={true}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AerialMapTab;
