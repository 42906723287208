import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LockIcon from "@mui/icons-material/Lock";
import HorizontalLinearStepper from "./SPSsignupSteps";
import GooglePlayeStore from "../../../assets/signin/playstore.webp";
import AppStore from "../../../assets/signin/app_store_img.webp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../common/AlertDialog";
import { Button } from "@mui/material";
import "../../user-signup/signup.scss";
import SignInForm from "./SPSignupForm";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { clearLocalStoarge, getObjLength } from "../../helpers/utils";
import { appStoreURL, playStoreURL } from "../../helpers/constants";

const ServiceProviderSignup = () => {
  const [btnCLicked, setBtnClicked] = useState(false);

  const [resumeCount, setResumeCount] = useState(
    parseInt(localStorage.getItem("currentStep"))
  );
  const [activeStep, setActiveStep] = useState(
    parseInt(localStorage.getItem("currentStep"))
  );

  const dispatch = useDispatch();
  const { resumeProcessResponse } = useSelector(
    (state) => state?.serviceProviderSignup
  );

  const createAcc = (e) => {
    console.log("click on next==>", e);
    setBtnClicked(true);
  };
  const resumetheFlow = () => {
    console.log("resume flow==>");
    if (getObjLength(resumeProcessResponse)) {
      setActiveStep(
        resumeProcessResponse?.data?.json_last_step?.step ||
          localStorage.getItem("currentStep")
      );
    }

    setResumeCount(undefined);
    setBtnClicked(true);
  };
  console.log("session state==>", resumeCount + 1 > 0);

  useEffect(() => {
    console.log("clear data...");
    dispatch(serviceProviderSignupAction.clearData());
    localStorage.removeItem("authToken");
    localStorage.removeItem("authorised");
  }, [dispatch]);

  useEffect(() => {
    console.log("resumeProcessResponse => ", resumeProcessResponse);
    if (getObjLength(resumeProcessResponse) && resumeProcessResponse.success) {
      // setOpenPopup(false);
      resumetheFlow();
    }
  }, [resumeProcessResponse]);

  return (
    <>
      <Grid container>
        <Grid
          xs={12}
          sm={12}
          mdOffset={2}
          md={8}
          lgOffset={2}
          lg={8}
          xlOffset={2}
          xl={8}
          className="stepper-head"
        >
          <Box
            className="signIn-box"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {!btnCLicked ? (
              <Box>
                <CssBaseline />
                <Box
                  sx={{
                    bgcolor: "white",
                    width: "100%",
                    boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
                  }}
                >
                  <Typography
                    align="center"
                    variant="subtitle1"
                    className="py-1 formHeader"
                  >
                    <LockIcon sx={{ fontSize: 16 }} className="me-1" />
                    Secure connection
                  </Typography>
                  <Box className="accountBoxWrapper">
                    <Typography align="center" variant="h3">
                      Create your Account
                    </Typography>
                    <Typography
                      align="center"
                      variant="body1"
                      sx={{ marginBottom: "1rem !important" }}
                    >
                      We just need to gather some information to set up your
                      digital profile.
                    </Typography>
                    <SignInForm onBtnClick={createAcc} />
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <HorizontalLinearStepper
                  resumeCount={activeStep}
                  backToRoot={() => setBtnClicked(false)}
                />
              </>
            )}
            {btnCLicked && (
              <Box py={5} className="mobileApp_Wrapper text-center">
                <Typography variant="body1" mb={1}>
                  Move faster with the mobile app
                </Typography>
                <div className="d-flex justify-content-around">
                  <img
                    src={GooglePlayeStore}
                    onClick={() => window.open(playStoreURL, "_blank")}
                    alt="GooglePlayStore"
                    style={{
                      width: "100px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                  <img
                    src={AppStore}
                    onClick={() => window.open(appStoreURL, "_blank")}
                    alt="AppStore"
                    style={{ width: "100px", height: "30px" }}
                  />
                </div>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {resumeCount + 1 > 0 && (
        <AlertDialog
          openPopup={resumeCount + 1 > 0 ? true : false}
          onClose={false}
          type="Warning"
          mesage={"Do you want to resume from where you left."}
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => {
                  setResumeCount(undefined);
                  setBtnClicked(false);
                  setActiveStep(0);
                  dispatch(serviceProviderSignupAction.resetSPState({}));
                  clearLocalStoarge();
                }}
              >
                Nope
              </Button>
              <Button
                className="popup_Btn success_popup_btn "
                onClick={resumetheFlow}
              >
                Yes
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default ServiceProviderSignup;
