import { useEffect } from "react";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Navbar, Row } from "react-bootstrap";

import { Stack } from "@mui/system";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import LennarSubscription from ".";
import LennarPublicPageFooter from "../../layout/LennarPublicPageFooter";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import check from "../../../assets/lennar/check.webp";
import logo from "../../../assets/lennar/ep-logo.svg";
import CircularProgressLoader from "../../common/CircularProgressLoader";
import ProviderTotalPyamentPopup from "../../common/ProviderTotalPyamentPopup";
import AddDynamicSubscriptionModal from "../../common/AddDynamicSubscriptionModal";
import { lennarSubscriptionAction } from "../../../redux/slices/lennar/subscriptionSlice";
import {
  calulateDatefromDaysLater,
  currencyFormatter,
  customeDomainName,
  decryptPayload,
  encryptPayload,
  filteroutPlanData,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

import "../../../styles/lennarPropertyList.scss";
import { DEFAULT_URL, ROLES } from "../../helpers/constants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  //   textAlign: "center",
  color: theme.palette.text.secondary,
}));

const LennarPropertyList = () => {
  const dispatch = useDispatch();

  // states
  const [paymentPage, setPaymentPage] = useState(false);
  const [planScreens, setPlanScreens] = useState(false);
  const [dataflag, setDataflag] = useState(true);
  const [plansData, setPlanData] = useState({});
  const [defaultPlan, setDefaultPlan] = useState();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [renewalAmount, setRenewalAmount] = useState({});
  const [totalAmountDetail, setTotalAmountDetail] = useState({});

  // store data
  const {
    lennarPropertyList,
    lennarLoader,
    providerDetails,
    providerLoader,
    cardLoader,
    totalAmountLoader,
    totalAmountDetails,
  } = useSelector((state) => state?.lennarSubscription);

  const queryString = window.location.search;
  let paramString = queryString.split("?")[1];
  const pathName = window.location.pathname;
  const fetchCustomDomain = customeDomainName(window.location.host);
  let path = null;
  let cancellationPolicyURL = null;
  if (!fetchCustomDomain) {
    path = pathName?.split("/")?.[1];
    cancellationPolicyURL = `/${path}/cancellation-policy`;
  } else {
    path = fetchCustomDomain;
    cancellationPolicyURL = `/cancellation-policy`;
  }
  console.log("path==>", path);
  let from = paramString?.split("&")?.[1]?.replace("from=", "");
  if (decryptPayload(from)?.includes("admin")) {
    localStorage.setItem("goToAdmin", from);
  }
  let token = paramString?.replace("token", "");

  const customPathname = cancellationPolicyURL;

  const choosePlans = () => {
    setPlanScreens(true);
  };

  const getFinalAmountAPI = (planDetails) => {
    dispatch(
      lennarSubscriptionAction?.getTotalAmountDetails({
        url: `providers/${path}/get-final-amount`,
        data: {
          authToken: token,
          plan_id: planDetails?.id,
        },
      })
    );
  };

  const selectedPlanDetails = (plan) => {
    let planDetails = providerDetails?.data?.plans?.find(
      (i) => i?.name === plan
    );
    console.log(plan, "selectedPlan==>", planDetails);
    setRenewalAmount(
      providerDetails?.data?.plans?.find(
        (i) => i?.name === plan && !i?.is_default
      )?.amount
    );
    setSelectedPlan(
      providerDetails?.data?.plans?.find((i) => i?.name === plan)
    );
    getFinalAmountAPI(planDetails);
    setDataflag(true);
    setDefaultPlan(planDetails?.name);
    // setPlanScreens(false);
  };

  const goBackToAdmin = () => {
    const user_id = (lennarPropertyList?.data?.user_id).toString();
    localStorage.setItem("user_id", encryptPayload(user_id));
    window.location.href =
      DEFAULT_URL +
      decryptPayload(from) +
      `?from=${ROLES?.provider}&user_id=` +
      user_id;
  };

  const goToPayment = () => {
    localStorage.setItem(
      "user_id",
      encryptPayload((lennarPropertyList?.data?.user_id).toString())
    );
    if (providerDetails?.data?.plans?.length === 2) {
      let planDetails = providerDetails?.data?.plans?.find(
        (i) => i?.name === defaultPlan
      );
      console.log(
        defaultPlan,
        "Payment Plan -->",
        providerDetails?.data?.plans?.find((i) => i?.name === defaultPlan)
      );
      setRenewalAmount(
        providerDetails?.data?.plans?.find(
          (i) => i?.name === defaultPlan && !i?.is_default
        )?.amount
      );
      setSelectedPlan(
        providerDetails?.data?.plans?.find((i) => i?.name === defaultPlan)
      );
      setDataflag(true);
      getFinalAmountAPI(planDetails);
      // setPaymentPage(true);
    } else {
      choosePlans();
    }
  };

  const goToPolicy = () => {
    // Store the current URL in localStorage
    localStorage.setItem("previousURL", encryptPayload(window.location.href));
  };

  useEffect(() => {
    if (token?.length > 0) {
      dispatch(
        lennarSubscriptionAction?.getProviderDetails({
          url: `providers/${path}/detail`,
          data: {},
        })
      );
      dispatch(
        lennarSubscriptionAction?.getPropertyDetails({
          url: `providers/${path}/properties/list?authToken${token}`,
          data: {},
        })
      );
      dispatch(
        lennarSubscriptionAction?.userCardList({
          url: `providers/${path}/cards/list`,
          data: { authToken: token },
        })
      );
      localStorage.removeItem("roles");
    } else {
      // navigate("/404");
    }
  }, [dispatch, path, token]);

  useEffect(() => {
    let selectedData = filteroutPlanData(providerDetails?.data);
    setDefaultPlan(providerDetails?.data?.plans?.[0]?.name);
    setPlanData(selectedData);
  }, [dispatch, path, providerDetails?.data, token]);

  console.log(defaultPlan, "==plansData==>", plansData);

  let org_logo = logo;
  console.log("lennarPropertyList==>", lennarPropertyList);

  const closeTotalAmountModal = (action) => {
    setTotalAmountDetail(totalAmountDetails);
    if (action === "submit") {
      setPaymentPage(true);
      setPlanScreens(false);
    }
    dispatch(lennarSubscriptionAction.fetchTotalAmountDetails({}));
    setDataflag(false);
  };

  // defualt scroll bottom to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {!paymentPage ? (
        <>
          <Box className="lennar-wrapper">
            <div className=" property_bg_img text-center pb-3">
              <Navbar.Brand>
                {providerDetails?.data?.logo && (
                  <img
                    className="lennar-logo"
                    src={providerDetails?.data?.logo}
                    alt="lennar-logo"
                  />
                )}
                <img className="lennar-logo" src={org_logo} alt="lennar-logo" />
              </Navbar.Brand>
              <section className="content-spacing">
                <>
                  <Row className="mt-5 d-flex justify-content-center align-items-center text-white mx-0">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      // className="content-spacing"
                    >
                      <h1 className="property_heading fw-400 mb-4 mt-0 mt-sm-4">
                        Welcome, {lennarPropertyList?.data?.user_name}
                      </h1>
                      <p className="paragraph_texts fw-400 mt-2 mx-auto">
                        Together with EquityProtect, we are providing the best
                        protection against the biggest threat.
                      </p>
                    </Col>
                  </Row>
                </>
              </section>
            </div>
            <section
              className="d-flex align-items-center mt-3 content-spacing"
              style={{ background: "#FAFAFA", paddingBottom: "5rem" }}
            >
              <Container>
                <Box>
                  <Typography className="d-flex justify-content-start properties_list my-3">
                    Your Properties
                  </Typography>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    display={"flex"}
                    // justifyContent={
                    //   lennarPropertyList?.data?.properties?.length > 1 &&
                    //   "center"
                    // }
                  >
                    {lennarLoader ? (
                      <Grid item xs={12} md={6}>
                        <Item>
                          <div className="d-flex align-items-center justify-content-center">
                            <p className="px-2">Please wait..</p>
                            <CircularProgressLoader />
                          </div>
                        </Item>
                      </Grid>
                    ) : lennarPropertyList?.data?.properties?.length > 0 ? (
                      <>
                        {lennarPropertyList?.data?.properties?.map(
                          (data, key) => {
                            return (
                              <>
                                <Grid item xs={12} md={6}>
                                  <Item>
                                    <div className="text-start p-3">
                                      <div className="d-flex align-items-center justify-content-end">
                                        <div className="poperty_Btn unprotected_btn ">
                                          Unprotected
                                        </div>
                                      </div>
                                      <p className="property_list_heading">
                                        Property {key + 1}
                                      </p>
                                      <p className="body_text_1">
                                        {data?.address}
                                      </p>
                                      <p className="body_text_2">
                                        Built {data?.year_built} | Added:{" "}
                                        {data?.created_at
                                          ? getLocalFormatedDate(
                                              data?.created_at
                                            )
                                          : " -"}{" "}
                                        | Est. Value:
                                        {data?.approx_price &&
                                        (!isNaN(data?.approx_price) ||
                                          data?.approx_price !== "N/A")
                                          ? ` ${currencyFormatter(
                                              data?.approx_price
                                            )}`
                                          : " -"}
                                      </p>
                                    </div>
                                  </Item>
                                </Grid>
                              </>
                            );
                          }
                        )}
                        {/* {decryptPayload(from)?.includes("admin") && (
                          <Grid item md={12} className="text-center">
                            <Button
                              onClick={goBackToAdmin}
                              className="continue_payment_btn mt-4 mx-1"
                            >
                              Back
                            </Button>
                          </Grid>
                        )} */}
                      </>
                    ) : (
                      <Grid item xs={12} md={6}>
                        <Item>
                          <div className="d-flex align-items-center justify-content-center">
                            <p className="py-2 m-0">
                              <b>No properties Found</b>
                            </p>
                          </div>
                        </Item>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Container>
            </section>
            <section className="pt-3 bg-white content-spacing">
              <p className="choose_us_header">Why choose us</p>
              <Grid container spacing={3} className="px-150 content-spacing">
                <Grid
                  item
                  xs={12}
                  md={6}
                  // className="content-spacing"
                  // style={{ padding: "45px" }}
                >
                  <div className="">
                    <p className="choose_us_text mt-2">
                      Significantly, in the preceding year, several Attorney
                      General's Offices issued warnings to homeowners in
                      response to a troubling surge in complaints related to
                      title theft. Various state agencies and industry-related
                      associations, including the American Land and Title
                      Association and the California Association of Realtors,
                      have also issued numerous warnings.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Stack
                        className=""
                        direction={"row"}
                        justifyContent={"start"}
                      >
                        <img
                          className="p-1 mt-2"
                          src={check}
                          alt="article"
                          style={{
                            width: "31px",
                            height: "22px ",
                          }}
                          loading="lazy"
                        />
                        <p className="p-2 description_text">
                          Our multi-factor authentication technology safeguards
                          your title and equity by securing your homeownership
                          against cybercriminals and fraudulent activities.
                        </p>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack
                        className="pt-3"
                        direction={"row"}
                        justifyContent={"start"}
                      >
                        <img
                          className="p-1 mt-2"
                          src={check}
                          alt="article"
                          style={{
                            width: "31px",
                            height: "22px ",
                          }}
                          loading="lazy"
                        />

                        <p className="p-2 description_text">
                          Property owners maintain complete control, as only
                          they can only request to “unlock” their property,
                          effectively eliminating the possibility of tampering
                          or theft of home equity and ownership. This service
                          parallels credit lock services that monitor and
                          prevent suspicious activity on one's credit profile.
                        </p>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack
                        className="pt-3"
                        direction={"row"}
                        justifyContent={"start"}
                      >
                        <img
                          className="p-1 mt-2"
                          src={check}
                          alt="article"
                          style={{
                            width: "31px",
                            height: "22px ",
                          }}
                          loading="lazy"
                        />
                        <p className="p-2 description_text">
                          EquityProtect, a leading cybersecurity fintech
                          company, is transforming the safeguarding of real
                          property interests through cutting-edge technology
                          that provides validated and legally recognized
                          property owner authorizations. This innovative
                          solution caters to various consumer and business
                          professional scenarios, revolutionizing the protection
                          of legal interests in real property.
                        </p>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </section>
            <section
              className="text-center content-spacing"
              style={{ background: " #235CA30D" }}
            >
              {lennarLoader ? (
                <Stack className="py-5 ">
                  <div className="d-flex align-items-center justify-content-center">
                    <CircularProgressLoader />
                  </div>
                </Stack>
              ) : (
                <Stack direction={"column"} className="py-5 ">
                  {providerDetails?.data?.plans?.length === 2 && (
                    <>
                      <p className="pricing_brerakdown">Pricing breakdown</p>
                      <p className="mt-2 pricing">
                        Signup fee:{" "}
                        <b>
                          {currencyFormatter(
                            providerDetails?.data?.plans?.[0]?.amount
                          )}
                        </b>
                      </p>
                      {providerDetails?.data?.plans?.[1]?.amount && (
                        <p className="pricing">
                          Renewal fee:{" "}
                          <b>
                            {currencyFormatter(
                              providerDetails?.data?.plans?.[1]?.amount
                            )}
                          </b>
                        </p>
                      )}
                      {/*
                      <Stack
                        direction={"row"}
                        spacing={4}
                        justifyContent={"center"}
                      >
                         {providerDetails?.data?.plans?.[0]?.additional_charges
                        ?.recording_fees && (
                        <p className="pricing ">
                          Recording fee:{" "}
                          <b>
                            {currencyFormatter(
                              providerDetails?.data?.plans?.[0]
                                ?.additional_charges?.recording_fees || 0
                            )}
                          </b>
                        </p>
                      )} 
                        {providerDetails?.data?.plans?.[0]
                          ?.additional_charges?.["setup fees"] && (
                          <p className="pricing ">
                            Setup fee:{" "}
                            <b>
                              {currencyFormatter(
                                providerDetails?.data?.plans?.[0]
                                  ?.additional_charges?.["setup fees"] || 0
                              )}
                            </b>
                          </p>
                        )}
                      </Stack>
                      */}
                      {providerDetails?.data?.plans?.[0]?.trial_period &&
                        parseInt(
                          providerDetails?.data?.plans?.[0]?.trial_period?.day
                        ) > 0 && (
                          <p className="pricing">
                            <b>
                              {providerDetails?.data?.plans?.[0]?.trial_period
                                ?.day || 0}
                              -Day Free Trial
                            </b>
                          </p>
                        )}
                      {lennarPropertyList?.data?.plan_pricing?.created_at ? (
                        <p className="pricing">
                          Your {defaultPlan} subscription starting on{" "}
                          <b>
                            {calulateDatefromDaysLater(
                              //lennarPropertyList?.data?.properties?.[0]?.created_at,
                              new Date(),
                              lennarPropertyList?.data?.plan_pricing
                                ?.trial_period?.day || 0
                            ) + "*"}
                          </b>
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <>
                    <Stack
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      spacing={2}
                      direction={{
                        md: "row-reverse",
                        xs: "column",
                      }}
                    >
                      <Button
                        onClick={goToPayment}
                        className="continue_payment_btn mt-2 mx-1"
                      >
                        Continue to payment
                      </Button>
                      {decryptPayload(from)?.includes("admin") && (
                        <Button
                          onClick={goBackToAdmin}
                          className="continue_payment_btn other_popup_btn mt-2 mx-1"
                        >
                          Back
                        </Button>
                      )}
                    </Stack>
                    <Link to={{ pathname: customPathname }} replace>
                      <p className="cancellation-policy" onClick={goToPolicy}>
                        Cancellation Policy
                      </p>
                    </Link>
                  </>
                </Stack>
              )}
              {/* {lennarLoader ? (
              <Stack direction={"column"} className="py-5">
                <p className="pricing_brerakdown">Pricing breakdown</p>
                <p className="mt-2 pricing">
                  Signup fee: <b>-</b>
                </p>
                <p className="pricing">
                  <b>60-Day Free Trial</b>
                </p>
                <p className="pricing">
                  <b>-</b>
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <CircularProgressLoader />
                </div>
                <Link to="/cancellation-policy">
                  <p className="cancellation-policy" onClick={goToPolicy}>
                    {" "}
                    Cancellation Policy
                  </p>
                </Link>
              </Stack>
            ) : (
              <></>
            )} */}
            </section>
          </Box>
          <LennarPublicPageFooter />
        </>
      ) : (
        <LennarSubscription
          selectedPlan={selectedPlan}
          dynamicLogo={providerDetails?.data?.logo}
          defaultPlan={defaultPlan}
          path={path}
          fetchCustomDomain={fetchCustomDomain}
          totalAmountDetail={totalAmountDetail}
          setPaymentPage={setPaymentPage}
          renewalAmount={renewalAmount}
        />
      )}

      {/* add subscription modal */}
      <AddDynamicSubscriptionModal
        open={planScreens}
        closeModalHandler={() => setPlanScreens(false)}
        plansDetails={plansData}
        selectedPlanDetails={selectedPlanDetails}
      />

      {getObjLength(providerDetails) && !providerDetails?.success ? (
        <AlertDialog
          type={"Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(lennarSubscriptionAction?.fetchProviderDetails());
          }}
          buttons={
            providerDetails?.data?.errors?.user_exist && (
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  dispatch(lennarSubscriptionAction?.fetchProviderDetails());
                }}
              >
                ok, Close
              </Button>
            )
          }
          mesage={providerDetails?.data?.message}
        />
      ) : null}

      {/* modal for showing details of the payment */}
      {getObjLength(totalAmountDetails) && dataflag ? (
        totalAmountDetails?.success ? (
          <ProviderTotalPyamentPopup
            dataflag={dataflag}
            setDataFlag={closeTotalAmountModal}
            alert={true}
            setAlert={closeTotalAmountModal}
            closePopup={closeTotalAmountModal}
            planDetails={plansData}
            isClose={true}
            paySecure={closeTotalAmountModal}
            defaultPlan={defaultPlan}
          />
        ) : (
          <>
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() =>
                dispatch(lennarSubscriptionAction.fetchTotalAmountDetails({}))
              }
              mesage={
                totalAmountDetails?.data?.message ||
                "An error occured while getting the payment details."
              }
            />
          </>
        )
      ) : null}

      <Loader open={providerLoader || cardLoader || totalAmountLoader} />
    </>
  );
};

export default LennarPropertyList;
