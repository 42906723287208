import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Autocomplete,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Unstable_Grid2";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import CustomTextField from "../../common/CustomTextField";
import { getObjLength } from "../../helpers/utils";
const FieldStyle = {
  borderRadius: "3px",
};

const validationSchema = Yup.object().shape({
  parcel_number: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(15, "Too Long!")
    .required("Parcel number is required"),
  county_code: Yup.string("Please select the County ").required(
    "County  is required"
  ),
});

export default function FindPropertyByParcel({
  setShowPropertyListing,
  setPropertyValues,
  questionsErrMsg,
  setErrMsg,
  errMsg,
  path,
  setScreen = () => {},
}) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [checkTerms, setCheckTerms] = useState(true);
  const [checked1, setChecked1] = useState(false);

  const { getPropertyData, countyList, statesLoader, verifiedProperties } =
    useSelector((state) => state.signUpUser);

  const onSubmit = (values, errors) => {
    setCheckTerms(checked1);
    if (checked1) {
      let data = { ...values, type: "parcel" };
      console.log("submited values==>", data);
      dispatch(
        userSigupAction.getUserProperty({
          url: "properties",
          data: data,
          token: false,
        })
      );
    }
    // onBtnClick();
  };

  const getCountyList = (event) => {
    console.log("value==>", event.target.value);
    dispatch(
      userSigupAction.getCountyList({
        url: "get-county-autocomplete",
        data: { county: event.target.value },
      })
    );
  };

  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };

  useEffect(() => {
    if (getObjLength(countyList)) {
      console.log(countyList, "options==>", options);
      if (countyList?.success) {
        setOptions([...countyList?.data]);
      }
    }
  }, [countyList]);
  useEffect(() => {
    if (getObjLength(getPropertyData)) {
      if (getPropertyData?.success) {
        setPropertyValues(getPropertyData?.data);
        setErrMsg("");
        if (getPropertyData?.data?.length === 1) {
          dispatch(
            userSigupAction.selectedUserPropertyData(getPropertyData?.data[0])
          );
        }
        if (getPropertyData?.data?.length > 1) {
          setOptions([]);
          setShowPropertyListing(true);
          console.log("setPropertyValues ==>", getPropertyData);
        }
      } else {
        setPropertyValues({});
        setErrMsg(getPropertyData?.data?.message);
      }
    }
  }, [getPropertyData]);

  return (
    <>
      <Box className="">
        {
          <>
            <>
              <>
                <Box mt={3} className="findPropertyBoxWrapper">
                  <Formik
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                      parcel_number: "",
                      county_code: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, touched, errors }) => {
                      return (
                        <Form>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid xs={12} md={6} px={1} pb={1}>
                              <CustomTextField
                                label="Parcel Number"
                                name="parcel_number"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                                onChange={(event) => {
                                  setFieldValue(
                                    "parcel_number",
                                    event.target.value
                                  );
                                }}
                                classes="standard-field"
                                otherProps={{
                                  variant: "standard",
                                }}
                              />
                            </Grid>

                            <Grid xs={12} md={6} px={1} pb={1}>
                              <FormControl
                                fullWidth
                                // className="sign-textField"
                                htmlFor="demo-customized-textbox"
                                error={
                                  touched.county_code &&
                                  Boolean(errors.county_code)
                                }
                              >
                                <Autocomplete
                                  id="asynchronous-demo"
                                  // freeSolo
                                  disableClearable
                                  sx={FieldStyle}
                                  open={open}
                                  onOpen={() => {
                                    setOpen(true);
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      "county_code",
                                      String(newValue?.id)
                                    );
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.county === value.county
                                  }
                                  getOptionLabel={(option) => option.county}
                                  options={options}
                                  loading={statesLoader}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="standard"
                                      onChange={(event) => {
                                        getCountyList(event);
                                        setOptions([]);
                                      }}
                                      label="County"
                                      placeholder={"Search the County"}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {statesLoader ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {touched.county_code &&
                                  Boolean(errors.county_code) && (
                                    <FormHelperText
                                      sx={{ color: "#d32f2f !important" }}
                                    >
                                      {touched.county_code &&
                                        errors.county_code}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Box
                            className={!checkTerms && "border border-danger"}
                            textAlign={"start"}
                            py={1}
                            px={2}
                          >
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent={"flex-start"}
                              alignItems={"start"}
                            >
                              <span className="pt-1">
                                <CustomizedCheckbox onChecked={onChecked} />
                              </span>
                              <span>
                                For security reasons, I understand that I will
                                be asked challenge questions and/or my customer
                                service PIN to verify my identity. If I receive
                                a call and I am NOT asked the proper challenge
                                questions, I will hang up and call Customer
                                Support immediately.
                              </span>
                            </Stack>
                          </Box>
                          {!checkTerms && (
                            <p className="text-danger m-3">
                              Please select given terms to proceed further.
                            </p>
                          )}
                          {errMsg && (
                            <p className="text-center text-danger m-3">
                              {errMsg}
                            </p>
                          )}
                          <div className="text-center stepBtn mt-5">
                            {parseInt(verifiedProperties) >= 1 && (
                              <Button
                                className="popup_Btn other_popup_btn mb-3 mx-2"
                                variant="contained"
                                onClick={() => setScreen("owner-verification")}
                              >
                                Go To Property List
                              </Button>
                            )}
                            <Button
                              type="submit"
                              className="popup_Btn success_popup_btn mb-3"
                              variant="contained"
                            >
                              Next Step
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
              </>
            </>

            {questionsErrMsg?.length > 0 && (
              <p className="text-danger text-center">{questionsErrMsg}</p>
            )}
          </>
        }
      </Box>
      {/* <Loader open={propertyLoader} /> */}
    </>
  );
}
