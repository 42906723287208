import { createSlice } from "@reduxjs/toolkit";

const intialSubscriptionDetails = {
  lennarSubscriptionDetails: {},
  providerDetails: {},
  providerLoader: false,
  lennarPropertyList: {},
  lennarLoader: false,
  cardList: {},
  cardLoader: false,
  totalAmountDetails: {},
  totalAmountLoader: false,
};

const lennarSubscription = createSlice({
  name: "logTableDetails",
  initialState: intialSubscriptionDetails,
  reducers: {
    addCard(state, action) {
      state.lennarLoader = true;
    },
    setCardDetails(state, action) {
      state.lennarSubscriptionDetails = action.payload;
      state.lennarLoader = false;
    },
    userCardList(state, action) {
      state.cardLoader = true;
    },
    fetchUserCardDetails(state, action) {
      state.cardList = action.payload;
      state.cardLoader = false;
    },
    getProviderDetails(state, action) {
      state.providerLoader = true;
    },
    fetchProviderDetails(state, action) {
      state.providerDetails = action.payload;
      state.providerLoader = false;
    },
    getPropertyDetails(state, action) {
      state.lennarLoader = true;
    },
    fetchPropertyDetails(state, action) {
      state.lennarPropertyList = action.payload;
      state.lennarLoader = false;
    },
    getTotalAmountDetails(state, action) {
      state.totalAmountLoader = true;
    },
    fetchTotalAmountDetails(state, action) {
      state.totalAmountDetails = action.payload;
      state.totalAmountLoader = false;
    },
  },
});
export const lennarSubscriptionAction = lennarSubscription.actions;
export default lennarSubscription.reducer;
