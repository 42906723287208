import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Grid, Typography } from "@mui/material";

const LocationInfoTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );

  const { viewDetailsPropertyData: viewProperty } = useSelector(
    (state) => state?.properties
  );

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Row>
              <Col className="label-text">
                <Typography>Zip Code: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.LocationInfo?.[
                    "Zip Code"
                  ]?.trim() || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Zip +4: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.LocationInfo?.[
                    "Zip +4"
                  ]?.trim() || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Carrier Route: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.LocationInfo?.[
                    "Carrier Route"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>City: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>{viewProperty?.city || "-"}</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Country: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.LocationInfo?.["County"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>State: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>{viewProperty?.state || "-"}</Typography>
              </Col>
            </Row>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Row>
              <Col className="label-text">
                <Typography>Tract Number: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.LocationInfo?.[
                    "Tract Number"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Census Tract Number:</Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.LocationInfo?.[
                    "Census Tract Number"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LocationInfoTab;
