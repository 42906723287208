import React, { useEffect, useState } from "react";

import { Button, Typography } from "@mui/material";
import AlertDialog from "../../common/AlertDialog";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { useDispatch, useSelector } from "react-redux";
import { getObjLength } from "../../helpers/utils";
import WaiverDocumentFormModal from "../../for-service-provider/WaiverDocumentFormModal";
import TerminateDocumentFormModal from "../../for-service-provider/TerminateDocumentFormModal";
import { requestDocumentsAction } from "../../../redux/slices/admin/requestDocuments/requestDocumentsSlice";
import { Loader } from "../../common/loader";

const RequestPropertyDocuments = ({
  alertBox,
  handleClose,
  requestType,
  requestDocumentAction,
  alreadyRequested,
  selectedProperty,
  setSelectedProperty,
  callListAPI,
}) => {
  const dispatch = useDispatch();

  const [editRequestedDocumnetPopup, setRequestedDocumnetPopup] =
    useState(false);

  // store data
  const { requestedDocumentDetails, requestedDocumentLoader } = useSelector(
    (state) => state.requestDocuments
  );

  const handleFormDocumentSubmitBtn = (payload, type) => {
    console.log(type, "payload ==>", payload);
    const docType = requestType?.includes("termination")
      ? "terminate"
      : "waiver";
    dispatch(
      requestDocumentsAction.requestedDocument({
        url: `admin/property/${docType}-document`,
        data: {
          type: requestType,
          property_id: selectedProperty?.id,
          ...payload,
        },
        token: true,
      })
    );
  };

  return (
    <>
      {requestDocumentAction && !alreadyRequested ? (
        <>
          {/* open form based on action */}
          {requestType === "waiver" ? (
            <WaiverDocumentFormModal
              open={requestDocumentAction}
              selectedItem={selectedProperty}
              setSelectedProperty={setSelectedProperty}
              callListAPI={callListAPI}
              loader={false}
              handleClose={() => {
                dispatch(
                  serviceProviderSignupAction?.clearValidateRequestDetails()
                );
                setRequestedDocumnetPopup();
                handleClose();
              }}
              onSubmit={(payload) => handleFormDocumentSubmitBtn(payload)}
            />
          ) : requestType === "termination" ? (
            <TerminateDocumentFormModal
              open={requestDocumentAction}
              callListAPI={callListAPI}
              selectedItem={selectedProperty}
              setSelectedProperty={setSelectedProperty}
              loader={false}
              handleClose={() => {
                dispatch(
                  serviceProviderSignupAction?.clearValidateRequestDetails()
                );
                setRequestedDocumnetPopup();
                handleClose();
              }}
              onSubmit={(payload) => handleFormDocumentSubmitBtn(payload)}
            />
          ) : null}
        </>
      ) : null}

      <AlertDialog
        type={selectedProperty?.status === "Terminated" ? "Error" : "Warning"}
        openPopup={alertBox}
        onClose={true}
        onCloseFun={handleClose}
        mesage={
          <>
            {selectedProperty?.status === "Terminated" ? (
              <p className="fw-500">
                The property for which you are requesting a{" "}
                <span className="text-capitalize">{requestType}</span> has
                already been terminated
              </p>
            ) : (
              <p className="fw-500">
                You cannot process the{" "}
                <span className="text-capitalize">{requestType}</span> action
                for this property until the property status is Active.
              </p>
            )}
          </>
        }
        loader={false}
        buttons={
          <>
            <Button
              variant="link"
              className={`popup_Btn ${
                selectedProperty?.status === "Terminated"
                  ? "error_popup_btn"
                  : "success_popup_btn"
              }`}
              onClick={handleClose}
            >
              ok, close
            </Button>
          </>
        }
        footer={false}
      />

      <AlertDialog
        type="Error"
        openPopup={alreadyRequested}
        onClose={true}
        onCloseFun={handleClose}
        mesage={
          "The request for accessing this document has already in process. you must cancel or wait for the user to accept or reject the current document request before making another request."
        }
        loader={false}
        buttons={
          <>
            <Button
              variant="link"
              className="popup_Btn error_popup_btn"
              onClick={handleClose}
            >
              ok, close
            </Button>
          </>
        }
        footer={false}
      />

      {/* {getObjLength(requestedDocumentDetails) ? (
        <AlertDialog
          type={requestedDocumentDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (requestedDocumentDetails?.success) {
              setSelectedProperty(null);
              handleClose(false);
              callListAPI();
            }
            dispatch(requestDocumentsAction?.setRequestedDocumentDetails({}));
          }}
          mesage={
            requestedDocumentDetails?.success
              ? requestedDocumentDetails?.message
              : requestedDocumentDetails?.data?.message
          }
          footer={false}
        />
      ) : null} */}

      <Loader open={requestedDocumentLoader} />
    </>
  );
};

export default RequestPropertyDocuments;
