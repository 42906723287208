import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "react-bootstrap";

const PropertyListing = ({ data, selectProperty, handleToggle = () => {} }) => {
  const [checked, setChecked] = useState(false);
  return (
    <Box sx={{ flexGrow: 1 }} mt={2} className="propertyList">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={5}>
          {data?.address}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={5}>
          {data?.property_details?.OwnerInfo?.["Owner Name"]}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          {!checked ? (
            <Button
              className="popup_Btn text-center success_popup_btn"
              onClick={() => {
                selectProperty(data);
                handleToggle();
                setChecked(true);
              }}
            >
              Select
            </Button>
          ) : (
            <Button className="popup_Btn text-center other_popup_btn cursor-disable">
              Select
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PropertyListing;
