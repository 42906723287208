import { put, takeLatest, call } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { getAPIPayload } from "../../../apis/getApi";
import { serviceProviderSignupAction } from "../../slices/serviceProvider/SPSignUpSlice";

export function* getUserSignupSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchSuccess(response));
}

export function* verifyotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchOtpData(response));
}

export function* resendotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchNewOtpData(response));
}

export function* verifyEmailotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchEmailOtpData(response));
}

export function* resendEmailotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchNewEmailOtpData(response));
}

export function* getProfessionalsListSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchProfessionalsData(response));
}

export function* getProfessionalsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(serviceProviderSignupAction.fetchProfessionalsSuccess(response));
}

export function* getRegisterFaceSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(serviceProviderSignupAction.fetchRegisterFace(response));
}

export function* getRegisterSignatureSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(serviceProviderSignupAction.fetchRegisterSignature(response));
}

export function* getRequestAccessDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(serviceProviderSignupAction.setRequestAccessDetails(response));
}

export function* getValidateRequestDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(serviceProviderSignupAction.setValidateRequestDetails(response));
}

export function* getWelcomeEmailDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(serviceProviderSignupAction.sendWelcomeEmailDetails(response));
}

export function* watchSignupServiceProviderAsync() {
  yield takeLatest(serviceProviderSignupAction.spSignupData, getUserSignupSaga);
  yield takeLatest(serviceProviderSignupAction.spVerifyOTP, verifyotpSaga);
  yield takeLatest(serviceProviderSignupAction.resedOTP, resendotpSaga);
  yield takeLatest(
    serviceProviderSignupAction.spVerifyEmailOTP,
    verifyEmailotpSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.resedEmailOTP,
    resendEmailotpSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.getProfessionals,
    getProfessionalsListSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.spProfessionalsData,
    getProfessionalsSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.getRegisterFace,
    getRegisterFaceSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.getRegisterSignature,
    getRegisterSignatureSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.getRequestAccessDetails,
    getRequestAccessDetailsSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.getValidateRequestDetails,
    getValidateRequestDetailsSaga
  );
  yield takeLatest(
    serviceProviderSignupAction.getSendWelcomeEmailDetails,
    getWelcomeEmailDetailsSaga
  );
}
