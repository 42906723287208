import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme, useMediaQuery } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const DrawerFromBottom = ({ 
  open, 
  onClose, 
  children, 
  width = 'auto',
  height = "auto",
  anchor = 'bottom',
  drawerUponModal= false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  const drawerStyle = {
    width: isMobile ? '100%' : isTablet ? '90%' : width,
    height: isMobile ? '100%' : "auto",
    maxHeight: isMobile ? '100vh' : height,
    right: isMobile ? 0 : isTablet ? '5%' : '20px',
    left: isMobile ? 0 : isTablet ? '5%' : 'auto',
    mx: isMobile ? 0 : isTablet ? '0' : '20px',
    maxWidth: isMobile ? '100%' : isTablet ? '90%' : 'calc(100% - 40px)',
    borderTopLeftRadius: !isMobile && anchor === 'bottom' ? 16 : 0,
    borderTopRightRadius: !isMobile && anchor === 'bottom' ? 16 : 0,
    boxShadow: !isMobile && anchor === 'bottom' 
      ? '-4px 10px 45px rgba(0, 0, 0, 0.5)'
      : !isMobile && anchor === 'right'
      ? '-4px 0px 10px rgba(0, 0, 0, 0.1)'
      : isMobile
      ? 'none'
      : '0px 4px 10px rgba(0, 0, 0, 0.1)',
    '& .MuiPaper-root': {
      overflowY: 'auto',
      zIndex:99999,
    },
  };

  const backdropStyle = {
    backgroundColor: isMobile || isTablet ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
  };

  const contentStyle = {
    backgroundColor: 'white',
    height: isMobile ? '100%' : 'auto',
    overflowY: 'auto',
    zIndex:"99999"
  };

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: drawerStyle,
      }}
      ModalProps={{
        BackdropProps: {
          sx: backdropStyle,
        },
      }}
      className={drawerUponModal ? "card-details-drawer-wrapper" : ""}
    >
      <Box sx={contentStyle}>
        {children}
      </Box>
    </Drawer>
  );
};

export default DrawerFromBottom;