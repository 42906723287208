import { useRef } from "react";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import Authentication from "./Authentication";
import { clearLocalStoarge, encryptData, getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import SecureConnectionTemplate from "../../common/secureConnectionTemplate";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { serviceProviderSignInAction } from "../../../redux/slices/serviceProvider/SPSignINSlice";
import { useTheme, useMediaQuery } from '@mui/material';
import "../../login/login.scss";
import "../../user-signup/signup.scss";
import { secretKeyEncrypt } from "../../helpers/constants";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  password: Yup.string("Please enter your Password.")
    .required("Password is required")
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const servicerLoginFormRef = useRef();
  const captchaRef = useRef();

  const [fromErr, setFormErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [authScreen, setAuthScreen] = useState(false);
  const [isBiometric, setIsBiometric] = useState(false);
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));

  const { signInLoader, signInData } = useSelector(
    (state) => state.serviceProviderSignin
  );

  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );

  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values) => {
    console.log("Form data", values);
    clearLocalStoarge();
    dispatch(
      serviceProviderSignInAction.userSignInData({
        url: "service-provider/login",
        data: values,
      })
    );
  };

  console.log("signInData==>", signInData);

  useEffect(() => {
    // clear success-error captcha data
    dispatch(userSigupAction.setreCAPTCHA({}));

    if (getObjLength(signInData)) {
      if (signInData?.success) {
        localStorage.removeItem("CAPTCHA_Validate");
        if (signInData?.data?.status === "pending") {
          dispatch(serviceProviderSignInAction.resetSPSignINState());
          navigate("/create-account", { replace: false });
        }

        setIsBiometric(signInData?.data?.biometric_registered);
        setAuthScreen(true);
        setFormErr("");
        captchaRef?.current?.reset();
        dispatch(userSigupAction.setreCAPTCHA({}));
        localStorage.removeItem("CAPTCHA_Validate");
      } else {
        setAuthScreen(false);
        setFormErr(signInData?.data?.message);
        dispatch(userSigupAction.setreCAPTCHA({}));
        captchaRef?.current?.reset();
        servicerLoginFormRef?.current?.setSubmitting(false);
        localStorage.removeItem("CAPTCHA_Validate");
      }
    }
    if (!localStorage.getItem("authToken")) {
      localStorage?.removeItem("roles");
    }
  }, [dispatch, navigate, signInData]);

  // useEffect(() => {
  //   if (
  //     getObjLength(checkReCAPTCHADetails) &&
  //     checkReCAPTCHADetails?.data?.success &&
  //     formValues
  //   ) {
  //     if (localStorage.getItem("CAPTCHA_Validate")) {
  //       onSubmit(formValues);
  //     }
  //   }
  // }, [checkReCAPTCHADetails]);

  function login() {
    return (
      <>
        {!authScreen ? (
          <Box className="accountBoxWrapper Form_Wrapper">
            <Typography align="center" variant="h3">
              Service Provider Log-in
            </Typography>
            <Typography
              align="center"
              variant="body1"
              sx={{ marginBottom: "1rem !important" }}
            >
              To access your account, please enter your provided credentials
              below
            </Typography>
            <Box>
              <Formik
                enableReinitialize
                validateOnChange
                innerRef={servicerLoginFormRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  // if (!recaptchaToken) {
                  //   return;
                  // }

                  // localStorage.setItem("CAPTCHA_Validate", 1);
                  // dispatch(
                  //   userSigupAction.checkreCAPTCHA({
                  //     url: `validate-captcha?token=${recaptchaToken}`,
                  //     data: {},
                  //   })
                  // );
                  // setFormValues(values);
                  const payload = JSON.parse(JSON.stringify(values));
                  payload.password = values?.password?.trim();
                  const encryptedData = encryptData(payload?.password, secretKeyEncrypt.toString());
                  payload.password = encryptedData;
                  payload.role = "service-provider";
                  onSubmit(payload);
                }}
              >
                {({ values, setFieldValue, touched, errors, isSubmitting }) => {
                  return (
                    <Form>
                      <Grid
                        container
                        spacing={2}
                        textAlign={"center"}
                        alignItems={"center"}
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            className="textField "
                            // id="outlined-basic"
                            label="Email address"
                            name="email"
                            variant="outlined"
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#0D120B",
                              },
                              shrink: true,
                            }}
                            placeholder={"Email address"}
                            value={values?.email}
                            onChange={(event) => {
                              setFieldValue("email", event.target.value);
                              setFormErr("");
                            }}
                            helperText={touched.email && errors.email}
                            error={touched.email && Boolean(errors.email)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl className="textField">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                              className="textfield"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              // sx={{ width: "320px" }}
                              name="password"
                              inputProps={{
                                autoComplete: "new-password",
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(setShowPassword)
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              value={values?.password}
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  "password",
                                  event.target.value?.trim()
                                );
                              }}
                              helperText={touched.password && errors.password}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                            />
                            {touched.password && Boolean(errors.password) && (
                              <FormHelperText
                                className="d-block d-sm-none"
                                sx={{
                                  color: "rgb(211, 47, 47) !important",
                                  fontSize: "12px !important",
                                  maxWidth: "100% !important",
                                  mb: "5px !important",
                                }}
                              >
                                {touched.password && errors.password}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {touched.password && Boolean(errors.password) && (
                            <FormHelperText
                              className="d-none d-sm-block"
                              sx={{
                                color: "rgb(211, 47, 47) !important",
                                fontSize: "12px !important",
                                px: 0.5,
                                mb: "5px !important",
                              }}
                            >
                              {touched.password && errors.password}
                            </FormHelperText>
                          )}
                        </Grid>
                        {/* <Grid
                          item
                          xs={12}
                          md={12}
                          className="d-flex justify-content-center my-2"
                        >
                          <FormControl className="textField captcha-field">
                            <ReCAPTCHA
                              sitekey={RECAPTCHA_SITE_KEY}
                              onChange={handleCaptchaChange}
                              className="g-recaptcha"
                              ref={captchaRef}
                            />
                          </FormControl>
                        </Grid>*/}
                        {fromErr?.length > 0 && (
                          <p className="text-danger text-center w-100 mb-3">
                            {fromErr}
                          </p>
                        )}
                      </Grid>
                      <div className="text-center stepBtn mt-4">
                        <Button
                          className="login_button"
                          type="submit"
                          variant="contained"
                          //   onClick={loggedIn}
                        >
                          Log In Now
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className="text-center resetPwd mt-3">
                <Link
                  onClick={() => {
                    localStorage.removeItem("CAPTCHA_Validate");
                    dispatch(serviceProviderSignInAction.clearSigninData());
                    setFormErr("");
                  }}
                  to="/reset-password"
                >
                  Reset your password
                </Link>
              </div>
            </Box>
          </Box>
        ) : (
          <Authentication
            isBiometric={isBiometric}
            handleBackBtn={() => {
              setAuthScreen(false);
              dispatch(serviceProviderSignInAction.resetSPSignINState({}));
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Grid container className="d-flex justify-content-center">
        <Grid
          sx={{ margin: "0 auto" }}
          xs={12}
          sm={12}
          // mdOffset={2}
          md={10}
          lgOffset={2}
          lg={8}
          xlOffset={2}
          xl={8}
        >
          <SecureConnectionTemplate isPadding={isPadding} content={login()} footer={true} />
        </Grid>
      </Grid>

      {getObjLength(checkReCAPTCHADetails) &&
        !checkReCAPTCHADetails?.data?.success && (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userSigupAction.setreCAPTCHA({}));
            }}
            mesage={checkReCAPTCHADetails?.data?.message}
            footer={false}
          />
        )}
      <Loader open={signInLoader || checkReCAPTCHALoader} />
    </>
  );
};

export default Login;
