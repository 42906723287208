/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

import Cropper from "react-easy-crop";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import getCroppedImg from "./cropImage";
import { Loader } from "../common/loader";
import ImageCapture from "./image-capture";
import { getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import ContcatAdminSupport from "../common/ContcatAdminSupport";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import useCloseCameraStream from "../hooks/useCloseCameraStream";

const PhotoIdScreen = (props) => {
  const dispatch = useDispatch();

  const [btnCLicked, setBtnClicked] = useState(false);
  const [picture, setPicture] = useState(undefined);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [webcamAccess, setWebcamAccess] = useState(true);
  const [openPopup, setOpenPopup] = useState(true);
  const [faceRegisteredErr, setFaceRegisteredErr] = useState("");
  const [isCameraResponseChecked, setIsCameraResponseChecked] = useState(false);
  const { updateCameraState } = useCloseCameraStream();

  const webcamRef = useRef(null);

  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );
  const faceRegistered = useSelector(
    (state) => state.signUpUser.faceRegistered
  );

  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  const checkMediaAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setOpenPopup(false);
        setWebcamAccess(false);
        setIsCameraResponseChecked(true);
        updateCameraState(stream);
      }
    } catch (err) {
      if (err.name === "NotAllowedError") {
        setWebcamAccess(true);
        setIsCameraResponseChecked(true);
      } else if (
        err.name === "NotFoundError" ||
        err.name === "NotReadableError"
      ) {
        setWebcamAccess(true);
        setIsCameraResponseChecked(true);
      } else {
        setWebcamAccess(true);
        setIsCameraResponseChecked(true);
        console.log("Error accessing camera:", err);
      }
    }
    // navigator.mediaDevices
    //   .enumerateDevices()
    //   .then((devices) =>
    //     devices.forEach((device) => {
    //       if (device.kind === "videoinput") {
    //         setOpenPopup(false);
    //         setWebcamAccess(false);
    //         console.log("YY Has Video Access");
    //       }
    //     })
    //   )
    //   .catch((err) => {
    //     if (err) {
    //       console.log("error occurred", err);
    //       setOpenPopup(true);
    //     }
    //   });
  };

  useEffect(() => {
    checkMediaAccess();
  }, []);
  useEffect(() => {
    console.log("faceRegistered==>>", faceRegistered);
    handleClose();
  }, [faceRegistered]);

  const verifyImg = (e, action, image) => {
    console.log("action taken==>", action);
    setPicture(image);
    props.onBtnClick(e, action);
    if (action === "back") {
      setBtnClicked(true);
      // props.HideSkipBtn(false);
    } else {
      setBtnClicked(false);
      // props.HideSkipBtn(true);
    }
    setCroppedImage(null);
    setFaceRegisteredErr("");
  };

  const capture = () => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    verifyImg("", "back", pictureSrc);
  };

  const skipBiometric = () => {
    dispatch(
      userSigupAction.skipRegisterFaceStep({
        url: "skip-face",
        data: {
          user_id: getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession,
        },
      })
    );
    setOpenPopup(false);
    localStorage.setItem("currentStep", "6");
    localStorage.removeItem("isOwnerVerified");
    props.onBtnClick();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log("sss==>", croppedArea, croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(picture, croppedAreaPixels);
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  });

  const handleClose = () => {
    if (!faceRegistered?.success) {
      setFaceRegisteredErr(faceRegistered?.data?.message);
    }
    setOpen(false);
    if (faceRegistered?.success) {
      // SetSubmit(true);
      props.onBtnClick();
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const finalCroppedImage = () => {
    console.log("final image==>.", croppedImage);

    // var reader = new FileReader();
    // reader.readAsDataURL(croppedImage);
    // reader.onloadend = function () {
    //   var base64data = reader.result;
    //   console.log("base64data==>>", base64data);
    // };

    // Set the Width and Height you want your resized image to be
    var width = 1920;
    var height = 1080;

    var canvas = document.createElement("canvas"); // Dynamically Create a Canvas Element
    canvas.width = width; // Set the width of the Canvas
    canvas.height = height; // Set the height of the Canvas
    var ctx = canvas.getContext("2d"); // Get the "context" of the canvas
    var img = document.getElementById("finalImage"); // The id of your image container
    ctx.drawImage(img, 0, 0, width, height); // Draw your image to the canvas

    var jpegFile = canvas.toDataURL("image/jpeg");
    console.log("img==>>>", jpegFile);

    let data = {
      face_image: jpegFile,
      user_id: getObjLength(crerateAccData)
        ? crerateAccData.data?.user_id
        : userIdFromSession,
      // crerateAccData?.data?.user_id,
      step: 6,
      step_key: "REGISTER_FACE",
    };

    dispatch(
      userSigupAction.getRegisterFace({
        url: "customer/register-face",
        data: data,
      })
    );
    handleToggle();
  };

  return (
    <>
      <Box className="accountBoxWrapper">
        <Typography align="center" variant="h3">
          Place your face in the center
        </Typography>
        <Typography align="center" variant="body1">
          Make sure you’re in a properly lit room, and you don’t wear
          sunglasses, hats, etc
        </Typography>
        {!btnCLicked && isCameraResponseChecked ? (
          <>
            <ImageCapture picture={picture} webcamRef={webcamRef} />
          </>
        ) : (
          <>
            {croppedImage ? (
              <Box>
                <div className="cropImg_center">
                  <img
                    // style={{ maxWidth: "100%" }}
                    src={croppedImage}
                    alt="croppedImage"
                    id="finalImage"
                  />
                </div>
                {faceRegisteredErr?.length > 0 && (
                  <>
                    <p className="text-danger text-center p-3">
                      {faceRegisteredErr}
                    </p>
                    {props.errorPopup && (
                      <ContcatAdminSupport
                        message={faceRegisteredErr}
                        errorPopup={props.errorPopup}
                        reduceErrorCount={props.reduceErrorCount}
                      />
                    )}
                  </>
                )}
              </Box>
            ) : (
              <>
                <Box p={2} className="cropContainer">
                  <Cropper
                    image={picture}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <Box
        className={`d-flex ${
          // btnCLicked ? "justify-content-between" :
          "justify-content-end"
        } align-items-center mx-4 mb-5`}
      >
        {btnCLicked && (
          <>
            <Button
              className="popup_Btn mx-2 other_popup_btn text-blue"
              onClick={(e) => verifyImg(e, "retake")}
            >
              retake
            </Button>
          </>
        )}
        {!btnCLicked ? (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="popup_Btn success_popup_btn"
              variant="contained"
            >
              TAKE PHOTO
            </Button>
          </>
        ) : croppedImage ? (
          <>
            <Button
              onClick={finalCroppedImage}
              className="popup_Btn success_popup_btn"
              variant="contained"
            >
              Verify Photo
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={showCroppedImage}
              className="popup_Btn success_popup_btn"
              variant="contained"
            >
              Crop Photo
            </Button>
          </>
        )}
      </Box>
      {openPopup && (
        <AlertDialog
          type="Info"
          openPopup={openPopup}
          onClose={true}
          onCloseFun={() => setOpenPopup(false)}
          mesage={
            <>
              <p className="mt-3">
                To maximize the security experience we would like to use your
                facial recognition to lock and unlock your profile. This adds a
                whole new layer of protection which requires the use of your
                local camera. You can always enable or disable this feature in
                your profile.
              </p>
              {/* <Stack
                direction="row"
                spacing={4}
                justifyContent="center"
                mt={2}
                mb={2}
              >
                <Box
                  onClick={() =>
                    window.open(`https://play.google.com/store/apps`, "_blank")
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={PlaystoreSVG}
                    alt="playstore"
                    style={{ width: "50px" }}
                  />
                </Box>
                <Box
                  onClick={() =>
                    window.open(`https://www.apple.com/app-store/`, "_blank")
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={AppstoreSVG}
                    alt="appstore"
                    style={{ width: "50px" }}
                  />
                </Box>
              </Stack>
              <p className="m-1">
                If you still wish to proceed without this protection click here.
                You can always update your biometrics after creating your
                profile.
              </p> */}
            </>
          }
          buttons={
            <Box>
              <Button
                className="popup_Btn other_popup_btn m-2"
                variant="outlined"
                onClick={() => setOpenPopup(false)}
              >
                Ok, Close
              </Button>
              <Button
                className="m-2 popup_Btn success_popup_btn "
                onClick={skipBiometric}
              >
                Skip anyway
              </Button>
            </Box>
          }
        />
      )}
      <Loader open={open} />
    </>
  );
};

export default PhotoIdScreen;
