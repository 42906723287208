import { createSlice } from "@reduxjs/toolkit";
import {
  decryptPayload,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const intialSpouseState = {
  spouseList: [],
  selectSpouseList: [],
  isSelectSpouse: false,
  addSpouseDetails: {},
  deleteSpouseDetails: {},
  spouseListLoader: false,
  professionalTypeLoader: false,
};

const spouse = createSlice({
  name: "propertiesDetails",
  initialState: intialSpouseState,
  reducers: {
    resetSpouseState(state, action) {
      state.spouseList = {};
      state.spouseListLoader = false;
    },
    clearAddSpouseDetailsData(state, action) {
      state.addSpouseDetails = {};
      state.spouseListLoader = false;
    },
    addSpouseDetails(state, action) {
      state.spouseListLoader = true;
    },
    editSpouseDetails(state, action) {
      state.spouseListLoader = true;
    },
    deleteSpouseDetails(state, action) {
      state.spouseListLoader = true;
    },
    setSpouseDetails(state, action) {
      state.addSpouseDetails = action.payload;
      state.spouseListLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // spouceDetails: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Spouse Added", data);
      }
    },
    setEditedSpouseDetails(state, action) {
      state.addSpouseDetails = action.payload;
      state.spouseListLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // spouceDetails: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Spouse Edited", data);
      }
    },
    setDeletedSpouseDetails(state, action) {
      state.deleteSpouseDetails = action.payload;
      state.spouseListLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // deletedSpouce: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Spouce Deleted", data);
      }
    },
    fetchSpouseListData(state, action) {
      state.spouseListLoader = true;
      state.isParentList = action.payload?.parentList;
    },
    setSpouseListData(state, action) {
      if (state.isParentList) {
        state.selectSpouseList = action.payload;
        state.isParentList = false;
      } else {
        state.spouseList = action.payload;
      }
      state.spouseListLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // data: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Allow Access", data);
      }
    },
    clearData(state, action) {
      state.deleteSpouseDetails = {};
      state.spouseListLoader = false;
    },
  },
});
export const spouseAction = spouse.actions;
export default spouse.reducer;
