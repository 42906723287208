import React, { useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import DialogBox from "../common/DialogBox";
import { ROLES } from "../helpers/constants";
import { decryptPayload } from "../helpers/utils";
import addTagsIcon from "../../assets/documents/add_circle.svg";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";

const EditDocument = ({
  openPopup,
  setOpenPopup,
  closeDialogBox,
  docName,
  tagsList,
  docId,
  openUploadDocPopup,
}) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([...tagsList]);
  const [editedDocName, setEditedDocName] = useState("");
  const [renameMsg, setRenameMsg] = useState("");
  const [enableRename, setEnableRename] = useState(true);

  function modalTitle() {
    return (
      <Box className="accountBoxWrapper text-start">
        <Typography variant="h3">Edit document</Typography>
        <Typography
          variant="body1"
          className="m-0"
          sx={{ maxWidth: "100% !important" }}
        >
          Edit the selected document and add more tags to easy search.
        </Typography>
      </Box>
    );
  }
  function modalContent() {
    return (
      <>
        <Box className="bg-white " py={5} m={0.5}>
          <Formik
            enableReinitialize
            initialValues={{ fileName: docName }}
            onSubmit={renameFile}
          >
            {({ values, setFieldValue, resetForm }) => {
              return (
                <Form>
                  <Box
                    className="d-flex align-items-center justify-content-between"
                    px={2}
                  >
                    <TextField
                      disabled={enableRename}
                      className="w-75"
                      defaultValue={docName}
                      name="fileName"
                      onChange={(event) => {
                        setFieldValue("fileName", event.target.value);
                      }}
                      onBlur={(event) => {
                        renameFile(event.target.value);
                      }}
                      value={values?.inputTag}
                      placeholder="File Name"
                      variant="outlined"
                    />
                    {enableRename ? (
                      <Button
                        //   type="submit"
                        className="edit_popup_btn d-none d-sm-block"
                        variant="outlined"
                        size="large"
                        onClick={() => setEnableRename(false)}
                      >
                        Rename
                      </Button>
                    ) : (
                      <Button
                        //   type="submit"
                        className="edit_popup_btn d-none d-sm-block"
                        variant="outlined"
                        size="large"
                        onClick={() => setEnableRename(true)}
                      >
                        Cancel
                      </Button>
                    )}
                    {enableRename ? (
                      <IconButton
                        className="d-block d-sm-none"
                        onClick={() => setEnableRename(false)}
                      >
                        <BorderColorOutlinedIcon sx={{ color: "#15be53" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="d-block d-sm-none"
                        onClick={() => setEnableRename(true)}
                      >
                        <ClearIcon sx={{ color: "#15be53" }} />
                      </IconButton>
                    )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
          {/* {renameMsg.length > 0 && (
            <p
              style={{ color: "#15be53", fontSize: "16px" }}
              className=" p-3 mb-0 text-center"
            >
              {renameMsg}
            </p>
          )} */}
        </Box>
        <Box className="bg-white" p={0.6} m={0.5}>
          <Formik
            enableReinitialize
            initialValues={{ inputTag: "" }}
            onSubmit={submitTags}
          >
            {({ values, setFieldValue, resetForm }) => {
              return (
                <Form>
                  <Box px={2}>
                    <OutlinedInput
                      fullWidth
                      name="inputTag"
                      onChange={(event) => {
                        setFieldValue("inputTag", event.target.value);
                      }}
                      id="outlined-adornment-weight"
                      inputProps={<TextField />}
                      value={values?.inputTag}
                      placeholder="Add tags here"
                      endAdornment={
                        <InputAdornment position="end">
                          <Button type="submit">
                            <img src={addTagsIcon} alt="addTags" />
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
          <Box pt={2} sx={{ textAlign: "start" }}>
            {tags?.map((tag, i) => (
              <Chip
                key={i}
                sx={{
                  backgroundColor: "#C4D9CC",
                  margin: 1,
                }}
                className="fw-500 text-uppercase"
                label={tag}
                onDelete={() => handleDelete(i)}
              />
            ))}
          </Box>
        </Box>
      </>
    );
  }
  function modalFooter() {
    return (
      <Box className="w-100 d-flex align-items-center justify-content-between m-3">
        <Button
          onClick={() => {
            openUploadDocPopup(true);
            setOpenPopup(false);
          }}
          type="submit"
          className="edit_popup_btn  d-none d-sm-block"
          variant="outlined"
        >
          Upload new version
        </Button>
        {/* <IconButton
          className="uploadDocs d-block d-sm-none"
          sx={{ borderRadius: "50%" }}
          onClick={() => {
            openUploadDocPopup(true);
            setOpenPopup(false);
          }}
        >
          <UploadFileIcon sx={{ color: "#15be53" }} />
        </IconButton> */}
        <Button
          className="uploadDocs d-flex d-sm-none m-0 p-2"
          variant="outlined"
          onClick={() => {
            openUploadDocPopup(true);
            setOpenPopup(false);
          }}
          startIcon={<UploadFileIcon />}
          sx={{ fontSize: "12px", color: "#15be53" }}
        >
          New Version
        </Button>

        <Button
          onClick={() => reNameDocument()}
          type="submit"
          className="next_button"
          variant="contained"
        >
          save
        </Button>
      </Box>
    );
  }
  console.log("tags==>>", tagsList);

  const renameFile = (values) => {
    console.log("filerame===:>", values);
    setEditedDocName(values);
    setRenameMsg("Document Name changed successfully.");
  };
  const submitTags = (values, { resetForm }) => {
    console.log(tags, "inputTag==>>", values);
    if (values.inputTag.length > 0) {
      if (tags) {
        setTags([...tags, values.inputTag]);
      } else {
        setTags([values.inputTag]);
      }
      resetForm({ values: "" });
      // setInputTag("");
    }
  };
  const handleDelete = (i) => {
    console.log(tags, "delete==>>", i);

    if (i > -1) {
      tags.splice(i, 1);
      setTags([...tags]);
    }
    console.log("remove tags==>>", tags);
  };

  const reNameDocument = () => {
    console.log("tags==>.", tags);
    console.log("File Name-==>>", editedDocName);
    console.log("File ID-==>>", docId);
    dispatch(
      documentsAction.editDocument({
        url:
          `${
            decryptPayload(localStorage.getItem("roles"))?.includes(
              ROLES.serviceProvider
            )
              ? "service-provider"
              : "customer"
          }/documents/` +
          docId +
          "/edit",
        data: {
          filename: editedDocName,
          tags: tags,
        },
        token: true,
      })
    );
    setOpenPopup(false);
  };
  return (
    <>
      <DialogBox
        width={"sm"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={closeDialogBox}
        dividers={true}
        title={modalTitle()}
        content={modalContent()}
        footer={modalFooter()}
      />

      {/* <Loader open={propertyListLoader} /> */}
    </>
  );
};

export default EditDocument;
