import { createSlice } from "@reduxjs/toolkit";

const intialCountyRecordersState = {
  countyRecordersLoader: false,
  recordingFeesLoader:false,
  createCountyRecorders: {},
  createRecordingFees: {},
  deleteRecordingFeesData: {},
  countyRecordersList: {},
  recordingFeesList: {},
  activateInactivateDetails: {},
  recordingFeesActiveStatus: {},
  editedPersonalDetailsData: {},
  registeredCountyListData: {},
};

const countyRecorders = createSlice({
  name: "countyRecordersDetails",
  initialState: intialCountyRecordersState,
  reducers: {
    createStatewiseRecordingFeesData: (state, action) => {
      state.recordingFeesLoader = true;
    },
    fetchStatewiseRecordingFeesData: (state, action) => {
      state.recordingFeesLoader = false;
      state.createRecordingFees = action.payload;
    },
    countyRecordersListData: (state, action) => {
      state.countyRecordersLoader = true;
    },
    fetchCountyRecordersListData: (state, action) => {
      state.countyRecordersLoader = false;
      state.countyRecordersList = action.payload;
    },
    recordingFeesListData: (state, action) => {
      console.log("called......2");
      state.recordingFeesLoader = true;
    },
    fetchRecordingFeesListData: (state, action) => {
      console.log("called......4");
      state.recordingFeesLoader = false;
      state.recordingFeesList = action.payload;
    },
    createCountyRecordersData: (state, action) => {
      state.countyRecordersLoader = true;
    },
    fetchCountyRecordersData: (state, action) => {
      state.countyRecordersLoader = false;
      state.createCountyRecorders = action.payload;
    },
    editedPersonalDetailsData: (state, action) => {
      state.countyRecordersLoader = true;
    },
    fetchEditedPersonalDetailsData: (state, action) => {
      state.countyRecordersLoader = false;
      state.editedPersonalDetailsData = action.payload;
    },
    activateInactivateCounty: (state, action) => {
      state.countyRecordersLoader = true;
    },
    fetchActivateInactivateCounty: (state, action) => {
      state.countyRecordersLoader = false;
      state.activateInactivateDetails = action.payload;
    },
    activateInactivateRecordingFee: (state, action) => {
      state.recordingFeesLoader = true;
    },
    fetchActivateInactivateFee: (state, action) => {
      state.recordingFeesLoader = false;
      state.recordingFeesActiveStatus = action.payload;
    },
    registeredCountyList: (state, action) => {
      state.countyRecordersLoader = true;
    },
    fetchRegisteredCountyList: (state, action) => {
      state.countyRecordersLoader = false;
      state.registeredCountyListData = action.payload;
    },
    deleteRecordingFees: (state, action) => {
      state.recordingFeesLoader = true;
    },
    fetchDeleteRecordingFees: (state, action) => {
      state.recordingFeesLoader = false;
      state.deleteRecordingFeesData = action.payload;
    },
    clearData: (state, action) => {
      console.log("Clear data");
      state.createRecordingFees = {};
    },
  },
});
export const countyRecordersAction = countyRecorders.actions;
export default countyRecorders.reducer;
