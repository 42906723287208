import { createSlice } from "@reduxjs/toolkit";

const intialOtherProvidersState = {
  otherProvidersLoader: false,
  createOtherProviders: {},
  otherProvidersList: {},
  activateInactivateDetails: {},
  editedPersonalDetailsData: {},
};

const otherProviders = createSlice({
  name: "otherProvidersDetails",
  initialState: intialOtherProvidersState,
  reducers: {
    otherProvidersListData: (state, action) => {
      state.otherProvidersLoader = true;
      console.log("otherProviders slice", action.payload);
    },
    fetchOtherProvidersListData: (state, action) => {
      console.log("fetchotherProviders data slice", action.payload);
      state.otherProvidersLoader = false;
      state.otherProvidersList = action.payload;
    },
    domainListData: (state, action) => {},
    fetchDomainListData: (state, action) => {
      state.domainListDataList = action.payload;
    },
    createOtherProvidersData: (state, action) => {
      state.otherProvidersLoader = true;
      console.log("otherProviders slice", action.payload);
    },
    fetchCreatedProvidersData: (state, action) => {
      console.log("fetchotherProviders data slice", action.payload);
      state.otherProvidersLoader = false;
      state.createOtherProviders = action.payload;
    },
    activateInactivateProvider: (state, action) => {
      state.otherProvidersLoader = true;
      console.log("otherProviders slice", action.payload);
    },
    fetchActivateInactivateProvider: (state, action) => {
      console.log("fetchotherProviders data slice", action.payload);
      state.otherProvidersLoader = false;
      state.activateInactivateDetails = action.payload;
    },
    editedPersonalDetailsData: (state, action) => {
      state.otherProvidersLoader = true;
      console.log("otherProviders slice", action.payload);
    },
    fetchEditedPersonalDetailsData: (state, action) => {
      console.log("fetchotherProviders data slice", action.payload);
      state.otherProvidersLoader = false;
      state.editedPersonalDetailsData = action.payload;
    },
  },
});
export const otherProvidersAction = otherProviders.actions;
export default otherProviders.reducer;
