import { createSlice } from "@reduxjs/toolkit";

const intialSeviceProvidersState = {
  seviceProvidersLoader: false,
  seviceProvidersList: {},
  activateServiceProvidersRes: {},
  editServiceProvidersRes: {},
  deleteServiceProvidersRes: {},
  actionLoader: false,
  UploadedServiceAgreementDetails: {},
  historyServiceAgreements: {},
  serviceAgreementLoader: false,
  uploadedCompanyLogoDetails: {},
  companyLogoLoader: false,
};

const seviceProviders = createSlice({
  name: "seviceProvidersDetails",
  initialState: intialSeviceProvidersState,
  reducers: {
    getSeviceProvidersData: (state, action) => {
      state.seviceProvidersLoader = true;
      console.log("seviceProviders slice", action.payload);
    },
    fetchSeviceProvidersList: (state, action) => {
      console.log("fetchseviceProviders data slice", action.payload);
      state.seviceProvidersLoader = false;
      state.seviceProvidersList = action.payload;
    },
    getHistoryAgreementsData: (state, action) => {
      state.seviceProvidersLoader = true;
    },
    fetchHistoryAgreementsList: (state, action) => {
      state.seviceProvidersLoader = false;
      state.historyServiceAgreements = action.payload;
    },
    getSeviceProvidersActivate: (state, action) => {
      state.seviceProvidersLoader = true;
      console.log("seviceProviders slice", action.payload);
    },
    fetchActivateSeviceProviders: (state, action) => {
      console.log("fetchseviceProviders data slice", action.payload);
      state.seviceProvidersLoader = false;
      state.activateServiceProvidersRes = action.payload;
    },
    getEditSeviceProvidersData: (state, action) => {
      state.actionLoader = true;
      console.log("seviceProviders slice", action.payload);
    },
    fetchEditSeviceProviders: (state, action) => {
      console.log("fetchseviceProviders data slice", action.payload);
      state.actionLoader = false;
      state.editServiceProvidersRes = action.payload;
    },
    getDeleteSeviceProvidersData: (state, action) => {
      state.actionLoader = true;
      console.log("seviceProviders slice", action.payload);
    },
    fetchDeleteSeviceProviders: (state, action) => {
      console.log("fetchseviceProviders data slice", action.payload);
      state.actionLoader = false;
      state.deleteServiceProvidersRes = action.payload;
    },
    activateDeactivateServiceProvider: (state, action) => {
      state.actionLoader = true;
      console.log("seviceProviders slice", action.payload);
    },
    fetchActivateDeactivateServiceProvider: (state, action) => {
      console.log("fetchseviceProviders data slice", action.payload);
      state.actionLoader = false;
      state.activateDeactivateServiceProvider = action.payload;
    },
    uploadServiceAgreement: (state, action) => {
      state.serviceAgreementLoader = true;
    },
    setUploadServiceAgreementDetails: (state, action) => {
      state.UploadedServiceAgreementDetails = action.payload;
      state.serviceAgreementLoader = false;
    },
    uploadCompanyLogo: (state, action) => {
      state.companyLogoLoader = true;
    },
    setUploadedCompanyLogoDetails: (state, action) => {
      state.uploadedCompanyLogoDetails = action.payload;
      state.companyLogoLoader = false;
    },
    clearData(state, action) {
      console.log("clear data==>");
      state.actionLoader = false;
      state.activateServiceProvidersRes = {};
      state.editServiceProvidersRes = {};
      state.deleteServiceProvidersRes = {};
    },
  },
});
export const seviceProvidersAction = seviceProviders.actions;
export default seviceProviders.reducer;
