import React, { useState } from "react";

import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { PER_PAGE } from "../../helpers/constants";
import usePagination from "../../common/PaginationLogic";
import { getLocalFormatedDate } from "../../helpers/utils";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";

const columns = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
  },
  {
    id: "updated_by",
    disablePadding: true,
    label: "Updated By",
  },
  {
    id: "updated_at",
    disablePadding: true,
    label: "Updated At",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props?.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const HistoryOfRequestedDocuments = ({
  open = false,
  handleClose = () => {},
}) => {
  const [page, setPage] = useState(1);

  // store data
  const { historyOfRequestedDocumentDetails, editRequestedDocumentLoader } =
    useSelector((state) => state.requestDocuments);

  const count = Math.ceil(
    historyOfRequestedDocumentDetails?.data?.length / PER_PAGE
  );
  const _DATA = usePagination(
    historyOfRequestedDocumentDetails?.data,
    PER_PAGE,
    page,
    setPage
  );

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          History of Requested Document
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <Box className="accountBoxWrapper findPropertyBoxWrapper bg-white">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead columns={columns} />
                  <TableBody>
                    {historyOfRequestedDocumentDetails?.success &&
                      historyOfRequestedDocumentDetails?.data?.map(
                        (row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell align="left">
                                {(page - 1) * PER_PAGE + index + 1}
                              </TableCell>

                              <TableCell align="left">
                                {row?.updated_by?.full_name || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {getLocalFormatedDate(row?.updated_at) || "-"}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
                {!historyOfRequestedDocumentDetails?.data?.length > 0 && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
              {historyOfRequestedDocumentDetails?.data?.length > 5 && (
                <Box id="pagination-container" className="mt-3">
                  <CustomPagination
                    count={count}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                </Box>
              )}
            </Paper>
          </Box>
          <DialogActions className="alert-modal-btn">
            <Stack direction="row" spacing={2}>
              <>
                <Button
                  className="popup_Btn success_popup_btn mt-1"
                  type="submit"
                  variant="contained"
                  onClick={handleClose}
                >
                  ok,close
                </Button>
              </>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Loader open={editRequestedDocumentLoader} />
    </>
  );
};

export default HistoryOfRequestedDocuments;
