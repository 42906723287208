/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAction } from "../../../../redux/slices/user/userProfileSlice";
import AlertDialog from "../../../common/AlertDialog";
import { Loader } from "../../../common/loader";
import { autoFocusOnField } from "../../../helpers/utils";
import PlainAlertMessage from "../../../common/PlainAlertMessage"
import { useTheme, useMediaQuery } from '@mui/material';

const VerifyPhysicalCode = ({ row, setStannupPop, from, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('575'));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('415'));
  const optLength = 7;
  const [otp, SetOTP] = useState("");
  const [otpErr, SetOTPErr] = useState("");
  const [emptyOTPErr, SetEmptyOTPErr] = useState("");
  const [otpSucess, SetOTPSucess] = useState("");
  const dispatch = useDispatch();
  const { verifyStannupDetails, postcardLoader } = useSelector(
    (state) => state?.userProfile
  );
  const OTPFieldStyle = {
    background: "#F6F9FC",
    border: "1px solid rgba(52, 75, 91, 0.1)",
    borderRadius: "3px",
    width: isSmallMobile ? "26px" : !isMobile ? "40px" : "34px",
    height: isSmallMobile ? "30px" : "55px",
    fontWeight: "600",
    fontSize: isSmallMobile ? "14px" : "20px",
    lineHeight: "55px",
    textAlign: "center",
    color: "#0D120B",
  };

  useEffect(() => {
    console.log("data editted called==>", verifyStannupDetails);
    if (Object.keys(verifyStannupDetails).length > 0) {
      validateOTPResponse();
    }
  }, [verifyStannupDetails]);
  const validateOTPResponse = () => {
    if (verifyStannupDetails?.success) {
      SetOTPSucess(verifyStannupDetails?.message);
      SetOTPErr("");
      // dispatch(
      //   userProfileAction.getStannupEmailData({
      //     url: "customer/address",
      //     data: {},
      //     token: true,
      //   })
      // );
    } else {
      SetOTPSucess("");
      SetOTPErr(verifyStannupDetails?.data?.message);
      localStorage.removeItem("addressId");
    }
  };
  const handleChange = (otp) => {
    if (otp.length === optLength) {
      SetOTPErr("");
      SetEmptyOTPErr("");
    }
    // else {
    //   SetOTPErr("Please Enter Valid Token");
    // }
    console.log("otp handled==>>", otp);
    SetOTP(otp);
  };

  const otpValidate = (event) => {
    event.preventDefault();
    console.log("in otp validation", otp.length);
    if (otp.length === optLength) {
      SetOTPErr("");
      if (from === "QR") {
        dispatch(
          userProfileAction.verifyStannupData({
            url: "customer/verify-address/" + row,
            data: { security_code: otp },
            token: true,
          })
        );
      } else {
        dispatch(
          userProfileAction.verifyStannupData({
            url: "customer/verify-address/" + row.id,
            data: { security_code: otp },
            token: true,
          })
        );
      }
      autoFocusOnField();
    } else {
      SetEmptyOTPErr("Please Enter Valid Token");
    }
  };
  const closepostcardPopup = (val) => {
    SetOTPSucess("");
    SetOTPErr("");
    if (val === "success") {
      dispatch(
        userProfileAction.getStannupEmailData({
          url: "customer/address",
          data: {},
          token: true,
        })
      );
    }
    dispatch(userProfileAction.clearPostcardData());
    if (from === "QR") {
      console.log("from 3 ==>", from);
      onClose();
    }
    setStannupPop(false);
  };

  const closepostcardErrorPopup = (val) => {
    SetOTPSucess("");
    SetOTPErr("");
    SetOTP("");
    autoFocusOnField();
  };

  return (
    <>
      {otpSucess?.length === 0 && <Box >
      <Typography className="text-fix" align="center" variant="body1">
        Please enter the {optLength} digit security code we have sent to your
        address.
      </Typography>
      <form onSubmit={otpValidate}>
        <Box className="text-center otpBoxWrapper">
          <OtpInput
            className="otp mx-1"
            value={otp}
            isInputNum
            onChange={handleChange}
            numInputs={optLength}
            shouldAutoFocus
            inputStyle={OTPFieldStyle}
          />
          {emptyOTPErr?.length > 0 && (
            <Typography variant="caption" className="text-danger 1" pt={2}>
              {emptyOTPErr}
            </Typography>
          )}
          {/*
          ) : otpSucess?.length !== 0 && otpErr?.length === 0 ? (
            <Typography variant="caption" className="text-success 2" p={2}>
              {otpSucess}
            </Typography>
          ) : null} */}
          {otpErr?.length > 0 && otpSucess?.length === 0 && (
            <Typography variant="caption" className="text-danger 1" p={2}>
              {otpErr}
            </Typography>
          )}
        </Box>
        <div className="text-center stepBtn mt-5 mb-3">
          <Button
            onClick={() => closepostcardPopup()}
            className="  popup_Btn other_popup_btn m-2"
          >
            Close
          </Button>
          <Button
            type="submit"
            onClick={otpValidate}
            className="popup_Btn success_popup_btn "
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </form>
      </Box>}
      {(otpSucess?.length > 0 || otpErr?.length > 0) && (
        <>
          {otpSucess?.length > 0 && (
            <PlainAlertMessage
              type="Success"
              openPopup={otpSucess?.length > 0 ? true : false}
              onClose={false}
              onCloseFun={() => closepostcardPopup("success")}
              message={otpSucess}
              buttons={
                <Button
                  className="popup_Btn success_popup_btn "
                  onClick={() => closepostcardPopup("success")}
                >
                  Close
                </Button>
              }
            />
          ) 
          // : (
          //   <PlainAlertMessage
          //     type="Error"
          //     openPopup={otpErr?.length > 0 ? true : false}
          //     onClose={true}
          //     // onCloseFun={() => closepostcardPopup()}
          //     onCloseFun={closepostcardErrorPopup}
          //     mesage={otpErr}
          //     buttons={
          //       <Button
          //         className="popup_Btn error_popup_btn "
          //         onClick={closepostcardErrorPopup}
          //       >
          //         Close
          //       </Button>
          //     }
          //   />
          // )
          }
        </>
      )}
      <Loader open={postcardLoader} />
    </>
  );
};

export default VerifyPhysicalCode;
