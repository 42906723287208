import React, { useEffect } from "react";
import PhotoIdScreen from "../../../common/capture-image/photoIdScreen";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { serviceProviderSignInAction } from "../../../../redux/slices/serviceProvider/SPSignINSlice";
import { Loader } from "../../../common/loader";
import useCloseCameraStream from "../../../hooks/useCloseCameraStream";

export const BioMetricAuth = ({ backToAuth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isCamera, setIsCamera] = useState(false);
  const [faceErr, setFaceErr] = useState("");
  const [isCameraResponseChecked, setIsCameraResponseChecked] = useState(false);
  const { updateCameraState } = useCloseCameraStream();
  const { authLoader, userCredentials, verifyFace } = useSelector(
    (state) => state.serviceProviderSignin
  );

  const onsubmit = (image) => {
    let inputParam = {
      face_image: image,
      ...userCredentials,
    };
    console.log("on submit==>.", inputParam);
    dispatch(
      serviceProviderSignInAction.getVerifyFace({
        url: "service-provider/verify-face",
        data: inputParam,
      })
    );
  };
  const removeValidationMsg = () => {
    setFaceErr("");
  };

  const checkMediaAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setIsCamera(true);
        setIsCameraResponseChecked(true);
        updateCameraState(stream);
      }
    } catch (err) {
      if (err.name === "NotAllowedError") {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else if (
        err.name === "NotFoundError" ||
        err.name === "NotReadableError"
      ) {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
        console.log("Error accessing camera:", err);
      }
    }
    // navigator.mediaDevices.enumerateDevices().then((devices) =>
    //   devices.forEach((device) => {
    //     if (device.kind === "videoinput") {
    //       setIsCamera(true);
    //       console.log("YY Has Video Access");
    //     }
    //   })
    // );
  };

  useEffect(() => {
    checkMediaAccess();
  }, []);

  useEffect(() => {
    console.log("Face verify -->", verifyFace);
    if (Object.keys(verifyFace).length > 0) {
      const previousPath = localStorage.getItem("previousPath");
      if (Object.keys(verifyFace).length > 0) {
        let uID = verifyFace?.data?.user?.address?.user_id;
        localStorage.setItem("uID", uID);
        if (!verifyFace?.success) {
          setFaceErr(verifyFace?.data?.message);
        } else {
          dispatch(serviceProviderSignInAction.resetSPSignINState({}));
          if (
            previousPath &&
            parseInt(uID) === parseInt(localStorage.getItem("previousUserID"))
          ) {
            navigate(previousPath, { replace: false });
            localStorage.removeItem("previousPath");
          } else {
            navigate("/servicer-profile", { replace: false });
          }
          setFaceErr("");
        }
      }
    }
  }, [verifyFace]);

  return (
    <>
      <PhotoIdScreen
        biometric={true}
        backBtn={true}
        backToAuth={backToAuth}
        onsubmit={onsubmit}
        faceErr={faceErr}
        isCamera={isCamera}
        removeValidationMsg={removeValidationMsg}
        isCameraResponseChecked={isCameraResponseChecked}
      />
      <Loader open={authLoader} />
    </>
  );
};
