import { createSlice } from "@reduxjs/toolkit";

const initialSubUsersState = {
  subUsersListdata: {},
  subUsersCount: 0,
  subUserListLoader: false,
  addSubUserDetails: {},
  deleteSubUserDetails: {},
};

const subUsers = createSlice({
  name: "subUserDetails",
  initialState: initialSubUsersState,
  reducers: {
    resetSubUserListState(state, action) {
      state.subUsersListdata = {};
      state.subUserListLoader = false;
    },
    // listing
    getSubUsersListData(state, action) {
      state.subUserListLoader = true;
    },
    setSubUsersListData(state, action) {
      state.subUsersListdata = action?.payload;
      state.subUsersCount = action?.payload?.count;
      state.subUserListLoader = false;
    },
    // add / edit
    addSubUserDetails(state, action) {
      state.subUserListLoader = true;
    },
    setAddSubUserDetails(state, action) {
      state.addSubUserDetails = action.payload;
      state.subUserListLoader = false;
    },
    // delete
    deleteSubUserDetails(state, action) {
      state.subUserListLoader = true;
    },
    setDeletedAdminDetails(state, action) {
      state.deleteSubUserDetails = action.payload;
      state.subUserListLoader = false;
    },
  },
});
export const subUsersActions = subUsers.actions;
export default subUsers.reducer;
