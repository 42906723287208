import { put, call, takeLatest } from "redux-saga/effects";
import { postAPIPayload } from "../../../../apis/postApi";
import { deleteAPIPayload } from "../../../../apis/deleteApi";
import { subUsersActions } from "../../../slices/admin/subUsers/subUserSlice";

// listing
export function* getSubUsersListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(subUsersActions.setSubUsersListData(response));
}

// add / edit
export function* addSubUserDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(subUsersActions.setAddSubUserDetails(response));
}

// delete
export function* deleteSubUserDetailsSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);
  yield put(subUsersActions.setDeletedAdminDetails(response));
}

export function* watchSubUsersSagaAsync() {
  yield takeLatest(
    subUsersActions.getSubUsersListData,
    getSubUsersListDataSaga
  );
  yield takeLatest(subUsersActions.addSubUserDetails, addSubUserDetailsSaga);
  yield takeLatest(
    subUsersActions.deleteSubUserDetails,
    deleteSubUserDetailsSaga
  );
}
