import React from "react";
import PhotoIdScreen from "../../common/capture-image/photoIdScreen";
import { useState } from "react";
import { useEffect } from "react";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { useDispatch, useSelector } from "react-redux";
import { getObjLength } from "../../helpers/utils";
import { Loader } from "../../common/loader";
import { Box, Button } from "@mui/material";
import AlertDialog from "../../common/AlertDialog";
import useCloseCameraStream from "../../hooks/useCloseCameraStream";

const BiometricScreen = ({ onBtnClick }) => {
  const [isCamera, setIsCamera] = useState(false);
  const [faceErr, setFaceErr] = useState("");
  const [openPopup, setOpenPopup] = useState(true);
  const [isCameraResponseChecked, setIsCameraResponseChecked] = useState(false);
  const { updateCameraState } = useCloseCameraStream();

  const dispatch = useDispatch();
  const { spSignupLoader, createAccResponse, faceRegistered } = useSelector(
    (state) => state.serviceProviderSignup
  );
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));

  const onsubmit = (image) => {
    let inputParam = {
      face_image: image,
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
      step_key: "REGISTER_FACE",
      step: 3,
    };
    console.log("on submit==>.", inputParam);
    // handleToggle();
    dispatch(
      serviceProviderSignupAction.getRegisterFace({
        url: "service-provider/register-face",
        data: inputParam,
      })
    );
  };
  const checkMediaAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setOpenPopup(false);
        setIsCamera(true);
        setIsCameraResponseChecked(true);
        updateCameraState(stream);
      }
    } catch (err) {
      if (err.name === "NotAllowedError") {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else if (
        err.name === "NotFoundError" ||
        err.name === "NotReadableError"
      ) {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
        console.log("Error accessing camera:", err);
      }
    }
    // navigator.mediaDevices.enumerateDevices().then((devices) =>
    //   devices.forEach((device) => {
    //     if (device.kind === "videoinput") {
    //       setOpenPopup(false);
    //       setIsCamera(true);
    //       console.log("YY Has Video Access");
    //     }
    //   })
    // );
  };

  const removeValidationMsg = () => {
    setFaceErr("");
  };

  useEffect(() => {
    checkMediaAccess();
  }, []);

  useEffect(() => {
    console.log("faceRegistered==>", faceRegistered);
    if (faceRegistered?.success) {
      onBtnClick();
      setFaceErr("");
    } else {
      setFaceErr(faceRegistered?.data?.message);
    }
  }, [faceRegistered]);

  return (
    <>
      <PhotoIdScreen
        biometric={true}
        backBtn={false}
        closeBtn={true}
        backToAuth={() => onBtnClick()}
        onsubmit={onsubmit}
        faceErr={faceErr}
        isCamera={true}
        removeValidationMsg={removeValidationMsg}
        isCameraResponseChecked={isCameraResponseChecked}
      />
      <Loader open={spSignupLoader} />

      {openPopup && (
        <AlertDialog
          type="Info"
          openPopup={openPopup}
          onClose={true}
          onCloseFun={() => setOpenPopup(false)}
          mesage={
            <>
              <p className="mt-3">
                To maximize the security experience we would like to use your
                facial recognition to lock and unlock your profile. This adds a
                whole new layer of protection which requires the use of your
                local camera. You can always enable or disable this feature in
                your profile.
              </p>
            </>
          }
          buttons={
            <Box>
              <Button
                className="popup_Btn other_popup_btn m-2"
                variant="outlined"
                onClick={() => setOpenPopup(false)}
              >
                Ok, Close
              </Button>
              <Button
                className="m-2 popup_Btn success_popup_btn "
                onClick={() => {
                  setOpenPopup(false);
                  onBtnClick();
                }}
              >
                Skip anyway
              </Button>
            </Box>
          }
        />
      )}
    </>
  );
};

export default BiometricScreen;
