import * as React from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Typography } from "@mui/material";

import SendInvitationModal from "./SendInviteModal";
import SendInvitationListTable from "./SendInvitationListTable";
import { sendInvitationAction } from "../../../redux/slices/professional/send-invitation/sendInvitationSlice";

const SendInvitation = () => {
  const dispatch = useDispatch();
  const [isSendInvitationModalOpen, setIsSendInvitationModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alertMsg, setAlertMsg] = useState(false);

  // handle addProfessional modal close
  const handleClose = () => {
    setIsSendInvitationModalOpen(false);
    setAlertMsg(false);
  };

  // handle edit professional in add professional modal
  const handleEditInvitation = (item) => {
    setSelectedItem(item);
    setIsSendInvitationModalOpen(true);
  };

  //   handle add/edit save profesional
  const handleSendInvitationForm = (values) => {
    if (values?.promo_code?.length === 0) {
      delete values?.promo_code;
    }
    if (selectedItem && selectedItem?.id) {
      // handle edit dispatch
      dispatch(
        sendInvitationAction?.sendInvitationDetails({
          url: `invitations/${selectedItem?.id}/edit`,
          data: values,
          token: true,
        })
      );
    } else {
      // handle add dispatch
      dispatch(
        sendInvitationAction?.sendInvitationDetails({
          url: `invitations`,
          data: values,
          token: true,
        })
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Send Invitation
        </Typography>
        <Button
          variant="contained"
          className="success-btn"
          onClick={() => {
            setSelectedItem(null);
            setIsSendInvitationModalOpen(true);
          }}
        >
          Send Invite
        </Button>
      </Box>
      <Box>
        <SendInvitationListTable
          handleEditButton={handleEditInvitation}
          handleClose={handleClose}
          setAlertMsg={setAlertMsg}
        />
      </Box>
      <SendInvitationModal
        open={isSendInvitationModalOpen}
        handleClose={handleClose}
        selectedItem={selectedItem}
        onSubmit={handleSendInvitationForm}
        setAlertMsg={setAlertMsg}
      />
    </>
  );
};

export default SendInvitation;
