import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Grid, Typography } from "@mui/material";

const TaxComp = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <Row>
              <Col className="label-text">
                <Typography>APN: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["APN"] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Percent Improved: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Percent Improved"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Legal Decription: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Legal Description"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Grid>

        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <Row>
              <Col className="label-text">
                <Typography>Lot: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Lot"] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Tax area: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Tax Area"] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Assessed Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Assessed Value"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Percent Improved: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Percern Improved"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TaxComp;
