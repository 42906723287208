import React, { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import usePagination from "../PaginationLogic";
import CustomLinkWrapper from "../CustomLinkWrapper";
import { urlPatternValidation } from "../../helpers/utils";
import CustomPagination from "../customDesignedComponents/CustomPagination";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
  },
  {
    id: "loan_date",
    disablePadding: true,
    label: "Loan Date",
  },
  {
    id: "borrower_name",
    disablePadding: true,
    label: "Borrower Name(s)",
  },
  {
    id: "doc#",
    disablePadding: true,
    label: "Doc #",
  },
  {
    id: "loan_amount",
    disablePadding: true,
    label: "Loan Amount",
  },
  {
    id: "loan_type",
    disablePadding: true,
    label: "Loan Type",
  },
  {
    id: "lender",
    disablePadding: true,
    label: "Lender",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "#0395FF" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const FinanceComp = () => {
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );

  const PER_PAGE = 10;

  const count = Math.ceil(
    propertyData?.property_details?.hasOwnProperty("FinanceHistory")
      ? propertyData?.property_details?.FinanceHistory?.length / PER_PAGE
      : 0
  );
  const _DATA = usePagination(
    propertyData?.property_details?.hasOwnProperty("FinanceHistory")
      ? [...propertyData?.property_details?.FinanceHistory]
      : [],
    PER_PAGE,
    page,
    setPage
  );

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // staticData.jump(p);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="bg-white">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={propertyData?.property_details?.FinanceHistory?.length}
            />
            <TableBody>
              {stableSort(
                _DATA.currentData(),
                getComparator(order, orderBy)
              )?.map((row, index) => {
                console.log("row", row);
                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.address)}
                    tabIndex={-1}
                    key={row?.id}
                  >
                    <TableCell align="left">
                      {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell align="left">
                      {row?.["Loan Date"] ? row?.["Loan Date"] : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {propertyData?.property_details?.OwnerInfo?.[
                        "Owner Name"
                      ]?.trim() ||
                        propertyData?.property_details?.OwnerInfo?.[
                          "Owner Name 2"
                        ]?.trim() ||
                        "-"}
                    </TableCell>
                    <TableCell align="left">
                      {urlPatternValidation(row?.["Doc_link"]) ? (
                        <CustomLinkWrapper
                          link={row?.["Doc_link"]}
                          // link="#"
                          title={`${row?.["Doc #"]}`}
                          classes="text-primary"
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row?.["Loan Amount"] ? row?.["Loan Amount"] : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row?.["Loan Type"] ? row?.["Loan Type"] : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row?.["Lender"] ? row?.["Lender"] : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!propertyData?.property_details?.FinanceHistory?.length > 0 && (
            <Typography className="p-5 text-center" variant="h6">
              No Data Found.
            </Typography>
          )}
        </TableContainer>
      </Paper>
      {propertyData?.property_details?.FinanceHistory?.length > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default FinanceComp;
