import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "react-bootstrap";
import { useState } from "react";

const PropertyListing = ({ data, selectProperty, handleToggle }) => {
  const [checked, setChecked] = useState(false);
  return (
    <>
      <Box sx={{ flexGrow: 1 }} mt={2} className="propertyList px-1">
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={4} md={4} lg={4}>
            {data.address}
          </Grid>
          <Grid item sm={4} md={4} lg={5}>
            {data.owner}
          </Grid>
          <Grid item sm={4} md={4} lg={3}>
            {!checked ? (
              <Button
                className="popup_Btn text-center success_popup_btn"
                onClick={() => {
                  selectProperty(data);
                  handleToggle();
                  setChecked(true);
                }}
              >
                Select
              </Button>
            ) : (
              <Button className="popup_Btn text-center other_popup_btn cursor-disable">
                Select
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PropertyListing;
