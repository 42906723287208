import React from "react";

import bro from "../../assets/SEO/bro.webp";
import home from "../../assets/SEO/Home.webp";
import CommonFirstSection from "./CommonFirstSection";
import CommonThirdSection from "./CommonThirdSection";
import CommonFifthSection from "./CommonFifthSection";
import CommonSecondSection from "./CommonSecondSection";
import CommonFourthSection from "./CommonFourthSection";
import old_couple from "../../assets/SEO/Old Couple.webp";
import protected_house from "../../assets/SEO/Protected House.webp";
import old_couple_mobile from "../../assets/SEO/Old Couple Mobile.webp";
import hacker_surveiling from "../../assets/SEO/Hacker Surveiling.webp";

import "./TitleProtectionForRealEstateInvestors.scss";

const TitleProtectionForHomeOwners = () => {
  return (
    <div className="title-protection-real-estate-investors">
      <CommonFirstSection
        topHeader={"Title Theft Risks for Long-Time Homeowners"}
        topPara={`EquityProtect goes beyond monitoring to safeguard your title and equity with our patented SmartPolicy, protecting your home ownership from cybercriminals and fraud.`}
        bgImg={old_couple}
        bgImg_Mobile={old_couple_mobile}
      />

      <CommonSecondSection
        image={hacker_surveiling}
        content={[
          `You bought your dream home decades ago. Over the years, you're proud to have paid down or paid off your mortgage, providing you with significant equity. The value of your home has also increased, adding even more equity. This can make your home and title attractive to home equity theft. You wonder, "Can my home title be stolen?"`,
          `Unfortunately, thieves have become more sophisticated in their home equity fraud activities. They've become adept at creating fraudulent loan paperwork that makes it easy for them to place a lien on your home. To get at the equity, they foreclose on your home and sell it out from under you, taking your hard-earned equity with them. This makes home equity protection vital for you and your home.`,
        ]}
      />
      <CommonThirdSection
        image={home}
        title={"Why Thieves Go After Homes With High Equity"}
        content={[
          `Protecting home equity becomes more urgent as you build more equity in your home. As a long-time homeowner, you know that your equity includes the amount you've paid off on your mortgage as well as any increases in the house's value. This makes your property more attractive as a target for home equity theft.  `,
          `Thieves have to expend the same amount of time and effort stealing equity from a property with only $25,000 worth of equity as they do one with $150,000 worth of it. This makes homes with high equity a lot more attractive to would-be thieves. They can do the same amount of work crafting fraudulent paperwork with a much larger payoff.  `,
        ]}
      />
      <CommonFourthSection
        image={bro}
        title={"I Own My Home, Can My Home Title Be Stolen?"}
        content={[
          `We often hear homeowners ask, "Can my home title be stolen?" The answer is "yes, it can."  In the modern world, home equity fraud has become easier for bad actors who can create fraudulent loan paperwork to place a lien on your home without your knowledge. `,
          `  Many homeowners don't know about the lien until they get paperwork saying the fraudsters are about to foreclose on the property, forcing them to move out without their hard-earned equity. Of course, the homeowner can fight the foreclosure, but they need to prove that the lien is fraudulent, and this can be harder than it should be.  `,
        ]}
      />
      <CommonFifthSection
        image={protected_house}
        title={"Safeguard Your Hard-Earned Home Equity Investment"}
        content={[
          `EquityProtect knows that home equity protection is the smart move for all homeowners, especially those who have lived in their homes for many years and have a lot of equity in the property. It's much easier to stop someone from putting a lien on your home than trying to play catch up and having a fraudulent one removed.  `,
          `You want to make sure that no one can place a lien on your home without the need to monitor your home title constantly. You can expend a lot of time and energy going to the courthouse every few months to ensure that you still have a clean title to the property. Protecting home equity can come in a variety of forms, and you need to pursue the one that's right for you.  `,
        ]}
        title_container2={
          "Home Equity Protection That Stops the Crime Before It Even Starts"
        }
        content_containet2={
          <span>
            Home equity protection can be easier than you think. At{" "}
            <a href={"/"} target="_blank" rel="noreferrer">
              EquityProtect
            </a>
            , we help you play offense when protecting your home's title. Our
            program allows you to place a lien on your home, and no one can
            remove or place a lien ahead of you without your knowledge and help.
            We want to help protect you from home equity theft and make sure you
            never ask, "Can my home title be stolen?" because you know the
            answer is no. Contact us now to learn more. 
          </span>
        }
      />
    </div>
  );
};

export default TitleProtectionForHomeOwners;
