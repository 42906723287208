/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";

import { CardElement } from "@stripe/react-stripe-js";
import "./Style.scss";
import { Box, Typography } from "@mui/material";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      innerWidth: "100px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",

      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function CardSection({ stripeErr, validateErr }) {
  return (
    <Box className="stripeParent">
      <Typography variant="h6" className="p-3">
        Card details
      </Typography>
      <CardElement
        //   style={{ boxShadow: "0 1px 3px 0 #293a4e" }}
        options={CARD_ELEMENT_OPTIONS}
        onChange={(e) => validateErr(e)}
      />
      {stripeErr && (
        <p
          className="stripeValidation text-danger m-0 pt-3 fw-400"
          style={{ fontSize: "17px" }}
        >
          {stripeErr}
        </p>
      )}
    </Box>
  );
}
export default CardSection;
