import * as React from "react";
import { useCallback } from "react";
import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { PER_PAGE } from "../../helpers/constants";
import DocumnetListModal from "./DocumnetListModal";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
} from "../../helpers/utils";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../../admin/AddLeads";
import { professionalListAction } from "../../../redux/slices/professional/professional-dashboard/propertyListSlice";

import "../../admin/AdminDashboard/propertyTable.scss";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const columns = [
  {
    id: "number",
    disablePadding: true,
    label: "No.",
    value: "address",
    minWidth: 60,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Address",
    value: "address",
    minWidth: 60,
  },
  {
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 60,
  },
  {
    id: "state",
    disablePadding: true,
    label: "State",
    value: "state",
    minWidth: 60,
  },
  {
    id: "Zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 60,
  },
  // {
  //   id: "type",
  //   disablePadding: true,
  //   label: "Type",
  //   value: "type",
  // },
  {
    id: "built",
    disablePadding: true,
    label: "Built",
    value: "year_built",
    minWidth: 60,
  },
  {
    id: "sold",
    disablePadding: true,
    label: "Sold",
    value: "sold",
    minWidth: 60,
  },
  {
    id: "owner",
    disablePadding: true,
    label: "Owner name",
    value: "owner",
    minWidth: 60,
  },
  {
    id: "status",
    disablePadding: true,
    label: "Property status",
    value: "status",
    minWidth: 60,
  },
  // {
  //   id: "docs",
  //   disablePadding: true,
  //   label: "Docs",
  //   value: "docs",
  //   minWidth: 60,
  // },
  {
    id: "created_at",
    disablePadding: true,
    label: "Assigned At",
    // label: "Assign At",
    value: "created_at",
    minWidth: 200,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell></TableCell> */}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PropertyTable() {
  const dispatch = useDispatch();
  // const [selected, setSelected] = useState([]);
  const [propertyPayload, setPropertyPayload] = useState();
  const [propertyType, setPropertyType] = useState("all");
  const [propertyTerm, setPropertyTerm] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [toggleFilterOptions, setToggleFilterOptions] = React.useState(false);
  const [selectedProperty, setSelectedProperty] = React.useState(null);
  const [documnetListModal, setDocumnetListModal] = React.useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  // store data
  const { professionalListLoader } = useSelector(
    (state) => state?.professionals
  );

  // Pagination States & Variables
  let [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);

  // Selectors for Property Listing
  const { propertyListData, propertiesLoader, propertyListDataCount } =
    useSelector((state) => state?.professionalPropertyLists);

  console.log("propertyListData=", propertyListData);
  // const count = Math.ceil(propertyListData.length / PER_PAGE);
  const count = Math.ceil(propertyListDataCount / PER_PAGE);
  // const _DATA = usePagination(propertyListData, PER_PAGE, page, setPage);
  const _DATA = propertyListData;

  const handlePageChange = (e, p) => {
    setPage(p);
    // _DATA.jump(p);
    // staticData.jump(p);
    setPropertyPayload((prevState) => ({
      ...prevState,
      limit: PER_PAGE || 10,
      offset: (p - 1) * PER_PAGE,
    }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterProperty = () => {
    setPage(1);
    setPropertyPayload((prevState) => ({
      ...prevState,
      type: propertyType || undefined,
      term: propertyTerm || undefined,
      status: propertyStatus || undefined,
      from_date: selectedDateRange
        ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
        : undefined,
      to_date: selectedDateRange
        ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
        : undefined,
      limit: PER_PAGE || 10,
      offset: offset || 0,
    }));
    // setPropertyType();
    // setPropertyTerm("");
    // setPropertyStatus();
  };

  const resetAllFilterOptions = () => {
    setPropertyType("all");
    setPropertyTerm("");
    setPropertyStatus();
    setSelectedDateRange(null);
    if (
      propertyPayload?.term ||
      propertyPayload?.status ||
      propertyPayload?.from_date ||
      propertyPayload?.to_date
    ) {
      setPropertyPayload({ type: "all", limit: PER_PAGE, offset: 0 });
    }
    setPage(1);
  };

  const getPropertyListData = useCallback(() => {
    dispatch(
      professionalListAction.getProfessionalListData({
        url: `professional/property-list`,
        data: {
          type: propertyPayload?.type,
          term: propertyPayload?.term,
          status: propertyPayload?.status,
          from_date: propertyPayload?.from_date,
          to_date: propertyPayload?.to_date,
          limit: PER_PAGE,
          offset: propertyPayload?.offset || offset,
        },
        token: true,
      })
    );
  }, [
    dispatch,
    propertyPayload?.type,
    propertyPayload?.term,
    propertyPayload?.status,
    propertyPayload?.from_date,
    propertyPayload?.to_date,
    propertyPayload?.offset,
    offset,
  ]);

  useEffect(() => {
    getPropertyListData();
  }, [dispatch, getPropertyListData]);

  const handleCloseModal = () => {
    setDocumnetListModal(false);
  };

  const handleOpenDocumentListModal = () => {
    setDocumnetListModal(true);
  };
  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {/* Filtering Options */}
        {toggleFilterOptions && (
          <>
            {/* mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={3} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={5} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={propertyType}
                      onChange={(e) => {
                        setPropertyType(e.target.value);
                        setPropertyTerm("");
                        setPropertyStatus("");
                        setSelectedDateRange(null);
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.id !== "docs" && (
                            <MenuItem key={cell.id} value={cell.value}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>

                {propertyType !== "status" && propertyType !== "created_at" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl
                      className="filter-search w-100"
                      variant="outlined"
                    >
                      <InputLabel>Enter search term</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        }
                        label="Enter search term"
                        value={propertyTerm}
                        onChange={(e) => setPropertyTerm(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}

                {(propertyType === "status" || propertyType === "all") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Property Status"
                        value={propertyStatus}
                        onChange={(e) => setPropertyStatus(e.target.value)}
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Verified"}>Verified</MenuItem>
                        <MenuItem value={"Active"}>Active</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {propertyType === "created_at" && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    className="text-uppercase"
                    onClick={resetAllFilterOptions}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* desktop view */}
            <Card id="filter-options-card" className="d-none d-md-block">
              <Box className="filter-options-box">
                <div className="search-text">Search By</div>
                <FormControl className="filter-select-one">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="All Types"
                    value={propertyType}
                    onChange={(e) => {
                      setPropertyType(e.target.value);
                      setPropertyTerm("");
                      setPropertyStatus("");
                      setSelectedDateRange(null);
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {columns.map(
                      (cell) =>
                        cell.label !== "No." &&
                        cell.id !== "docs" && (
                          <MenuItem key={cell.id} value={cell.value}>
                            {cell.label}
                          </MenuItem>
                        )
                    )}
                  </TextField>
                </FormControl>
                {propertyType !== "status" && propertyType !== "created_at" && (
                  <FormControl className="filter-search" variant="outlined">
                    <InputLabel>Enter search term</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      }
                      label="Enter search term"
                      value={propertyTerm}
                      onChange={(e) => setPropertyTerm(e.target.value)}
                    />
                  </FormControl>
                )}
                {(propertyType === "status" || propertyType === "all") && (
                  <FormControl className="filter-select-one">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Property Status"
                      value={propertyStatus}
                      onChange={(e) => setPropertyStatus(e.target.value)}
                    >
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"Verified"}>Verified</MenuItem>
                      <MenuItem value={"Active"}>Active</MenuItem>
                    </TextField>
                  </FormControl>
                )}
                {propertyType === "created_at" && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <FormControl>
                  <Button
                    className="filter-button"
                    onClick={handleFilterProperty}
                  >
                    Filter Results
                  </Button>
                </FormControl>
                <Button
                  className="reset-filters"
                  onClick={resetAllFilterOptions}
                >
                  Reset
                </Button>
              </Box>
            </Card>
          </>
        )}
      </Box>
      {/* Property Listing Table */}
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 py-4">
        {_DATA?.map((row, index) => {
          return (
            <>
              <Stack direction="column" spacing={1.2} px={2}>
                <Stack direction="column" spacing={0.5}>
                  <Typography
                    className="value fw-500"
                    sx={{ fontSize: "15px !important" }}
                  >
                    {[row?.address, row?.state.toUpperCase(), row?.zip]?.join(
                      ", "
                    )}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Built:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.year_built || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Sold:</Typography>
                  <Typography className="value">{row?.sold || "-"}</Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Owner:</Typography>
                  <Typography className="value  text-capitalize">
                    {row?.property_details?.OwnerInfo?.["Owner Name"]?.trim()
                      ? row?.property_details?.OwnerInfo?.["Owner Name"]
                          ?.trim()
                          ?.toLowerCase()
                      : row?.property_details?.OwnerInfo?.[
                          "Owner Name 2"
                        ]?.trim()
                      ? row?.property_details?.OwnerInfo?.["Owner Name 2"]
                          ?.trim()
                          ?.toLowerCase()
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Status:</Typography>
                  <Typography className="value">
                    {row?.status === "Pending" ? (
                      <Tooltip
                        placement="top"
                        title="Property's Address verification is still Pending."
                        arrow
                      >
                        <span className="text-muted">Pending</span>
                      </Tooltip>
                    ) : row?.status === "Verified" ? (
                      <span className="text-info">Verified</span>
                    ) : row?.status === "Active" ? (
                      <span className="text-success">Active</span>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Assigned At:</Typography>
                  <Typography className="value">
                    {row.created_at
                      ? getLocalFormatedDate(row.created_at)
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              {index < _DATA?.length - 1 && (
                <Divider
                  className="my-4"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {!_DATA?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <EnhancedTableHead
                // numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={propertyListData.length}
                // rowCount={rows.length}
              />
              <TableBody>
                {/* {stableSort(_DATA.currentData(), getComparator(order, orderBy)) */}
                {_DATA
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    // const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.address)}
                        // role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                      >
                        <TableCell align="left">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={row?.property_id}
                          scope="row"
                          padding="none"
                        >
                          {row?.address.split(",")?.[0]}
                        </TableCell>
                        <TableCell align="left">{row?.city}</TableCell>
                        <TableCell align="left">
                          {row?.state.toUpperCase()}
                        </TableCell>
                        <TableCell align="left">{row?.zip}</TableCell>
                        {/* <TableCell align="left">
                          {row?.type || "RSFR"}
                        </TableCell> */}
                        <TableCell align="left">{row?.year_built}</TableCell>
                        {/* <TableCell align="left">{row?.sold}</TableCell> */}
                        <TableCell align="left">{row?.sold || "-"}</TableCell>
                        {/* <TableCell align="left">{row?.owner}</TableCell> */}
                        <TableCell align="left">
                          {row?.property_details?.OwnerInfo?.[
                            "Owner Name"
                          ]?.trim()
                            ? row?.property_details?.OwnerInfo?.[
                                "Owner Name"
                              ]?.trim()
                            : row?.property_details?.OwnerInfo?.[
                                "Owner Name 2"
                              ]?.trim()
                            ? row?.property_details?.OwnerInfo?.[
                                "Owner Name 2"
                              ]?.trim()
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.status === "Pending" ? (
                            <Tooltip
                              placement="top"
                              title="Property's Address verification is still Pending."
                              arrow
                            >
                              <span className="text-muted">Pending</span>
                            </Tooltip>
                          ) : row?.status === "Verified" ? (
                            <span className="text-info">Verified</span>
                          ) : row?.status === "Active" ? (
                            <span className="text-success">Active</span>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        {/* <TableCell align="left">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                            >
                              <span
                                className={`${
                                  row?.verified_documents &&
                                  row?.verified_documents?.length > 0 &&
                                  "doc-status"
                                }`}
                                onClick={() => {
                                  if (
                                    row?.verified_documents &&
                                    row?.verified_documents?.length > 0
                                  ) {
                                    setSelectedProperty(row);
                                    handleOpenDocumentListModal();
                                  }
                                }}
                              >
                                {row?.verified_documents
                                  ? row?.verified_documents?.length
                                  : "-"}
                              </span>
                            </Stack>
                          </Box>
                        </TableCell> */}
                        <TableCell align="left">
                          {row?.created_at
                            ? getLocalFormatedDate(row?.created_at)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!propertyListData.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
      </Box>
      {propertyListDataCount > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
      <Loader open={propertiesLoader || professionalListLoader} />
      <DocumnetListModal
        open={documnetListModal}
        handleClose={() => {
          handleCloseModal();
        }}
        files={selectedProperty?.verified_documents}
      />
    </>
  );
}
