import { Box, Button, Card } from "@mui/material";
import React, { Component } from "react";
import sync from "../../assets/signin/sync.svg";
import SignaturePad from "react-signature-canvas";
import "./signup.scss";

class SignPad extends Component {
  state = { trimmedDataURL: null };
  sigPad = {};
  img = {};
  clear = () => {
    this.setState({ signRefresh: true });
    this.sigPad.clear();
    this.props.signatureDone(undefined);
  };
  signDone = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
    this.props.signatureDone(
      this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  render() {
    // let { trimmedDataURL } = this.state;
    console.log("props==>", this.props);
    return (
      <>
        <Box
          className="signBoxWrapper"
          sx={{
            maxWidth: "100%",
            margin: "0 auto",
            background: "#F6F9FC",
            padding: "15px",
          }}
        >
          <Card
            className="signBox text-center"
            sx={{
              boxShadow: "none",
              minHeight: "400px",
              height: "400px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px dashed #BDBDBD",
            }}
          >
            <Box className="sign-container">
              <div className="text-end">
                <Button onClick={this.clear} className="refreshSign">
                  <img src={sync} alt="syncIcon" />
                </Button>
              </div>
              <Box className="sigBlock">
                <SignaturePad
                  onEnd={this.signDone}
                  canvasProps={{ minDistance: 0, className: "sigPad" }}
                  ref={(ref) => {
                    this.sigPad = ref;
                  }}
                />
              </Box>

              {/* {trimmedDataURL ? (
          <img className="sigImage" src={trimmedDataURL} alt="sign" />
        ) : null} */}
            </Box>
          </Card>
          {this.props.error}
        </Box>
      </>
    );
  }
}

export default SignPad;
