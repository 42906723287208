import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Autocomplete as Pracel,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import CallUsNow from "../common/CallUsNow";
import mail from "../../assets/icons/mail.png";
import AlertDialog from "../common/AlertDialog";
import Success from "../../assets/icons/success.png";
import ContactSupport from "../common/ContactSupport";
import CustomTextField from "../common/CustomTextField";
import account from "../../assets/icons/account_circle.png";
import Facebook_Icon from "../../assets/articles/facebook.svg";
import Instagram_Icon from "../../assets/articles/instagram.svg";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import CustomizedCheckbox from "../common/customDesignedComponents/customCheckbox";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";
import {
  encryptPayload,
  getAddressFromGoogleAutoComplete,
  getObjLength,
  getShortNameAddressFromGoogleAutoComplete,
  propertyRiskReportDataObj,
} from "../helpers/utils";
import {
  DEFAULT_URL,
  FACEBOOK_LINK,
  FieldStyle,
  GOOGLE_API_KEY,
  INSTAGRAM_LINK,
  MARKETING_AUTOMATION_ID,
  SERVER,
  UCC_FILLER_PHONE_FORMAT,
} from "../helpers/constants";

import "./PropertyRiskManagement.scss";
import "../../styles/landingpage.scss";
import "../../styles/howItWorksSection.scss";
import { LoaderWithTimeout } from "../common/LoaderWithTimeout";

const fieldsValidations = (type) => {
  return Yup.object().shape({
    name: Yup.string("Please enter your First Name.")
      .trim("The Your name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Your Name is required"),
    address:
      type === "address"
        ? Yup.string("Please enter your Address.")
            .trim("The Address cannot include leading and trailing spaces")
            .strict(true)
            .min(5, "Too Short!")
            .max(100, "Too Long!")
            .required("Address is required")
        : Yup.string(),
    parcel_number:
      type === "parcel"
        ? Yup.string().required("Parcel number is required")
        : Yup.string(),
    county_code:
      type === "parcel"
        ? Yup.string("Please select the County ").required(
            "County  is required"
          )
        : Yup.number(),

    email: Yup.string("Please enter your Email Id.")
      .email("Invalid email format")
      .max(255)
      .required("Email id is required"),
    phone: Yup.string("Please enter your Phone number.")
      .typeError("That doesn't look like a phone number")
      // .positive()
      .required("Mobile number is required"),
  });
};

const PropertyRiskManagementForm = ({
  is_PRM = true,
  setGetFreeReport = () => {},
  getFreeReport,
  county_id = undefined,
  widthClass,
  source = null,
  host_path = null,
  host_promocode = undefined,
  AUTOMATION_ID = MARKETING_AUTOMATION_ID,
  isAdmin = false,
  setIsRiskReport = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // store data
  const { riskManagementData, riskManagementLoader } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const { loaded } = useSelector((state) => state?.autocomplete);
  const { countyList, statesLoader, getRiskReportDetails, userLoginLoader } =
    useSelector((state) => state.signUpUser);

  // const loaded = useGoogleMapsScript(GOOGLE_API_KEY);
  // console.log("loaded==>", loaded);
  const [checkTerms, setCheckTerms] = useState(true); //set it true coz hide the checkTerms
  const [checked1, setChecked1] = useState(false);
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const [callScript, setCallScript] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [value, setValue] = useState("address");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const formRef = useRef(null);

  // Create URL object
  const parsedUrl = new URL(window?.location?.href);
  const params = new URLSearchParams(parsedUrl?.search);
  // Extract tokens
  const reportToken = params.get("report_token");
  const resendToken = params.get("resend_token");

  // const handleChange = (place) => {
  //   var address = place.formatted_address;
  //   console.log("Place values==?>", place);
  //   var value = address?.split(",");
  //   const count = value.length;
  //   const stateVal = value[count - 2];

  //   var stateSplit = stateVal?.split(" ");

  //   const state = stateSplit[1];

  //   let param = {
  //     address: address,
  //     state: state,
  //   };

  //   console.log(value, "map lag==>>", param);
  // };

  const onCheckedTerms = (e) => {
    console.log("checked val==>", e.target.checked);
    setCheckTerms(e.target.checked);
  };

  const onCheckedAcknowledge = (e) => {
    console.log("checked val==>", e.target.checked);
    setChecked1(e.target.checked);
  };

  const runAnotherReport = (values) => {
    dispatch(propertyRiskManagementAction.fetchPropertyRiskManagementData({}));
    setIsFormSubmit(false);
    //  setCheckTerms(false);
    setChecked1(false);
  };

  const onSubmit = (values) => {
    let payload = { ...values, ...propertyData, type: value };

    if (value === "address") {
      delete payload?.county_code;
      delete payload?.parcel_number;
    } else {
      delete payload?.address;
      delete payload?.city;
      delete payload?.state;
      delete payload?.zip;
    }
    console.log(value, "values==>>", payload);
    if (checked1 && checkTerms) {
      if (getFreeReport) {
        payload = {
          ...payload,
          for_county: 1,
          county_id: county_id,
          source: source,
          promocode: host_promocode,
        };
        localStorage.setItem(
          "user_details",
          encryptPayload(JSON.stringify(payload))
        );
      }
      dispatch(
        propertyRiskManagementAction.propertyRiskManagementData({
          url: `risk-level`,
          data: payload,
        })
      );
      setOnSubmitClick(false);
      if (isAdmin) {
        setCallScript(false);
      } else {
        setCallScript(true);
      }
    } else {
      setOnSubmitClick(true);
      setCallScript(false);
    }
  };

  useEffect(() => {
    console.log("propertyRiskManagement==>", riskManagementData);
    if (getObjLength(riskManagementData) && riskManagementData?.success) {
      console.log("ss nofrom ->", window.__ss_noform);
      // Submit __ss_noform data
      if (SERVER === "production" && callScript && isFormLoaded) {
        window.__ss_noform.push([
          "submit",
          function () {
            return console.log("__ss_noform submitted!");
          },
          AUTOMATION_ID,
        ]);
      }
      if (!getFreeReport) {
        setIsFormSubmit(true);
      } else {
        setGetFreeReport(false);
        if (isAdmin) {
          setIsRiskReport(true);
        } else {
          navigate("/property-risk-report");
        }
        setCallScript(false);
        localStorage.setItem("cc", 1);
        localStorage.setItem("host_path", encryptPayload(host_path));
        if (host_promocode) {
          localStorage.setItem("host_code", encryptPayload(host_promocode));
        }
      }
    } else {
      setIsFormSubmit(false);
    }
  }, [riskManagementData]);

  const getCountyList = (county_value) => {
    console.log("value==>", county_value);
    dispatch(
      userSigupAction.getCountyList({
        url: "get-county-autocomplete",
        data: { county: county_value },
      })
    );
  };

  useEffect(() => {
    if (getObjLength(countyList)) {
      if (countyList?.success) {
        setOptions([...countyList?.data]);
      }
    }
  }, [countyList]);

  const validationSchema = fieldsValidations(value);

  // Check if the form is in the DOM
  const checkFormLoaded = () => {
    if (formRef?.current) {
      setIsFormLoaded(true);
    } else {
      setTimeout(checkFormLoaded, 100); // Check again after 100ms
    }
  };

  useEffect(() => {
    // Start checking for the form's presence
    checkFormLoaded();

    return () => {
      // Clean up if necessary
    };
  }, []);

  useEffect(() => {
    // Create a <script> element for Lead Gen GTM tags
    if (SERVER === "production" && !isAdmin && isFormLoaded) {
      const form_script = document.createElement("script");
      form_script.type = "text/javascript";
      form_script.innerHTML = `
      var __ss_noform = __ss_noform || [];
      __ss_noform.push([
        "baseURI",
        "https://app-3SCX408CYC.marketingautomation.services/webforms/receivePostback/MzY0MrYwMjEzAQA/"
      ]);
      __ss_noform.push([
        "form",
        "property-risk-form",
      "${AUTOMATION_ID}"
      ]);
      __ss_noform.push(['submitType', 'manual']);
    `;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://koi-3SCX408CYC.marketingautomation.services/client/noform.js?ver=1.24";

      // Append the <script> element to the document's <body> tag
      const script_loaded = document.querySelector(
        'script[src="https://koi-3SCX408CYC.marketingautomation.services/client/noform.js?ver=1.24"]'
      );
      if (!script_loaded) {
        document.body.appendChild(form_script);
        document.body.appendChild(script);
      }

      // Clean up to remove the <script> element when the component unmounts
      return () => {
        document.body.removeChild(form_script);
        document.body.removeChild(script);
      };
    }

    dispatch(
      propertyRiskManagementAction.clearPropertyRiskManagementPayload({})
    );
    dispatch(propertyRiskManagementAction.clearPropertyRiskManagementData({}));
    localStorage.removeItem("host_code");
    // localStorage.removeItem("host_path");
  }, [dispatch, isFormLoaded]);

  useEffect(() => {
    if (reportToken) {
      console.log("report_token=-->", reportToken);
      let data = {
        report_id: reportToken,
      };
      if (resendToken) {
        data.resend_token = resendToken;
      }
      dispatch(
        userSigupAction.getRiskReportDetails({
          url: "get-report-details",
          data: data,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (getObjLength(getRiskReportDetails) && getRiskReportDetails?.success) {
      if (reportToken) {
        let property_search_data = propertyRiskReportDataObj(
          getRiskReportDetails?.data
        );
        console.log(getRiskReportDetails, "property==>", property_search_data);
      }
    }
  }, [getRiskReportDetails]);

  if (!loaded) return <LoaderWithTimeout open={true} />;

  return (
    <Box
      className={`  box-container bg-white  ${
        is_PRM ? "box_shadow text-center" : ""
      } ${widthClass}`}
    >
      <Box
        className={`${is_PRM ? "request-form" : "property_form_padding"} pb-2 `}
      >
        {!isFormSubmit ? (
          <>
            <Formik
              enableReinitialize
              validateOnChange
              validationSchema={validationSchema}
              initialValues={{
                name: getRiskReportDetails?.data?.name || "",
                email: getRiskReportDetails?.data?.email || "",
                phone: "",
                address: getRiskReportDetails?.data?.address_1 || "",
              }}
              onSubmit={(values, validation) => {
                if (!isValidPhoneNumber(values?.phone)) {
                  return;
                }
                console.log("vcalues-->>", values);
                onSubmit(values);
              }}
            >
              {({ values, setFieldValue, touched, errors, isSubmitting }) => {
                // handle selected address for sign up form
                const handleChange = (address) => {
                  console.log("address changed==>", address);
                  const condos = address.address_components.filter((i, k) =>
                    i.types.includes("subpremise")
                  );
                  var units = condos.length > 0 ? condos[0]?.long_name : "";
                  setFieldValue("unit_no", units);
                  setFieldValue(
                    "address",
                    getShortNameAddressFromGoogleAutoComplete(
                      address,
                      "street_number"
                    ) +
                      (getShortNameAddressFromGoogleAutoComplete(
                        address,
                        "street_number"
                      )
                        ? " "
                        : "") +
                      getShortNameAddressFromGoogleAutoComplete(
                        address,
                        "route"
                      )
                  );
                  var county = getShortNameAddressFromGoogleAutoComplete(
                    address,
                    "administrative_area_level_2"
                  );
                  var county_name = county?.replace("County", "").trim();
                  let data = {
                    searched_Add: address?.formatted_address,
                    street_number: getAddressFromGoogleAutoComplete(
                      address,
                      "street_number"
                    ),
                    site_route: getAddressFromGoogleAutoComplete(
                      address,
                      "route"
                    ),
                    country: getShortNameAddressFromGoogleAutoComplete(
                      address,
                      "country"
                    ),
                    latitude: address.geometry.location.lat(),
                    longitude: address.geometry.location.lng(),
                    city: getAddressFromGoogleAutoComplete(address, "locality"),
                    county: county_name,
                    state_name: getAddressFromGoogleAutoComplete(
                      address,
                      "administrative_area_level_1"
                    ),
                    state: getShortNameAddressFromGoogleAutoComplete(
                      address,
                      "administrative_area_level_1"
                    ),
                    zip: getAddressFromGoogleAutoComplete(
                      address,
                      "postal_code"
                    ),
                    //unit_no: units,
                  };

                  setPropertyData(data);
                };
                return (
                  <>
                    <Form id="property-risk-form" ref={formRef}>
                      <Grid container spacing={1.25}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={isAdmin ? 6 : 12}
                          className="p-top-down"
                        >
                          <CustomTextField
                            placeholder="Your Name"
                            name="name"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("name", event.target.value);
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    className="textbox-icons"
                                    src={account}
                                    alt="account"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={isAdmin ? 6 : 12}
                          className="p-top-down"
                        >
                          <CustomTextField
                            name="email"
                            placeholder="Your Email"
                            type="email"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("email", event.target.value);
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    className="textbox-icons"
                                    src={mail}
                                    alt="mail"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={isAdmin ? 6 : 12}
                          className="p-top-down"
                        >
                          <CustomPhoneNumberInput
                            name="phone"
                            values={values}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("phone", event);
                                if (!event) {
                                  setFieldValue("phone", "");
                                }
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={isAdmin ? 6 : 12}
                          className="p-top-down"
                        >
                          <Select
                            value={value}
                            onChange={(event) => {
                              setValue(event.target.value);
                            }}
                            displayEmpty
                            className="googleAutoComplete mobile-select-tabs w-100 text-start"
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem
                              sx={{
                                color:
                                  value === "address" ? "#0395FF" : "# 0D120B",
                              }}
                              value={"address"}
                            >
                              Search by Address
                            </MenuItem>
                            <MenuItem
                              sx={{
                                color:
                                  value === "parcel" ? "#0395FF" : "#0D120B",
                              }}
                              value={"parcel"}
                            >
                              Search by Parcel
                            </MenuItem>
                          </Select>
                        </Grid>
                        {value === "address" && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={isAdmin ? 6 : 12}
                              className="p-top-down"
                            >
                              <Autocomplete
                                className={`googleAutoComplete mw-100 ${
                                  touched.address && Boolean(errors.address)
                                    ? "error_border"
                                    : ""
                                }`}
                                name="property"
                                //  value={values?.address}
                                apiKey={GOOGLE_API_KEY}
                                // onChange={(e) => {
                                //   setFieldValue("address", e.target.value);
                                // }}
                                onPlaceSelected={(address) => {
                                  handleChange(address);
                                }}
                                onBlur={(event) => {
                                  console.log(
                                    "onChange==>",
                                    event.target.value
                                  );

                                  setFieldValue("address", event.target.value);
                                }}
                                onKeyDown={(event) =>
                                  event.key === "Enter"
                                    ? event.preventDefault()
                                    : null
                                }
                                placeholder="Enter your property address"
                                options={{
                                  types: ["address"],
                                  componentRestrictions: { country: "us" },
                                }}
                              />
                              {touched.address && Boolean(errors.address) && (
                                <FormHelperText
                                  className="m-0"
                                  sx={{
                                    color: "#d32f2f !important",
                                    maxWidth: "none !important",
                                    fontSize: "12px !important",
                                  }}
                                >
                                  {touched.address && errors.address}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={isAdmin ? 6 : 12}
                            >
                              <CustomTextField
                                placeholder="Unit Number"
                                name="unit_no"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                                otherProps={{
                                  onChange: (event) => {
                                    setFieldValue(
                                      "unit_no",
                                      event.target.value
                                    );
                                  },
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <div
                                        style={{
                                          //  opacity: "0.5",
                                          color: "#c3d4cb",
                                        }}
                                      >
                                        <ApartmentIcon />
                                      </div>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {value === "parcel" && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={isAdmin ? 6 : 12}
                              className="p-top-down"
                            >
                              <CustomTextField
                                placeholder="Parcel Number"
                                name="parcel_number"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                                otherProps={{
                                  onChange: (event) => {
                                    setFieldValue(
                                      "parcel_number",
                                      event.target.value
                                    );
                                  },
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={isAdmin ? 6 : 12}
                              className="p-top-down"
                            >
                              <FormControl
                                fullWidth
                                className="sign-textField"
                                htmlFor="demo-customized-textbox"
                                error={
                                  touched.county_code &&
                                  Boolean(errors.county_code)
                                }
                              >
                                <Pracel
                                  name="county"
                                  id="county"
                                  // freeSolo
                                  disableClearable
                                  sx={FieldStyle}
                                  open={open}
                                  // value={values.county_code}
                                  onOpen={() => {
                                    setOpen(true);
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      "county_code",
                                      String(newValue?.id)
                                    );
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.county === value.county
                                  }
                                  getOptionLabel={(option) =>
                                    option.county || values.county_code
                                  }
                                  options={options}
                                  loading={statesLoader}
                                  renderInput={(params) => (
                                    <TextField
                                      onChange={(event) => {
                                        // setFieldValue(
                                        //   "county_code",
                                        //   event.target.value
                                        // );
                                        getCountyList(event.target.value);
                                        setOptions([]);
                                      }}
                                      label="County"
                                      placeholder={"Search the County"}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {statesLoader ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {touched.county_code &&
                                  Boolean(errors.county_code) && (
                                    <FormHelperText
                                      sx={{ color: "#d32f2f !important" }}
                                    >
                                      {touched.county_code &&
                                        errors.county_code}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <Stack
                        py={1}
                        direction={{
                          md: "column",
                          // sm: "column-reverse",
                          // xs: "column-reverse",
                        }}
                        spacing={1}
                        justifyContent={{
                          // md: `${is_PRM ? "center" : "space-between"}`,
                          sm: "start",
                          // xs: "center",
                        }}
                        alignItems={{
                          md: "start",
                          // sm: "center",
                          // xs: "center",
                        }}
                        color={"black"}
                      >
                        <div className="mt-2">
                          {/* <Stack
                            direction={"row"}
                            spacing={1}
                            justifyContent={"flex-start"}
                            alignItems={"start"}
                            color={"black"}
                          >
                            <span className="py-1">
                              <CustomizedCheckbox onChecked={onCheckedTerms} />
                            </span>
                            <span className="pt-1 text-start">
                              I agree to the{" "}
                              <a
                                href={`${DEFAULT_URL}api/service_agreement_2023.pdf`}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                Terms and Conditions
                              </a>
                            </span>
                          </Stack> */}
                          <Stack
                            direction={"row"}
                            spacing={1}
                            justifyContent={"flex-start"}
                            alignItems={"start"}
                            color={"black"}
                          >
                            <span className="py-1">
                              <CustomizedCheckbox
                                onChecked={onCheckedAcknowledge}
                              />
                            </span>
                            <span className="pt-1 text-start">
                              I acknowledge that a sales representative can call
                              me.
                            </span>
                          </Stack>
                          {onSubmitClick
                            ? (!checkTerms || !checked1) && (
                                <span className="py-3 fw-500 terms_error">
                                  You must agree to the terms and conditions to
                                  proceed.
                                </span>
                              )
                            : null}
                        </div>

                        {!isAdmin && (
                          <Button
                            type="submit"
                            className={`button1 success_popup_btn mt-4 button_width
                          }`}
                            variant="contained"
                          >
                            {host_path?.includes("Equity-Guard")
                              ? "Get Free Title Monitoring"
                              : "Get Your Free Report"}
                          </Button>
                        )}
                        {/* {host_path?.includes("Equity-Guard") && (
                          <p
                            className="text-danger text-center fw-700"
                            style={{ padding: "2px 4%" }}
                          >
                            Look up Your Address Started with an Address Lookup
                            & Free Risk Report
                          </p>
                        )} */}
                      </Stack>
                      {isAdmin && (
                        <Button
                          type="submit"
                          className={`button1 success_popup_btn mt-4 text-center text-white
                        }`}
                        >
                          Get Your Free Report
                        </Button>
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        ) : (
          <Box className="text-center">
            <Box className="">
              <img className="popup_icon border-none" src={Success} alt="" />
              <Typography className="text-center heading_msg py-2">
                Your report is on its way
              </Typography>
              <Typography className="text-center caption_texts fw-400 m-0 pb-3">
                Please check the email address you provided for your free
                report.
              </Typography>
              <Box className="msg_blocks">
                <Typography className="text-center caption_texts fw-400 m-0 ">
                  Should your property appear at risk, please contact our 100%
                  US-based agents or call us right now at:
                </Typography>
                <Typography className="text-center phone_text fw-400 m-0 pt-3">
                  {UCC_FILLER_PHONE_FORMAT}
                </Typography>
              </Box>
              <Box className="mt-5 alert-modal-btn">
                <Button
                  className="button1 success_popup_btn  "
                  onClick={runAnotherReport}
                >
                  Run another report
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {is_PRM && (
        <>
          <Divider orientation="horizontal" />
          <Stack
            direction={"row"}
            justifyContent="center"
            paddingX={2}
            paddingBottom={isFormSubmit ? 0 : 2}
            paddingTop={1}
          >
            {/* <img src={help_center} alt="help_center" className="me-1" /> */}
            <span className="me-1 text-dark">Having trouble?</span>
            <CallUsNow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              renderText={
                <span
                  className="text-blue text-decoration-none"
                  style={{ cursor: "pointer" }}
                >
                  Contact support
                </span>
              }
            />{" "}
          </Stack>
          {isFormSubmit && (
            <Stack
              direction={"row"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              className=" pt-2"
              paddingBottom={1}
              sx={{}}
            >
              <a
                href={INSTAGRAM_LINK}
                target="_blank"
                rel="noreferrer"
                className="px-2"
              >
                <img
                  src={Instagram_Icon}
                  alt="Facebook"
                  style={{ height: "35px" }}
                />
                {/* <InstagramIcon style={{ color: "#E4405F" }} fontSize="large" /> */}
              </a>

              <a
                href={FACEBOOK_LINK}
                target="_blank"
                rel="noreferrer"
                className="px-2"
              >
                <img
                  src={Facebook_Icon}
                  alt="Facebook"
                  style={{ height: "35px" }}
                />
                {/* <FacebookIcon fontSize="large" /> */}
              </a>
            </Stack>
          )}
        </>
      )}

      {getObjLength(riskManagementData) && !riskManagementData?.success && (
        <AlertDialog
          type="Error"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(
              propertyRiskManagementAction.fetchPropertyRiskManagementData({})
            );
          }}
          mesage={
            <>
              {riskManagementData?.data?.message}
              {riskManagementData?.data?.errors?.contact_support && (
                <ContactSupport hideMsg={true} spacing={"pt-3"} />
              )}
            </>
          }
          footer={false}
        />
      )}

      <Loader open={riskManagementLoader || userLoginLoader} />
    </Box>
  );
};

export default PropertyRiskManagementForm;
