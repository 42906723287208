import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SecurityPinGeneration from "../../common/SecurityPinGeneration";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { userSettingsAction } from "../../../redux/slices/user/userSettingSlice";

const ChangeSecurityPIN = ({ onClose, titleText = "" }) => {
  const dispatch = useDispatch();
  const { securityPinDetails, settingsLoader } = useSelector(
    (state) => state.userSettings
  );
  const onFinalPIN = (pin) => {
    let inputParam = {
      security_pin: pin,
    };
    dispatch(
      userSettingsAction.getSecurityPinData({
        url: "edit-security-pin",
        data: inputParam,
        token: true,
      })
    );
  };

  const clearSecurityPINData = () => {
    dispatch(userSettingsAction.clearSecurityPINData({}));
    dispatch(
      userProfileAction.userProfileData({
        url: "profile",
        data: {},
        token: true,
      })
    );
    onClose();
  };
  return (
    <>
      <Button
        onClick={() => onClose()}
        className="modal-close-btn"
        style={{
          position: "absolute",
          right: 10,
          top: 15,
          background: "transparent",
        }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </Button>
      <SecurityPinGeneration
        titleText={titleText ? titleText : "Change Your Security PIN"}
        confirmText={"Confirm Your Security PIN"}
        subTitletext={"You can change Your security PIN here anytime you want."}
        onCloseBtnFun={clearSecurityPINData}
        apiCall={onFinalPIN}
        securityPinResponse={securityPinDetails}
        loader={settingsLoader}
      />
    </>
  );
};

export default ChangeSecurityPIN;
