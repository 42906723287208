import { Button, Stack, IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./GetCroppedImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import "./common.scss";

const CropImage = ({ file, setFile, handleUploadBtn }) => {
  const [selectedFile, setSelectedFile] = useState(URL.createObjectURL(file));
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedFile, croppedAreaPixels);
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, selectedFile]);

  useEffect(() => {
    return () => {
      if (!selectedFile) {
        setSelectedFile(null);
        setCroppedImage(null);
        setFile(null);
      }
    };
  }, [selectedFile, setFile]);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        {selectedFile && !croppedImage && (
          <Stack>
            <>
              <Stack className="cropper-box">
                <Cropper
                  image={selectedFile}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="horizontal-cover"
                />
              </Stack>
              <Box className="d-flex justify-content-end" mt={2}>
                <Button
                  onClick={showCroppedImage}
                  variant="contained"
                  className="success-btn"
                  // color="primary"
                >
                  Crop Picture
                </Button>
              </Box>
            </>
          </Stack>
        )}
        {croppedImage && (
          <Box className="cropped-image">
            {/* {console.log("cropped Images", croppedImage)} */}
            <Box className="position-relative">
              <img
                height="100%"
                width="100%"
                src={croppedImage}
                alt="cropped"
                id="croppedImage"
              />

              <IconButton
                sx={{
                  color: "#FC2125",
                  fontSize: "1.4rem",
                  cursor: "pointer",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                onClick={() => {
                  setFile(null);
                  setCroppedImage(null);
                  setSelectedFile(null);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <Box className="d-flex justify-content-end" mt={2}>
              <Button
                onClick={async () => {
                  console.log("Cropped blob", croppedImage);
                  //   console.log("Cropped Image", blobToImage(croppedImage));
                  //   console.log("Cropped Image", await createImage(croppedImage));
                  //   console.log(
                  //     "Cropped Image",
                  //     new File([croppedImage], "image.jpeg", {
                  //       type: croppedImage.type,
                  //     })
                  //   );
                  handleUploadBtn(croppedImage);
                }}
                variant="contained"
                className="success-btn"
                // color="primary"
              >
                Upload
              </Button>
            </Box>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default CropImage;
