import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../common/loader";
import { useEffect } from "react";
import { autoFocusOnField, getObjLength } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const VerifySecurityPIN = ({ uid, onClose, closePopup, rowData = {} }) => {
  const [pin, SetPIN] = useState("");

  const dispatch = useDispatch();
  const { propertyUsersLoader, securityPINResponse } = useSelector(
    (state) => state.propertyUsers
  );

  const [pinErr, SetPINErr] = useState(undefined);
  const [pinSucess, SetPINSucess] = useState("");
  const [diableMobileBtn, setdiableMobileBtn] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  const handlePINChange = (pin) => {
    SetPINSucess("");
    if (pin.length === 5) {
      SetPINErr("");
    }
    console.log("pin handled==>>", pin);
    SetPIN(pin);
  };

  const pinValidate = (event) => {
    event.preventDefault();
    console.log("in pin validation", pin.length);
    SetPINSucess("");
    if (pin.length === 5) {
      SetPINErr("");
      let inputParam = {
        security_pin: pin,
      };
      autoFocusOnField();
      dispatch(
        propertyUsersAction.getSecurityPIN({
          url: "admin/users/" + uid + "/verify-security-pin",
          data: inputParam,
          token: true,
        })
      );
    } else {
      SetPINErr("Please Enter 5 digit Pin to continue further.");
    }
  };

  const PINFieldStyle = {
    background: "#F6F9FC",
    border: "1px solid rgba(52, 75, 91, 0.1)",
    borderRadius: "3px",
    width: "40px",
    height: "55px",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    textAlign: "center",
    color: "#0D120B",
  };

  const onClosePopup = () => {
    setAlertMsg(false);
    dispatch(propertyUsersAction.clearData({}));
    SetPIN("");
    autoFocusOnField();
  };

  useEffect(() => {
    if (getObjLength(securityPINResponse)) {
      setAlertMsg(true);
      if (securityPINResponse?.success) {
        setSuccessMsg(securityPINResponse?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(securityPINResponse?.data?.message);
      }
    }
  }, [securityPINResponse]);

  return (<>
    {!getObjLength(securityPINResponse) &&
    <Box className="accountBoxWrapper">
      <Button
        onClick={() => onClose()}
        className="modal-close-btn"
        style={{
          position: "absolute",
          right: 10,
          top: 15,
          background: "transparent",
        }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </Button>
      <Box>
        <Typography align="center" variant="h3">
          Security Pin Challenge
        </Typography>
        {!rowData?.security_pin_added ? (
          <>
            <Typography
              className="para text-danger fw-500 pt-3"
              variant="boady1"
              align="center"
            >
              The selected user has not added a Security PIN. Kindly request the
              user to add one from the Settings page.
            </Typography>
            <div>
              <Button
                onClick={() => onClose()}
                className="popup_Btn error_popup_btn mt-4"
                variant="contained"
              >
                CLose
              </Button>
            </div>
          </>
        ) : (
          <form onSubmit={pinValidate}>
            <Typography className="para" align="center" variant="body1">
              Verfiy 5 digits pin to confirm Customer is valid or not.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={pin}
                isInputNum
                onChange={handlePINChange}
                numInputs={5}
                shouldAutoFocus
                disabled={diableMobileBtn}
                inputStyle={PINFieldStyle}
              />
              {pinErr?.length > 0 && pinSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  {pinErr}
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {pinSucess}
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={pinValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableMobileBtn}
              >
                Verify
              </Button>
            </div>
          </form>
        )}
      </Box>
      <Loader open={propertyUsersLoader} />
    </Box>
    }
    {alertMsg && getObjLength(securityPINResponse) && (
      <>
        {getObjLength(securityPINResponse) && securityPINResponse?.success ? (
          <PlainAlertMessage
            type="Success"
            openPopup={alertMsg}
            onClose={true}
            onCloseFun={() => {
              onClosePopup();
              closePopup(false);
            }}
            message={successMsg}
            buttons={
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  onClosePopup();
                  closePopup(false);
                }}
              >
                ok, All Good
              </Button>
            }
          />
        ) : (
          <PlainAlertMessage
            type="Error"
            openPopup={alertMsg}
            onClose={true}
            onCloseFun={() => onClosePopup()}
            message={errMsg}
          />
        )}
      </>
    )}
  </>
  );
};

export default VerifySecurityPIN;
