import React from "react";

import ReactDOM from "react-dom";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const Loader = ({ open, message = false }) => {
  return (
    <>
      {open
        ? ReactDOM.createPortal(
            <>
              <Backdrop sx={{ color: "#fff", zIndex: 13000002 }} open={open}>
                <CircularProgress color="inherit" />
                &nbsp;&nbsp;&nbsp;{message || "Please wait.."}
              </Backdrop>
            </>,
            document.body
          )
        : null}
    </>
  );
};
