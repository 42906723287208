import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ImageIcon from "@mui/icons-material/Image";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useEffect, useState } from "react";
import DialogBox from "./cardPopup";
import EditBioMetric from "./EditBioMetric";
import { useSelector } from "react-redux";
import DisplayImage from "./DisplayImage";
import EditSignature from "./EditSignature";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import { ROLES } from "../../helpers/constants";

const BiometricAndSign = ({ Paperstyles, isSignPopup, setIsSignPopup }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [signUpdatedPopup, setSignUpdatedPopup] = useState(false);
  const [imageUpdatedPopup, setImageUpdatedPopup] = useState(false);
  const [image, setImage] = useState("Biometric");
  const displayCloseButton =
    parseInt(decryptPayload(localStorage.getItem("userRoleCode"))) !== 53;
  const {
    biometricDetails,
    signatureDetails,
    loginAsSpouse: isSecondaryUser,
  } = useSelector((state) => state?.userProfile);

  const showImage = (value) => {
    console.log("show==>", value);
    setOpenImage(true);
    setImage(value);
  };
  const updateImage = (value) => {
    console.log("show==>", value);
    setOpenPopup(true);
    setImage(value);
  };

  const closeAddImagePopup = () => {
    setOpenPopup(false);
  };
  const closeViewImagePopup = () => {
    setOpenImage(false);
  };

  // to open popup on initial pageload for subusers
  useEffect(() => {
    if (
      isSignPopup &&
      getObjLength(signatureDetails) &&
      !signatureDetails?.data?.signature
    ) {
      if (
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.secondaryUser
        ) &&
        parseInt(decryptPayload(localStorage.getItem("userRoleCode"))) === 53
      ) {
        setOpenImage(true);
        setImage("signature");
      }
      setIsSignPopup(false);
    }
  }, [isSignPopup, signatureDetails]);

  return (
    <>
      <Paper elevation={0} className="trusted-contacts" sx={Paperstyles}>
        <Box
          className="d-flex align-items-center justify-content-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="h6" className="card-title">
            Signature and Biometric
          </Typography>
        </Box>
        <Typography variant="body1" className="w-75 caption mb-3 mb-lg-4">
          You can see and update your Signature & Biometric Image from here.
        </Typography>
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            position="relative"
            // margin={2}
          >
            <Box className="contacts d-flex flex-column">
              <Typography variant="body1" className="person-name">
                Signature
              </Typography>
            </Box>
            <Stack
              direction="row"
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <Tooltip
                title="View"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  disabled={isSecondaryUser}
                  onClick={() => showImage("Signature")}
                >
                  <ImageIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Update Signature"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  disabled={isSecondaryUser}
                  onClick={() => updateImage("Signature")}
                >
                  <ModeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            position="relative"
            marginTop={2}
          >
            <Box className="contacts d-flex flex-column">
              <Typography variant="body1" className="person-name">
                Biometric
              </Typography>
            </Box>
            <Stack
              direction="row"
              style={{ position: "absolute", top: 0, right: 0 }}
              justifyContent="center"
            >
              {/* {getObjLength(biometricDetails) &&
                biometricDetails?.data?.length > 0 && ( */}
              <Tooltip
                title="View"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() => showImage("Biometric")}
                  disabled={isSecondaryUser}
                >
                  <ImageIcon />
                </IconButton>
              </Tooltip>
              {/* )} */}
              <Tooltip
                title="Update Image"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  disabled={isSecondaryUser}
                  onClick={() => updateImage("Biometric")}
                >
                  <CameraAltIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </>
      </Paper>
      {openPopup && (
        <DialogBox
          modalOverApex={true}
          width={signUpdatedPopup || imageUpdatedPopup ? "sm" : "md"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={displayCloseButton ? closeAddImagePopup : null}
          mesage={
            <>
              {image === "Biometric" ? (
                <EditBioMetric
                  setOpenPopup={closeAddImagePopup}
                  imageUpdatedPopup={imageUpdatedPopup}
                  setImageUpdatedPopup={setImageUpdatedPopup}
                />
              ) : (
                <EditSignature
                  displayCloseButton={displayCloseButton}
                  setOpenPopup={closeAddImagePopup}
                  signUpdatedPopup={signUpdatedPopup}
                  setSignUpdatedPopup={setSignUpdatedPopup}
                />
              )}
            </>
          }
        />
      )}
      {openImage && (
        <DialogBox
          width="md"
          openPopup={openImage}
          setOpenPopup={setOpenImage}
          onClose={displayCloseButton ? closeViewImagePopup : null}
          mesage={
            <>
              {image === "Biometric" ? (
                <DisplayImage
                  imgSrc={biometricDetails?.data?.face_image}
                  name="Biometric"
                  setOpenImage={closeViewImagePopup}
                  updateImage={updateImage}
                  displayCloseButton={true}
                />
              ) : (
                <DisplayImage
                  imgSrc={signatureDetails?.data?.signature}
                  name="Signature"
                  setOpenImage={closeViewImagePopup}
                  updateImage={updateImage}
                  displayCloseButton={displayCloseButton}
                  // style={
                  //   window?.innerWidth < 550
                  //     ? {
                  //         height: "160px !important",
                  //       }
                  //     : {}
                  // }
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default BiometricAndSign;
