import { useCallback } from "react";
import { getObjLength } from "../helpers/utils";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import { useDispatch } from "react-redux";

const useClearLocalStorage = () => {
  const dispatch = useDispatch();

  const clearLocalStorageValues = useCallback((term) => {
    console.log("clear LOCALSTORAGE value-->");
    let subUsersList = localStorage.getItem("subUsersList");
    let county_details = {};
    let is_scrp_hp = localStorage.getItem("scrp_hp");
    if (
      term === "check_county" &&
      is_scrp_hp &&
      !is_scrp_hp.includes("undefined")
    ) {
      county_details = {
        is_scrp_hp,
        countyCode: localStorage.getItem("county-code"),
      };
    }
    localStorage.clear();
    dispatch(propertiesAction.resetState());
    localStorage.setItem("preference", true);
    localStorage.setItem("subUsersList", subUsersList);
    // upadte couynty if it's already available
    if (getObjLength(county_details)) {
      localStorage.setItem("scrp_hp", county_details?.is_scrp_hp);
      localStorage.setItem("county-code", county_details?.countyCode);
    }
  }, []);

  return { clearLocalStorageValues };
};

export default useClearLocalStorage;
