import { createSlice } from "@reduxjs/toolkit";

const intialCsvState = {
  exportCsvLoader: false,
  exportCsvData: {},
  paymentDetailsListData: {},
  exportCSVFileData: {},
};

const exportCsv = createSlice({
  name: "exportCSVDetails",
  initialState: intialCsvState,
  reducers: {
    resetSPState: () => intialCsvState,
    setExportCSVData(state, action) {
      console.log("In slice...", action.payload);
      state.exportCsvLoader = true;
    },
    fetchSetExportData(state, action) {
      console.log("Payload sucewss==>", action.payload);
      state.exportCsvLoader = false;
      state.exportCsvData = action.payload;
    },
    getPaymentDetailsListData(state, action) {
      console.log("In slice...", action.payload);
      state.exportCsvLoader = true;
    },
    fetchPaymentDetailsListData(state, action) {
      console.log("Payload sucewss==>", action.payload);
      state.exportCsvLoader = false;
      state.paymentDetailsListData = action.payload;
    },
    clearData: (state, action) => {
      console.log("Clear data");
      state.exportCsvLoader = false;
      state.exportCsvData = {};
    },
    exportCSVFile: (state, action) => {
      state.exportCsvLoader = true;
    },
    getExportCSVFile: (state, action) => {
      console.log("Payload sucewss==>", action.payload);
      state.exportCsvLoader = false;
      state.exportCSVFileData = action.payload;
    },
  },
});
export const exportCSVDetialsAction = exportCsv.actions;
export default exportCsv.reducer;
