import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import ImageIcon from "@mui/icons-material/Image";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useState } from "react";
import EditBioMetric from "./EditBioMetric";
import { useSelector } from "react-redux";
import DisplayImage from "./DisplayImage";
import EditSignature from "./EditSignature";
import DialogBox from "../../userProfile/Dashboard/cardPopup";

const BiometricAndSign = ({ Paperstyles }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState("Biometric");

  const { biometricDetails, signatureDetails } = useSelector(
    (state) => state?.servicerProfile
  );

  const showImage = (value) => {
    console.log("show==>", value);
    setOpenImage(true);
    setImage(value);
  };
  const updateImage = (value) => {
    console.log("show==>", value);
    setOpenPopup(true);
    setImage(value);
  };

  const closeAddImagePopup = () => {
    setOpenPopup(false);
  };
  const closeViewImagePopup = () => {
    setOpenImage(false);
  };

  return (
    <>
      <Paper elevation={0} className="trusted-contacts" sx={Paperstyles}>
        <Box
          className="d-flex align-items-center justify-content-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="h6" className="card-title">
            Signature and Biometric
          </Typography>
        </Box>
        <Typography variant="body1" className="w-75 caption mb-3 mb-lg-4">
          You can see and update your Signature & Biometric Image from here.
        </Typography>
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            position="relative"
            // margin={2}
          >
            <Box className="contacts d-flex flex-column">
              <Typography variant="body1" className="person-name">
                Signature
              </Typography>
            </Box>
            <Stack
              direction="row"
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <Tooltip
                title="View"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() => showImage("Signature")}
                >
                  <ImageIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Update Signature"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() => updateImage("Signature")}
                >
                  <ModeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            position="relative"
            marginTop={2}
          >
            <Box className="contacts d-flex flex-column">
              <Typography variant="body1" className="person-name">
                Biometric
              </Typography>
            </Box>
            <Stack
              direction="row"
              style={{ position: "absolute", top: 0, right: 0 }}
              justifyContent="center"
            >
              {/* {getObjLength(biometricDetails) &&
                biometricDetails?.data?.length > 0 && ( */}
              <Tooltip
                title="View"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() => showImage("Biometric")}
                >
                  <ImageIcon />
                </IconButton>
              </Tooltip>
              {/* )} */}
              <Tooltip
                title="Update Image"
                TransitionComponent={Zoom}
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    color: "#0395FF",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() => updateImage("Biometric")}
                >
                  <CameraAltIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </>
      </Paper>
      {openPopup && (
        <DialogBox
          width="md"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={closeAddImagePopup}
          mesage={
            <>
              {image === "Biometric" ? (
                <EditBioMetric setOpenPopup={setOpenPopup} />
              ) : (
                <EditSignature setOpenPopup={setOpenPopup} />
              )}
            </>
          }
        />
      )}
      {openImage && (
        <DialogBox
          width="md"
          openPopup={openImage}
          setOpenPopup={setOpenImage}
          onClose={closeViewImagePopup}
          mesage={
            <>
              {image === "Biometric" ? (
                <DisplayImage
                  imgSrc={biometricDetails?.data?.face_image}
                  name="Biometric"
                  setOpenImage={setOpenImage}
                  updateImage={updateImage}
                />
              ) : (
                <DisplayImage
                  imgSrc={signatureDetails?.data?.signature}
                  name="Signature"
                  setOpenImage={setOpenImage}
                  updateImage={updateImage}
                  style={
                    window?.innerWidth < 550
                      ? {
                          height: "160px !important",
                        }
                      : {}
                  }
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default BiometricAndSign;
