import { put, call, takeLatest } from "redux-saga/effects";
import { postAPIPayload } from "../../../../apis/postApi";
import { otherProvidersAction } from "../../../slices/admin/other-providers/otherProvidersSlice";
import { getAPIPayload } from "../../../../apis/getApi";

export function* fetchOtherProvidersListSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(otherProvidersAction.fetchOtherProvidersListData(response));
}

export function* fetchDomainListSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(otherProvidersAction.fetchDomainListData(response));
}

export function* fetchOtherProvidersDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(otherProvidersAction.fetchCreatedProvidersData(response));
}

export function* fetchactivateInactivateDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(otherProvidersAction.fetchActivateInactivateProvider(response));
}

export function* fetcheditedPersonalDataSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("seviceProviders  response => ", response);
  yield put(otherProvidersAction.fetchEditedPersonalDetailsData(response));
}

export function* watchOtherProvidersSagaAsync() {
  yield takeLatest(
    otherProvidersAction.otherProvidersListData,
    fetchOtherProvidersListSaga
  );
  yield takeLatest(otherProvidersAction.domainListData, fetchDomainListSaga);
  yield takeLatest(
    otherProvidersAction.createOtherProvidersData,
    fetchOtherProvidersDataSaga
  );
  yield takeLatest(
    otherProvidersAction.activateInactivateProvider,
    fetchactivateInactivateDataSaga
  );
  yield takeLatest(
    otherProvidersAction.editedPersonalDetailsData,
    fetcheditedPersonalDataSaga
  );
}
