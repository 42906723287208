import { createSlice } from "@reduxjs/toolkit";

const intialServiceProviderProfileState = {
  servicerProfileDetails: {},
  profileLoader: false,
  editProfileLoader: false,
  editedServicerProfileDetails: {},
  servicerProfessionalDetails: {},
  professionalLoader: false,
  editProfessionalLoader: false,
  editedServicerProfessionalDetails: {},
  switchServiceProviderDetails: {},
  reqDocLoader: false,
  requestDocumentsDetails: {},
  biometricDetails: {},
  updateBiometricDetails: {},
  biometric_signLoader: false,
  signatureDetails: {},
  updateSignatureDetails: {},
  isResetPasswordRequired: localStorage.getItem("isResetPasswordRequired") || 0,
  cancelDocumentReqDataDetails: {},
  cancelDocumentReqDataLoader: false,
  uploadedLogoDetails: {},
  uploadCompanyLogoLoader: false,
  deletedLogoDetails: {},
  deleteCompanyLogoLoader: false,
  uploadedServiceAgreementDetails: {},
  uploadServiceAgreementLoader: false,
  uploadRequestedDocDetails: {},
  uploadRequestedDocLoader: false,
};

const serviceProviderProfile = createSlice({
  name: "ServiceProviderProfileDetails",
  initialState: intialServiceProviderProfileState,
  reducers: {
    getServiceProviderProfile(state, action) {
      state.profileLoader = true;
    },
    setServiceProviderProfile(state, action) {
      state.servicerProfileDetails = action.payload;
      if (action.payload?.data?.isResetPasswordRequired) {
        state.isResetPasswordRequired = state?.isResetPasswordRequired + 1;
      }
      state.profileLoader = false;
    },
    clearResetPasswordRequired(state, action) {
      state.isResetPasswordRequired = state.isResetPasswordRequired + 1;
    },
    logoutPasswordRequired(state, action) {
      state.isResetPasswordRequired = 0;
    },
    editServicerProfileData(state, action) {
      state.editProfileLoader = true;
      state.editedServicerProfileDetails = {};
    },
    setEditServicerProfileData(state, action) {
      state.editedServicerProfileDetails = action.payload;
      state.editProfileLoader = false;
    },
    getServiceProviderProfessionalDetails(state, action) {
      state.professionalLoader = true;
    },
    setServiceProviderProfessionalDetails(state, action) {
      state.servicerProfessionalDetails = action.payload;
      state.professionalLoader = false;
    },
    editServicerProfessionalData(state, action) {
      state.editProfessionalLoader = true;
      state.editedServicerProfessionalDetails = {};
    },
    setEditServicerProfessionalData(state, action) {
      state.editedServicerProfessionalDetails = action.payload;
      state.editProfessionalLoader = false;
    },
    switchServiceProviderData(state, action) {
      state.editProfessionalLoader = true;
      state.switchServiceProviderDetails = {};
    },
    setSwitchServiceProviderData(state, action) {
      state.switchServiceProviderDetails = action.payload;
      state.editProfessionalLoader = false;
    },
    requestDocumnetsData(state, action) {
      state.reqDocLoader = true;
      // state.requestDocumentsDetails = {};
    },
    fetchRequestDocumnetsData(state, action) {
      state.requestDocumentsDetails = action.payload;
      state.reqDocLoader = false;
    },
    getSPBiometricData(state, action) {
      state.biometricDetails = {};
      state.biometric_signLoader = true;
    },

    fetchSPBiometricData(state, action) {
      state.updateBiometricDetails = {};
      state.biometricDetails = action.payload;
      state.biometric_signLoader = false;
    },
    updateSPBiometricData(state, action) {
      state.updateBiometricDetails = {};
      state.biometric_signLoader = true;
    },

    fetchUpdatedSPBiometricData(state, action) {
      state.updateBiometricDetails = action.payload;
      state.biometric_signLoader = false;
    },
    getSPSignatureData(state, action) {
      state.signatureDetails = {};
      state.updateSignatureDetails = {};
      state.biometric_signLoader = true;
    },

    fetchSPSignatureData(state, action) {
      state.signatureDetails = action.payload;
      state.biometric_signLoader = false;
    },
    updateSPSignatureData(state, action) {
      state.updateSignatureDetails = {};
      state.biometric_signLoader = true;
    },

    fetchUpdatedSPSignatureData(state, action) {
      state.updateSignatureDetails = action.payload;
      state.biometric_signLoader = false;
    },
    cancelDocumentReqData(state, action) {
      state.cancelDocumentReqDataDetails = {};
      state.cancelDocumentReqDataLoader = true;
    },
    fetchCancelledDocReqData(state, action) {
      state.cancelDocumentReqDataDetails = action.payload;
      state.cancelDocumentReqDataLoader = false;
    },
    uploadCompanyLogo(state, action) {
      state.uploadCompanyLogoLoader = true;
    },
    setUploadedCompanyLogoDetails(state, action) {
      state.uploadedLogoDetails = action?.payload;
      state.uploadCompanyLogoLoader = false;
    },
    deleteCompanyLogo(state, action) {
      state.deleteCompanyLogoLoader = true;
    },
    setDeletedCompanyLogoDetails(state, action) {
      state.deletedLogoDetails = action?.payload;
      state.deleteCompanyLogoLoader = false;
    },
    uploadServiceAgreement(state, action) {
      state.uploadServiceAgreementLoader = true;
    },
    setUploadedServiceAgreementDetails(state, action) {
      state.uploadedServiceAgreementDetails = action?.payload;
      state.uploadServiceAgreementLoader = false;
    },
    uploadRequestedDoc(state, action) {
      state.uploadRequestedDocLoader = true;
    },
    setUploadRequestedDocDetails(state, action) {
      state.uploadRequestedDocDetails = action?.payload;
      state.uploadRequestedDocLoader = false;
    },
  },
});
export const serviceProviderProfileAction = serviceProviderProfile.actions;
export default serviceProviderProfile.reducer;
