import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { useTheme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "./loader";
import AlertDialog from "./AlertDialog";
import { ROLES } from "../helpers/constants";
import SecurePaymentPanel from "./SecurePaymentPanel";
import SubscriptionPlansContent from "./SubscriptionPlans";
import SubscriptionPlansFeatures from "./SubscriptionPlansFeatures";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import TotalPyamentPopup from "../user-signup/React-stripe/TotalPyamentPopup";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import {
  decryptPayload,
  getObjLength,
  getPrefixUrl,
  reactPixelEventOnlyForCustomer,
} from "../helpers/utils";

const AddSubscriptionModal = ({
  open,
  closeModalHandler,
  selectedProperty,
  addPromoCode = false,
  from = "",
  getPropertyList = () => {},
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // states
  const [selectedPlan, setSelectdPlan] = useState(null);
  const [cardData, selectedCardData] = useState(null);
  const [planSelected, setPlanSelected] = useState(false);
  const [valdiateModal, setValdiateModal] = useState(false);
  const [totalAmountModal, setTotalAmountModal] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState();

  // store data
  const { subscriptionPlans, totalPaymentDetails, statesLoader } = useSelector(
    (state) => state?.signUpUser
  );
  const { userProfileDetails, loggedInEmail, cardLoader } = useSelector(
    (state) => state?.userProfile
  );
  const { addSubscriptionDetails, addSubscriptionLoader } = useSelector(
    (state) => state?.properties
  );
  const { servicerProfessionalDetails } = useSelector(
    (state) => state?.servicerProfile
  );

  console.log("selectedPlan", selectedPlan, "cardData", cardData);
  const handleCloseModal = () => {
    setSelectdPlan(null);
    selectedCardData(null);
    setPlanSelected(false);
    closeModalHandler();
  };

  const handleAddSubscription = () => {
    //for fb meta data
    let metadata = {};
    if (selectedProperty) {
      metadata = {
        propetiesDetails: {
          property_id: selectedProperty?.property_id,
          property_address: selectedProperty?.address,
        },
        plan_name: selectedPlan?.name,
        plan_id: selectedPlan?.plan_id,
        email: loggedInEmail,
      };
    } else {
      metadata = {
        plan_name: selectedPlan?.name,
        email: loggedInEmail,
        plan_id: selectedPlan?.plan_id,
      };
    }
    console.log(selectedProperty, "metadata==>>", metadata);
    localStorage.setItem("PlanDetails", JSON.stringify(metadata));

    if (selectedProperty && selectedProperty?.id && cardData?.[0]?.id) {
      let data = {
        user_id:
          userProfileDetails?.data?.user_id ||
          servicerProfessionalDetails?.data?.user_id,
        plan: selectedPlan?.plan_id,
        card_id: cardData?.[0]?.id,
        property_id: selectedProperty?.id,
      };
      if (promoCodeValue) {
        data.promo_code = promoCodeValue;
      }
      console.log("data==>", data);
      if (from === "property-renewal") {
        // let url = `customer/terminated/continue-subscription`;
        // if (selectedProperty?.subscription?.expired) {
        let url = `customer/terminated/subscription-payment`;
        // }
        dispatch(
          propertiesAction.addPropertySubscription({
            url,
            data: data,
            token: true,
          })
        );
      } else {
        let URL = `${getPrefixUrl(userProfileDetails)}/subscription-payment`;
        if (
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
        ) {
          URL = `service-provider/subscription-payment`;
        }

        dispatch(
          propertiesAction.addPropertySubscription({
            url: URL,
            data: data,
            token: true,
          })
        );
      }
    }
  };

  const handleGetEstimatedAmount = (plan) => {
    let data = {
      user_id:
        userProfileDetails?.data?.user_id ||
        servicerProfessionalDetails?.data?.user_id,
      plan_id: parseInt(plan?.plan_id),
      property_id: [selectedProperty?.property_id],
      // state: selectedProperty?.state,
      property_ids: [
        {
          address: selectedProperty?.address,
          property_id: selectedProperty?.property_id,
          state: selectedProperty?.state,
          city: selectedProperty?.city,
          zip: selectedProperty?.zip,
        },
      ],
    };
    localStorage.setItem(
      "Plan_Data",
      JSON.stringify({ ...data, state: selectedProperty?.state })
    );

    // URL = `service-provider/get-final-amount`; // giving Unauthorized error
    dispatch(
      userSigupAction.getTotalPaymentDetails({
        url: `customer/get-final-amount`,
        data: data,
      })
    );
  };

  const onSuccessFun = () => {
    getPropertyList();
    dispatch(propertiesAction.setAddPropertySubscription({}));
    handleCloseModal();
  };

  const onFailedFun = () => {
    localStorage.setItem("paymentPopup", 0);
    setPlanSelected(true);
    dispatch(propertiesAction.setAddPropertySubscription({}));
    // getPropertyList();
    // handleCloseModal();
  };

  const onSuccessFunfor3DsecureCard = () => {
    console.log("in success action");
    reactPixelEventOnlyForCustomer(
      "Add Subscription Successfully",
      JSON.parse(localStorage.getItem("PlanDetails"))
    );
    onSuccessFun();
  };

  const onFailedFunfor3DsecureCard = () => {
    console.log("in failed funfor3DsecureCard");
    reactPixelEventOnlyForCustomer(
      "Add Subscription Failed",
      JSON.parse(localStorage.getItem("PlanDetails"))
    );
    onFailedFun();
  };

  const closeTotalAmountModal = (action) => {
    setTotalAmountModal(false);
    if (action === "cancel") {
      setPromoCodeValue("");
    }
    if (action === "submit") {
      setPlanSelected(true);
    }
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
  };
  useEffect(() => {
    if (getObjLength(addSubscriptionDetails)) {
      if (addSubscriptionDetails?.success) {
        if (addSubscriptionDetails?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !addSubscriptionDetails?.data?.actionRequired &&
          addSubscriptionDetails?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, addSubscriptionDetails]);
  console.log(
    "addSubscriptionDetails data==>",
    addSubscriptionDetails?.data?.length
  );

  return (
    <>
      <Dialog
        className="zoom-modal-wrapper"
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ my: "20px !important" }} className="zoom-container">
          <Typography
            align="center"
            variant="subtitle1"
            className="py-1 my-1 formHeader"
            sx={{ position: "relative" }}
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
            <Button
              onClick={closeModalHandler}
              className="modal-close-btn close-text hide-inner-close"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                alignSelf: "right",
                background: "transparent",
                textTransform: "none",
                justifyContent: "end",
              }}
            >
              <CloseIcon
                className="hide-inner-close"
                sx={{ fontSize: 20, color: "white" }}
              />
            </Button>
          </Typography>

          <DialogTitle id="alert-dialog-title " className=" no-box-shadow">
            {!planSelected && (
              <p className="fw-600" style={{ textAlign: "center" }}>
                Add Subscription
              </p>
            )}
            {/* <p className="close-text" style={{top:"-18px",right:"34px"}}>Close</p> */}
            <Button
              onClick={() => handleCloseModal()}
              className="modal-close-btn close-text-a hide-outer-close"
              style={{
                position: "absolute",
                right: 10,
                top: 0,
                background: "transparent",
                textTransform: "none",
              }}
            >
              Close
              <CloseIcon
                className="hide-outer-close"
                sx={{ fontSize: "2.5rem", color: "#15be53" }}
              />
            </Button>
          </DialogTitle>
          <DialogContent className="profile-wrapper bg-white mb-2">
            {!planSelected && (
              <>
                <Box
                  container
                  sx={{
                    display: "flex",
                    flexDirection: `${isMobile ? "column" : "row"}`,
                    justifyContent: "space-between",
                  }}
                  className="market-value-second-box plans-content-spacing"
                >
                  <Grid container spacing={2} className="">
                    <Grid
                      sx={{ padding: 1 }}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      order={{ xs: 2, sm: 1, md: 1, lg: 1, xl: 1 }}
                    >
                      <Box
                        className="card"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box className="d-flex align-items-center justify-content-between p-3">
                          <SubscriptionPlansContent
                            plan1={true}
                            plan2={false}
                            fullPage={true}
                          />
                        </Box>

                        <Stack
                          direction="column"
                          alignItems="center"
                          className="py-3"
                        >
                          <Button
                            variant="contained"
                            className="popup_Btn success_popup_btn"
                            onClick={() => {
                              // setPlanSelected(true);
                              setSelectdPlan(
                                subscriptionPlans?.data?.find(
                                  (data) => data?.name === "Monthly"
                                )
                              );
                              setTotalAmountModal(true);
                              handleGetEstimatedAmount(
                                subscriptionPlans?.data?.find(
                                  (data) => data?.name === "Monthly"
                                )
                              );
                            }}
                          >
                            Activate Plan
                          </Button>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      sx={{ padding: 1 }}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      order={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                    >
                      <Box
                        className="card"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box className="d-flex align-items-center justify-content-between p-3">
                          <SubscriptionPlansContent
                            plan1={false}
                            plan2={true}
                            fullPage={true}
                          />
                        </Box>

                        <Stack
                          direction="column"
                          alignItems="center"
                          className="py-3"
                        >
                          <Button
                            variant="contained"
                            className="popup_Btn success_popup_btn"
                            onClick={() => {
                              // setPlanSelected(true);
                              setSelectdPlan(
                                subscriptionPlans?.data?.find(
                                  (data) => data?.name === "Yearly"
                                )
                              );
                              setTotalAmountModal(true);
                              handleGetEstimatedAmount(
                                subscriptionPlans?.data?.find(
                                  (data) => data?.name === "Yearly"
                                )
                              );
                            }}
                          >
                            Activate Plan
                          </Button>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <section className="plans-content-spacing">
                  <Container fluid>
                    <SubscriptionPlansFeatures fullPage={true} />
                  </Container>
                </section>
              </>
            )}

            {planSelected && (
              <>
                {/* <Elements stripe={stripePromise}> */}
                <div className="">
                  <SecurePaymentPanel
                    displayLabel={false}
                    cardData={selectedCardData}
                    paymentData={addSubscriptionDetails}
                    onSuccessFun={onSuccessFunfor3DsecureCard}
                    onFailedFun={onFailedFunfor3DsecureCard}
                    userId={
                      userProfileDetails?.data?.user_id ||
                      servicerProfessionalDetails?.data?.user_id
                    }
                    addPromoCode={addPromoCode}
                    promoCodeValue={setPromoCodeValue}
                    from={from}
                    setTotalAmountModal={setTotalAmountModal}
                    paySecure={handleAddSubscription}
                  />

                  {/* </Elements> */}

                  <DialogActions>
                    <div className="d-flex justify-content-between pb-2 w-100 card-list-btn">
                      <Button
                        className="popup_Btn other_popup_btn"
                        onClick={() => {
                          handleCloseModal();
                        }}
                        disabled={addSubscriptionLoader}
                      >
                        Cancel
                      </Button>

                      <Button
                        className="popup_Btn success_popup_btn"
                        onClick={handleAddSubscription}
                        disabled={!cardData?.[0]?.id || addSubscriptionLoader}
                      >
                        Pay Securly
                      </Button>
                    </div>
                    <Grid container className="grid-card-list-btn" spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          className="popup_Btn other_popup_btn w-100"
                          onClick={() => {
                            handleCloseModal();
                          }}
                          disabled={addSubscriptionLoader}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          className="popup_Btn success_popup_btn w-100"
                          onClick={handleAddSubscription}
                          disabled={!cardData?.[0]?.id || addSubscriptionLoader}
                        >
                          Pay Securly
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </div>
              </>
            )}
          </DialogContent>
          <Loader open={addSubscriptionLoader || statesLoader || cardLoader} />
        </Box>
      </Dialog>

      {totalAmountModal &&
      !planSelected &&
      getObjLength(totalPaymentDetails) ? (
        totalPaymentDetails?.success ? (
          <TotalPyamentPopup
            dataflag={true}
            setDataFlag={closeTotalAmountModal}
            alert={totalAmountModal}
            setAlert={closeTotalAmountModal}
            promoCodeValue={promoCodeValue}
            closePopup={closeTotalAmountModal}
            planDetails={selectedPlan}
            isClose={true}
            paySecure={closeTotalAmountModal}
          />
        ) : (
          <>
            <AlertDialog
              type="Info"
              openPopup={true}
              onClose={true}
              onCloseFun={() =>
                dispatch(userSigupAction.fetchTotalPaymentDetails({}))
              }
              mesage={
                <p>
                  Are you sure you want to continue with a{" "}
                  <b className="text-uppercase">{selectedPlan?.name} </b>
                  Plan?
                </p>
              }
              buttons={
                <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                  <Button
                    className="popup_Btn other_popup_btn m-2"
                    onClick={() =>
                      dispatch(userSigupAction.fetchTotalPaymentDetails({}))
                    }
                  >
                    cancel
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn m-2"
                    onClick={() => {
                      setPlanSelected(true);
                      dispatch(userSigupAction.fetchTotalPaymentDetails({}));
                    }}
                    // onClick={() => setAlert(false)}
                  >
                    continue
                  </Button>
                </Stack>
              }
            />
          </>
        )
      ) : null}

      {getObjLength(addSubscriptionDetails) && valdiateModal ? (
        addSubscriptionDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={onSuccessFun}
            mesage={addSubscriptionDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={onFailedFun}
            mesage={addSubscriptionDetails?.data?.message}
            footer={false}
          />
        )
      ) : null}
    </>
  );
};

export default AddSubscriptionModal;
