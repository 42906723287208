import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import UsersTable from "./UsersTable";

const ProfessionalUsersList = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Users List
        </Typography>
      </Box>
      <Box>
        <UsersTable />
      </Box>
      
    </>
  );
};

export default ProfessionalUsersList;
