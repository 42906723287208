import { createSlice } from "@reduxjs/toolkit";

const intialMarketValueState = {
  saleAreaMapData: [],
  saleAreaChartData: [],
  rentAreaMapData: [],
  rentAreaGraphData: [],
  trueCostOfownershipData: {},
  saleAreaMapLoader: false,
  saleAreaChartLoader: false,
  rentAreaMapLoader: false,
  rentAreaChartLoader: false,
  trueCostOfownershipLoader: false,
};

const marketValue = createSlice({
  name: "propertiesDetails",
  initialState: intialMarketValueState,
  reducers: {
    resetMarketValue(state, action) {
      state.saleAreaMapData = [];
      state.saleAreaChartData = [];
      state.rentAreaMapData = [];
      state.rentAreaGraphData = [];
      state.trueCostOfownershipData = {};
    },
    fetchSaleAreaMapData(state, action) {
      state.saleAreaMapLoader = true;
    },
    setSaleAreaMapData(state, action) {
      state.saleAreaMapData = action.payload.data;
      state.saleAreaMapLoader = false;
    },
    fetchRentAreaMapData(state, action) {
      state.rentAreaMapLoader = true;
    },
    setRentAreaMapData(state, action) {
      state.rentAreaMapData = action.payload.data;
      state.rentAreaMapLoader = false;
    },
    fetchSaleAreaChartData(state, action) {
      state.saleAreaChartLoader = true;
    },
    setSaleAreaChartData(state, action) {
      state.saleAreaChartData = action.payload.data;
      state.saleAreaChartLoader = false;
    },
    fetchTrueCostOfOwnershipData(state, action) {
      state.trueCostOfownershipLoader = true;
    },
    setTrueCostOfOwnershipData(state, action) {
      state.trueCostOfownershipData = action.payload.data;
      state.trueCostOfownershipLoader = false;
    },
    updateOwnershipData(state, action) {
      state.trueCostOfownershipLoader = true;
    },
    setUpdateOwnershipData(state, action) {
      state.trueCostOfownershipData = action.payload.data;
      state.trueCostOfownershipLoader = false;
    },
  },
});
export const marketValueAction = marketValue.actions;
export default marketValue.reducer;
