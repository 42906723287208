import { createSlice } from "@reduxjs/toolkit";

const intialProfessionalsState = {
  professionalData: {},
  professionalDataCount: 0,
  addProfessionalDetails: {},
  deleteProfessionalDetails: {},
  professionalListLoader: false,
  professionalTypeList: {},
  switchProfessionalData: {},
  professionalTypeLoader: false,
  activateDeactivateProfessionalDetails: {},
  activateDeactivateProfessionalLoader: false,
};

const professional = createSlice({
  name: "professionalDetails",
  initialState: intialProfessionalsState,
  reducers: {
    resetProfessionalState(state, action) {
      state.professionalData = {};
      state.professionalListLoader = false;
    },
    clearAddProfessionalDetailsData(state, action) {
      state.addProfessionalDetails = {};
      state.professionalListLoader = false;
    },
    addProfessionalDetails(state, action) {
      state.professionalListLoader = true;
    },
    deleteProfessionalDetails(state, action) {
      state.professionalListLoader = true;
    },
    setProfessionalDetails(state, action) {
      state.addProfessionalDetails = action.payload;
      state.professionalListLoader = false;
    },
    setDeletedProfessionalDetails(state, action) {
      state.deleteProfessionalDetails = action.payload;
      state.professionalListLoader = false;
    },
    fetchProfessionalListData(state, action) {
      state.professionalListLoader = true;
    },
    setProfessionalListData(state, action) {
      state.professionalData = action.payload;
      state.professionalDataCount = action.payload.count;
      state.professionalListLoader = false;
    },
    fetchProfessionalTypes(state, action) {
      state.professionalTypeLoader = true;
    },
    setProfessionalTypes(state, action) {
      state.professionalTypeList = action.payload;
      state.professionalTypeLoader = false;
    },
    getSwitchProfessionalData(state, action) {
      state.professionalTypeLoader = true;
    },
    fetchSwitchProfessionalData(state, action) {
      state.switchProfessionalData = action.payload;
      state.professionalTypeLoader = false;
    },
    clearData(state, action) {
      console.log("Clear data");
      state.deleteProfessionalDetails = {};
      state.professionalTypeLoader = false;
      state.professionalListLoader = false;
    },
    activateDeactivateProfessional(state, action) {
      state.activateDeactivateProfessionalLoader = true;
    },
    setActivateDeactivateProfessionalDetails(state, action) {
      state.activateDeactivateProfessionalDetails = action?.payload;
      state.activateDeactivateProfessionalLoader = false;
    },
  },
});
export const professionalAction = professional.actions;
export default professional.reducer;
