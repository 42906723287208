import { useEffect } from "react";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Navbar, Row } from "react-bootstrap";

import { Stack } from "@mui/system";
import { Box, Button, Divider, Grid } from "@mui/material";

import { Loader } from "../common/loader";
import logo from "../../assets/lennar/ep-logo.svg";
import SignupTimeline from "../howItsWorks/SignupTimeline";
import ProductComparison from "../common/ProductComparison";
import CircularProgressLoader from "../common/CircularProgressLoader";
import { lennarSubscriptionAction } from "../../redux/slices/lennar/subscriptionSlice";
import {
  calulateDatefromDaysLater,
  currencyFormatter,
  customeDomainName,
  filteroutPlanData,
  getObjLength,
} from "../helpers/utils";

import "../../styles/howItworks.scss";
import "../../styles/lennarLandingPage.scss";

const Homepage = () => {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const fetchCustomDomain = customeDomainName(window.location.host);
  let path = null;
  if (!fetchCustomDomain) {
    path = pathName?.split("/")?.[1];
  } else {
    path = fetchCustomDomain;
  }
  console.log("path==>", path);

  const [providerPlans, setProviderPlans] = useState();
  // store data
  const { providerDetails, providerLoader } = useSelector(
    (state) => state?.lennarSubscription
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (path?.length > 0) {
      dispatch(
        lennarSubscriptionAction?.getProviderDetails({
          url: `providers/${path}/detail`,
          data: {},
        })
      );
    }
    localStorage.removeItem("roles");
  }, [dispatch, path]);

  useEffect(() => {
    let selectedData = filteroutPlanData(providerDetails?.data);
    setProviderPlans(selectedData);
  }, [dispatch, path, providerDetails?.data]);

  useEffect(() => {
    console.log("providerDetails==>", providerDetails);
    if (getObjLength(providerDetails)) {
      if (!providerDetails?.success) {
        navigate("/404");
      }
      if (providerDetails?.success && !providerDetails?.data?.is_customer_ep) {
        navigate("/404");
      }
    }
  }, [providerDetails]);

  let org_logo = logo;
  const planData = providerDetails?.data?.plans;
  const activeMonthlyplan = providerPlans?.monthly_plans?.is_active;
  const activeYearlyplan = providerPlans?.yearly_plans?.is_active;
  const setupFees = providerPlans?.monthly_plans?.setup_fees;
  console.log("providerPlans==>", providerPlans);

  return (
    <>
      {" "}
      <Box className="lennar-wrapper">
        <div className=" property_bg_img1 text-center pb-3">
          <Navbar.Brand>
            {providerDetails?.data?.logo && (
              <img
                className="lennar-logo"
                src={providerDetails?.data?.logo}
                alt="lennar-logo"
              />
            )}
            <img className="lennar-logo" src={org_logo} alt="lennar-logo" />
          </Navbar.Brand>
          <section className="">
            <>
              <Row className="mt-5 d-flex justify-content-center align-items-center text-white mx-0">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={10}
                  xl={10}
                  // className="content-spacing"
                >
                  <h1 className="property_heading fw-400 mb-4 mt-0 mt-sm-4">
                    Welcome
                    {/* , <span className="text-capitalize">{path}</span> */}
                  </h1>
                  <p className="paragraph_texts fw-400 mt-2 mx-auto">
                    Together with EquityProtect, we are providing the best
                    protection against the biggest threat.
                  </p>
                </Col>
              </Row>
            </>
          </section>
        </div>

        <section
          className={`m-0 bg-white ${
            path.includes("notarized") && "bottom_border"
          }`}
        >
          <Grid container spacing={3} className="">
            <Grid
              xs={12}
              lg={6}
              className=""
              style={{ background: "#235CA30D" }}
            >
              <Stack
                className="choose_us_block"
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Stack
                  className=""
                  direction={"column"}
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <p className="choose_us_header">Why are you here?</p>
                  <p className="choose_us_text mt-2">
                    Significantly, in the preceding year, several Attorney
                    General's Offices issued warnings to homeowners in response
                    to a troubling surge in complaints related to title theft.
                    Various state agencies and industry-related associations,
                    including the American Land and Title Association and the
                    California Association of Realtors, have also issued
                    numerous warnings.
                  </p>
                </Stack>
              </Stack>
            </Grid>

            <Grid
              xs={12}
              lg={6}
              style={{ background: " #EAEFF6" }}
              // className="content-spacing"
              // style={{ padding: "45px" }}
            >
              {planData?.length > 2 && (
                <p className="pricing_brerakdown_txt pt-5 d-flex d-sm-none  align-items-center justify-content-center">
                  Pricing breakdown
                </p>
              )}

              <Stack
                className="pt-sm-4 pb-3 px-3"
                direction={{ xs: "row", sm: "column" }}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {planData?.length > 2 && (
                  <p className="pricing_brerakdown_txt d-none d-sm-flex align-items-center justify-content-center">
                    Pricing breakdown
                  </p>
                )}

                {providerLoader ? (
                  <Stack className=" ">
                    <div className="d-flex align-items-center justify-content-center py-5">
                      <CircularProgressLoader />
                    </div>
                  </Stack>
                ) : (
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    className={` d-flex align-items-center ${
                      planData?.length > 2
                        ? "  justify-content-evenly"
                        : "justify-content-center"
                    } `}
                  >
                    {/* Monthly plan */}
                    {activeMonthlyplan && (
                      <>
                        {" "}
                        <Box className="px-5 py-2">
                          <p
                            className={`pricing_brerakdown ${
                              planData?.length === 2 &&
                              activeMonthlyplan &&
                              "pricing_brerakdown_txt"
                            }`}
                          >
                            {planData?.length > 2
                              ? providerPlans?.monthly_plans?.name
                              : "Pricing breakdown"}
                          </p>
                          <p className="mt-2 pricing">
                            Plan amount:{" "}
                            <b>
                              {currencyFormatter(
                                parseFloat(
                                  providerPlans?.monthly_plans?.amount || 0
                                )
                              )}
                            </b>
                          </p>
                          {parseFloat(setupFees) > 0 && (
                            <p className="mt-2 pricing">
                              Setup fee:{" "}
                              <b>
                                {currencyFormatter(parseFloat(setupFees || 0))}
                              </b>
                            </p>
                          )}
                          {providerPlans?.recording_fees && (
                            <p className="mt-2 pricing ">
                              Deposit for recording fee:{" "}
                              <b>
                                {currencyFormatter(
                                  providerPlans?.recording_fees
                                )}
                              </b>
                            </p>
                          )}
                          {parseInt(
                            providerPlans?.monthly_plans?.trial_period?.day
                          ) > 0 && (
                            <p className="pricing">
                              <b>
                                {providerPlans?.monthly_plans?.trial_period
                                  ?.day || 0}
                                -Day Free Trial
                              </b>
                            </p>
                          )}
                          <p className="subscription-plan-text">
                            {parseInt(
                              providerPlans?.monthly_plans?.trial_period?.day
                            ) > 0 ? (
                              <span>
                                <b>
                                  {currencyFormatter(
                                    providerPlans?.monthly_plans
                                      ?.monthly_renewal_plans || 0
                                  )}
                                </b>{" "}
                                {providerPlans?.monthly_plans?.name} starting on{" "}
                              </span>
                            ) : (
                              <span>
                                <b>
                                  {currencyFormatter(
                                    providerPlans?.monthly_plans
                                      ?.monthly_renewal_plans || 0
                                  )}
                                </b>{" "}
                                {providerPlans?.monthly_plans?.name} from{" "}
                              </span>
                            )}
                            <b>
                              {`${
                                calulateDatefromDaysLater(
                                  new Date(),
                                  providerPlans?.monthly_plans?.trial_period
                                    ?.day || 0,
                                  "MM"
                                ) + `*`
                              }`}
                            </b>
                          </p>
                          {/* )} */}
                        </Box>
                        {activeYearlyplan && (
                          <span className=" d-flex d-sm-none px-4 w-100">
                            <Divider className=" d-flex d-sm-none w-100" />
                          </span>
                        )}
                      </>
                    )}
                    {/* Yearly Plan */}
                    {activeYearlyplan && (
                      <Box className="px-5 py-2">
                        <p
                          className={`pricing_brerakdown ${
                            planData?.length === 2 &&
                            activeYearlyplan &&
                            "pricing_brerakdown_txt"
                          }`}
                        >
                          {planData?.length > 2
                            ? providerPlans?.yearly_plans?.name
                            : "Pricing breakdown"}
                        </p>
                        <p className="mt-2 pricing ">
                          Plan amount:{" "}
                          <b>
                            {currencyFormatter(
                              providerPlans?.yearly_plans?.amount
                            )}
                          </b>{" "}
                          {/* for the first year */}
                        </p>
                        {parseFloat(setupFees) > 0 && (
                          <p className="mt-2 pricing ">
                            <b>No Set-up fee</b>{" "}
                          </p>
                        )}
                        {providerPlans?.recording_fees && (
                          <p className="mt-2 pricing ">
                            Deposit for recording fee:{" "}
                            <b>
                              {currencyFormatter(providerPlans?.recording_fees)}
                            </b>
                          </p>
                        )}
                        {/* {providerDetails?.data?.plans?.[0]?.trial_period &&
                          parseInt(
                            providerDetails?.data?.plans?.[0]?.trial_period?.day
                          ) > 0 && ( */}
                        {parseInt(
                          providerPlans?.yearly_plans?.trial_period?.day
                        ) > 0 && (
                          <p className="pricing">
                            <b>
                              {providerPlans?.yearly_plans?.trial_period?.day ||
                                0}
                              -Day Free Trial
                            </b>
                          </p>
                        )}
                        <p className="subscription-plan-text">
                          {parseInt(
                            providerPlans?.yearly_plans?.trial_period?.day
                          ) > 0 ? (
                            <span>
                              <b>
                                {currencyFormatter(
                                  providerPlans?.yearly_plans
                                    ?.yearly_renewal_plans || 0
                                )}
                              </b>{" "}
                              {providerPlans?.yearly_plans?.name} starting on{" "}
                            </span>
                          ) : (
                            <span>
                              <b>
                                {currencyFormatter(
                                  providerPlans?.yearly_plans
                                    ?.yearly_renewal_plans || 0
                                )}
                              </b>{" "}
                              {providerPlans?.yearly_plans?.name} from{" "}
                            </span>
                          )}
                          <b>
                            {`${
                              calulateDatefromDaysLater(
                                new Date(),
                                providerPlans?.yearly_plans?.trial_period
                                  ?.day || 0,
                                "YY"
                              ) + `*`
                            }`}
                          </b>
                        </p>
                        {/* )} */}
                      </Box>
                    )}
                  </Stack>
                )}
              </Stack>
              <div className="text-center mb-4">
                <Button
                  onClick={() => {
                    fetchCustomDomain
                      ? navigate(`/create-account`)
                      : navigate(`/${path}/create-account`);
                  }}
                  className="continue_payment_btn mt-2 mx-1"
                >
                  Continue to payment
                </Button>
              </div>
            </Grid>
          </Grid>
        </section>
        {path.includes("notarized") && (
          <>
            <section
              className="d-flex align-items-center py-5 stand_out_section"
              style={{ background: "#EBF3FC" }}
            >
              <Container fluid className="">
                <div className=" text-center">
                  <div className="mb-5">
                    <p className="notify_header">How we stand out</p>
                    <h3 className="sub_header mb-4">
                      Designed to Set a New Grade in Title Security and Service
                    </h3>
                    <p className="subTitle mb-5">See how we stand out</p>
                  </div>
                </div>
                <Row className="mt-5 mb-3 justify-content-center ">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={10}
                    xl={10}
                    className="content-spacing"
                  >
                    <ProductComparison
                      topPadding={"0px"}
                      textClass={"mabrypro"}
                    />
                  </Col>
                </Row>
              </Container>
            </section>

            <section
              className="how-it-works-content pt-5 pb-3"
              style={{
                position: "relative",
                boxShadow: "0px 0px 0px 0px #DCE4EC,0px -1px 0px 0px #DCE4EC",
              }}
            >
              <section className="how-it-work-section p-0">
                <Stack className="justify-content-center fw-600 how-it-work-text">
                  How it works
                </Stack>
                <SignupTimeline />
              </section>
            </section>
          </>
        )}

        {/* footer */}
        <section>
          <p className="copy_right_texts m-0">
            Copyright © 2024. EquityProtect, INC. All rights reserved.
          </p>
        </section>
      </Box>
      <Loader open={providerLoader} />
    </>
  );
};

export default Homepage;
