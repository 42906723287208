import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { Box, Button, FormControl, Grid, TextField } from "@mui/material";

import { RECAPTCHA_SITE_KEY } from "../helpers/constants";
import CustomPhoneNumberInput from "./CustomPhoneNumberInput";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";

const initialEmailValues = {
  email: "",
};
const initialPhoneValues = {
  phone: "",
};

const validationEmailSchema = Yup.object().shape({
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
});
const validationPhoneSchema = Yup.object().shape({
  phone: Yup.string("PLease enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Mobile number is required"),
});

const ResetPasswordForm = ({
  onSubmit,
  selectedOption,
  fromErr,
  setFormErr,
  goBack,
  resetPasswordFormRef,
  captchaRef,
  recaptchaToken = null,
  setFormValues = () => {},
  handleCaptchaChange = () => {},
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <>
        <Box>
          <Formik
            enableReinitialize
            validateOnChange
            innerRef={resetPasswordFormRef}
            initialValues={
              selectedOption === "email"
                ? initialEmailValues
                : initialPhoneValues
            }
            validationSchema={
              selectedOption === "email"
                ? validationEmailSchema
                : validationPhoneSchema
            }
            onSubmit={(values) => {
              if (!recaptchaToken) {
                return;
              }
              localStorage.setItem("CAPTCHA_Validate", 1);
              dispatch(
                userSigupAction.checkreCAPTCHA({
                  url: `validate-captcha?token=${recaptchaToken}`,
                  data: {},
                })
              );
              setFormValues(values);

              // onSubmit(values)
            }}
          >
            {({ values, setFieldValue, touched, errors, isSubmitting }) => {
              return (
                <Form
                  className="d-flex justify-content-center align-items-center"
                  style={{ flexDirection: "column" }}
                >
                  <Grid
                    container
                    // textAlign={"center"}
                    // alignItems={"center"}
                    spacing={1}
                    className="d-flex justify-content-center align-items-center mt-3 w-100"
                  >
                    {selectedOption === "email" ? (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        textAlign={"center"}
                        alignItems={"center"}
                      >
                        <TextField
                          className="textField"
                          // id="outlined-basic"
                          variant="outlined"
                          label="Your email address"
                          name="email"
                          InputLabelProps={{
                            style: {
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#00000075",
                            },
                          }}
                          value={values?.email}
                          onChange={(event) => {
                            setFormErr("");
                            setFieldValue("email", event.target.value);
                          }}
                          helperText={touched.email && errors.email}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>
                    ) : (
                      <Grid xs={12} sm={10} md={6} px={1}>
                        <CustomPhoneNumberInput
                          name="phone"
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          otherProps={{
                            onChange: (event) => {
                              setFormErr("");
                              setFieldValue("phone", event);
                              if (!event) {
                                setFieldValue("phone", "");
                              }
                            },
                          }}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="d-flex justify-content-center my-2"
                    >
                      <FormControl className="textField captcha-field">
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_SITE_KEY}
                          onChange={handleCaptchaChange}
                          className="g-recaptcha"
                          ref={captchaRef}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {(fromErr || (isSubmitting && !recaptchaToken)) && (
                    <p className="text-danger text-center w-100 mb-3">
                      {isSubmitting && !recaptchaToken
                        ? "Please verify ReCAPTCHA"
                        : fromErr}
                    </p>
                  )}

                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <Button
                      className="popup_Btn other_popup_btn mx-2"
                      variant="contained"
                      onClick={() => {
                        goBack(true);
                        setFormErr("");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn mx-2"
                      type="submit"
                      variant="contained"
                      // onClick={checkEmail}
                    >
                      Reset password
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="text-center resetPwd mt-3">
            <Link to="/login">Back to login</Link>
          </div>
        </Box>
      </>
    </>
  );
};

export default ResetPasswordForm;
