import { put, call, takeEvery } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { userProfessionalAction } from "../../slices/user/userProfessionalSlice";

export function* fetchUserProfessionalSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("fetchUserProfessional response => ", response);
  yield put(userProfessionalAction.fetchUserProfessionallData(response));
}

export function* watchUserProfessionalSagaAsync() {
  yield takeEvery(
    userProfessionalAction.getUserProfessionalData,
    fetchUserProfessionalSaga
  );
}
