import { useEffect } from "react";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import AddLeadsForm from "./AddLeadForm";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import EditIconSVG from "../../common/customSVGs/EditIconSVG";
import { COUNTRY_CODE, PER_PAGE } from "../../helpers/constants";
import DeleteIconSVG from "../../common/customSVGs/DeleteIconSVG";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import { mobileFilterResetBtnStyle, mobileFilterResultStyle } from ".";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import { addLeadsAction } from "../../../redux/slices/admin/addLeads/addLeadsSlice";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import ResendInvitationModal from "../../professional/send-invitation/ResendInvitationModal";
import {
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

import "./addLeads.scss";

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "pending", label: "Pending" },
];

const LeadsListTable = ({ columns, rowsData, openPopup, setOpenPopup }) => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState({});
  const [alertMsg, setAlertMsg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [status, setStatus] = useState("");
  const [allTypes, setAllTypes] = useState("all");
  const [callApi, setCallApi] = useState(false);
  const [terms, setTerms] = useState("");
  const [isResendInvitationModalOpen, setIsResendInvitationModalOpen] =
    useState(false);

  const [page, setPage] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(7); // updated_at key for sorting
  const [sortingColumn, setSortingColumn] = useState("created_at"); // created_at for default sorting

  const open = Boolean(anchorEl);

  const {
    addleadsLoader,
    leadDeletedData,
    addLeadsData,
    addLeadsDataCount,
    sendInvitationListLoader,
    resendInvitationDetails,
  } = useSelector((state) => state?.addLeads);

  const count = Math.ceil(addLeadsDataCount / PER_PAGE);

  const _DATA = rowsData;

  const handlePageChange = (e, p) => {
    setPage(p);

    // let url;
    // if (terms) {
    //   url = `leads?limit=${PER_PAGE}&offset=${
    //     (p - 1) * PER_PAGE
    //   }&type=${allTypes}&term=${encodeURIComponent(terms)}`;
    // } else {
    //   url = `leads?limit=${PER_PAGE}&offset=${(p - 1) * PER_PAGE}`;
    // }

    dispatch(
      addLeadsAction.getLeadsData({
        url: getLeadslUrl(p),
        data: {},
        token: true,
      })
    );
  };

  const handleMenuClick = (event, data) => {
    console.log("selected row==>", data);
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getLeadslUrl = (p) => {
    let URL = "leads";
    const key = terms || selectedDateRange ? `&type=${allTypes}` : "";
    const value = terms ? `&term=${encodeURIComponent(terms)}` : "";
    const selectedDateRangePayload = selectedDateRange
      ? `&from_date=${
          getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) || ""
        } &to_date=${
          getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) || ""
        }`
      : "";

    const direction = directionFlow
      ? `&sorting_order=desc`
      : `&sorting_order=asc`;
    const column = sortingColumn ? `&order_by_column=${sortingColumn}` : "";

    const finalUrl = `${URL}?limit=${PER_PAGE}&offset=${
      (p - 1) * PER_PAGE
    }${key}${value}${selectedDateRangePayload}${direction}${column}`;

    return finalUrl;
  };

  const filterOptions = () => {
    if (terms.length > 0 || selectedDateRange) {
      setCallApi(true);
      setPage(1);

      dispatch(
        addLeadsAction.getLeadsData({
          url: getLeadslUrl(1),
          data: {},
          token: true,
        })
      );
    }
  };

  const resetData = () => {
    resetFilterStates();
    setPage(1);
    console.log("setCallApi==>", callApi);
    if (callApi) {
      dispatch(
        addLeadsAction.getLeadsData({
          url: `leads?limit=${PER_PAGE}&offset=${0}`,
          data: {},
          token: true,
        })
      );
      setCallApi(false);
    }
  };

  const resetFilterStates = () => {
    console.log("Resetting filter states");
    setAllTypes("all");
    setTerms("");
    setSelectedDateRange(null);
    setSelectedColumn(7);
    setDirectionFlow(true);
  };

  const handleMenuItemClick = (event) => {
    console.log(selectedRow, "selcted option==>.", event);

    if (event === "edit") {
      setEditAlert(true);
    }

    if (event === "delete") {
      setDeleteAlert(true);
    }
  };

  const closeDialogBox = () => {
    setEditAlert(false);
  };
  const deleteRecord = () => {
    dispatch(
      addLeadsAction.deleteLeadsData({
        url: "leads/" + selectedRow?.id,
        token: true,
      })
    );
  };

  const onSuccess = () => {
    setAlertMsg(false);
    dispatch(addLeadsAction.clearData({}));
    if (addLeadsDataCount > PER_PAGE && rowsData?.length === 1 && deleteAlert) {
      dispatch(
        addLeadsAction.getLeadsData({
          url: `leads?limit=${PER_PAGE}&offset=${
            (page - 2) * PER_PAGE
          }&type=${allTypes}&term=${encodeURIComponent(terms)}`,
          token: true,
        })
      );
      setPage(page - 1);
    } else {
      dispatch(
        addLeadsAction.getLeadsData({
          url: `leads?limit=${PER_PAGE}&offset=${
            (page - 1) * PER_PAGE
          }&type=${allTypes}&term=${encodeURIComponent(terms)}`,
          token: true,
        })
      );
    }
    setDeleteAlert(false);
  };

  const handleCloseResendModal = () => {
    setIsResendInvitationModalOpen(false);
  };

  const handleResendInvitation = () => {
    if (selectedRow && selectedRow?.id) {
      dispatch(
        addLeadsAction.resendInvitationDetails({
          url: `leads/${selectedRow?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    // dispatch(
    //   addLeadsAction.getLeadsData({
    //     url: `leads?limit=${PER_PAGE}&offset=${
    //       (page - 1) * PER_PAGE
    //     }&type=${allTypes}&term=${encodeURIComponent(
    //       terms
    //     )}&order_by_column=${column}&sorting_order=${
    //       !directionFlow ? `desc` : `asc`
    //     }`,
    //     data: {},
    //     token: true,
    //   })
    // );
  };

  useEffect(() => {
    dispatch(
      addLeadsAction.getLeadsData({
        url: `${getLeadslUrl(page)}`,
        data: {},
        token: true,
      })
    );
  }, [dispatch, sortingColumn, directionFlow]);

  useEffect(() => {
    console.log("openPopup", openPopup);
    if (openPopup) {
      setEditAlert(false);
    }
  }, [openPopup]);
  useEffect(() => {
    console.log("openPopup", openPopup);
    if (getObjLength(leadDeletedData)) {
      setAlertMsg(true);
      if (leadDeletedData?.success) {
        setSuccessMsg(leadDeletedData?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(leadDeletedData?.data?.message);
      }
    }
  }, [leadDeletedData]);

  return (
    <>
      {/* Filtering Component Section */}
      <Box
        id="filter"
        onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
      >
        <h3 className="filter-text">Filter your results</h3>
        <ArrowDropDownIcon
          className="filter-arrow"
          sx={{ ms: 1, fontSize: "20px" }}
        />
      </Box>
      {/* Filtering Options */}
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl
                    className="filter-select-one"
                    sx={{ width: "100%" }}
                  >
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      name="type"
                      onChange={(event) => {
                        setAllTypes(event.target.value);
                        setTerms("");
                        setStatus("");
                        setSelectedDateRange(null);
                      }}
                      value={allTypes}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.label !== "Actions" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {allTypes !== "status" &&
                  allTypes !== "phone" &&
                  allTypes !== "created_at" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search"
                        sx={{ width: "100%" }}
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          name="terms"
                          onChange={(event) => {
                            setTerms(event.target.value);
                          }}
                          value={terms}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {allTypes === "status" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl
                      className="filter-search"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      <TextField
                        id="outlined-adornment-password"
                        select
                        label="Status"
                        value={status}
                        onChange={(e) => {
                          setTerms(e.target.value);
                          setStatus(e.target.value);
                        }}
                      >
                        {statusOptions.map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {allTypes === "phone" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl
                      className="filter-search"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      <Box className={`phone-number-input bg-white filter`}>
                        <PhoneInput
                          className="bg-white"
                          international={false}
                          placeholder="Phone Number"
                          value={terms}
                          countries={["US", "IN"]}
                          defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                          countryOptionsOrder={["US", "IN"]}
                          addInternationalOption={false}
                          onChange={(event) => {
                            setTerms(event);
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                )}
                {(allTypes === "all" || allTypes === "created_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={filterOptions}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={() => {
                      resetData();
                    }}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card className="d-none d-md-block filter-options-carddiv">
              <Grid container className="filter-options-box">
                <Stack direction="row" spacing={2} alignItems="center">
                  <div className="search-text mx-2">Search By</div>
                  <FormControl className="filter-select-one">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      name="type"
                      onChange={(event) => {
                        setAllTypes(event.target.value);
                        setTerms("");
                        setStatus("");
                        setSelectedDateRange(null);
                      }}
                      value={allTypes}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.label !== "Actions" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Stack>
                {allTypes !== "status" &&
                  allTypes !== "phone" &&
                  allTypes !== "created_at" && (
                    <Grid item>
                      <FormControl className="filter-search" variant="outlined">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          name="terms"
                          onChange={(event) => {
                            setTerms(event.target.value);
                          }}
                          value={terms}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {allTypes === "status" && (
                  <Grid item>
                    <FormControl className="filter-search" variant="outlined">
                      <TextField
                        id="outlined-adornment-password"
                        select
                        label="Status"
                        value={status}
                        onChange={(e) => {
                          setTerms(e.target.value);
                          setStatus(e.target.value);
                        }}
                      >
                        {statusOptions.map((data, key) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {allTypes === "phone" && (
                  <Grid item>
                    <Box className={`phone-number-input bg-white filter`}>
                      <PhoneInput
                        className="bg-white"
                        international={false}
                        placeholder="Phone Number"
                        value={terms}
                        countries={["US", "IN"]}
                        defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                        countryOptionsOrder={["US", "IN"]}
                        addInternationalOption={false}
                        onChange={(event) => {
                          setTerms(event);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                {(allTypes === "all" || allTypes === "created_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FormControl>
                      <Button
                        type="submit"
                        onClick={filterOptions}
                        className="filter-button "
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      type="reset"
                      onClick={() => {
                        resetData();
                      }}
                      className="reset-filters"
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>
      {/* Property Listing Table */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
                columns={columns}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.address}
                    >
                      <TableCell align="left">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>

                      <TableCell align="left" className="text-capitalize">
                        {row?.name?.trim() || "-"}
                      </TableCell>
                      <TableCell align="left">{row?.email || "-"}</TableCell>
                      <TableCell align="left">
                        {getFormatedPhoneNumber(row?.phone) || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.promocode?.name || "-"}
                      </TableCell>
                      <TableCell align="left" className="text-capitalize">
                        {row.status === "pending" ? (
                          <span className="text-danger">Pending</span>
                        ) : (
                          <Tooltip
                            title="Click here to see user's details."
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <Link
                              className="text-decoration-none "
                              to={"/admin-dashboard/" + row?.user_id}
                            >
                              <span className="text-success">Active</span>
                            </Link>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.created_at)
                          : "-"}
                      </TableCell>

                      <TableCell sx={{ width: 180 }} align="left">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={(e) => handleMenuClick(e, row)}
                            size="small"
                            className="settings-button"
                            aria-controls={open ? "settings-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            <SettingsIconSVG />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!rowsData.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
        {addLeadsDataCount > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
      {/* table options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => handleMenuItemClick("edit")}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <EditIconSVG />
            <Typography className="setting-menu-text">Edit</Typography>
          </Box>
        </MenuItem>

        {selectedRow.status !== "active" && (
          <MenuItem
            key="resend"
            onClick={() => {
              // dispatch(
              //   sendInvitationAction.clearSendInvitationDetailsData()
              // );
              setIsResendInvitationModalOpen(true);
            }}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <ScheduleSendIcon />
              <Typography className="setting-menu-text">Resend</Typography>
            </Box>
          </MenuItem>
        )}

        {selectedRow.status === "active" && (
          <MenuItem
            key="delete"
            onClick={() => handleMenuItemClick("delete")}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <DeleteIconSVG />
              <Typography className="setting-menu-text">Delete</Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
      {editAlert && (
        <AddLeadsForm
          openPopup={editAlert}
          closeDialogBox={closeDialogBox}
          setOpenPopup={setEditAlert}
          from={"edit"}
          initialData={selectedRow}
        />
      )}
      {deleteAlert && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={deleteAlert}
            onClose={true}
            onCloseFun={() => {
              setDeleteAlert(false);
            }}
            mesage={
              "Are you sure you want to delete this record? This action can not be undone."
            }
            loader={addleadsLoader}
            footer={false}
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn mx-2"
                  onClick={() => setDeleteAlert(false)}
                >
                  No, Close
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={deleteRecord}
                >
                  Yes, Delete
                </Button>
              </>
            }
          />
        </>
      )}
      {alertMsg && getObjLength(leadDeletedData) && (
        <>
          {getObjLength(leadDeletedData) && leadDeletedData?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                onSuccess();
              }}
              mesage={successMsg}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                dispatch(addLeadsAction.clearData({}));
              }}
              mesage={errMsg}
              footer={false}
            />
          )}
        </>
      )}

      {/* {getObjLength(addLeadsData) ? (
        addLeadsData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              let url;
              if (terms) {
                url = `leads?limit=${PER_PAGE}&offset=${
                  (page - 1) * PER_PAGE
                }&type=${allTypes}&term=${encodeURIComponent(terms)}`;
              } else {
                url = `leads?limit=${PER_PAGE}&offset=${(page - 1) * PER_PAGE}`;
              }
              setOpenPopup(false);
              setEditAlert(false);
              dispatch(addLeadsAction.clearData({}));
              dispatch(
                addLeadsAction.getLeadsData({
                  url: url,
                  token: true,
                })
              );
            }}
            mesage={addLeadsData?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(addLeadsAction.clearData({}));
            }}
            mesage={addLeadsData?.data?.message}
            footer={false}
          />
        )
      ) : null} */}

      {/* resend invitation modal */}
      <ResendInvitationModal
        open={isResendInvitationModalOpen}
        handleClose={handleCloseResendModal}
        resendInvitaionHandler={handleResendInvitation}
        loader={sendInvitationListLoader}
      />

      {/* resend invitation response */}
      {getObjLength(resendInvitationDetails) ? (
        <>
          {resendInvitationDetails?.success ? (
            <AlertDialog
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                dispatch(addLeadsAction.setResendInvitationDetails());
                handleCloseResendModal();
              }}
              mesage={resendInvitationDetails?.message}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                dispatch(addLeadsAction.setResendInvitationDetails());
              }}
              mesage={
                resendInvitationDetails?.data?.message || "Something went wrong"
              }
              footer={false}
            />
          )}
        </>
      ) : null}

      <Loader open={addleadsLoader} />
    </>
  );
};

export default LeadsListTable;
