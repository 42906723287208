import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "react-bootstrap";

const PropertyListing = ({ data, selectProperty, handleToggle }) => {
  const [checked, setChecked] = useState(false);
  return (
    <Box sx={{ flexGrow: 1 }} mt={2} className="propertyList w-100">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ display: "flex" }} className="field-container">
          {data.address}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ display: "flex" }} className="field-container">
          {data.owner}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ display: "flex"}}className="field-container-btn">
          {!checked ? (
            <Button
              className="popup_Btn text-center success_popup_btn btn-width"
              sx={{
                // width: { xs: "100%", lg: "50%" }, // Set width to 100% for xs (mobile), 50% for lg (large screens)
              }}
              onClick={() => {
                selectProperty(data);
                handleToggle();
                setChecked(true);
              }}
            >
              Select
            </Button>
          ) : (
            <Button className="popup_Btn text-center other_popup_btn cursor-disable btn-width"   sx={{
              // width: { xs: "100%", lg: "50%" }, // Set width to 100% for xs (mobile), 50% for lg (large screens)
            }}>
              Select
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PropertyListing;