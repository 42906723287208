import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, FormHelperText, Stack } from "@mui/material";
import "./signup.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomizedCheckbox from "../common/customDesignedComponents/customCheckbox";
import { USA_STATES } from "../helpers/constants";

const validationSchema = Yup.object().shape({
  street_number: Yup.string()
    // .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Street number is required"),
  site_route: Yup.string()
    // .min(2, "Too Short!")
    .required("Street Name is required"),
  city: Yup.string("Please enter your City.")
    .strict(true)
    // .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // unit_no: Yup.string("Please enter your Unit .").required("Unit is required"),
});

export default function PropertyForm({
  propertyValues,
  onBtnClick,
  stateCodesData,
  otherPropertyValues,
  gotoQuestions,
  handleToggle,
}) {
  const [checkTerms, setCheckTerms] = useState(true);
  const [checked1, setChecked1] = useState(false);

  const onSubmit = (values, errors) => {
    setCheckTerms(checked1);
    if (checked1) {
      let searchedAdd = otherPropertyValues?.address;
      console.log(otherPropertyValues, "search add", values);
      if (
        values.street_number !== propertyValues?.street_number &&
        values.site_route !== propertyValues?.site_route
      ) {
        searchedAdd = values?.street_number + " " + values?.site_route;
        delete otherPropertyValues?.address;
      }
      // if (formAdd.length > 0) {
      //   delete otherPropertyValues?.address;
      // }

      if (values.unit_no?.length === 0) {
        delete values?.unit_no;
      }

      const data = Object.assign(values, otherPropertyValues);
      gotoQuestions({ ...data, address: searchedAdd });
      handleToggle();
    }
    // onBtnClick();
  };

  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };

  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const ss = Object.values(stateCodesData);
  //   const stateVal = ss.map((i, k) => (i === propertyValues.state ? k : null));
  const stateValue = ss.filter((e) => e === propertyValues?.state);

  return (
    <Box mt={3} className="">
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          street_number: propertyValues?.street_number || "",
          site_route: propertyValues?.site_route || "",
          city: propertyValues?.city || "",
          state:
            USA_STATES?.find(
              (state) =>
                state?.code === propertyValues?.state ||
                state?.name?.toLowerCase() ===
                  propertyValues?.state?.toLowerCase()
            )?.code || "",
          zip: propertyValues?.zip || "",
          unit_no: propertyValues?.unit_no || "",
          county: propertyValues?.county || "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => {
          return (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Street Number"
                    name="street_number"
                    value={values?.street_number}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    onChange={(event) => {
                      setFieldValue("street_number", event.target.value);
                    }}
                    helperText={touched.street_number && errors.street_number}
                    error={
                      touched.street_number && Boolean(errors.street_number)
                    }
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Street Name"
                    name="site_route"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.site_route}
                    onChange={(event) => {
                      setFieldValue("site_route", event.target.value);
                    }}
                    helperText={touched.site_route && errors.site_route}
                    error={touched.site_route && Boolean(errors.site_route)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="City"
                    name="city"
                    value={values?.city}
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    onChange={(event) => {
                      setFieldValue("city", event.target.value);
                    }}
                    helperText={touched.city && errors.city}
                    error={touched.city && Boolean(errors.city)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <FormControl
                    fullWidth
                    className="sign-textField "
                    error={touched.state && Boolean(errors.state)}
                  >
                    <InputLabel
                      // id="demo-simple-select-placeholder"
                      label="State"
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-placeholder"
                      id="demo-simple-select"
                      value={values?.state}
                      label="State"
                      name="state"
                      variant="outlined"
                      onChange={(event) => {
                        setFieldValue("state", event.target.value);
                      }}
                      MenuProps={MenuProps}
                    >
                      {USA_STATES?.map((state) => (
                        <MenuItem value={state?.code}>{state?.code}</MenuItem>
                      ))}
                    </Select>
                    {touched.state && Boolean(errors.state) && (
                      <FormHelperText sx={{ color: "#d32f2f !important" }}>
                        {touched.state && errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Zip"
                    name="zip"
                    type="number"
                    variant="outlined"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.zip}
                    onChange={(event) => {
                      setFieldValue("zip", event.target.value);
                    }}
                    helperText={touched.zip && errors.zip}
                    error={touched.zip && Boolean(errors.zip)}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1} pb={1}>
                  <TextField
                    className="sign-textField "
                    label="Unit"
                    placeholder={"Unit"}
                    name="unit_no"
                    variant="outlined"
                    // type="number"
                    sx={FieldStyle}
                    InputLabelProps={{
                      style: {
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#0D120B",
                      },
                    }}
                    value={values?.unit_no}
                    onChange={(event) => {
                      setFieldValue("unit_no", event.target.value);
                    }}
                    helperText={touched.unit_no && errors.unit_no}
                    error={touched.unit_no && Boolean(errors.unit_no)}
                  />
                </Grid>
              </Grid>
              <Box
                className={!checkTerms && "border border-danger"}
                textAlign={"start"}
                py={1}
                px={2}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"flex-start"}
                  alignItems={"start"}
                >
                  <span className="pt-1">
                    <CustomizedCheckbox onChecked={onChecked} />
                  </span>
                  <span>
                    For security reasons, I understand that I will be asked
                    challenge questions and/or my customer service PIN to verify
                    my identity. If I receive a call and I am NOT asked the
                    proper challenge questions, I will hang up and call Customer
                    Support immediately.
                  </span>
                </Stack>
              </Box>
              {!checkTerms && (
                <p className="text-danger m-3">
                  Please select given terms to proceed further.
                </p>
              )}
              <div className="text-center stepBtn mt-5">
                <Button
                  type="submit"
                  className="next_button mb-3"
                  variant="contained"
                >
                  Next Step
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
