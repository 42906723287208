import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useMediaQuery, useTheme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import ContactSupport from "../common/ContactSupport";
import SupportEmailLink from "../common/SupportEmailLink";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import {
  RECAPTCHA_SITE_KEY,
  secretKeyEncrypt,
  SERVER,
  SIGNUP_MARKETING_AUTOMATION_ID,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";
import {
  decryptPayload,
  encryptData,
  getAddressFromGoogleAutoComplete,
  getObjLength,
  getShortNameAddressFromGoogleAutoComplete,
  propertyRiskReportDataObj,
} from "../helpers/utils";

export const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
  address: Yup.string("Please enter your Address.")
    .trim("The Address cannot include leading and trailing spaces")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),

  city: Yup.string("Please enter your City.")
    .trim("The city cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),

  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // .min(6, "Zip needs 6 digits")
  // .max(6, "Zip needs 6 digits"),
  phone1: Yup.string("Please enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Phone number is required"),
  password: Yup.string("Please enter Password.")
    .required("Password is required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
    )
    .test(
      "no-spaces",
      "Password cannot contain any spaces",
      (value) => !/\s/.test(value)
    ),
  confirm_password: Yup.string("Please enter Confirm Password.")
    .required("Confirm Password is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .oneOf([Yup.ref("password")], "Both password need to be the same")
        .test(
          "no-spaces",
          "Password cannot contain any spaces",
          (value) => !/\s/.test(value)
        ),
    }),
});

const contactsValueStyle = {
  cursor: "pointer",
};

const SignInForm = (props) => {
  const queryString = window.location.search;
  let paramString = queryString.split("?")[1];
  // Create URL object
  const parsedUrl = new URL(window?.location?.href);
  const params = new URLSearchParams(parsedUrl?.search);
  // Extract tokens
  const reportToken = params.get("report_token");
  const resendToken = params.get("resend_token");

  let param_code = paramString?.split("=")[1];
  let valueOf = paramString?.split("=")[0];
  if (valueOf) {
    localStorage.setItem("valueOf", valueOf);
  }
  if (param_code) {
    localStorage.setItem("referral_code", param_code);
  }
  const dispatch = useDispatch();
  const signUpFormRef = useRef();
  const captchaRef = useRef();

  const [fromErr, setFormErr] = useState("err");
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [searched_Address, setSearched_Address] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [leadDetails, setLeadDetails] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [propertyData, setProeprtyData] = useState({});
  const [existingAdd, setExistingAdd] = useState("");
  const [host_code, setHost_Code] = useState(
    decryptPayload(localStorage.getItem("host_code"))
  );

  const storeData = useSelector((state) => state.signUpUser.createAcc);
  const accCreated = useSelector((state) => state.signUpUser.createAccResponse);
  const getInvitationDetails = useSelector(
    (state) => state.signUpUser.getInvitationDetails
  );
  const {
    stateList,
    checkReCAPTCHADetails,
    checkReCAPTCHALoader,
    getRiskReportDetails,
    userLoginLoader,
  } = useSelector((state) => state?.signUpUser);
  const { countyData } = useSelector((state) => state?.propertyRiskManagement);
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const lsRCode = localStorage.getItem("referral_code");
  const lcValueOf = localStorage.getItem("valueOf");
  console.log(lsRCode, "<=== lsRCode ==>", lcValueOf);
  const property_search_data = JSON.parse(
    localStorage.getItem("property_search_data")
  );

  if (lsRCode) {
    param_code = lsRCode;
  }
  if (lcValueOf) {
    valueOf = lcValueOf;
  }
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));
  const handleClickShowPassword = (setState) => setState((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // remove address item from localstorage
    // localStorage.removeItem("param");
    // localStorage.removeItem("fixParams");
    // localStorage.removeItem("searched_Add");
    // localStorage.removeItem("searched_Add_popup");

    // clear success-error captcha data
    dispatch(userSigupAction.setreCAPTCHA({}));

    dispatch(userSigupAction.getState({ url: "states", data: {} }));
    if (valueOf === "invite") {
      dispatch(
        userSigupAction.getInvitationDetails({
          url: "get-lead-details",
          data: { lead_id: param_code },
        })
      );
    }
    if (valueOf === "professional_code") {
      dispatch(
        userSigupAction.getInvitationDetails({
          url: "get-invite-details",
          data: { professional_code: param_code },
        })
      );
    }
    // if (valueOf === "ios") {
    //   window.webkit.messageHandlers.test.postMessage("Did finish loading");
    // webkit.messageHandlers.geocodeAddress.postMessage(
    //   {
    //       street: document.getElementById(“street”).value,
    //       city: document.getElementById(“city”).value,
    //       state: document.getElementById(“state”).value,
    //       country: document.getElementById(“country”).value
    //   });
    // }

    if (reportToken) {
      console.log("report_token=-->", reportToken);
      let data = {
        report_id: reportToken,
      };
      if (resendToken) {
        data.resend_token = resendToken;
      }
      dispatch(
        userSigupAction.getRiskReportDetails({
          url: "get-report-details",
          data: data,
        })
      );
    }

    if (param_code === "risk_report") {
      if (getObjLength(property_search_data)) {
        localStorage.setItem(
          "param",
          JSON.stringify(property_search_data?.param)
        );
        localStorage.setItem(
          "fixParams",
          JSON.stringify(property_search_data?.fixParams)
        );
        localStorage.setItem(
          "searched_Add",
          property_search_data?.searched_Add
        );
        localStorage.setItem("searched_Add_popup", true);
      }
      setExistingAdd(property_search_data?.searched_Add);
      setProeprtyData(property_search_data);
    }
  }, [dispatch]);

  useEffect(() => {
    if (storeData) {
      handleClose();
      localStorage.removeItem("CAPTCHA_Validate");
      props.onBtnClick();
    }
  }, [storeData]);

  useEffect(() => {
    if (getObjLength(accCreated)) {
      handleClose();
      if (accCreated?.success) {
        console.log("ss nofrom ->", window.__ss_noform);
        // Submit __ss_noform data
        if (SERVER === "production") {
          window.__ss_noform.push([
            "submit",
            function () {
              return console.log("__ss_noform submitted!");
            },
            SIGNUP_MARKETING_AUTOMATION_ID,
          ]);
        }
      }
    }
  }, [accCreated]);

  useEffect(() => {
    if (getInvitationDetails?.success) {
      let name = getInvitationDetails?.data?.name?.trim().split(" ");
      console.log(name, "emailVal==>", getInvitationDetails);
      setLeadDetails({
        first_name: name?.[0],
        last_name: name?.slice(1).join(" "),
        ...getInvitationDetails?.data,
      });
    }
  }, [getInvitationDetails]);

  const onSubmit = (values, errors) => {
    // delete values.phone1;
    // console.log("Form data 2", values);
    props.signupStep(1);
    console.log("values=>", values);

    if (values?.promo_code) {
      localStorage.setItem("promoCode", values?.promo_code);
    }
    console.log("values?.referral_code==>", values?.referral_code);
    if (values?.referral_code) {
      localStorage.setItem("referralCode", values?.referral_code);
    }

    let data = values;
    if (values?.referral_code?.length === 0) {
      // data = { ...values, referral_code };
      delete values?.referral_code;
      localStorage.removeItem("referralCode");
    }
    if (values?.promo_code?.length === 0) {
      delete values?.promo_code;
      localStorage.removeItem("promoCode");
    }
    data = {
      ...values,
      step: 0,
      step_key: "SEND_OTP",
      searched_address: searched_Address,
      county_id: countyData?.data?.id,
    };
    console.log("final data==>>", data);
    dispatch(userSigupAction.userSignupData({ url: "send-otp", data: data }));
    handleToggle();
  };
  const onClickSubmit = (err) => {
    setFormErr(err);
    // props.onBtnClick();
  };

  const handleClose = () => {
    setOpen(false);
    if (!accCreated?.success) {
      setOpenPopup(true);
      setRecaptchaToken(null);
      dispatch(userSigupAction.setreCAPTCHA({}));
      captchaRef?.current?.reset();
      signUpFormRef?.current?.setSubmitting(false);
    } else {
      captchaRef?.current?.reset();
      dispatch(userSigupAction.setreCAPTCHA({}));
      setRecaptchaToken(null);
      localStorage.removeItem("CAPTCHA_Validate");
    }
    // else {
    //   setOpenPopup(false);
    // }
  };
  const handleToggle = () => {
    setOpen(!open);
    // setOpenPopup(!openPopup);
  };

  const FieldStyle = {
    borderRadius: "3px",
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const addresss = (place) => {
    var address = place.formatted_address;
    console.log("Place values==?>", place);
    console.log(
      "Place values filterd==>",
      place.address_components.filter((i, k) =>
        i.types.includes("street_number")
      )
    );
    var value = address?.split(",");
    const count = value.length;
    const country = value[count - 1];
    const stateVal = value[count - 2];
    const city = value[count - 3];
    const streetAdd = value[count - 4] !== undefined ? value[count - 4] : "";
    var stateSplit = stateVal?.split(" ");
    var i = stateSplit?.length;
    var pincode = "";
    const state = stateSplit[1];
    if (i > 2) {
      pincode = stateSplit[2];
    }
    var ss = streetAdd?.split(" ");
    console.log(ss.length, "ss==>.", ss);
    const sn = place.address_components.filter((i, k) =>
      i.types.includes("street_number")
    );
    var streetNum = sn.length > 0 ? sn[0]?.long_name : "";
    const sr = place.address_components.filter((i, k) =>
      i.types.includes("route")
    );
    var site_route = sr.length > 0 ? sr[0]?.long_name : "";
    const condos = place.address_components.filter((i, k) =>
      i.types.includes("subpremise")
    );
    var units = condos.length > 0 ? condos[0]?.long_name : "";
    var county = getShortNameAddressFromGoogleAutoComplete(
      place,
      "administrative_area_level_2"
    );
    var county_name = county?.replace("County", "").trim();
    console.log(county, "<== county_name==>", county_name);
    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    let param = {
      street_number: streetNum,
      site_route: site_route,
      city: city,
      state: state,
      zip: pincode,
      unit_no: units,
      county: county_name,
    };
    let fixParams = {
      country: country,
      latitude: latitude,
      longitude: longitude,
      address: streetAdd,
    };
    setSearched_Address({
      param: param,
      fixed_params: fixParams,
      full_address: address,
    });
    console.log(fixParams, "map lag==>>", param);
    localStorage.setItem("param", JSON.stringify(param));
    localStorage.setItem("fixParams", JSON.stringify(fixParams));
    localStorage.setItem("searched_Add", address);
    localStorage.setItem("searched_Add_popup", true);
  };

  const Callto = ({ phone, children, className }) => {
    return (
      <a href={`tel:${phone}`} className={className}>
        {children}
      </a>
    );
  };

  const handleCaptchaChange = (captchaValue) => {
    console.log("captcha value: ", captchaValue);
    setRecaptchaToken(captchaValue);
  };

  useEffect(() => {
    console.log("cptcha called...");
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.data?.success &&
      !props?.resumePopup
    ) {
      if (localStorage.getItem("CAPTCHA_Validate")) {
        onSubmit(formValues);
      }
    }
  }, [checkReCAPTCHADetails]);

  useEffect(() => {
    if (getObjLength(getRiskReportDetails) && getRiskReportDetails?.success) {
      if (valueOf === "report_token") {
        let property_search_data = propertyRiskReportDataObj(
          getRiskReportDetails?.data
        );
        setHost_Code(getRiskReportDetails?.data?.promocode);
        setProeprtyData(property_search_data);
        setExistingAdd(getRiskReportDetails?.data?.address);
      }
    }
  }, [getRiskReportDetails]);

  // added scripts
  useEffect(() => {
    // Create a <script> element for Lead Gen GTM tags
    if (SERVER === "production") {
      const form_script = document.createElement("script");
      form_script.type = "text/javascript";
      form_script.innerHTML = `
      var __ss_noform = __ss_noform || [];
      __ss_noform.push([
        "baseURI",
        "https://app-3SCX408CYC.marketingautomation.services/webforms/receivePostback/MzY0MrYwMjEzAQA/"
      ]);
      __ss_noform.push([
        "form",
        "signup-form",
      "${SIGNUP_MARKETING_AUTOMATION_ID}"
      ]);
      __ss_noform.push(['submitType', 'manual']);
    `;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://koi-3SCX408CYC.marketingautomation.services/client/noform.js?ver=1.24";

      // Append the <script> element to the document's <body> tag
      const script_loaded = document.querySelector(
        'script[src="https://koi-3SCX408CYC.marketingautomation.services/client/noform.js?ver=1.24"]'
      );
      if (!script_loaded) {
        document.body.appendChild(form_script);
        document.body.appendChild(script);
      }

      // Clean up to remove the <script> element when the component unmounts
      return () => {
        document.body.removeChild(form_script);
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <>
      <Box>
        <Formik
          enableReinitialize
          validateOnChange
          innerRef={signUpFormRef}
          initialValues={{
            first_name:
              leadDetails?.first_name ||
              propertyData?.user_data?.first_name ||
              "",
            last_name:
              leadDetails?.last_name ||
              propertyData?.user_data?.last_name ||
              "",
            address: propertyData?.fixParams?.address || "",
            city: propertyData?.param?.city || "",
            state: propertyData?.param?.state_name || "",
            zip: propertyData?.param?.zip || "",
            email: leadDetails?.email || propertyData?.user_data?.email || "",
            phone: leadDetails?.phone || propertyData?.user_data?.phone || "",
            phone1: leadDetails?.phone || propertyData?.user_data?.phone || "",
            password: "",
            confirm_password: "",
            referral_code:
              valueOf === "ref" ? param_code : "" || leadDetails?.referral_code,
            promo_code:
              valueOf === "code"
                ? param_code
                : "" || host_code || leadDetails?.promo_code,
          }}
          validationSchema={validationSchema}
          // onSubmit={onSubmit}
          onSubmit={(values, validation) => {
            if (localStorage.getItem("searched_Add_popup") !== true) {
              localStorage.setItem(
                "param",
                JSON.stringify({
                  street_number:
                    values?.address && !isNaN(values?.address?.split(" ")?.[0])
                      ? values?.address?.split(" ")?.[0]
                      : "",
                  site_route: values?.address
                    ? isNaN(values?.address?.split(" ")?.[0])
                      ? values?.address
                      : values?.address?.split(" ")?.slice(1)?.join(" ")
                    : "",
                  city: values?.city || "",
                  state: values?.state || "",
                  unit_no: values?.unit_no || "",
                  zip: values?.zip || "",
                })
              );
              if (!localStorage.getItem("fixParams")) {
                localStorage.setItem(
                  "fixParams",
                  JSON.stringify({
                    country: "USA",
                    address: values?.address,
                  })
                );
              }
              localStorage.setItem(
                "searched_Add",
                [
                  values?.address,
                  values?.city,
                  values?.state,
                  values?.zip,
                  "USA",
                ]?.join(", ")
              );
              localStorage.setItem("searched_Add_popup", true);
            }

            if (!isValidPhoneNumber(values?.phone1)) {
              return;
            }
            if (!stateList?.find((val) => val.name === values?.state)) {
              // values.state = "";
              return;
            }

            if (!recaptchaToken) {
              return;
            }

            // window.grecaptcha.ready(() => {
            //   window.grecaptcha
            //     .execute(`${RECAPTCHA_SITE_KEY}`, {
            //       action: "create",
            //     })
            //     .then((token) => {
            localStorage.setItem("CAPTCHA_Validate", 1);
            dispatch(
              userSigupAction.checkreCAPTCHA({
                url: `validate-captcha?token=${recaptchaToken}`,
                data: {},
              })
            );
            //     });
            // });
            const payload = JSON.parse(JSON.stringify(values));
            payload.password = values?.password?.trim();
            const encryptedData = encryptData(
              payload.password,
              secretKeyEncrypt.toString()
            );
            payload.password = encryptedData;
            payload.confirm_password = encryptedData;
            // payload.role = "user";
            setFormValues(payload);
            // onSubmit(values);
          }}
        >
          {({ values, setFieldValue, touched, errors, isSubmitting }) => {
            // for the manage state validation
            if (!stateList?.find((val) => val.name === values?.state)) {
              values.state = "";
            } else {
              delete errors.state;
            }

            // handle selected address for sign up form
            const handleChange = (address) => {
              console.log("address changed==>", address);
              setFieldValue(
                "address",
                getAddressFromGoogleAutoComplete(address, "street_number") +
                  (getAddressFromGoogleAutoComplete(address, "street_number")
                    ? " "
                    : "") +
                  getAddressFromGoogleAutoComplete(address, "route")
              );
              setFieldValue(
                "city",
                getAddressFromGoogleAutoComplete(address, "locality")
              );
              setFieldValue(
                "state",
                getAddressFromGoogleAutoComplete(
                  address,
                  "administrative_area_level_1"
                )
              );
              setFieldValue(
                "zip",
                getAddressFromGoogleAutoComplete(address, "postal_code")
              );
              setFieldValue(
                "unit_no",
                getAddressFromGoogleAutoComplete(address, "subpremise")
              );
            };

            return (
              <Form id="signup-form">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="First name"
                      name="first_name"
                      value={values?.first_name}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"First Name"}
                      onChange={(event) => {
                        setFieldValue("first_name", event.target.value);
                      }}
                      helperText={touched.first_name && errors.first_name}
                      error={touched.first_name && Boolean(errors.first_name)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Last name"
                      name="last_name"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Last Name"}
                      value={values?.last_name}
                      onChange={(event) => {
                        setFieldValue("last_name", event.target.value);
                      }}
                      helperText={touched.last_name && errors.last_name}
                      error={touched.last_name && Boolean(errors.last_name)}
                    />
                  </Grid>
                  <Grid xs={12} md={12} px={1} pb={1}>
                    <Autocomplete
                      className="googleAutoComplete mw-100"
                      name="searched_address"
                      value={existingAdd}
                      apiKey={GOOGLE_API_KEY}
                      onChange={(e) => {
                        setExistingAdd(e.target.value);
                      }}
                      onPlaceSelected={(address) => {
                        setExistingAdd(address?.formatted_address);
                        handleChange(address);
                        addresss(address);
                      }}
                      onKeyDown={(event) =>
                        event.key === "Enter" ? event.preventDefault() : null
                      }
                      placeholder="Enter your property address"
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "us" },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Address"
                      name="address"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Address"}
                      value={values?.address}
                      onChange={(event) => {
                        setFieldValue("address", event.target.value);
                      }}
                      helperText={touched.address && errors.address}
                      error={touched.address && Boolean(errors.address)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="City"
                      name="city"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"City"}
                      value={values?.city}
                      onChange={(event) => {
                        setFieldValue("city", event.target.value);
                      }}
                      helperText={touched.city && errors.city}
                      error={touched.city && Boolean(errors.city)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <FormControl
                      fullWidth
                      className="sign-textField"
                      error={touched.state && Boolean(errors.state)}
                    >
                      <InputLabel
                        shrink
                        // id="demo-simple-select-placeholder"
                        style={{ color: "#00000075" }}
                        label="State"
                      >
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-placeholder"
                        id="demo-simple-select"
                        value={values?.state}
                        label="State"
                        name="state"
                        variant="outlined"
                        onChange={(event) => {
                          setFieldValue("state", event.target.value);
                        }}
                        MenuProps={MenuProps}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <span style={{ color: "#00000075" }}>State</span>
                        </MenuItem>
                        {stateList?.map((state) => (
                          <MenuItem value={state.name}>{state.name}</MenuItem>
                        ))}
                      </Select>
                      {touched.state && Boolean(errors.state) && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {touched.state && errors.state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // length={6}
                      // id="outlined-basic"
                      label="Zip"
                      name="zip"
                      type="number"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Zip"}
                      value={values?.zip}
                      onChange={(event) => {
                        setFieldValue("zip", event.target.value);
                      }}
                      helperText={touched.zip && errors.zip}
                      error={touched.zip && Boolean(errors.zip)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      label="Unit Number"
                      name="unit_no"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Unit Number"}
                      value={values?.unit_no}
                      onChange={(event) => {
                        setFieldValue("unit_no", event.target.value);
                      }}
                      helperText={touched.unit_no && errors.unit_no}
                      error={touched.unit_no && Boolean(errors.unit_no)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} px={1} pb={1}></Grid>
                  <hr />
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Email Address"
                      name="email"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Email Address"}
                      value={values?.email}
                      onChange={(event) => {
                        setFieldValue("email", event.target.value);
                      }}
                      onBlur={(event) =>
                        setFieldValue("email", event.target.value)
                      }
                      helperText={touched.email && errors.email}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <CustomPhoneNumberInput
                      name="phone1"
                      values={values}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      otherProps={{
                        onChange: (event) => {
                          setFieldValue("phone1", event);
                          setFieldValue("phone", event);
                          if (!event) {
                            setFieldValue("phone1", "");
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <OutlinedInput
                      className="textfield"
                      type={showPassword ? "text" : "password"}
                      placeholder="Create Your Password"
                      name="password"
                      sx={{ backgroundColor: "#f6f9fc", outline: "none" }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword(setShowPassword)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={values?.password}
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("password", event.target.value?.trim());
                      }}
                      helperText={touched.password && errors.password}
                      error={touched.password && Boolean(errors.password)}
                    />
                    {touched.password && Boolean(errors.password) && (
                      <FormHelperText
                        sx={{
                          color: "rgb(211, 47, 47) !important",
                          fontSize: "12px !important",
                          m: "0 !important",
                          maxWidth: "100% !important",
                        }}
                      >
                        {touched.password && errors.password}
                      </FormHelperText>
                    )}
                    {/* <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Create your password"
                      type="password"
                      name="password"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Create your password"}
                      value={values?.password}
                      onChange={(event) => {
                        setFieldValue("password", event.target.value);
                      }}
                      helperText={touched.password && errors.password}
                      error={touched.password && Boolean(errors.password)}
                    /> */}
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <OutlinedInput
                      className="textfield"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Your Password"
                      name="confirm_password"
                      sx={{ backgroundColor: "#f6f9fc" }}
                      inputProps={{
                        autoComplete: "new-confirm_password",
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm_password visibility"
                            onClick={() =>
                              handleClickShowPassword(setShowConfirmPassword)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={values?.confirm_password}
                      fullWidth
                      onChange={(event) => {
                        setFieldValue(
                          "confirm_password",
                          event.target.value?.trim()
                        );
                      }}
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      error={
                        touched.confirm_password &&
                        Boolean(errors.confirm_password)
                      }
                    />
                    {touched.confirm_password &&
                      Boolean(errors.confirm_password) && (
                        <FormHelperText
                          sx={{
                            color: "rgb(211, 47, 47) !important",
                            fontSize: "12px !important",
                            m: "0 !important",
                            maxWidth: "100% !important",
                          }}
                        >
                          {touched.confirm_password && errors.confirm_password}
                        </FormHelperText>
                      )}
                    {/* <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      type="password"
                      label="Confirm your password"
                      name="confirm_password"
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Confirm your password"}
                      value={values?.confirm_password}
                      onChange={(event) => {
                        setFieldValue("confirm_password", event.target.value);
                      }}
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      error={
                        touched.confirm_password &&
                        Boolean(errors.confirm_password)
                      }
                    /> */}
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      // id="outlined-basic"
                      label="Referral Code"
                      name="referral_code"
                      value={values?.referral_code}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Optional"}
                      onChange={(event) => {
                        setFieldValue("referral_code", event.target.value);
                      }}
                      // helperText={touched.referral_code && errors.referral_code}
                      // error={touched.referral_code && Boolean(errors.referral_code)}
                    />
                  </Grid>
                  <Grid xs={12} md={6} px={1} pb={1}>
                    <TextField
                      className="sign-textField "
                      label="Promo Code"
                      name="promo_code"
                      value={values?.promo_code}
                      variant="outlined"
                      sx={FieldStyle}
                      InputLabelProps={{
                        style: {
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#00000075",
                        },
                        shrink: true,
                      }}
                      placeholder={"Optional"}
                      onChange={(event) => {
                        setFieldValue("promo_code", event.target.value);
                      }}
                      // helperText={touched.promo_code && errors.promo_code}
                      // error={touched.promo_code && Boolean(errors.promo_code)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center my-2"
                  >
                    <FormControl className="textField captcha-field">
                      <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                        className="g-recaptcha"
                        ref={captchaRef}
                      />
                    </FormControl>
                  </Grid>
                  {isSubmitting && !recaptchaToken && (
                    <p className="text-danger text-center w-100 mb-3">
                      {isSubmitting && !recaptchaToken
                        ? "Please verify reCAPTCHA"
                        : fromErr}
                    </p>
                  )}
                  <Grid xs={12} md={12} px={1} pb={1}>
                    <div className="text-center createAcBtn mt-2">
                      <Button
                        onClick={() => onClickSubmit(errors)}
                        type="submit"
                        className="next_button"
                        variant="contained"
                      >
                        Create your account
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {/* {storeData ? (
          <p className="text-sucess">{accCreated.message}</p>
        ) : (
          <p className="text-danger">{accCreated && accCreated}</p>
        )} */}
      </Box>
      <Divider className="m-3" />
      <Typography
        className={`para ${isPadding && "apex-padding"}`}
        align="center"
        variant="body1"
      >
        If you do not have a mobile device please contact us at{" "}
        <span>
          <Callto phone={`${SUPPORT_PHONE}`} className="">
            {SUPPORT_PHONE_FORMAT}
          </Callto>
        </span>{" "}
        or email support at{" "}
        <SupportEmailLink
          email={SUPPORT_EMAIL}
          className="green-link text-blue"
          sx={contactsValueStyle}
          text={SUPPORT_EMAIL}
        />
      </Typography>
      {getObjLength(accCreated) && !accCreated?.success && openPopup ? (
        <AlertDialog
          type={accCreated?.data?.errors?.user_exist ? "Warning" : "Error"}
          openPopup={openPopup}
          onClose={true}
          onCloseFun={() => {
            props.reduceErrorCount();
            dispatch(userSigupAction.resetCreateAccResponse());
            setOpenPopup(false);
          }}
          buttons={
            accCreated?.data?.errors?.user_exist && (
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  setOpenPopup(false);
                  props.reduceErrorCount();
                  dispatch(userSigupAction.resetCreateAccResponse());
                }}
              >
                ok, Close
              </Button>
            )
          }
          mesage={
            <>
              <p className={props.errorPopup ? `text-danger` : ""}>
                {!accCreated?.data?.success && accCreated?.data?.message}
              </p>
              {props.errorPopup && (
                <p>
                  <ContactSupport />
                </p>
              )}
            </>
          }
        />
      ) : null}

      {/* {getObjLength(checkReCAPTCHADetails) &&
        !props?.resumePopup &&
        !checkReCAPTCHADetails?.data?.success && (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(userSigupAction.setreCAPTCHA({}));
            }}
            mesage={checkReCAPTCHADetails?.data?.message}
            footer={false}
          />
        )} */}

      <Loader open={open || userLoginLoader || checkReCAPTCHALoader} />
    </>
  );
};

export default SignInForm;
