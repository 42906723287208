import { useEffect } from "react";
import { useCallback } from "react";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import HistoryIcon from "@mui/icons-material/History";
import TableContainer from "@mui/material/TableContainer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../common/loader";
import RenewPlanModal from "./RenewPlanModal";
import AlertDialog from "../common/AlertDialog";
import { PER_PAGE, ROLES } from "../helpers/constants";
import SubscriptionHistoryModal from "./SubscriptionHistoryModal";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import { subscriptionAction } from "../../redux/slices/subscription/subscriptionSlice";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../admin/AddLeads";
import SubscriptionDetailsDialog from "../common/userViewPropertyTabsComponents/SubscriptionDetailsDialog";
import {
  currencyFormatter,
  decryptPayload,
  getDateInYYYYMMDDFormat,
  getObjLength,
  roundOfNum,
} from "../helpers/utils";

const filterMenu = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
  },
  {
    id: "plan",
    disablePadding: true,
    label: "Subscription Plan",
  },
  {
    id: "sub_amount",
    disablePadding: true,
    label: "Subscription Amount",
  },
  {
    id: "status",
    disablePadding: true,
    label: "Status",
  },
  {
    id: "sub_date",
    disablePadding: true,
    label: "Dates",
  },
];

const btn_style = {
  backgroundColor: "#15be53 !important",
};

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props?.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row({
  row,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
  isSecondaryUser,
  selectRenewalProperties,
  setSelectedRow,
  setRetryPayment,
  handleSubscriptionModalOpen,
  subscriptionIds,
  selectedRetrySubscriptionIds,
  setRetrySubscriptionIds,
  handleGetEstimatedAmount,
}) {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={(e) => {
          handleCollapsibleRow(row);
        }}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="center">
          {((row?.subscription?.expired &&
            row?.subscription?.cancel_at === null) ||
            row?.subscription?.payment_status === "failed") &&
          // row?.subscription?.auto_renew === 0 &&
          !isSecondaryUser &&
          row.is_owner ? (
            <Tooltip
              title="Select Property to Renew Plan"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Checkbox
                color="primary"
                onChange={(e) => {
                  if (
                    row?.subscription?.expired &&
                    row?.subscription?.cancel_at === null
                  ) {
                    selectRenewalProperties(e, row);
                  } else if (row?.subscription?.payment_status === "failed") {
                    setRetrySubscriptionIds(row);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                checked={
                  subscriptionIds?.includes(row?.subscription?.id) ||
                  selectedRetrySubscriptionIds?.includes(row?.subscription?.id)
                }
              />
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>

        <TableCell align="left">{row?.address}</TableCell>
        <TableCell align="left">{row?.subscription?.plan?.name}</TableCell>
        <TableCell align="left">
          {row?.subscription?.plan?.amount ? (
            <span>
              ${roundOfNum(parseInt(row?.subscription?.plan?.amount) / 100)}
            </span>
          ) : (
            "$0.00"
          )}
        </TableCell>

        {/* Paid Amount */}
        <TableCell align="left">
          {row?.estimated_amount ? currencyFormatter(row?.pay_amount) : "$0.00"}
        </TableCell>

        <TableCell align="left">{row?.subscription?.start_at}</TableCell>
        <TableCell align="left">{row?.subscription?.end_at}</TableCell>
        <TableCell align="left">
          {row?.subscription?.expired &&
          row?.subscription?.cancel_at === null ? (
            <span className="text-danger">Expired</span>
          ) : row?.subscription?.cancel_at !== null ? (
            <span className="text-danger">Cancelled</span>
          ) : row?.subscription?.payment_status === "success" ? (
            <span className="text-success">Active</span>
          ) : (
            // ) : row?.subscription?.payment_status === 1 ? (
            <span className="text-danger">Failed</span>
          )}
        </TableCell>

        <TableCell align="left">
          {row?.subscription?.expired &&
          row?.subscription?.cancel_at === null &&
          row.is_owner &&
          // row?.subscription?.auto_renew === 0 &&
          !isSecondaryUser ? (
            <Tooltip
              title="Your Subscription is expired please Renew the Plan"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRow(row);
                  handleGetEstimatedAmount(row);
                  // getPlanRenewed(row);
                }}
                sx={btn_style}
                className="px-3 error_popup_btn text-white mt-2"
              >
                Renew
              </Button>
            </Tooltip>
          ) : !isSecondaryUser &&
            row.is_owner &&
            row?.subscription?.payment_status === "failed" ? (
            <Tooltip
              title="Your payment got failed please try again"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRow(row);
                  setRetryPayment(true);
                  handleGetEstimatedAmount(row);
                  // getPlanRenewed(row);
                }}
                sx={btn_style}
                className="px-3 error_popup_btn text-white mt-2"
              >
                Retry
              </Button>
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            <Tooltip
              title="View Subscription History"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(propertiesAction.setViewDetailsPropertyData(row));
                  handleSubscriptionModalOpen();
                }}
                size="small"
                className="settings-button"
              >
                <HistoryIcon sx={{ color: "#15BE53" }} />
              </IconButton>
            </Tooltip>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      {/* collapsible row */}
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Discount
                </Typography>
                <Typography className="content-text">
                  {row?.discount !== null
                    ? currencyFormatter(row?.discount)
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Sales/Local Tax
                </Typography>
                <Typography className="content-text">
                  {row?.sales_tax ? currencyFormatter(row?.sales_tax) : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Deposit for recording fees
                </Typography>
                <Typography className="content-text">
                  {row?.recording_charge
                    ? currencyFormatter(row?.recording_charge)
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Setup Fees
                </Typography>
                <Typography className="content-text">
                  {row?.["setup fees"]
                    ? currencyFormatter(row?.["setup fees"])
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Estimated Amount
                </Typography>
                <Typography className="content-text">
                  {row?.estimated_amount
                    ? currencyFormatter(row?.estimated_amount)
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={3}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Refund Amount
                </Typography>
                <Typography className="content-text">
                  {row?.refund ? currencyFormatter(row?.refund) : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SubscriptionListTable = ({ columns, setMetData }) => {
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState({});
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [allTypes, setAllTypes] = useState("all");
  const [status, setStatus] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [terms, setTerms] = useState("");
  const [page, setPage] = useState(1);
  const [isSubscriptionHistoryModalOpen, setIsSubscriptionHistoryModalOpen] =
    useState(false);
  const [isRenewPlanModalOpen, setIsRenewPlanModalOpen] = useState(false);
  const [isRenewFinalAmtModalOpen, setIsRenewFinalAmtModalOpen] =
    useState(false);
  const [valdiateModal, setValdiateModal] = useState(false);
  const [subscriptionIds, setSubscriptionIds] = useState([]);
  const [bulkPayment, setBulkPayment] = useState(false);
  const [retryPayment, setRetryPayment] = useState(false);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] =
    React.useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = React.useState(false);
  const [selectedRetrySubscriptionIds, setSelectedRetrySubscriptionIds] =
    useState([]);
  const [retryBulkPayment, setRetryBulkPayment] = useState(false);
  const [propertiesIds, setPropertiesIds] = useState([]);
  const [isSubscriptionDetailsModalOpen, setIsSubscriptionDetailsModalOpen] =
    useState(false);
  const [retryPropertiesIds, setRetryPropertiesIds] = useState([]);

  // store data
  const {
    subscriptionRenewed,
    subscriptionData,
    totalSubscriptionData,
    subscriptionLoader,
    bulkPlanRenewalData,
    retryPaymentData,
  } = useSelector((state) => state?.subscription);
  const {
    loggedInEmail,
    loginAsSpouse: isSecondaryUser,
    parent_id,
  } = useSelector((state) => state?.userProfile);
  // store data
  const { totalPaymentLoader } = useSelector((state) => state?.signUpUser);

  const count = Math.ceil(totalSubscriptionData / PER_PAGE);

  // get url for customer or sub user
  const getUrl = useCallback(() => {
    let url;
    if (isSecondaryUser) {
      url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "customer"
      }/property-subscription/${parent_id}`;
    } else {
      url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "customer"
      }/property-subscription`;
    }
    return url;
  }, [isSecondaryUser, parent_id]);

  const handlePageChange = (e, p) => {
    setPage(p);
    // _DATA.jump(p);
    dispatch(
      subscriptionAction.getSubscriptionData({
        url: getSubscriptionLostingURL(p),
        data: {},
        token: true,
      })
    );
  };

  const handleSubscriptionModalOpen = () => {
    setIsSubscriptionHistoryModalOpen(true);
  };

  const handleSubscriptionModalClose = () => {
    dispatch(propertiesAction.setViewDetailsPropertyData({}));
    setIsSubscriptionHistoryModalOpen(false);
  };

  const getSubscriptionLostingURL = (page = 1) => {
    let url;
    if (
      terms.length > 0 ||
      getObjLength(selectedDateRange) ||
      status.length > 0
    ) {
      const type = allTypes ? "&type=" + allTypes : "";
      const value = terms ? "&term=" + encodeURIComponent(terms) : "";
      const planStatus = status ? "&status=" + status : "";
      const selectedDateRangePayload = selectedDateRange
        ? `&sub_start_date=${
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) ||
            ""
          } &sub_end_date=${
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) || ""
          }`
        : "";

      url = `${getUrl()}?limit=${PER_PAGE}&offset=${
        (page - 1) * PER_PAGE
      }${type}${value}${planStatus}${selectedDateRangePayload}`;
    } else {
      url = `${getUrl()}?limit=${PER_PAGE}&offset=${(page - 1) * PER_PAGE}`;
    }

    return url;
  };

  const filterOptions = () => {
    if (
      terms.length > 0 ||
      getObjLength(selectedDateRange) ||
      status.length > 0
    ) {
      setCallApi(true);
      dispatch(
        subscriptionAction.getSubscriptionData({
          url: getSubscriptionLostingURL(page),
          data: {},
          token: true,
        })
      );
    }
  };

  const resetFilterStates = () => {
    console.log("Resetting filter states");
    setAllTypes("all");
    setTerms("");
    setSelectedDateRange(null);
    setStatus("");
  };

  const resetData = () => {
    resetFilterStates();
    console.log("setCallApi==>", callApi);
    if (callApi) {
      getSubscriptionData();
      setPage(1);
      setCallApi(false);
    }
  };

  const selectColumn = (event) => {
    setAllTypes(event.target.value);
    setTerms("");
    setStatus("");
    setSelectedDateRange(null);
  };

  // get subscription data list
  const getSubscriptionData = useCallback(() => {
    let plan_renewal = localStorage.getItem("plan_renewal");
    let url = `${getUrl()}?limit=${PER_PAGE}&offset=${0}`;
    if (plan_renewal) {
      url = `${getUrl()}?limit=${PER_PAGE}&offset=${0}&id=${plan_renewal}`;
    }

    dispatch(
      subscriptionAction.getSubscriptionData({
        url: url,
        data: {},
        token: true,
      })
    );
  }, [dispatch, getUrl]);

  const onSuccess = () => {
    dispatch(subscriptionAction.clearData({}));
    setPropertiesIds([]);
    setSubscriptionIds([]);
    setSelectedRetrySubscriptionIds([]);
    getSubscriptionData();
  };

  const handleRenewPlanModalClose = () => {
    dispatch(subscriptionAction.clearData({}));
    setBulkPayment(false);
    setRetryBulkPayment(false);
    setIsRenewPlanModalOpen(false);
  };

  const handleRenewPlan = (selectedCard) => {
    let subId = selectedRow?.subscription?.id;
    console.log(subId, "getPlanRenewed==>", selectedRow);
    console.log(subId, "selectedCard==>", selectedCard, selectedCard?.[0]?.id);

    let metadata = {};
    if (selectedRow) {
      metadata = {
        property_id: selectedRow?.property_id,
        property_address: selectedRow?.address,
        email: loggedInEmail,
        subscriptionId: subId,
      };
    }
    console.log("plan_details==>", metadata);
    setMetData(metadata);
    if (subId && !bulkPayment && !retryBulkPayment) {
      dispatch(subscriptionAction.getPropertiesDataForMetaPixel(subId));
      if (!retryPayment) {
        dispatch(
          subscriptionAction.getSubscriptionRenewed({
            url: `customer/subscription-renew/${subId}?card_id=${selectedCard?.[0]?.id}`,
            token: true,
          })
        );
      } else {
        // for retrying the subscription payment
        console.log("retry payment", retryPayment);
        dispatch(
          subscriptionAction.getRetryPaymentData({
            url: `re-payment/${subId}`,
            data: {
              card_id: selectedCard?.[0]?.id,
            },
            token: true,
          })
        );
      }
    } else {
      if (bulkPayment) {
        // for retry bulk payment of the subscription
        if (retryBulkPayment) {
          dispatch(
            subscriptionAction.getPropertiesDataForMetaPixel(
              selectedRetrySubscriptionIds
            )
          );
          dispatch(
            subscriptionAction.getBulkPlanRenewalData({
              url: "re-payment",
              data: {
                subscription_ids: selectedRetrySubscriptionIds,
                card_id: selectedCard?.[0]?.id,
              },
              token: true,
            })
          );
        } else {
          // for bulk payment of the subscription renewal
          dispatch(
            subscriptionAction.getPropertiesDataForMetaPixel(subscriptionIds)
          );
          dispatch(
            subscriptionAction.getBulkPlanRenewalData({
              url: "customer/subscription-renew",
              data: {
                subscription_ids: subscriptionIds,
                card_id: selectedCard?.[0]?.id,
              },
              token: true,
            })
          );
        }
      }
    }
  };

  const selectRenewalProperties = (event, row) => {
    let Plan_id = row?.subscription?.id;
    console.log("Row==>", row);
    // var subscription_ids = []
    if (subscriptionIds?.includes(row?.subscription?.id)) {
      // to remove deselectd properties
      setSubscriptionIds(subscriptionIds?.filter((e) => e !== Plan_id));
      setPropertiesIds(
        propertiesIds.filter((e) => e !== row?.subscription?.property_id)
      );
    } else {
      // to add selectd properties
      setSubscriptionIds((prev) => [...prev, Plan_id]);
      setPropertiesIds((prev) => [...prev, row?.subscription?.property_id]);
    }
    console.log(Plan_id, "selected values==>", event.target.checked);
  };

  const retryBulkPlans = () => {
    console.log("retry bulk payment");
    setBulkPayment(true);
    handleGetEstimatedAmount(null, true);
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  // set retry subscription ids
  const setRetrySubscriptionIds = (row) => {
    if (selectedRetrySubscriptionIds?.includes(row?.subscription?.id)) {
      setSelectedRetrySubscriptionIds((prevState) =>
        prevState?.filter((ids) => ids !== row?.subscription?.id)
      );
      setRetryPropertiesIds(
        retryPropertiesIds.filter((e) => e !== row?.subscription?.property_id)
      );
    } else {
      setSelectedRetrySubscriptionIds((prevState) => [
        ...prevState,
        row?.subscription?.id,
      ]);
      setRetryPropertiesIds((prev) => [
        ...prev,
        row?.subscription?.property_id,
      ]);
    }
  };

  const handleGetEstimatedAmount = (row, isRetryBulkPayment = false) => {
    let data;
    if (row) {
      data = {
        property_id: [row?.subscription?.property_id],
      };
    } else {
      data = {
        property_id: isRetryBulkPayment ? retryPropertiesIds : propertiesIds,
      };
    }
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
    dispatch(
      userSigupAction.getTotalPaymentDetails({
        url: `get-amount`,
        data: data,
        token: true,
      })
    );
    setIsRenewFinalAmtModalOpen(true);
  };

  const renewBulkPlans = () => {
    setBulkPayment(true);
    // setIsRenewPlanModalOpen(true);
    handleGetEstimatedAmount(null, false);
  };

  const handleMobileSubscriptionDetailsModalClose = () => {
    setIsSubscriptionDetailsModalOpen(false);
    dispatch(propertiesAction.setViewDetailsPropertyData({}));
  };

  // console.log(
  //   "subscriptionIds",
  //   subscriptionIds,
  //   "selectedRetrySubscriptionIds",
  //   selectedRetrySubscriptionIds,
  //   "propertiesIds",
  //   propertiesIds,
  //   "retryPropertiesIds",
  //   retryPropertiesIds,
  //   "bulkPlanRenewalData",
  //   bulkPlanRenewalData,
  //   "retryPaymentData",
  //   retryPaymentData,
  //   "subscriptionRenewed",
  //   subscriptionRenewed
  // );

  const resetSelectedSubscriptions = () => {
    setPropertiesIds([]);
    setSubscriptionIds([]);
    setSelectedRetrySubscriptionIds([]);
    setRetryPropertiesIds([]);
    dispatch(
      subscriptionAction.getSubscriptionData({
        url: getSubscriptionLostingURL(page),
        data: {},
        token: true,
      })
    );
  };

  useEffect(() => {
    getSubscriptionData();
  }, [getSubscriptionData]);

  useEffect(() => {
    if (getObjLength(subscriptionRenewed)) {
      if (subscriptionRenewed?.success) {
        if (subscriptionRenewed?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !subscriptionRenewed?.data?.actionRequired &&
          subscriptionRenewed?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, getSubscriptionData, subscriptionRenewed]);

  useEffect(() => {
    if (getObjLength(bulkPlanRenewalData)) {
      if (bulkPlanRenewalData?.success) {
        if (bulkPlanRenewalData?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !bulkPlanRenewalData?.data?.actionRequired &&
          bulkPlanRenewalData?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, bulkPlanRenewalData, getSubscriptionData]);

  useEffect(() => {
    if (getObjLength(retryPaymentData)) {
      if (retryPaymentData?.success) {
        if (retryPaymentData?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !retryPaymentData?.data?.actionRequired &&
          retryPaymentData?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, getSubscriptionData, retryPaymentData]);

  useEffect(() => {
    if (getObjLength(subscriptionData)) {
      if (subscriptionData?.success) {
        console.log("called from subscription table", subscriptionData);
        localStorage.removeItem("plan_renewal");
      }
    }
  }, [subscriptionData]);

  return (
    <>
      {/* Filtering Component Section */}
      <Box
        className={`d-flex justify-content-between w-100 ${
          subscriptionIds?.length > 0 &&
          selectedRetrySubscriptionIds?.length > 0
            ? "flex-column"
            : ""
        } flex-sm-row`}
      >
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          className={`${
            subscriptionIds?.length > 0 &&
            selectedRetrySubscriptionIds?.length > 0
              ? "justify-content-between"
              : "justify-content-end"
          } pe-3`}
        >
          {subscriptionIds?.length > 0 ? (
            <Tooltip
              title="Renew the select Properties Plan"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Badge
                badgeContent={subscriptionIds?.length || 0}
                color="error"
                showZero
              >
                <Button
                  sx={btn_style}
                  className="m-2 px-3 error_popup_btn text-white"
                  disabled={subscriptionIds?.length === 0 ? true : false}
                  onClick={renewBulkPlans}
                >
                  Renew Plans
                </Button>
              </Badge>
            </Tooltip>
          ) : null}
          {selectedRetrySubscriptionIds?.length > 0 ? (
            <Tooltip
              title="Retry the selected subscription Plan"
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <Badge
                badgeContent={selectedRetrySubscriptionIds?.length || 0}
                color="error"
                showZero
              >
                <Button
                  className="m-2 px-3 error_popup_btn text-white"
                  sx={{ cursor: "pointer !important", ...btn_style }}
                  disabled={
                    selectedRetrySubscriptionIds?.length === 0 ? true : false
                  }
                  onClick={() => {
                    setRetryBulkPayment(true);
                    retryBulkPlans();
                  }}
                >
                  Retry Plans
                </Button>
              </Badge>
            </Tooltip>
          ) : null}
        </Stack>
      </Box>
      {/* Filtering Options */}
      <Box
        sx={{
          // overflow: "hidden",
          // overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none mt-3">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      name="type"
                      onChange={(event) => {
                        selectColumn(event);
                      }}
                      value={allTypes}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {filterMenu.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.id !== "sub_date" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {allTypes !== "status" && allTypes !== "sub_date" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl
                      className="filter-search w-100"
                      variant="outlined"
                    >
                      <InputLabel>Enter search term</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        }
                        label="Enter search term"
                        name="terms"
                        type={allTypes === "sub_amount" ? "number" : ""}
                        onChange={(event) => {
                          setTerms(event.target.value);
                        }}
                        value={terms}
                      />
                    </FormControl>
                  </Grid>
                )}
                {allTypes === "sub_date" && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                {(allTypes === "all" || allTypes === "status") && (
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Subscription Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"expired"}>Expired</MenuItem>
                        <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={filterOptions}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={() => {
                      resetData();
                    }}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card className="d-none d-md-block filter-options-carddiv">
              <Grid container className="filter-options-box">
                <Stack direction="row" spacing={2} alignItems="center">
                  <div className="search-text m-3">Search By</div>
                  <FormControl className="filter-select-one">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      name="type"
                      onChange={(event) => {
                        selectColumn(event);
                      }}
                      value={allTypes}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {filterMenu.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.id !== "sub_date" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Stack>

                {allTypes !== "sub_date" && allTypes !== "status" && (
                  <Grid item>
                    <FormControl className="filter-search " variant="outlined">
                      <InputLabel>Enter search term</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        }
                        label="Enter search term"
                        name="terms"
                        type={allTypes === "sub_amount" ? "number" : ""}
                        onChange={(event) => {
                          setTerms(event.target.value);
                        }}
                        value={terms}
                      />
                    </FormControl>
                  </Grid>
                )}
                {(allTypes === "all" || allTypes === "status") && (
                  <Grid item>
                    <FormControl className="filter-select-one ">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Subscription Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"expired"}>Expired</MenuItem>
                        <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {allTypes === "sub_date" && (
                  <Grid item>
                    <FormControl className="filter-search ">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControl>
                      <Button
                        type="submit"
                        onClick={filterOptions}
                        className="filter-button"
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      type="reset"
                      onClick={() => {
                        resetData();
                      }}
                      className="reset-filters"
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>

      {/* Property Listing Table */}
      {/* mobile screen */}
      <Box
        sx={{ paddingBottom: "130px" }}
        className="d-block d-sm-none mobile-table bg-white px-0 mb-3 "
      >
        {subscriptionData?.data?.map((row, index) => {
          return (
            <>
              <Stack alignItems="flex-end" mx={1}>
                <Tooltip
                  title="View Subscription History"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch(
                        propertiesAction.setViewDetailsPropertyData(row)
                      );
                      handleSubscriptionModalOpen();
                    }}
                    size="small"
                    sx={{ color: "#15BE53" }}
                  >
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={0}
                px={1.4}
                mb={2}
              >
                <Tooltip
                  title="Select Property to Renew Plan"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      if (
                        row?.subscription?.expired &&
                        row?.subscription?.cancel_at === null
                      ) {
                        selectRenewalProperties(e, row);
                      } else if (
                        row?.subscription?.payment_status === "failed"
                      ) {
                        setRetrySubscriptionIds(row);
                      }
                    }}
                    onClick={(e) => e.stopPropagation()}
                    disabled={
                      !(
                        (row?.subscription?.expired &&
                          row?.subscription?.cancel_at === null) ||
                        row?.subscription?.payment_status === "failed"
                      ) || isSecondaryUser
                    }
                    checked={
                      subscriptionIds?.includes(row?.subscription?.id) ||
                      selectedRetrySubscriptionIds?.includes(
                        row?.subscription?.id
                      )
                    }
                  />
                </Tooltip>
                <Typography
                  className="value fw-500"
                  sx={{ fontSize: "15px !important" }}
                >
                  {row?.address || "-"}
                </Typography>
              </Stack>
              <Stack direction="column" spacing={1.2} px={3}>
                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Subscription Plan:</Typography>
                  <Typography className="value">
                    {row?.subscription?.plan?.name || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Subscription Amount:</Typography>
                  <Typography className="value">
                    {row?.subscription?.plan?.amount ? (
                      <span>
                        ${parseInt(row?.subscription?.plan?.amount) / 100}
                      </span>
                    ) : (
                      "$0.00"
                    )}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Paid Amount:</Typography>
                  <Typography className="value">
                    {row?.estimated_amount
                      ? currencyFormatter(row?.pay_amount)
                      : "$0.00"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Start Date:</Typography>
                  <Typography className="value">
                    {row?.subscription?.start_at || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">End Date:</Typography>
                  <Typography className="value">
                    {row?.subscription?.end_at || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Status:</Typography>
                  <Typography className="value">
                    {row?.subscription?.expired &&
                    row?.subscription?.cancel_at === null ? (
                      <span className="text-danger">Expired</span>
                    ) : row?.subscription?.cancel_at !== null ? (
                      <span className="text-danger">Cancelled</span>
                    ) : row?.subscription?.payment_status === "success" ? (
                      <span className="text-success">Active</span>
                    ) : (
                      // ) : row?.subscription?.payment_status === 1 ? (
                      <span className="text-danger">Failed</span>
                    )}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Purchase Plan:</Typography>
                  {row?.subscription?.expired &&
                  row?.subscription?.cancel_at === null &&
                  // row?.subscription?.auto_renew === 0 &&
                  !isSecondaryUser ? (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRow(row);
                        handleGetEstimatedAmount(row);
                        // getPlanRenewed(row);
                      }}
                      sx={btn_style}
                      className="px-3 error_popup_btn text-white mt-2"
                    >
                      Renew
                    </Button>
                  ) : !isSecondaryUser &&
                    row?.subscription?.payment_status === "failed" ? (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRow(row);
                        setRetryPayment(true);
                        handleGetEstimatedAmount(row);
                        // getPlanRenewed(row);
                      }}
                      sx={btn_style}
                      className="px-3 error_popup_btn text-white mt-2"
                    >
                      Retry
                    </Button>
                  ) : (
                    "-"
                  )}
                </Stack>

                <Box className="w-100 d-flex justify-content-center">
                  <Button
                    variant="link"
                    className="viewBtn text-capitalize"
                    sx={{ color: "#15be53" }}
                    onClick={(e) => {
                      setSelectedRow(row);
                      setIsSubscriptionDetailsModalOpen(true);
                    }}
                  >
                    View Details
                  </Button>
                </Box>
              </Stack>

              {index < subscriptionData?.data?.length - 1 && (
                <Divider
                  className="my-2 mt-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {getObjLength(subscriptionData) &&
          !subscriptionData?.data?.length > 0 && (
            <Typography
              className="d-block d-sm-none p-5 text-center"
              variant="h6"
            >
              No Data Found.
            </Typography>
          )}
      </Box>

      {/* desktop screen */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <EnhancedTableHead columns={columns} />
              {subscriptionData?.data?.length > 0 && (
                <TableBody>
                  {subscriptionData?.data?.map((row, index) => {
                    return (
                      <>
                        <Row
                          key={(page - 1) * 10 + index + 1}
                          row={row}
                          // handleEditButton={handleEditButton}
                          // handleDeleteButton={handleDeleteButton}
                          page={page}
                          index={index}
                          handleCollapsibleRow={handleCollapsibleRow}
                          isCollapsible={
                            selectedCollapsibleRow?.id === row?.id &&
                            isCollapsibleRow
                          }
                          setSelectedRow={setSelectedRow}
                          handleSubscriptionModalOpen={
                            handleSubscriptionModalOpen
                          }
                          isSecondaryUser={isSecondaryUser}
                          selectRenewalProperties={selectRenewalProperties}
                          setRetryPayment={setRetryPayment}
                          subscriptionIds={subscriptionIds}
                          selectedRetrySubscriptionIds={
                            selectedRetrySubscriptionIds
                          }
                          setRetrySubscriptionIds={setRetrySubscriptionIds}
                          handleGetEstimatedAmount={handleGetEstimatedAmount}
                        />
                      </>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {!subscriptionData?.data?.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
      </Box>
      {subscriptionData?.count > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}

      {getObjLength(subscriptionRenewed) && valdiateModal ? (
        subscriptionRenewed?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              onSuccess();
              handleRenewPlanModalClose();
            }}
            mesage={subscriptionRenewed?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              resetSelectedSubscriptions();
            }}
            mesage={subscriptionRenewed?.data?.message}
          />
        )
      ) : null}

      {/* for retry payment */}
      {getObjLength(retryPaymentData) && valdiateModal ? (
        retryPaymentData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              onSuccess();
              handleRenewPlanModalClose();
            }}
            mesage={retryPaymentData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              resetSelectedSubscriptions();
            }}
            mesage={retryPaymentData?.data?.message}
          />
        )
      ) : null}

      {/* for bulk payment */}
      {getObjLength(bulkPlanRenewalData) && valdiateModal ? (
        bulkPlanRenewalData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              onSuccess();
              handleRenewPlanModalClose();
            }}
            mesage={bulkPlanRenewalData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              resetSelectedSubscriptions();
            }}
            mesage={bulkPlanRenewalData?.data?.message}
          />
        )
      ) : null}

      {/* subscription history modal */}
      <SubscriptionHistoryModal
        open={isSubscriptionHistoryModalOpen}
        handleClose={handleSubscriptionModalClose}
      />

      {/* Renew plan modal */}
      <RenewPlanModal
        open={isRenewPlanModalOpen}
        loader={subscriptionLoader}
        isRenewFinalAmtModalOpen={isRenewFinalAmtModalOpen}
        setIsRenewFinalAmtModalOpen={setIsRenewFinalAmtModalOpen}
        handleClose={() => {
          if (
            getObjLength(subscriptionRenewed) ||
            getObjLength(retryPaymentData) ||
            getObjLength(bulkPlanRenewalData)
          ) {
            setPropertiesIds([]);
            setSubscriptionIds([]);
            setSelectedRetrySubscriptionIds([]);
            setRetryPropertiesIds([]);
          }
          handleRenewPlanModalClose();
        }}
        onSubmit={handleRenewPlan}
        // for 3d secure card paymnet
        paymnetData={
          bulkPayment
            ? bulkPlanRenewalData
            : retryPayment
            ? retryPaymentData
            : subscriptionRenewed
        }
        // for 3d secure card paymnet events
        pixelEventMessage={
          bulkPayment
            ? "Bulk re-payment"
            : retryPayment
            ? "Retry Payment"
            : "Subscription Renewed"
        }
        user_id={selectedRow?.subscription?.user_id}
        onSuccess={onSuccess}
        apiCall={getSubscriptionData}
        setIsRenewPlanModalOpen={setIsRenewPlanModalOpen}
        selectedRow={selectedRow}
      />

      {/* subscription details modal for mobile */}
      <SubscriptionDetailsDialog
        isSubscriptionDetailsModalOpen={isSubscriptionDetailsModalOpen}
        subscriptionDetails={{
          discount:
            selectedRow?.discount !== null
              ? currencyFormatter(selectedRow?.discount)
              : "$0.00",
          sales_tax: selectedRow?.sales_tax
            ? currencyFormatter(selectedRow?.sales_tax)
            : "$0.00",
          recording_fees: selectedRow?.recording_charge
            ? currencyFormatter(selectedRow?.recording_charge)
            : "$0.00",
          setup_fees: selectedRow?.["setup fees"]
            ? currencyFormatter(selectedRow?.["setup fees"])
            : "$0.00",
          estimated_amount: selectedRow?.estimated_amount
            ? currencyFormatter(selectedRow?.estimated_amount)
            : "$0.00",
          refund: selectedRow?.refund
            ? currencyFormatter(selectedRow?.refund)
            : "$0.00",
        }}
        handleClose={handleMobileSubscriptionDetailsModalClose}
      />

      <Loader open={totalPaymentLoader} />
    </>
  );
};

export default SubscriptionListTable;
