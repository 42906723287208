import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "../../common/CustomTextField";
import { Loader } from "../../common/loader";

const RefundRecordingFeesModal = ({
  open,
  handleClose,
  selectedProperty,
  handleRefundRecordingFees,
}) => {
  // store data
  const { refundRecordingFeesLoader } = useSelector(
    (state) => state?.propertyLists
  );
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            overflow: "visible",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Refund Recording Fees
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <>
          <DialogContent dividers sx={{ overflow: "visible" }}>
            <Box
              className="bg-white d-flex justify-content-center bg-white"
              p={1}
            >
              <Formik
                enableReinitialize
                validateOnChange
                initialValues={{
                  amount: "",
                }}
                validationSchema={Yup.object().shape({
                  amount: Yup.number()
                    .required(
                      `Please Enter any refund amount between $${0} and payment amount $${
                        parseFloat(selectedProperty?.paid_recording_charge) -
                        parseFloat(
                          selectedProperty?.refunded_recording_charge || 0
                        )
                      }`
                    )
                    .moreThan(0, `Refund amount should be more than Zero.`)
                    .lessThan(
                      parseFloat(selectedProperty?.paid_recording_charge) -
                        parseFloat(
                          selectedProperty?.refunded_recording_charge || 0
                        ) +
                        0.0001,
                      `Refund amount should not be more than $${
                        parseFloat(selectedProperty?.paid_recording_charge) -
                        parseFloat(
                          selectedProperty?.refunded_recording_charge || 0
                        )
                      }`
                    ),
                })}
                // onSubmit={handleBindProfessional}
                onSubmit={handleRefundRecordingFees}
              >
                {({ values, setFieldValue, touched, errors }) => {
                  return (
                    <Form className="w-100">
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} m={3}>
                          <CustomTextField
                            label="Refund Amount ($)"
                            name="amount"
                            type="number"
                            validation={{
                              values,
                              errors,
                              touched,
                              setFieldValue,
                            }}
                          />
                        </Grid>
                        {selectedProperty?.refunded_recording_charge && (
                          <p className="recording_refund_note">
                            Note: $
                            {parseFloat(
                              selectedProperty?.refunded_recording_charge
                            ).toFixed(2)}{" "}
                            has already been refunded.
                          </p>
                        )}
                      </Grid>

                      <DialogActions>
                        <Stack direction="row" spacing={1}>
                          <Button
                            className="popup_Btn other_popup_btn"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="popup_Btn success_popup_btn"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      </DialogActions>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </DialogContent>
        </>
        <Loader open={refundRecordingFeesLoader} />
      </Dialog>
    </>
  );
};

export default RefundRecordingFeesModal;
