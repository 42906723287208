import { useState } from "react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../loader";
import usePagination from "../PaginationLogic";
import { PER_PAGE } from "../../helpers/constants";
import { currencyFormatter } from "../../helpers/utils";
import VisaCard from "../../../assets/dashboard/visa.svg";
import MasterCard from "../../../assets/dashboard/mastercard.svg";
import DiscoverCard from "../../../assets/dashboard/discover.svg";
import SubscriptionDetailsDialog from "./SubscriptionDetailsDialog";
import AmericanCard from "../../../assets/dashboard/american-express.svg";
import CustomPagination from "../customDesignedComponents/CustomPagination";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

import "../common.scss";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 60,
  },
  {
    id: "plan_name",
    disablePadding: true,
    label: "Plan Name",
    minWidth: 105,
  },
  {
    id: "card",
    disablePadding: true,
    label: "Card",
    minWidth: 120,
  },
  {
    id: "start_at",
    disablePadding: true,
    label: "Start Date",
    minWidth: 120,
  },
  {
    id: "end_at",
    disablePadding: true,
    label: "End Date",
    minWidth: 120,
  },
  {
    id: "subscription_amount",
    disablePadding: true,
    label: "Subscription Amount",
    minWidth: 120,
  },
  // {
  //   id: "discount",
  //   disablePadding: true,
  //   label: "Discount",
  //   minWidth: 100,
  // },
  // {
  //   id: "local_tax",
  //   disablePadding: true,
  //   label: "Sales/Local Tax",
  //   minWidth: 120,
  // },
  // {
  //   id: "recording_fees",
  //   disablePadding: true,
  //   label: "Deposit for recording fees",
  //   minWidth: 120,
  // },
  // {
  //   id: "setup_fees",
  //   disablePadding: true,
  //   label: "Setup Fees",
  //   minWidth: 120,
  // },
  {
    id: "estimated_amount",
    disablePadding: true,
    label: "Estimated Amount",
    minWidth: 120,
  },
  {
    id: "paid_amount",
    disablePadding: true,
    label: "Paid Amount",
    minWidth: 120,
  },
  {
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 150,
  },
];

export const getCardImage = (brand) => {
  return brand === "MasterCard" ? (
    <img width={"30px"} src={MasterCard} alt="Master Card" />
  ) : brand === "Visa" ? (
    <img width={"30px "} src={VisaCard} alt="Visa Card" />
  ) : brand === "American Express" ? (
    <img width={"30px"} src={AmericanCard} alt="American Card" />
  ) : brand === "Discover" ? (
    <img width={"30px"} src={DiscoverCard} alt="Discover Card" />
  ) : (
    <CreditCardIcon fontSize="large" />
  );
};

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
            className="break-word"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row({
  row,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
}) {
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="left">
          {row?.plan?.id ? `${row?.plan?.name}` : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.amountDetails?.card?.brand ? (
            <Stack spacing={2} direction="row">
              {getCardImage(row?.amountDetails?.card?.brand)}
              <Typography className="content-text">
                {row?.amountDetails?.card?.last4}
              </Typography>
            </Stack>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="left">{row?.start_at || "-"}</TableCell>
        <TableCell align="left">{row?.end_at || "-"}</TableCell>
        <TableCell align="left">
          {row?.amountDetails && row?.amountDetails?.subscription
            ? currencyFormatter(row?.amountDetails?.subscription)
            : "$0.00"}
        </TableCell>
        <TableCell align="left">
          {row?.amountDetails && row?.amountDetails?.estimated_amount
            ? currencyFormatter(row?.amountDetails?.estimated_amount)
            : "$0.00"}
        </TableCell>
        <TableCell align="left">
          {row?.amountDetails && row?.amountDetails?.pay_amount
            ? currencyFormatter(row?.amountDetails?.pay_amount)
            : "$0.00"}
        </TableCell>
        {/* action */}
        <TableCell align="center" width="100px">
          <Stack direction="row" spacing={1} justifyContent="space-between">
            {row?.is_active &&
            !row?.expired &&
            !row?.cancel_at &&
            row?.payment_status !== "failed" ? (
              // <Tooltip
              //   title="Plan Active"
              //   TransitionComponent={Zoom}
              //   placement="top"
              //   arrow
              // >
              <Typography
                sx={{ marginTop: "4px" }}
                className="value text-success"
              >
                Active
              </Typography>
            ) : (
              // </Tooltip>
              // <Tooltip
              //   title={
              //     !row?.expired && row?.payment_status === "failed"
              //       ? "Payment Failed"
              //       : row?.refunded
              //       ? "Cancelled"
              //       : !row?.expired
              //       ? "Cancelled"
              //       : "Expired"
              //   }
              //   TransitionComponent={Zoom}
              //   placement="top"
              //   arrow
              // >
              <Typography
                sx={{ marginTop: "4px" }}
                className="value text-danger"
                style={{
                  color: "rgba(220, 53, 69, 1)",
                  // fontSize: "1.4rem",
                  // cursor: "pointer",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {!row?.expired && row?.payment_status === "failed"
                  ? "Failed"
                  : row?.refunded
                  ? "Cancelled"
                  : !row?.expired
                  ? "Cancelled"
                  : "Expired"}
                {/* {!row?.expired && row?.payment_status === "failed" ? (
                    <ReportProblemIcon />
                  ) : row?.refunded ? (
                    <CancelTwoToneIcon />
                  ) : row?.expired ? (
                    <RunningWithErrorsIcon />
                  ) : (
                    <CancelTwoToneIcon />
                  )} */}
              </Typography>
              // </Tooltip>
            )}

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      {/* collapsible row */}
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "50px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Discount
                </Typography>
                <Typography className="content-text">
                  {row?.amountDetails && !isNaN(row?.amountDetails?.discount)
                    ? currencyFormatter(row?.amountDetails?.discount)
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "50px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Sales/Local Tax
                </Typography>
                <Typography className="content-text">
                  {row?.amountDetails && row?.amountDetails?.["Sales Tax"]
                    ? currencyFormatter(row?.amountDetails?.["Sales Tax"])
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "50px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Deposit for recording fees
                </Typography>
                <Typography className="content-text">
                  {row?.amountDetails &&
                  row?.amountDetails?.["recording charge"]
                    ? currencyFormatter(
                        row?.amountDetails?.["recording charge"]
                      )
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "50px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Setup Fees
                </Typography>
                <Typography className="content-text">
                  {row?.amountDetails && row?.amountDetails?.["setup fees"]
                    ? currencyFormatter(row?.amountDetails?.["setup fees"])
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={4}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "50px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Refund Amount
                </Typography>
                <Typography className="content-text">
                  {row?.amountDetails && row?.amountDetails?.["refund"]
                    ? currencyFormatter(row?.amountDetails?.["refund"])
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SubscriptionHistory = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSubscriptionDetailsModalOpen, setIsSubscriptionDetailsModalOpen] =
    useState(false);

  // store data
  const {
    viewDetailsPropertyData: propertyData,
    propertiesLoader,
    subscriptionHistory,
    subscriptionHistoryLoader,
  } = useSelector((state) => state?.properties);

  const count = Math.ceil(
    subscriptionHistory?.data ? subscriptionHistory?.data?.length / PER_PAGE : 0
  );
  const _DATA = usePagination(
    subscriptionHistory?.data ? subscriptionHistory?.data : [],
    PER_PAGE,
    page,
    setPage
  );

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // staticData.jump(p);
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  const handleSubscriptionDetailsModalOpen = (row) => {
    setSelectedRow(row);
    setIsSubscriptionDetailsModalOpen(true);
  };
  const handleSubscriptionDetailsModalClose = () => {
    setSelectedRow(null);
    setIsSubscriptionDetailsModalOpen(false);
  };

  useEffect(() => {
    if (propertyData?.id) {
      dispatch(
        propertiesAction.fetchSubscriptionHistory({
          url: `property/${propertyData?.id}/subscription-history`,
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, propertyData?.id]);

  return (
    <>
      {/* Subscription History Table */}

      {/* table mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 ">
        {_DATA.currentData()?.map((row, index) => {
          return (
            <>
              <Grid container>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={1.2} px={2} pt={2}>
                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Plan Name:</Typography>
                      <Typography className="value">
                        {row?.plan?.id ? `${row?.plan?.name}` : "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Start Date:</Typography>
                      <Typography className="value">
                        {row?.start_at || "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key">
                        {" "}
                        Subscription Amount:
                      </Typography>
                      <Typography className="value">
                        {row?.amountDetails && row?.amountDetails?.subscription
                          ? currencyFormatter(row?.amountDetails?.subscription)
                          : "$0.00"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key">Paid Amount:</Typography>
                      <Typography className="value">
                        {row?.amountDetails && row?.amountDetails?.pay_amount
                          ? currencyFormatter(row?.amountDetails?.pay_amount)
                          : "$0.00"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={1.2} px={2} pt={2}>
                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Card:</Typography>
                      {row?.amountDetails?.card?.brand ? (
                        <Stack spacing={2} direction="row">
                          {getCardImage(row?.amountDetails?.card?.brand)}
                          <Typography className="value">
                            {row?.amountDetails?.card?.last4}
                          </Typography>
                        </Stack>
                      ) : (
                        "-"
                      )}
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> End Date:</Typography>
                      <Typography className="value">
                        {row?.end_at || "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key">Estimated Amount:</Typography>
                      <Typography className="value">
                        {row?.amountDetails &&
                        row?.amountDetails?.estimated_amount
                          ? currencyFormatter(
                              row?.amountDetails?.estimated_amount
                            )
                          : "$0.00"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Status:</Typography>
                      {row?.is_active &&
                      !row?.expired &&
                      !row?.subscription?.cancel_at &&
                      row?.payment_status !== "failed" ? (
                        <Typography className="value text-success">
                          Active
                        </Typography>
                      ) : (
                        <Typography className="value text-danger">
                          {!row?.expired && row?.payment_status === "failed"
                            ? "Failed"
                            : row?.refunded
                            ? "Cancelled"
                            : row?.expired
                            ? "Expired"
                            : "Cancelled"}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Box className="w-100 d-flex justify-content-center">
                <Button
                  variant="link"
                  className="viewBtn text-capitalize"
                  sx={{ color: "#15be53", mt: 2 }}
                  onClick={() => handleSubscriptionDetailsModalOpen(row)}
                >
                  View Details
                </Button>
              </Box>

              {index < subscriptionHistory?.data?.length - 1 && (
                <Divider
                  className="my-2"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {!subscriptionHistory?.data?.length > 0 && !propertiesLoader && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* table desktop view */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {_DATA.currentData()?.map((row, index) => {
                  return (
                    <>
                      <Row
                        key={(page - 1) * 10 + index + 1}
                        row={row}
                        page={page}
                        index={index}
                        handleCollapsibleRow={handleCollapsibleRow}
                        isCollapsible={
                          selectedCollapsibleRow?.id === row?.id &&
                          isCollapsibleRow
                        }
                      />
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {!subscriptionHistory?.data?.length > 0 && !propertiesLoader && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
        {subscriptionHistory?.data?.length > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>

      {/* subscription details modal for mobile */}
      <SubscriptionDetailsDialog
        isFromHistory={true}
        isSubscriptionDetailsModalOpen={isSubscriptionDetailsModalOpen}
        subscriptionDetails={{
          discount:
            selectedRow?.amountDetails &&
            !isNaN(selectedRow?.amountDetails?.discount)
              ? currencyFormatter(selectedRow?.amountDetails?.discount)
              : "$0.00",
          sales_tax:
            selectedRow?.amountDetails &&
            selectedRow?.amountDetails?.["Sales Tax"]
              ? currencyFormatter(selectedRow?.amountDetails?.["Sales Tax"])
              : "$0.00",
          recording_fees:
            selectedRow?.amountDetails &&
            selectedRow?.amountDetails?.["recording charge"]
              ? currencyFormatter(
                  selectedRow?.amountDetails?.["recording charge"]
                )
              : "$0.00",
          setup_fees:
            selectedRow?.amountDetails &&
            selectedRow?.amountDetails?.["setup fees"]
              ? currencyFormatter(selectedRow?.amountDetails?.["setup fees"])
              : "$0.00",
          refund:
            selectedRow?.amountDetails && selectedRow?.amountDetails?.["refund"]
              ? currencyFormatter(selectedRow?.amountDetails?.["refund"])
              : "$0.00",
        }}
        handleClose={handleSubscriptionDetailsModalClose}
      />

      <Loader open={subscriptionHistoryLoader} />
    </>
  );
};

export default SubscriptionHistory;
