/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Webcam from "react-webcam";

import Box from "@mui/material/Box";

import "./signup.scss";

// const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 1024,
  height: 1024,
  facingMode: "user",
};
const ImageCapture = ({ webcamRef, picture }) => {
  return (
    <>
      <Box className="text-center">
        <div style={{ position: "relative" }}>
          {!picture ? (
            <Webcam
              audio={false}
              width={300}
              height={300}
              style={{ width: "100%", zIndex: "1" }}
              ref={webcamRef}
              mirrored={true}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              videoConstraints={videoConstraints}
            />
          ) : null}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "220px",
              height: "60%",
              border: "2px solid red",
              zIndex: 2,
            }}
          />
        </div>
      </Box>
    </>
  );
};
export default ImageCapture;
