import React from "react";
import Button from "@mui/material/Button";
import mailImg from "../../../assets/signin/Mail.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLocalStoarge,
  getObjLength,
  redirectSubDomainToMainDomain,
} from "../../helpers/utils";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { useEffect } from "react";
import { Loader } from "../../common/loader";

const FinalStepCompleted = ({ backToRoot }) => {
  const { userEmail } = useSelector((state) => state.signUpUser);
  const dispatch = useDispatch();

  const userDataFromSession = JSON.parse(
    localStorage.getItem("spSignupDetails")
  );
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const { welcomeEmailLoader, createAccResponse, welcomeEmailDetails } =
    useSelector((state) => state.serviceProviderSignup);

  console.log("signup email==>", userEmail);

  useEffect(() => {
    console.log("signatureRegistered==>");
    let data = {
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
      step_key: "SEND_WELCOME_EMAIL",
      step: 5,
    };
    dispatch(
      serviceProviderSignupAction.getSendWelcomeEmailDetails({
        url: "service-provider/send-welcome-mail",
        data: data,
      })
    );
  }, []);

  useEffect(() => {
    if (getObjLength(welcomeEmailDetails)) {
      dispatch(serviceProviderSignupAction.resetSPState());
    }
  }, [welcomeEmailDetails]);

  return (
    <Box className="accountBoxWrapper">
      <Typography align="center" variant="h3">
        Success
      </Typography>
      <Typography align="center" variant="body1">
        This is a success message. We sent an email to&nbsp;
        {userEmail ? (
          <b>{userEmail}</b>
        ) : getObjLength(userDataFromSession) ? (
          <b>{userDataFromSession?.email}</b>
        ) : (
          "Your registered email"
        )}
        &nbsp; with the confirmation and the details of your account.
      </Typography>
      <Box className="text-center" py={2}>
        <img src={mailImg} alt="sign" />
      </Box>
      <div className="text-center stepBtn mt-5">
        <Link className="text-white text-decoration-none" to="/login">
          <Button
            onClick={() => {
              clearLocalStoarge();
              backToRoot();
              dispatch(serviceProviderSignupAction.resetSPState());
              // redirectSubDomainToMainDomain("login");
            }}
            className="next_button mt-3"
            variant="contained"
          >
            Log In Now
          </Button>
        </Link>
      </div>
      <Loader open={welcomeEmailLoader} />
    </Box>
  );
};

export default FinalStepCompleted;
