import { call, put, takeEvery } from "redux-saga/effects";

import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { deleteAPIPayload } from "../../../apis/deleteApi";
import { userSigInAction } from "../../slices/user/userSignInSlice";
import { userProfileAction } from "../../slices/user/userProfileSlice";

export function* getUserProfileSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchUserData(response));
}

export function* editUserProfileSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetcheditedUserData(response));
}

export function* fetchTrustedContactsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.setTrustedCotacts(response));
  // return console.log("in sagas--", payload);
}

// logout user from website and remove it's data from local storage and redux state
export function* logoutUserSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  if (response?.success) {
    yield put(userProfileAction.clearUserProfileData());
    yield put(userSigInAction.successLogout());

    localStorage.removeItem("authToken");
    localStorage.removeItem("authorised");
    localStorage.removeItem("roles");
  }
}

export function* getCardDetailsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.getCardDetails(response));
}

export function* getAddNewCardDetailsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.getNewCardDetails(response));
}

export function* getRemoveCardDetailsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.getRemovedCardDetails(response));
}

export function* removeServiceProvidersCardSaga({ payload }) {
  const response = yield call(deleteAPIPayload, payload);
  yield put(userProfileAction.getRemovedCardDetails(response));
}

export function* getSetDefaultCardDetailsSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.getsetDefaultCardDetails(response));
}

export function* addBeneficiaryDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set data in redux store
  yield put(userProfileAction.setBeneficiaryDetails(response));
  if (response?.success) {
    yield put(
      userProfileAction.fetchBeneficiaryList({
        data: {},
        token: true,
        url: "customer/beneficiaries",
      })
    );
  }
}

export function* deleteBeneficiaryDetailsSaga({ payload }) {
  // return console.log("  ", payload);
  const response = yield call(getAPIPayload, payload);

  // set data in redux store
  yield put(userProfileAction.setBeneficiaryDetails(response));
  if (response?.success) {
    yield put(
      userProfileAction.fetchBeneficiaryList({
        data: {},
        token: true,
        url: "customer/beneficiaries",
      })
    );
  }
}

export function* fetchBeneficiaryListSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  console.log("payload", payload);
  // set data in redux store
  yield put(userProfileAction.setBeneficiaryList(response));
}

export function* stannupEmailDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchStannupEmailData(response));
}

export function* sendPostCardDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchPostCardData(response));
}

export function* verifyStannupDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchVerifiedStannupData(response));
}

export function* biometricDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchBiometricData(response));
}

export function* updateBiometricDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchUpdatedBiometricData(response));
}

export function* signatureDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchSignatureData(response));
}

export function* updateSignatureDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchUpdatedSignatureData(response));
}

export function* referralDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchReferralData(response));
}

export function* qrCodeVerificationDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userProfileAction.fetchQRCodeVerificationData(response));
}

export function* switchAccountSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(userProfileAction.setSwitchAccountDetails(response));
}

export function* getPaymnetDoneDetailsSaga({ payload }) {
  console.log("payment saga ==>", payload);
  const response = yield call(getAPIPayload, payload);
  yield put(userProfileAction.getPaymnetDoneDetails(response));
}

// ----------------- watcher ---------------------------

export function* watchUserProfileAsync() {
  yield takeEvery(userProfileAction.userProfileData, getUserProfileSaga);
  yield takeEvery(userProfileAction.editUserProfileData, editUserProfileSaga);
  yield takeEvery(userProfileAction.logoutUser, logoutUserSaga);

  yield takeEvery(
    userProfileAction.fetchTrustedContacts,
    fetchTrustedContactsSaga
  );
  yield takeEvery(userProfileAction.userCardDetails, getCardDetailsSaga);
  yield takeEvery(userProfileAction.addNewCard, getAddNewCardDetailsSaga);
  yield takeEvery(userProfileAction.removeNewCard, getRemoveCardDetailsSaga);
  yield takeEvery(
    userProfileAction.removeServiceProvidersCard,
    removeServiceProvidersCardSaga
  );
  yield takeEvery(
    userProfileAction.setDefaultCard,
    getSetDefaultCardDetailsSaga
  );
  yield takeEvery(
    userProfileAction.addBeneficiaryDetails,
    addBeneficiaryDetailsSaga
  );
  yield takeEvery(
    userProfileAction.deleteBeneficiaryDetails,
    deleteBeneficiaryDetailsSaga
  );
  yield takeEvery(
    userProfileAction.fetchBeneficiaryList,
    fetchBeneficiaryListSaga
  );
  yield takeEvery(userProfileAction.sendPostCardData, sendPostCardDataSaga);
  yield takeEvery(userProfileAction.getStannupEmailData, stannupEmailDataSaga);
  yield takeEvery(userProfileAction.verifyStannupData, verifyStannupDataSaga);
  yield takeEvery(userProfileAction.getBiometricData, biometricDataSaga);
  yield takeEvery(
    userProfileAction.updateBiometricData,
    updateBiometricDataSaga
  );
  yield takeEvery(userProfileAction.getSignatureData, signatureDataSaga);
  yield takeEvery(
    userProfileAction.updateSignatureData,
    updateSignatureDataSaga
  );
  yield takeEvery(userProfileAction.getReferralData, referralDataSaga);
  yield takeEvery(
    userProfileAction.getQRCodeVerificationData,
    qrCodeVerificationDataSaga
  );
  // yield takeEvery(userProfileAction.switchAccount, switchAccountSaga);
  yield takeEvery(
    userProfileAction.paymnetDoneDetails,
    getPaymnetDoneDetailsSaga
  );
}
