import { useEffect } from "react";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTheme, useMediaQuery } from '@mui/material';
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { Loader } from "./loader";
import AlertDialog from "./AlertDialog";
import { ROLES } from "../helpers/constants";
import { decryptPayload, getObjLength } from "../helpers/utils";
import PurcaseTab from "./userViewPropertyTabsComponents/PurcaseTab";
import MortgageTab from "./userViewPropertyTabsComponents/MortgageTab";
import AerialMapTab from "./userViewPropertyTabsComponents/AerialMapTab";
import DocumentComp from "./adminViewPropertyTabsComponents/DocumentComp";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import AssessmentTaxTab from "./userViewPropertyTabsComponents/AssessmentTaxTab";
import UserSubscriptionHistory from "./userViewPropertyTabsComponents/SubscriptionHistory";

import "./common.scss";

const tabContent = [
  {
    label: "Assesment & Tax",
    value: 1,
    component: (
      <>
        <AssessmentTaxTab />
      </>
    ),
  },
  {
    label: "Aerial map",
    value: 2,
    component: (
      <>
        <AerialMapTab />
      </>
    ),
  },
  {
    label: "Mortgage",
    value: 3,
    component: (
      <>
        <MortgageTab />
      </>
    ),
  },
  {
    label: "Purchase",
    value: 4,
    component: (
      <>
        <PurcaseTab />
      </>
    ),
  },
  {
    label: "Documents",
    value: 5,
    component: (
      <>
        <DocumentComp role={ROLES.customer} />
      </>
    ),
  },
  {
    label: "Subscription History",
    value: 6,
    component: (
      <>
        <UserSubscriptionHistory />
      </>
    ),
  },
];

export const StyledSubTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    background-color: white;
    border-radius: 5px 5px 0 0;
    color: #0395ff;
    border: none !important;
    border-bottom: 2px solid #d7dfe7 !important;
    text-transform: none;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-top: 1px solid #d7dfe7 !important;
    border-left: 1px solid #d7dfe7 !important;
    border-right: 1px solid #d7dfe7 !important;
    border-bottom: none !important; /* not working */
    line-height: 18px;
    color: #0d120b;
  }

  .MuiTabs-indicator {
    display: none;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserPropertyViewDetailsModal = ({
  open,
  selectedProperty,
  propertyDetailLoader,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(576));
  // states
  const [value, setValue] = useState(1);

  // store values
  const { userViewDetailsPropertyData, viewPropertyDetailLoader } = useSelector(
    (state) => state?.properties
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalClose = () => {
    dispatch(propertiesAction.setViewDetailsPropertyData({}));
    dispatch(propertiesAction.setViewPropertyDetails({}));
    handleClose();
    setValue(1);
  };

  useEffect(() => {
    if (open && selectedProperty?.id) {
      let prefix = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.customer
        ) ||
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.secondaryUser
        )
          ? "customer"
          : decryptPayload(localStorage.getItem("roles"))?.includes(
              ROLES.serviceProvider
            )
          ? "service-provider"
          : decryptPayload(localStorage.getItem("roles"))?.includes(
              ROLES.secondaryUser
            )
          ? "sub-user"
          : "professional"
      }`;
      dispatch(
        propertiesAction.getViewPropertyDetails({
          url: `${prefix}/property-detail/${selectedProperty?.id}`,
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, open, selectedProperty?.id]);

  return (
    <>
      {getObjLength(userViewDetailsPropertyData) &&
        userViewDetailsPropertyData?.success && (
          <>
            <Dialog
              open={open}
              fullWidth
              maxWidth="xl"
              onClose={handleModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={`${isMobile && "zoom-modal-wrapper"} property-view-details-modal`}
            >
              <Button
                onClick={handleModalClose}
                className="modal-close-btn"
                variant="link"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  background: "transparent",
                }}
              >
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>
              <DialogTitle id="alert-dialog-title" mt={1}>
                {selectedProperty?.address
                  ? selectedProperty?.address
                  : "View Property Details"}
              </DialogTitle>
              <DialogContent className="" sx={{ px: 0, position: "relative" }}>
                <Box className="d-block d-md-none" margin="0 10px">
                  <Select
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value);
                    }}
                    displayEmpty
                    className="mobile-select-tabs w-100"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {tabContent?.map((tabs, index) => (
                      <MenuItem
                        sx={{
                          color: value === index + 1 ? "#0D120B" : "#0395FF",
                          paddingLeft: value === index + 1 ? "30px" : "",
                        }}
                        value={tabs?.value}
                      >
                        {tabs?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <StyledSubTabs
                  value={value}
                  scrollButtons
                  onChange={handleChange}
                  id="__new-tabs-container"
                  aria-label="basic tabs example"
                  className="bg-white d-none d-md-block"
                  sx={{
                    pl: 3,
                    m: 0,
                    position: "sticky",
                    top: 0,
                    zIndex: (theme) => theme.zIndex.appBar,
                  }}
                >
                  {tabContent?.map((tabs, index) => (
                    <Tab
                      // sx={{ ml: 0.3, mr: 0.3 }}
                      value={tabs?.value}
                      label={tabs?.label}
                      {...a11yProps(index + 1)}
                    />
                  ))}
                </StyledSubTabs>
                <Box sx={{ minHeight: "65vh" }}>
                  {tabContent?.map((tabs, index) => (
                    <TabPanel value={value} index={index + 1}>
                      {value === index + 1 ? tabs.component : null}
                    </TabPanel>
                  ))}
                </Box>
              </DialogContent>
              <Loader open={propertyDetailLoader} />
            </Dialog>
          </>
        )}

      {getObjLength(userViewDetailsPropertyData) &&
        !userViewDetailsPropertyData?.success && (
          <>
            <AlertDialog
              type="Error"
              openPopup={!userViewDetailsPropertyData?.success}
              onClose={true}
              onCloseFun={() => {
                handleModalClose();
                dispatch(propertiesAction.setViewPropertyDetails({}));
              }}
              mesage={userViewDetailsPropertyData?.data?.message}
              footer={false}
            />
          </>
        )}

      <Loader open={viewPropertyDetailLoader} />
    </>
  );
};

export default UserPropertyViewDetailsModal;
