import { put, call, takeEvery } from "redux-saga/effects";
import { professionalPropertiesAction } from "../../../slices/professional/professional-properties/professionalPropertiesSlice";
import { getAPIPayload } from "../../../../apis/getApi";

export function* getProfessionalPropertyDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(professionalPropertiesAction.setProfessionalPropertyData(response));
}

// export function* getProfessionalsCardDetailsSaga({ payload }) {
//   const response = yield call(getAPIPayload, payload);
//   yield put(professionalPropertiesAction.setProfessionalsCardDetails(response));
// }

export function* watchProfessionalPropertiesSagaAsync() {
  yield takeEvery(
    professionalPropertiesAction.getProfessionalPropertyData,
    getProfessionalPropertyDataSaga
  );
  //   yield takeEvery(
  //     professionalPropertiesAction.getProfessionalsCardDetails,
  //     getProfessionalsCardDetailsSaga
  //   );
}
