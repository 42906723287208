import { put, call, takeLatest } from "redux-saga/effects";
import { logsListAction } from "../../slices/logsTable/logsTableSlice";
import { postAPIPayload } from "../../../apis/postApi";

export function* fetchLogsListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(logsListAction.setLogsListData(response));
}

export function* watchLogsListSagaAsync() {
  yield takeLatest(logsListAction.fetchLogsListData, fetchLogsListDataSaga);
}
