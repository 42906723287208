import React from "react";
import imagePlaceholder from "../../assets/image-fallback-placeholder.jpg";
import { isImage } from "../helpers/utils";

const Photo = ({ sourceUrl, ...rest }) => {
  return sourceUrl === null ||
    sourceUrl === undefined ||
    !isImage(sourceUrl) ? (
    <img src={imagePlaceholder} alt="ep_pictures" {...rest} />
  ) : (
    <img src={sourceUrl} alt="pictures" {...rest} />
  );
};

export default Photo;
