import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import usePagination from "../PaginationLogic";
import { ROLES } from "../../helpers/constants";
import CustomLinkWrapper from "../CustomLinkWrapper";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import CustomPagination from "../customDesignedComponents/CustomPagination";
import {
  getLocalFormatedDate,
  urlPatternValidation,
} from "../../helpers/utils";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 70,
  },
  {
    id: "Recording_date",
    disablePadding: true,
    label: "Record Date",
    minWidth: 120,
  },
  {
    id: "sale_price",
    disablePadding: true,
    label: "Sale Price",
    minWidth: 130,
  },
  {
    id: "deed_type",
    disablePadding: true,
    label: "Deed Type",
    minWidth: 120,
  },
  {
    id: "doc#",
    disablePadding: true,
    label: "Doc #",
    minWidth: 120,
  },
  {
    id: "buyer/seller",
    disablePadding: true,
    label: "Buyer / Seller",
    minWidth: 150,
  },
  {
    id: "UCCLink",
    disablePadding: true,
    label: "UCC Link",
    minWidth: 150,
  },
  {
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 100,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const DocumentComp = ({ role = ROLES.admin }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [tableData, setTabledata] = useState([]);

  // store data
  const propertyData = useSelector((state) =>
    role === ROLES.admin
      ? state?.propertyLists?.viewPropertyDetails?.data
      : state?.properties?.userViewDetailsPropertyData?.data
  );

  useEffect(() => {
    let data = [];
    if (role === ROLES.admin) {
      if (propertyData?.hasOwnProperty("all_documents")) {
        data = [...data, ...propertyData?.all_documents];
      }
    } else {
      if (propertyData?.hasOwnProperty("all_documents")) {
        data = [...data, ...propertyData?.all_documents];
      }
    }
    setTabledata([...data]);
  }, [
    propertyData,
    propertyData?.property_details,
    propertyData?.verified_documents,
    role,
  ]);

  const PER_PAGE = 10;

  const count = Math.ceil(tableData ? tableData?.length / PER_PAGE : 0);
  const _DATA = usePagination(
    tableData ? [...tableData] : [],
    PER_PAGE,
    page,
    setPage
  );

  console.log("property data", [...tableData]);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // staticData.jump(p);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3">
        {_DATA?.currentData()?.map((row, index) => {
          return (
            <>
              <Stack alignItems="flex-end" mx={1}>
                <Tooltip
                  title="View Document"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      if (
                        urlPatternValidation(row?.["Doc_link"]) ||
                        row?.path
                      ) {
                        const link = document.createElement("a");
                        link.href = urlPatternValidation(row?.["Doc_link"])
                          ? row?.["Doc_link"]
                          : row?.path
                          ? row?.path + row?.filename
                          : "#";
                        link.target = "_blank";
                        link.click();
                      }
                    }}
                    sx={{ color: "#15be53" }}
                    disabled={
                      !urlPatternValidation(row?.["Doc_link"]) && !row?.path
                    }
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack direction="column" spacing={1.2} px={2}>
                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Recording Date:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.path
                      ? getLocalFormatedDate(row?.created_at)
                      : row?.["Recording Date"]
                      ? `${row?.["Recording Date"]}`
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Sale Price:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.["Sale Price"] ? `${row?.["Sale Price"]}` : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Deed Type:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.["Deed Type"] ? `${row?.["Deed Type"]}` : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Doc#:</Typography>
                  <Typography>
                    {" "}
                    {urlPatternValidation(row?.["Doc_link"]) ? (
                      <CustomLinkWrapper
                        link={row?.["Doc_link"]}
                        // link="#"
                        title={`${row?.["Doc #"]}`}
                        classes="text-primary value"
                      />
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Buyer/Seller:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.["Buyer / Seller"]
                      ? `${row?.["Buyer / Seller"]}`
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">UCC Link:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.path ? (
                      <CustomLinkWrapper
                        link={row?.path + row?.filename}
                        // link="#"
                        title={`${row?.filename}`}
                        classes="text-primary"
                      />
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Stack>
              </Stack>

              {index < _DATA.currentData()?.length - 1 && (
                <Divider
                  className="my-2"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {!_DATA.currentData()?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      <Box className="bg-white d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData?.length}
              />
              <TableBody>
                {_DATA.currentData()?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.address)}
                      tabIndex={-1}
                      key={row?.id}
                    >
                      <TableCell align="left">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell align="left">
                        {row?.path
                          ? getLocalFormatedDate(row?.created_at)
                          : row?.["Recording Date"]
                          ? `${row?.["Recording Date"]}`
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.["Sale Price"] ? `${row?.["Sale Price"]}` : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.["Deed Type"] ? `${row?.["Deed Type"]}` : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {urlPatternValidation(row?.["Doc_link"]) ? (
                          <CustomLinkWrapper
                            link={row?.["Doc_link"]}
                            // link="#"
                            title={`${row?.["Doc #"]}`}
                            classes="text-primary"
                          />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row?.["Buyer / Seller"]
                          ? `${row?.["Buyer / Seller"]}`
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.path ? (
                          <CustomLinkWrapper
                            link={row?.path + row?.filename}
                            // link="#"
                            title={`${row?.filename}`}
                            classes="text-primary"
                          />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="left" width={120}>
                        <IconButton
                          onClick={(event) => {
                            handleMenuClick(event);
                            setSelectedProperty(row);
                          }}
                          size="small"
                          className="settings-button"
                          aria-controls={open ? "settings-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <SettingsIconSVG />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!tableData?.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>
      </Box>
      {tableData?.length > PER_PAGE && (
        <Box id="pagination-container">
          <CustomPagination
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          className="setting-menu-item"
          onClick={() => {
            if (
              urlPatternValidation(selectedProperty?.["Doc_link"]) ||
              selectedProperty?.path
            ) {
              const link = document.createElement("a");
              link.href = urlPatternValidation(selectedProperty?.["Doc_link"])
                ? selectedProperty?.["Doc_link"]
                : selectedProperty?.path
                ? selectedProperty?.path + selectedProperty?.filename
                : "#";
              link.target = "_blank";
              link.click();
            }
          }}
          disabled={
            !urlPatternValidation(selectedProperty?.["Doc_link"]) &&
            !selectedProperty?.path
          }
        >
          <Box className="setting-menu-box">
            <VisibilityOutlinedIcon />
            <Typography className="setting-menu-text">View</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DocumentComp;
