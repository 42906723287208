/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Paper, Stack, Typography } from "@mui/material";
import "./signup.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { Loader } from "../common/loader";
import FinalStepCompleted from "./finalStepCompleted";
import {
  clearLocalStoarge,
  encryptPayload,
  getObjLength,
} from "../helpers/utils";
import ContcatAdminSupport from "../common/ContcatAdminSupport";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../helpers/constants";
// import SendMailModal from "./SendMailModal";
import { Buffer } from "buffer";

const initialValues = {
  first_name: "",
  last_name: "",
  relation: "",
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .required("Last Name is required"),
  relation: Yup.string("Please enter your Relation.")
    .trim("The Relation cannot include leading and trailing spaces")
    .strict(true)
    .required("Relation is required"),
});

const BeneficiaryForm = ({
  finalScreen,
  HideSkipBtn,
  errorPopup,
  reduceErrorCount,
  isIOS,
}) => {
  const dispatch = useDispatch();

  const [addNewBeneficiary, setAddNewBeneficiary] = useState(false);
  const [submit, setSubmit] = useState(finalScreen);
  // const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false);

  const crerateAccData = useSelector(
    (state) => state.signUpUser?.createAccResponse
  );

  const {
    beneficiaryList,
    beneficiaryLoader,
    beneficiaryError,
    sendMailDetails,
    resumeProcessResponse,
    userSignupDetails,
  } = useSelector((state) => state.signUpUser);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const userDataFromSession = JSON.parse(
    localStorage.getItem("userSignupDetails")
  );
  const completed = JSON.parse(localStorage.getItem("completed"));

  const navigate = useNavigate();

  const onSubmit = (values, errors) => {
    dispatch(
      userSigupAction.addBeneficiaryDetails({
        url: "beneficiaries",
        data: {
          user_id: getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession,
          // userData?.user_id,
          ...values,
          step: 7,
          step_key: "SAVE_BENEFICIARIES",
        },

        token: true,
      })
    );
    setAddNewBeneficiary(false);
  };

  console.log(userSignupDetails, "Decoded String:", userDataFromSession);
  const sendWelcomeMailHandler = () => {
    dispatch(
      userSigupAction.sendSignUpMail({
        url: "send-welcome-email",
        data: {
          user_id: getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession,
          // userData?.user_id,
          step: 7,
          step_key: "SEND_WELCOME_EMAIL",
        },
      })
    );
    let loginToken = localStorage.getItem("setLoginUserToken");
    if (!loginToken) {
      const storedValue = sessionStorage.getItem("userSignupDetails");
      let passwordToken = getObjLength(storedValue)
        ? JSON.parse(storedValue).data?.password
        : null;
      // Decode the encoded string
      const decodedPassword = passwordToken
        ? Buffer.from(passwordToken, "base64").toString()
        : null;
      console.log(userSignupDetails, "Decoded String:", decodedPassword);
      // Temporary hack to not auto login when the user comes from IOS
      if (isIOS !== "ios") {
        dispatch(
          userSigupAction.userLoginData({
            url: "login",
            data: {
              email: getObjLength(userSignupDetails)
                ? userSignupDetails.data?.email
                : getObjLength(userDataFromSession)
                ? userDataFromSession?.email
                : "",
              password: decodedPassword,
              auto_login: 1,
            },
          })
        );
      }
    }
  };

  const handleNextBtn = () => {
    // setIsSendMailModalOpen(true);
    sendWelcomeMailHandler();
    setSubmit(true);
    dispatch(userSigupAction.clearData());
    localStorage.removeItem("user_Id");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("skip");
  };

  // const handleClose = () => {
  //   setIsSendMailModalOpen(false);
  //   setSubmit(true);
  //   dispatch(userSigupAction.clearData());
  // };

  const FieldStyle = {
    borderRadius: "3px",
  };
  const OwnerCardStyle = {
    background: "#FFFFFF",
    boxShadow: "0px 17.0825px 39.8592px -5.69417px rgba(0, 0, 0, 0.15);",
    borderRadius: "3px",
    py: 2,
    px: 4,
    // border: '1px solid #15be53',
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#0D120B",
    minWidth: "250px",
  };
  useEffect(() => {
    if (
      // !getObjLength(crerateAccData) &&
      completed === 1
    ) {
      console.log("skip==>>");
      setSubmit(true);
      sendWelcomeMailHandler();
    }
  }, [finalScreen]);

  useEffect(() => {
    console.log(beneficiaryError, "beneficiaryList==>", beneficiaryList);
    if (beneficiaryList.length > 0) {
      console.log("button skiped==>");
      HideSkipBtn(false);
    }
  }, [beneficiaryList, beneficiaryError]);

  useEffect(() => {
    let loginToken = localStorage.getItem("setLoginUserToken");
    if (getObjLength(sendMailDetails)) {
      clearLocalStoarge("check_county");
      if (loginToken?.length > 0) {
        console.log("getObjLength==", loginToken);
        localStorage.setItem("authToken", loginToken);
        localStorage.setItem("authorised", true);
        localStorage.setItem("roles", encryptPayload(ROLES.customer));
        localStorage.setItem("stanupPopupCount", 1);
        localStorage.setItem("paymentPopup", 0);
        // Temporary hack to not auto login when the user comes from IOS
        if (localStorage.getItem("authToken")) {
          if (isIOS === "ios") {
            console.log("if device is ios !!");
            navigate("/user-profile?from=ios", { replace: false });
          } else {
            console.log("if device is web !!");
            navigate("/user-profile", { replace: false });
          }
        }
      }
    }
  }, [sendMailDetails]);

  return (
    <>
      {!submit ? (
        <>
          <Box
            className="d-flex justify-content-center"
            sx={{ minWidth: "300px" }}
          >
            <Stack direction="column" alignItems="center" pb={3}>
              <Typography align="center" variant="h5">
                Beneficiary Form
              </Typography>
              <Typography align="center" variant="body1">
                This beneficiary data will display on your profile
              </Typography>
              <Grid container spacing={3} alignItems="center" pt={2}>
                {beneficiaryList?.map((person, key) => (
                  <>
                    <Grid item xs={6} sm={12} md={6} lg={6}>
                      <Box sx={{ position: "relative", mb: 3 }}>
                        <Paper sx={OwnerCardStyle}>
                          <Typography>
                            First Name : {person?.first_name}
                          </Typography>
                          <Typography>
                            Last Name : {person?.last_name}
                          </Typography>
                          <Typography>Relation : {person?.relation}</Typography>
                        </Paper>
                      </Box>
                    </Grid>
                  </>
                ))}
                {/* {beneficiaryList?.map((person) => (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                {person?.first_name}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                {person?.last_name}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                {person?.relation}
              </Grid>
            </>
          ))} */}
              </Grid>
            </Stack>
          </Box>
          <Box className="d-flex justify-content-center">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, touched, errors, resetForm }) => {
                return (
                  <Form className="beneficiary-form">
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {((addNewBeneficiary && beneficiaryList.length > 0) ||
                        (!addNewBeneficiary &&
                          beneficiaryList.length === 0)) && (
                        <>
                          <Grid xs={12} md={6} px={1} pb={1}>
                            <TextField
                              className="sign-textField "
                              // id="outlined-basic"
                              label="First name"
                              name="first_name"
                              value={values?.first_name}
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              onChange={(event) => {
                                setFieldValue("first_name", event.target.value);
                              }}
                              helperText={
                                touched.first_name && errors.first_name
                              }
                              error={
                                touched.first_name && Boolean(errors.first_name)
                              }
                            />
                          </Grid>
                          <Grid xs={12} md={6} px={1} pb={1}>
                            <TextField
                              className="sign-textField "
                              // id="outlined-basic"
                              label="Last name"
                              name="last_name"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.last_name}
                              onChange={(event) => {
                                setFieldValue("last_name", event.target.value);
                              }}
                              helperText={touched.last_name && errors.last_name}
                              error={
                                touched.last_name && Boolean(errors.last_name)
                              }
                            />
                          </Grid>
                          <Grid xs={12} md={6} px={1} pb={1}>
                            <TextField
                              className="sign-textField "
                              // id="outlined-basic"
                              label="Relation"
                              name="relation"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.relation}
                              onChange={(event) => {
                                setFieldValue("relation", event.target.value);
                              }}
                              helperText={touched.relation && errors.relation}
                              error={
                                touched.relation && Boolean(errors.relation)
                              }
                            />
                          </Grid>
                        </>
                      )}

                      <Grid xs={12} md={12} px={1} pb={1}>
                        <div className="action-btn mt-5">
                          {addNewBeneficiary ? (
                            <Button
                              className="stepBtn"
                              variant="contained"
                              onClick={() => {
                                setAddNewBeneficiary(false);
                              }}
                            >
                              Cancle
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                beneficiaryList.length === 0 ||
                                beneficiaryList.length === 2
                              }
                              className="stepBtn"
                              variant="contained"
                              onClick={() => {
                                resetForm();
                                setAddNewBeneficiary(true);
                              }}
                            >
                              Add
                            </Button>
                          )}
                          {(!addNewBeneficiary && beneficiaryList.length > 0) ||
                          (!addNewBeneficiary &&
                            beneficiaryList.length === 2) ||
                          beneficiaryList?.length === 2 ? (
                            <Button
                              onClick={() => handleNextBtn()}
                              className="stepBtn"
                              variant="contained"
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              className="stepBtn"
                              variant="contained"
                            >
                              Save
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    {beneficiaryError && (
                      <p className="text-center text-danger">
                        {beneficiaryError}
                      </p>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </>
      ) : (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <FinalStepCompleted isIOS={isIOS} />
          </Typography>
        </>
      )}
      <Loader open={beneficiaryLoader} />
      {errorPopup && (
        <ContcatAdminSupport
          message={beneficiaryError}
          errorPopup={errorPopup}
          reduceErrorCount={reduceErrorCount}
        />
      )}
      {/* {isSendMailModalOpen && (
        <SendMailModal
          open={isSendMailModalOpen}
          handleClose={handleClose}
          sendWelcomMailReqAgain={sendWelcomeMailHandler}
        />
      )} */}
    </>
  );
};

export default BeneficiaryForm;
