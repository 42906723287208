import React from "react";
import { Navigate } from "react-router-dom";
import { userSigInAction } from "../redux/slices/user/userSignInSlice";
import { useDispatch } from "react-redux";

const RouteProtection = ({ children, pageAccess, isIOS = null }) => {
  let isAutheticated;
  const dispatch = useDispatch();

  try {
    const user = localStorage.getItem("authorised");
    console.log(user?.length === 0, "YY token==>", user);
    if (user === "true") {
      isAutheticated = true;
    } else {
      isAutheticated = false;
    }

    // for professional users to login from mobile APP
    let iosCode = "";
    if (isIOS === "ios") {
      let cookieValue = document.cookie;
      // iosCode = source?.[2];
      iosCode = cookieValue?.replace(
        /(?:(?:^|.*;\s*)professioanlCookie\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      localStorage.setItem("preference", true);
      localStorage.setItem("isIos", true);
      if (iosCode) {
        dispatch(
          userSigInAction?.fetchUserData(JSON.parse(window.atob(iosCode)))
        );
        console.log("IOS response ==>", JSON.parse(window.atob(iosCode)));
        isAutheticated = true;
      } else {
        isAutheticated = false;
      }
    }
    console.log("YY isAutheticated token==>>", isAutheticated);
  } catch (error) {
    isAutheticated = false;
  }

  return (
    <React.Fragment>
      {isAutheticated ? children : <Navigate to={"/login"} />}
    </React.Fragment>
  );
};

export default RouteProtection;
