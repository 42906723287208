import React from "react";

const DeleteIconSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_422_2165"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_422_2165)">
        <path
          d="M8.33299 12.938L9.99999 11.271L11.667 12.938L12.438 12.167L10.771 10.5L12.438 8.833L11.667 8.062L9.99999 9.729L8.33299 8.062L7.56199 8.833L9.22899 10.5L7.56199 12.167L8.33299 12.938ZM6.74999 16.583C6.37533 16.583 6.05933 16.4547 5.80199 16.198C5.54533 15.9407 5.41699 15.6247 5.41699 15.25V5.5H4.41699V4.417H7.99999V3.521H12V4.417H15.583V5.5H14.583V15.229C14.583 15.6177 14.4547 15.9407 14.198 16.198C13.9407 16.4547 13.6247 16.583 13.25 16.583H6.74999ZM13.5 5.5H6.49999V15.25C6.49999 15.3053 6.52766 15.361 6.58299 15.417C6.63899 15.4723 6.69466 15.5 6.74999 15.5H13.25C13.3053 15.5 13.361 15.4723 13.417 15.417C13.4723 15.361 13.5 15.3053 13.5 15.25V5.5ZM6.49999 5.5V15.5V15.25V5.5Z"
          fill="#15BE53"
        />
      </g>
    </svg>
  );
};

export default DeleteIconSVG;
