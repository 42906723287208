import React from "react";

import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { decryptPayload } from "../helpers/utils";

const SecureConnectionTemplate = ({ content, footer, isPadding=false }) => {
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  return (
    <Box
      sx={{
        backgroundColor: "#f6f9fc",
        paddingTop: "30px",
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "white",
            width: "100%",
            boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
          }}
        >
          <Typography
            align="center"
            variant="subtitle1"
            className="py-1 formHeader"
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
          </Typography>

          <>
            {content}
            <Divider className="separator" />
            {footer && (
              <Box py={5} px={2} sx={isPadding && {marginBottom:"110px"}}>
                <div className="text-center newAccountLink">
                  <Typography variant="caption" className="me-2">
                    Don’t have an account?
                  </Typography>
                  <Link
                    to={`${is_scrp_hp ? "/" + countyCode : ""}/create-account`}
                    onClick={() => localStorage.removeItem("CAPTCHA_Validate")}
                  >
                    Create one here
                  </Link>
                </div>
              </Box>
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default SecureConnectionTemplate;
