import React from "react";

const OneIconSVG = () => {
  return (
    <>
      <svg
        width="35"
        height="49"
        viewBox="0 0 35 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="1"
          d="M2.3 49V45.29H16.58V3.22H16.23L2.79 15.19L0.41 12.53L14.34 0.139999H20.78V45.29H34.22V49H2.3Z"
          fill="#02a8e2"
        />
      </svg>
    </>
  );
};

export default OneIconSVG;
