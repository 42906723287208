import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddNoteModal from "./AddNoteModal";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import DeleteModal from "../../common/DeleteModal";
import SettingsIconSVG from "../../common/customSVGs/SettingsIconSVG";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ROLES } from "../../helpers/constants";

const UserInformationTab = ({ offsetNum }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // store data
  const {
    selectedProperty,
    historyNotesData,
    noteDetails,
    deleteNoteLoader,
    deleteNoteDetails,
  } = useSelector((state) => state?.propertyUsers);

  const Callto = ({ phone, children }) => {
    return (
      <a
        href={`tel:${phone}`}
        style={{ color: "#15be53", textDecoration: "none" }}
      >
        {children}
      </a>
    );
  };

  const redirectToLogs = () => {
    if (selectedProperty?.user_id !== null) {
      if (getObjLength(offsetNum)) {
        localStorage.setItem("offset", offsetNum);
      }
      dispatch(propertyUsersAction.fetchPropertyUsersList({}));
      navigate(`/admin-logs/${selectedProperty?.user_id}`);
    }
  };

  const handleAddNoteModalOpen = () => {
    setSelectedNote(null);
    setIsAddNoteModalOpen(true);
  };
  const handleAddNoteModalClose = () => {
    setIsAddNoteModalOpen(false);
    setSelectedNote(null);
  };

  const handleNoteSubmit = (values) => {
    if (selectedNote?.id) {
      dispatch(
        propertyUsersAction?.addNote({
          url: `note-update/${selectedNote?.id}`,
          data: values,
          token: true,
        })
      );
    } else {
      dispatch(
        propertyUsersAction?.addNote({
          url: `notes/${selectedProperty?.user_id}`,
          data: values,
          token: true,
        })
      );
    }
  };

  // handle close delete note modal
  const handleCloseDeleteModal = () => {
    setIsDeleteNoteModalOpen(false);
    setSelectedNote(null);
    dispatch(propertyUsersAction?.setDeleteNoteDetails({}));
  };

  // handle delete note
  const handleDeleteNote = () => {
    if (selectedNote?.id) {
      dispatch(
        propertyUsersAction?.deleteNote({
          url: `notes/${selectedNote?.id}`,
          token: true,
        })
      );
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(
      propertyUsersAction.getUserHistory({
        url: `users/${selectedProperty?.user_id}`,
        token: true,
      })
    );
  }, [dispatch, selectedProperty?.user_id]);

  return (
    <>
      <Box className="user-information-tab-box">
        {/* bottom section */}
        <Grid container spacing={1.25} mt={0.1}>
          {/* left side card */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className="content-card bg-white">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={1.75}
              >
                <Typography className="card-heading">History</Typography>
                {!decryptPayload(localStorage.getItem("roles"))?.includes(
                  ROLES.provider
                ) &&
                  !decryptPayload(localStorage.getItem("roles"))?.includes(
                    ROLES.countyRecorder
                  ) && (
                    <Button
                      variant="outlined"
                      // className="edit-btn"
                      onClick={() => redirectToLogs()}
                    >
                      View all
                    </Button>
                  )}
              </Stack>
              <Box
                sx={{
                  maxHeight: "310px",
                  height: "310px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                {historyNotesData?.success ? (
                  Object.keys(historyNotesData?.data?.activities)?.length >
                  0 ? (
                    Object.keys(historyNotesData?.data?.activities)?.map(
                      (date, index) => (
                        <>
                          <Box key={index} mb={1.25}>
                            <Typography className="card-sub-text text-uppercase">
                              {date || "-"}
                            </Typography>
                          </Box>
                          {historyNotesData?.data?.activities?.[date]?.map(
                            (data) => (
                              <Box ml={1.25}>
                                <Typography className="history-list">
                                  {data?.message}
                                </Typography>
                              </Box>
                            )
                          )}
                        </>
                      )
                    )
                  ) : (
                    <Typography
                      className="content-text p-5 text-center"
                      component="div"
                      variant="subtitle2"
                    >
                      No data found
                    </Typography>
                  )
                ) : (
                  <Typography className="text-danger text-center">
                    {historyNotesData?.data?.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>

          {/* right side card */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} pl={0}>
            <Box className="content-card bg-white">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={1.75}
              >
                <Typography className="card-heading">Notes</Typography>
                <Button
                  variant="outlined"
                  // className="edit-btn"
                  onClick={handleAddNoteModalOpen}
                >
                  Add Note
                </Button>
              </Stack>
              <Box
                sx={{
                  maxHeight: "310px",
                  height: "310px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                {historyNotesData?.success ? (
                  historyNotesData?.data?.notes?.length > 0 ? (
                    <>
                      {historyNotesData?.data?.notes?.map((note) => (
                        <Box className="note-list position-relative" mb={1.25}>
                          <Stack
                            className="note-content"
                            direction="column"
                            spacing={1.5}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography className="note-text">
                                {note?.day}
                              </Typography>
                              <IconButton
                                onClick={(event) => {
                                  handleMenuClick(event);
                                  setSelectedNote(note);
                                }}
                                size="small"
                                className="settings-button"
                                sx={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "8px",
                                }}
                                aria-controls={
                                  open ? "settings-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <SettingsIconSVG color={"#15BE53"} />
                              </IconButton>
                              {/* <Stack direction="row">
                                
                                <IconButton
                                  sx={{
                                    color: "#FC2125",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedNote(note);
                                    setIsDeleteNoteModalOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack> */}
                            </Stack>
                            <Typography className="note-text">
                              {note?.note}
                            </Typography>
                          </Stack>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Typography
                      className="p-5 text-center"
                      component="div"
                      variant="subtitle2"
                    >
                      No Data Found.
                    </Typography>
                  )
                ) : (
                  <Typography className="text-danger text-center">
                    {historyNotesData?.data?.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Loader open={historyNoteLoader} /> */}

      <Menu
        key="edit-delete"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          className="setting-menu-item"
          onClick={() => {
            setIsAddNoteModalOpen(true);
          }}
        >
          <Box className="setting-menu-box">
            <BorderColorOutlinedIcon />
            <Typography className="setting-menu-text">Edit</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          className="setting-menu-item"
          onClick={() => {
            setIsDeleteNoteModalOpen(true);
          }}
        >
          <Box className="setting-menu-box">
            <DeleteForeverOutlinedIcon />
            <Typography className="setting-menu-text">Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>

      <AddNoteModal
        open={isAddNoteModalOpen}
        onSubmit={handleNoteSubmit}
        handleClose={handleAddNoteModalClose}
        selectedNote={selectedNote}
      />

      {/* {getObjLength(noteDetails) &&
        (noteDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyUsersAction.setAddNote({}));
              handleAddNoteModalClose();
              dispatch(
                propertyUsersAction.getUserHistory({
                  url: `users/${selectedProperty?.user_id}`,
                  token: true,
                })
              );
            }}
            mesage={noteDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyUsersAction.setAddNote({}));
            }}
            mesage={noteDetails?.data?.message}
            footer={false}
          />
        ))} */}

      <DeleteModal
        open={isDeleteNoteModalOpen}
        title="Delete Note"
        item="Note"
        deleteLoader={deleteNoteLoader}
        handleClose={handleCloseDeleteModal}
        handleDeleteBtn={handleDeleteNote}
        fetchedData={deleteNoteDetails}
        isDeleteSuccess={deleteNoteDetails?.success}
        successMessage={deleteNoteDetails?.message}
        errorMessage={deleteNoteDetails?.data?.message}
        callListingAPI={() => {
          dispatch(
            propertyUsersAction.getUserHistory({
              url: `users/${selectedProperty?.user_id}`,
              token: true,
            })
          );
        }}
        footer={false}
      />
    </>
  );
};

export default UserInformationTab;
