import React, { useEffect } from "react";
import { useState } from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Add, DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import DeleteModal from "../../common/DeleteModal";
import { PER_PAGE, ROLES } from "../../helpers/constants";
import {
  decryptPayload,
  encryptPayload,
  getObjLength,
} from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import UserInformationTab from "./UserInformationTab";
import PropertyTab from "../AdminDashboard/DashBoard";
import AddSpouseModal from "../../spouse/AddSpouseModal";
import SubUserListTable from "../sub-users/SubUserListTable";
import BackArrow from "../../../assets/property-user-list/arrow_back.svg";
import AddSubcription from "../../userProfile/add-property/AddSubcription";
import { subUsersActions } from "../../../redux/slices/admin/subUsers/subUserSlice";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";

import "../../common/common.scss";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";

const StyledSubTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    color: #0395ff;
    border: none !important;
    border-bottom: 2px solid #d7dfe7 !important;
    text-transform: none;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-top: 1px solid #d7dfe7 !important;
    border-left: 1px solid #d7dfe7 !important;
    border-right: 1px solid #d7dfe7 !important;
    border-bottom: none !important; /* not working */
    line-height: 18px;
    color: #0d120b;
    background-color: transparent;
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box my={5} mx={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewEditUserPage = ({
  selectedUser,
  backToUserListPageHandler,
  activeTab,
  offsetNum,
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const pathName = window.location.pathname;
  let path = pathName.split("/")?.[1];
  console.log("path==>", path);

  // states
  const [value, setValue] = useState(activeTab || 1);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [addSubcriptionPopup, setAddSubcriptionPopup] = useState(false);
  const [chooseAddSubcriptionPopup, setChooseAddSubcriptionPopup] =
    useState(false);
  const [isAddSubUserModalOpen, setIsAddSubUserModalOpen] = useState(false);
  const [selectedSubUser, setSelectedSubUser] = useState(null);
  const [isDeleteSubUserModalOpen, setIsDeleteSubUserModalOpen] =
    useState(false);
  const [offset, setOffset] = useState(0);
  const [providerData, setProviderData] = useState();
  const [countyDetails, setCountyDetails] = useState();
  const [isLoader, setIsLoader] = useState(false);

  // store data
  const { deletePropertyUserDetails, deletePropertyUserLoader } = useSelector(
    (state) => state?.propertyUsers
  );
  const { deleteSubUserDetails, addSubUserDetails, subUserListLoader } =
    useSelector((state) => state?.subUsers);

  const { selectedPropertiesData } = useSelector((state) => state?.properties);
  const { userProfileDetails } = useSelector((state) => state?.userProfile);

  const { lennarGenerateLinkDetails } = useSelector(
    (state) => state?.lennarSignUp
  );

  console.log({ selectedUser });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedSubUser(null);
  };

  const addSubscription = () => {
    if (selectedPropertiesData?.provider?.length > 0) {
      setProviderData(selectedPropertiesData?.provider?.[0]?.provider);
      setChooseAddSubcriptionPopup(true);
      setAddSubcriptionPopup(false);
    } else {
      if (
        selectedPropertiesData?.properties_Id?.length > 0 ||
        selectedPropertiesData?.countyDetails?.length > 0
      ) {
        setCountyDetails(selectedPropertiesData?.countyDetails);
        setAddSubcriptionPopup(true);
        setChooseAddSubcriptionPopup(false);
      }
    }
  };

  // //   handleClosing delete modal
  const handleCloseDeleteModal = () => {
    setIsDeleteUserModalOpen(false);
    dispatch(propertyUsersAction.setDeletePropertyUserDetails({}));
  };

  // dispatch action to delete professional
  const handleDeletePropertyUser = () => {
    if (selectedUser?.user_id) {
      dispatch(
        propertyUsersAction.deletePropertyUser({
          url: `admin/users/${selectedUser?.user_id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // open edit sub user modal
  const handleEditSubUser = (row) => {
    setSelectedSubUser(row);
    setIsAddSubUserModalOpen(true);
    console.log("row", row);
  };

  // open delete sub user modal
  const handleOpenDeleteSubUser = (row) => {
    setSelectedSubUser(row);
    setIsDeleteSubUserModalOpen(true);
  };

  const handleCloseAddSubUserModal = () => {
    setSelectedSubUser(null);
    setIsAddSubUserModalOpen(false);
    dispatch(subUsersActions?.setAddSubUserDetails({}));
  };

  const handleCloseDeleteSubUserModal = () => {
    setSelectedSubUser(null);
    setIsDeleteSubUserModalOpen(false);
    dispatch(subUsersActions?.setDeletedAdminDetails({}));
  };

  // call API for adding or editing the sub user
  const handleAddEditSubUser = (values) => {
    console.log("sub user values", values);

    if (selectedSubUser && selectedSubUser?.id) {
      // handle edit dispatch
      dispatch(
        subUsersActions?.addSubUserDetails({
          url: `admin/edit-sub-user/${selectedSubUser?.id}`,
          data: {
            ...values,
            user_id: params?.userId || selectedUser?.user_id || undefined,
          },
          token: true,
        })
      );
    } else {
      // handle add dispatch
      dispatch(
        subUsersActions?.addSubUserDetails({
          url: `admin/add-sub-user`,
          data: {
            ...values,
            user_id: params?.userId || selectedUser?.user_id || undefined,
          },
          token: true,
        })
      );
    }
  };

  // call delete API for sub user
  const handleDeleteSubUser = () => {
    if (selectedSubUser?.id) {
      dispatch(
        subUsersActions.deleteSubUserDetails({
          url: `admin/delete-sub-user/${selectedSubUser?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // get listing of the sub user
  const fetchSubUserListing = () => {
    let URL = `admin/sub-users/${params?.userId || selectedUser?.user_id}`;
    dispatch(
      subUsersActions.getSubUsersListData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: offset,
        },
        token: true,
      })
    );
  };

  const getPropertyListData = () => {
    dispatch(
      propertyListAction.getPropertyListData({
        url: "admin/property-list/" + selectedPropertiesData?.uid,
        data: {
          limit: PER_PAGE,
          offset: 0,
        },
        token: true,
      })
    );
  };

  const tabContent = [
    {
      label: "User Information",
      value: 1,
      component: (
        <>
          <UserInformationTab offsetNum={offsetNum} />
        </>
      ),
    },
    {
      label: "Properties",
      value: 2,
      component: (
        <>
          <PropertyTab offsetNum={offsetNum} tabComponent={true} />
        </>
      ),
    },
    {
      label: "Sub Users",
      value: 3,
      component: (
        <>
          <SubUserListTable
            handleEditSubUser={handleEditSubUser}
            handleOpenDeleteSubUser={handleOpenDeleteSubUser}
            setOffset={setOffset}
          />
        </>
      ),
      hide: [ROLES.provider, ROLES.countyRecorder],
    },
  ];

  const goToPayment = () => {
    let domain = providerData?.domain || providerData?.custom_domain;
    if (getObjLength(offsetNum)) {
      localStorage.setItem("offset", offsetNum);
    }
    dispatch(
      lennarSignUpAction.generateLink({
        url: `providers/${domain}/${selectedPropertiesData?.uid}/ep/generate-link`,
        data: {
          domain,
          create_by_ep: true,
          generated_by: userProfileDetails?.data?.user_id,
        },
        toekn: true,
      })
    );
    setIsLoader(true);
  };

  useEffect(() => {
    console.log("lennarGenerateLinkDetails==>", lennarGenerateLinkDetails);
    if (
      getObjLength(lennarGenerateLinkDetails) &&
      lennarGenerateLinkDetails?.success
    ) {
      let orignalURL = lennarGenerateLinkDetails?.data?.link;
      // ?.replace(
      //   OTHER_PROVIDERS_URL,
      //   DEFAULT_URL
      // );
      window.location.href = orignalURL + "&from=" + encryptPayload(path);
      dispatch(lennarSignUpAction?.resetLennarSignUpState({}));
    }
  }, [dispatch, lennarGenerateLinkDetails]);

  return (
    <>
      <Box className="view-edit-page">
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          onClick={backToUserListPageHandler}
        >
          <img
            alt="back arrow"
            src={BackArrow}
            height="12px"
            width="12px"
            className="cursor-pointer"
          />
          <Typography className="back-to-user-list">
            Back to User List
          </Typography>
        </Stack>

        <Box
          mt={1.5}
          className="w-100"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Typography className="user-name text-capitalize">
            {`${selectedUser?.first_name} ${selectedUser?.last_name}` ||
              "Stephanie Nicol"}
          </Typography>
          <Stack
            direction="row"
            className="align-items-center justify-content-between"
            spacing={1}
          >
            <Button
              variant="contained"
              className="btn d-none d-sm-block popup_Btn"
              style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
              onClick={() => {
                console.log("On delete click");
                dispatch(propertyUsersAction.clearData());
                // handleDeleteButton(selectedUser.id);
                setIsDeleteUserModalOpen(true);
              }}
            >
              Delete User
            </Button>
            <Button
              className="btn d-flex d-sm-none popup_Btn"
              style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
              variant="outlined"
              onClick={() => {
                console.log("On delete click");
                dispatch(propertyUsersAction.clearData());
                // handleDeleteButton(selectedUser.id);
                setIsDeleteUserModalOpen(true);
              }}
              sx={{ color: "white" }}
            >
              <DeleteOutline />
            </Button>

            {(selectedPropertiesData?.ids?.length > 0 ||
              selectedPropertiesData?.provider?.length > 0) &&
              value === 2 && (
                <>
                  <Button
                    variant="contained"
                    className={`d-none d-sm-block popup_Btn success_popup_btn`}
                    style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
                    onClick={addSubscription}
                  >
                    Add Subscription
                  </Button>
                  <Button
                    variant="contained"
                    className="btn btn d-flex d-sm-none popup_Btn success_popup_btn"
                    style={{ backgroundColor: "#f43455", borderRadius: "25px" }}
                    onClick={addSubscription}
                  >
                    <AccountBalanceWalletOutlinedIcon />
                  </Button>
                </>
              )}

            {value === 3 &&
              decryptPayload(localStorage.getItem("roles")) !==
                ROLES.provider &&
              decryptPayload(localStorage.getItem("roles")) !==
                ROLES.countyRecorder && (
                <>
                  <Button
                    variant="contained"
                    className={`popup_Btn success_popup_btn d-none d-sm-block`}
                    onClick={() => {
                      setSelectedSubUser(null);
                      setIsAddSubUserModalOpen(true);
                    }}
                  >
                    Add Sub-user
                  </Button>
                  <Button
                    variant="contained"
                    className="popup_Btn success_popup_btn d-flex d-sm-none"
                    onClick={() => {
                      setSelectedSubUser(null);
                      setIsAddSubUserModalOpen(true);
                    }}
                  >
                    <Add />
                  </Button>
                </>
              )}
          </Stack>
        </Box>
        <Box className="d-block d-sm-none" margin="0 10px">
          <Select
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            displayEmpty
            className="mobile-select-tabs w-100"
            inputProps={{ "aria-label": "Without label" }}
          >
            {tabContent?.map(
              (tabs, index) =>
                !tabs?.hide?.includes(
                  decryptPayload(localStorage.getItem("roles"))
                ) && (
                  <MenuItem
                    sx={{
                      color: value === index + 1 ? "#0D120B" : "#0395FF",
                      paddingLeft: value === index + 1 ? "30px" : "",
                    }}
                    value={tabs?.value}
                  >
                    {tabs?.label}
                  </MenuItem>
                )
            )}
          </Select>
        </Box>
        <StyledSubTabs
          value={value}
          scrollButtons
          onChange={handleChange}
          id="__new-tabs-container"
          aria-label="basic tabs example"
          className="d-none d-sm-block"
          sx={{ pl: 0, m: 0, mt: 4 }}
        >
          {tabContent?.map(
            (tabs, index) =>
              !tabs?.hide?.includes(
                decryptPayload(localStorage.getItem("roles"))
              ) && (
                <Tab
                  // sx={{ ml: 0.3, mr: 0.3 }}
                  value={tabs?.value}
                  label={tabs?.label}
                  {...a11yProps(index + 1)}
                />
              )
          )}
        </StyledSubTabs>
        <Box sx={{ minHeight: "65vh" }}>
          {tabContent?.map((tabs, index) => (
            <TabPanel value={value} index={index + 1}>
              {value === index + 1 ? tabs.component : null}
            </TabPanel>
          ))}
        </Box>
      </Box>

      {/* delete user modal */}
      {isDeleteUserModalOpen && (
        <DeleteModal
          open={isDeleteUserModalOpen}
          title="Delete Property User"
          item="Property User"
          deleteLoader={deletePropertyUserLoader}
          handleClose={handleCloseDeleteModal}
          handleDeleteBtn={handleDeletePropertyUser}
          fetchedData={deletePropertyUserDetails}
          isDeleteSuccess={deletePropertyUserDetails?.success}
          successMessage={deletePropertyUserDetails?.message}
          errorMessage={deletePropertyUserDetails?.data?.message}
          callListingAPI={() => {
            backToUserListPageHandler();
          }}
          footer={false}
        />
      )}

      {/* Add subscription Modal */}
      {addSubcriptionPopup && (
        <DialogBox
          width="lg"
          contentClassName={"bg-white"}
          openPopup={addSubcriptionPopup}
          setOpenPopup={setAddSubcriptionPopup}
          fullScreenModal={true}
          onClose={() => {
            setAddSubcriptionPopup(false);
            // dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <AddSubcription
              selectedData={providerData || countyDetails}
              callListingAPI={getPropertyListData}
              closePopup={setAddSubcriptionPopup}
              propertiesIds={selectedPropertiesData?.properties_Id}
              ids={selectedPropertiesData?.ids}
              uid={selectedPropertiesData?.uid}
              tabComponent={true}
              offsetNum={offsetNum}
            />
          }
        />
      )}

      {/* Choose subscription Modal */}
      {chooseAddSubcriptionPopup && (
        <AlertDialog
          type={"Warning"}
          contentClassName={"bg-white"}
          openPopup={chooseAddSubcriptionPopup}
          setOpenPopup={setChooseAddSubcriptionPopup}
          onClose={() => {
            setChooseAddSubcriptionPopup(false);
          }}
          onCloseFun={() => {
            setChooseAddSubcriptionPopup(false);
          }}
          mesage={
            <>
              <Typography variant="h6" className="text-center fw-400 m-0">
                You will be redirected to{" "}
                <span className="text-capitalize">
                  {providerData?.domain || providerData?.custom_domain}{" "}
                </span>
                platform for payment processing.
              </Typography>
              <p className="text-danger fw-500 mt-2">
                Please note that all properties associated with this account on{" "}
                <span className="text-capitalize">{providerData?.domain}</span>{" "}
                will be included in this transaction.
              </p>
            </>
          }
          buttons={
            <div className="mt-3">
              <Button
                onClick={() => {
                  setChooseAddSubcriptionPopup(false);
                }}
                className="popup_Btn  other_popup_btn mt-2 mx-1"
              >
                Cancel
              </Button>
              <Button
                onClick={goToPayment}
                className="popup_Btn success_popup_btn mt-2 mx-1"
              >
                Continue to payment
              </Button>
            </div>
          }
        />
      )}

      {/* add/edit sub user modal */}
      <AddSpouseModal
        fetchSubUserListing={fetchSubUserListing}
        open={isAddSubUserModalOpen}
        label="Sub User"
        handleClose={handleCloseAddSubUserModal}
        selectedItem={selectedSubUser}
        onSubmit={handleAddEditSubUser}
        isAdmin={true}
        setSelectedSubUser={setSelectedSubUser}
      />

      {/* delete sub user modal */}
      {isDeleteSubUserModalOpen && (
        <DeleteModal
          open={isDeleteSubUserModalOpen}
          title="Delete Sub User"
          item="Sub User"
          deleteLoader={subUserListLoader}
          handleClose={handleCloseDeleteSubUserModal}
          handleDeleteBtn={handleDeleteSubUser}
          fetchedData={deleteSubUserDetails}
          isDeleteSuccess={deleteSubUserDetails?.success}
          successMessage={deleteSubUserDetails?.message}
          errorMessage={deleteSubUserDetails?.data?.message}
          callListingAPI={() => {
            fetchSubUserListing();
          }}
          footer={false}
        />
      )}

      {/* success/error add sub user modal */}
      {/* {getObjLength(addSubUserDetails) ? (
        <AlertDialog
          type={addSubUserDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            // here below two dispatch using same loader in the slice so I reset the add sub user data then called the listing so loder will not stop unexpectedly(before data arrive).
            dispatch(subUsersActions?.setAddSubUserDetails({}));
            if (addSubUserDetails?.success) {
              setIsAddSubUserModalOpen(false);
              fetchSubUserListing();
            }
          }}
          mesage={
            addSubUserDetails?.success
              ? addSubUserDetails?.message
              : addSubUserDetails?.data?.message || "Something went wrong"
          }
          footer={false}
        />
      ) : null} */}

      <Loader open={subUserListLoader || isLoader} />
    </>
  );
};

export default ViewEditUserPage;
