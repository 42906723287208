import { createSlice } from "@reduxjs/toolkit";

const intialSendInvitationState = {
  sendInvitationListData: {},
  sendInvitationListDataCount: 0,
  sendInvitationDetails: {},
  sendInvitationListLoader: false,
  professionalTypeList: {},
  professionalTypeLoader: false,
  resendInvitationDetails: {},
};

const sendInvitation = createSlice({
  name: "sendInvitationDetails",
  initialState: intialSendInvitationState,
  reducers: {
    resetSendInvitationState(state, action) {
      state.sendInvitationListData = {};
      state.sendInvitationListLoader = false;
    },
    clearSendInvitationDetailsData(state, action) {
      state.sendInvitationDetails = {};
      state.sendInvitationListLoader = false;
    },
    sendInvitationDetails(state, action) {
      state.sendInvitationListLoader = true;
    },
    setSendInvitationDetails(state, action) {
      state.sendInvitationDetails = action.payload;
      state.sendInvitationListLoader = false;
    },
    fetchSendInvitationListData(state, action) {
      state.sendInvitationListLoader = true;
    },
    setSendInvitationListData(state, action) {
      state.sendInvitationListData = action.payload;
      state.sendInvitationListDataCount = action.payload?.count;
      state.sendInvitationListLoader = false;
    },
    resendInvitationDetails(state, action) {
      state.sendInvitationListLoader = true;
    },
    setResendInvitationDetails(state, action) {
      state.resendInvitationDetails = action.payload;
      state.sendInvitationListLoader = false;
    },
  },
});
export const sendInvitationAction = sendInvitation.actions;
export default sendInvitation.reducer;
