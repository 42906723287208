import React from "react";

import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { getObjLength, getURLName } from "../../helpers/utils";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useSelector } from "react-redux";

const DocumnetListModal = ({
  open,
  handleClose,
  files,
  generalFiles,
  editUCC,
}) => {
  console.log("files", files);
  const { uploadedPropertyDocuments } = useSelector(
    (state) => state?.propertyLists
  );
  return (
    !getObjLength(uploadedPropertyDocuments) && (
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-between align-items-center"
        >
          {/* Document List */}
          <Typography variant="h6">Document List</Typography>
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers mb={2}>
          {files?.length !== 0 && (
            <>
              <Typography className="key">UCC Document:</Typography>
              <Box pt={0} className="dropzone-items">
                {files?.map((file) => (
                  <Box
                    className="preview-file bg-white"
                    padding="5px 15px"
                    m={0.5}
                  >
                    {console.log("item", file)}
                    <CustomLinkWrapper
                      link={file ? file?.path + file?.filename : "#"}
                      title={getURLName(file?.filename)}
                      key={file?.path}
                      classes="preview-url"
                    />
                    <Tooltip
                      title="Replace UCC document"
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                    >
                      <IconButton
                        sx={{ color: "rgb(21, 190, 83)" }}
                        aria-label="toggle password visibility"
                        onClick={editUCC}
                        edge="end"
                      >
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </>
          )}

          {generalFiles?.length !== 0 && (
            <>
              <Typography className="key">General Document:</Typography>
              <Box pt={0} className="dropzone-items">
                {generalFiles?.map((file) => (
                  <Box
                    className="preview-file bg-white"
                    padding="5px 15px"
                    m={0.5}
                  >
                    {console.log("item", file)}
                    <CustomLinkWrapper
                      link={file ? file?.path + file?.filename : "#"}
                      title={getURLName(file?.filename)}
                      key={file?.path}
                      classes="preview-url"
                    />
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  );
};

export default DocumnetListModal;
