import { useState } from "react";
import { useEffect } from "react";
import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import { PER_PAGE } from "../helpers/constants";
import { getLocalFormatedDate } from "../helpers/utils";
import PaymentFailed from "../userProfile/Dashboard/PaymentFailed";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import { getDateInYYYYMMDDFormat, getObjLength } from "../helpers/utils";
import { logsListAction } from "../../redux/slices/logsTable/logsTableSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../admin/AddLeads";

const columns = [
  {
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    id: "message",
    disablePadding: true,
    label: "Message",
    minWidth: 200,
  },
  {
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    minWidth: 200,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const LogsTable = () => {
  const dispatch = useDispatch();

  // states
  const [page, setPage] = useState(1);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);
  const [selectedKey, setSelectedKey] = React.useState("message");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedDateRange, setSelectedDateRange] = React.useState(null);
  const [isCallFilterAPI, setIsCallFilterAPI] = React.useState(false);
  const [toggleFilterOptions, setToggleFilterOptions] = React.useState(false);

  // store data
  const { logsListLoader, logsListData } = useSelector(
    (state) => state?.logsLists
  );
  const { paymnetDoneDetails, isPaymentPending, paymentLoader } = useSelector(
    (state) => state?.userProfile
  );

  const _DATA = logsListData?.data ? logsListData?.data : [];
  const count = Math.ceil(logsListData?.count / PER_PAGE);

  const getLogsURL = (p) => {
    let URL = `customer/get-activities`;
    const key = selectedKey
      ? `&type=${selectedKey === "company_name" ? "company" : selectedKey}`
      : "";
    const value = selectedValue
      ? `&value=${encodeURIComponent(selectedValue)}`
      : "";
    const selectedDateRangePayload = selectedDateRange
      ? `&from_date=${
          getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) || ""
        } &to_date=${
          getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) || ""
        }`
      : "";
    return `${URL}?limit=${PER_PAGE}&offset=${
      (p - 1) * PER_PAGE
    }${key}${value}${selectedDateRangePayload}`;
  };

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    // _DATA.jump(p);
    // staticData.jump(p);
    dispatch(
      logsListAction.fetchLogsListData({
        url: getLogsURL(p),
        data: {},
        token: true,
      })
    );
  };

  // handle filter property in logs
  const handleFilterProperty = () => {
    if ((selectedKey && selectedValue) || selectedDateRange) {
      dispatch(
        logsListAction?.fetchLogsListData({
          url: getLogsURL(1),
          data: {},
          token: true,
        })
      );
      setPage(1);
      setIsCallFilterAPI(true);
    } else {
      return;
    }
  };

  // handle reset function in logs
  const resetFilterOption = () => {
    if (isCallFilterAPI) {
      setSelectedKey("message");
      setSelectedValue("");
      setSelectedDateRange(null);
      setPage(1);

      setIsCallFilterAPI(false);
      dispatch(
        logsListAction?.fetchLogsListData({
          url: `customer/get-activities?limit=${PER_PAGE}&offset=${0}`,
          data: {},
          token: true,
        })
      );
    } else {
      setSelectedKey("message");
      setSelectedValue("");
      setSelectedDateRange(null);
    }
  };

  //   get logs details
  const getLogsLists = useCallback(() => {
    dispatch(
      logsListAction?.fetchLogsListData({
        url: `customer/get-activities?limit=${PER_PAGE}&offset=${0}`,
        data: {},
        token: true,
      })
    );
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getLogsLists();
  }, [dispatch, getLogsLists]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  return (
    <>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Typography variant="h6" className="title">
          Logs List
        </Typography>
      </Box>
      {logsListData?.success ? (
        <>
          {/* Filtering Component Section */}
          <Tooltip title="Filter options" placement="right-start">
            <Box
              id="filter"
              onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
            >
              <h3 className="filter-text">Filter your results</h3>
              <ArrowDropDownIcon
                className="filter-arrow"
                sx={{ ms: 1, fontSize: "20px" }}
              />
            </Box>
          </Tooltip>

          {/* Filtering Options */}
          <Box
            sx={{
              // overflow: "hidden",
              // overflowX: "auto",
              mb: 1.5,
            }}
          >
            {toggleFilterOptions && (
              <>
                {/* for mobile view */}
                <Box className="d-block d-md-none mt-3">
                  <Grid
                    container
                    className="bg-white d-flex justify-content-around align-items-center px-4"
                    spacing={2}
                    pb={2}
                    pt={2}
                  >
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Typography className="search-text">Search By</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <FormControl className="filter-select-one w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          value={selectedKey}
                          onChange={(event) => {
                            setSelectedKey(event.target.value);
                            setSelectedValue("");
                            setSelectedDateRange(null);
                          }}
                        >
                          {columns.map(
                            (cell) =>
                              cell.id !== "no." && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.label}
                                </MenuItem>
                              )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    {selectedKey !== "created_at" && (
                      <Grid item xs={12} sm={12} md={4} lg={2}>
                        <FormControl
                          className="filter-search w-100"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {selectedKey === "created_at" && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-search w-100">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="w-100">
                        <Button
                          type="submit"
                          onClick={handleFilterProperty}
                          sx={mobileFilterResultStyle}
                        >
                          Filter Results
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        type="reset"
                        onClick={() => {
                          resetFilterOption();
                        }}
                        sx={mobileFilterResetBtnStyle}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* for desktop view */}
                <Card className="d-none d-md-block filter-options-carddiv">
                  <Grid container className="filter-options-box">
                    <Stack direction="row" spacing={2} alignItems="center">
                      <div className="search-text mx-2">Search By</div>
                      <FormControl className="filter-select-one">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          value={selectedKey}
                          onChange={(event) => {
                            setSelectedKey(event.target.value);
                            setSelectedValue("");
                            setSelectedDateRange(null);
                          }}
                        >
                          {columns.map(
                            (cell) =>
                              cell.id !== "no." && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.label}
                                </MenuItem>
                              )
                          )}
                        </TextField>
                      </FormControl>
                    </Stack>
                    {selectedKey !== "created_at" && (
                      <Grid item>
                        <FormControl
                          className="filter-search"
                          variant="outlined"
                        >
                          <InputLabel>Enter search term</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter search term"
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {selectedKey === "created_at" && (
                      <Grid item>
                        <FormControl className="filter-search">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <FormControl>
                          <Button
                            className="filter-button"
                            onClick={handleFilterProperty}
                          >
                            Filter Results
                          </Button>
                        </FormControl>
                        <Button
                          className="reset-filters"
                          onClick={resetFilterOption}
                        >
                          Reset
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </>
            )}
          </Box>
          {/* Logs Listing Table */}
          {/* mobile view */}
          <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 sub-user-mobile-table py-4">
            {_DATA?.map((row, index) => {
              return (
                <>
                  <Stack direction="column" spacing={1.2} px={2}>
                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Message:</Typography>
                      <Typography className="value">
                        {row?.message || "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Created At:</Typography>
                      <Typography className="value">
                        {row.created_at
                          ? getLocalFormatedDate(row.created_at)
                          : "-"}
                      </Typography>
                    </Stack>
                  </Stack>

                  {index < _DATA?.length - 1 && (
                    <Divider
                      className="my-3"
                      sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                    />
                  )}
                </>
              );
            })}

            {getObjLength(logsListData) && !_DATA?.length > 0 && (
              <Typography
                className="d-block d-sm-none p-5 text-center"
                variant="h6"
              >
                No Data Found.
              </Typography>
            )}
          </Box>

          {/* desktop view */}
          <Box sx={{ width: "100%" }} className="d-none d-sm-block">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {_DATA?.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={row?.id}>
                          <TableCell align="left">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell align="left">{`${
                            row?.message || "-"
                          }`}</TableCell>
                          <TableCell align="left">
                            {row?.created_at
                              ? getLocalFormatedDate(row?.created_at)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!logsListData?.data?.length > 0 && !logsListLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
          </Box>
          {logsListData?.count > PER_PAGE && (
            <Box id="pagination-container">
              <CustomPagination
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography className="text-center text-danger">
            {logsListData?.message}
          </Typography>
        </>
      )}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
        )}

      <Loader open={logsListLoader || paymentLoader} />
    </>
  );
};

export default LogsTable;
