import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import HistoryIcon from "@mui/icons-material/History";
import TableContainer from "@mui/material/TableContainer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import HistoryOfRequestedDocuments from "./HistoryOfRequestedDocuments";
import UploadRequestDocModal from "../AdminDashboard/UploadRequestDocModal";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import RequestedDocumentListModal from "../../common/RequestedDocumentListModal";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import WaiverDocumentFormModal from "../../for-service-provider/WaiverDocumentFormModal";
import TerminateDocumentFormModal from "../../for-service-provider/TerminateDocumentFormModal";
import {
  PER_PAGE,
  REQUEST_ACCESS_ACTION,
  REQUESTED_DOCUMENT_STATUS,
} from "../../helpers/constants";
import { requestDocumentsAction } from "../../../redux/slices/admin/requestDocuments/requestDocumentsSlice";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
  getObjLength,
  urlPatternValidation,
} from "../../helpers/utils";

const columns = [
  { key: 1, id: "no.", disablePadding: true, label: "No.", minWidth: 50 },
  {
    key: 2,
    id: "address",
    disablePadding: true,
    label: "Property Address",
    minWidth: 160,
  },
  {
    key: 3,
    id: "doc_id",
    disablePadding: true,
    label: "Document",
    minWidth: 30,
  },
  {
    key: 4,
    id: "user_name",
    disablePadding: true,
    label: "Service Provider",
    minWidth: 120,
  },
  {
    key: 5,
    id: "request_action",
    disablePadding: true,
    label: "Request Action",
    minWidth: 70,
  },
  {
    key: 6,
    id: "transaction_id",
    disablePadding: true,
    label: "Transaction id",
    minWidth: 185,
  },
  {
    key: 7,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 70,
    showInfoIcon: true,
    // tooltipText: (
    //   <>
    //     <Stack direction="column">
    //       <Typography>Requested</Typography>
    //       <Typography>Accepted</Typography>
    //       <Typography>Rejected</Typography>
    //       <Typography> Completed</Typography>
    //     </Stack>
    //   </>
    // ),
    tooltipText: "Requested/ Accepted/ Rejected/ Completed",
  },
  {
    key: 8,
    id: "created_at",
    disablePadding: true,
    label: "Created At ",
    minWidth: 120,
    sorting: true,
  },
  {
    key: 9,
    id: "updated_at",
    disablePadding: true,
    label: "Uploaded At ",
    minWidth: 120,
    sorting: true,
  },
  {
    key: 10,
    id: "action",
    disablePadding: true,
    label: "Action",
    minWidth: 50,
  },
];

export const documentCount = (doc_url = null, uploaded_doc = null) => {
  if (doc_url && uploaded_doc) {
    return 2;
  } else if (doc_url || uploaded_doc) {
    return 1;
  } else {
    return 0;
  }
};

const RequestDocumentsListTable = () => {
  const dispatch = useDispatch();

  // states
  const [page, setPage] = useState(1);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedValue, setSelectedValue] = useState("");
  const [requestDocumentPayload, setRequestDocumentPayload] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isFilterApiCalled, setIsFilterApiCalled] = useState(false);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(9); // updated_at key for sorting
  const [requestedStatus, setRequestedStatus] = useState("");
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [requestAction, setRequestAction] = useState("");
  const [isDocumentListModalOpen, setIsDocumentListModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [
    isCancelRequestedDocumentModalOpen,
    setIsCancelRequestedDocumentModalOpen,
  ] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editRequestedDocumnet, setEditRequestedDocumnet] = useState(false);
  const [historyOfRequestedDocumnet, setHistoryOfRequestedDocumnet] =
    useState(false);
  const [uploadDocPopup, setUplaodDocPopup] = useState(false);
  const open = Boolean(anchorEl);

  // store data
  const {
    requestDocumentsData,
    requestDocumentsDataCount,
    requestDocumentListLoader,
    cancelRequestedDocumentDetails,
    cancelRequestedDocumentLoader,
    editRequestedDocumentDetails,
    editRequestedDocumentLoader,
    uploadedDocDetails,
    docLoader,
  } = useSelector((state) => state.requestDocuments);

  const { statesCodeList } = useSelector((state) => state.signUpUser);

  const count = Math.ceil(requestDocumentsDataCount / PER_PAGE);

  const _DATA = requestDocumentsData?.data ? requestDocumentsData?.data : [];

  const handlePageChange = (e, p) => {
    // pageChangeHandler(e, p);
    setPage(p);
    setRequestDocumentPayload((prevState) => ({
      ...prevState,
      limit: PER_PAGE,
      offset: (p - 1) * PER_PAGE,
    }));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const opnEditRequestedDocumnetModal = () => {
    if (!getObjLength(statesCodeList)) {
      dispatch(
        userSigupAction.getStateCode({ url: "get-states-code", data: {} })
      );
    }
    setEditRequestedDocumnet(true);
  };

  const handleEditDocumentBtn = (payload, type) => {
    console.log(type, "payload ==>", payload);
    dispatch(
      requestDocumentsAction.editRequestedDocument({
        url: `admin/property/document-requests/${selectedRow?.id}/edit`,
        data: {
          type,
          ...payload,
        },
        token: true,
      })
    );
    setEditRequestedDocumnet(false);
  };

  const handleHistoryDocumnetBtn = () => {
    setHistoryOfRequestedDocumnet(true);
    dispatch(
      requestDocumentsAction.historyOfRequestedDocument({
        url: `admin/property/document-requests/${selectedRow?.id}/logs`,
        token: true,
      })
    );
  };

  // upload documentation
  const uploadedPropertyDocuments = (row) => {
    setUplaodDocPopup(true);
  };
  const handleCloseModal = () => {
    dispatch(requestDocumentsAction.fetchUploadedDocData({}));
    setUplaodDocPopup(false);
  };

  const handleUploadDocuments = (values) => {
    const uploadedDocs = values.upload_doc;
    const docType = selectedRow?.request_action?.includes("termination")
      ? "terminate"
      : "waiver";
    const formData = new FormData();

    // formData.append("id", selectedProperty.id.toString());
    // uploadedDocs.map((doc, index) =>
    formData.append(`document`, uploadedDocs);
    // );
    // formData.append("property_id", selectedProperty?.property_id);

    // Display the values

    for (const value of formData.values()) {
      console.log(value);
    }

    dispatch(
      requestDocumentsAction.getUploadedDocData({
        url: `admin/upload-document/${selectedRow?.id}/${docType}`,
        data: formData,
        token: true,
        isFile: true,
      })
    );
  };

  const getRequestDocumentList = useCallback(() => {
    dispatch(
      requestDocumentsAction.getRequestDocumentListData({
        url: "admin/property/document-requests",
        data: {
          limit: requestDocumentPayload?.limit || PER_PAGE,
          offset: requestDocumentPayload?.offset || 0,
          type: requestDocumentPayload?.type,
          term: requestDocumentPayload?.term,
          status: requestDocumentPayload?.status,
          document_type: requestDocumentPayload?.document_type,
          from_date: requestDocumentPayload?.from_date,
          to_date: requestDocumentPayload?.to_date,
          modified_from_date: requestDocumentPayload?.modified_from_date,
          modified_to_date: requestDocumentPayload?.modified_to_date,
          order_by_column:
            requestDocumentPayload?.order_by_column || "updated_at",
          sorting_order: requestDocumentPayload?.sorting_order || "desc",
        },
        token: true,
      })
    );
  }, [
    dispatch,
    requestDocumentPayload?.limit,
    requestDocumentPayload?.offset,
    requestDocumentPayload?.type,
    requestDocumentPayload?.term,
    requestDocumentPayload?.status,
    requestDocumentPayload?.document_type,
    requestDocumentPayload?.from_date,
    requestDocumentPayload?.to_date,
    requestDocumentPayload?.modified_from_date,
    requestDocumentPayload?.modified_to_date,
    requestDocumentPayload?.order_by_column,
    requestDocumentPayload?.sorting_order,
  ]);

  // handle filter property in request document
  const handleFilterProperty = () => {
    if (
      (selectedKey && selectedValue) ||
      selectedDateRange ||
      requestedStatus ||
      requestAction
    ) {
      setPage(1);
      setRequestDocumentPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        term: selectedValue || undefined,
        status: requestedStatus || undefined,
        document_type: requestAction || undefined,
        from_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (selectedKey === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        modified_to_date:
          (selectedKey === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE,
        offset: 0,
      }));
      setIsFilterApiCalled(true);
    } else {
      return;
    }
  };

  // handle reset function in request document
  const resetFilterOption = () => {
    if (isFilterApiCalled) {
      setPage(1);
      setRequestDocumentPayload({ limit: PER_PAGE, offset: 0 });
      setIsFilterApiCalled(false);
      setDirectionFlow(true);
      setSelectedColumn(8);
      setRequestAction("");
    }
    setSelectedValue("");
    setSelectedKey("all");
    setSelectedDateRange(null);
    setRequestedStatus("");
    setSelectedDateType("created_at");
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    setRequestDocumentPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  const handleCancelRequestAPI = () => {
    if (selectedRow) {
      dispatch(
        requestDocumentsAction.cancelRequestedDocument({
          url: `admin/cancel-requested-documents/${selectedRow?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // use effect to fetch documents
  useEffect(() => {
    getRequestDocumentList();
  }, [dispatch, getRequestDocumentList]);

  return (
    <>
      {/* Request Document Listing Table */}
      {getObjLength(requestDocumentsData) ? (
        requestDocumentsData?.success ? (
          <>
            {/* Filtering Component Section */}
            <Tooltip title="Filter options" placement="right-start">
              <Box
                id="filter"
                onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
              >
                <h3 className="filter-text">Filter your results</h3>
                <ArrowDropDownIcon
                  className="filter-arrow"
                  sx={{ ms: 1, fontSize: "20px" }}
                />
              </Box>
            </Tooltip>

            {/* Filtering Options */}
            <Box
              sx={{
                overflow: "hidden",
                overflowX: "auto",
                mb: 1.5,
              }}
            >
              {toggleFilterOptions && (
                <>
                  {/* for mobile view */}
                  <Box className="d-block d-md-none">
                    <Grid
                      container
                      className="bg-white d-flex justify-content-around align-items-center px-4"
                      spacing={2}
                      pb={2}
                      pt={2}
                    >
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <Typography className="search-text">
                          Search By
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <FormControl className="filter-select-one w-100">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="All"
                            value={selectedKey}
                            onChange={(event) => {
                              setSelectedKey(event.target.value);
                              setSelectedValue("");
                              setSelectedDateRange("");
                              setRequestedStatus("");
                              setSelectedDateType("created_at");
                              setRequestAction("");
                            }}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {columns.map(
                              (cell) =>
                                cell.id !== "no." &&
                                cell.id !== "uploaded_at" && (
                                  <MenuItem key={cell.id} value={cell.id}>
                                    {cell.label}
                                  </MenuItem>
                                )
                            )}
                          </TextField>
                        </FormControl>
                      </Grid>

                      {selectedKey !== "created_at" &&
                        selectedKey !== "request_action" &&
                        selectedKey !== "status" &&
                        selectedKey !== "updated_at" && (
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormControl
                              className="filter-search w-100"
                              variant="outlined"
                            >
                              <InputLabel>Enter search term</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Search />
                                  </InputAdornment>
                                }
                                label="Enter search term"
                                value={selectedValue}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                              />
                            </FormControl>
                          </Grid>
                        )}
                      {(selectedKey === "all" || selectedKey === "status") && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status"
                              value={requestedStatus}
                              onChange={(e) =>
                                setRequestedStatus(e.target.value)
                              }
                            >
                              {REQUESTED_DOCUMENT_STATUS?.map((status, key) => (
                                <MenuItem
                                  id={status?.value}
                                  value={status?.value}
                                >
                                  {status?.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {(selectedKey === "all" ||
                        selectedKey === "request_action") && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Request Action"
                              value={requestAction}
                              onChange={(e) => setRequestAction(e.target.value)}
                            >
                              {REQUEST_ACCESS_ACTION?.map((action, key) => (
                                <MenuItem
                                  id={action?.value}
                                  value={action?.value}
                                >
                                  {action?.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {selectedKey === "all" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Date type"
                              value={selectedDateType}
                              onChange={(e) => {
                                setSelectedDateType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Created At", value: "created_at" },
                                { label: "Updated At", value: "updated_at" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {(selectedKey === "all" ||
                        selectedKey === "created_at" ||
                        selectedKey === "updated_at") && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-search w-100">
                            <CustomDateRangePicker
                              rangeValue={selectedDateRange}
                              onChange={(values) => {
                                setSelectedDateRange(values);
                              }}
                              onClear={() => {
                                setSelectedDateRange(null);
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="w-100">
                          <Button
                            type="submit"
                            onClick={handleFilterProperty}
                            sx={mobileFilterResultStyle}
                          >
                            Filter Results
                          </Button>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Button
                          type="reset"
                          onClick={() => {
                            resetFilterOption();
                          }}
                          sx={mobileFilterResetBtnStyle}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  <Card
                    className="d-none d-md-block filter-options-carddiv"
                    // id="filter-options-card"
                  >
                    <Grid container className="filter-options-box">
                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <div className="search-text">Search By</div>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="All"
                              value={selectedKey}
                              onChange={(event) => {
                                setSelectedKey(event.target.value);
                                setSelectedValue("");
                                setSelectedDateRange("");
                                setRequestedStatus("");
                                setSelectedDateType("created_at");
                                setRequestAction("");
                              }}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              {columns.map(
                                (cell) =>
                                  cell.id !== "no." &&
                                  cell.id !== "uploaded_at" && (
                                    <MenuItem key={cell.id} value={cell.id}>
                                      {cell.label}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          </FormControl>
                        </Stack>
                      </Grid>

                      {selectedKey !== "status" &&
                        selectedKey !== "created_at" &&
                        selectedKey !== "request_action" &&
                        selectedKey !== "updated_at" && (
                          <FormControl
                            className="filter-search"
                            variant="outlined"
                          >
                            <InputLabel>Enter search term</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              }
                              label="Enter search term"
                              value={selectedValue}
                              onChange={(e) => setSelectedValue(e.target.value)}
                            />
                          </FormControl>
                        )}

                      {(selectedKey === "all" || selectedKey === "status") && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status"
                              value={requestedStatus}
                              onChange={(e) =>
                                setRequestedStatus(e.target.value)
                              }
                            >
                              {REQUESTED_DOCUMENT_STATUS?.map((status, key) => (
                                <MenuItem value={status?.value}>
                                  {status?.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {/* {(propertyType === "all" ||
                      propertyType === "postcardstatus") && (
                      <Grid item>
                        <FormControl className="filter-select-one">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Postcard Status"
                            value={postcardStatus}
                            onChange={(e) => setPostcardStatus(e.target.value)}
                          >
                            {postcardStatusOptions.map((data, key) => (
                              <MenuItem value={data.value}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )} */}

                      {(selectedKey === "all" ||
                        selectedKey === "request_action") && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Request Action"
                              value={requestAction}
                              onChange={(e) => setRequestAction(e.target.value)}
                            >
                              {REQUEST_ACCESS_ACTION?.map((action, key) => (
                                <MenuItem value={action?.value}>
                                  {action?.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {selectedKey === "all" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Date type"
                              value={selectedDateType}
                              onChange={(e) => {
                                setSelectedDateType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Created At", value: "created_at" },
                                { label: "Updated At", value: "updated_at" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {(selectedKey === "all" ||
                        selectedKey === "created_at" ||
                        selectedKey === "updated_at") && (
                        <FormControl className="filter-search">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      )}

                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <FormControl>
                            <Button
                              className="filter-button"
                              onClick={handleFilterProperty}
                            >
                              Filter Results
                            </Button>
                          </FormControl>
                          <Button
                            className="reset-filters"
                            onClick={resetFilterOption}
                          >
                            Reset
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </>
              )}
            </Box>
            <Box sx={{ width: "100%" }} className="mt-3">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <TableHeaderForSorting
                      handleSorting={handleSorting}
                      directionFlow={directionFlow}
                      setSelectedColumn={setSelectedColumn}
                      selectedColumn={selectedColumn}
                      columns={columns}
                    />
                    <TableBody>
                      {_DATA?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.address)}
                            tabIndex={-1}
                            key={row?.id}
                          >
                            <TableCell align="left">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {row?.address || "-"}
                            </TableCell>
                            <TableCell align="center">
                              {urlPatternValidation(row?.doc_url) ||
                              urlPatternValidation(row?.uploaded_doc) ? (
                                <span
                                  style={{ wordBreak: "break-all" }}
                                  className={`custom-link-wrapper text-break preview-url cursor-pointer p-2`}
                                  onClick={() => {
                                    setIsDocumentListModalOpen(true);
                                    setSelectedDocument(row);
                                  }}
                                >
                                  {documentCount(
                                    row?.doc_url,
                                    row?.uploaded_doc
                                  )}
                                </span>
                              ) : (
                                0
                              )}
                            </TableCell>
                            <TableCell align="left" className="text-capitalize">
                              {row?.user_id !== null ? (
                                row?.user_type?.includes("Super Admin") ? (
                                  row?.user_name || "-"
                                ) : (
                                  <Tooltip
                                    title={`Click here to see ${
                                      row?.user_type === "Customer"
                                        ? "Customer's"
                                        : row?.user_type === "Admin"
                                        ? "Admin's"
                                        : "Service Provider's"
                                    } details.`}
                                    TransitionComponent={Zoom}
                                    placement="top"
                                    arrow
                                  >
                                    <Link
                                      className="text-decoration-none "
                                      to={
                                        row?.user_type === "Customer"
                                          ? `/admin-dashboard/${row?.user_id}`
                                          : row?.user_type === "Admin"
                                          ? `/admins/${row?.user_id}`
                                          : `/service-providers/${row?.user_id}`
                                      }
                                    >
                                      {row?.user_name || "-"}
                                    </Link>
                                  </Tooltip>
                                )
                              ) : row?.user_name ? (
                                row?.user_name
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell align="left" className="text-capitalize">
                              {row?.request_action || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.transaction_id || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.status || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.created_at
                                ? getLocalFormatedDate(row?.created_at)
                                : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.updated_at
                                ? getLocalFormatedDate(row?.updated_at)
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={` ${
                                row?.status !== "Requested" &&
                                row?.status !== "Accepted" &&
                                "cursor-disable"
                              }`}
                            >
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // if (row?.status === "Requested") {
                                  handleMenuClick(event);
                                  setSelectedRow(row);
                                  // }
                                }}
                                size="small"
                                className={`settings-button`}
                                aria-controls={
                                  open ? "settings-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                disabled={
                                  row?.status !== "Requested" &&
                                  row?.status !== "Accepted"
                                }
                              >
                                <SettingsIconSVG
                                  color={
                                    row?.status === "Requested" ||
                                    row?.status === "Accepted"
                                      ? "#15BE53"
                                      : "#e0e0e0"
                                  }
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!requestDocumentsData?.data?.length > 0 &&
                    !requestDocumentListLoader && (
                      <Typography className="p-5 text-center" variant="h6">
                        No Data Found.
                      </Typography>
                    )}
                </TableContainer>
              </Paper>
              {requestDocumentsDataCount > PER_PAGE && (
                <Box id="pagination-container">
                  <CustomPagination
                    count={count}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Typography className="text-center text-danger my-3">
            {requestDocumentsData?.data?.message ||
              requestDocumentsData?.message ||
              "Something went wrong."}
          </Typography>
        )
      ) : null}

      {/* Menu Options */}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        disabled={selectedRow?.status !== "Requested"}
      >
        {selectedRow?.status === "Requested" && (
          <MenuItem
            className="setting-menu-item"
            onClick={opnEditRequestedDocumnetModal}
          >
            <Box className="setting-menu-box">
              <DriveFileRenameOutlineIcon />
              <Typography className="setting-menu-text">
                Edit Termination Request
              </Typography>
            </Box>
          </MenuItem>
        )}
        {selectedRow?.status === "Requested" && (
          <MenuItem
            className="setting-menu-item"
            onClick={() => {
              setIsCancelRequestedDocumentModalOpen(true);
            }}
          >
            <Box className="setting-menu-box">
              <CancelOutlinedIcon />
              <Typography className="setting-menu-text">
                Cancel Requested Document
              </Typography>
            </Box>
          </MenuItem>
        )}

        {/* <MenuItem
            className="setting-menu-item"
            onClick={handleHistoryDocumnetBtn}
          >
            <Box className="setting-menu-box">
              <HistoryIcon />
              <Typography className="setting-menu-text">History</Typography>
            </Box>
          </MenuItem> */}

        {selectedRow?.status === "Accepted" && (
          <MenuItem
            className="setting-menu-item"
            onClick={() => uploadedPropertyDocuments()}
          >
            <Box className="setting-menu-box">
              <UploadFileOutlinedIcon />
              <Typography className="setting-menu-text">
                Upload Document
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>

      {/* document list modal */}
      {isDocumentListModalOpen && (
        <RequestedDocumentListModal
          open={isDocumentListModalOpen}
          handleClose={() => {
            setIsDocumentListModalOpen(false);
            setSelectedDocument(null);
          }}
          requestedDocument={selectedDocument?.doc_url}
          uploadedDocument={selectedDocument?.uploaded_doc}
        />
      )}

      {/* Cancel Request Waring popup */}
      {isCancelRequestedDocumentModalOpen && (
        <AlertDialog
          openPopup={isCancelRequestedDocumentModalOpen}
          type={"Warning"}
          onClose={true}
          onCloseFun={() => {
            setIsCancelRequestedDocumentModalOpen(false);
          }}
          buttons={
            <>
              <div className="text-center stepBtn mt-4 ">
                <Button
                  onClick={() => setIsCancelRequestedDocumentModalOpen(false)}
                  className="popup_Btn other_popup_btn mx-1 mb-2 mb-sm-0"
                  variant="contained"
                >
                  No, Cancel
                </Button>
                <Button
                  onClick={handleCancelRequestAPI}
                  className="popup_Btn success_popup_btn mx-1 mb-2 mb-sm-0"
                  variant="contained"
                >
                  Yes, Sure
                </Button>
              </div>
            </>
          }
          mesage={
            <>
              {`Are you sure you want to cancel the ${selectedRow?.request_action} for `}
              <b>{selectedRow?.address}</b>
              {"?"}
            </>
          }
          loader={cancelRequestedDocumentLoader}
        />
      )}

      {getObjLength(cancelRequestedDocumentDetails) ? (
        <AlertDialog
          type={cancelRequestedDocumentDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (cancelRequestedDocumentDetails?.success) {
              setSelectedRow(null);
              setIsCancelRequestedDocumentModalOpen(false);
              getRequestDocumentList();
            }
            dispatch(
              requestDocumentsAction?.setCancelRequestedDocumentDetails({})
            );
          }}
          mesage={
            cancelRequestedDocumentDetails?.success
              ? cancelRequestedDocumentDetails?.message
              : cancelRequestedDocumentDetails?.data?.message
          }
          footer={false}
        />
      ) : null}

      {/* Edit Requested Documnet Modal */}
      {editRequestedDocumnet ? (
        selectedRow?.request_action === "waiver request" ? (
          <WaiverDocumentFormModal
            open={editRequestedDocumnet}
            selectedData={selectedRow}
            loader={false}
            handleClose={() => {
              setEditRequestedDocumnet(false);
            }}
            onSubmit={(payload) =>
              handleEditDocumentBtn(payload, "waiver request")
            }
            selectedItem={selectedRow}
            isEdit={true}
          />
        ) : selectedRow?.request_action === "termination request" ? (
          <TerminateDocumentFormModal
            open={editRequestedDocumnet}
            selectedData={selectedRow}
            loader={false}
            handleClose={() => {
              setEditRequestedDocumnet(false);
            }}
            onSubmit={(payload) =>
              handleEditDocumentBtn(payload, "termination request")
            }
            selectedItem={selectedRow}
            isEdit={true}
          />
        ) : null
      ) : null}

      {getObjLength(editRequestedDocumentDetails) ? (
        <AlertDialog
          type={editRequestedDocumentDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (editRequestedDocumentDetails?.success) {
              setSelectedRow(null);
              setEditRequestedDocumnet(false);
              getRequestDocumentList();
            }
            dispatch(
              requestDocumentsAction?.setEditRequestedDocumentDetails({})
            );
          }}
          mesage={
            editRequestedDocumentDetails?.success
              ? editRequestedDocumentDetails?.message
              : editRequestedDocumentDetails?.data?.message
          }
          footer={false}
        />
      ) : null}

      {/* History of Requested documents */}
      {historyOfRequestedDocumnet && (
        <HistoryOfRequestedDocuments
          open={historyOfRequestedDocumnet}
          handleClose={() => {
            setHistoryOfRequestedDocumnet(false);
            dispatch(requestDocumentsAction.setHistoryOfDocumentDetails({}));
          }}
        />
      )}

      {/* Uplaod Document popup */}
      <UploadRequestDocModal
        from="TerminationDocument"
        fileFormates={{
          "image/png": [".png", ".jpeg", ".jpg"],
          "image/gif": [".gif"],
          "application/vnd.ms-powerpoint": [".ppt"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/csv": [".csv"],
        }}
        multiple={false}
        open={uploadDocPopup}
        handleClose={() => {
          handleCloseModal();
        }}
        caption={
          "Please upload Property Termination document for this property. "
        }
        isUploadSuccess={uploadedDocDetails?.success}
        uploadedDocData={uploadedDocDetails}
        successMessage={uploadedDocDetails?.message}
        errorMessage={uploadedDocDetails?.data?.message}
        uploadLoader={docLoader}
        handleUploadDoc={handleUploadDocuments}
        fetchPropertyListingData={() => {
          getRequestDocumentList();
        }}
      />

      <Loader
        open={
          requestDocumentListLoader || editRequestedDocumentLoader || docLoader
        }
      />
    </>
  );
};

export default RequestDocumentsListTable;
