import React from "react";

import { Box, Button, Stack, Typography } from "@mui/material";

import AlertDialog from "./AlertDialog";
import SupportEmailLink from "./SupportEmailLink";
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";

export const Callto = ({ phone, children, className }) => {
  return (
    <a href={`tel:${phone}`} className={className}>
      {children}
    </a>
  );
};
const contactsValueStyle = {
  cursor: "pointer",
};

const ContcatAdminSupport = ({ errorPopup, reduceErrorCount, message }) => {
  return (
    <AlertDialog
      openPopup={errorPopup}
      onClose={true}
      onCloseFun={() => {
        reduceErrorCount();
      }}
      type="Error"
      footer={false}
      mesage={
        <Stack spacing={2}>
          <p className="text-danger text-center">{message}</p>
          <Typography>
            Are you stuck here? Please contact our support team for any help.
          </Typography>
          <Box className="d-flex justify-content-center">
            <Stack justifyContent="center" spacing={1}>
              <Stack direction="row" spacing={2}>
                <Typography>Email:</Typography>
                <SupportEmailLink
                  email={SUPPORT_EMAIL}
                  className="green-link"
                  sx={contactsValueStyle}
                  text={SUPPORT_EMAIL}
                />{" "}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography>By Phone:</Typography>
                <Typography>
                  <Callto phone={`${SUPPORT_PHONE}`} className="green-link">
                    {SUPPORT_PHONE_FORMAT}
                  </Callto>
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      }
      buttons={
        <>
          <Button
            className="popup_Btn error_popup_btn m-2"
            onClick={reduceErrorCount}
          >
            ok, Close
          </Button>
        </>
      }
    />
  );
};

export default ContcatAdminSupport;
