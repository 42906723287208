import React, { lazy, Suspense, useEffect } from "react";

import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import { Stack } from "@mui/system";
import { Box } from "@mui/material";

import { Loader } from "../common/loader";
import bgimg from "../../assets/image-1.WebP";
import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import house_icon from "../../assets/icons/house.svg";
import lable_icon from "../../assets/icons/label.svg";
import forbes from "../../assets/leadpage/forbes.WebP";
import tv_icon from "../../assets/leadpage/tv-icon.svg";
import article_icon from "../../assets/icons/article.svg";
import address_icon from "../../assets/icons/pin_drop.svg";
import ProductComparison from "../common/ProductComparison";
import FAQsAccordianDesign from "../common/AccordianDesign";
import usa_today from "../../assets/leadpage/usa_today.WebP";
import msnbc_logo from "../../assets/leadpage/msnbc-logo.png";
import { getObjLength, reactPixelEvent } from "../helpers/utils";
import headphones_icon from "../../assets/icons/headset_mic.svg";
import { userSigInAction } from "../../redux/slices/user/userSignInSlice";
import EquityProtectAssetsVideos from "../common/EquityProtectAssetsVideos";

import "../../styles/landingpage.scss";

const RealStoryDesign = lazy(() => import("../common/RealStoryDesign"));
const Testimonials = lazy(() => import("../common/Testimonials"));
const HowItWorksSection = lazy(() => import("../common/HowItWorksSection"));

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = window.location.search;
  let path = params?.split("?")[1];
  let is_sso = path?.split("&")[0]?.replace("is_sso=", "");
  let token = path?.split("&")[1]?.replace("token=", "");

  const { ssoDetails, sso_Loader } = useSelector((state) => state.signInUser);

  useEffect(() => {
    reactPixelEvent("HomePage", {});
  }, []);

  useEffect(() => {
    if (is_sso === "true") {
      console.log("token==>", token);
      dispatch(
        userSigInAction.getSSOData({
          url: `sso-authenticate`,
          data: {
            authToken: token,
          },
        })
      );
    }
  }, [dispatch, is_sso, token]);

  useEffect(() => {
    console.log("ssoDetails==>", ssoDetails);
    if (getObjLength(ssoDetails)) {
      if (ssoDetails?.success) {
        navigate("/user-profile", { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
      dispatch(userSigInAction.fetchSSODetails({}));
    }
  }, [dispatch, navigate, ssoDetails]);

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href="../../assets/image-1.WebP"
          type="image/webp"
        />
      </Helmet>
      <div className="landing-content">
        <section
          className="d-flex align-items-center background_img"
          style={{ backgroundImage: `url('${bgimg}')` }}
        >
          <Container fluid className="text-white">
            <Row className="mt-5 justify-content-center text-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={7}
                className="content-spacing"
              >
                <h1 className="H1 fw-500 mb-4">
                  The <span className="header_text_underline">Only</span>{" "}
                  Company That{" "}
                  <span className="header_text_underline">Prevents</span> Deed
                  And Home Title Theft
                </h1>
                <p className="paragraph_text fw-500 mb-4 mt-2">
                  EquityProtect does not just monitor, we do what others don’t –
                  we protect your title and more importantly your equity with
                  our patented SmartPolicy locking down your home ownership from
                  cybercriminals and fraud.
                </p>
                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={{ xs: "column", sm: "column" }}
                  spacing={2}
                >
                  <Link
                    className=" text-white text-decoration-none"
                    to="/create-account"
                  >
                    <button className="signin_button button_clr1">
                      SIGNUP NOW
                    </button>
                  </Link>
                  <Link
                    className="text-white text-decoration-none"
                    to="/property-risk-management"
                  >
                    <button className="signin_button risk_button text-uppercase">
                      Check now to see if you are a victim
                    </button>
                  </Link>
                </Stack>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-content-center my-3 py-1 as_seen_on">
              <img src={tv_icon} alt="Tv_icon" />
              <span className="px-2 pt-1">As Seen on</span>
            </div>
            <div className="d-flex justify-content-evenly align-content-center pb-3">
              <Stack
                className="homepage_logos d-flex justify-content-around d-md-none"
                spacing={2}
              >
                <Stack
                  spacing={{ xs: 2, sm: 3 }}
                  direction="row"
                  justifyContent="center"
                >
                  <LazyLoadImage src={nbc} alt="nbc" />
                  <LazyLoadImage src={forbes} alt="forbes" />
                  <LazyLoadImage src={fox} alt="fox" />
                </Stack>
                <Stack
                  spacing={{ xs: 1, sm: 3 }}
                  direction="row"
                  justifyContent="center"
                >
                  <LazyLoadImage src={cbs} alt="cbs" />
                  <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" />
                  <LazyLoadImage src={usa_today} alt="usa_today" />
                  <LazyLoadImage src={cnn} alt="cnn" />
                </Stack>
              </Stack>
              <Box className="homepage_logos d-flex justify-content-around d-none d-md-flex">
                <LazyLoadImage src={nbc} alt="nbc" className="px-2" />
                <LazyLoadImage src={forbes} alt="forbes" className="px-2" />
                <LazyLoadImage src={fox} alt="fox" className="px-2" />
                <LazyLoadImage src={cbs} alt="cbs" className="px-2" />
                <LazyLoadImage
                  src={msnbc_logo}
                  alt="msnbc_logo"
                  className="px-2"
                />
                <LazyLoadImage
                  src={usa_today}
                  alt="usa_today"
                  className="px-2"
                />
                <LazyLoadImage src={cnn} alt="cnn" className="px-2" />
              </Box>
            </div>
          </Container>
        </section>

        <section className="pt-150 alert_section mobile-secions-pt-0 pb-150">
          <Container fluid className="mx-150">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ backgroundColor: "#daebf7", padding: "45px" }}
                className="content-spacing"
              >
                <div className="alwaysAlert_wrapper">
                  <p className="notify_header mb-4">Always in alert</p>
                  <p className="bold sub_header mb-4">
                    24/7 PROTECTION - GUARANTEED
                  </p>
                  <p className="description_text fw-300 mt-2 mb-3 mb-lg-5">
                    Most “home title lock” companies today will monitor your
                    property for fraud and then notify you after the crime
                    occurs – some will even help you fix it once it has
                    happened. What they don’t tell you is that this type of
                    monitoring is already available to you{" "}
                    <b className="fw-500">absolutely free</b> from many county
                    recorders.
                  </p>
                  <p className="description_text fw-500 mt-2 mb-3 mb-lg-5">
                    Additionally, once the fraud happens, it can cost you up to
                    thousands of dollars to reverse and months of stress and
                    worry that could have been easily prevented.
                  </p>
                  <p className="description_text fw-300 mt-2 mb-3 mb-lg-5">
                    Using a new Patented process, EquityProtect will scan,
                    monitor, alert, and most importantly,{" "}
                    <span className="fw-500">
                      <u>protect you</u>
                    </span>{" "}
                    from any attempts to tamper with or steal your home equity
                    and ownership – 365 days a year, 24/7.
                  </p>
                  <Stack direction="row" justifyContent="center">
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="success protected_btn text-uppercase text-white mt-3">
                        GET PEACE OF MIND
                      </Button>
                    </Link>
                  </Stack>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={{ span: 7, offset: 1 }}
                xl={{ span: 7, offset: 1 }}
                className="content-spacing"
              >
                <Row style={{ marginTop: "20px" }}>
                  <h1 className="text-center bold  mb-4">
                    We’re Your Private Guard.
                  </h1>
                  <p className="p-2 mb-2 pb-0">
                    {/* The FBI reported that in 2017 there were 9,654 cases of real
                    estate fraud, resulting in more than $56 million in losses.
                    Home title fraud has grown considerably since then, with
                    11,578 cases of real estate fraud reported in 2021, with
                    losses totaling more than $350 million. */}
                    Fraudsters are trying different methods of impacting the
                    real estate industry – as evidenced by the aforementioned
                    categories. It’s no surprise then that losses are up from
                    years past. Just take a look: Real estate industry losses in{" "}
                    <br />
                    <b>2020: $213,196,082</b>
                    <br /> <b>2021: $350,328,166</b> <br />
                    <b>2022: $396,932,821</b>
                  </p>
                  <p className="p-2 pb-0 mb-2">
                    The difference between 2020 and 2022 is incredibly large
                    with losses almost doubling over that two-year period.
                  </p>
                  <p className="p-2 pb-0">
                    Because there are no signs of fraud activity slowing down,
                    those in the real estate industry need to take heed. Large
                    amounts of money are changing hands with each closing – and
                    this makes everyone involved vulnerable.
                  </p>
                  <p className="p-2 pb-0">
                    <b>The Importance of Reporting Crime</b>
                    {/* </p> <p className="p-2"> */}
                    <br />
                    As discussed, real estate fraud is very real. Not only do
                    these numbers prove that it is moving in the wrong
                    direction. Sadly, it is becoming commonplace, with local
                    stories of scams and fraudulent activity in the industry
                    making it onto the daily evening news lineup.
                  </p>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={article_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="article"
                      />
                      {/* <img
                        className="p-1"
                        src={article_icon}
                        alt="article"
                        width="78px"
                        height="78px"
                        loading="lazy"
                      /> */}
                      <p className="privateGuardList">
                        Title changes & new Loans
                      </p>
                      <p className="p-2">
                        Criminals can literally remove you from your home's
                        title. Then they take out loans against your home and
                        leave you in debt.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={lable_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="lable"
                      />
                      {/* <img
                        className="p-1"
                        src={lable_icon}
                        alt="lable"
                        width="78px"
                        height="78px"
                        loading="lazy"
                      /> */}
                      <p className="privateGuardList">Rent listings</p>
                      <p className="p-2">
                        EquityProtect will continuously monitor for your homes’
                        addresses to be listed on popular vacation rental and
                        classified ad sites.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={address_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="address"
                      />
                      {/* <img
                        className="p-1"
                        src={address_icon}
                        alt="address"
                        width="78px"
                        height="78px"
                        loading="lazy"
                      /> */}
                      <p className="privateGuardList">Property address</p>
                      <p className="p-2">
                        Instant alerts if we detect tampering with your title or
                        mortgage - we’re the hawk keeping a close watch at all
                        times.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={house_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="house"
                      />
                      {/* <img
                        className="p-1"
                        src={house_icon}
                        alt="house"
                        width="78px"
                        height="78px"
                        loading="lazy"
                      /> */}
                      <p className="privateGuardList">Sale listings</p>
                      <p className="p-2">
                        By constantly monitoring the Real Estate Listing
                        Services and the web, we can protect your investment
                        property (or second home) from being sold without your
                        knowledge.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6} className="px-0">
                    <div className="pt-3">
                      <LazyLoadImage
                        src={headphones_icon}
                        className="p-1"
                        width="78px"
                        height="78px"
                        alt="headphones"
                      />
                      {/* <img
                        className="p-1"
                        src={headphones_icon}
                        alt="headphones"
                        width="78px"
                        height="78px"
                        loading="lazy"
                      /> */}
                      <p className="privateGuardList">Live Support</p>
                      <p className="p-2">
                        Instant alerts 24/7 if we detect any form of tampering
                        with your title or mortgage.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="d-flex align-items-center pt-150  pb-5"
          style={{ background: "#EBF3FC" }}
        >
          <Container fluid className="">
            <div className=" text-center">
              <div className="mb-5">
                <p className="notify_header">How we stand out</p>
                <h3 className="sub_header mb-4">
                  Designed to Set a New Grade in Title Security and Service
                </h3>
                <p className="subTitle mb-5">See how we stand out</p>
              </div>
            </div>
            <Row className="mt-5 mb-3 justify-content-center ">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={10}
                className="content-spacing"
              >
                <ProductComparison />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pt-5 propertyFraud_section">
          <Container fluid className="px-3 px-md-5">
            <Row>
              <Col>
                <div className="card text-center linear_bg">
                  <div className="card-body p-0">
                    <p className="privateGuardList card-title text-white text-center mb-4">
                      Let’s protect you against what is now one of the Fastest
                      Growing Crimes in America.
                    </p>
                  </div>
                  <div className="mt-2">
                    <Row className="">
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <h3 className="value fw-700">$43.4 Trillion</h3>
                        <p className="label d-block d-md-none">
                          U.S Homes Value as of 2021
                        </p>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <h3 className="value fw-700">6,134%</h3>
                        <p className="label d-block d-md-none">
                          Property Fraud Increase{" "}
                        </p>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <h3 className="value fw-700">$9.9 Trillion</h3>
                        <p className="label d-block d-md-none">
                          Homeowner Equity as of 2021
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className="label d-none d-md-block">
                          U.S Homes Value as of 2021
                        </p>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className="label d-none d-md-block">
                          Property Fraud Increase{" "}
                        </p>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className="label d-none d-md-block">
                          Homeowner Equity as of 2021
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-150 titlePro_section">
          <Container fluid className="mx-150">
            <Row>
              <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                <p className="notify_header">Things to look out for</p>
                <h3 className="sub_header">
                  Here’s what can happen without title protection
                </h3>
                <Link
                  className=" text-white text-decoration-none"
                  to="/create-account"
                >
                  <button className="signin_button protection_bg">
                    Get Protected
                  </button>
                </Link>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={{ span: 6, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 6, offset: 1 }}
              >
                <ul
                  className="protection_list list mt-4 mt-md-0"
                  style={{ listStyleType: "none" }}
                >
                  <li className="position-relative mb-3">
                    <span>
                      Property fraud & deed fraud – meaning your house may be
                      stolen from you.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Criminals can then take out loans using your home as
                      collateral.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Victims often don’t find out until they receive a
                      foreclosure notice or even an eviction.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Your home may be listed for rent on popular Classified Ad
                      sites or vacation rental sites.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Under most state laws, a county records division must
                      accept any filed document.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Years of stress (which could have been easily prevented
                      for &nbsp;<u>only a few pennies a day!</u>)
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-150 howWorks_section">
          <Container fluid className="px-3 px-md-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={150}>
                <HowItWorksSection />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </section>

        <section className="pt-150 offer_section">
          <Container fluid className="mx-150">
            <Row>
              <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                <p className="notify_header">What we offer</p>
                <h3 className="sub_header">Why our clients trust us.</h3>
                <p className="fw-300">
                  We take pride in being at the forefront of creating
                  Multi-Factor Authentication technology and monitoring services
                  to protect you from this terrible new crime.
                </p>
                <Link
                  className=" text-white text-decoration-none"
                  to="/create-account"
                >
                  <button className="signin_button protection_bg">
                    Get Protected
                  </button>
                </Link>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={{ span: 6, offset: 1 }}
                lg={{ span: 5, offset: 1 }}
                xl={{ span: 6, offset: 1 }}
              >
                <ul
                  className="reasons_list list mt-4 mt-md-0"
                  style={{ listStyleType: "none" }}
                >
                  <li className="position-relative mb-3">
                    <span className="fw-500">
                      The competition alerts the user if there has been an
                      issue. We physically STOP the action from being able to
                      happen.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Our USA-based experts are at your service 24/7 – there
                      will never be any overseas call centers involved with
                      Equity Protect!
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      Our SmartPolicy covers ALL losses incurred during the time
                      of enrollment.
                    </span>
                  </li>
                  <li className="position-relative mb-3">
                    <span>
                      We offer real-time tracking tools to monitor your title,
                      property value, rental rates, customized interest rate,
                      and many more.
                    </span>
                  </li>
                </ul>
                <div className="text-center mt-4">
                  <Link
                    className=" text-white text-center text-decoration-none"
                    to="/create-account"
                  >
                    <button className="signin_button button_clr1">
                      SIGN UP TODAY
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-150 banner_section">
          <Container fluid className="px-3 px-md-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={200}>
                <Testimonials />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </section>

        {/* <section className="pt-150 faq_wrapper">
          <Container fluid className="mx-150 pb-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={200}>
                <FAQsAccordianDesign />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </section> */}

        <section className="pt-150 pb-5 story_section">
          <Container fluid className="mx-150 pb-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={200}>
                <RealStoryDesign />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </section>
        <div className="pt-2 pb-5 ">
          {/* <Container fluid className=""> */}
          <Suspense fallback={<div>Loading...</div>}>
            <LazyLoadComponent threshold={200}>
              <EquityProtectAssetsVideos />
            </LazyLoadComponent>
          </Suspense>
          {/* </Container> */}
        </div>
      </div>
      <Loader open={sso_Loader} />
    </>
  );
};

export default React.memo(LandingPage);
