import React from "react";
import Box from '@mui/material/Box';
import MiniSidebar from "../layout/miniSidebar";

const UserProfile = ({comp}) => {
  return (
    <Box>
      <MiniSidebar comp={comp}/>
    </Box>
  )
};

export default UserProfile;
