import { createSlice } from "@reduxjs/toolkit";
import {
  decryptPayload,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const intialDocumentsState = {
  documentsData: {},
  documentsDataCount: 0,
  loader: false,
  propertyListLoader: false,
  uploadDocData: {},
  propertiesData: {},
  deleteDocData: {},
  deleteDocLoader: false,
  editDocData: {},
  terminationDocLoader: false,
  terminatedDocRequestPayload: {},
  terminatedDocRequestData: {},
  terminatedPropertyData: {},
  uploadedDocData: {},
  terminatedPropertiesData: {},
  renewedPropertiesData: {},
  waiverPropertiesData: {},
  resubscribePropertyData: {},
  propertyRestrictedData: {},
};

const documents = createSlice({
  name: "documentsDetails",
  initialState: intialDocumentsState,
  reducers: {
    getDocumentsData(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.loader = true;
    },
    fethDocumentsDataDoc(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.documentsData = action.payload;
      state.documentsDataCount = action.payload?.count;
      // state.uploadDocData = {};
      // state.deleteDocData = {};
      // state.editDocData = {};
      state.loader = false;
    },
    getCustomerPropertiesData(state, action) {
      console.log("get propertiesData slice==>", action.payload);
      state.uploadDocData = {};
      state.propertyListLoader = true;
    },
    fethPropertiesData(state, action) {
      console.log("get propertiesData slice==>", action.payload);
      state.propertiesData = action.payload;
      state.propertyListLoader = false;
    },
    uploadDocument(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.loader = true;
    },
    fethUploadedDocument(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.uploadDocData = action.payload;
      state.loader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // data: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Upload document", data);
      }
    },
    deleteDocument(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.deleteDocLoader = true;
    },
    fethDeletedDocument(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.deleteDocData = action.payload;
      state.deleteDocLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // data: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Document Deleted", data);
      }
    },
    editDocument(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.deleteDocLoader = true;
    },
    fethEditedDocument(state, action) {
      console.log("get documentsData slice==>", action.payload);
      state.editDocData = action.payload;
      state.deleteDocLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // data: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Document Edited", data);
      }
    },
    clearData(state, action) {
      state.uploadDocData = {};
      state.deleteDocData = {};
      state.editDocData = {};
      state.deleteDocLoader = false;
    },
    getTerminatedDocRequestData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
    },
    fetchTerminatedDocRequestData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminatedDocRequestData = action.payload;
      state.terminationDocLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
        // data: action.payload,
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Requested Documents", data);
      }
    },
    getTerminatedPropertyData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
      state.terminatedDocRequestPayload = action?.payload;
    },
    fetchTerminatedPropertyData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminatedPropertyData = action.payload;
      state.terminationDocLoader = false;
      let actions = state.terminatedDocRequestPayload;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer(
          `${actions?.action} ${actions?.type} reuqest`,
          data
        );
      }
      state.terminatedDocRequestPayload = {};
    },
    getUploadedDocData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
      state.terminatedDocRequestPayload = action?.payload;
    },
    fetchUploadedDocData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.uploadedDocData = action.payload;
      state.terminationDocLoader = false;
      let message = state.terminatedDocRequestPayload?.message;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer(message, data);
      }
      state.terminatedDocRequestPayload = {};
    },
    getTerminatedPropertiesData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
    },
    fetchTerminatedPropertiesData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminatedPropertiesData = action.payload;
      state.terminationDocLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Termination Document tab", data);
      }
    },
    getRenewedPropertiesData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
    },
    fetchRenewedPropertiesData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.renewedPropertiesData = action.payload;
      state.terminationDocLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Renewed Property Tab", data);
      }
    },
    getWaiverPropertiesData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
    },
    fetchWaiverPropertiesData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.waiverPropertiesData = action.payload;
      state.terminationDocLoader = false;
      let data = {
        email: decryptPayload(localStorage.getItem("loggedInEmail")),
      };
      if (action.payload?.success) {
        reactPixelEventOnlyForCustomer("Wavier Document Tab", data);
      }
    },
    getResubscribePropertyData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
    },
    fetchResubscribePropertyData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.resubscribePropertyData = action.payload;
      state.terminationDocLoader = false;
    },
    getPropertyRestrictedData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.terminationDocLoader = true;
    },
    fetchPropertyRestrictedData(state, action) {
      console.log("get terminatedDocRequestData slice==>", action.payload);
      state.propertyRestrictedData = action.payload;
      state.terminationDocLoader = false;
    },
  },
});
export const documentsAction = documents.actions;
export default documents.reducer;
