import { put, call, takeEvery } from "redux-saga/effects";
import { usersListAction } from "../../../slices/professional/professional-users-list/usersListSlice";
import { postAPIPayload } from "../../../../apis/postApi";

export function* fetchAllUsersSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("All Users response => ", response);
  yield put(usersListAction.fetchUsersListData(response));
}

export function* watchProfessionalUsersListsSagaAsync() {
  yield takeEvery(
    usersListAction.getUsersListData,
    fetchAllUsersSaga
  );
}
