import React, { useState } from "react";
import { useEffect } from "react";

import { ToastContainer } from "react-toastify";

import { Elements } from "@stripe/react-stripe-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import RootRouter from "./routes/index";
import {
  loadScriptByURL,
  richSnippetImplementation,
} from "./components/helpers/utils";
import { GOOGLE_API_KEY, stripePromise } from "./components/helpers/constants";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import { Loader } from "./components/common/loader";
import useGoogleMapsScript from "./components/hooks/useGoogleMapScript";
import { useDispatch } from "react-redux";
import { autocompleteAction } from "./redux/slices/google-map-api/autocompleteSlice";

function App() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
    },
  });

  const handleElementError = (error) => {
    console.error("Stripe Element Error:", error);
  };

  // load library over here
  useEffect(() => {
    // Check the environment variable
    const isProduction = process.env.REACT_APP_SERVER === "production";

    if (isProduction) {
      const scriptElement = richSnippetImplementation();
      document.body.appendChild(scriptElement);
    }

    // Function to be executed on mouse movement
    const loadAllScripts = () => {
      // google tag manager
      // loadScriptByURL(
      //   "google-tag-manager",
      //   "https://www.googletagmanager.com/gtag/js?id=G-D41DPKNEN1",
      //   function () {
      //     return;
      //   }
      // );
      // google map api
      // loadScriptByURL(
      //   "google-map",
      //   `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
      //   function () {
      //     return;
      //   }
      // );
    };

    // Attach event listeners

    // document.addEventListener("mousemove", loadAllScripts);
    // document.addEventListener("click", loadAllScripts);

    // Clean up event listeners on component unmount
    return () => {
      // document.removeEventListener("mousemove", loadAllScripts);
      // document.removeEventListener("click", loadAllScripts);
    };
  }, []);

  // const loaded = useGoogleMapsScript(GOOGLE_API_KEY);

  const loadScript = (url, callback) => {
    if (!document.querySelector(`script[src="${url}"]`)) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.defer = true;
      script.onload = callback;
      document.head.appendChild(script);
    } else {
      callback();
    }
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
      () => {
        setLoaded(true);
        dispatch(autocompleteAction.changeLoadedStatus(true));
      }
    );
  }, []);

  // if (!loaded) return <Loader />;

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Elements stripe={stripePromise} options={{ handleElementError }}>
          <RootRouter />
        </Elements>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
