import Dropzone, { DropzoneProps, useDropzone } from "react-dropzone";
import UploadFileIcon from "./customSVGs/UploadFileIcon";
import "./common.scss";
import { MAX_FILE_SIZE } from "../helpers/constants";
import { useState } from "react";
import { Typography } from "@mui/material";

const FileUpload = (props) => {
  const [error, setError] = useState(null);
  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = (files) => {
    var allFiles = files;

    (files || []).map((file) => {
      return Object.assign(file, {
        formattedSize: formatBytes(file.size),
      });
    });

    if (props.onFileUpload) props.onFileUpload(allFiles);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <>
      <Dropzone
        {...props}
        maxSize={MAX_FILE_SIZE * 1024 * 1024}
        onError={(error) => console.log("dropzone error", error)}
        onDrop={(acceptedFiles, rejectedFiles) => {
          console.log("rejectedFiles", rejectedFiles);
          if (!rejectedFiles?.length > 0) {
            handleAcceptedFiles(acceptedFiles);
            setError(null);
          } else {
            setError(
              "Please try again with less than 100MB files or different file type." // There might be a file in a list which contain size of more than 100MB or It's type is Invalid.
            );
          }
        }}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isFileDialogActive,
        }) => (
          <div
            className={`
              ${
                isDragActive || isFileDialogActive
                  ? "drag-active dropzone"
                  : "dropzone"
              }`}
          >
            <div
              className="dz-message needsclick custom-dropzone"
              style={{ padding: "16px" }}
              {...getRootProps()}
            >
              <p className="mb-0 break-word">
                {props.dragDropText
                  ? props.dragDropText
                  : `Drag and drop document here or`}
                <span className="text-success fw-600 fs-14">
                  {props.greenText ? props.greenText : " upload a file."}
                </span>
              </p>
              <input {...getInputProps()} name="file" />
              <i className="d-inline-block">
                <UploadFileIcon />
              </i>
              <p className="mb-0 text-muted break-word">
                <small>
                  {props.subText
                    ? props.subText
                    : "PDF, PPT, DOC, XLS, JPEG, PNG, or GIF - 100MB Maximum size"}
                </small>
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      {error ? (
        <Typography className="text-center text-danger" pt={2}>
          {error}
        </Typography>
      ) : null}
    </>
  );
};

export default FileUpload;
