import React from "react";

const EditIconSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_422_2159"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_422_2159)">
        <path
          d="M7.70801 17.083L10.292 14.5H17.917V17.083H7.70801ZM3.41701 15.75H4.50001L12.042 8.22901L10.938 7.12501L3.41701 14.667V15.75ZM14.854 7.31201L11.854 4.29201L12.812 3.35401C13.076 3.09001 13.3887 2.95801 13.75 2.95801C14.1113 2.95801 14.4307 3.09001 14.708 3.35401L15.812 4.45801C16.0627 4.72201 16.188 5.04501 16.188 5.42701C16.188 5.80901 16.056 6.12501 15.792 6.37501L14.854 7.31201ZM13.938 8.22901L5.08301 17.083H2.08301V14.083L10.938 5.22901L13.938 8.22901ZM11.5 7.68801L10.938 7.12501L12.042 8.22901L11.5 7.68801Z"
          fill="#15BE53"
        />
      </g>
    </svg>
  );
};

export default EditIconSVG;
