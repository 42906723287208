import { useState } from "react";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import AlertDialog from "../../common/AlertDialog";
import ChangeSecurityPIN from "./ChangeSecurityPIN";
import PaymentFailed from "../Dashboard/PaymentFailed";
import SupportEmailLink from "../../common/SupportEmailLink";
import { userSigInAction } from "../../../redux/slices/user/userSignInSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { userSettingsAction } from "../../../redux/slices/user/userSettingSlice";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import {
  clearLocalStoarge,
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../../helpers/utils";
import {
  DEFAULT_URL,
  ROLES,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../../helpers/constants";

import "../../../styles/dashboard.scss";

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const UserSettingIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // store data
  const {
    userProfileDetails,
    loggedInEmail,
    loginAsSpouse: isSecondaryUser,
    paymnetDoneDetails,
    isPaymentPending,
    paymentLoader,
  } = useSelector((state) => state?.userProfile);

  const { settingsLoader, marketingMailDetails } = useSelector(
    (state) => state?.userSettings
  );

  const [changePinModal, setChangePinModal] = useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);

  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };

  const onChecked = (e, checked) => {
    dispatch(
      userSettingsAction.getMarketingMailData({
        url: "customer/marketing-mails-status",
        data: { receive_marketing_mail: checked },
        token: true,
      })
    );
  };

  const handleSignOut = () => {
    dispatch(
      userProfileAction.logoutUser({ url: "logout", data: {}, token: true })
    );
    dispatch(userSigInAction.successLogout());
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("authorised");
    // localStorage.removeItem("param");
    // localStorage.removeItem("fixParams");
    // localStorage.removeItem("searched_Add");
    // localStorage.removeItem("searched_Add_popup");
    clearLocalStoarge();

    let isIos = false;
    if (JSON.parse(localStorage.getItem("isIos"))) {
      isIos = JSON.parse(localStorage.getItem("isIos"));
    }
    clearLocalStoarge();
    localStorage.setItem("ivokeNativeMethod", isIos);

    // logout native method call for mobile app
    if (
      window?.webkit &&
      window?.webkit?.messageHandlers &&
      window?.webkit?.messageHandlers?.invokeNativeLogout
    ) {
      console.log("Logout Native App got called...");
      window.webkit.messageHandlers.invokeNativeLogout.postMessage({});
    }

    navigate("/login");
  };
  const changePIN = () => {
    console.log("change pin");
    setChangePinModal(true);
  };

  // handle back to original user
  const handleLogoutFromSpouse = () => {
    dispatch(userProfileAction.logoutFromSpouse());
  };

  // for react pixel events
  useEffect(() => {
    if (loggedInEmail?.length > 0) {
      reactPixelEventOnlyForCustomer("Settings Page", { email: loggedInEmail });
    }
  }, [loggedInEmail]);

  useEffect(() => {
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  useEffect(() => {
    if (getObjLength(userProfileDetails) && userProfileDetails?.success) {
      if (userProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${DEFAULT_URL}login`;
      }
    }
  }, [userProfileDetails]);
  return (
    <>
      <Box className="profile-wrapper">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="h6" className="title">
            Settings
          </Typography>
          {/* {isSecondaryUser && (
            <Button
              variant="contained"
              className="signoutBtn d-none d-md-block"
              onClick={handleLogoutFromSpouse}
            >
              Back to {userProfileDetails?.data?.first_name}{" "}
              {userProfileDetails?.data?.last_name}
            </Button>
          )}
          {!isSecondaryUser && ( */}
          <Button
            variant="contained"
            className="signoutBtn d-none d-md-block"
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
          {/* )} */}
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={7.5} lg={8} xl={8}>
            {userProfileDetails?.data?.user_type?.includes(
              ROLES?.professional
            ) &&
            !userProfileDetails?.data?.role?.includes(
              ROLES?.customer
            ) ? null : (
              <Paper elevation={0} className="personal-info" sx={Paperstyles}>
                <Box
                  className="d-flex align-items-center justify-content-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6" className="card-title">
                    Marketing Mail
                  </Typography>
                </Box>
                <Box className="d-flex align-items-center justify-content-start">
                  <Typography>
                    Do you want to send/receive marketing mails?
                  </Typography>
                  <div className="px-3">
                    <CustomizedCheckbox
                      checked={userProfileDetails?.data?.receive_marketing_mail}
                      onChecked={(e) => onChecked(e, 1)}
                      disabled={isSecondaryUser}
                    />
                    <span>Yes</span>
                  </div>
                  <div>
                    <CustomizedCheckbox
                      checked={
                        !userProfileDetails?.data?.receive_marketing_mail
                      }
                      onChecked={(e) => onChecked(e, 0)}
                      disabled={isSecondaryUser}
                    />
                    <span>No</span>
                  </div>
                </Box>
              </Paper>
            )}

            <Paper elevation={0} className="personal-info" sx={Paperstyles}>
              <Box
                className="d-flex align-items-center justify-content-between"
                sx={{ mb: 3 }}
              >
                <Typography variant="h6" className="card-title">
                  Security PIN
                </Typography>
              </Box>
              <Box className="d-flex align-items-center justify-content-start">
                <Typography>
                  {userProfileDetails?.data?.security_pin &&
                  userProfileDetails?.data?.security_pin?.length > 0
                    ? "Forget your PIN? Reset it here."
                    : "Add your security PIN here."}
                </Typography>
                <div className="mx-3 other_popup_btn">
                  <Button
                    onClick={() => changePIN()}
                    disabled={isSecondaryUser}
                  >
                    {userProfileDetails?.data?.security_pin &&
                    userProfileDetails?.data?.security_pin?.length > 0
                      ? "Change PIN"
                      : "Add PIN"}
                  </Button>
                </div>
              </Box>
            </Paper>
          </Grid>
          <Grid className="" item xs={12} sm={12} md={4.5} lg={4} xl={4}>
            <Paper elevation={0} className="help-contact" sx={Paperstyles}>
              <Box
                className="d-flex align-items-center justify-content-between"
                sx={{ mb: 3 }}
              >
                <Typography variant="h6" className="card-title">
                  Help and contact
                </Typography>
              </Box>
              <List
                className="supportContact"
                sx={{ width: "100%", bgcolor: "background.paper" }}
              >
                <ListItem disablePadding className="align-items-start">
                  <ListItemText className="mb-0 label">Support:</ListItemText>
                  <ListItemText className="mb-0 content">
                    <SupportEmailLink
                      email={SUPPORT_EMAIL}
                      text={"Click here to contact us"}
                    />
                  </ListItemText>
                </ListItem>
                <ListItem disablePadding className="align-items-start">
                  <ListItemText className="mb-0 label">Email:</ListItemText>
                  <ListItemText className="mb-0 content">
                    <SupportEmailLink
                      email={SUPPORT_EMAIL}
                      text={SUPPORT_EMAIL}
                    />
                  </ListItemText>
                </ListItem>
                <ListItem disablePadding className="align-items-start">
                  <ListItemText className="mb-0 label">By phone:</ListItemText>
                  <ListItemText className="mb-0 content">
                    <div className="d-flex flex-column">
                      <Callto phone={`${SUPPORT_PHONE}`}>
                        {SUPPORT_PHONE_FORMAT}
                      </Callto>
                      <p className="mb-0">
                        MON-FRI: <span>8:00AM - 6:00PM</span>
                      </p>
                      <p className="mb-0">
                        SAT-SUN: <span>9:00AM - 5:00PM</span>
                      </p>
                    </div>
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, mb: 2 }}>
          {isSecondaryUser && (
            <Button
              variant="contained"
              className="signoutBtn d-block d-md-none w-100"
              sx={{
                padding: "6px 20px !important",
                fontSize: "12px !important",
              }}
              onClick={handleLogoutFromSpouse}
            >
              Back to {userProfileDetails?.data?.first_name}{" "}
              {userProfileDetails?.data?.last_name}
            </Button>
          )}
          {!isSecondaryUser && (
            <Button
              variant="contained"
              className="signoutBtn d-block d-md-none w-100"
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          )}
        </Box>
      </Box>

      {/* Marketing Mail Alerts  */}

      {getObjLength(marketingMailDetails) ? (
        <AlertDialog
          type="Success"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (marketingMailDetails?.success) {
              dispatch(userSettingsAction.fetchMarketingMailData({}));
              dispatch(
                userProfileAction.userProfileData({
                  url: "profile",
                  data: {},
                  token: true,
                })
              );
            } else {
              dispatch(userSettingsAction.fetchMarketingMailData({}));
            }
          }}
          mesage={
            marketingMailDetails?.success
              ? marketingMailDetails?.message
              : marketingMailDetails?.data?.message
          }
        />
      ) : null}

      {/* Security PIN popup */}
      {changePinModal && (
        <DialogBox
          width={"md"}
          contentClassName={"bg-white"}
          openPopup={changePinModal}
          setOpenPopup={setChangePinModal}
          onClose={() => setChangePinModal(false)}
          dividers={true}
          content={
            <ChangeSecurityPIN
              onClose={() => setChangePinModal(false)}
              titleText={
                userProfileDetails?.data?.security_pin &&
                userProfileDetails?.data?.security_pin?.length > 0
                  ? ""
                  : "Add Your Security PIN"
              }
            />
          }
        />
      )}
      {/* Paymnet failed Popup */}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed isPaymentDonePopup={isPaymentDonePopup}
          setIsPaymentDonePopup={setIsPaymentDonePopup} />
        )}
      <Loader open={settingsLoader || paymentLoader} />
    </>
  );
};

export default UserSettingIndex;
