import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Settings } from "@mui/icons-material";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import { PER_PAGE, ROLES } from "../helpers/constants";
import SettingsIconSVG from "../common/SettingsIconSVG";
import { decryptPayload, getObjLength } from "../helpers/utils";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";
import UploadRequestDocModal from "../admin/AdminDashboard/UploadRequestDocModal";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import CustomLinkWrapper from "../common/CustomLinkWrapper";
import TextTruncate from "../common/TextTruncate";

const columns = [
  {
    id: "number",
    disablePadding: true,
    label: "No.",
    value: "no",
    minWidth: 60,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
    value: "property",
    minWidth: 120,
  },
  {
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 60,
  },
  {
    id: "state",
    disablePadding: true,
    label: "State",
    value: "state",
    minWidth: 60,
  },
  {
    id: "zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 60,
  },
  {
    id: "doc_url",
    disablePadding: true,
    label: "Document",
    value: "document",
    minWidth: 60,
  },
  {
    id: "request_by",
    disablePadding: true,
    label: "Requested By",
    value: "request_by",
  },
  {
    id: "reuqest_type",
    disablePadding: true,
    label: "Request Type",
    value: "docs",
    minWidth: 60,
  },
  // {
  //   id: "agree",
  //   disablePadding: true,
  //   label: "Property Termination",
  //   value: "agree",
  //   minWidth: 60,
  // },
  {
    id: "action",
    disablePadding: true,
    label: "Action",
    value: "action",
    minWidth: 70,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const RequestedDocuments = () => {
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyTerminatePopup, setPropertyTerminatePopup] = useState(false);
  const [terminatedPopup, setTerminatedPopup] = useState(false);
  const [waiverPopup, setWaiverPopup] = useState(false);
  const [uploadDocPopup, setUplaodDocPopup] = useState(false);
  console.log("selectedProperty==>", selectedProperty);
  const dispatch = useDispatch();

  const {
    terminationDocLoader,
    terminatedDocRequestData,
    terminatedPropertyData,
    uploadedDocData,
  } = useSelector((state) => state?.documents);

  const {
    callDefaultAPIs,
    loginAsSpouse: isSecondaryUser,
    parent_id,
  } = useSelector((state) => state?.userProfile);

  const _DATA = terminatedDocRequestData?.data
    ? terminatedDocRequestData?.data
    : [];
  const count = Math.ceil(terminatedDocRequestData?.count / PER_PAGE);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const terminatedProperty = (action, url) => {
    console.log("selectedProperty ==>", selectedProperty);
    dispatch(
      documentsAction.getTerminatedPropertyData({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/request-action/${selectedProperty?.id}/${url}`,
        data: { action },
        type: url,
        action: action.charAt(0).toUpperCase() + action.slice(1),
        token: true,
      })
    );
    setPropertyTerminatePopup(false);
    setWaiverPopup(false);
  };

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  const handleCloseModal = () => {
    dispatch(documentsAction.fetchUploadedDocData({}));
    setUplaodDocPopup(false);
  };
  const handleUploadDocuments = (values) => {
    const uploadedDocs = values.upload_doc;

    const formData = new FormData();

    // formData.append("id", selectedProperty.id.toString());
    // uploadedDocs.map((doc, index) =>
    formData.append(`document`, uploadedDocs);
    // );
    // formData.append("property_id", selectedProperty?.property_id);

    // Display the values

    for (const value of formData.values()) {
      console.log(value);
    }

    dispatch(
      documentsAction.getUploadedDocData({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/upload-document/${selectedProperty?.id}/terminate`,
        data: formData,
        token: true,
        isFile: true,
      })
    );
  };

  // get Documnets details
  const getRequestedDocsLists = useCallback(() => {
    let URL = `${
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
        ? "service-provider"
        : "customer"
    }/document-request-list`;

    dispatch(
      documentsAction.getTerminatedDocRequestData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          user_id: isSecondaryUser ? parseInt(parent_id) : undefined,
        },
        token: true,
      })
    );
  }, [dispatch, isSecondaryUser, parent_id, payload?.offset]);

  useEffect(() => {
    if (callDefaultAPIs === 1) {
      getRequestedDocsLists();
    }
  }, [getRequestedDocsLists, callDefaultAPIs]);

  useEffect(() => {
    console.log("terminatedPropertyData==>", terminatedPropertyData);
    if (getObjLength(terminatedPropertyData)) {
      setTerminatedPopup(true);
    }
  }, [terminatedPropertyData]);

  return (
    <>
      {getObjLength(terminatedDocRequestData) ? (
        terminatedDocRequestData?.success ? (
          <>
            {/* mobile view */}
            <Box className="d-block d-lg-none mobile-table bg-white px-0 mb-3 py-3">
              {_DATA?.map((row, index) => {
                return (
                  <>
                    <Stack
                      direction="column"
                      spacing={1.2}
                      px={2}
                      className="position-relative"
                    >
                      <Stack
                        direction="column"
                        spacing={1.2}
                        pr={2}
                        justifyContent="center"
                        className="position-relative"
                      >
                        <Tooltip
                          title="View Subscription History"
                          TransitionComponent={Zoom}
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleMenuClick(event);
                              setSelectedProperty(row);
                            }}
                            size="small"
                            sx={{
                              color: "#15BE53",
                              position: "absolute",
                              right: "0px",
                            }}
                            className="settings-button"
                          >
                            <Settings />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          className="value fw-500"
                          sx={{ fontSize: "15px !important" }}
                        >
                          {row?.property?.address || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">City: </Typography>
                        <Typography className="value">
                          {row?.property?.city || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">State: </Typography>
                        <Typography className="value">
                          {row?.property?.state || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Zip: </Typography>
                        <Typography className="value">
                          {row?.property?.zip || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Document: </Typography>
                        <Typography className="value">
                          {row?.doc_url ? (
                            <CustomLinkWrapper
                              link={row?.doc_url}
                              title={
                                <TextTruncate
                                  text={
                                    row?.doc_url?.split("/")?.[
                                      row?.doc_url?.split("/")?.length - 1
                                    ] || "-"
                                  }
                                  maxLength={30}
                                />
                              }
                              classes="text-primary value"
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key"> Requested By:</Typography>
                        <Typography className="value">
                          {row?.user?.email || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key"> Request Type:</Typography>
                        <Typography className="value text-capitalize">
                          {row?.request_action || "-"}
                        </Typography>
                      </Stack>
                    </Stack>

                    {index < _DATA?.length - 1 && (
                      <Divider
                        className="my-2 mt-3"
                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                      />
                    )}
                  </>
                );
              })}

              {!terminatedDocRequestData?.data?.length > 0 &&
                !terminationDocLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found
                  </Typography>
                )}
            </Box>

            {/* desktop view */}
            <Box sx={{ width: "100%" }} className="d-none d-lg-block">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {_DATA?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.id}
                            style={{ mineight: "20px" }}
                          >
                            <TableCell align="left">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.address || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.city || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.state || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.zip || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.doc_url ? (
                                <CustomLinkWrapper
                                  link={row?.doc_url}
                                  title={
                                    <TextTruncate
                                      text={
                                        row?.doc_url?.split("/")?.[
                                          row?.doc_url?.split("/")?.length - 1
                                        ] || "-"
                                      }
                                      maxLength={30}
                                    />
                                  }
                                  classes="text-primary value"
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row?.user?.email || "-"}
                            </TableCell>

                            <TableCell align="left" className="text-capitalize">
                              {row?.request_action || "-"}
                            </TableCell>

                            {/* <TableCell align="left">
                        <Tooltip
                          title={"Terminate this property."}
                          TransitionComponent={Zoom}
                          placement="top"
                          arrow
                        >
                          <Button
                            className="text-white error_popup_btn"
                            onClick={() => {
                              setSelectedProperty(row);
                              setPropertyTerminatePopup(true);
                            }}
                          >
                            Terminate
                          </Button>
                        </Tooltip>
                      </TableCell> */}
                            <TableCell>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMenuClick(event);
                                  setSelectedProperty(row);
                                }}
                                size="small"
                                className="settings-button"
                                aria-controls="settings-menu"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <SettingsIconSVG />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!terminatedDocRequestData?.data?.length > 0 &&
                    !terminationDocLoader && (
                      <Typography className="p-5 text-center" variant="h6">
                        No Data Found
                      </Typography>
                    )}
                </TableContainer>
              </Paper>
              {terminatedDocRequestData?.count > PER_PAGE && (
                <Box id="pagination-container">
                  <CustomPagination
                    count={count}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Typography className="w-100 p-5 text-center text-danger bg-white fs-6">
            {terminatedDocRequestData?.data?.message || "Something went wrong!"}
          </Typography>
        )
      ) : null}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {/* <MenuItem
          className="setting-menu-item"
          onClick={() => uploadedPropertyDocuments()}
        >
          <Box className="setting-menu-box">
            <UploadFileOutlinedIcon />
            <Typography className="setting-menu-text">
              Upload Document
            </Typography>
          </Box>
        </MenuItem> */}
        {selectedProperty?.request_action === "termination request" && (
          <MenuItem
            className="setting-menu-item"
            onClick={() => setPropertyTerminatePopup(true)}
          >
            <Box className="setting-menu-box">
              {/* <Box className="setting-menu-box"> */}
              <RemoveCircleOutlineOutlinedIcon />
              <Typography className="setting-menu-text">
                Terminate Property
              </Typography>
            </Box>
          </MenuItem>
        )}
        {selectedProperty?.request_action === "waiver request" && (
          <MenuItem
            className="setting-menu-item"
            onClick={() => setWaiverPopup(true)}
          >
            <Box className="setting-menu-box">
              {/* <Box className="setting-menu-box"> */}
              <FlagOutlinedIcon />
              <Typography className="setting-menu-text">
                Waiver Requested
              </Typography>
            </Box>
          </MenuItem>
        )}
        {/* {selectedProperty?.uploaded_doc && (
          <MenuItem
            className="setting-menu-item"
            onClick={() => setPropertyTerminatePopup(true)}
          >
            <Box className="setting-menu-box">
              <SimCardDownloadOutlinedIcon />
              <Typography className="setting-menu-text">
                Download Document
              </Typography>
            </Box>
          </MenuItem>
        )} */}
      </Menu>
      {/* Uplaod Document popup */}
      <UploadRequestDocModal
        from="TerminationDocument"
        fileFormates={{
          "image/png": [".png", ".jpeg", ".jpg"],
          "image/gif": [".gif"],
          "application/vnd.ms-powerpoint": [".ppt"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/csv": [".csv"],
        }}
        multiple={false}
        open={uploadDocPopup}
        handleClose={() => {
          handleCloseModal();
        }}
        caption={
          "Please upload Property Termination document for this property."
        }
        isUploadSuccess={uploadedDocData?.success}
        uploadedDocData={uploadedDocData}
        successMessage={uploadedDocData?.message}
        errorMessage={uploadedDocData?.data?.message}
        uploadLoader={terminationDocLoader}
        handleUploadDoc={handleUploadDocuments}
        fetchPropertyListingData={() => {
          getRequestedDocsLists();
        }}
      />

      {/* Property waiver Waring popup */}
      {waiverPopup && (
        <AlertDialog
          openPopup={waiverPopup}
          type={"Warning"}
          onClose={true}
          onCloseFun={() => {
            setWaiverPopup(false);
          }}
          buttons={
            <>
              <div className="text-center stepBtn mt-4 ">
                <Button
                  onClick={() => terminatedProperty("reject", "waiver")}
                  className="popup_Btn other_popup_btn"
                  variant="contained"
                >
                  No,Reject
                </Button>
                <Button
                  onClick={() => terminatedProperty("accept", "waiver")}
                  className="popup_Btn success_popup_btn mx-3"
                  variant="contained"
                >
                  Yes,Sure
                </Button>
              </div>
            </>
          }
          mesage={
            "Are you sure you want to accept a waiver request for this property?"
          }
        />
      )}

      {/* Property Termination Waring popup */}
      {propertyTerminatePopup && (
        <AlertDialog
          openPopup={propertyTerminatePopup}
          type={"Warning"}
          onClose={true}
          onCloseFun={() => {
            setPropertyTerminatePopup(false);
          }}
          buttons={
            <>
              <div className="text-center stepBtn mt-4 ">
                <Button
                  onClick={() => terminatedProperty("reject", "terminate")}
                  className="popup_Btn other_popup_btn"
                  variant="contained"
                >
                  No,Reject
                </Button>
                <Button
                  onClick={() => terminatedProperty("accept", "terminate")}
                  className="popup_Btn success_popup_btn mx-3"
                  variant="contained"
                >
                  Yes,Terminate
                </Button>
              </div>
            </>
          }
          mesage={"Are you sure you want to terminate this property?"}
        />
      )}

      {/* Property Termination validation */}
      {terminatedPopup && getObjLength(terminatedPropertyData) && (
        <AlertDialog
          type={terminatedPropertyData?.success ? "Success" : "Error"}
          openPopup={terminatedPopup}
          onClose={true}
          onCloseFun={() => {
            setTerminatedPopup(false);
            dispatch(documentsAction.fetchTerminatedPropertyData({}));
            if (terminatedPropertyData?.success) {
              getRequestedDocsLists();
            }
          }}
          mesage={
            terminatedPropertyData?.success
              ? terminatedPropertyData?.message
              : terminatedPropertyData?.data?.message
          }
          footer={false}
        />
      )}
      <Loader open={terminationDocLoader} />
    </>
  );
};

export default RequestedDocuments;
