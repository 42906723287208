import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import { getUrlLink } from "../../helpers/utils";

const PurcaseTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.properties?.userViewDetailsPropertyData
  );

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={4} mb={2}>
            <Row>
              <Col className="label-text">
                <Typography>Recording Date: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Recording Date"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Type of Sale: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Deed Type"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Doc #: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {getUrlLink(
                    propertyData?.property_details?.SalesHistory?.[0]?.[
                      "Doc #"
                    ],
                    propertyData?.property_details?.SalesHistory?.[0]?.[
                      "Doc_link"
                    ]
                  )}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Title Co: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Title Co"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Mortgage Doc #: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Mortgage Doc #"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Buyer Name(s): </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Buyer / Seller"
                  ].split("/")[0] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Seller Name(s): </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Buyer / Seller"
                  ].split("/")[1] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Transaction Flag's: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Transaction Flag's"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} mb={2}>
            <Row>
              <Col className="label-text">
                <Typography>Transfer Amount: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Transfer Amount"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>First TD: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "First TD"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Doc Type: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Doc Type"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Rate Type: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.SalesHistory?.[0]?.[
                    "Rate Type"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Lender: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.FinanceHistory?.[0]?.[
                    "Lender"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Buyer Vesting: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.["Vesting"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PurcaseTab;
