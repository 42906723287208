import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTheme, useMediaQuery } from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";

import PropertySearchType from "./PropertySearchType";
import Aurthorised from "../../../assets/price_F&Q/aurthorize.png";
import SecurityInfoAndCardPayment from "./SecurityInfoAndCardPayment";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

import "../../../styles/properties.scss";

const AddPropertyPopup = ({
  getPropertyList,
  classes,
  showBtn = true,
  initialyAddPropertyModal = false,
  handleCloseModal = () => {},
  isByPassOptionAvailable = false,
  from = "",
  isUserCreateProperty = false,
  setIsMapDisplay = () => {},
  isMapDisplay,
  callListingAPI = true,
}) => {
  const dispatch = useDispatch();
  const dialogContentRef = useRef(null);

  // states
  const [addPropertyModal, setAddPropertyModal] = useState(
    initialyAddPropertyModal
  );
  const [screen, setScreen] = useState("security");
  const [selectedData, setSelectedData] = useState({});
  const [skipVerification, setSkipVerification] = useState(false);
  const theme = useTheme();
  const isPadding = useMediaQuery(theme.breakpoints.down(884));
  const { propertyQuestion, getPropertyData, selectedProperty } = useSelector(
    (state) => state.properties
  );
  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const { servicerProfessionalDetails } = useSelector(
    (state) => state?.servicerProfile
  );

  const handleOpenAddPropertyModal = () => {
    setScreen("security");
    dispatch(propertiesAction.clearPropertiesList());
    setAddPropertyModal(true);
    setIsMapDisplay(false);
    setSkipVerification(false);
  };
  // handle close property modal
  const handleCloseAddPropertyModal = (type) => {
    if (type === "error") {
      dispatch(propertiesAction.fethPayment());
    } else {
      dispatch(userSigupAction.fetchTotalPaymentDetails({}));
      dispatch(propertiesAction.clearPropertiesList());
      dispatch(propertiesAction.generateQuestionsInputParams({}));

      setAddPropertyModal(false);
      setIsMapDisplay(true);

      setScreen("security");
      setSkipVerification(false);
      handleCloseModal();
    }
  };
  useEffect(() => {
    localStorage.removeItem("promoCode");
    localStorage.removeItem("referralCode");
  }, []);

  useEffect(() => {
    console.log("propertyQuestion  ==>", propertyQuestion);
    if (propertyQuestion?.success && propertyQuestion?.data?.is_verified) {
      console.log(
        selectedProperty,
        "subscription screen called getPropertyData, 2",
        getPropertyData
      );
      if (Object.keys(selectedProperty).length > 0) {
        let propertyData = selectedProperty?.json_response;
        setSelectedData({
          property_id: selectedProperty?.property_id,
          state_code: propertyData.mm_fips_state_code,
          user_id:
            userData?.user_id || servicerProfessionalDetails?.data?.user_id,
        });

        console.log("answers object==>", selectedData);
      }
      setScreen("subscriptions");
    } else {
      setSelectedData({});
    }

    if (
      propertyQuestion?.data?.questions?.length > 0 &&
      propertyQuestion?.success &&
      !propertyQuestion?.data?.is_verified
    ) {
      console.log("security screen called");
      setScreen("questions");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyQuestion]);

  useEffect(() => {
    // Scroll the dialog content to the top whenever the screen changes
    if (dialogContentRef?.current) {
      dialogContentRef.current.scrollTop = 0;
    }
  }, [screen]);

  return (
    <>
      {showBtn && (
        <Button
          variant="contained"
          className={classes}
          onClick={(e) => {
            e.preventDefault();
            handleOpenAddPropertyModal();
          }}
        >
          Add property
        </Button>
      )}

      <Dialog
        open={addPropertyModal}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseAddPropertyModal}
        className="add-propery-modal zoom-modal-wrapper"
      >
        {/* {screen === "cardsInfo" && (
          <Typography
            align="center"
            variant="subtitle1"
            className="formHeader"
            color={"white"}
            // sx={{position:"absolute"}}
          >
            <LockIcon sx={{ fontSize: 12 }} className="me-1" />
            Secure connection
          </Typography>
        )} */}
        <DialogContent
          className="bg-white"
          dividers={true}
          sx={{ px: "2 !important", position: "relative" }}
          ref={dialogContentRef}
        >
          {/* <p className="close-text">Close</p> */}
          {/* <Typography
            align="center"
            variant="subtitle1"
            className="formHeader zoom-container"
            color={"white"}
            sx={{maxWidth:"880px"}}
            // sx={{position:"absolute"}}
          >
            <LockIcon sx={{ fontSize: 12 }} className="me-1" />
            Secure connection
          </Typography> */}

          <Button
            onClick={handleCloseAddPropertyModal}
            className="modal-close-btn close-text hide-outer-close"
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              background: "transparent",
              textTransform: "none",
            }}
          >
            Close
            <CloseIcon
              className="hide-outer-close"
              sx={{ fontSize: "2.5rem", color: "#15be53" }}
            />
          </Button>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
            className="zoom-container"
            sx={isPadding && { paddingBottom: "110px" }}
          >
            {screen === "security" ? (
              <PropertySearchType
                onBtnClick={(e) => {
                  // e.preventDefault();
                  console.log("submitted");
                }}
                handleCloseAddPropertyModal={handleCloseAddPropertyModal}
                isByPassOptionAvailable={isByPassOptionAvailable}
                onChangeModalScreen={() => setScreen("questions")}
                onCloseModalHandler={() => {
                  if (!isByPassOptionAvailable) {
                    getPropertyList();
                  }
                  setScreen("subscriptions");
                  // handleCloseAddPropertyModal();
                }}
              />
            ) : (
              <SecurityInfoAndCardPayment
                screen={screen}
                onChangeModalScreen={setScreen}
                backToSearchProperty={setScreen}
                handleCloseAddPropertyModal={handleCloseAddPropertyModal}
                from={from}
                onCloseModalHandler={(type) => {
                  if (
                    !isByPassOptionAvailable &&
                    (type === "success" || callListingAPI)
                  ) {
                    getPropertyList();
                  }
                  handleCloseAddPropertyModal(type);
                }}
                isByPassOptionAvailable={isByPassOptionAvailable}
                selectedData={selectedData}
                addPromoCode={true}
                skipVerification={skipVerification}
                setSkipVerification={setSkipVerification}
                isUserCreateProperty={isUserCreateProperty}
              />
            )}
          </DialogContentText>
        </DialogContent>
        {screen === "cardsInfo" && (
          <DialogActions sx={{ paddingBottom: "50px" }}>
            <div className="text-center mt-3 mb-0 w-100">
              <img src={Aurthorised} alt="Aurthorised" />
            </div>
          </DialogActions>
        )}
      </Dialog>
      {/* {openAlertModal && !cardDetails?.data?.length > 0 && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={openAlertModal}
            onClose={true}
            onCloseFun={() => {
              setOpenAlertModal(false);
            }}
            buttons={
              <>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  sx={{
                    backgroundColor: "#ffffff !important",
                    color: "black !important",
                    border: "1px #9cb8a6 solid !important",
                  }}
                  onClick={() => {
                    setOpenAlertModal(false);
                  }}
                >
                  ok, close
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => {
                    setOpenAlertModal(false);
                    if (
                      userData?.user_type?.includes(ROLES?.professional) &&
                      !userData?.role?.includes(ROLES?.customer)
                    ) {
                      navigate("/professional-profile", {
                        replace: true,
                        state: { isAddPaymentModal: true },
                      });
                    } else {
                      navigate("/user-profile", {
                        replace: true,
                        state: { isAddPaymentModal: true },
                      });
                    }
                  }}
                >
                  ok, let's go
                </Button>
              </>
            }
            mesage={
              "You don't have any cards added. Please add the card first from the user profile page."
            }
            footer={false}
          />
        </>
      )} */}
    </>
  );
};

export default AddPropertyPopup;
