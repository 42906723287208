import React from "react";

const SellIconSVG = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9 19.05C11.6167 19.3333 11.2627 19.475 10.838 19.475C10.4127 19.475 10.0583 19.3333 9.775 19.05L0.975 10.25C0.825 10.1 0.708333 9.93333 0.625 9.75C0.541667 9.56667 0.5 9.36667 0.5 9.15V2C0.5 1.58333 0.646 1.22933 0.938 0.938C1.22933 0.646 1.58333 0.5 2 0.5H9.15C9.35 0.5 9.546 0.541667 9.738 0.625C9.92933 0.708333 10.0917 0.816667 10.225 0.95L19.025 9.75C19.325 10.05 19.475 10.4123 19.475 10.837C19.475 11.2623 19.3333 11.6167 19.05 11.9L11.9 19.05ZM10.825 18L17.975 10.85L9.15 2H2V9.15L10.825 18ZM4.5 5.75C4.85 5.75 5.146 5.629 5.388 5.387C5.62933 5.14567 5.75 4.85 5.75 4.5C5.75 4.15 5.62933 3.854 5.388 3.612C5.146 3.37067 4.85 3.25 4.5 3.25C4.15 3.25 3.85433 3.37067 3.613 3.612C3.371 3.854 3.25 4.15 3.25 4.5C3.25 4.85 3.371 5.14567 3.613 5.387C3.85433 5.629 4.15 5.75 4.5 5.75Z"
          fill="#757575"
        />
      </svg>
    </>
  );
};

export default SellIconSVG;
