import React, { useState } from "react";
import { userProfileAction } from "../../../../redux/slices/user/userProfileSlice";
import DialogBox from "../cardPopup";
import StannupEmailVerification from "./stannupEmailVerification";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const VerifyQRCode = ({ openPopup, addressId }) => {
  const [stannupPopup, setStannupPop] = useState(openPopup);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeStanupPopup = () => {
    dispatch(userProfileAction.clearPostcardData({}));
    localStorage.removeItem("addressId");
    // navigate(localStorage.getItem("path")); // for manage the user profile action
    // setStannupPop(false);
  };
  console.log(openPopup, "<==openPopup, addressId==>", addressId);
  return (
    <>
      {stannupPopup && (
        <DialogBox
          width="md"
          openPopup={stannupPopup}
          onClose={false}
          mesage={
            <StannupEmailVerification
              row={addressId}
              setStannupPop={setStannupPop}
              from="QR"
              onClose={closeStanupPopup}
            />
          }
        />
      )}
    </>
  );
};

export default VerifyQRCode;
