import { useEffect } from "react";
import { useCallback } from "react";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Search, Settings } from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../common/loader";
import EditDocument from "./editDocument";
import AlertDialog from "../common/AlertDialog";
import TextTruncate from "../common/TextTruncate";
import { PER_PAGE, ROLES } from "../helpers/constants";
import EditIconSVG from "../common/customSVGs/EditIconSVG";
import CustomLinkWrapper from "../common/CustomLinkWrapper";
import DeleteIconSVG from "../common/customSVGs/DeleteIconSVG";
import SettingsIconSVG from "../common/customSVGs/SettingsIconSVG";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../admin/AddLeads";
import {
  decryptPayload,
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
  getObjLength,
} from "../helpers/utils";

import "./propertyTable.scss";

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {props?.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function DocumentsListsTable({ columns, openUploadDocPopup }) {
  const [order, setOrder] = useState("asc");
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [editPopup, setEditPopup] = useState(false);
  const [docName, setDocName] = useState("");
  const [docId, setDocId] = useState("");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [tags, setTags] = useState([]);
  const [term, setTerm] = useState("");
  const [allTypes, setAllTypes] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  // const [date, setDate] = useState({});
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [callApi, setCallApi] = useState(false);

  // store data
  const {
    editDocData,
    deleteDocData,
    deleteDocLoader,
    documentsData,
    documentsDataCount,
    uploadDocData,
  } = useSelector((state) => state?.documents);
  const {
    loginAsSpouse: isSecondaryUser,
    parent_id,
  } = useSelector((state) => state?.userProfile);

  const open = Boolean(anchorEl);

  const _DATA = documentsData?.data;
  const count = Math.ceil(documentsDataCount / PER_PAGE);
  // const count = Math.ceil(documentsData?.data?.length / PER_PAGE);

  const getUrl = useCallback(() => {
    let url;
    if (isSecondaryUser) {
      url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "customer"
      }/documents/${parent_id}`;
    } else {
      url = `${
        decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES.serviceProvider
        )
          ? "service-provider"
          : "customer"
      }/documents`;
    }
    return url;
  }, [isSecondaryUser, parent_id]);

  // get url with pagination and filter if(filter or pagination applied)
  const getListingURL = (page = 1) => {
    let url;
    if (term || selectedDateRange) {
      const type = allTypes ? "&type=" + allTypes : "";
      const value = term ? "&value=" + encodeURIComponent(term) : "";
      const selectedDateRangePayload = selectedDateRange
        ? `&from_date=${
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate)) ||
            ""
          } &to_date=${
            getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate)) || ""
          }`
        : "";

      url = `${getUrl()}?limit=${PER_PAGE}&offset=${
        (page - 1) * PER_PAGE
      }${type}${value}${selectedDateRangePayload}`;
    } else {
      url = `${getUrl()}?limit=${PER_PAGE}&offset=${(page - 1) * PER_PAGE}`;
    }
    return url;
  };

  const handlePageChange = (e, p) => {
    setPage(p);

    dispatch(
      documentsAction.getDocumentsData({
        url: getListingURL(p),
        data: {},
        token: true,
      })
    );
  };

  const handleMenuClick = (event, data) => {
    console.log("selected row==>", data);
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const closeDialogBox = () => {
    setEditPopup(false);
  };
  const handleMenuItemClick = (event) => {
    console.log("selcted option==>.", event);

    if (event === "edit") {
      setDocName(selectedRow.filename);
      setTags(selectedRow.tags);
      setDocId(selectedRow.id);
      setEditPopup(true);
    }

    if (event === "delete") {
      setDeleteAlert(true);
    }
  };

  const deleteDocument = () => {
    // setDeleteAlert(false);
    dispatch(
      documentsAction.deleteDocument({
        url:
          `${
            decryptPayload(localStorage.getItem("roles"))?.includes(
              ROLES.serviceProvider
            )
              ? "service-provider"
              : "customer"
          }/documents/` +
          selectedRow.id +
          "/delete",
        data: {},
        token: true,
      })
    );
  };

  const filterOptions = (values, { resetForm }) => {
    if (term || selectedDateRange) {
      setCallApi(true);
      dispatch(
        documentsAction.getDocumentsData({
          url: getListingURL(1),
          data: {},
          token: true,
        })
      );
    }
  };

  const resetData = (resetForm) => {
    resetForm({ values: "" });
    resetStates();
    if (callApi) {
      dispatch(
        documentsAction.getDocumentsData({
          url: `${getUrl()}?limit=${PER_PAGE}&offset=${0}`,
          data: {},
          token: true,
        })
      );
      setPage(1);
    }
    setCallApi(false);
  };

  const handleFilterTypeChange = (value) => {
    console.log("select filter", value);
    setAllTypes(value);
  };

  const resetStates = () => {
    setSelectedDateRange(null);
    setAllTypes("all");
    setTerm("");
    setCallApi(false);
  };

  useEffect(() => {
    dispatch(
      documentsAction.getDocumentsData({
        url: `${getUrl()}?limit=${PER_PAGE}&offset=${0}`,
        data: {},
        token: true,
      })
    );
  }, [dispatch, getUrl]);

  return (
    <>
      <Box
        id="filter"
        onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
      >
        <h3 className="filter-text">Filter your results</h3>
        <ArrowDropDownIcon
          className="filter-arrow"
          sx={{ ms: 1, fontSize: "20px" }}
        />
      </Box>
      {/* Filtering Options */}
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Formik
              enableReinitialize
              initialValues={{
                type: "all" || allTypes,
                value: "" || term,
                date: "",
              }}
              onSubmit={filterOptions}
            >
              {({ values, setFieldValue, resetForm }) => {
                return (
                  <Form>
                    <Box className="d-block d-md-none">
                      <Grid
                        container
                        className="bg-white d-flex justify-content-around align-items-center px-4"
                        spacing={2}
                        pb={2}
                        pt={2}
                      >
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <Typography className="search-text">
                            Search By
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="All Types"
                              name="type"
                              onChange={(event) => {
                                setFieldValue("type", event.target.value);
                                handleFilterTypeChange(event.target.value);
                              }}
                              value={values?.type}
                              // onChange={handleFilterTypeChange}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              {columns.map(
                                (cell) =>
                                  cell.label !== "No." &&
                                  cell.label !== "Action" &&
                                  cell.id !== "address" && (
                                    <MenuItem key={cell.id} value={cell.id}>
                                      {cell.label}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          </FormControl>
                        </Grid>
                        {allTypes !== "created_at" && (
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormControl
                              className="filter-search w-100"
                              variant="outlined"
                            >
                              <InputLabel>Enter search term</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Search />
                                  </InputAdornment>
                                }
                                label="Enter search term"
                                name="value"
                                onChange={(event) => {
                                  setFieldValue("value", event.target.value);
                                  setTerm(event.target.value);
                                }}
                                value={values?.value}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {allTypes === "created_at" && (
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormControl className="filter-search w-100">
                              <CustomDateRangePicker
                                rangeValue={selectedDateRange}
                                onChange={(values) => {
                                  setSelectedDateRange(values);
                                  setFieldValue("date", {
                                    fromdate: `${getDateInYYYYMMDDFormat(
                                      new Date(values?.startDate)
                                    )}`,
                                    todate: `${getDateInYYYYMMDDFormat(
                                      new Date(values?.endDate)
                                    )}`,
                                  });
                                }}
                                onClear={() => {
                                  setSelectedDateRange(null);
                                  setFieldValue("date", {});
                                }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="w-100">
                            <Button type="submit" sx={mobileFilterResultStyle}>
                              Filter Results
                            </Button>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Button
                            type="reset"
                            onClick={() => {
                              resetData(resetForm);
                            }}
                            sx={mobileFilterResetBtnStyle}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>

            {/* for desktop view */}
            <Card id="filter-options-card" className="d-none d-md-block">
              <Formik
                enableReinitialize
                initialValues={{
                  type: "all" || allTypes,
                  value: "" || term,
                  date: "",
                }}
                onSubmit={filterOptions}
              >
                {({ values, setFieldValue, resetForm }) => {
                  return (
                    <Form>
                      <Box className="filter-options-box">
                        <div className="search-text">Search By</div>
                        <FormControl className="filter-select-one">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="All Types"
                            name="type"
                            onChange={(event) => {
                              setFieldValue("type", event.target.value);
                              handleFilterTypeChange(event.target.value);
                            }}
                            value={values?.type}
                            // onChange={handleFilterTypeChange}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {columns.map(
                              (cell) =>
                                cell.label !== "No." &&
                                cell.label !== "Action" &&
                                cell.id !== "address" && (
                                  <MenuItem key={cell.id} value={cell.id}>
                                    {cell.label}
                                  </MenuItem>
                                )
                            )}
                          </TextField>
                        </FormControl>
                        {allTypes !== "created_at" && (
                          <FormControl
                            className="filter-search"
                            variant="outlined"
                          >
                            <InputLabel>Enter search term</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              }
                              label="Enter search term"
                              name="value"
                              onChange={(event) => {
                                setFieldValue("value", event.target.value);
                                setTerm(event.target.value);
                              }}
                              value={values?.value}
                            />
                          </FormControl>
                        )}
                        {allTypes === "created_at" && (
                          <FormControl className="filter-search">
                            <CustomDateRangePicker
                              rangeValue={selectedDateRange}
                              onChange={(values) => {
                                setSelectedDateRange(values);
                                setFieldValue("date", {
                                  fromdate: `${getDateInYYYYMMDDFormat(
                                    new Date(values?.startDate)
                                  )}`,
                                  todate: `${getDateInYYYYMMDDFormat(
                                    new Date(values?.endDate)
                                  )}`,
                                });
                              }}
                              onClear={() => {
                                setSelectedDateRange(null);
                                setFieldValue("date", {});
                              }}
                            />
                          </FormControl>
                        )}
                        <FormControl>
                          <Button type="submit" className="filter-button ">
                            Filter Results
                          </Button>
                        </FormControl>
                        <Button
                          type="reset"
                          onClick={() => {
                            resetData(resetForm);
                          }}
                          className="reset-filters"
                        >
                          Reset
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </>
        )}
      </Box>
      {/* Property Listing Table */}
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 py-2">
        {_DATA?.map((row, index) => {
          return (
            <>
              <Stack
                direction="column"
                spacing={1.2}
                px={2}
                className="position-relative"
              >
                <Tooltip
                  title="View Subscription History"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={(e) => {
                      if (isSecondaryUser || row?.user_id === "0") {
                        return;
                      } else {
                        handleMenuClick(e, row);
                      }
                    }}
                    color={`${isSecondaryUser ? "gray" : ""}`}
                    size="small"
                    sx={{
                      color: `${isSecondaryUser ? "gray" : "#15BE53"}`,
                      position: "absolute",
                      right: "10px",
                    }}
                    className={`${
                      isSecondaryUser || row?.user_id === "0"
                        ? " cursor-disable settings-button"
                        : "settings-button"
                    }`}
                  >
                    <Settings />
                  </IconButton>
                </Tooltip>
                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Address:</Typography>
                  <Typography className="value">
                    {" "}
                    {row?.property?.address || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Document:</Typography>
                  <CustomLinkWrapper
                    link={
                      row?.path.includes(row?.filename)
                        ? row?.path
                        : row?.path + row?.filename
                    }
                    title={<TextTruncate text={row?.filename} maxLength={30} />}
                    classes="text-primary value"
                  />
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key">Tags:</Typography>
                  <Typography className="value">
                    {!row?.tags ||
                    row?.tags === "null" ||
                    JSON.parse(row?.tags)?.length === 0
                      ? "-"
                      : JSON.parse(row?.tags)?.map((i) => (
                          <Chip
                            sx={{
                              backgroundColor: "#C4D9CC",
                              height: "28px",
                              m: 0.5,
                            }}
                            className="fw-500 text-uppercase px-1"
                            label={i}
                          />
                        ))}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Uploaded At:</Typography>
                  <Typography className="value">
                    {row.created_at
                      ? getLocalFormatedDate(row.created_at)
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              {index < _DATA?.length - 1 && (
                <Divider
                  className="my-2 mt-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {getObjLength(documentsData) && !_DATA?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      {getObjLength(documentsData) && (
        <>
          <Box sx={{ width: "100%" }} className="d-none d-sm-block">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={documentsData?.data?.length}
                    columns={columns}
                  />
                  <TableBody>
                    {_DATA?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {row?.property?.address || "-"}
                          </TableCell>
                          <TableCell align="left">
                            <CustomLinkWrapper
                              link={
                                row?.path.includes(row?.filename)
                                  ? row?.path
                                  : row?.path + row?.filename
                              }
                              title={row?.filename}
                              classes={"preview-url"}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {!row?.tags ||
                            row?.tags === "null" ||
                            JSON.parse(row?.tags)?.length === 0
                              ? "-"
                              : JSON.parse(row?.tags)?.map((i) => (
                                  <Chip
                                    sx={{
                                      backgroundColor: "#C4D9CC",
                                      margin: 1,
                                    }}
                                    className="fw-500 text-uppercase"
                                    label={i}
                                  />
                                ))}
                          </TableCell>
                          <TableCell align="left" width={130}>
                            {row.created_at
                              ? getLocalFormatedDate(row.created_at)
                              : "-"}
                          </TableCell>
                          <TableCell sx={{ width: 180 }} align="left">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  if (isSecondaryUser || row?.user_id === "0") {
                                    return;
                                  } else {
                                    handleMenuClick(e, row);
                                  }
                                }}
                                size="small"
                                className={`${
                                  isSecondaryUser || row?.user_id === "0"
                                    ? " cursor-disable settings-button"
                                    : "settings-button"
                                }`}
                                aria-controls={
                                  open ? "settings-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                // disabled={isSecondaryUser || row?.user_id === "0"}
                              >
                                <SettingsIconSVG
                                  color={`${isSecondaryUser ? "gray" : ""}`}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!documentsData?.data?.length > 0 && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
          </Box>
          {documentsDataCount > PER_PAGE && (
            <Box id="pagination-container">
              <CustomPagination
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Box>
          )}
        </>
      )}
      {/* table options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => handleMenuItemClick("edit")}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <EditIconSVG />
            <Typography className="setting-menu-text">Edit</Typography>
          </Box>
        </MenuItem>
        {/* <MenuItem
          key="move"
          onClick={() => handleMenuItemClick("move")}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <MoveIconSVG />
            <Typography className="setting-menu-text">Move</Typography>
          </Box>
        </MenuItem> */}
        <MenuItem
          key="delete"
          onClick={() => handleMenuItemClick("delete")}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <DeleteIconSVG />
            <Typography className="setting-menu-text">Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>

      {/* edit alert */}
      {getObjLength(editDocData) ? (
        <>
          {editDocData?.success ? (
            <AlertDialog
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                dispatch(documentsAction.clearData({}));
                dispatch(
                  documentsAction.getDocumentsData({
                    url: getListingURL(page),
                    data: {},
                    token: true,
                  })
                );
              }}
              mesage={editDocData?.message}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                dispatch(documentsAction.clearData({}));
              }}
              mesage={editDocData?.data?.message}
            />
          )}
        </>
      ) : null}

      {/* Delete confirmation Popup */}
      {deleteAlert && (
        <AlertDialog
          type="Warning"
          openPopup={deleteAlert}
          onClose={true}
          onCloseFun={() => {
            setDeleteAlert(false);
          }}
          mesage={" Are you sure, you want to delete this Document?"}
          loader={deleteDocLoader}
          buttons={
            <>
              <Button
                onClick={() => setDeleteAlert(false)}
                className="popup_Btn other_popup_btn mx-2"
              >
                No
              </Button>
              <Button
                onClick={deleteDocument}
                className="popup_Btn success_popup_btn mx-2"
              >
                Yes
              </Button>
            </>
          }
        />
      )}

      {/* delete alert */}
      {getObjLength(deleteDocData) ? (
        deleteDocData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(documentsAction.clearData({}));
              if (
                documentsData?.data?.length === 1 &&
                documentsDataCount > PER_PAGE &&
                deleteAlert
              ) {
                dispatch(
                  documentsAction.getDocumentsData({
                    url: getListingURL(page - 1),
                    data: {},
                    token: true,
                  })
                );
                setPage(page - 1);
              } else {
                dispatch(
                  documentsAction.getDocumentsData({
                    url: getListingURL(page),
                    data: {},
                    token: true,
                  })
                );
              }
              setDeleteAlert(false);
            }}
            mesage={deleteDocData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(documentsAction.clearData({}));
            }}
            mesage={deleteDocData?.data?.message}
          />
        )
      ) : null}

      {getObjLength(uploadDocData) ? (
        uploadDocData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(documentsAction.clearData({}));
              dispatch(
                documentsAction.getDocumentsData({
                  url: getListingURL(page),
                  data: {},
                  token: true,
                })
              );
            }}
            mesage={uploadDocData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(documentsAction.clearData({}));
            }}
            mesage={uploadDocData?.data?.message || "Something went wrong"}
          />
        )
      ) : null}

      {/* Edit popup */}
      {editPopup && (
        <EditDocument
          openPopup={editPopup}
          closeDialogBox={closeDialogBox}
          setOpenPopup={setEditPopup}
          docName={docName}
          tagsList={JSON.parse(tags) ? JSON.parse(tags) : []}
          docId={docId}
          openUploadDocPopup={openUploadDocPopup}
        />
      )}
      <Loader open={deleteDocLoader} />
    </>
  );
}
