import { call, put, takeEvery } from "redux-saga/effects";

import { postAPIPayload } from "../../../../apis/postApi";
import { sendInvitationAction } from "../../../slices/professional/send-invitation/sendInvitationSlice";

export function* fetchSendInvitationListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(sendInvitationAction.setSendInvitationListData(response));
}

export function* sendInvitationDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(sendInvitationAction.setSendInvitationDetails(response));
}

export function* resendInvitationDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(sendInvitationAction.setResendInvitationDetails(response));
}

export function* watchSendInvitationSagaAsync() {
  yield takeEvery(
    sendInvitationAction.fetchSendInvitationListData,
    fetchSendInvitationListDataSaga
  );
  yield takeEvery(
    sendInvitationAction.sendInvitationDetails,
    sendInvitationDetailsSaga
  );
  yield takeEvery(
    sendInvitationAction.resendInvitationDetails,
    resendInvitationDetailsSaga
  );
}
