import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import AddProfessionalModal from "./AddProfessionalModal";
import ProfessionalListTable from "./ProfessionalListTable";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";

import "../AdminDashboard/propertyTable.scss";

const Professional = () => {
  const dispatch = useDispatch();
  // states
  const [page, setPage] = useState(1);
  const [isAddProfessionalModalOpen, setIsAddProfessionalModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [
    isActivateDeactivateProfessionalModalOpen,
    setIsActivateDeactivateProfessionalModalOpen,
  ] = useState(null);
  // handle addProfessional modal close
  const handleClose = () => {
    setIsAddProfessionalModalOpen(false);
  };

  // handle edit professional in add professional modal
  const handleEditProfessional = (item) => {
    setSelectedItem(item);
    setIsAddProfessionalModalOpen(true);
  };

  //   handle add/edit save profesional
  const handleSubmitProfessionalForm = (values) => {
    if (selectedItem && selectedItem?.id) {
      // handle edit dispatch
      dispatch(
        professionalAction?.addProfessionalDetails({
          url: `professional/${selectedItem?.id}/edit`,
          data: values,
          token: true,
        })
      );
    } else {
      // handle add dispatch
      dispatch(
        professionalAction?.addProfessionalDetails({
          url: `professional/create`,
          data: { ...values, step: 7, step_key: "SEND_WELCOME_EMAIL" },
          token: true,
        })
      );
    }
  };

  const getProfessionalTypes = useCallback(() => {
    dispatch(
      professionalAction.fetchProfessionalTypes({
        url: `professional/types`,
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getProfessionalTypes();
  }, [getProfessionalTypes]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Professionals
        </Typography>
        <Button
          variant="contained"
          className="success-btn admin_buttons d-none d-sm-block"
          onClick={() => {
            setSelectedItem(null);
            setIsAddProfessionalModalOpen(true);
          }}
        >
          Add Professional
        </Button>
        <Button
          className="success-btn admin_buttons d-flex d-sm-none"
          variant="outlined"
          onClick={() => {
            setSelectedItem(null);
            setIsAddProfessionalModalOpen(true);
          }}
          startIcon={<AddOutlinedIcon />}
          sx={{ color: "#FFF" }}
        >
          Add
        </Button>
      </Box>
      <Box>
        <ProfessionalListTable
          page={page}
          setPage={setPage}
          isActivateDeactivateProfessionalModalOpen={
            isActivateDeactivateProfessionalModalOpen
          }
          setIsActivateDeactivateProfessionalModalOpen={
            setIsActivateDeactivateProfessionalModalOpen
          }
          handleEditButton={handleEditProfessional}
          setIsAddProfessionalModalOpen={setIsAddProfessionalModalOpen}
        />
      </Box>
      <AddProfessionalModal
        page={page}
        open={isAddProfessionalModalOpen}
        handleClose={handleClose}
        selectedItem={selectedItem}
        onSubmit={handleSubmitProfessionalForm}
        setIsActivateDeactivateProfessionalModalOpen={
          setIsActivateDeactivateProfessionalModalOpen
        }
      />
    </>
  );
};

export default Professional;
