import { Box, Typography } from "@mui/material";
import React from "react";

import VerifyPhysicalCode from "./VerifyPhysicalCode";

import "../../../login/login.scss";
import { useSelector } from "react-redux";

const StannupEmailVerification = ({ row, setStannupPop, from, onClose }) => {
  console.log("popup called==>", row);
  const { verifyStannupDetails} = useSelector(
    (state) => state?.userProfile
  );
  return (
    <Box className={`${!verifyStannupDetails?.success &&"accountBoxWrapper"} Form_Wrapper`} >
      {!verifyStannupDetails?.success && <Typography align="center" variant="h3">
        Address Verification
      </Typography>}
      <VerifyPhysicalCode
        row={row}
        setStannupPop={setStannupPop}
        from={from}
        onClose={onClose}
      />
    </Box>
  );
};

export default StannupEmailVerification;
