import * as React from "react";
import { useCallback } from "react";
import { useEffect, useState } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import usePagination from "../../common/PaginationLogic";
import AddPropertyPopup from "../../userProfile/add-property";
import { COUNTRY_CODE, PER_PAGE } from "../../helpers/constants";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../../admin/AddLeads";
import { usersListAction } from "../../../redux/slices/professional/professional-users-list/usersListSlice";
import {
  getDateInYYYYMMDDFormat,
  getFormatedPhoneNumber,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

import "../../admin/AdminDashboard/propertyTable.scss";

const columns = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
    value: "sr number",
    minWidth: 50,
  },
  {
    id: "name",
    disablePadding: true,
    label: "Name",
    value: "name",
    minWidth: 120,
  },
  {
    id: "email",
    disablePadding: true,
    label: "Email Address",
    value: "email",
    minWidth: 280,
  },
  {
    id: "phone",
    disablePadding: true,
    label: "Phone",
    value: "phone",
    minWidth: 160,
  },
  {
    id: "user_type",
    disablePadding: true,
    label: "User Type",
    value: "user_type",
    minWidth: 120,
  },
  {
    id: "status",
    disablePadding: true,
    label: "Subscription",
    value: "status",
    minWidth: 120,
  },
  {
    id: "created_at",
    disablePadding: true,
    label: "Created At",
    // label: "Assign At",
    value: "created_at",
    minWidth: 200,
  },
  // {
  //   id: "action",
  //   disablePadding: true,
  //   label: "Action",
  //   minWidth: 70,
  // },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell></TableCell> */}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UsersTable() {
  const dispatch = useDispatch();
  // const [selected, setSelected] = useState([]);
  const [usersPayload, setUsersPayload] = useState({
    type: undefined,
    value: undefined,
    // status: undefined,
  });
  // const [usersType, setUsersType] = useState("");
  // const [usersStatus, setUsersStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [toggleFilterOptions, setToggleFilterOptions] = React.useState(false);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isAddPropertyModalOpen, setIsAddPropertyModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  // store data
  const { professionalListLoader } = useSelector(
    (state) => state?.professionals
  );
  const open = Boolean(anchorEl);

  // Selectors for users Listing
  const { usersListData, propertiesLoader, usersListDataCount } = useSelector(
    (state) => state?.professionalUsersLists
  );

  const count = Math.ceil(usersListDataCount / PER_PAGE);
  // const count = 2;
  const _DATA = usePagination(usersListData?.data, PER_PAGE, page, setPage);
  // const _DATA = usersListData?.data ? usersListData?.data : [];

  // const _DATA = usersListData;

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // dispatch(
    //   usersListAction.fetchUsersListData({
    //     url: getUsersListData(p),
    //     data: {},
    //     token: true,
    //   })
    // );
  };

  const handleMenuClick = (event, data) => {
    console.log("selected row==>", data);
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterusers = () => {
    if (selectedValue || selectedStatus || selectedDateRange) {
      setPage(1);
      setUsersPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        value: selectedValue || undefined,
        from_date: selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
          : undefined,
        to_date: selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
          : undefined,
        // status: selectedStatus || undefined,
      }));
    }
    // setUsersType();
    // setUsersEmail("");
    // setUsersStatus();
  };

  const resetAllFilterOptions = () => {
    // setUsersType();
    // setUsersEmail("");
    // setUsersStatus();
    setSelectedKey("all");
    setSelectedStatus("");
    setSelectedValue("");
    setSelectedDateRange(null);
    setUsersPayload({});
    setPage(1);
  };

  const getUsersListData = useCallback(() => {
    dispatch(
      usersListAction.getUsersListData({
        url: "professional/users-list",
        data: {
          type: usersPayload?.type,
          value: usersPayload?.value,
          from_date: usersPayload?.from_date,
          to_date: usersPayload?.to_date,
          // status: usersPayload?.status,
        },
        token: true,
      })
    );
    // }, [dispatch, usersPayload?.status, usersPayload?.term, usersPayload?.type]);
  }, [
    dispatch,
    usersPayload?.type,
    usersPayload?.value,
    usersPayload?.from_date,
    usersPayload?.to_date,
  ]);

  useEffect(() => {
    getUsersListData();
  }, [dispatch, getUsersListData]);

  console.log("usersListData==>", usersListData);
  return (
    <>
      {usersListData?.success ? (
        <>
          {/* Filtering Component Section */}
          <Tooltip title="Filter options" placement="right-start">
            <Box
              id="filter"
              onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
            >
              <h3 className="filter-text">Filter your results</h3>
              <ArrowDropDownIcon
                className="filter-arrow"
                sx={{ ms: 1, fontSize: "20px" }}
              />
            </Box>
          </Tooltip>
          <Box
            sx={{
              overflow: "hidden",
              overflowX: "auto",
              mb: 1.5,
            }}
          >
            {/* Filtering Options */}
            {toggleFilterOptions && (
              <>
                {/* mobile view */}
                <Box className="d-block d-md-none">
                  <Grid
                    container
                    className="bg-white d-flex justify-content-around align-items-center px-4"
                    spacing={2}
                    pb={2}
                    pt={2}
                  >
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Typography className="search-text">Search By</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <FormControl className="filter-select-one  w-100">
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="All Types"
                          value={selectedKey}
                          onChange={(event) => {
                            setSelectedKey(event.target.value);
                            setSelectedValue("");
                            setSelectedStatus("");
                            setSelectedDateRange(null);
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          {columns.map(
                            (cell) =>
                              cell.id !== "no" &&
                              cell.id !== "user_type" &&
                              cell.id !== "action" && (
                                <MenuItem key={cell.id} value={cell.id}>
                                  {cell.label}
                                </MenuItem>
                              )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    {selectedKey !== "phone" &&
                      selectedKey !== "created_at" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl
                            className="filter-search w-100"
                            variant="outlined"
                          >
                            <InputLabel>Enter search value</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              }
                              label="Enter value"
                              value={selectedValue}
                              onChange={(e) => setSelectedValue(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    {selectedKey === "phone" && (
                      <Grid item xs={12} sm={12} md={4} lg={2}>
                        <Box className={`phone-number-input bg-white filter`}>
                          <PhoneInput
                            className="bg-white"
                            international={false}
                            placeholder="Phone Number"
                            value={selectedValue}
                            countries={["US", "IN"]}
                            defaultCountry={
                              COUNTRY_CODE === "+91" ? "IN" : "US"
                            }
                            countryOptionsOrder={["US", "IN"]}
                            addInternationalOption={false}
                            onChange={(event) => {
                              setSelectedValue(event);
                            }}
                          />
                        </Box>
                      </Grid>
                    )}
                    {selectedKey === "created_at" && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-search w-100">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <FormControl className="w-100">
                        <Button
                          className="filter-button"
                          onClick={handleFilterusers}
                          sx={mobileFilterResultStyle}
                        >
                          Filter Results
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        type="reset"
                        onClick={resetAllFilterOptions}
                        sx={mobileFilterResetBtnStyle}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* desktop view */}
                <Card id="filter-options-card" className="d-none d-md-block">
                  <Box className="filter-options-box">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        onChange={(event) => {
                          setSelectedKey(event.target.value);
                          setSelectedValue("");
                          setSelectedStatus("");
                          setSelectedDateRange(null);
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.id !== "no" &&
                            cell.id !== "user_type" &&
                            cell.id !== "action" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                    {selectedKey !== "phone" &&
                      selectedKey !== "created_at" && (
                        <FormControl
                          className="filter-search"
                          variant="outlined"
                        >
                          <InputLabel>Enter search value</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            }
                            label="Enter value"
                            value={selectedValue}
                            onChange={(e) => setSelectedValue(e.target.value)}
                          />
                        </FormControl>
                      )}

                    {selectedKey === "phone" && (
                      <Box className={`phone-number-input bg-white filter`}>
                        <PhoneInput
                          className="bg-white"
                          international={false}
                          placeholder="Phone Number"
                          value={selectedValue}
                          countries={["US", "IN"]}
                          defaultCountry={COUNTRY_CODE === "+91" ? "IN" : "US"}
                          countryOptionsOrder={["US", "IN"]}
                          addInternationalOption={false}
                          onChange={(event) => {
                            setSelectedValue(event);
                          }}
                        />
                      </Box>
                    )}

                    {selectedKey === "created_at" && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-search w-100">
                          <CustomDateRangePicker
                            rangeValue={selectedDateRange}
                            onChange={(values) => {
                              setSelectedDateRange(values);
                            }}
                            onClear={() => {
                              setSelectedDateRange(null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterusers}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetAllFilterOptions}
                    >
                      Reset
                    </Button>
                  </Box>
                </Card>
              </>
            )}
          </Box>

          {/* users Listing Table */}
          {/* mobile view */}
          <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 sub-user-mobile-table py-4">
            {_DATA?.currentData()?.map((row, index) => {
              return (
                <>
                  <Stack direction="column" spacing={1.2} px={2}>
                    <Stack direction="column" spacing={0.5}>
                      <Typography
                        className="value fw-500 text-capitalize"
                        sx={{ fontSize: "15px !important" }}
                      >
                        {`${row?.first_name} ${row?.last_name}`.toLowerCase() ||
                          "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Email:</Typography>
                      <Typography className="value">
                        {row?.email || "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Phone:</Typography>
                      <Typography className="value">
                        {row?.phone ? getFormatedPhoneNumber(row?.phone) : "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key">Type:</Typography>
                      <Typography className="value">
                        {row?.user_type[0] || "-"}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key">Subscription:</Typography>
                      <Typography className="value">
                        {row?.status === "pending" ? (
                          <span className="text-danger ">Pending</span>
                        ) : (
                          <span className="text-success">Active</span>
                        )}
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={0.5}>
                      <Typography className="key"> Created At:</Typography>
                      <Typography className="value">
                        {row?.created_at
                          ? getLocalFormatedDate(row?.created_at)
                          : "-"}
                      </Typography>
                    </Stack>
                  </Stack>

                  {index < _DATA?.currentData()?.length - 1 && (
                    <Divider
                      className="my-3"
                      sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                    />
                  )}
                </>
              );
            })}

            {getObjLength(usersListData) &&
              !_DATA?.currentData()?.length > 0 && (
                <Typography
                  className="d-block d-sm-none p-5 text-center"
                  variant="h6"
                >
                  No Data Found.
                </Typography>
              )}
          </Box>

          {/* desktop view */}
          <Box sx={{ width: "100%" }} className="d-none d-sm-block">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {_DATA?.currentData()?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.address)}
                          // role="checkbox"
                          tabIndex={-1}
                          key={(page - 1) * 10 + index + 1}
                        >
                          <TableCell align="left">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell
                            className="text-capitalize"
                            align="left"
                          >{`${row?.first_name} ${row?.last_name}`}</TableCell>
                          <TableCell
                            align="left"
                            sx={{ wordBreak: "break-all" }}
                          >
                            {row?.email}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ wordBreak: "keep-all" }}
                          >
                            {row?.phone
                              ? getFormatedPhoneNumber(row?.phone)
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.user_type[0]}
                          </TableCell>
                          <TableCell align="left">
                            {row?.status === "pending" ? (
                              <span className="text-danger ">Pending</span>
                            ) : (
                              <span className="text-success">Active</span>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {row?.created_at
                              ? getLocalFormatedDate(row?.created_at)
                              : "-"}
                          </TableCell>
                          {/* <TableCell align="left">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={(e) => handleMenuClick(e, row)}
                                size="small"
                                className="settings-button"
                                aria-controls={
                                  open ? "settings-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <SettingsIconSVG />
                              </IconButton>
                            </Box>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!usersListData?.data?.length > 0 && (
                  <Typography className="p-5 text-center w-100" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
            {/* {usersListData?.length > PER_PAGE && ( */}
          </Box>

          {usersListData?.data?.length > PER_PAGE && (
            <Box id="pagination-container">
              <CustomPagination
                count={count}
                page={page}
                handlePageChange={handlePageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Typography className="text-center text-danger my-3">
          {usersListData?.data?.message}
        </Typography>
      )}
      <Loader open={propertiesLoader || professionalListLoader} />

      {/* )} */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="add_property"
          onClick={() => {
            if (
              selectedRow?.json_last_step &&
              selectedRow?.json_last_step?.step < 1 &&
              selectedRow?.json_last_step?.step_key !== "SEND_OTP"
            ) {
              return;
            }
            dispatch(propertiesAction.setSelectedUserID(selectedRow?.user_id));
            setIsAddPropertyModalOpen(true);
          }}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <AddIcon />
            <Typography className="setting-menu-text">Add Property</Typography>
          </Box>
        </MenuItem>
      </Menu>

      {/* add property modal */}
      {isAddPropertyModalOpen && (
        <AddPropertyPopup
          classes={"d-none"}
          getPropertyList={() => {}}
          showBtn={false}
          initialyAddPropertyModal={isAddPropertyModalOpen}
          isByPassOptionAvailable={true}
          handleCloseModal={() => {
            // dispatch(
            //   propertyUsersAction.getPropertyUsersData({
            //     url: `admin/users-v1`,
            //     data: {},
            //     token: true,
            //   })
            // );
            setIsAddPropertyModalOpen(false);
          }}
        />
      )}
    </>
  );
}
