import React, { useRef, useState } from "react";
import playButton from "../../assets/icons/play_btn.svg";
import bluePlayButton from "../../assets/icons/blue_play_button.svg";
import "../../styles/LazyIFrame.scss";
import { Box, Typography } from "@mui/material";

const LazyIframe = ({ id, caption }) => {
  const opts = {
    height: "300px",
    width: "100%",
    playerVars: {
      // autoplay: 1,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [hovered, setHovered] = React.useState(false);

  const handlePlayVideo = (id) => {
    setIsPlaying(true);
  };
  const thumbnail = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;

  return (
    <div className="video-wrapper">
      {isPlaying ? (
        <iframe
          width="100%"
          height="230"
          src={`https://www.youtube.com/embed/${id}?autoplay=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ display: "block" }}
        />
      ) : (
        //  <YouTube videoId={id} opts={opts} onReady={_onReady} />
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "230px",
            cursor: "pointer",
            transition: "opacity 0.3s",
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={handlePlayVideo}
        >
          <img
            src={thumbnail}
            alt={`Thumbnail for video ${id}`}
            className="thumbnail"
          />
          <div className="custom-play-button">
            {hovered ? (
              <img src={bluePlayButton} alt={`play`} />
            ) : (
              <img src={playButton} alt={`play`} />
            )}
          </div>
          {hovered && (
            <Box
              position="absolute"
              // top={0}
              left={0}
              right={0}
              bottom={40}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pointerEvents: "none" }}
            >
              <Typography className="captions">{caption}</Typography>
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default LazyIframe;
