import React, { useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Paper, Typography } from "@mui/material";

import ProvidersListTable from "./ProvidersListTable";
import AddProviderComponent from "./AddProviderComponent";
import { useCallback } from "react";
import { useEffect } from "react";
import { otherProvidersAction } from "../../../redux/slices/admin/other-providers/otherProvidersSlice";
import { useDispatch } from "react-redux";

const Providers = () => {
  const [isAddProvider, setIsAddProvider] = useState(false);
  const dispatch = useDispatch();

  // get providers details
  const getProvidersLists = () => {
    dispatch(
      otherProvidersAction?.otherProvidersListData({
        url: `providers`,
        data: {
          limit: 10,
          offset: 0,
          order_by_column: "updated_at",
          sorting_order: "desc",
        },
        token: true,
      })
    );
  };

  return (
    <>
      {isAddProvider ? (
        <AddProviderComponent
          goBack={() => {
            setIsAddProvider(false);
          }}
          listingAPI={getProvidersLists}
        />
      ) : (
        <>
          <Paper
            elevation={0}
            className=" d-flex justify-content-between"
            sx={{ background: "none" }}
          >
            <Box className="" sx={{ mb: 1 }}>
              <Typography variant="h6" className="card-title fw-600">
                Providers List
              </Typography>
            </Box>
            <Box className="">
              <Button
                variant="contained"
                className="success-btn admin_buttons d-none d-sm-block"
                style={{
                  borderRadius: "20px",
                }}
                onClick={() => {
                  setIsAddProvider(true);
                }}
              >
                Add Provider
              </Button>
              <Button
                className="success-btn admin_buttons d-flex d-sm-none"
                variant="outlined"
                onClick={() => {
                  setIsAddProvider(true);
                }}
                startIcon={<AddOutlinedIcon />}
                sx={{ color: "#FFF" }}
              >
                Add
              </Button>
            </Box>
          </Paper>

          <Box>
            <ProvidersListTable listingAPI={getProvidersLists} />
          </Box>
        </>
      )}
    </>
  );
};

export default Providers;
