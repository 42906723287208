import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Grid, Typography } from "@mui/material";

const OwnerInfoTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );

  return (
    <>
      <Box>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Row>
              <Col className="label-text">
                <Typography>Owner Name: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.[
                    "Owner Name"
                  ]?.trim() || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Owner Name 2: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.[
                    "Owner Name 2"
                  ]?.trim() || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Vesting: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.[
                    "Vesting"
                  ]?.trim() || "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Row>
              <Col className="label-text">
                <Typography>Owner Occupied: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.[
                    "Owner Occupied"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Mailing Address: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.[
                    "Mailing Address"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Mailing City & State: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.[
                    "Mailing City & State"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Mailing Zip: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.OwnerInfo?.["Mailing Zip"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OwnerInfoTab;
