/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PhotoIdScreen from "../../common/capture-image/photoIdScreen";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { Box, Button } from "@mui/material";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import useCloseCameraStream from "../../hooks/useCloseCameraStream";

const EditBioMetric = ({
  backToAuth,
  setOpenPopup,
  imageUpdatedPopup,
  setImageUpdatedPopup,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isCamera, setIsCamera] = useState(false);
  const [faceErr, setFaceErr] = useState("");
  const [isCameraResponseChecked, setIsCameraResponseChecked] = useState(false);
  const { updateCameraState } = useCloseCameraStream();

  const { updateBiometricDetails } = useSelector((state) => state?.userProfile);

  useEffect(() => {
    console.log("Face verify -->", updateBiometricDetails);
    if (Object.keys(updateBiometricDetails).length > 0) {
      console.log("validate 1");
      handleClose();
    }
  }, [updateBiometricDetails]);

  const handleClose = () => {
    if (Object.keys(updateBiometricDetails).length > 0) {
      if (!updateBiometricDetails?.success) {
        setFaceErr(updateBiometricDetails?.data?.message);
      } else {
        setFaceErr("");
        setImageUpdatedPopup(true);
      }
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const onsubmit = (image) => {
    let inputParam = {
      face_image: image,
    };
    console.log("on submit==>.", inputParam);
    handleToggle();
    dispatch(
      userProfileAction.updateBiometricData({
        url: "customer/update-biometric/face",
        data: inputParam,
        token: true,
      })
    );
  };
  const removeValidationMsg = () => {
    setFaceErr("");
  };
  const closePopup = () => {
    setImageUpdatedPopup(false);
    setOpenPopup(false);
    dispatch(
      userProfileAction.getBiometricData({
        url: "customer/get-biometric/face",
        data: {},
        token: true,
      })
    );
  };

  const checkMediaAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setIsCamera(true);
        setIsCameraResponseChecked(true);
        updateCameraState(stream);
      }
    } catch (err) {
      if (err.name === "NotAllowedError") {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else if (
        err.name === "NotFoundError" ||
        err.name === "NotReadableError"
      ) {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
      } else {
        setIsCamera(false);
        setIsCameraResponseChecked(true);
        console.log("Error accessing camera:", err);
      }
    }
    // navigator.mediaDevices.enumerateDevices().then((devices) =>
    //   devices.forEach((device) => {
    //     if (device.kind === "videoinput") {
    //       setIsCamera(true);
    //       console.log("YY Has Video Access");
    //     }
    //   })
    // );
  };

  useEffect(() => {
    checkMediaAccess();
  }, []);
  return (
    <Box sx={{ minHeight: "300px" }}>
      {!imageUpdatedPopup && (
        <PhotoIdScreen
          biometric={true}
          backBtn={false}
          backToAuth={() => setOpenPopup(false)}
          onsubmit={onsubmit}
          faceErr={faceErr}
          isCamera={isCamera}
          removeValidationMsg={removeValidationMsg}
          isCameraResponseChecked={isCameraResponseChecked}
          userProfileScreen={true}
        />
      )}
      <Loader open={open} />
      {imageUpdatedPopup && (
        <PlainAlertMessage
          type="Success"
          openPopup={imageUpdatedPopup}
          onClose={true}
          onCloseFun={closePopup}
          message={updateBiometricDetails?.message}
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={() => {
                closePopup();
              }}
            >
              Ok, All Good
            </Button>
          }
        />
      )}
    </Box>
  );
};

export default EditBioMetric;
