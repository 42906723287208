import * as React from "react";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 26,
  height: 26,
  border: "1px solid rgba(52, 75, 91, 0.1)",
  position: "relative",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#F6F9FC",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0395FF",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "input:hover ~ &": {
    backgroundColor: "#0395FF",
  },
});

// Inspired by blueprintjs
export default function CustomizedRadios(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
