import React from "react";

import { useSelector } from "react-redux";

import { Button, Stack, Typography } from "@mui/material";

import AlertDialog from "../../common/AlertDialog";

const TotalPyamentPopup = ({
  dataflag = false,
  setDataFlag = () => {},
  alert = false,
  setAlert = () => {},
  planDetails,
  closePopup,
  isClose = true,
  paySecure = () => {},
}) => {
  // const { totalPaymentDetails } = useSelector((state) => state.signUpUser);
  const { totalPaymentDetails } = useSelector((state) => state.signUpUser);
  console.log(dataflag, "alert==>>", alert);

  return (
    <>
      {alert && dataflag ? (
        <>
          <AlertDialog
            type="Info"
            openPopup={alert}
            onClose={isClose}
            onCloseFun={() => {
              setAlert(false);
            }}
            mesage={
              <>
                <Typography align="center" variant="h6">
                  Total Amount Details
                  {planDetails
                    ? ` for ${planDetails?.name || planDetails?.plan_Name} Plan`
                    : null}
                </Typography>
                <p className="para">
                  Total Properties:{" "}
                  <b>{totalPaymentDetails?.data?.no_of_properties} </b>
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span> Plan
                  amount: <b>${totalPaymentDetails?.data?.sub_amount} </b>
                  <br />
                  Discount: <b>${totalPaymentDetails?.data?.discount} </b>{" "}
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span>{" "}
                  Sales/Local taxes:{" "}
                  <b>${totalPaymentDetails?.data?.sales_tax_amount_actual} </b>
                  {parseFloat(
                    totalPaymentDetails?.data?.recording_amount_actual
                  ) > 0 && (
                    <>
                      <br />
                      Deposit for recording fees:{" "}
                      <b>
                        ${totalPaymentDetails?.data?.recording_amount_actual}{" "}
                      </b>{" "}
                      <br className="d-block d-sm-none" />
                      <span className="d-none d-sm-inline-block">
                        {" "}
                        and{" "}
                      </span>{" "}
                      Setup Fees:{" "}
                      <b>${totalPaymentDetails?.data?.setup_fees_actual}</b>
                    </>
                  )}
                  <br />
                  <br className="d-block d-sm-none" />
                  Total Payable Amount:{" "}
                  <b>${totalPaymentDetails?.data?.total} </b>
                </p>
              </>
            }
            buttons={
              <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                <Button
                  onClick={() => closePopup("cancel")}
                  type="submit"
                  className=" mx-2 popup_Btn other_popup_btn"
                  variant="contained"
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    closePopup("submit");
                    paySecure();
                  }}
                  type="submit"
                  className="mx-2 popup_Btn success_popup_btn"
                  variant="contained"
                >
                  Next
                </Button>
              </Stack>
            }
          />
        </>
        ) : (
        <AlertDialog
          type="Error"
          openPopup={alert}
          onClose={true}
          onCloseFun={isClose}
          mesage={totalPaymentDetails?.data?.message}
          buttons={
            <Stack spacing={2} direction={"row"} justifyContent={"center"}>
              <Button
                className="popup_Btn error_popup_btn m-2"
                onClick={() => {
                  setAlert(false);
                  closePopup("cancel");
                }}
              >
                cancel
              </Button>
            </Stack>
          }
        />
      )}
    </>
  );
};

export default TotalPyamentPopup;
