/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
} from "@mui/material";

import { getObjLength } from "../helpers/utils";
import AlertDialog from "../common/AlertDialog";
import ContcatAdminSupport from "../common/ContcatAdminSupport";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import CustomizedCheckbox from "../common/customDesignedComponents/customCheckbox";

import "./signup.scss";

const FieldStyle = {
  borderRadius: "3px",
};

const validationSchema = Yup.object().shape({
  parcel_number: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(15, "Too Long!")
    .required("Parcel number is required"),
  county_code: Yup.string("Please select the County ").required(
    "County  is required"
  ),
});

export default function FindPropertyByParcel({
  onBtnClick,
  HideSkipBtn,
  errorPopup,
  reduceErrorCount,
  setShowPropertyListing,
  propertyValues,
  setPropertyValues,
  questionsErrMsg,
  setErrMsg,
  errMsg,
}) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const [options, setOptions] = React.useState([]);
  const [checkTerms, setCheckTerms] = useState(true);
  const [checked1, setChecked1] = useState(false);
  const [countyAlert, setCountyAlert] = useState(false);

  const { getPropertyData, countyList, statesLoader } = useSelector(
    (state) => state.signUpUser
  );
  const { countyData } = useSelector((state) => state?.propertyRiskManagement);

  const onSubmit = (values, errors) => {
    setErrMsg("");

    setCheckTerms(checked1);
    if (checked1) {
      let data = { ...values, type: "parcel" };
      let countyName = data?.county_name?.split(" ")?.[0];
      console.log(
        countyName,
        "submited values==>",
        countyData?.data?.county_name
      );
      if (getObjLength(countyData)) {
        if (
          countyName?.toLowerCase() ===
          countyData?.data?.county_name?.toLowerCase()
        ) {
          dispatch(
            userSigupAction.getUserProperty({
              url: "properties",
              data: data,
              token: false,
            })
          );
          setCountyAlert(false);
          console.log("county data in", countyData);
        } else {
          setCountyAlert(true);
          console.log("county data out", countyData);
        }
      } else {
        dispatch(
          userSigupAction.getUserProperty({
            url: "properties",
            data: data,
            token: false,
          })
        );
      }
    }
    // onBtnClick();
  };

  const getCountyList = (event) => {
    console.log("value==>", event.target.value);
    dispatch(
      userSigupAction.getCountyList({
        url: "get-county-autocomplete",
        data: { county: event.target.value },
      })
    );
  };

  const onChecked = (e) => {
    console.log("cjceked val==?", e.target.checked);
    setChecked1(e.target.checked);
    if (!e.target.checked) {
      setCheckTerms(e.target.checked);
    } else {
      setCheckTerms(true);
    }
  };

  useEffect(() => {
    if (getObjLength(countyList)) {
      console.log(countyList, "options==>", options);
      if (countyList?.success) {
        setOptions([...countyList?.data]);
      }
    }
  }, [countyList]);
  useEffect(() => {
    if (getObjLength(getPropertyData)) {
      if (getPropertyData?.success) {
        setPropertyValues(getPropertyData?.data);
        setErrMsg("");
        if (getPropertyData?.data?.length === 1) {
          dispatch(
            userSigupAction.selectedUserPropertyData(getPropertyData?.data[0])
          );
        }
        if (getPropertyData?.data?.length > 1) {
          setOptions([]);
          setShowPropertyListing(true);
          console.log("setPropertyValues ==>", getPropertyData);
        }
      } else {
        setPropertyValues({});
        setErrMsg(getPropertyData?.data?.message);
      }
    }
  }, [getPropertyData]);

  return (
    <>
      <Box>
        {
          <>
            <>
              <>
                <Box mt={3} className="findPropertyBoxWrapper">
                  <Formik
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                      parcel_number: "",
                      county_code: "",
                      county_name: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, touched, errors }) => {
                      return (
                        <Form>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid xs={12} md={6} px={1} pb={1}>
                              <TextField
                                className="sign-textField"
                                label="Parcel Number"
                                name="parcel_number"
                                // type="number"
                                // onKeyDown={(e) => {
                                //   // if (!e.key.match(/^[0-9\s]*$/)?.length > 0) {
                                //   //   e.preventDefault();
                                //   // }
                                //   console.log("ee=>", e);
                                //   if (
                                //     e.key !== "Control" &&
                                //     e.key.match("^[a-z]+$")?.length > 0
                                //   ) {
                                //     e.preventDefault();
                                //   }
                                // }}
                                value={values?.parcel_number}
                                variant="outlined"
                                sx={FieldStyle}
                                InputLabelProps={{
                                  style: {
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#0D120B",
                                  },
                                }}
                                onChange={(event) => {
                                  setFieldValue(
                                    "parcel_number",
                                    event.target.value
                                  );
                                }}
                                helperText={
                                  touched.parcel_number && errors.parcel_number
                                }
                                error={
                                  touched.parcel_number &&
                                  Boolean(errors.parcel_number)
                                }
                              />
                            </Grid>

                            <Grid xs={12} md={6} px={1} pb={1}>
                              <FormControl
                                fullWidth
                                className="sign-textField"
                                htmlFor="demo-customized-textbox"
                                error={
                                  touched.county_code &&
                                  Boolean(errors.county_code)
                                }
                              >
                                <Autocomplete
                                  id="asynchronous-demo"
                                  // freeSolo
                                  disableClearable
                                  sx={FieldStyle}
                                  open={open}
                                  onOpen={() => {
                                    setOpen(true);
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      "county_code",
                                      String(newValue?.id)
                                    );
                                    setFieldValue(
                                      "county_name",
                                      String(newValue?.county)
                                    );
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.county === value.county
                                  }
                                  getOptionLabel={(option) => option.county}
                                  options={options}
                                  loading={statesLoader}
                                  renderInput={(params) => (
                                    <TextField
                                      onChange={(event) => {
                                        getCountyList(event);
                                        setOptions([]);
                                      }}
                                      label="County"
                                      placeholder={"Search the County"}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {statesLoader ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {touched.county_code &&
                                  Boolean(errors.county_code) && (
                                    <FormHelperText
                                      sx={{ color: "#d32f2f !important" }}
                                    >
                                      {touched.county_code &&
                                        errors.county_code}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Box
                            className={!checkTerms && "border border-danger"}
                            textAlign={"start"}
                            py={1}
                            px={2}
                          >
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent={"flex-start"}
                              alignItems={"start"}
                            >
                              <span className="pt-1">
                                <CustomizedCheckbox onChecked={onChecked} />
                              </span>
                              <span>
                                For security reasons, I understand that I will
                                be asked challenge questions and/or my customer
                                service PIN to verify my identity. If I receive
                                a call and I am NOT asked the proper challenge
                                questions, I will hang up and call Customer
                                Support immediately.
                              </span>
                            </Stack>
                          </Box>
                          {!checkTerms && (
                            <p className="text-danger m-3">
                              Please select given terms to proceed further.
                            </p>
                          )}
                          {errMsg && (
                            <p className="text-center text-danger m-3">
                              {errMsg}
                            </p>
                          )}
                          <div className="text-center stepBtn mt-5">
                            <Button
                              type="submit"
                              className="next_button mb-3"
                              variant="contained"
                            >
                              Next Step
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
              </>
            </>

            {questionsErrMsg?.length > 0 && (
              <p className="text-danger text-center">{questionsErrMsg}</p>
            )}
          </>
        }
      </Box>
      {/* <Loader open={propertyLoader} /> */}

      {countyAlert && (
        <AlertDialog
          type={"Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            setCountyAlert(false);
          }}
          mesage={
            <>
              The address must be within{" "}
              {<b>{countyData?.data?.county_name}</b>} County.
            </>
          }
          footer={false}
        />
      )}

      {errorPopup && (
        <ContcatAdminSupport
          message={errMsg || questionsErrMsg}
          errorPopup={errorPopup}
          reduceErrorCount={reduceErrorCount}
        />
      )}
    </>
  );
}
