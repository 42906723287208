import { createSlice } from "@reduxjs/toolkit";

const intialUserProfessionalState = {
  userTokenVarification: {},
  userFormDetails: {},
  userTokenLoader: false,
};

const userDetailsForm = createSlice({
  name: "userDetailsFormDetails",
  initialState: intialUserProfessionalState,
  reducers: {
    getUserTokenVarificationData: (state, action) => {
      state.userTokenLoader = true;
      console.log("userDetailsForm slice", action.payload);
    },
    fetchUserTokenVarificationData: (state, action) => {
      console.log("userDetailsFormDetails slice", action.payload);
      state.userTokenLoader = false;
      state.userTokenVarification = action.payload;
    },
    getUserFormDetails: (state, action) => {
      state.userTokenLoader = true;
      console.log("userDetailsForm slice", action.payload);
    },
    fetchUserFormDetails: (state, action) => {
      console.log("userDetailsFormDetails slice", action.payload);
      state.userTokenLoader = false;
      state.userFormDetails = action.payload;
    },
  },
});

export const userDetailsFormAction = userDetailsForm.actions;
export default userDetailsForm.reducer;
