import React from "react";
import { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";

import { Loader } from "../common/loader";
import { spouseAction } from "../../redux/slices/spouse/spouseSlice";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { subUsersActions } from "../../redux/slices/admin/subUsers/subUserSlice";
import "../common/common.scss";
import PlainAlertMessage from "../common/PlainAlertMessage";
import { getObjLength } from "../helpers/utils";
import { PER_PAGE } from "../helpers/constants";

const FieldStyle = {
  borderRadius: "3px",
};

const AddSpouseModal = ({
  open,
  selectedItem,
  handleClose,
  onSubmit,
  label = "Access",
  isAdmin = false,
  fetchSubUserListing,
  setSelectedSubUser = () => {},
}) => {
  const dispatch = useDispatch();
  const { addSpouseDetails, spouseListLoader } = useSelector(
    (state) => state?.spouse
  );
  const { addSubUserDetails } = useSelector((state) => state?.subUsers);
  // clear add/edit/delete professional's existing data
  useEffect(() => {
    if (open) {
      dispatch(spouseAction.clearAddSpouseDetailsData());
    }
  }, [dispatch, open]);

  const getSpouseDetails = () => {
    dispatch(
      spouseAction.fetchSpouseListData({
        url: `sub-users`,
        data: {
          limit: PER_PAGE,
          offset: 0,
        },
        token: true,
      })
    );
  };

  return (
    <>
      <Dialog
        open={open}
        sx={{ zIndex: "13000001" }}
        fullWidth
        maxWidth={
          !getObjLength(addSubUserDetails) && !getObjLength(addSpouseDetails)
            ? "md"
            : "sm"
        }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!getObjLength(addSubUserDetails) && !getObjLength(addSpouseDetails) ? (
          <>
            <DialogTitle id="alert-dialog-title">
              {selectedItem ? `Edit ${label}` : `Add ${label}`}
              <Button
                onClick={() => handleClose()}
                className="modal-close-btn"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  background: "transparent",
                }}
              >
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>
            </DialogTitle>
            <DialogContent dividers>
              <Box className="d-flex justify-content-center bg-white">
                <Formik
                  enableReinitialize
                  validateOnChange
                  initialValues={{
                    first_name: selectedItem?.first_name || "",
                    last_name: selectedItem?.last_name || "",
                    email: selectedItem?.email || "",
                    phone_number:
                      selectedItem?.phone_number || selectedItem?.phone || "",
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string("Please enter your First Name.")
                      .trim(
                        "The First name cannot include leading and trailing spaces"
                      )
                      .strict(true)
                      .required("First Name is required"),
                    last_name: Yup.string("Please enter your Last name.")
                      .trim(
                        "The Last name cannot include leading and trailing spaces"
                      )
                      .strict(true)
                      .required("Last Name is required"),
                    email: Yup.string("Please enter your email.")
                      .trim(
                        "The email cannot include leading and trailing spaces"
                      )
                      .strict(true)
                      .email("Please enter valid email address")
                      .required("Email is required"),
                    phone_number: Yup.string("PLease enter your Phone number.")
                      .typeError("That doesn't look like a phone number")
                      .required("Mobile number is required"),
                  })}
                  onSubmit={(values, validation) => {
                    if (!isValidPhoneNumber(values?.phone_number)) {
                      return;
                    }
                    onSubmit(values, validation);
                    setSelectedSubUser(values);
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    touched,
                    errors,
                    initialValues,
                  }) => {
                    return (
                      <Form
                        className="beneficiary-form w-100"
                        style={{ maxWidth: "100%" }}
                      >
                        <DialogContent className="bg-white">
                          {/* {!addSpouseDetails?.success && ( */}
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            className="bg-white"
                            // spacing={1}
                          >
                            <>
                              {/* first name */}
                              <Grid item xs={12} sm={6} md={6} pb={1}>
                                <TextField
                                  className="sign-textField "
                                  label="First name"
                                  name="first_name"
                                  value={values?.first_name}
                                  variant="outlined"
                                  sx={FieldStyle}
                                  InputLabelProps={{
                                    style: {
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      color: "#00000075",
                                    },
                                  }}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "first_name",
                                      event.target.value
                                    );
                                  }}
                                  helperText={
                                    touched.first_name && errors.first_name
                                  }
                                  error={
                                    touched.first_name &&
                                    Boolean(errors.first_name)
                                  }
                                />
                              </Grid>
                              {/* last name */}
                              <Grid item xs={12} sm={6} md={6} pb={1}>
                                <TextField
                                  className="sign-textField "
                                  label="Last name"
                                  name="last_name"
                                  variant="outlined"
                                  sx={FieldStyle}
                                  InputLabelProps={{
                                    style: {
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      color: "#00000075",
                                    },
                                  }}
                                  value={values?.last_name}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "last_name",
                                      event.target.value
                                    );
                                  }}
                                  helperText={
                                    touched.last_name && errors.last_name
                                  }
                                  error={
                                    touched.last_name &&
                                    Boolean(errors.last_name)
                                  }
                                />
                              </Grid>
                              {/* email */}
                              <Grid item xs={12} sm={6} md={6} pb={1}>
                                <TextField
                                  className="sign-textField "
                                  label="Email"
                                  name="email"
                                  disabled={
                                    !isAdmin &&
                                    (selectedItem?.user_id || selectedItem?.id)
                                      ? true
                                      : false
                                  }
                                  variant="outlined"
                                  sx={FieldStyle}
                                  InputLabelProps={{
                                    style: {
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      color: "#00000075",
                                    },
                                  }}
                                  value={values?.email}
                                  onChange={(event) => {
                                    setFieldValue("email", event.target.value);
                                  }}
                                  helperText={touched.email && errors.email}
                                  error={touched.email && Boolean(errors.email)}
                                />
                              </Grid>
                              {/* filler phone */}
                              <Grid item xs={12} sm={6} md={6} pb={1}>
                                <CustomPhoneNumberInput
                                  name="phone_number"
                                  values={values}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                  disabled={
                                    !isAdmin &&
                                    (selectedItem?.user_id || selectedItem?.id)
                                      ? true
                                      : false
                                  }
                                />
                              </Grid>
                            </>
                          </Grid>
                        </DialogContent>

                        <DialogActions className="alert-modal-btn">
                          <Stack direction="row" spacing={2} pb={2}>
                            {addSpouseDetails?.success ? (
                              <Button
                                className="popup_Btn other_popup_btn m-1"
                                // variant="contained"
                                onClick={() => {
                                  handleClose();
                                }}
                              >
                                Close
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className="popup_Btn other_popup_btn m-1"
                                  // variant="contained"
                                  onClick={() => {
                                    handleClose();
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="popup_Btn success_popup_btn m-1"
                                  type="submit"
                                  disabled={
                                    JSON.stringify(initialValues) ===
                                    JSON.stringify(values)
                                  }
                                  variant="contained"
                                >
                                  Save
                                </Button>
                              </>
                            )}
                          </Stack>
                        </DialogActions>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            {getObjLength(addSpouseDetails) ||
            getObjLength(addSubUserDetails) ? (
              addSpouseDetails?.success || addSubUserDetails.success ? (
                <PlainAlertMessage
                  type="Success"
                  openPopup={true}
                  onClose={true}
                  onCloseFun={
                    isAdmin
                      ? () => {
                          // here below two dispatch using same loader in the slice so I reset the add sub user data then called the listing so loder will not stop unexpectedly(before data arrive).
                          if (addSubUserDetails?.success) {
                            handleClose(false);
                            fetchSubUserListing();
                          }
                          dispatch(subUsersActions?.setAddSubUserDetails({}));
                        }
                      : () => {
                          handleClose(false);
                          dispatch(spouseAction.clearAddSpouseDetailsData());
                          getSpouseDetails();
                        }
                  }
                  message={
                    isAdmin
                      ? addSubUserDetails?.message
                      : addSpouseDetails?.message
                  }
                  footer={false}
                />
              ) : (
                <PlainAlertMessage
                  type="Error"
                  openPopup={true}
                  onClose={true}
                  onCloseFun={
                    isAdmin
                      ? () => {
                          if (addSubUserDetails?.success) {
                            handleClose(false);
                            fetchSubUserListing();
                          }
                          dispatch(subUsersActions?.setAddSubUserDetails({}));
                        }
                      : () => {
                          dispatch(spouseAction.clearAddSpouseDetailsData());
                        }
                  }
                  message={
                    (isAdmin
                      ? addSubUserDetails?.data?.message
                      : addSpouseDetails?.data?.message) ||
                    "Something went wrong"
                  }
                  footer={false}
                />
              )
            ) : null}
          </>
        )}
        <Loader open={spouseListLoader} />
      </Dialog>
    </>
  );
};

export default AddSpouseModal;
