import { createSlice } from "@reduxjs/toolkit";
import { encryptPayload } from "../../../../components/helpers/utils";

const initialPropertyRiskManagementState = {
  riskManagementLoader: false,
  riskManagementData: {},
  riskManagementPayload: {},
  countyLoader: false,
  propertyData: {},
  countyData: {},
  monitoredPropertyListLoader: false,
  monitoredPropertyList: {},
  navbarColored: false,
  resendReportMailData: {},
};

const propertyRiskManagement = createSlice({
  name: "propertyRiskManagement",
  initialState: initialPropertyRiskManagementState,
  reducers: {
    propertyRiskManagementData: (state, action) => {
      state.riskManagementLoader = true;
      state.riskManagementPayload = action?.payload?.data;
      console.log("propertyRiskManagement slice", action.payload);
    },
    fetchPropertyRiskManagementData: (state, action) => {
      console.log("fetch propertyRiskManagement data slice", action.payload);
      state.riskManagementLoader = false;
      if (action?.payload?.success) {
        state.propertyData = action.payload;
      }
      state.riskManagementData = action.payload;
    },
    clearPropertyRiskManagementPayload: (state, action) => {
      state.riskManagementPayload = {};
      state.riskManagementLoader = false;
    },
    clearPropertyRiskManagementData: (state, action) => {
      state.propertyData = {};
      state.riskManagementLoader = false;
      state.riskManagementData = {};
    },
    countyData: (state, action) => {
      state.countyLoader = true;
      console.log("countyData slice", action.payload);
    },
    fetchCountyData: (state, action) => {
      console.log("fetch countyData data slice", action.payload);
      state.countyLoader = false;
      if (action.payload?.success) {
        localStorage.setItem("scrp_hp", 1);
        localStorage.setItem(
          "county-code",
          encryptPayload(action.payload?.data?.county_domain_name)
        );
      } else {
        localStorage.removeItem("scrp_hp");
        localStorage.removeItem("county-code");
      }
      state.countyData = action.payload;
    },
    saveEmailDetails: (state, action) => {
      state.riskManagementLoader = true;
      console.log("saveEmailDetails slice", action.payload);
    },
    fetchSaveEmailDetails: (state, action) => {
      console.log("fetcho saveEmailDetails slice", action.payload);
      state.riskManagementLoader = false;
      state.saveEmailDetails = action.payload;
    },
    subscribeFreeMonitoring: (state, action) => {
      state.riskManagementLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    fetchSubscribeFreeMonitoring: (state, action) => {
      console.log("fetcho subscribeFreeMonitoring slice", action.payload);
      state.riskManagementLoader = false;
      state.subscribeFreeMonitoring = action.payload;
    },
    freeMonitoringList: (state, action) => {
      state.monitoredPropertyListLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    fetchFreeMonitoringList: (state, action) => {
      console.log("fetcho subscribeFreeMonitoring slice", action.payload);
      state.monitoredPropertyListLoader = false;
      state.monitoredPropertyList = action.payload;
    },
    deleteMonitoredProperty: (state, action) => {
      state.riskManagementLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    fetchDeletedMonitoredPropertyData: (state, action) => {
      console.log("fetcho deleteMonitoredProperty slice", action.payload);
      state.riskManagementLoader = false;
      state.deleteMonitoredPropertyData = action.payload;
    },
    setNavbarColor: (state, action) => {
      state.navbarColored = action.payload;
    },
    fetchResendReportMailData: (state, action) => {
      state.riskManagementLoader = true;
      console.log("subscribeFreeMonitoring slice", action.payload);
    },
    getResendReportMailData: (state, action) => {
      console.log("fetcho deleteMonitoredProperty slice", action.payload);
      state.riskManagementLoader = false;
      state.resendReportMailData = action.payload;
    },
  },
});
export const propertyRiskManagementAction = propertyRiskManagement.actions;
export default propertyRiskManagement.reducer;
