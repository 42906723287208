import * as React from "react";
import { useEffect } from "react";

import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import { Backdrop, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

import CallUsNow from "../../common/CallUsNow";
import AlertDialog from "../../common/AlertDialog";
import pusher from "../../../notifications/Pusher";
import { getObjLength } from "../../helpers/utils";
import LogoIcon from "../../../assets/dashboard/icon.svg";
import NotificationMenu from "../../layout/notifications";
import Facebook_Icon from "../../../assets/articles/facebook.svg";
import Instagram_Icon from "../../../assets/articles/instagram.svg";
import MenuIconImg from "../../../assets/dashboard/menu_open.svg";
import SidebarLogo from "../../../assets/footer/equity black.png";
import ServicerAccountMenu from "../../layout/servicerAccountMenu";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { notificationActions } from "../../../redux/slices/notification/notificationSlice";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  SERVICE_PROVIDERS_URL,
} from "../../helpers/constants";
import { serviceProviderProfileAction } from "../../../redux/slices/serviceProvider/servicerProfile/servicerProfileSlice";

import "../../../styles/appbar.scss";
import "../../../styles/sidebar.scss";

const drawerWidth = 203;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  border: "none",
  zIndex: "999",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  border: "none",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("md")]: {
    width: "0",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 3),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginLeft: theme.spacing(7),
  width: `calc(100% - ${theme.spacing(8)} - 1.5px)`,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "0",
  },
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 2px rgba(52, 75, 91, 0.3)",
  // zIndex: theme.zIndex.drawer + 1,
  zIndex: "9",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ServicerSideBar({ comp }) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [pusherChannel, setPusherChannel] = React.useState(null);

  // store data
  const { servicerProfileDetails } = useSelector(
    (state) => state?.servicerProfile
  );
  const { reRenderCount } = useSelector((state) => state?.userProfile);

  const handleModalClosing = () => {
    dispatch(userProfileAction.setReRenderCount(reRenderCount + 1));
    if (window.innerWidth < 575) {
      setOpen((prevState) => !prevState);
    }
  };

  const userPreference = (access) => {
    localStorage.setItem("preference", access);
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(
      serviceProviderProfileAction.getServiceProviderProfile({
        url: "service-provider/profile",
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (servicerProfileDetails?.data?.user_id && pusherChannel === null) {
      const channel = pusher
        .subscribe(`App.User.${servicerProfileDetails?.data?.user_id}`)
        .unbind("equity_protect")
        .bind("equity_protect", (data) => {
          dispatch(
            notificationActions?.appendLiveNotifications({
              ...data,
              read_at: null,
              // created_at: new Date(),
            })
          );
          dispatch(notificationActions?.setIsNewNotificationArrive(true));
        });

      setPusherChannel(channel);
    }
  }, [dispatch, pusherChannel, servicerProfileDetails?.data?.user_id]);

  useEffect(() => {
    if (
      getObjLength(servicerProfileDetails) &&
      servicerProfileDetails?.success
    ) {
      if (servicerProfileDetails?.data?.status === "inactive") {
        localStorage.clear();
        localStorage.setItem("preference", true);
        window.location.href = `${SERVICE_PROVIDERS_URL}login`;
      }
    }
  }, [servicerProfileDetails]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          className={open ? "open" : "close"}
        >
          <Toolbar className="toolbar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
            >
              <img
                src={MenuIconImg}
                alt="Menu Icon"
                className="d-none d-md-block"
              />
              <MenuIcon color="action" className="d-block d-md-none" />
            </IconButton>
            <Link
              className="text-white text-center text-decoration-none"
              to="/"
            >
              <img
                src={SidebarLogo}
                alt="Logo"
                className="d-block d-md-none"
                style={{ height: "30px", width: "84px" }}
              />
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ml: "auto",
              }}
            >
              <NotificationMenu />
              <ServicerAccountMenu />
              <div className="px-1 px-md-3">
                <CallUsNow
                  type="help"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                />
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          className={
            open
              ? window.innerWidth < 575
                ? "open position-absolute"
                : "open"
              : window.innerWidth < 575
              ? "close position-absolute"
              : "close"
          }
        >
          <DrawerHeader sx={{ minHeight: "24px" }} className="mt-2">
            <div
              className="sidebarLogoWrapper "
              style={{ width: open ? "80%" : "100%", cursor: "pointer" }}
              // onClick={() => {
              //   navigate("/");
              // }}
            >
              <Link
                className="text-white text-center text-decoration-none"
                to="/"
              >
                <Box sx={{ display: open ? "visible" : "none" }}>
                  <img src={SidebarLogo} alt="Sidebar Logo" />
                </Box>
                <Box sx={{ display: open ? "none" : "visible" }}>
                  <img
                    src={LogoIcon}
                    alt="Logo Icon"
                    style={{ maxHeight: "24px" }}
                  />
                </Box>
              </Link>
            </div>
          </DrawerHeader>

          <List
            className="menuItemsList d-flex flex-column"
            sx={{ flex: "1 0 auto" }}
          >
            <ListItem disablePadding>
              <Divider className="w-100" textAlign="center" sx={{ py: "8px" }}>
                <Typography sx={{ display: open ? "flex" : "none" }}>
                  For Customer Use
                </Typography>
              </Divider>
              {/* <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                opacity: open ? 0 : 1,
                pt: "30px",
                width: "100%",
              }}
            >
              <FiberManualRecordIcon />
            </ListItemIcon> */}
            </ListItem>

            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("documents")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/documents"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DescriptionOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={`menu-item`}
                    primary={"Documents List"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("request-document")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/request-document"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SummarizeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={`menu-item`}
                    primary={"Request Document"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem disablePadding>
              <Divider className="w-100" textAlign="center" sx={{ py: "8px" }}>
                <Typography sx={{ display: open ? "flex" : "none" }}>
                  For Personal Use
                </Typography>
              </Divider>
              {/* <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                opacity: open ? 0 : 1,
                pt: "30px",
                width: "100%",
              }}
            >
              <DoubleArrowIcon />
            </ListItemIcon> */}
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("servicer-properties")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/servicer-properties"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HomeOutlinedIcon sx={{ fontSize: "1.675rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    className={`menu-item`}
                    primary={"Properties"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("servicer-subscriptions")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/servicer-subscriptions"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AccountBalanceWalletOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Subscriptions"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("servicer-documents")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/servicer-documents"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <b>
                      <FolderCopyOutlinedIcon />
                    </b>
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Documents"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("servicer-requested-documents")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/servicer-requested-documents"
              >
                <ListItemButton
                  sx={{
                    width: 250,
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FileOpenOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Requested Document"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname
                    .split("/")
                    .includes("servicer-allow-access")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/servicer-allow-access"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SupervisedUserCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Allow Access"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                className={`text-white text-center text-decoration-none ${
                  window.location.pathname.split("/").includes("servicer-logs")
                    ? "active"
                    : ""
                }`}
                onClick={handleModalClosing}
                to="/servicer-logs"
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: 250,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-item"
                    primary={"Logs"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Stack
            direction={open ? "row" : "column"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            className="text-center border-top pt-2"
            sx={{}}
          >
            <a
              href={INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer"
              className="px-2 pt-2"
            >
              <img
                src={Instagram_Icon}
                alt="Facebook"
                style={{ height: "25px" }}
              />
              {/* <InstagramIcon
                style={{ color: "#E4405F" }}
                fontSize={!open ? "large" : "medium"}
              /> */}
            </a>

            <a
              href={FACEBOOK_LINK}
              target="_blank"
              rel="noreferrer"
              className="px-2 pt-2"
            >
              <img
                src={Facebook_Icon}
                alt="Facebook"
                style={{ height: "25px" }}
              />
              {/* <FacebookIcon fontSize={!open ? "large" : "medium"} /> */}
            </a>
          </Stack>
          <Box
            className="text-center p-0 pb-3"
            p={3}
            sx={{ display: open ? "visible" : "none" }}
          >
            <CallUsNow
              type="text"
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            />
          </Box>
          <Box
            className="text-center p-0 py-2"
            sx={{ display: open ? "none" : "block", py: 3 }}
          >
            <div className="call cursor-pointer">
              <CallUsNow
                type="icon"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              />
            </div>
          </Box>
        </Drawer>
        <Box
          component="main"
          className="main p-4 p-md-5 mt-5 mt-md-0"
          sx={{ flexGrow: 1, overflowX: "hidden" }}
          key={reRenderCount}
        >
          <DrawerHeader sx={{ minHeight: "35px !important" }} />
          {/* {userProfileDetails?.success ? (
          comp
        ) : (
          <Box className="text-center">
            <span
              className="text-danger text-capitalize"
              // onClick={clearUserData}
            >
              {userProfileDetails?.data?.message ? (
                <>
                  {`${userProfileDetails?.data?.message}`}
                  <Link
                    to={"/login"}
                    className="bold text-decoration-none"
                    onClick={clearUserData}
                  >
                    &nbsp;Click here to Login again.
                  </Link>
                </>
              ) : null}
            </span>
          </Box>
        )} */}
          {comp}
        </Box>
        {(localStorage.getItem("preference") === null ||
          localStorage.getItem("preference").length === 0) && (
          <AlertDialog
            type="Warning"
            // width="md"
            openPopup={openAlert}
            onClose={false}
            mesage={
              <Box className="text-start">
                <Typography className=" fw-600 text-center m-3" variant="h6">
                  This website uses cookies
                </Typography>
                <p className="m-4 fw-500">
                  We use essential cookies to make our site work. With your
                  consent, we may also use non-essential cookies to improve user
                  experience, share information about your use of our site with
                  our social media partners, advertising, analytics partners and
                  analyze website traffic. By clicking “ACCEPT” you agree to our
                  website's cookie use. You can change your cookie settings at
                  any time by clicking “Preferences.”
                </p>
              </Box>
            }
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn m-2"
                  color="error"
                  onClick={() => userPreference(false)}
                >
                  Deny
                </Button>
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => userPreference(true)}
                >
                  Accept
                </Button>
              </>
            }
          />
        )}
      </Box>
      {open && window.innerWidth < 575
        ? ReactDOM.createPortal(
            <>
              <Backdrop
                sx={{ zIndex: 995 }}
                open={open}
                onClick={() => {
                  setOpen((prevState) => !prevState);
                }}
              />
            </>,
            document.body
          )
        : null}
    </>
  );
}
