import React from "react";

const ThreeIconSVG = () => {
  return (
    <>
      <svg
        width="32"
        height="51"
        viewBox="0 0 32 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="1"
          d="M15.76 22.56C19.12 22.56 21.7333 21.7433 23.6 20.11C25.5133 18.43 26.47 16.26 26.47 13.6V12.69C26.47 9.93667 25.56 7.81333 23.74 6.32C21.92 4.82667 19.5867 4.08 16.74 4.08C13.8933 4.08 11.6067 4.66333 9.88 5.83C8.2 6.95 6.84667 8.49 5.82 10.45L2.67 8.21C3.23 7.18333 3.93 6.20333 4.77 5.27C5.61 4.29 6.59 3.42666 7.71 2.68C8.87667 1.93333 10.2067 1.35 11.7 0.929998C13.1933 0.509997 14.8967 0.299997 16.81 0.299997C18.8167 0.299997 20.6833 0.579998 22.41 1.14C24.1367 1.7 25.63 2.51666 26.89 3.59C28.15 4.61666 29.13 5.9 29.83 7.44C30.5767 8.93333 30.95 10.6367 30.95 12.55C30.95 15.8633 30.04 18.4533 28.22 20.32C26.4 22.1867 24.1367 23.4467 21.43 24.1V24.38C22.8767 24.6133 24.23 25.0567 25.49 25.71C26.75 26.3167 27.8467 27.1333 28.78 28.16C29.76 29.1867 30.53 30.4233 31.09 31.87C31.65 33.3167 31.93 34.95 31.93 36.77C31.93 38.87 31.5567 40.8067 30.81 42.58C30.0633 44.3067 28.99 45.7767 27.59 46.99C26.2367 48.2033 24.58 49.16 22.62 49.86C20.7067 50.5133 18.56 50.84 16.18 50.84C12.26 50.84 9.08667 50.0933 6.66 48.6C4.23333 47.06 2.25 45.1467 0.71 42.86L3.86 40.48C5.21333 42.58 6.82333 44.2133 8.69 45.38C10.6033 46.5 13.0767 47.06 16.11 47.06C19.6567 47.06 22.4333 46.1967 24.44 44.47C26.4467 42.6967 27.45 40.27 27.45 37.19V36.07C27.45 32.99 26.47 30.5867 24.51 28.86C22.55 27.0867 19.75 26.2 16.11 26.2H9.95V22.56H15.76Z"
          fill="#02a8e2"
        />
      </svg>
    </>
  );
};

export default ThreeIconSVG;
