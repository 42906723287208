import React, { useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import DialogBox from "../common/DialogBox";
import { ROLES } from "../helpers/constants";
import FileUpload from "../common/FileUpload";
import PropertySelection from "./propertySelection";
import CustomLinkWrapper from "../common/CustomLinkWrapper";
import addTagsIcon from "../../assets/documents/add_circle.svg";
import { createFileUrl, decryptPayload } from "../helpers/utils";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";

const UploadDocuments = ({
  openPopup,
  setOpenPopup,
  closeDialogBox,
  propertiesData,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedPropetyId, setSelectedPropetyID] = useState(null);
  const [propetySelectErr, setPropetySelectErr] = useState("");
  const [goNext, setGoNext] = useState(false);
  const [tags, setTags] = useState([]);
  const { loader, propertyListLoader } = useSelector(
    (state) => state.documents
  );

  function modalTitle() {
    return (
      <Box className="accountBoxWrapper text-start p-0">
        <Typography variant="h3" className="px-2">
          Upload document(s)
        </Typography>
        <Typography
          variant="body1"
          className="m-0 px-2"
          sx={{ maxWidth: "100% !important" }}
        >
          Upload the needed documents of the related properties.
        </Typography>
      </Box>
    );
  }
  function modalContent() {
    return (
      <>
        {selectedFile ? (
          <>
            <Box className="preview-file bg-white" p={0.6} m={0.5}>
              <CustomLinkWrapper
                link={selectedFile ? createFileUrl(selectedFile) : "#"}
                title={selectedFile?.path}
                key={selectedFile?.path}
                classes="preview-url"
              />
              <span
                className="cursor-pointer"
                onClick={() => {
                  setSelectedFile(null);
                  // resetForm();
                }}
              >
                <CloseOutlinedIcon />
              </span>
            </Box>
            <Box className="bg-white" p={0.6} m={0.5}>
              <Formik
                enableReinitialize
                initialValues={{ inputTag: "" }}
                onSubmit={submitTags}
              >
                {({ values, setFieldValue, resetForm }) => {
                  return (
                    <Form>
                      <Box px={2}>
                        <OutlinedInput
                          fullWidth
                          name="inputTag"
                          onChange={(event) => {
                            setFieldValue("inputTag", event.target.value);
                          }}
                          id="outlined-adornment-weight"
                          inputProps={<TextField />}
                          value={values?.inputTag}
                          placeholder="Add tags here"
                          endAdornment={
                            <InputAdornment position="end">
                              <Button type="submit">
                                <img src={addTagsIcon} alt="addTags" />
                              </Button>
                            </InputAdornment>
                          }
                        />
                        <Box pt={2} sx={{ textAlign: "start" }}>
                          {tags.map((tag, i) => (
                            <Chip
                              key={i}
                              sx={{
                                backgroundColor: "#C4D9CC",
                                margin: 1,
                              }}
                              className="fw-500 text-uppercase"
                              label={tag}
                              onDelete={() => handleDelete(i)}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </>
        ) : (
          <Box className="m-0">
            {!goNext ? (
              <>
                <PropertySelection
                  propertySelected={setSelectedPropetyID}
                  propertiesData={
                    propertiesData?.data ? propertiesData?.data : []
                  }
                  propetySelectErr={propetySelectErr}
                  setPropetySelectErr={setPropetySelectErr}
                />
              </>
            ) : (
              <FileUpload
                name="upload_doc"
                accept={{
                  "image/png": [".png", ".jpeg", ".jpg"],
                  "image/gif": [".gif"],
                  "application/vnd.ms-powerpoint": [".ppt"],
                  "application/pdf": [".pdf"],
                  "application/msword": [".doc"],
                  "application/vnd.ms-excel": [".xls"],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [".xlsx"],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".docx"],
                  "text/csv": [".csv"],
                }}
                maxFiles={1}
                multiple={false}
                onFileUpload={async (file) => {
                  setSelectedFile(file[0]);
                }}
              />
            )}
          </Box>
        )}
        {/* <Previews /> */}
      </>
    );
  }

  function modalFooter() {
    return (
      <Box className="text-end createAcBtn my-3 mx-2">
        {!goNext ? (
          <Button
            // disabled={!selectedFile ? true : false}
            onClick={() => uploadFileScreen()}
            type="submit"
            className="next_button"
            variant="contained"
          >
            Next
          </Button>
        ) : (
          <Button
            disabled={!selectedFile ? true : false}
            onClick={() => onClickSubmit()}
            type="submit"
            className="next_button"
            variant="contained"
          >
            upload
          </Button>
        )}
      </Box>
    );
  }

  const handleDelete = (i) => {
    console.log("delete==>>", i);

    if (i > -1) {
      tags.splice(i, 1);
      setTags([...tags]);
    }
    console.log("remove tags==>>", tags);
  };

  const uploadFileScreen = () => {
    if (!selectedPropetyId) {
      setPropetySelectErr("Please Select any Propety.");
    } else {
      setPropetySelectErr("");
      setGoNext(true);
    }
  };
  const onClickSubmit = () => {
    dispatch(
      documentsAction.uploadDocument({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/documents/upload`,
        data: {
          document: selectedFile,
          property_id: selectedPropetyId,
          tags: tags,
        },
        token: true,
        isFile: true,
      })
    );
    closeDialogBox();
  };

  const submitTags = (values, { resetForm }) => {
    console.log("inputTag==>>", values);
    if (values.inputTag.length > 0) {
      setTags([...tags, values.inputTag]);
      resetForm({ values: "" });
      // setInputTag("");
    }
  };
  console.log("submitTags==>", tags);
  return (
    <>
      <DialogBox
        width={"sm"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={closeDialogBox}
        dividers={true}
        title={modalTitle()}
        content={modalContent()}
        footer={modalFooter()}
      />

      <Loader open={propertyListLoader} />
    </>
  );
};

export default UploadDocuments;
