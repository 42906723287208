import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Grid, Typography } from "@mui/material";

const AssessmentTaxTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.properties?.userViewDetailsPropertyData
  );

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={4} mb={2}>
            <Row>
              <Col className="label-text">
                <Typography>Assessed Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Assessed Value"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Land Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>-</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Improvement Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Improvement Value"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Percent Improvement: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Percent Improvement"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Market Land Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Market Land Value"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Market Imp. Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.[
                    "Market Imp. Value"
                  ] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Market Value: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Market Value"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Row>
              <Col className="label-text">
                <Typography>Tax Amount: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {!isNaN(
                    propertyData?.property_details?.TaxInfo?.["Tax Amount"]
                  )
                    ? propertyData?.property_details?.TaxInfo?.["Tax Amount"]
                    : "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Tax Rate Area: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Tax Area"] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Tax Year: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Tax Year"] || "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Assessed Year: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Assessed Year"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Tax Status: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Tax Status"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col className="label-text">
                <Typography>Exemption: </Typography>
              </Col>
              <Col className="value-text">
                <Typography>
                  {propertyData?.property_details?.TaxInfo?.["Exemption"] ||
                    "-"}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AssessmentTaxTab;
