import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";

import { Loader } from "../../common/loader";
import CreditCardCRUD from "./CreditCardCRUD";
import AlertDialog from "../../common/AlertDialog";
import logo from "../../../assets/lennar/ep-logo.svg";
import CustomTextField from "../../common/CustomTextField";
import PlanStepsImg from "../../../assets/lennar/Union.webp";
import SecurePaymentPanel from "../../common/SecurePaymentPanel";
import { DEFAULT_URL, PROVIDERSTYPE, ROLES } from "../../helpers/constants";
import StanderedStripeCardElement from "../../common/StanderedStripeCardElement";
import { lennarSubscriptionAction } from "../../../redux/slices/lennar/subscriptionSlice";
import { subscriptionAction } from "../../../redux/slices/subscription/subscriptionSlice";
import {
  calulateDatefromDaysLater,
  currencyFormatter,
  decryptPayload,
  encryptPayload,
  getObjLength,
} from "../../helpers/utils";

import "../../../styles/lennarSubscription.scss";

const LennarSubscription = ({
  selectedPlan,
  dynamicLogo,
  path,
  totalAmountDetail = {},
  setPaymentPage = () => {},
  renewalAmount,
  fetchCustomDomain = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams] = useSearchParams();

  // states
  const [stripeCardError, setStripeCardError] = useState("");
  const [open, setOpen] = useState(false);
  const [cardData, setCardData] = useState({});
  const [isCardsAdded, setIsCardsAdded] = useState(false);

  // store data
  const {
    lennarSubscriptionDetails,
    lennarLoader,
    lennarPropertyList,
    cardList,
    cardLoader,
  } = useSelector((state) => state?.lennarSubscription);

  const today = new Date();

  // Calculate the date that is 60 days from today
  const sixtyDaysFromToday = new Date(today);
  sixtyDaysFromToday.setDate(today.getDate() + 60);

  console.log(
    renewalAmount,
    "selectedPlan?.trial_period?.month==>",
    selectedPlan
  );

  console.log("cardList==>", cardList);

  const onSuccessFun = () => {
    if (lennarSubscriptionDetails?.success) {
      // to redirect on equityproetect portal
      if (params?.provider_type?.includes(PROVIDERSTYPE?.lennar)) {
        localStorage.setItem("roles", encryptPayload(ROLES.lennar));
      }
      const goToAdmin = decryptPayload(localStorage.getItem("goToAdmin"));
      if (goToAdmin?.includes("admin")) {
        window.location.href =
          DEFAULT_URL +
          goToAdmin +
          `?from=${ROLES?.provider}&user_id=` +
          decryptPayload(localStorage.getItem("user_id"));
      } else {
        if (fetchCustomDomain) {
          navigate(`/payment-done`);
        } else {
          navigate(`/${path}/payment-done`);
        }
      }
    }
    dispatch(lennarSubscriptionAction.setCardDetails({}));
    dispatch(subscriptionAction.clearFailedPaymentData({}));
  };

  const onFailedFun = () => {
    dispatch(subscriptionAction.clearTotalAmountData({}));
    dispatch(subscriptionAction.clearFailedPaymentData({}));
    dispatch(lennarSubscriptionAction.setCardDetails({}));
  };

  const handleOpenAddNewCardModal = (flag) => {
    setIsCardsAdded(flag);
    // dispatch(lennarSubscriptionAction?.fetchUserCardDetails({}));
  };

  useEffect(() => {
    if (getObjLength(cardList) && cardList?.data?.length > 0) {
      setIsCardsAdded(true);
    } else {
      setIsCardsAdded(false);
    }
  }, [dispatch, cardList]);

  // defualt scroll bottom to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Box className="lennar-subscription">
        <Grid container>
          <Grid item md={4.5} className="property_img">
            <Navbar.Brand>
              <Stack direction={"row"} spacing={1} justifyContent={"center"}>
                {dynamicLogo && (
                  <img
                    src={dynamicLogo}
                    className="lennar-logo"
                    alt="lennar-logo"
                  />
                )}
                <img src={logo} className="lennar-logo" alt="lennar-logo" />
              </Stack>
            </Navbar.Brand>

            <Box
              className="lennar-subscription-features-box content-spacing"
              gap={2}
            >
              <Typography className="feature-heading">
                Protecting your largest investment from Home Title Theft is our
                only goal. Enroll today and enjoy the following benefits:
              </Typography>
              <ul
                className="lennar-features_list mt-3"
                style={{ listStyleType: "none", textAlign: "left" }}
              >
                <li className="position-relative mb-3">
                  Physical Protection prevents the crime from occurring
                </li>
                <li className="position-relative mb-3">
                  24/7 title monitoring
                </li>
                <li className="position-relative mb-3">
                  Utilization of Multifactor authentication before allowing
                  permission to move forward with a new refinance or sale
                </li>
                <li className="position-relative mb-3">
                  Our "Duty to Defend" SmartPolicy Insurance covers 100% of the
                  cost of defense should an issue arise.
                </li>
                <li className="position-relative mb-3">
                  Ability to create 'sub-user' accounts for properties managed
                  by family members or business partners
                </li>
                <li className="position-relative mb-3">
                  24/7 risk assessment and quarterly reports
                </li>
                <li className="position-relative mb-3">Urgent fraud alerts</li>
                <li className="position-relative mb-3">Title restoration</li>
              </ul>
            </Box>
          </Grid>
          <Grid item md={7.5} className="w-100 right-grid">
            <Box className="lennar-subscription-box content-spacing">
              <Typography className="lennar-subscription-heading">
                Create your account today and have complete protection for only
                a few dollars a month
              </Typography>

              <Stack direction="row" spacing={2} className="subscription-steps">
                {params?.provider_type?.includes(PROVIDERSTYPE?.lennar) ? (
                  <>
                    <img
                      alt="plan-steps"
                      // width="30px"
                      style={{
                        width: "30px",
                        height: "130px",
                        margin: "8px 0px",
                        aspectRatio: 3 / 2,
                        objectFit: "contain",
                      }}
                      src={PlanStepsImg}
                    />
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      spacing={2.5}
                    >
                      <Typography className="subscription-plan-text">
                        Signup fee:{" "}
                        <b>{currencyFormatter(selectedPlan?.amount || 0)}</b>
                      </Typography>
                      <Typography className="subscription-plan-text">
                        {selectedPlan?.trial_period?.day || 0}-Day Free Trial
                      </Typography>
                      <Typography className="subscription-plan-text">
                        <b>$12.99</b> monthly starting on{" "}
                        <b>
                          {`${
                            lennarPropertyList?.data?.plan_pricing?.created_at
                              ? calulateDatefromDaysLater(
                                  //lennarPropertyList?.data?.properties?.[0]?.created_at,
                                  new Date(),
                                  lennarPropertyList?.data?.plan_pricing
                                    ?.trial_period?.day || 0
                                ) + `*`
                              : `-`
                          }`}
                        </b>
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    spacing={2.5}
                  >
                    {" "}
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {parseInt(selectedPlan?.trial_period?.day) > 0 && (
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot className="p-2" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography className="subscription-plan-text">
                              {selectedPlan?.trial_period?.day || 0}-Day Free
                              Trial
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      )}
                      <TimelineItem className="pt-1">
                        <TimelineSeparator>
                          <TimelineDot className="p-2" />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography className="subscription-plan-text">
                            <b>
                              {selectedPlan?.trial_period?.day > 0
                                ? currencyFormatter(renewalAmount || 0)
                                : currencyFormatter(
                                    parseFloat(selectedPlan?.amount || 0) +
                                      parseFloat(
                                        selectedPlan?.additional_charges?.[
                                          "setup fees"
                                        ] || 0
                                      ) || 0
                                  )}
                            </b>{" "}
                            {selectedPlan?.name} starting on{" "}
                            <b>
                              {`${
                                lennarPropertyList?.data?.plan_pricing
                                  ?.created_at
                                  ? calulateDatefromDaysLater(
                                      // lennarPropertyList?.data?.properties?.[0]?.created_at,
                                      new Date(),
                                      selectedPlan?.trial_period?.day || 0
                                    ) + `*`
                                  : `-`
                              }`}
                            </b>
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </Stack>
                )}
              </Stack>

              {/* subscription box */}
              <Formik
                enableReinitialize
                validateOnChange
                initialValues={{
                  cardholder_name: "",
                }}
                validationSchema={
                  isCardsAdded
                    ? null
                    : Yup.object().shape({
                        cardholder_name: Yup.string(
                          "Please enter your Card Holder Name."
                        )
                          .trim(
                            "The Card Holder Name cannot include leading and trailing spaces"
                          )
                          .strict(true)
                          .required("Card Holder Name is required"),
                      })
                }
                onSubmit={async (values, validation) => {
                  console.log(isCardsAdded, "values", values);

                  if (isCardsAdded) {
                    let data = {
                      card_id: cardData?.[0]?.id,
                      authToken: searchParams?.get("token"),
                      plan_id:
                        // lennarPropertyList?.data?.plan_pricing?.id ||
                        selectedPlan?.id,
                    };
                    console.log("stripe token", data);
                    // handle submit call API
                    dispatch(
                      lennarSubscriptionAction?.addCard({
                        url: `providers/${path}/cards`,
                        data: data,
                      })
                    );
                  } else {
                    if (!stripe || !elements) {
                      // Stripe.js has not yet loaded.
                      // Make  sure to disable form submission until Stripe.js has loaded.
                      return;
                    }
                    setOpen(true);
                    const card = elements.getElement(CardElement);
                    const result = await stripe.createToken(card, {
                      name: values?.cardholder_name,
                    });
                    // console.log("result: ", result);
                    if (result.error || !searchParams?.get("token")) {
                      // console.error(result?.error?.message);
                      setStripeCardError(
                        result?.error?.message ||
                          "Authentication Token is not available"
                      );
                      setOpen(false);
                    } else {
                      let data = {
                        token: result?.token?.id,
                        authToken: searchParams?.get("token"),
                        plan_id:
                          // lennarPropertyList?.data?.plan_pricing?.id ||
                          selectedPlan?.id,
                      };
                      console.log("stripe token", data);
                      setStripeCardError("");

                      // handle submit call API
                      dispatch(
                        lennarSubscriptionAction?.addCard({
                          url: `providers/${path}/cards`,
                          data: data,
                        })
                      );
                      setOpen(false);
                    }
                  }
                }}
              >
                {({
                  values,
                  setFieldValue,
                  touched,
                  errors,
                  setTouched,
                  isSubmitting,
                }) => {
                  return (
                    <>
                      <Form className="w-100">
                        <Box className="bg-white subscription-details content-spacing">
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}
                            alignItems="center"
                            pb={3}
                            sx={{ color: "#15BE53", fontFamily: "Mabry Pro" }}
                          >
                            <span>Secure Connection</span>
                            <HttpsOutlinedIcon />
                          </Stack>
                          <Box className="d-flex align-items-center justify-content-between">
                            <Typography className="subscription-main-text">
                              {isCardsAdded
                                ? "Payment Information"
                                : "Please enter your card details"}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                              {isCardsAdded ? (
                                <>
                                  <Button
                                    className="popup_Btn success_popup_btn d-none d-sm-block"
                                    variant="outlined"
                                    sx={{
                                      fontFamily: "Mabry Pro",
                                    }}
                                    onClick={() =>
                                      handleOpenAddNewCardModal(false)
                                    }
                                  >
                                    Pay with new Card
                                  </Button>
                                  <Button
                                    className="popup_Btn success_popup_btn mx-1 d-flex d-sm-none"
                                    variant="outlined"
                                    onClick={() =>
                                      handleOpenAddNewCardModal(false)
                                    }
                                    startIcon={<AddOutlinedIcon />}
                                  >
                                    Card
                                  </Button>
                                </>
                              ) : cardList?.data?.length > 0 ? (
                                <>
                                  <Button
                                    className="popup_Btn success_popup_btn d-none d-sm-block"
                                    variant="outlined"
                                    sx={{
                                      fontFamily: "Mabry Pro",
                                    }}
                                    onClick={() =>
                                      handleOpenAddNewCardModal(true)
                                    }
                                  >
                                    Card List
                                  </Button>
                                  <Button
                                    className="popup_Btn success_popup_btn d-flex d-sm-none"
                                    variant="outlined"
                                    sx={{
                                      fontFamily: "Mabry Pro",
                                    }}
                                    onClick={() =>
                                      handleOpenAddNewCardModal(true)
                                    }
                                  >
                                    List
                                  </Button>
                                </>
                              ) : null}
                            </Stack>
                          </Box>
                          {isCardsAdded ? (
                            <CreditCardCRUD cardData={setCardData} />
                          ) : (
                            <Box my="40px">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12} px={1} pb={1}>
                                  <CustomTextField
                                    // label="Cardholder name"
                                    name="cardholder_name"
                                    validation={{
                                      values,
                                      setFieldValue,
                                      touched,
                                      errors,
                                    }}
                                    classes="standard-field"
                                    otherProps={{
                                      variant: "standard",
                                      placeholder: "Cardholder name",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  px={1}
                                  pb={1}
                                  className="lennar-card"
                                >
                                  <StanderedStripeCardElement />
                                </Grid>
                                {stripeCardError &&
                                  stripeCardError?.length > 0 && (
                                    <FormHelperText
                                      sx={{
                                        color: "rgb(211, 47, 47) !important",
                                        fontSize: "12px !important",
                                        m: "0px !important",
                                        pl: "24px !important",
                                        maxWidth: "100% !important",
                                      }}
                                    >
                                      {stripeCardError}
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </Box>
                          )}
                        </Box>

                        <Stack
                          className="w-100 subscription-summery content-spacing"
                          spacing={2}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Typography className="total-subscription-text">
                              Subtotal
                            </Typography>
                            <Typography className="total-subscription-text">
                              {selectedPlan?.trial_period?.day > 0
                                ? currencyFormatter(
                                    totalAmountDetail?.data?.discount || 0
                                  )
                                : currencyFormatter(
                                    totalAmountDetail?.data?.total || 0
                                  )}
                            </Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography className="total-subscription-text">
                              Discount
                            </Typography>
                            <Typography className="total-subscription-text">
                              -
                              {selectedPlan?.trial_period?.day > 0
                                ? currencyFormatter(
                                    totalAmountDetail?.data?.discount || 0
                                  )
                                : "$0.00"}
                            </Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography className="total-subscription-text total-subscription-amount">
                              Billed now
                            </Typography>
                            <Typography className="total-subscription-text total-subscription-amount">
                              {selectedPlan?.trial_period?.day > 0
                                ? "$0.00"
                                : currencyFormatter(
                                    totalAmountDetail?.data?.total || 0
                                  )}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack
                          className="bg-white lennar-subscription-submit-section content-spacing"
                          spacing={2}
                        >
                          <Stack
                            className="bg-white lennar-subscription-submit-section content-spacing"
                            spacing={2}
                            direction={{ xs: "column", sm: "row" }}
                          >
                            <Button
                              variant="contained"
                              disabled={!stripe}
                              sx={{ alignItems: "center !important" }}
                              className="popup_Btn d-flex lennar_back_btn w-100"
                              onClick={() => setPaymentPage(false)}
                            >
                              Back
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              disabled={!stripe}
                              className="popup_Btn lennar_popup_btn w-100"
                            >
                              Add card securely
                            </Button>
                          </Stack>

                          {selectedPlan?.trial_period?.day > 0 && (
                            <Typography className="cancel-text">
                              *Cancel anytime
                              {lennarPropertyList?.data?.plan_pricing
                                ?.created_at
                                ? ` before ` +
                                  calulateDatefromDaysLater(
                                    lennarPropertyList?.data?.properties?.[0]
                                      ?.created_at,
                                    selectedPlan?.trial_period?.day || 0
                                  )
                                : ""}
                              , and we will not continue charging your account
                            </Typography>
                          )}
                        </Stack>
                      </Form>
                    </>
                  );
                }}
              </Formik>
              <a
                alt="terms-and-condition"
                className="terms-and-conditions"
                href={`${DEFAULT_URL}api/service_agreement_2023.pdf`}
                target={"_blank"}
                rel="noreferrer"
              >
                Terms and Conditions
              </a>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {getObjLength(lennarSubscriptionDetails) &&
      !lennarSubscriptionDetails?.data?.actionRequired ? (
        <AlertDialog
          type={lennarSubscriptionDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            // props.reduceErrorCount();
            onSuccessFun();
          }}
          mesage={
            lennarSubscriptionDetails?.success
              ? lennarSubscriptionDetails?.message
              : lennarSubscriptionDetails?.data?.message
          }
        />
      ) : null}

      <SecurePaymentPanel
        displayLabel={false}
        cardData={null}
        paymentData={lennarSubscriptionDetails}
        onSuccessFun={onSuccessFun}
        onFailedFun={onFailedFun}
        cardList={false}
        userId={null}
        // successMsg={successMsg()}
        successClose={true}
        path={path}
      />

      <Loader open={lennarLoader || open} />
    </>
  );
};

export default LennarSubscription;
