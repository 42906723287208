import React from "react";

const PropertiesIconSVG = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_440_1926"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_440_1926)">
          <path
            d="M4.5 20.5V9.25L12 3.625L19.5 9.25V20.5H13.925V13.8H10.075V20.5H4.5ZM6 19H8.575V12.3H15.425V19H18V10L12 5.5L6 10V19Z"
            fill="#9CB8A6"
          />
        </g>
      </svg>
    </>
  );
};

export default PropertiesIconSVG;
