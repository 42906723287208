import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import { MenuProps } from "../../helpers/constants";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";

const ChangeSubscriptionPlanModal = ({
  open,
  handleClose,
  selectedProperty,
  handleChangeSubscription,
}) => {
  const dispatch = useDispatch();

  // store data
  const { changeSubscriptionPlanList, changeSubscriptionLoader } = useSelector(
    (state) => state?.propertyLists
  );

  useEffect(() => {
    if (open) {
      let county_details = selectedProperty?.owner?.[0]?.county_details;
      let URL = `admin/plans/${selectedProperty?.subscription?.id}`;
      if (getObjLength(county_details)) {
        URL = `admin/plans/${selectedProperty?.subscription?.id}/${county_details?.id}`;
      }
      dispatch(
        propertyListAction?.getChangeSubscriptionPlanList({
          url: URL,
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, open, selectedProperty?.subscription?.id]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            overflow: "visible",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Change Subscription
          <Button
            onClick={handleClose}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <>
          <DialogContent dividers sx={{ overflow: "visible" }}>
            {changeSubscriptionPlanList?.success ? (
              <Box
                className="bg-white d-flex justify-content-center bg-white"
                p={1}
              >
                <Formik
                  enableReinitialize
                  validateOnChange
                  initialValues={{
                    subscription: "",
                  }}
                  validationSchema={Yup.object().shape({
                    subscription: Yup.string()
                      .trim()
                      .required("Please Selecte Any Plan"),
                  })}
                  // onSubmit={handleBindProfessional}
                  onSubmit={handleChangeSubscription}
                >
                  {({ values, setFieldValue, touched, errors }) => {
                    return (
                      <Form className="w-100">
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} p={1}>
                            <FormControl
                              fullWidth
                              className="sign-textField "
                              error={
                                touched.subscription &&
                                Boolean(errors.subscription)
                              }
                            >
                              <InputLabel label="State">
                                Subscription
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-placeholder"
                                id="demo-simple-select"
                                value={values?.subscription}
                                label="State"
                                name="subscription"
                                variant="outlined"
                                onChange={(event) => {
                                  setFieldValue(
                                    "subscription",
                                    event.target.value
                                  );
                                }}
                                MenuProps={MenuProps}
                              >
                                {changeSubscriptionPlanList?.data?.map(
                                  (subscription) => (
                                    <MenuItem value={subscription?.id}>
                                      {subscription?.name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              {touched.subscription &&
                                Boolean(errors.subscription) && (
                                  <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {touched.subscription &&
                                      errors.subscription}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>

                        <DialogActions>
                          <Stack direction="row" spacing={1}>
                            <Button
                              className="popup_Btn other_popup_btn"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="popup_Btn success_popup_btn"
                              type="submit"
                            >
                              Update
                            </Button>
                          </Stack>
                        </DialogActions>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            ) : (
              <Box
                className="bg-white d-flex justify-content-center bg-white"
                p={1}
              >
                <p className="text-danger">
                  {changeSubscriptionPlanList?.data?.message}
                </p>
              </Box>
            )}
          </DialogContent>
        </>
        <Loader open={changeSubscriptionLoader} />
      </Dialog>
    </>
  );
};

export default ChangeSubscriptionPlanModal;
