import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Settings } from "@mui/icons-material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import { PER_PAGE, ROLES } from "../helpers/constants";
import SettingsIconSVG from "../common/SettingsIconSVG";
import CustomLinkWrapper from "../common/CustomLinkWrapper";
import AddSubscriptionModal from "../common/AddSubscriptionModal";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";
import UploadRequestDocModal from "../admin/AdminDashboard/UploadRequestDocModal";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  decryptPayload,
  getLocalFormatedDate,
  getObjLength,
  getURLName,
} from "../helpers/utils";

const columns = [
  {
    id: "number",
    disablePadding: true,
    label: "No.",
    value: "no",
    minWidth: 60,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
    value: "property",
    minWidth: 120,
  },
  {
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 60,
  },
  {
    id: "county",
    disablePadding: true,
    label: "County",
    value: "county",
    minWidth: 60,
  },
  {
    id: "Zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 60,
  },
  {
    id: "transaction_id",
    disablePadding: true,
    label: "Transaction id",
    value: "transaction_id",
    minWidth: 130,
  },
  // {
  //   id: "resubscribe",
  //   disablePadding: true,
  //   label: "Property Resubscribe",
  //   value: "agree",
  //   minWidth: 60,
  // },
  {
    id: "terminated_at",
    disablePadding: true,
    label: "Terminated at",
    value: "terminated_at",
    minWidth: 130,
  },
  {
    id: "uploaded_doc",
    disablePadding: true,
    label: "Uploaded Document",
    value: "uploaded_doc",
    minWidth: 230,
  },
  {
    id: "action",
    disablePadding: true,
    label: "Action",
    value: "action",
    minWidth: 70,
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TerminatedProperties = () => {
  const dispatch = useDispatch();

  // states
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyResubscribePopup, setPropertyResubscribePopup] =
    useState(false);
  const [uploadDocPopup, setUplaodDocPopup] = useState(false);
  const [renewedPopup, setRenewedPopup] = useState(false);
  const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] =
    useState(false);

  // store data
  const {
    terminationDocLoader,
    terminatedPropertiesData,
    uploadedDocData,
    resubscribePropertyData,
  } = useSelector((state) => state?.documents);

  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );

  const { addSubscriptionDetails, addSubscriptionLoader } = useSelector(
    (state) => state?.properties
  );

  const { loginAsSpouse: isSecondaryUser, parent_id } = useSelector(
    (state) => state?.userProfile
  );

  const _DATA = terminatedPropertiesData?.success
    ? terminatedPropertiesData?.data
    : [];
  const count = Math.ceil(terminatedPropertiesData?.count / PER_PAGE);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // upload documentation
  const uploadedPropertyDocuments = (row) => {
    setUplaodDocPopup(true);
  };

  const handleCloseModal = () => {
    dispatch(documentsAction.fetchUploadedDocData({}));
    setUplaodDocPopup(false);
  };

  const handleUploadDocuments = (values) => {
    const uploadedDocs = values.upload_doc;

    const formData = new FormData();

    // formData.append("id", selectedProperty.id.toString());
    // uploadedDocs.map((doc, index) =>
    formData.append(`document`, uploadedDocs);
    // );
    // formData.append("property_id", selectedProperty?.property_id);

    // Display the values

    for (const value of formData.values()) {
      console.log(value);
    }

    dispatch(
      documentsAction.getUploadedDocData({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/upload-document/${selectedProperty?.id}/terminate`,
        data: formData,
        token: true,
        isFile: true,
        message: "Termination document uploaded",
      })
    );
  };

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  // handle RE-Subscription
  const handleReSubscription = () => {
    console.log("selectedProperty==>", selectedProperty);
    // if (selectedProperty?.subscription?.expired) {
    setIsAddSubscriptionModalOpen(true);
    // } else {
    //   setIsAddSubscriptionModalOpen(false);
    //   setPropertyResubscribePopup(false);
    //   dispatch(
    //     propertiesAction.addPropertySubscription({
    //       url: `${
    //         decryptPayload(localStorage.getItem("roles"))?.includes(
    //           ROLES.serviceProvider
    //         )
    //           ? "service-provider"
    //           : "customer"
    //       }/terminated/continue-subscription`,
    //       data: { property_id: selectedProperty?.id },
    //       token: true,
    //     })
    //   );
    // }
  };

  // handle close cancel subscription modal
  const handleCloseAddSubscriptionModal = () => {
    setIsAddSubscriptionModalOpen(false);
    setSelectedProperty(null);
    setPropertyResubscribePopup(false);
    getTerminatedPropertiesLists();
  };

  //   get Documnets details
  const getTerminatedPropertiesLists = useCallback(() => {
    let URL = `${
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
        ? "service-provider"
        : "customer"
    }/terminated-properties`;

    dispatch(
      documentsAction.getTerminatedPropertiesData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          user_id: isSecondaryUser ? parseInt(parent_id) : undefined,
        },
        token: true,
      })
    );
  }, [dispatch, isSecondaryUser, parent_id, payload?.offset]);

  const onSuccessFun = () => {
    getTerminatedPropertiesLists();
    dispatch(propertiesAction.setAddPropertySubscription({}));
  };

  const onFailedFun = () => {
    dispatch(propertiesAction.setAddPropertySubscription({}));
  };

  useEffect(() => {
    getTerminatedPropertiesLists();
  }, [getTerminatedPropertiesLists]);

  useEffect(() => {
    if (!isSecondaryUser) {
      dispatch(
        userSigupAction.getSubscriptionPlan({
          url: "get-plans",
          data: {},
          type: "post",
        })
      );
      dispatch(
        userProfileAction.userCardDetails({
          url: `${
            decryptPayload(localStorage.getItem("roles"))?.includes(
              ROLES.serviceProvider
            )
              ? "service-provider"
              : "customer"
          }/card/list`,
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, isSecondaryUser, userData?.role]);

  useEffect(() => {
    console.log("resubscribePropertyData==>", resubscribePropertyData);
    if (getObjLength(resubscribePropertyData)) {
      setRenewedPopup(true);
    }
  }, [resubscribePropertyData]);

  return (
    <>
      {getObjLength(terminatedPropertiesData) ? (
        terminatedPropertiesData?.success ? (
          <>
            {/* mobile view */}
            <Box className="d-block d-lg-none mobile-table bg-white px-0 mb-3 py-3">
              {_DATA?.map((row, index) => {
                return (
                  <>
                    <Stack
                      direction="column"
                      spacing={1.2}
                      px={2}
                      className="position-relative"
                    >
                      <Stack
                        direction="column"
                        spacing={1.2}
                        pr={2}
                        justifyContent="center"
                        className="position-relative"
                      >
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMenuClick(event);
                            setSelectedProperty(row);
                          }}
                          size="small"
                          sx={{
                            color: "#15BE53",
                            position: "absolute",
                            right: "0px",
                          }}
                          className="settings-button"
                        >
                          <Settings />
                        </IconButton>

                        <Typography
                          className="value fw-500"
                          sx={{ fontSize: "15px !important" }}
                        >
                          {row?.property?.address || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">City: </Typography>
                        <Typography className="value">
                          {row?.city || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Country: </Typography>
                        <Typography className="value">
                          {row?.property?.county || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Zip: </Typography>
                        <Typography className="value">
                          {row?.zip || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">
                          {" "}
                          Transaction id:
                        </Typography>
                        <Typography className="value">
                          {row?.transaction_id || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key"> Terminated at:</Typography>
                        <Typography className="value">
                          {row?.property?.termination_date
                            ? getLocalFormatedDate(
                                row?.property?.termination_date
                              )
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">
                          {" "}
                          Uploaded Document:
                        </Typography>
                        <Typography className="value">
                          {row?.uploaded_doc?.length > 0 ? (
                            <CustomLinkWrapper
                              link={row?.uploaded_doc}
                              title={
                                <span style={{ color: "blue" }}>
                                  {getURLName(row?.doc_url)}
                                </span>
                              }
                              classes={"preview-url value"}
                            />
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Stack>
                    </Stack>

                    {index < _DATA?.length - 1 && (
                      <Divider
                        className="my-2 mt-3"
                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                      />
                    )}
                  </>
                );
              })}
              {!terminatedPropertiesData?.data?.length > 0 &&
                !terminationDocLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found
                  </Typography>
                )}
            </Box>

            {/* desktop view */}
            <Box sx={{ width: "100%" }} className="d-none d-lg-block">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {_DATA?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.id}
                            style={{ mineight: "20px" }}
                          >
                            <TableCell align="left">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.address || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.city || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property?.county || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.zip || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.transaction_id || "-"}
                            </TableCell>

                            <TableCell align="left">
                              {row?.property?.termination_date
                                ? getLocalFormatedDate(
                                    row?.property?.termination_date
                                  )
                                : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.uploaded_doc?.length > 0 ? (
                                <CustomLinkWrapper
                                  link={row?.uploaded_doc}
                                  title={getURLName(row?.uploaded_doc)}
                                  classes={"preview-url"}
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMenuClick(event);
                                  setSelectedProperty(row);
                                }}
                                size="small"
                                className="settings-button"
                                aria-controls="settings-menu"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <SettingsIconSVG />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!terminatedPropertiesData?.data?.length > 0 &&
                    !terminationDocLoader && (
                      <Typography className="p-5 text-center" variant="h6">
                        No Data Found
                      </Typography>
                    )}
                </TableContainer>
              </Paper>
              {terminatedPropertiesData?.count > PER_PAGE && (
                <Box id="pagination-container">
                  <CustomPagination
                    count={count}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Typography className="w-100 p-5 text-center text-danger bg-white fs-6">
            {terminatedPropertiesData?.data?.message || "Something went wrong!"}
          </Typography>
        )
      ) : null}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {/* {(!isSecondaryUser || selectedProperty?.access_requests) && (
          <MenuItem
            className="setting-menu-item"
            onClick={() => uploadedPropertyDocuments()}
          >
            <Box className="setting-menu-box">
              <UploadFileOutlinedIcon />
              <Typography className="setting-menu-text">
                Upload Document
              </Typography>
            </Box>
          </MenuItem>
        )} */}
        {selectedProperty?.doc_url && (
          <MenuItem
            className="setting-menu-item"
            // onClick={() => setPropertyTerminatePopup(true)}
          >
            <a
              className="custom-link-wrapper text-break"
              href={selectedProperty?.doc_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box className="setting-menu-box">
                <SimCardDownloadOutlinedIcon />
                <Typography className="setting-menu-text">
                  Download Document
                </Typography>
              </Box>
            </a>
          </MenuItem>
        )}

        {!selectedProperty?.property?.added_by_other_user &&
          !isSecondaryUser && (
            <MenuItem
              className="setting-menu-item"
              onClick={() => setPropertyResubscribePopup(true)}
            >
              <Box className="setting-menu-box">
                <AccountBalanceWalletOutlinedIcon />
                <Typography className="setting-menu-text">
                  Add Subscription
                </Typography>
              </Box>
            </MenuItem>
          )}
      </Menu>

      {/* Uplaod Document popup */}
      <UploadRequestDocModal
        from="TerminationDocument"
        fileFormates={{
          "image/png": [".png", ".jpeg", ".jpg"],
          "image/gif": [".gif"],
          "application/vnd.ms-powerpoint": [".ppt"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/csv": [".csv"],
        }}
        multiple={false}
        open={uploadDocPopup}
        handleClose={() => {
          handleCloseModal();
        }}
        caption={
          "Please upload Property Termination document for this property. "
        }
        isUploadSuccess={uploadedDocData?.success}
        uploadedDocData={uploadedDocData}
        successMessage={uploadedDocData?.message}
        errorMessage={uploadedDocData?.data?.message}
        uploadLoader={terminationDocLoader}
        handleUploadDoc={handleUploadDocuments}
        fetchPropertyListingData={() => {
          getTerminatedPropertiesLists();
        }}
      />

      {/* Property Termination Waring popup */}
      {propertyResubscribePopup && (
        <AlertDialog
          openPopup={propertyResubscribePopup}
          type={"Warning"}
          onClose={true}
          onCloseFun={() => {
            setPropertyResubscribePopup(false);
          }}
          buttons={
            <>
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    setPropertyResubscribePopup(false);
                  }}
                  className="popup_Btn other_popup_btn mb-2 mb-sm-0"
                  variant="contained"
                >
                  No,Close
                </Button>
                <Button
                  onClick={() => handleReSubscription()}
                  className="popup_Btn success_popup_btn mx-3 mb-2 mb-sm-0"
                  variant="contained"
                >
                  Yes,Resubscribe
                </Button>
              </div>
            </>
          }
          mesage={"Are you sure you want to Resubscribe this property ?"}
        />
      )}

      {/* Property renewed validation */}
      {renewedPopup && getObjLength(resubscribePropertyData) && (
        <AlertDialog
          type={resubscribePropertyData?.success ? "Success" : "Error"}
          openPopup={renewedPopup}
          onClose={true}
          onCloseFun={() => {
            setRenewedPopup(false);
            dispatch(documentsAction.fetchTerminatedPropertyData({}));
            if (resubscribePropertyData?.success) {
              getTerminatedPropertiesLists();
            }
          }}
          mesage={
            resubscribePropertyData?.success
              ? resubscribePropertyData?.message
              : resubscribePropertyData?.data?.message
          }
          footer={false}
        />
      )}

      {isAddSubscriptionModalOpen && (
        <AddSubscriptionModal
          open={isAddSubscriptionModalOpen}
          selectedProperty={selectedProperty}
          closeModalHandler={handleCloseAddSubscriptionModal}
          //adding promocode
          addPromoCode={true}
          from="property-renewal"
        />
      )}

      {getObjLength(addSubscriptionDetails) ? (
        addSubscriptionDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={onSuccessFun}
            mesage={addSubscriptionDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={onFailedFun}
            mesage={addSubscriptionDetails?.data?.message}
            footer={false}
          />
        )
      ) : null}

      <Loader open={terminationDocLoader || addSubscriptionLoader} />
    </>
  );
};

export default TerminatedProperties;
