import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import RequestDocumentsListTable from "./RequestDocumnetsListTable";

const RequestDocumentList = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Request Documents
        </Typography>
      </Box>
      <Box>
        <RequestDocumentsListTable />
      </Box>
    </>
  );
};

export default RequestDocumentList;
