import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
} from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { LOCAL_RECORDING_FEES } from "../../helpers/constants";
import auto_activity1 from "../../../assets/home/auto_activity_zone.svg";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import auto_activity from "../../../assets/home/simple_auto_activity_zone .svg";
import {
  currencyFormatter,
  decryptPayload,
  getObjLength,
  user_signup_details,
} from "../../helpers/utils";

import "../../../styles/pricing.scss";
import "../../../styles/homepage.scss";
import TotalPyamentPopup from "./TotalPyamentPopup";

let MONTHLY_PLAN_PER_MONTH;
let MONTHLY_DISCOUNT_PLAN_AMOUNT;
let MONTHLY_DISCOUNT_SETUP_AMOUNT;
let YEARLY_PLAN_PER_MONTH;
let YEARLY_SAVINGS;
let FIRST_YEAR_SAVINGS;
let YEARLY_DISCOUNT_PLAN_AMOUNT;
let MONTHLY_PLAN_RECORDING_FEES;
let YEARLY_PLAN_RECORDING_FEES;

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 26,
  height: 26,
  // display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "none",
  border: "1px solid rgba(52, 75, 91, 0.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#0395FF" : "#F6F9FC",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#0395FF" : "#F6F9FC",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
  display: "none",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0395FF",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0395FF",
  },
});
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const GetSubscriptionPlan = ({
  subscriptionPlans,
  onBtnClick,
  yearlyPlan,
  handleGetPlansScreen,
}) => {
  const [plan, setPlan] = useState(yearlyPlan?.plan_id);
  const [alert, setAlert] = useState(false);
  const [dataflag, setDataFlag] = useState(false);
  const [planDetails, setPlanDetails] = useState(yearlyPlan);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const propetiesDetails = localStorage.getItem("propetiesDetails");

  const dispatch = useDispatch();

  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );

  const totalPaymnetDetails = useSelector(
    (state) => state.signUpUser.totalPaymentDetails
  );
  const statesLoader = useSelector((state) => state.signUpUser.statesLoader);
  const { propertyIds, properties } = useSelector((state) => state.signUpUser);
  const submitChanges = () => {
    console.log("Submit changes==>", plan);

    //fo fb meta data
    let metadata = {};
    if (propetiesDetails) {
      metadata = {
        ...JSON.parse(propetiesDetails),
        plan_name: planDetails?.name,
        plan_id: plan,
        email: user_signup_details()?.email,
      };
    } else {
      metadata = {
        plan_name: planDetails?.name,
        email: user_signup_details()?.email,
        plan_id: plan,
      };
    }
    console.log("metadata==>>", metadata);
    localStorage.setItem("PlanDetails", JSON.stringify(metadata));

    let data = {
      user_id: getObjLength(crerateAccData)
        ? crerateAccData.data?.user_id
        : userIdFromSession,
      plan: plan,
    };
    dispatch(userSigupAction.addNewProperty({}));
    handleGetPlansScreen(data, planDetails);
    setAlert(false);
  };

  const choosePLans = () => {
    let pc = localStorage.getItem("promoCode");
    let data = {
      user_id: getObjLength(crerateAccData)
        ? crerateAccData.data?.user_id
        : userIdFromSession,
      plan_id: parseInt(plan),
      property_id: propertyIds,
      // property_ids: properties,//due to revert the changes
    };
    if (pc?.length > 0) {
      data.promo_code = pc;
    }
    console.log("Submit changes==>", data);
    dispatch(
      userSigupAction.getTotalPaymentDetails({
        url: "get-final-amount",
        data: data,
      })
    );

    // setAlert(true);
  };

  if (getObjLength(subscriptionPlans) && subscriptionPlans?.success) {
    if ((subscriptionPlans?.data).length > 0) {
      const monthlyPlans = subscriptionPlans?.data?.find(
        (data) => data?.name === "Monthly"
      );
      const yearlyPlans = subscriptionPlans?.data?.find(
        (data) => data?.name === "Yearly"
      );

      MONTHLY_PLAN_PER_MONTH = monthlyPlans.recurring_amount;
      //remove comments after promocode feature added in admin side
      MONTHLY_DISCOUNT_PLAN_AMOUNT = 0; //monthlyPlans?.subscription_discount;
      MONTHLY_DISCOUNT_SETUP_AMOUNT = 0; //monthlyPlans?.setup_fee_discount;
      YEARLY_PLAN_PER_MONTH = yearlyPlans?.recurring_amount;
      YEARLY_SAVINGS = yearlyPlans?.yearly_savings;
      FIRST_YEAR_SAVINGS = yearlyPlans?.first_year_savings;
      //remove comments after promocode feature added in admin side
      YEARLY_DISCOUNT_PLAN_AMOUNT = 0; //yearlyPlans?.subscription_discount;
      MONTHLY_PLAN_RECORDING_FEES = monthlyPlans.recording_fee;
      YEARLY_PLAN_RECORDING_FEES = yearlyPlans?.recording_fee;
    }
  }

  const selectCredit = (e) => {
    console.log("Select creditBalance", e.target.value);
    setPlan(e.target.value);
    const planData = subscriptionPlans?.data?.filter(
      (i) => i.plan_id === parseInt(e.target.value)
    );
    console.log("e.target.value==>", planData?.[0]);
    setPlanDetails(planData?.[0]);
  };

  useEffect(() => {
    if (Object.keys(totalPaymnetDetails).length > 0) {
      setAlert(true);
      if (totalPaymnetDetails?.success) {
        setDataFlag(true);
      } else {
        setDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPaymnetDetails]);
  let county_name = decryptPayload(localStorage.getItem("county-code"));
  let yearly_plan_amount = (
    subscriptionPlans?.data?.find((data) => data?.name === "Yearly")?.amount /
    100
  )?.toFixed(2);
  let monthly_plan_amount = county_name
    ? (
        (subscriptionPlans?.data?.find((data) => data?.name === "Monthly")
          ?.amount || 0) / 100
      ).toFixed(2)
    : subscriptionPlans?.data?.find((data) => data?.name === "Monthly")
        ?.setup_fees;
  console.log(plan, "county_name subscription plan=>", county_name);
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            className="display-flex justify-content-center"
            row
            defaultValue={plan}
            aria-labelledby="payment-types"
            name="payment-modes"
            onChange={selectCredit}
          >
            <Stack direction="row" mt={3}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  order={{ xs: 2, sm: 1, md: 1, lg: 1, xl: 1 }}
                  mb={2}
                >
                  <FormControlLabel
                    // value={data.plan_id}
                    value={
                      subscriptionPlans &&
                      subscriptionPlans?.data?.find(
                        (data) => data?.name === "Monthly"
                      )?.plan_id
                    }
                    className="paymentMode plans-block w-100 d-flex justify-content-center align-items-center"
                    control={<BpRadio />}
                    sx={{ margin: 0 }}
                    label={
                      <div
                        className={`planCard d-flex flex-column ${
                          plan ==
                          subscriptionPlans?.data?.find(
                            (data) => data?.name === "Monthly"
                          )?.plan_id
                            ? "highlighted"
                            : "not-highlighted"
                        } ${!MONTHLY_PLAN_RECORDING_FEES && "county_width"}`}
                      >
                        <div
                          className={`"card_inner monthly_Card break-word" ${
                            MONTHLY_DISCOUNT_PLAN_AMOUNT
                              ? "dicsounted_monthly_Card"
                              : ""
                          }`}
                        >
                          <div className="d-flex align-items-center mb-3">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p className="mb-0">EquityProtect</p>
                              <h4 className="mb-0">
                                {
                                  subscriptionPlans?.data?.find(
                                    (data) => data?.name === "Monthly"
                                  )?.name
                                }
                              </h4>
                            </div>
                            <div className="ms-auto">
                              <img
                                src={auto_activity}
                                alt="auto_activity_zone"
                              />
                            </div>
                          </div>
                          <Divider />
                          <div className="pricing_Wrapper text-center">
                            {MONTHLY_DISCOUNT_SETUP_AMOUNT ? (
                              <h5 className={`flex-container mb-1 pt-4 fw-500`}>
                                <span className="text_strike pe-3">
                                  ${monthly_plan_amount}
                                </span>
                                <span>
                                  ${MONTHLY_DISCOUNT_SETUP_AMOUNT.toFixed(2)}
                                </span>
                              </h5>
                            ) : (
                              <h5 className={`mb-1 pt-4 fw-500`}>
                                ${monthly_plan_amount}
                              </h5>
                            )}
                            <p className="para mb-1 pt-1">
                              {county_name
                                ? "for the first Month"
                                : "Set-up & Activation Fee"}
                            </p>
                            {/* <p
                              className="para mt-1"
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: "10px auto",
                              }}
                            >
                              + ${MONTHLY_PLAN_PER_MONTH / 100} / month
                            </p> */}
                            {MONTHLY_DISCOUNT_PLAN_AMOUNT ? (
                              <>
                                <p
                                  className="para mt-2 mb-0"
                                  style={{
                                    fontSize: "22px",
                                    fontWeight: 600,
                                    margin: "10px auto",
                                  }}
                                >
                                  + $
                                  {(MONTHLY_DISCOUNT_PLAN_AMOUNT / 100).toFixed(
                                    2
                                  )}{" "}
                                  / month
                                </p>
                                <p className="mb-2">for the first Year</p>
                                <span className="mt-3 fw-700">
                                  + ${(MONTHLY_PLAN_PER_MONTH / 100).toFixed(2)}{" "}
                                  / month after a year
                                </span>
                              </>
                            ) : (
                              <p
                                className="para mt-1"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  margin: "10px auto",
                                }}
                              >
                                + ${(MONTHLY_PLAN_PER_MONTH / 100).toFixed(2)} /
                                month
                              </p>
                            )}

                            <p className="para mb-0 mt-0">
                              + applicable sales tax
                            </p>
                            <p className="para mb-1 w-100">
                              {/* + applicable Deposit for recording fees (One time
                              fee) */}
                              {/*<b>{currencyFormatter(LOCAL_RECORDING_FEES)}</b> */}
                              {MONTHLY_PLAN_RECORDING_FEES &&
                                "+ applicable Deposit for recording fees (One time fee)"}
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  order={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                  mb={2}
                >
                  <FormControlLabel
                    // value={data.plan_id}
                    value={
                      subscriptionPlans?.data?.find(
                        (data) => data?.name === "Yearly"
                      )?.plan_id
                    }
                    className="paymentMode plans-block w-100 d-flex justify-content-center align-items-center"
                    control={<BpRadio />}
                    sx={{ margin: 0 }}
                    label={
                      <div
                        className={`planCard d-flex flex-column ${
                          plan ==
                          subscriptionPlans?.data?.find(
                            (data) => data?.name === "Yearly"
                          )?.plan_id
                            ? "highlighted"
                            : "not-highlighted"
                        } ${!YEARLY_PLAN_RECORDING_FEES && "county_width"}`}
                      >
                        <div
                          className={`"card_inner w-100 break-word" ${
                            county_name && "monthly_Card"
                          }`}
                        >
                          <div className="d-flex align-items-center mb-3">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p className="mb-1">EquityProtect</p>
                              <h4 className="mb-1">
                                {
                                  subscriptionPlans?.data?.find(
                                    (data) => data?.name === "Yearly"
                                  )?.name
                                }
                              </h4>
                            </div>
                            <div className="ms-auto">
                              <img
                                src={auto_activity1}
                                alt="auto_activity_zone"
                              />
                            </div>
                          </div>
                          <Divider sx={{ mb: 3 }} />
                          <div className="pricing_Wrapper text-center">
                            <p className="para mb-0 activation_text">
                              No Set-up or Activation Fee
                            </p>
                            {YEARLY_DISCOUNT_PLAN_AMOUNT ? (
                              <h5 className={`flex-container mb-1 fw-500`}>
                                <span className="text_strike pe-3">
                                  ${yearly_plan_amount}
                                </span>
                                <span>
                                  $
                                  {(YEARLY_DISCOUNT_PLAN_AMOUNT / 100).toFixed(
                                    2
                                  )}
                                </span>
                              </h5>
                            ) : (
                              <h5 className={`mb-1 fw-500`}>
                                ${yearly_plan_amount}
                              </h5>
                            )}
                            <p className="para mb-0">for the first Year</p>

                            <p
                              className="mt-1"
                              style={{
                                fontSize: "18px",
                                fontWeight: 600,
                                margin: "10px auto",
                              }}
                            >
                              + ${YEARLY_PLAN_PER_MONTH / 100} / year
                            </p>
                            {!county_name && (
                              <p className="para mb-1">
                                per year for annual recertification
                              </p>
                            )}
                            <p className="para mb-0 mt-0">
                              + applicable sales tax
                            </p>
                            <p className="para mb-0 w-100">
                              {/* + applicable Deposit for recording fees (One time
                              fee) */}
                              {/*<b>{currencyFormatter(LOCAL_RECORDING_FEES)}</b> */}
                              {YEARLY_PLAN_RECORDING_FEES &&
                                "+ applicable Deposit for recording fees (One time fee)"}
                            </p>
                            {!county_name && (
                              <p className="para activation_text pt-1">
                                Savings of ${FIRST_YEAR_SAVINGS} for the first
                                Year and ${YEARLY_SAVINGS} every year after.{" "}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Stack>

            {/* {subscriptionPlans?.data?.map((data, key) =>
                data.name === "Test" ? null : (
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6} key={key}>
                    <Paper
                      className="shadow p-3  bg-white rounded"
                      sx={{
                        p: 2,
                        m: 3,

                        boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControlLabel
                        value={data.plan_id}
                        className="paymentMode w-100"
                        control={<BpRadio />}
                        label={
                          <Box className="d-flex align-items-center justify-content-center py-3">
                            <SubscriptionPlansContent
                              plan1={data.name === "Monthly" ? true : false}
                              plan2={data.name === "Yearly" ? true : false}
                              fullPage={true}
                            />
                            {/* <Typography
                              variant="h5"
                              className="mode me-1 fw-500"
                            >
                              {data.name} Plan -
                            </Typography>
                            <br />
                            <Typography
                              variant="h5"
                              className="value position-relative fw-600"
                            >
                              $ {data?.amount ? data.amount / 100 : 0}
                            </Typography> */}
            {/* </Box>
                        }
                      />
                    </Paper>
                  </Grid>
                )
              )} */}
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* <section>
          <Container fluid className="pricing_Wrapper">
            <SubscriptionPlansFeatures fullPage={true} />
          </Container>
        </section> */}
      {/* {Object.keys(paymentData).length > 0 && !paymentData?.success && (
        <p className="text-danger text-center mt-4">
          {paymentData?.data?.message}
        </p>
      )} */}
      <div className="text-center createAcBtn mt-5">
        <Button
          onClick={() => {
            choosePLans();
            // setAlert(true);
          }}
          type="submit"
          className="next_button"
          variant="contained"
        >
          Next Step
        </Button>
      </div>

      {alert &&
        (dataflag ? (
          <>
            <TotalPyamentPopup
              dataflag={dataflag}
              setDataFlag={setDataFlag}
              alert={alert}
              setAlert={setAlert}
              closePopup={() => setAlert(false)}
              planDetails={planDetails}
              isClose={true}
              paySecure={submitChanges}
            />
          </>
        ) : (
          <AlertDialog
            type="Info"
            openPopup={alert}
            onClose={true}
            onCloseFun={() => setAlert(false)}
            mesage={
              <p>
                Are you sure you want to continue with a{" "}
                <b className="text-uppercase">{planDetails?.name} </b>
                Plan?
              </p>
            }
            buttons={
              <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                <Button
                  className="popup_Btn other_popup_btn m-2"
                  onClick={() => setAlert(false)}
                >
                  cancel
                </Button>
                <Button
                  className="popup_Btn success_popup_btn m-2"
                  onClick={() => submitChanges()}
                  // onClick={() => setAlert(false)}
                >
                  continue
                </Button>
              </Stack>
            }
          />
        ))}
      <Loader open={statesLoader} />
    </>
  );
};

export default GetSubscriptionPlan;
