import { useEffect } from "react";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import DialogBox from "../cardPopup";
import AlertDialog from "../../../common/AlertDialog";
import usePagination from "../../../common/PaginationLogic";
import StannupEmailVerification from "./stannupEmailVerification";
import { userProfileAction } from "../../../../redux/slices/user/userProfileSlice";
import CustomPagination from "../../../common/customDesignedComponents/CustomPagination";

import "../../../userDocuments/propertyTable.scss";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props?.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ minWidth: headCell?.minWidth }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PropertiesListing({ columns, rowsData }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [stannupPopup, setStannupPop] = useState(false);
  const [postcatdAlert, setPostcardAlert] = useState(false);
  const [rowData, setRowData] = useState([]);
  const PER_PAGE = 5;

  // store data
  const { postCardDetails, loginAsSpouse: isSecondaryUser,verifyStannupDetails } = useSelector(
    (state) => state?.userProfile
  );
  const count = Math.ceil(rowsData.length / PER_PAGE);
  const _DATA = usePagination(rowsData, PER_PAGE, page, setPage);

  useEffect(() => {
    console.log("data editted called==>", postCardDetails);
    if (Object.keys(postCardDetails).length > 0) {
      setPostcardAlert(true);
    }
  }, [postCardDetails]);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const verifyStannupEmail = (row) => {
    console.log("verifyStannupEmail==>", row);
    setRowData(row);
    setStannupPop(true);
    dispatch(userProfileAction.clearPostcardData());
    // dispatch(
    //   userProfileAction.sendPostCardData({
    //     url: "customer/send-postcard/" + row.id,
    //     data: {},
    //     token: true,
    //   })
    // );
  };
  const closeStanupPopup = () => {
    dispatch(userProfileAction.clearPostcardData({}));
    setStannupPop(false);
  };

  const senPostCard = (row) => {
    console.log("senPostCard==>>", row);
    dispatch(
      userProfileAction.sendPostCardData({
        url: "customer/send-postcard/" + row.id,
        data: {},
        token: true,
      })
    );
  };
  const closepostcardPopup = () => {
    setPostcardAlert(false);
    dispatch(
      userProfileAction.getStannupEmailData({
        url: "customer/address",
        data: {},
        token: true,
      })
    );
    dispatch(userProfileAction.clearPostcardData({}));
  };

  return (
    <>
      {/* Property Listing Table */}
      <Box sx={{ width: "100%" }} className="d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead columns={columns} />
              <TableBody>
                {_DATA?.currentData()?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="left">
                        {(page - 1) * PER_PAGE + index + 1}
                      </TableCell>

                      <TableCell align="left">
                        {row?.city?.trim()
                          ? [row?.address?.trim(), row?.city?.trim()]?.join(
                              ", "
                            )
                          : row?.address?.trim() || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row.is_verified === 0 ? (
                          <Button
                            variant="contained"
                            onClick={() => verifyStannupEmail(row)}
                            disabled={isSecondaryUser}
                            color="error"
                          >
                            <p className=" m-0">Verify</p>
                          </Button>
                        ) : (
                          <Typography
                            // sx={{ color: "#43D551" }}
                            // variant="h6"
                            className="text-success fw-600 text-uppercase m-0"
                          >
                            Verifed
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row?.postcard === null ? (
                          <Button
                            variant="outlined"
                            onClick={() => senPostCard(row)}
                            // color="success"
                          >
                            Send Postcard
                          </Button>
                        ) : (
                          <Typography className="text-success  fw-600 text-uppercase m-0">
                            {row?.postcard?.status}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!rowsData.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>

        {rowsData.length >= 5 && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>

      {/* for mobile view */}
      <Paper
        elevation={0}
        className="help-contact d-block d-sm-none mobile-property-listing"
        sx={{ maxHeight: "350px", overflow: "hidden", overflowY: "auto" }}
      >
        {rowsData?.map((property, index) => (
          <>
            <List
              className="supportContact"
              sx={{ width: "100%", bgcolor: "background.paper" }}
            >
              <ListItem disablePadding className="align-items-start">
                <ListItemText className="mb-0 label">Address:</ListItemText>
                <ListItemText className="mb-0 content">
                  <Typography>
                    {property?.city?.trim()
                      ? [
                          property?.address?.trim(),
                          property?.city?.trim(),
                        ]?.join(", ")
                      : property?.address?.trim() || "-"}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding className="align-items-start">
                <ListItemText className="mb-0 label">
                  Verify Postcard:
                </ListItemText>
                <ListItemText className="mb-0 content">
                  {property?.is_verified === 0 ? (
                    <Button
                      variant="contained"
                      onClick={() => verifyStannupEmail(property)}
                      disabled={isSecondaryUser}
                      color="error"
                      className=""
                    >
                      Verify
                    </Button>
                  ) : (
                    <Typography className="text-success fw-600 text-uppercase m-0">
                      Verifed
                    </Typography>
                  )}
                </ListItemText>
              </ListItem>
              <ListItem disablePadding className="align-items-start">
                <ListItemText
                  className="mb-0 label"
                  sx={{ mt: property?.postcard === null ? 1 : 0 }}
                >
                  Status:
                </ListItemText>
                <ListItemText className="mb-0 content">
                  {property?.postcard === null ? (
                    <Button
                      variant="outlined"
                      onClick={() => senPostCard(property)}
                      // color="success"
                      sx={{ mt: 1 }}
                    >
                      Send Postcard
                    </Button>
                  ) : (
                    <Typography className="text-success fw-600 text-uppercase m-0">
                      {property?.postcard?.status}
                    </Typography>
                  )}
                </ListItemText>
              </ListItem>
            </List>
            {index < rowsData?.length - 1 && (
              <hr style={{ width: rowsData?.length < 3 ? "100%" : "97%" }} />
            )}
          </>
        ))}
      </Paper>

      {/* Code verification popup */}
      {stannupPopup && (
        <DialogBox
          width={verifyStannupDetails?.success ? "sm": "md"}
          openPopup={stannupPopup}
          onClose={closeStanupPopup}
          mesage={
            <StannupEmailVerification
              row={rowData}
              setStannupPop={setStannupPop}
              from="dashboard"
            />
          }
        />
      )}

      {/* Send postcard validation popup  */}
      {postcatdAlert && (
        <AlertDialog
          // openPopup={!accCreated?.success}
          type={`${postCardDetails?.success ? `Success` : `Error`}`}
          openPopup={postcatdAlert}
          onClose={false}
          mesage={
            <>
              {Object.keys(postCardDetails).length > 0 &&
              postCardDetails?.success ? (
                <p className="text-success">{postCardDetails?.message}</p>
              ) : (
                <p className="text-danger">{postCardDetails?.data?.message}</p>
              )}
              <Button
                className="popup_Btn success_popup_btn"
                onClick={closepostcardPopup}
              >
                Close
              </Button>
            </>
          }
        />
      )}
    </>
  );
}
