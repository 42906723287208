import React, { useEffect } from "react";
import { DEFAULT_URL } from "../helpers/constants";
import LandingPage from "./index";

const ForCustomer = () => {
  useEffect(() => {
    window.location.href = DEFAULT_URL;
  }, []);
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default ForCustomer;
