import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import DialogBox from "./cardPopup";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import Error from "../../../assets/icons/error.png";
import { ROLES } from "../../helpers/constants";
import SecurePaymentPanel from "../../common/SecurePaymentPanel";
import Info from "../../../assets/icons/info.png";
import {
  decryptPayload,
  encryptPayload,
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../../helpers/utils";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { subscriptionAction } from "../../../redux/slices/subscription/subscriptionSlice";
import TotalPyamentPopup from "../../user-signup/React-stripe/TotalPyamentPopup";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const columns = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
  },
  {
    id: "mailingaddress",
    disablePadding: true,
    label: "Property Address",
  },
  {
    id: "plan",
    disablePadding: true,
    label: "Subscription Plan",
  },
  {
    id: "pendingAmount",
    disablePadding: true,
    label: "Pending Amount",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell key={headCell.id} align={"left"} padding={"normal"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const PaymentFailed = ({
  isPaymentDonePopup,
  callListAPI = false,
  setIsPaymentDonePopup = () => {},
  apiCall,
}) => {
  const dispatch = useDispatch();

  const [viewDetails, setViewDetails] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState(null);
  const [valdiateModal, setValdiateModal] = useState(false);
  const [totalAmountPopup, setTotalAmountPopup] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const PER_PAGE = 5;

  const { paymnetDoneDetails, cardLoader, userProfileDetails } = useSelector(
    (state) => state?.userProfile
  );

  const { totalPaymentDetails } = useSelector((state) => state.signUpUser);
  const {
    totalAmountData,
    totalAmountLoader,
    failedPaymentData,
    failedPaymentLoader,
    threedSecurePayment,
    threedSecurePaymentLoader,
  } = useSelector((state) => state?.subscription);

  console.log("selectedCard==>", selectedCard);

  const closeModal = () => {
    dispatch(userProfileAction.setPaymentPending({}));
    localStorage.setItem(
      "paymentPopup",
      parseInt(localStorage.getItem("paymentPopup")) + 1
    );
    // to open subuser info modal
    localStorage.setItem("userRoleCode", encryptPayload("52"));
    dispatch(subscriptionAction.clearTotalAmountData({}));
    dispatch(subscriptionAction.clearFailedPaymentData({}));
  };

  console.log("paymnetDoneDetails=>", paymnetDoneDetails);

  const totalAmountApiCall = () => {
    let propertyIds = paymnetDoneDetails?.data?.properties?.map((i) => i.id);
    console.log("payment secure called", propertyIds);
    setSelectedProperties(propertyIds);
    dispatch(
      subscriptionAction.getTotalAmountData({
        url: "customer-get-final-amount",
        data: { property_id: propertyIds },
        token: true,
      })
    );
  };

  const paySecure = () => {
    console.log(selectedProperties, "payment secure called", selectedCard);
    dispatch(
      subscriptionAction.getFailedPaymentData({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/payment`,
        data: {
          property_id: selectedProperties,
          card_id: selectedCard?.[0]?.id,
        },
        token: true,
      })
    );
  };
  console.log(
    "address==>",
    paymnetDoneDetails?.data?.properties?.map((i) =>
      i?.address ? { address: i?.address, plan: i?.plan } : null
    )
  );
  let data = {
    email: decryptPayload(localStorage.getItem("loggedInEmail")),
    properties: paymnetDoneDetails?.data?.properties?.map((i) =>
      i?.address ? { address: i?.address, plan: i?.plan } : null
    ),
  };
  const onSuccessFun = () => {
    if (!failedPaymentData?.data?.actionRequired) {
      reactPixelEventOnlyForCustomer(
        "Successful Payment for Expired properties",
        data
      );
    }
    closeModal();
    if (callListAPI) {
      apiCall();
    }
    setValdiateModal(true);
    dispatch(userProfileAction.setPaymentPending({}));
    dispatch(subscriptionAction.clearTotalAmountData({}));
    dispatch(subscriptionAction.clearFailedPaymentData({}));
  };

  const onFailedFun = () => {
    if (!failedPaymentData?.data?.actionRequired) {
      reactPixelEventOnlyForCustomer(
        "Failed Payment for Expired properties",
        data
      );
    }
    // dispatch(userProfileAction.setPaymentPending({}));
    dispatch(subscriptionAction.clearTotalAmountData({}));
    dispatch(subscriptionAction.clearFailedPaymentData({}));
    localStorage.setItem("paymentPopup", 0);
    dispatch(subscriptionAction.clear3dSecurePaymentData({}));
  };

  const secureCardSuccess = () => {
    onSuccessFun();
    reactPixelEventOnlyForCustomer(
      "Successful 3D card Payment for Expired properties",
      data
    );
  };
  const secureCardFailed = () => {
    setValdiateModal(false);
    onFailedFun();
    reactPixelEventOnlyForCustomer(
      "Failed 3D card Payment for Expired properties",
      data
    );
  };

  const closeTotalAmountModal = (action) => {
    setTotalAmountPopup(false);
    if (action === "submit") {
      setViewDetails(4);
      dispatch(subscriptionAction.clearTotalAmountData({}));
    }
    dispatch(userSigupAction.fetchTotalPaymentDetails({}));
  };

  function viewDetailsButtons() {
    return (
      <>
        {" "}
        <Button
          className="popup_Btn other_popup_btn"
          onClick={() => {
            setViewDetails(2);
          }}
        >
          View Details
        </Button>
        <Button className="popup_Btn error_popup_btn" onClick={closeModal}>
          Ok, Close
        </Button>
      </>
    );
  }

  function payAmountButtons() {
    return (
      <>
        <Button
          className="popup_Btn other_popup_btn"
          onClick={() => setViewDetails(1)}
        >
          Back{" "}
        </Button>
        <Button
          className="popup_Btn success_popup_btn"
          onClick={() => {
            setViewDetails(3);
            totalAmountApiCall();
          }}
        >
          Go to Payment
        </Button>
      </>
    );
  }
  console.log("totalAmountPopup==>", totalAmountData);

  function totalAmountDetailsButtons() {
    return (
      <>
        <Button
          onClick={() => {
            setViewDetails(2);
          }}
          type="submit"
          className=" mx-2 popup_Btn other_popup_btn"
          variant="contained"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            closeTotalAmountModal("submit");
            closeTotalAmountModal();
          }}
          type="submit"
          className="mx-2 popup_Btn success_popup_btn"
          variant="contained"
        >
          Next
        </Button>
      </>
    );
  }

  function cardButtons() {
    return (
      <>
        <Button
          onClick={() => {
            setViewDetails(3);
            totalAmountApiCall();
          }}
          className="popup_Btn other_popup_btn"
          disabled={failedPaymentLoader || threedSecurePaymentLoader}
        >
          Back
        </Button>

        <Button
          type="submit"
          onClick={paySecure}
          className="popup_Btn success_popup_btn"
          variant="contained"
          disabled={failedPaymentLoader || threedSecurePaymentLoader}
        >
          Pay Securly
        </Button>
      </>
    );
  }

  useEffect(() => {
    if (getObjLength(totalAmountData)) {
      if (totalAmountData?.success) {
        setTotalAmountPopup(true);
      }
    }
  }, [totalAmountData]);

  useEffect(() => {
    dispatch(
      userProfileAction.userCardDetails({
        url: `${
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.serviceProvider
          )
            ? "service-provider"
            : "customer"
        }/card/list`,
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (getObjLength(failedPaymentData)) {
      if (failedPaymentData?.success) {
        if (failedPaymentData?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !failedPaymentData?.data?.actionRequired &&
          failedPaymentData?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, failedPaymentData]);
  console.log(viewDetails, "failedPaymentData==>", failedPaymentData);
  return (
    // <AlertDialog
    //   width={"md"}
    //   type={viewDetails === 1 ? "Error" : ""}
    //   openPopup={isPaymentDonePopup}
    //   onClose={true}
    //   onCloseFun={closeModal}
    //   mesage={
    <>
      <Dialog
        fullWidth={true}
        maxWidth={valdiateModal ? "sm" : "md"}
        sx={
          viewDetails === 1
            ? { textAlign: "center", zIndex: "13000000" }
            : { zIndex: "13000000" }
        }
        open={isPaymentDonePopup}
        onClose={closeModal}
        // scroll={scroll}
        className={`dialogWrapper`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {!valdiateModal && (
          <>
            <Box sx={{ p: 4, position: "relative" }} className="dialog-header">
              <Button
                onClick={closeModal}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  color: "black",
                  background: "transparent",
                }}
              >
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>
              <DialogTitle sx={{ p: 0 }}>
                {viewDetails === 1 && (
                  <>
                    <img className="popup_icon" src={Error} alt={"error"} />
                    <p className="text-center fw-600 m-0 my-2">Error</p>
                  </>
                )}
                {viewDetails === 2 && (
                  <Box sx={{ flexGrow: 1 }} className="text-center">
                    <Typography className="text-center fw-600" variant="h5">
                      Property List
                    </Typography>
                  </Box>
                )}
                {viewDetails === 3 && (
                  <Box sx={{ flexGrow: 1 }} className="text-center">
                    <Typography className="text-center fw-600" variant="h5">
                      Total Amount Details
                    </Typography>
                  </Box>
                )}
                {viewDetails === 4 && (
                  <Box sx={{ flexGrow: 1 }} className="text-center">
                    <Typography className="text-center fw-600" variant="h5">
                      Card List
                    </Typography>
                  </Box>
                )}
              </DialogTitle>
              {viewDetails === 1 && (
                <Typography className="fw-400 mt-3">
                  Your payment <b>${paymnetDoneDetails?.data?.final_amount}</b>{" "}
                  is pending, please complete your payment to enable the
                  features.
                </Typography>
              )}
            </Box>
            {viewDetails > 1 && (
              <DialogContent dividers>
                {viewDetails === 2 && (
                  <>
                    {/* mobile view */}
                    <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 py-4">
                      {paymnetDoneDetails?.data?.properties?.map(
                        (row, index) => {
                          return (
                            <>
                              <Stack direction="column" spacing={1.2} px={2}>
                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="key">
                                    Address:
                                  </Typography>
                                  <Typography className="value text-capitalize">
                                    {row?.address ? row?.address : "-"}
                                  </Typography>
                                </Stack>

                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="key">
                                    Subscription Plan:
                                  </Typography>
                                  <Typography className="value">
                                    {row?.plan ? row?.plan : "-"}
                                  </Typography>
                                </Stack>

                                <Stack direction="column" spacing={0.5}>
                                  <Typography className="key">
                                    {" "}
                                    Plan Amount:
                                  </Typography>
                                  <Typography className="value">
                                    {row?.pending_amount
                                      ? "$" + row?.pending_amount
                                      : "-"}
                                  </Typography>
                                </Stack>
                              </Stack>

                              {index <
                                paymnetDoneDetails?.data?.properties?.length -
                                  1 && (
                                <Divider
                                  className="my-3"
                                  sx={{
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                />
                              )}
                            </>
                          );
                        }
                      )}

                      {getObjLength(paymnetDoneDetails) &&
                        !paymnetDoneDetails?.data?.properties?.length > 0 && (
                          <Typography
                            className="d-block d-sm-none p-5 text-center"
                            variant="h6"
                          >
                            No Data Found.
                          </Typography>
                        )}
                    </Box>
                    {/* desktop view */}
                    <Paper
                      sx={{ width: "100%", mb: 2 }}
                      className="d-none d-sm-block"
                    >
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        >
                          <EnhancedTableHead
                            // onRequestSort={handleRequestSort}
                            rowCount={
                              paymnetDoneDetails?.success &&
                              paymnetDoneDetails?.data?.length
                            }
                            columns={columns}
                          />
                          <TableBody>
                            {paymnetDoneDetails?.success &&
                              paymnetDoneDetails?.data?.properties?.map(
                                (row, index) => {
                                  return (
                                    <TableRow tabIndex={-1} key={index}>
                                      <TableCell align="left">
                                        {(page - 1) * PER_PAGE + index + 1}
                                      </TableCell>

                                      <TableCell align="left">
                                        {row?.address ? row?.address : "-"}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row?.plan ? row?.plan : "-"}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row?.pending_amount
                                          ? "$" + row?.pending_amount
                                          : "-"}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <Loader open={totalAmountLoader} />
                  </>
                )}
                {viewDetails === 3 && (
                  <Box>
                    {/* <CardList cardData={setSelectedCard} /> 
                <Elements stripe={stripePromise}>
                */}
                    {totalAmountPopup && (
                      <>
                        <Paper
                          sx={{ width: "100%", mb: 2 }}
                          className=" d-sm-block"
                        >
                          <TableContainer>
                            <Table
                              sx={{ backgroundColor: "white" }}
                              aria-labelledby="tableTitle"
                            >
                              <TableRow>
                                <TableCell>
                                  <p
                                    className="para"
                                    style={{
                                      textAlign: "center",
                                      paddingTop: "20px",
                                      fontSize: "1.1rem",
                                    }}
                                  >
                                    Total Properties:{" "}
                                    <b>
                                      {
                                        totalPaymentDetails?.data
                                          ?.no_of_properties
                                      }{" "}
                                    </b>
                                    <br className="d-block d-sm-none" />
                                    <span className="d-none d-sm-inline-block">
                                      {" "}
                                      and{" "}
                                    </span>{" "}
                                    Plan amount:{" "}
                                    <b>
                                      ${totalPaymentDetails?.data?.sub_amount}{" "}
                                    </b>
                                    <br />
                                    Discount:{" "}
                                    <b>
                                      ${totalPaymentDetails?.data?.discount}{" "}
                                    </b>{" "}
                                    <br className="d-block d-sm-none" />
                                    <span className="d-none d-sm-inline-block">
                                      {" "}
                                      and{" "}
                                    </span>{" "}
                                    Sales/Local taxes:{" "}
                                    <b>
                                      $
                                      {
                                        totalPaymentDetails?.data
                                          ?.sales_tax_amount_actual
                                      }{" "}
                                    </b>
                                    {parseFloat(
                                      totalPaymentDetails?.data
                                        ?.recording_amount_actual
                                    ) > 0 && (
                                      <>
                                        <br />
                                        Deposit for recording fees:{" "}
                                        <b>
                                          $
                                          {
                                            totalPaymentDetails?.data
                                              ?.recording_amount_actual
                                          }{" "}
                                        </b>{" "}
                                        <br className="d-block d-sm-none" />
                                        <span className="d-none d-sm-inline-block">
                                          {" "}
                                          and{" "}
                                        </span>{" "}
                                        Setup Fees:{" "}
                                        <b>
                                          $
                                          {
                                            totalPaymentDetails?.data
                                              ?.setup_fees_actual
                                          }
                                        </b>
                                      </>
                                    )}
                                    <br />
                                    <br className="d-block d-sm-none" />
                                    Total Payable Amount:{" "}
                                    <b>${totalPaymentDetails?.data?.total} </b>
                                  </p>
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableContainer>
                        </Paper>
                        {/* <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                
              </Stack> */}
                      </>
                    )}
                  </Box>
                )}
                {viewDetails === 4 && (
                  <Box>
                    {/* <CardList cardData={setSelectedCard} /> 
                <Elements stripe={stripePromise}>
                */}
                    {/* <p>Payment</p> */}
                    <SecurePaymentPanel
                      displayLabel={false}
                      cardData={setSelectedCard}
                      paymentData={failedPaymentData}
                      onSuccessFun={secureCardSuccess}
                      onFailedFun={secureCardFailed}
                      userId={userProfileDetails?.data?.user_id}
                      isOpenFromBottom={true}
                      setValdiateModal={setValdiateModal}
                      setValidation3DMsg={setValidationMsg}
                    />

                    {/* </Elements> */}
                  </Box>
                )}
                <Loader open={failedPaymentLoader || totalAmountLoader} />
              </DialogContent>
            )}
            <DialogActions
              sx={{ py: 4 }}
              className={`d-flex align-items-center ${
                viewDetails === 1
                  ? `justify-content-center`
                  : `justify-content-between`
              }`}
            >
              {viewDetails === 1
                ? viewDetailsButtons()
                : viewDetails === 2
                ? payAmountButtons()
                : viewDetails === 3
                ? totalAmountDetailsButtons()
                : cardButtons()}
            </DialogActions>
            <Loader open={cardLoader} />
          </>
        )}
        {getObjLength(failedPaymentData) &&
        valdiateModal &&
        !getObjLength(threedSecurePayment) ? (
          failedPaymentData?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={true}
              onClose={onSuccessFun}
              onCloseFun={onSuccessFun}
              message={failedPaymentData?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                setValdiateModal(false);
                setIsPaymentDonePopup(false);
                // dispatch(subscriptionAction.clearFailedPaymentData({}));
              }}
              message={failedPaymentData?.data?.message}
              footer={false}
            />
          )
        ) : null}

        {getObjLength(threedSecurePayment) ? (
          threedSecurePayment?.success ? (
            <AlertDialog
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                onSuccessFun();
                dispatch(subscriptionAction.clear3dSecurePaymentData({}));
              }}
              mesage={threedSecurePayment?.message}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={secureCardFailed}
              mesage={
                validationMsg?.length > 0
                  ? validationMsg
                  : threedSecurePayment?.data?.message
              }
              footer={false}
            />
          )
        ) : null}
      </Dialog>
      {/* {totalAmountPopup && (
        <>
              <>
                <Typography align="center" variant="h6">
                  Total Amount Details
                  {planDetails
                    ? ` for ${planDetails?.name || planDetails?.plan_Name} Plan`
                    : null}
                </Typography>
                <p className="para">
                  Total Properties:{" "}
                  <b>{totalPaymentDetails?.data?.no_of_properties} </b>
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span> Plan
                  amount: <b>${totalPaymentDetails?.data?.sub_amount} </b>
                  <br />
                  Discount: <b>${totalPaymentDetails?.data?.discount} </b>{" "}
                  <br className="d-block d-sm-none" />
                  <span className="d-none d-sm-inline-block"> and </span>{" "}
                  Sales/Local taxes:{" "}
                  <b>${totalPaymentDetails?.data?.sales_tax_amount_actual} </b>
                  {parseFloat(
                    totalPaymentDetails?.data?.recording_amount_actual
                  ) > 0 && (
                    <>
                      <br />
                      Deposit for recording fees:{" "}
                      <b>
                        ${totalPaymentDetails?.data?.recording_amount_actual}{" "}
                      </b>{" "}
                      <br className="d-block d-sm-none" />
                      <span className="d-none d-sm-inline-block">
                        {" "}
                        and{" "}
                      </span>{" "}
                      Setup Fees:{" "}
                      <b>${totalPaymentDetails?.data?.setup_fees_actual}</b>
                    </>
                  )}
                  <br />
                  <br className="d-block d-sm-none" />
                  Total Payable Amount:{" "}
                  <b>${totalPaymentDetails?.data?.total} </b>
                </p>
              </>
              <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                <Button
                  onClick={() => closeTotalAmountModal("cancel")}
                  type="submit"
                  className=" mx-2 popup_Btn other_popup_btn"
                  variant="contained"
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    closeTotalAmountModal("submit");
                    paySecure();
                  }}
                  type="submit"
                  className="mx-2 popup_Btn success_popup_btn"
                  variant="contained"
                >
                  Next
                </Button>
              </Stack>
        </>
        <TotalPyamentPopup
          dataflag={true}
          setDataFlag={closeTotalAmountModal}
          alert={totalAmountPopup}
          setAlert={closeTotalAmountModal}
          closePopup={closeTotalAmountModal}
          isClose={true}
          paySecure={closeTotalAmountModal}
        />
      )} */}
    </>
  );
};

export default PaymentFailed;
