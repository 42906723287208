import React, { useEffect } from "react";

import { Navbar } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Stack, Typography } from "@mui/material";

import CallUsNow from "../../common/CallUsNow";
import logo from "../../../assets/lennar/ep-logo.svg";
import sacnME from "../../../assets/lennar/scan_me.png";
import QR_code from "../../../assets/lennar/QR_code.webp";
import playStore from "../../../assets/lennar/playstore.webp";
import appStore from "../../../assets/signin/app_store_img.webp";
import mobileImage from "../../../assets/lennar/mobile_images.png";
import emailRead from "../../../assets/lennar/mark_email_read.webp";
import houseShield from "../../../assets/lennar/house_with_shield.webp";
import LennarPublicPageFooter from "../../layout/LennarPublicPageFooter";
import { lennarSubscriptionAction } from "../../../redux/slices/lennar/subscriptionSlice";
import {
  DEFAULT_URL,
  ROLES,
  appStoreURL,
  playStoreURL,
} from "../../helpers/constants";

import "../../../styles/lennarPaymentDone.scss";
import { customeDomainName, decryptPayload } from "../../helpers/utils";

const PaymentDone = () => {
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const fetchCustomDomain = customeDomainName(window.location.host);
  let path = null;
  if (!fetchCustomDomain) {
    path = pathName?.split("/")?.[1];
  } else {
    path = fetchCustomDomain;
  }
  console.log("path==>", path);

  // store data
  const { lennarPropertyList, providerDetails } = useSelector(
    (state) => state?.lennarSubscription
  );

  const goToProfile = () => {
    localStorage.removeItem("roles");
    // window.location.href = `${DEFAULT_URL}login?from=${SUBDOMAIN?.provider}`;
    dispatch(lennarSubscriptionAction?.setCardDetails({}));
    dispatch(lennarSubscriptionAction?.fetchPropertyDetails({}));
    const goToAdmin = decryptPayload(localStorage.getItem("goToAdmin"));
    if (providerDetails?.data?.redirect_url) {
      if (goToAdmin?.includes("admin")) {
        window.location.href =
          DEFAULT_URL +
          goToAdmin +
          `?from=${ROLES?.provider}&user_id=` +
          decryptPayload(localStorage.getItem("user_id"));
      } else {
        window.location.href = providerDetails?.data?.redirect_url;
      }
    }
    dispatch(lennarSubscriptionAction?.fetchProviderDetails({}));
    localStorage.clear();
  };
  // defualt scroll bottom to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Box className="lennar-wrapper" sx={{ backgroundColor: "#235CA30D" }}>
        <div className=" paymentDone_bg_img text-center mb-3">
          <Navbar.Brand>
            {providerDetails?.data?.logo && (
              <img
                className="lennar-logo"
                src={providerDetails?.data?.logo}
                alt="lennar-logo"
              />
            )}
            <img src={logo} alt="lennar-logo" className="lennar-logo" />
          </Navbar.Brand>
          <section className="content-spacing">
            <Row className="mt-2 d-flex justify-content-center align-items-center text-white mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={10}
                // className="content-spacing"
              >
                <h1 className="property_heading fw-400 mb-3 mt-0 mt-sm-4">
                  Congratulations!
                </h1>
                <p className="paragraph_texts fw-400 mt-2 mx-auto">
                  Welcome to your Premium Account,
                  {lennarPropertyList?.data?.user_name}! EquityProtect goes
                  beyond mere monitoring; we take actions others don't.
                  Safeguarding not only your title but, more crucially, your
                  equity, we employ our patented SmartPolicy to secure your home
                  ownership, thwarting cybercriminals and preventing fraud.
                </p>
              </Col>
            </Row>
          </section>
        </div>

        <section className="paymentDone_wrapper mb-4 ">
          <Box className="overlap-div-spacing">
            <Container fluid className="bg-white main-container1">
              <Box className="box-container mb-5">
                <Box className="bg-white">
                  <Stack direction={"column"} justifyContent="center">
                    <Typography variant="h5" className="pd-header mt-3 mb-2">
                      What’s next
                    </Typography>
                    <Stack
                      spacing={2}
                      className="mt-4"
                      direction={"row"}
                      justifyContent={"start"}
                    >
                      <img
                        className="logo_specing"
                        src={emailRead}
                        alt="article"
                        loading="lazy"
                      />
                      <Typography variant="body1" className="pd-description ">
                        Your username will be your email address used with
                        <span className="text-capitalize"> {path}</span>Track.
                        {/* and a password will be sent to you by separate email. */}
                        Please watch for the email and that it is not in your
                        spam or junk folder.
                      </Typography>
                    </Stack>
                    <p className="fw-400 contact-support">
                      Didn’t receive the email?
                      <CallUsNow
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        renderText={
                          <u className="cursor-pointer">
                            Please contact Support
                          </u>
                        }
                      />
                    </p>
                    <Stack
                      spacing={2}
                      direction={"row"}
                      justifyContent={"start"}
                    >
                      <img
                        className="logo_specing"
                        src={houseShield}
                        alt="article"
                        loading="lazy"
                      />
                      <Typography variant="body1" className="pd-description">
                        Your coverage begins the day after the settlement date
                        of your transaction
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Container>
            <Container
              fluid
              className="main-container2"
              style={{ backgroundColor: "#fafbff" }}
            >
              <Box>
                <Stack
                  className="box-container"
                  direction={"row"}
                  justifyContent="center"
                >
                  <Box>
                    <p className="movefaster-heading">Move faster in the app</p>
                    <p className="movefaster-description">
                      All native and secure tools can be conveniently located on
                      your mobile device.
                    </p>
                    <Stack
                      className="qrimg-section"
                      // className="d-flex d-sm-block justify-content-center w-100 flex-sm-row flex-row"
                      direction={"row"}
                      // justifyContent="center"
                    >
                      <Box>
                        <Stack
                          className="m-2"
                          direction={"column"}
                          // justifyContent="center"
                        >
                          <img
                            className="mt-3 mb-1 qrcode_img"
                            src={QR_code}
                            alt="qr Code"
                            loading="lazy"
                            style={{
                              width: "100px",
                              height: "100px",
                            }}
                          />
                          <img
                            className=" mb-3"
                            src={sacnME}
                            alt="Scan Me"
                            loading="lazy"
                            style={{
                              width: "100px",
                              height: "22px",
                            }}
                          />
                        </Stack>
                      </Box>

                      <Box>
                        <Stack
                          className="m-2 "
                          direction={"column"}
                          // justifyContent="center"
                        >
                          <img
                            className="mb-1 mt-3 cursor-pointer"
                            src={playStore}
                            onClick={() => window.open(playStoreURL, "_blank")}
                            alt="playstore"
                            loading="lazy"
                            style={{
                              width: "150px",
                              height: "51px",
                            }}
                          />
                          <img
                            className="my-1 cursor-pointer"
                            src={appStore}
                            onClick={() => window.open(appStoreURL, "_blank")}
                            alt="Appstore"
                            loading="lazy"
                            style={{
                              width: "150px",
                              height: "51px",
                            }}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                  <Box className="d-none d-sm-block">
                    <img
                      className=""
                      src={mobileImage}
                      alt="mobileImage"
                      loading="lazy"
                    />
                  </Box>
                </Stack>
              </Box>
            </Container>
          </Box>
        </section>

        <section className="text-center py-5 content-spacing">
          <Stack direction={"column"} spacing={1}>
            <div>
              <Button
                onClick={goToProfile}
                className="continue_payment_btn mt-2"
              >
                Return to your profile
              </Button>
            </div>
            <p className="contact-support">
              <CallUsNow
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                renderText={
                  <u className="cursor-pointer">Need help? Click here now</u>
                }
              />
            </p>
          </Stack>
        </section>
      </Box>
      <LennarPublicPageFooter />
    </>
  );
};

export default PaymentDone;
