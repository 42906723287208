import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SecureConnectionTemplate from "../../common/secureConnectionTemplate";
import SPPersonalTokenScreen from "./SPPersonalTokenScreen";
import SPProfessionalForm from "./SPProfessionalForm";
import FinalStepCompleted from "./finalStepCompleted";
import BiometricScreen from "./BiometricScreen";
import SignatureScreen from "./SignatureScreen";

const steps = [
  "Personal token",
  "Professional info",
  "Photo ID",
  "Signature",
  "Final Step",
];

export default function HorizontalLinearStepper({ resumeCount, backToRoot }) {
  const [activeStep, setActiveStep] = React.useState(
    resumeCount > 0 ? resumeCount : 0
  );
  // eslint-disable-next-line no-unused-vars
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  React.useEffect(() => {
    if (activeStep + 1 !== 3) {
      window.onbeforeunload = function () {
        console.log("refreshed...");
        return true;
      };

      return () => {
        console.log("refreshed stop...");
        // dispatch(userSigupAction.clearData());
        window.onbeforeunload = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleNext = (e, action) => {
    console.log("parent action==>.", activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  console.log(activeStep, "===reatek==>.", resumeCount);

  function formContent() {
    return (
      <>
        <React.Fragment>
          <Box sx={{ position: "relative" }} mt={2} mb={1}>
            {activeStep + 1 === 1 ? (
              <SPPersonalTokenScreen onBtnClick={handleNext} />
            ) : activeStep + 1 === 2 ? (
              <SPProfessionalForm onBtnClick={handleNext} />
            ) : activeStep + 1 === 3 ? (
              <BiometricScreen onBtnClick={handleNext} />
            ) : activeStep + 1 === 4 ? (
              <SignatureScreen onBtnClick={handleNext} />
            ) : activeStep + 1 === 5 ? (
              <FinalStepCompleted backToRoot={backToRoot} />
            ) : null}
          </Box>
        </React.Fragment>
      </>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        className="stepWrapper"
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step className="stepWrapperItem" key={label} {...stepProps}>
              <StepLabel className="stepWrapperLabel" {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <SecureConnectionTemplate content={formContent()} footer={false} />
    </Box>
  );
}
