import { createSlice } from "@reduxjs/toolkit";

import { ROLES } from "../../../components/helpers/constants";
import {
  encryptPayload,
  getObjLength,
  reactPixelEventOnlyForCustomer,
} from "../../../components/helpers/utils";

const intialUserProfileState = {
  userProfileDetails: {},
  editedUserProfileDetails: {},
  cardDetails: {},
  addNewCard: {},
  removeCardDetails: {},
  removedCardLoader: false,
  setDefaultCard: {},
  defaultCardLoader: false,
  addBeneficiaryDetails: {},
  beneficiaryList: [],
  beneficiaryLoader: false,
  StannupEmailDetails: {},
  postCardDetails: {},
  verifyStannupDetails: {},
  addressLoader: false,
  postcardLoader: false,
  biometricDetails: {},
  updateBiometricDetails: {},
  biometric_signLoader: false,
  signatureDetails: {},
  updateSignatureDetails: {},
  referralDetails: {},
  referralLoader: false,
  loggedInEmail: "",
  editProfileLoader: false,
  profileLoader: false,
  qrCodeLoader: false,
  qrCodeAddData: {},
  loginAsSpouse: localStorage.getItem("parent_id")
    ? localStorage.getItem("parent_id")
    : false,
  switchAccountLoader: false,
  switchAccountDetails: {},
  cardLoader: false,
  isPaymentPending: false,
  paymnetDoneDetails: {},
  addNewCardLoader: false,
  reRenderCount: 0, // default API calls when a user navigates to the same page from the menu
  callDefaultAPIs: 1,
  isResetPasswordRequired:
    parseInt(localStorage.getItem("isResetPasswordRequired")) || 0,
  switchedToParentUser: false,
  parent_id: localStorage.getItem("parent_id") || null,
};

const userProfile = createSlice({
  name: "userProfileDetails",
  initialState: intialUserProfileState,
  reducers: {
    userProfileData(state, action) {
      console.log("login data==>>", action.payload);
      state.profileLoader = true;
    },
    clearUserProfileData(state, action) {
      state.userProfileDetails = {};
      state.editedUserProfileDetails = {};
      state.cardDetails = {};
      state.addNewCard = {};
      state.removeCardDetails = {};
      state.removedCardLoader = false;
      state.setDefaultCard = {};
      state.switchAccountDetails = {};
      state.switchAccountLoader = false;
      state.cardLoader = false;
    },
    editUserProfileData(state, action) {
      state.editProfileLoader = true;
      state.editedUserProfileDetails = {};
    },

    fetchUserData(state, action) {
      console.log("In state slice...", action.payload);
      if (action.payload?.success) {
        console.log("In user profile details...", action.payload);
        if (action.payload.data?.role?.includes(ROLES.customer)) {
          reactPixelEventOnlyForCustomer("User Profile", {
            email: action.payload?.data?.email,
          });
        }
        if (action.payload.data?.role?.includes(ROLES.secondaryUser)) {
          // localStorage.setItem("isSecondaryUser", action?.payload?.success);
          localStorage.setItem(
            "roles",
            encryptPayload(action?.payload?.data?.role)
          );
          // state.loginAsSpouse = action?.payload?.success;
        } else {
          state.loginAsSpouse = false;
          state.switchedToParentUser = false;
        }
        state.loggedInEmail = action.payload?.data?.email;
        localStorage.setItem(
          "loggedInEmail",
          encryptPayload(action.payload?.data?.email)
        );
        if (action.payload?.data?.isResetPasswordRequired) {
          state.isResetPasswordRequired = state.isResetPasswordRequired + 1;
        }
      }
      state.userProfileDetails = action.payload;
      state.profileLoader = false;
      //expire the cookie
      document.cookie =
        "iosValue =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    fetcheditedUserData(state, action) {
      state.editProfileLoader = false;
      state.editedUserProfileDetails = action.payload;
    },
    fetchTrustedContacts(state, action) {
      console.log("fetching trusted contacts...");
    },
    setTrustedCotacts(state, action) {
      state.trustedContacts = action.payload;
    },
    clearResetPasswordRequired(state, action) {
      state.isResetPasswordRequired = state.isResetPasswordRequired + 1;
    },
    logoutUser(state, action) {
      localStorage.setItem("authorised", null);
      state.userProfileDetails = {};
      state.editedUserProfileDetails = {};
      state.cardDetails = {};
      state.addNewCard = {};
      state.removeCardDetails = {};
      state.removedCardLoader = false;
      state.setDefaultCard = {};
      state.switchAccountDetails = {};
      state.paymnetDoneDetails = {};
      state.switchAccountLoader = false;
      state.isResetPasswordRequired = 0;
      state.loginAsSpouse = false;
      localStorage.setItem("isSecondaryUser", false);
      state.StannupEmailDetails = {};
      localStorage.removeItem("parent_id");
    },
    userCardDetails(state, action) {
      state.cardLoader = true;
      console.log("card details slice..", action.payload);
    },
    getCardDetails(state, action) {
      console.log("card details slice..", action.payload);
      state.cardDetails = action.payload;
      state.addNewCard = {};
      state.cardLoader = false;
    },
    addNewCard(state, action) {
      console.log("add new card details slice..", action.payload);
      state.addNewCardLoader = true;
    },
    getNewCardDetails(state, action) {
      console.log("added new card details slice..", action.payload);
      state.addNewCard = action.payload;
      state.addNewCardLoader = false;
    },
    removeNewCard(state, action) {
      console.log("add new card details slice..", action.payload);
      state.removedCardLoader = true;
    },
    removeServiceProvidersCard(state, action) {
      state.removedCardLoader = true;
    },
    getRemovedCardDetails(state, action) {
      console.log("added new card details slice..", action.payload);
      state.removeCardDetails = action.payload;
      state.removedCardLoader = false;
    },
    setDefaultCard(state, action) {
      console.log("setDefault card details slice..", action.payload);
      state.defaultCardLoader = true;
      state.setDefaultCard = {};
    },
    getsetDefaultCardDetails(state, action) {
      console.log("setDefault details slice..", action.payload);
      state.setDefaultCard = action.payload;
      state.defaultCardLoader = false;
    },
    emptyDefaultCardDetails(state, action) {
      state.setDefaultCard = {};
    },
    clearBeneficiaryData(state, action) {
      state.addBeneficiaryDetails = {};
      state.beneficiaryLoader = false;
    },
    addBeneficiaryDetails(state, action) {
      state.beneficiaryLoader = true;
    },
    deleteBeneficiaryDetails(state, action) {
      state.beneficiaryLoader = true;
    },
    setBeneficiaryDetails(state, action) {
      state.addBeneficiaryDetails = action.payload;
      state.beneficiaryLoader = false;
    },
    fetchBeneficiaryList(state, action) {
      state.beneficiaryLoader = true;
    },
    setBeneficiaryList(state, action) {
      state.beneficiaryList = action.payload;
      state.beneficiaryLoader = false;
    },
    getStannupEmailData(state, action) {
      state.StannupEmailDetails = {};
      state.addressLoader = true;
    },

    fetchStannupEmailData(state, action) {
      state.StannupEmailDetails = action.payload;
      state.addressLoader = false;
    },
    sendPostCardData(state, action) {
      state.postCardDetails = {};
      state.postcardLoader = true;
    },

    fetchPostCardData(state, action) {
      state.postCardDetails = action.payload;
      state.postcardLoader = false;
    },
    verifyStannupData(state, action) {
      state.verifyStannupDetails = {};
      state.postcardLoader = true;
    },

    fetchVerifiedStannupData(state, action) {
      state.verifyStannupDetails = action.payload;
      state.postcardLoader = false;
    },
    clearPostcardData(state, action) {
      state.postCardDetails = {};
      state.verifyStannupDetails = {};
      state.postcardLoader = false;
      state.QRCodePopup = false;
    },
    getBiometricData(state, action) {
      state.biometricDetails = {};
      state.biometric_signLoader = true;
    },

    fetchBiometricData(state, action) {
      state.updateBiometricDetails = {};
      state.biometricDetails = action.payload;
      state.biometric_signLoader = false;
    },
    updateBiometricData(state, action) {
      state.updateBiometricDetails = {};
      state.biometric_signLoader = true;
    },

    fetchUpdatedBiometricData(state, action) {
      state.updateBiometricDetails = action.payload;
      state.biometric_signLoader = false;
    },
    getSignatureData(state, action) {
      state.signatureDetails = {};
      state.updateSignatureDetails = {};
      state.biometric_signLoader = true;
    },

    fetchSignatureData(state, action) {
      state.signatureDetails = action.payload;
      state.biometric_signLoader = false;
    },
    updateSignatureData(state, action) {
      state.updateSignatureDetails = {};
      state.biometric_signLoader = true;
    },

    fetchUpdatedSignatureData(state, action) {
      state.updateSignatureDetails = action.payload;
      state.biometric_signLoader = false;
    },
    getReferralData(state, action) {
      state.referralDetails = {};
      state.referralLoader = true;
    },

    fetchReferralData(state, action) {
      state.referralDetails = action.payload;
      state.referralLoader = false;
    },
    getQRCodeVerificationData(state, action) {
      state.qrCodeAddData = {};
      state.qrCodeLoader = true;
    },
    fetchQRCodeVerificationData(state, action) {
      console.log("popup actions==>", action.payload);
      state.qrCodeAddData = action.payload;
      state.qrCodeLoader = false;
      if (action.payload?.data?.is_verified === 0) {
        localStorage.removeItem("stanupPopupCount");
        state.QRCodePopup = true;
      } else {
        state.QRCodePopup = false;
      }
    },
    switchAccount(state, action) {
      // state.switchAccountLoader = true;
      localStorage.setItem("isSecondaryUser", true);
      state.parent_id = action.payload?.parent_id;
      state.loginAsSpouse = true;
      localStorage.setItem("parent_id", action.payload?.parent_id);
      state.reRenderCount = state.reRenderCount + 1;
    },
    setSwitchAccountDetails(state, action) {
      // state.switchAccountDetails = action?.payload;
      // state.switchAccountLoader = false;
      // if (action?.payload?.success) {
      //   localStorage.setItem("isSecondaryUser", action?.payload?.success);
      // localStorage.setItem(
      //   "roles",
      //   encryptPayload(action?.payload?.data?.type)
      // );
      // state.loginAsSpouse = action?.payload?.success;
      // state.switchedToParentUser = true;
      // }
    },
    clearSwitchSpouseDetails(state, action) {
      state.switchAccountDetails = {};
      state.switchAccountLoader = false;
    },
    logoutFromSpouse(state, action) {
      localStorage.setItem("isSecondaryUser", false);
      state.loginAsSpouse = false;
      state.switchedToParentUser = false;
      state.parent_id = null;
      localStorage.removeItem("parent_id");
      // localStorage.setItem("roles", encryptPayload(ROLES.customer));
    },
    paymnetDoneDetails(state, action) {
      console.log("action==>", action.payload);
      // state.paymnetDoneDetails = {};
      state.isPaymentPending = true;
      if (getObjLength(action.payload)) {
        state.paymentLoader = true;
      } else {
        state.paymentLoader = false;
      }
    },
    getPaymnetDoneDetails(state, action) {
      console.log("paymnetDoneDetails==>", action.payload);
      state.paymnetDoneDetails = action.payload;
      if (action?.payload?.data?.properties?.length > 0) {
        state.isPaymentPending = false;
      } else {
        state.isPaymentPending = true;
      }
      state.paymentLoader = false;
      // if (action.payload?.data?.length === 0) {
      //   localStorage.setItem("paymentPopup", 0);
      // }
    },
    setPaymentPending(state, action) {
      state.isPaymentPending = true;
    },
    setReRenderCount(state, action) {
      state.reRenderCount = action.payload;
    },
    setDefaultSelectedTab(state, action) {
      state.callDefaultAPIs = action.payload;
    },
  },
});
export const userProfileAction = userProfile.actions;
export default userProfile.reducer;
