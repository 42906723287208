import { createSlice } from "@reduxjs/toolkit";

const initialProfessionalPropertiesStates = {
  professionalPropertiesData: {},
  professionalPropertiesLoader: false,
//   cardDetails: {},
//   cardDetailsLoader: false,
};

const professionalProperties = createSlice({
  name: "professionalProperties",
  initialState: initialProfessionalPropertiesStates,
  reducers: {
    getProfessionalPropertyData: (state, action) => {
      state.professionalPropertiesLoader = true;
    },
    setProfessionalPropertyData: (state, action) => {
      state.professionalPropertiesData = action?.payload;
      state.professionalPropertiesLoader = false;
    },
    // getProfessionalsCardDetails: (state, action) => {
    //   state.cardDetailsLoader = true;
    // },
    // setProfessionalsCardDetails: (state, action) => {
    //   state.cardDetails = action?.payload;
    //   state.cardDetailsLoader = false;
    // },
  },
});
export const professionalPropertiesAction = professionalProperties.actions;
export default professionalProperties.reducer;
