/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
} from "@mui/material";

import { getObjLength } from "../../helpers/utils";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";

import "../../user-signup/signup.scss";

const FieldStyle = {
  borderRadius: "3px",
};

const validationSchema = Yup.object().shape({
  parcel_number: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(15, "Too Long!")
    .required("Parcel number is required"),
  county_code: Yup.string("Please select the County ").required(
    "County  is required"
  ),
});

export default function FindPropertyByParcel({
  onBtnClick,
  onChangeModalScreen,
  isByPassOptionAvailable = false,
  isAdminCreateUser = false,
  onCloseModalHandler = () => {},
  setShowPropertyListing,
  propertyValues,
  setPropertyValues,
  setQuestionsErrMsg,
  questionsErrMsg,
  setErrMsg,
  errMsg,
  handleClose,
  validate,
  handleToggle,
  handleResponseModalOpen,
  editProperty = false,
}) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  // const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const [options, setOptions] = useState([]);
  const [editAdd, setEditAdd] = useState({});
  const { countyList, statesLoader } = useSelector((state) => state.signUpUser);

  const { getPropertyData, editPropertiesDetails } = useSelector(
    (state) => state.properties
  );

  const { verifiedPropertiesList } = useSelector((state) => state.signUpUser);

  const onSubmit = (values, errors) => {
    setErrMsg("");
    let data = { ...values, type: "parcel" };
    console.log("submited values==>", data);
    dispatch(
      propertiesAction.fetchUserProperty({
        url: "properties",
        data: data,
        token: false,
      })
    );
    // onBtnClick();
  };

  const getCountyList = (event) => {
    console.log("value==>", event);
    dispatch(
      userSigupAction.getCountyList({
        url: "get-county-autocomplete",
        data: { county: event },
      })
    );
  };

  useEffect(() => {
    if (editProperty) {
      console.log(editAdd?.county_code, "editProperty==>", editProperty);
      getCountyList(editPropertiesDetails?.data?.county_code);
    }
  }, []);
  useEffect(() => {
    if (getObjLength(countyList)) {
      console.log(countyList, "options==>", options);
      if (countyList?.success) {
        if (countyList?.data?.length > 0) {
          setOptions([...countyList?.data]);
        }
      }
    }
  }, [countyList]);
  console.log(countyList, "ssss--- options==>", options);

  useEffect(() => {
    if (getObjLength(getPropertyData)) {
      if (getPropertyData?.success) {
        setPropertyValues(getPropertyData?.data);
        setErrMsg("");
        if (getPropertyData?.data?.length === 1) {
          dispatch(
            propertiesAction.selectedUserPropertyData(getPropertyData?.data[0])
          );
        }
        if (getPropertyData?.data?.length > 1) {
          setOptions([]);
          setShowPropertyListing(true);
          console.log("setPropertyValues ==>", getPropertyData);
        }
      } else {
        setPropertyValues({});
        setErrMsg(getPropertyData?.data?.message);
      }
    }
  }, [getPropertyData]);

  useEffect(() => {
    if (getObjLength(editPropertiesDetails)) {
      if (editPropertiesDetails?.success && editProperty) {
        setEditAdd(editPropertiesDetails?.data);
      }
    }
  }, [editPropertiesDetails, editProperty]);

  return (
    <>
      <Box>
        {
          <>
            <>
              <>
                <Box mt={3} className="findPropertyBoxWrapper ">
                  <Formik
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                      parcel_number:
                        (editProperty ? editAdd?.parcel_number : "") || "",
                      county_code:
                        (editProperty ? editAdd?.county_code : "") || "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, touched, errors }) => {
                      return (
                        <Form>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <TextField
                                // fullWidth
                                className="sign-textField"
                                label="Parcel Number"
                                name="parcel_number"
                                // type="number"
                                // onKeyDown={(e) => {
                                //   // if (!e.key.match(/[A-Za-z]*$/)?.length > 0) {
                                //   //   e.preventDefault();
                                //   // }
                                //   // var x = e.which || e.keycode;
                                //   if (!e.key.match(/[A-Za-z]*$/)?.length > 0) {
                                //     e.preventDefault();
                                //   }
                                // }}
                                value={values?.parcel_number}
                                variant="outlined"
                                sx={FieldStyle}
                                InputLabelProps={{
                                  style: {
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#0D120B",
                                  },
                                }}
                                onChange={(event) => {
                                  setFieldValue(
                                    "parcel_number",
                                    event.target.value
                                  );
                                }}
                                helperText={
                                  touched.parcel_number && errors.parcel_number
                                }
                                error={
                                  touched.parcel_number &&
                                  Boolean(errors.parcel_number)
                                }
                              />
                            </Grid>

                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <FormControl
                                fullWidth
                                className="sign-textField"
                                htmlFor="demo-customized-textbox"
                                error={
                                  touched.county_code &&
                                  Boolean(errors.county_code)
                                }
                              >
                                <Autocomplete
                                  // name="county_code"
                                  id="asynchronous-demo"
                                  // freeSolo
                                  disableClearable
                                  sx={FieldStyle}
                                  open={open}
                                  // value={values.county_code}
                                  onOpen={() => {
                                    setOpen(true);
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      "county_code",
                                      String(newValue?.id)
                                    );
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.county === value.county
                                  }
                                  getOptionLabel={(option) =>
                                    option.county || values.county_code
                                  }
                                  options={options}
                                  loading={statesLoader}
                                  renderInput={(params) => (
                                    <TextField
                                      onChange={(event) => {
                                        // setFieldValue(
                                        //   "county_code",
                                        //   event.target.value
                                        // );
                                        getCountyList(event.target.value);
                                        setOptions([]);
                                      }}
                                      label="County"
                                      placeholder={"Search the County"}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {statesLoader ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {touched.county_code &&
                                  Boolean(errors.county_code) && (
                                    <FormHelperText
                                      sx={{ color: "#d32f2f !important" }}
                                    >
                                      {touched.county_code &&
                                        errors.county_code}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>

                            {isByPassOptionAvailable && (
                              <>
                                <Grid xs={12} md={12} px={1} pb={1}>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                  >
                                    <span className="pt-0">
                                      <CustomizedCheckbox
                                        onChecked={(e) => {
                                          dispatch(
                                            propertiesAction.setIsQuestionByPass(
                                              e.target.checked
                                            )
                                          );
                                          // setIsQuestionByPass(e.target.checked);
                                        }}
                                      />
                                    </span>
                                    <span>
                                      Do you want to bypass security questions
                                      for this property?
                                    </span>
                                  </Stack>
                                </Grid>
                              </>
                            )}
                            {errMsg && (
                              <p className="text-center text-danger mb-0">
                                {errMsg}
                              </p>
                            )}
                          </Grid>
                          <div className="text-center stepBtn mt-5">
                            {isAdminCreateUser &&
                              verifiedPropertiesList?.data?.length > 0 && (
                                <Button
                                  className=" other_popup_btn next_button mb-3 mx-2"
                                  variant="contained"
                                  onClick={() =>
                                    onCloseModalHandler("propertyList")
                                  }
                                >
                                  Property List
                                </Button>
                              )}

                            <Button
                              type="submit"
                              className="next_button mb-3"
                              variant="contained"
                            >
                              Next Step
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Box>
              </>
            </>

            {questionsErrMsg?.length > 0 && (
              <p className="text-danger text-center">{questionsErrMsg}</p>
            )}
          </>
        }
      </Box>
      {/* <Loader open={propertyLoader} /> */}
    </>
  );
}
