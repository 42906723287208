import { put, takeEvery, call } from "redux-saga/effects";
import { userSigInAction } from "../../slices/user/userSignInSlice";
import { postAPIPayload } from "../../../apis/postApi";
import { userSigupAction } from "../../slices/user/userSignUpSlice";

export function* getUserSignInSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  if (response?.data?.status === "pending") {
    yield put(userSigupAction.fetchResumeData(response));
  }
  yield put(userSigInAction.fetchUserData(response));
}

export function* forgotPasswordSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigInAction.forgotPasswordData(response));
}

export function* resetPasswordSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigInAction.resetPasswordData(response));
}

export function* sendOTPSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigInAction.sendOTPResponse(response));
}

export function* verifyOTPSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigInAction.verifyOTPResponse(response));
}

export function* resendotpSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(userSigInAction.resendResponse(response));
}

export function* getVerifyFaceSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("user RegisterFace response==>.", response);
  yield put(userSigInAction.fetchVerifyFace(response));
}

export function* getSSODataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(userSigInAction.fetchSSODetails(response));
}

export function* watchSignInUsersAsync() {
  yield takeEvery(userSigInAction.userSignInData, getUserSignInSaga);
  yield takeEvery(userSigInAction.forgotPassword, forgotPasswordSaga);
  yield takeEvery(userSigInAction.resetPassword, resetPasswordSaga);
  yield takeEvery(userSigInAction.sendOTPData, sendOTPSaga);
  yield takeEvery(userSigInAction.userVerifyOTP, verifyOTPSaga);
  yield takeEvery(userSigInAction.resendOTP, resendotpSaga);
  yield takeEvery(userSigInAction.getVerifyFace, getVerifyFaceSaga);
  yield takeEvery(userSigInAction.getSSOData, getSSODataSaga);
}
