import React from "react";

const UploadFileIcon = () => {
  return (
    <>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 16H12V18H0V16Z" fill="#9CB8A6" />
        <path d="M0 18H2V28H0V18Z" fill="#9CB8A6" />
        <path d="M44 60H32V58H44V60Z" fill="#9CB8A6" />
        <path d="M44 58H42V48H44V58Z" fill="#9CB8A6" />
        <path d="M0 60L1.57361e-06 48H2L2 60H0Z" fill="#9CB8A6" />
        <path d="M2 60L2 58L12 58L12 60L2 60Z" fill="#9CB8A6" />
        <rect
          opacity="0.15"
          x="2"
          y="18"
          width="40"
          height="40"
          fill="#9CB8A6"
        />
        <mask
          id="mask0_2453_3411"
          maskUnits="userSpaceOnUse"
          x="12"
          y="0"
          width="48"
          height="48"
        >
          <rect x="12" width="48" height="48" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2453_3411)">
          <rect x="20" y="6" width="32" height="34" fill="#F1F9FF" />
          <path
            d="M22.5 43C20.9667 43 19.6667 42.4667 18.6 41.4C17.5333 40.3333 17 39.0333 17 37.5V10.5C17 8.96667 17.5333 7.66667 18.6 6.6C19.6667 5.53333 20.9667 5 22.5 5H49.5C51.0333 5 52.3333 5.53333 53.4 6.6C54.4667 7.66667 55 8.96667 55 10.5V37.5C55 39.0333 54.4667 40.3333 53.4 41.4C52.3333 42.4667 51.0333 43 49.5 43H22.5ZM22.5 40H49.5C50.2 40 50.792 39.758 51.276 39.274C51.7587 38.7913 52 38.2 52 37.5V10.5C52 9.8 51.7587 9.208 51.276 8.724C50.792 8.24133 50.2 8 49.5 8H22.5C21.8 8 21.2087 8.24133 20.726 8.724C20.242 9.208 20 9.8 20 10.5V37.5C20 38.2 20.242 38.7913 20.726 39.274C21.2087 39.758 21.8 40 22.5 40ZM25.2 35L32 28.2L35.65 31.8L40 26.3L46.95 35H25.2ZM28.5 19.5C27.5333 19.5 26.7087 19.1587 26.026 18.476C25.342 17.792 25 16.9667 25 16C25 15.0333 25.342 14.208 26.026 13.524C26.7087 12.8413 27.5333 12.5 28.5 12.5C29.4667 12.5 30.292 12.8413 30.976 13.524C31.6587 14.208 32 15.0333 32 16C32 16.9667 31.6587 17.792 30.976 18.476C30.292 19.1587 29.4667 19.5 28.5 19.5Z"
            fill="#0395FF"
          />
        </g>
      </svg>
    </>
  );
};

export default UploadFileIcon;
