import React from "react";

import { Provider } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import { BrowserRouter } from "react-router-dom";

import ReactDOM from "react-dom/client";

import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";

import "cropperjs/dist/cropper.css";
import "react-phone-number-input/style.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import "./index.css";

// for gtm
// const tagManagerArgs = {
//   gtmId: "G-D41DPKNEN1",
// };

// for pixel code
const advancedMatching = { em: "" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const SERVER = process.env.REACT_APP_SERVER;
console.log("SERVER==>> ", SERVER);

// Dummy Pixel ID for development
// ReactPixel.init("123456789012345", advancedMatching, options);

if (SERVER === "production") {
  // TagManager.initialize(tagManagerArgs);
  ReactPixel.init("557626429841131", advancedMatching, options);
  // ReactPixel.pageView(); // For tracking page view

  // facebook-domain-verification
  const meta = document.createElement("meta");
  meta.setAttribute("name", "facebook-domain-verification");
  meta.setAttribute("content", "ml93exu5bq9xxe55rsewxb1zpddjwc");

  document.head.appendChild(meta);
}

if (SERVER === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
