import React from "react";

import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "./loader";
import PlainAlertMessage from "./PlainAlertMessage";
import CancelLennarSubscriptionModal from "./CancelLennarSubscriptionModal";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";

const CancelSubscriptionModal = ({
  open,
  title,
  cancelLoader,
  handleClose = () => {},
  handleCancelSubscriptionBtn,
  isCancelSubscriptionSuccess,
  cancelSubscriptionDetails,
  successMessage,
  errorMessage,
  endDate,
  selectedProperty = {},
  getPropertyList,
}) => {
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(propertiesAction.clearCancelSubscriptionStates());
    handleClose();
    getPropertyList();
  };
  return (
    <>
      {selectedProperty?.provider_id === null ? (
        <Dialog
          open={open}
          fullWidth
          maxWidth="sm"
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!cancelSubscriptionDetails?.data && (
            <>
              <DialogTitle id="alert-dialog-title">
                {title ? title : "Cancel Subscription"}
                <Button
                  onClick={() => handleClose()}
                  className="modal-close-btn"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                    background: "transparent",
                  }}
                >
                  <CloseIcon sx={{ color: "#9CB8A6" }} />
                </Button>
              </DialogTitle>
              <DialogContent className="bg-white">
                <Typography sx={{ wordBreak: "break-word" }}>
                  By canceling this subscription you will leave yourself
                  unprotected after <b>{endDate}</b>. We will immediately record
                  a termination notice and further protection will require a new
                  set up fee.
                </Typography>
              </DialogContent>
            </>
          )}

          {!cancelSubscriptionDetails?.data && (
            <DialogActions sx={{ paddingRight: "2rem" }}>
              <Stack direction="row" spacing={2} pb={2}>
                {isCancelSubscriptionSuccess ? (
                  <Button
                    variant="link"
                    className="text-primary"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="link"
                      className="text-success"
                      onClick={() => {
                        handleCancelSubscriptionBtn("cancel_and_refund");
                        // handleModalClose();
                      }}
                    >
                      Cancel and Refund
                    </Button>
                    <Button
                      variant="link"
                      className="text-danger"
                      disabled={errorMessage}
                      onClick={() =>
                        handleCancelSubscriptionBtn("cancel_at_period_end")
                      }
                    >
                      Cancel Subscription
                    </Button>
                  </>
                )}
              </Stack>
            </DialogActions>
          )}

          <Box className="mt-4">
            {cancelSubscriptionDetails?.success ? (
              <PlainAlertMessage
                type="Success"
                onClose={true}
                onCloseFun={() => {
                  handleModalClose();
                }}
                message={cancelSubscriptionDetails?.message}
                buttons={
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    Close
                  </Button>
                }
              />
            ) : (
              cancelSubscriptionDetails?.data && (
                <PlainAlertMessage
                  type="Error"
                  onClose={true}
                  onCloseFun={() => {
                    handleModalClose();
                  }}
                  message={cancelSubscriptionDetails?.data?.message}
                  buttons={
                    <Button
                      className="popup_Btn error_popup_btn my-3"
                      onClick={() => {
                        handleModalClose();
                      }}
                    >
                      Cancel
                    </Button>
                  }
                />
              )
            )}
          </Box>

          <Loader open={cancelLoader} />
        </Dialog>
      ) : (
        <CancelLennarSubscriptionModal
          open={open}
          cancelLoader={cancelLoader}
          handleClose={handleClose}
          selectedProperty={selectedProperty}
          callListAPI={true}
          callListingAPI={handleModalClose}
          handleCancelSubscriptionBtn={handleCancelSubscriptionBtn}
          endDate={endDate}
        />
      )}
    </>
  );
};

export default CancelSubscriptionModal;
