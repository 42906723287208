import React, { useEffect, useId, useRef, useState } from "react";

const MapHomeIconSvg = `<svg
    width="32"
    height="36"
    viewBox="0 0 32 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="#0395FF" />
    <path d="M20 32H12L16 36L20 32Z" fill="#0395FF" />
    <mask
      id="mask0_450_4331"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="24"
      height="24"
    >
      <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_450_4331)">
      <path
        d="M8 25V15.625L6.2 17L5 15.4L8 13.1V10H10V11.575L16 7L27 15.4L25.8 16.975L24 15.625V25H8ZM10 23H15V19H17V23H22V14.1L16 9.525L10 14.1V23ZM8 9C8 8.16667 8.29167 7.45833 8.875 6.875C9.45833 6.29167 10.1667 6 11 6C11.2833 6 11.521 5.904 11.713 5.712C11.9043 5.52067 12 5.28333 12 5H14C14 5.83333 13.7083 6.54167 13.125 7.125C12.5417 7.70833 11.8333 8 11 8C10.7167 8 10.479 8.09567 10.287 8.287C10.0957 8.479 10 8.71667 10 9H8Z"
        fill="white"
      />
    </g>
  </svg>`;

const MyMapComponent = ({
  center,
  zoom,
  className,
  coordinates,
  streetView = false,
  mapTypeControl = false,
  zoomControl = false,
  fullscreenControl = false,
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var bounds = new window.google.maps.LatLngBounds();

  // set up popup class to generate popup
  // eslint-disable-next-line react-hooks/exhaustive-deps
  class Popup extends window.google.maps.OverlayView {
    position;
    containerDiv;
    constructor(position, content, type = "center") {
      super();
      this.position = position;

      if (type === "points") {
        content.classList.add("popup-bubble");

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");

        bubbleAnchor.classList.add("popup-bubble-anchor");
        bubbleAnchor.appendChild(content);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("popup-container");
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      } else {
        content.classList.add("popup-center-bubble");

        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("popup-center-container");
        this.containerDiv.appendChild(content);

        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      }
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      );
      if (!divPosition) return;

      // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? "block"
          : "none";

      if (display === "block") {
        this.containerDiv.style.left = divPosition.x + "px";
        this.containerDiv.style.top = divPosition.y + "px";
      }

      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }
  }

  // render map first time
  useEffect(() => {
    if (ref.current && !map) {
      if (streetView) {
        new window.google.maps.StreetViewService().getPanorama(
          { location: center, radius: 50 },
          function (data, status) {
            if (streetView) {
              if (status === window.google.maps.StreetViewStatus.OK) {
                const paronama = new window.google.maps.StreetViewPanorama(
                  ref.current,
                  {
                    position: center,
                    pov: {
                      heading: 0,
                      pitch: 0,
                    },
                  }
                );
                const whereToLookLatLng = new window.google.maps.LatLng(
                  parseFloat(center?.lat),
                  parseFloat(center?.lng)
                );
                const manLatLng = data.location.latLng;
                // console.log("manLatLng", manLatLng);
                const heading =
                  window.google.maps.geometry.spherical.computeHeading(
                    manLatLng,
                    whereToLookLatLng
                  );
                // console.log("heading", heading);
                const pov = paronama.getPov();
                // console.log(`get pov`, pov);
                pov.heading = heading;
                pov.zoom = 0;
                // console.log(`set pov`, pov);
                paronama.setPov(pov);
                setMap(paronama);
              } else {
                setMap(
                  new window.google.maps.Map(ref?.current, {
                    center: {
                      lat: parseFloat(center?.lat),
                      lng: parseFloat(center?.lng),
                    },
                    zoom: zoom,
                    mapTypeControl: mapTypeControl,
                    zoomControl: zoomControl,
                    streetViewControl: streetView,
                    fullscreenControl: fullscreenControl,
                  })
                );
              }
            }
          }
        );
      } else {
        setMap(
          new window.google.maps.Map(ref?.current, {
            center: {
              lat: parseFloat(center?.lat),
              lng: parseFloat(center?.lng),
            },
            zoom: zoom,
            mapTypeControl: mapTypeControl,
            zoomControl: zoomControl,
            streetViewControl: streetView,
            fullscreenControl: fullscreenControl,
          })
        );
      }
      // } else {
      //   bounds.extend(new window.google.maps.LatLng(center?.lat, center?.lng));
      //   let mapView = map;
      //   mapView.fitBounds(bounds, 150);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (ref.current && map) {
        setMap(null);
      }
    };
  }, [
    ref,
    map,
    center,
    center?.lat,
    center?.lng,
    zoom,
    streetView,
    mapTypeControl,
    zoomControl,
    fullscreenControl,
  ]);

  // useEffect for showing popup of coordinates and set zoom level based on coordinates
  useEffect(() => {
    if (coordinates && coordinates.length && map) {
      coordinates.map((add, index) => {
        let tag,
          text,
          type = "points";
        if (
          parseFloat(add?.lat) !== parseFloat(center?.lat) &&
          parseFloat(add?.lng) !== parseFloat(center?.lng)
        ) {
          tag = document.createElement("div");
          text = document.createTextNode(add.address);
          tag.appendChild(text);
          // console.log("tag", tag);
          if (coordinates.length >= 1) {
            bounds.extend(
              new window.google.maps.LatLng(
                parseFloat(add?.lat),
                parseFloat(add?.lng)
              )
            );
            let mapView = map;
            mapView.fitBounds(bounds, 50);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          let popup = new Popup(
            new window.google.maps.LatLng(
              parseFloat(add?.lat),
              parseFloat(add?.lng)
            ),
            tag,
            type
          );

          return popup.setMap(map);
        }
      });
      bounds.extend(new window.google.maps.LatLng(center?.lat, center?.lng));
      let mapView = map;
      mapView.fitBounds(bounds, 50);
    }
  }, [map, coordinates, Popup, bounds, center?.lat, center?.lng]);

  // useEffect for centering showing center marker
  useEffect(() => {
    if (center && map) {
      const type = "center";
      const tag = document.createElement("span");
      const icon = MapHomeIconSvg;
      tag.insertAdjacentHTML("beforeend", icon);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      let popup = new Popup(
        new window.google.maps.LatLng(
          parseFloat(center?.lat),
          parseFloat(center?.lng)
        ),
        tag,
        type
      );
      return popup.setMap(map);
    }
  }, [Popup, center, map]);

  return (
    <>
      <div className={className} id={useId()} ref={ref} />
    </>
  );
};

export default MyMapComponent;
