import { useState } from "react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import LockIcon from "@mui/icons-material/Lock";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import CreateCountyRecorderForm from "./CreateCountyRecorderForm";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";

const AddCountyRecorderComponent = ({ goBack, listingAPI = () => {} }) => {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const { countyRecordersLoader, createCountyRecorders } = useSelector(
    (state) => state.countyRecorders
  );

  const closeAlertPopup = () => {
    setOpenPopup(false);
    if (createCountyRecorders?.success) {
      listingAPI();
      goBack();
    }

    dispatch(countyRecordersAction.fetchCountyRecordersData({}));
  };

  useEffect(() => {
    if (getObjLength(createCountyRecorders)) {
      setOpenPopup(true);
    }
  }, [createCountyRecorders]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mb={3}
      >
        <Typography variant="h6" className="card-title fw-600">
          Create County Recorder
        </Typography>
        <Tooltip
          className="d-none d-sm-block"
          title="Go to Providers list"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <Button
            variant="contained"
            className="success-btn d-none d-sm-block"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to go back to  Providers list?"
                )
              ) {
                goBack();
              }
            }}
          >
            Go Back
          </Button>
        </Tooltip>
        <Tooltip
          className="d-flex d-sm-none"
          title="Go to Providers list"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <IconButton
            className="success-btn d-flex d-sm-none"
            variant="outlined"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to go back to Providers list?"
                )
              ) {
                goBack();
              }
            }}
            sx={{ color: "#FFF" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box pb={3}>
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "white",
            width: "100%",
            boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
          }}
        >
          <Typography
            align="center"
            variant="subtitle1"
            className="py-1 formHeader"
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
          </Typography>
          <Box className="accountBoxWrapper">
            {/* signup form */}

            <>
              <Typography align="center" variant="body1">
                We just need to gather some information to set up County
                Recorder's digital profile.
              </Typography>
              <CreateCountyRecorderForm
                goBack={goBack}
                listingAPI={listingAPI}
                closeAlertPopup={closeAlertPopup}
              />
            </>
          </Box>
        </Box>
      </Box>

      {/* created provider alert */}
      {getObjLength(createCountyRecorders) && openPopup ? (
        <AlertDialog
          type={createCountyRecorders?.success ? "Success" : "Error"}
          openPopup={openPopup}
          onClose={true}
          mesage={
            createCountyRecorders?.message ||
            createCountyRecorders?.data?.message
          }
          onCloseFun={closeAlertPopup}
        />
      ) : null}

      <Loader open={countyRecordersLoader} />
    </>
  );
};

export default AddCountyRecorderComponent;
