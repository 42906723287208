import { createSlice } from "@reduxjs/toolkit";

const intialSignUpDetails = {
  lennarSignUpDetails: {},
  lennarPropertiesDetails: {},
  lennarGenerateLinkDetails: {},
  phoneVerificationDetails: {},
  emailVerificationDetails: {},
  resedPhoneOTPDetails: {},
  resedEmailOTPDetails: {},
  lennarLoader: false,
};

const lennarSignUp = createSlice({
  name: "lennarSignupDetails",
  initialState: intialSignUpDetails,
  reducers: {
    resetLennarSignUpState: () => intialSignUpDetails,
    createLennarUser(state, action) {
      state.lennarLoader = true;
      // removing password and confirm password from object
      let updatedObject = {};

      for (let key in action.payload?.data) {
        if (key !== "password" && key !== "confirm_password") {
          updatedObject[key] = action.payload?.data[key];
        }
      }
      console.log("updatedObject==>", updatedObject);
      let data = {
        ...updatedObject,
      };
      localStorage.setItem("userSignupDetails", JSON.stringify(data));
    },
    fetchCreateLennarUserData(state, action) {
      if (action.payload?.success) {
        localStorage.setItem(
          "user_Id",
          JSON.stringify(action.payload?.data?.user_id)
        );
        localStorage.setItem("currentStep", 0);
        localStorage.setItem("step_key", "SEND_OTP");
        state.lennarSignUpDetails = action.payload?.data?.user_id;
      }
      state.lennarSignUpDetails = action.payload;
      state.lennarLoader = false;
    },
    createProperties(state, action) {
      state.lennarLoader = true;
    },
    fetchcreatedPropertiesData(state, action) {
      let uid = localStorage.getItem("user_Id");
      if (action.payload?.success) {
        localStorage.clear();
        localStorage.setItem("currentStep", 2);
        localStorage.setItem("user_Id", uid);
      }
      state.lennarPropertiesDetails = action.payload;
      state.lennarLoader = false;
    },
    phoneVerificationData(state, action) {
      state.lennarLoader = true;
    },
    fetchPhoneVerificationData(state, action) {
      let uid = localStorage.getItem("user_Id");
      if (action.payload?.success) {
        localStorage.setItem("currentStep", 0);
        localStorage.setItem("user_Id", uid);
        localStorage.setItem("phone_verified", 1);
      }
      state.phoneVerificationDetails = action.payload;
      state.lennarLoader = false;
    },
    emailVerificationData(state, action) {
      state.lennarLoader = true;
    },
    fetchEmailVerificationData(state, action) {
      let uid = localStorage.getItem("user_Id");
      if (action.payload?.success) {
        localStorage.setItem("currentStep", 0);
        localStorage.setItem("user_Id", uid);
      }
      state.emailVerificationDetails = action.payload;
      state.lennarLoader = false;
    },
    resendPhoneOTP(state, action) {
      state.lennarLoader = true;
    },
    fetchResendPhoneOTPData(state, action) {
      state.resedPhoneOTPDetails = action.payload;
      state.lennarLoader = false;
    },
    resendEmailOTP(state, action) {
      state.lennarLoader = true;
    },
    fetchResendEmailOTPData(state, action) {
      state.resedEmailOTPDetails = action.payload;
      state.lennarLoader = false;
    },
    clearResendOTP(state, action) {
      state.resedEmailOTPDetails = {};
      state.resedPhoneOTPDetails = {};
      state.lennarLoader = false;
    },
    generateLink(state, action) {
      state.lennarLoader = true;
    },
    fetchGenerateLinkData(state, action) {
      state.lennarGenerateLinkDetails = action.payload;
      state.lennarLoader = false;
    },
  },
});
export const lennarSignUpAction = lennarSignUp.actions;
export default lennarSignUp.reducer;
