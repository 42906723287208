import { createSlice } from "@reduxjs/toolkit";

const intialUserSettingsState = {
  marketingMailDetails: {},
  securityPinDetails: {},
  settingsLoader: false,
};

const userSettings = createSlice({
  name: "userSettingDetails",
  initialState: intialUserSettingsState,
  reducers: {
    getMarketingMailData: (state, action) => {
      state.settingsLoader = true;
      console.log("getMarketingMailData slice", action.payload);
    },
    fetchMarketingMailData: (state, action) => {
      console.log("fetchMarketingMailData slice", action.payload);
      state.settingsLoader = false;
      state.marketingMailDetails = action.payload;
    },
    getSecurityPinData(state, action) {
      state.securityPinDetails = {};
      state.settingsLoader = true;
    },

    fetchSecurityPinData(state, action) {
      state.securityPinDetails = action.payload;
      state.settingsLoader = false;
    },
    clearSecurityPINData(state, action) {
      state.securityPinDetails = {};
      state.settingsLoader = false;
    },
  },
});

export const userSettingsAction = userSettings.actions;
export default userSettings.reducer;
