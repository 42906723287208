/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./Style.scss";
import { Box, Typography } from "@mui/material";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      margin: "10px",
      color: "#32325d",
      innerWidth: "100px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function CardSection({ stripeErr, validateErr }) {
  return (
    <Box className="stripeParent">
      <Typography variant="body">Card details</Typography>
      <CardElement
        className="border  border-dark sign-textField "
        options={CARD_ELEMENT_OPTIONS}
        onChange={(e) => validateErr(e)}
      />
      {stripeErr && (
        <p className="stripeValidation text-danger m-0 p-2">{stripeErr}</p>
      )}
    </Box>
  );
}
export default CardSection;
