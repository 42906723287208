import React from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "./loader";
import AlertDialog from "./AlertDialog";
import VisaCard from "../../assets/dashboard/visa.svg";
import DialogBox from "../userProfile/Dashboard/cardPopup";
import MasterCard from "../../assets/dashboard/mastercard.svg";
import DiscoverCard from "../../assets/dashboard/discover.svg";
import AddCardForm from "../userProfile/Dashboard/addCardForm";
import AmericanCard from "../../assets/dashboard/american-express.svg";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import {
  getMonthFromString,
  getObjLength,
  getPrefixUrl,
} from "../helpers/utils";

const Paperstyles = {
  "&.MuiPaper-elevation0": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(52, 75, 91, 0.06)",
    borderRadius: "4px",
    p: 3,
    mb: 2,
  },
};

const styles = {
  "&.MuiButton-outlined": {
    background: "#FFFFFF",
    border: "1px solid #15BE53",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#15BE53",
    padding: "11px",
    minWidth: "auto",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

const CardCRUD = ({ isSecondaryUser = false, isServiceProvider = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // states
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isRemoveCardModalOpen, setIsRemoveCardModalOpen] = useState(false);

  // store data
  const {
    userProfileDetails,
    cardDetails,
    addNewCardLoader,
    setDefaultCard: defaultCardData,
    defaultCardLoader,
    removeCardDetails: removeCardData,
    removedCardLoader,
    cardLoader,
  } = useSelector((state) => state?.userProfile);

  // handle open add card modal
  const handleAddCardModalOpen = () => {
    setIsAddCardModalOpen(true);
  };

  // handle close add card modal
  const handleAddCardModalClose = () => {
    setIsAddCardModalOpen(false);
    if (location?.state?.isAddPaymentModal) {
      navigate(location.pathname, { replace: true });
    }
  };

  const setDefaultCard = (data) => {
    let URL =
      `${getPrefixUrl(userProfileDetails)}/card/` + data?.id + "/set-default";
    if (isServiceProvider) {
      URL = `service-provider/card/` + data?.id + "/set-default";
    }

    dispatch(
      userProfileAction.setDefaultCard({
        url: URL,
        data: {},
        token: true,
      })
    );
  };

  const removeCard = (data) => {
    setSelectedCard(data);
    setIsRemoveCardModalOpen(true);
  };

  const handleDeleteCard = () => {
    if (isServiceProvider) {
      dispatch(
        userProfileAction.removeServiceProvidersCard({
          url: `service-provider/card/${selectedCard?.id}`,
          data: {},
          token: true,
        })
      );
    } else {
      dispatch(
        userProfileAction.removeNewCard({
          url:
            `${getPrefixUrl(userProfileDetails)}/card/` +
            selectedCard?.id +
            "/delete",
          data: {},
          token: true,
        })
      );
    }
  };

  const getCardListing = () => {
    let URL = `${
      isServiceProvider ? "service-provider" : getPrefixUrl(userProfileDetails)
    }`;
    dispatch(
      userProfileAction.userCardDetails({
        url: `${URL}/card/list`,
        data: {},
        token: true,
      })
    );
  };

  return (
    <>
      <Paper elevation={0} className="payment-info" sx={Paperstyles}>
        <Box
          className="d-flex align-items-center justify-content-between"
          sx={{ mb: 3 }}
        >
          <Typography variant="h6" className="card-title">
            Payment information
          </Typography>
          {/* {!isSecondaryUser && ( */}
          {!isSecondaryUser && (
            <Button
              className="d-none d-md-block"
              onClick={handleAddCardModalOpen}
              variant="outlined"
            >
              Add new card
            </Button>
          )}
          {/* )} */}
          <Button
            variant="outlined"
            className="d-block d-md-none EditBtn p-2"
            sx={styles}
            onClick={handleAddCardModalOpen}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Box>
        {Object.keys(cardDetails)?.length > 0 &&
          cardDetails?.success &&
          cardDetails?.data?.map((data, key) => (
            <>
              {/* <hr /> */}
              {/* desktop view */}
              <Box className="pt-2 d-none d-sm-block" key={key}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    mb={1}
                    className="d-flex align-items-center"
                  >
                    <Box className="px-3 w-100">
                      {data.brand === "MasterCard" ? (
                        <img width={"10%"} src={MasterCard} alt="Master Card" />
                      ) : data.brand === "Visa" ? (
                        <img width={"10% "} src={VisaCard} alt="Visa Card" />
                      ) : data.brand === "American Express" ? (
                        <img
                          width={"10%"}
                          src={AmericanCard}
                          alt="American Card"
                        />
                      ) : data.brand === "Discover" ? (
                        <img
                          width={"10%"}
                          src={DiscoverCard}
                          alt="Discover Card"
                        />
                      ) : (
                        <CreditCardIcon fontSize="large" />
                      )}

                      <Typography
                        variant="caption"
                        className="visa-num ms-3 text-uppercase "
                      >
                        {data.brand} - **** {data.last4}
                        &nbsp;&nbsp;
                        {data.default === 1 && (
                          <Chip
                            sx={{ backgroundColor: "#46b14c33" }}
                            className="fw-600 text-success"
                            label="DEFAULT"
                          />
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid container>
                      <Grid
                        item
                        xs={8}
                        sm={6}
                        md={6}
                        lg={6}
                        className="d-flex justify-content-center align-items-center"
                      >
                        Expiry Date, {data.exp_month} {data.exp_year}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={6}
                        md={6}
                        lg={6}
                        className="text-end"
                      >
                        {data.default === 0 && (
                          <>
                            <Tooltip
                              title="Set as Default"
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <IconButton
                                sx={{
                                  color: "#43D551",
                                }}
                                disabled={
                                  isSecondaryUser || data?.id
                                    ? data?.exp_year <= new Date().getFullYear()
                                      ? getMonthFromString(
                                          data?.exp_month,
                                          data?.exp_year
                                        ) <
                                        new Date().getMonth() + 1
                                        ? true
                                        : false
                                      : false
                                    : true
                                }
                                onClick={() => setDefaultCard(data)}
                              >
                                <CheckCircleIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Remove card"
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <IconButton
                                sx={{ color: "#FC2125" }}
                                onClick={() => removeCard(data)}
                                disabled={isSecondaryUser}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* mobile view */}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                className="d-flex d-sm-none"
              >
                <Stack direction="column">
                  <Box
                    sx={{
                      minWidth: data.default === 1 ? "200px" : "",
                    }}
                  >
                    {data.brand === "MasterCard" ? (
                      <img width={"15%"} src={MasterCard} alt="Master Card" />
                    ) : data.brand === "Visa" ? (
                      <img width={"15% "} src={VisaCard} alt="Visa Card" />
                    ) : data.brand === "American Express" ? (
                      <img
                        width={"15%"}
                        src={AmericanCard}
                        alt="American Card"
                      />
                    ) : data.brand === "Discover" ? (
                      <img
                        width={"15%"}
                        src={DiscoverCard}
                        alt="Discover Card"
                      />
                    ) : (
                      <CreditCardIcon fontSize="large" />
                    )}
                    <Typography
                      variant="caption"
                      className="visa-num ms-2 text-uppercase"
                      sx={{ mr: 1 }}
                    >
                      **** {data.last4}
                    </Typography>
                    {data.default === 1 && (
                      <Chip
                        sx={{
                          backgroundColor: "#46b14c33",
                          p: 0,
                        }}
                        className="fw-600 text-success"
                        label="DEFAULT"
                      />
                    )}
                  </Box>
                  <Typography>
                    Expiry Date, {data.exp_month} {data.exp_year}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  {data.default === 0 && (
                    <>
                      <Tooltip
                        title="Set as Default"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <IconButton
                          sx={{ color: "#43D551" }}
                          onClick={() => setDefaultCard(data)}
                          disabled={
                            isSecondaryUser || data?.id
                              ? data?.exp_year <= new Date().getFullYear()
                                ? getMonthFromString(
                                    data?.exp_month,
                                    data?.exp_year
                                  ) <
                                  new Date().getMonth() + 1
                                  ? true
                                  : false
                                : false
                              : true
                          }
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title="Remove card"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <IconButton
                          sx={{ color: "#FC2125" }}
                          onClick={() => removeCard(data)}
                          disabled={isSecondaryUser}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Stack>
              </Stack>
              {key < cardDetails?.data?.length - 1 && <hr />}
            </>
          ))}
      </Paper>
      {isAddCardModalOpen && (
        <DialogBox
          width="sm"
          openPopup={isAddCardModalOpen}
          setOpenPopup={setIsAddCardModalOpen}
          onClose={handleAddCardModalClose}
          mesage={
            // <Elements stripe={stripePromise}>
            <AddCardForm
              openPopup={isAddCardModalOpen}
              loader={addNewCardLoader}
              handleCloseAddCardModal={handleAddCardModalClose}
            />
            // </Elements>
          }
        />
      )}

      {getObjLength(defaultCardData) && (
        <AlertDialog
          type={defaultCardData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(userProfileAction.emptyDefaultCardDetails({}));
          }}
          mesage={
            defaultCardData?.success
              ? defaultCardData?.message
              : defaultCardData?.data?.message
          }
          buttons={
            <>
              {defaultCardData?.success ? (
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    dispatch(userProfileAction.emptyDefaultCardDetails({}));
                    getCardListing();
                  }}
                >
                  Ok, All Good
                </Button>
              ) : (
                <Button
                  className="popup_Btn error_popup_btn "
                  onClick={() => {
                    dispatch(userProfileAction.emptyDefaultCardDetails({}));
                  }}
                >
                  Ok, Close
                </Button>
              )}
            </>
          }
        />
      )}

      {isRemoveCardModalOpen ? (
        getObjLength(removeCardData) ? (
          <>
            {removeCardData?.success && (
              <AlertDialog
                type={removeCardData?.success ? "Success" : "Error"}
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  setIsRemoveCardModalOpen(false);
                  dispatch(userProfileAction.getRemovedCardDetails({}));
                }}
                mesage={
                  removeCardData?.success
                    ? removeCardData?.message
                    : removeCardData?.data?.message
                }
                buttons={
                  <>
                    {removeCardData?.success ? (
                      <Button
                        className="popup_Btn success_popup_btn"
                        onClick={() => {
                          setIsRemoveCardModalOpen(false);
                          dispatch(userProfileAction.getRemovedCardDetails({}));
                          getCardListing();
                        }}
                      >
                        Ok, All Good
                      </Button>
                    ) : (
                      <Button
                        className="popup_Btn error_popup_btn "
                        onClick={() => {
                          setIsRemoveCardModalOpen(false);
                          dispatch(userProfileAction.getRemovedCardDetails({}));
                        }}
                      >
                        Ok, Close
                      </Button>
                    )}
                  </>
                }
              />
            )}
          </>
        ) : (
          <>
            <AlertDialog
              type="Warning"
              openPopup={true}
              // setOpenPopup={setRemoveCardPopup}
              onCloseFun={() => setIsRemoveCardModalOpen(false)}
              onClose={true}
              loader={removedCardLoader}
              mesage={" Are you sure, you want to delete this card?"}
              buttons={
                <>
                  <Button
                    onClick={() => setIsRemoveCardModalOpen(false)}
                    className="popup_Btn other_popup_btn mx-2"
                    variant="outlined"
                  >
                    Nope
                  </Button>
                  <Button
                    onClick={handleDeleteCard}
                    className="popup_Btn success_popup_btn mx-2"
                    variant="outlined"
                    color="error"
                  >
                    Yes
                  </Button>
                </>
              }
            />
          </>
        )
      ) : null}

      <Loader open={defaultCardLoader || cardLoader} />
    </>
  );
};

export default CardCRUD;
