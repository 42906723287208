import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { autoFocusOnField, getObjLength } from "../../helpers/utils";
import { serviceProviderSignupAction } from "../../../redux/slices/serviceProvider/SPSignUpSlice";
import { resendOTPSeconds } from "../../helpers/constants";

const SPPersonalTokenScreen = (props) => {
  const [otp, SetOTP] = useState("");
  const [otpErr, SetOTPErr] = useState(undefined);
  const [otpSucess, SetOTPSucess] = useState("");
  const [emailScreen, SetEmailScreen] = useState(false);
  const [diableMobileBtn, setdiableMobileBtn] = useState(false);
  const [diableEmailBtn, setdiableEmailBtn] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [emailOTPErr, setEmailOTPErr] = useState(undefined);
  const [emailOTPSucess, setEmailOTPSucess] = useState("");
  const [emailSpamMsg, setEmailSpamMsg] = useState(
    " If you haven't received the email in your inbox, please check your email spam folder."
  );
  const [validate, setValidate] = useState(false);

  //initializes the timer states for hook
  const {
    timer,
    isTimerActive,
    startTimer,
    stopTimer,
    formatTimeForResendOTP,
  } = useTimer(120);

  const dispatch = useDispatch();
  const {
    spSignupLoader,
    createAccResponse,
    otpVerificationResponse,
    newPhoneOTP,
    step1Mobile,
    emailOTPVerificationResponse,
    step1Email,
    newEmailOTP,
    spSignupDetails,
    resendPhone_OTP,
    resendEmail_OTP,
  } = useSelector((state) => state.serviceProviderSignup);
  const [mobileVerified, setMobileVerified] = useState(step1Mobile);

  const spDataFromSession = JSON.parse(localStorage.getItem("spSignupDetails"));
  const isMobileVerified = JSON.parse(localStorage.getItem("emailScreen"));
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  console.log("signup details==m>>", spSignupDetails);
  console.log(
    userIdFromSession,
    "<<=== session values userSignupDetails==>>",
    spDataFromSession
  );

  const validated = () => {
    if (!otpVerificationResponse.success || !newPhoneOTP?.success) {
      SetOTPErr(
        otpVerificationResponse?.data?.message || newPhoneOTP?.data?.message
      );
      SetOTP("");
      SetOTPSucess("");
      handleClose();
    } else {
      SetOTPErr("");
      handleClose();
    }
  };
  const validatedEmail = () => {
    if (!emailOTPVerificationResponse.success || !newEmailOTP?.success) {
      setEmailOTPErr(
        emailOTPVerificationResponse?.data?.message ||
          newEmailOTP?.data?.message
      );
      setEmailOTP("");
      setEmailOTPSucess("");
      handleCloseEmailLoader();
    } else {
      setEmailOTPErr("");
      handleCloseEmailLoader();
    }
  };
  const handleChange = (otp) => {
    SetOTPSucess("");
    if (otp.length === 6) {
      SetOTPErr("");
    }
    console.log("otp handled==>>", otp);
    SetOTP(otp);
    setValidate(false);
  };

  const handleChangeEmailOTP = (otp) => {
    setEmailOTPSucess("");
    if (otp.length === 6) {
      setEmailOTPErr("");
      setEmailSpamMsg("");
    }
    console.log("otp email handled==>>", otp);
    setEmailOTP(otp);
    setValidate(false);
  };

  const handleClose = () => {
    console.log("otp number==>", newPhoneOTP);

    if (otpVerificationResponse?.success) {
      SetOTPSucess(otpVerificationResponse?.message);
      SetEmailScreen(true);
      setMobileVerified(true);
      setdiableMobileBtn(true);
      stopTimer();
    }

    if (newPhoneOTP?.success) {
      SetOTPSucess(newPhoneOTP?.message);
      setdiableMobileBtn(false);
      SetOTPErr("");
    }
  };
  const handleCloseEmailLoader = () => {
    console.log("otp email==>", newEmailOTP);
    if (emailOTPVerificationResponse?.success) {
      setEmailOTPSucess(emailOTPVerificationResponse?.message);
      setdiableEmailBtn(true);
    }
    if (newEmailOTP?.success) {
      setEmailOTPSucess(
        newEmailOTP?.message +
          " If you haven't received the email in your inbox, please check your email spam folder."
      );
      setEmailSpamMsg("");
      setEmailOTPErr("");
      setdiableEmailBtn(false);
    }
  };

  const otpValidate = (event) => {
    event.preventDefault();
    console.log("in otp validation", otp.length);
    SetOTPSucess("");
    // SetEmailScreen(true);
    // props.onBtnClick();
    if (otp.length === 6) {
      let inputParam = {
        user_id: getObjLength(createAccResponse)
          ? createAccResponse.data?.user_id
          : userIdFromSession,
        verification_code: otp,
        step_key: "VERIFY_PHONE_OTP",
        step: 0,
      };
      autoFocusOnField();
      dispatch(serviceProviderSignupAction.clearResedOTP());
      dispatch(
        serviceProviderSignupAction.spVerifyOTP({
          url: "service-provider/verify-otp/phone",
          data: inputParam,
        })
      );
    } else {
      SetOTPErr("Please Enter Valid Token");
    }
  };

  const emailOTPValidate = (event) => {
    event.preventDefault();
    console.log("in email otp validation", emailOTP.length);
    setEmailOTPSucess("");
    // props.onBtnClick();
    if (emailOTP.length === 6) {
      let inputParam = {
        user_id: getObjLength(createAccResponse)
          ? createAccResponse.data?.user_id
          : userIdFromSession,
        verification_code: emailOTP,
        step_key: "VERIFY_EMAIL_OTP",
        step: 1,
      };
      autoFocusOnField();
      dispatch(serviceProviderSignupAction.clearResedOTP());
      dispatch(
        serviceProviderSignupAction.spVerifyEmailOTP({
          url: "service-provider/verify-otp/email",
          data: inputParam,
        })
      );
    } else {
      setEmailOTPErr("Please Enter Valid Token");
    }
  };

  const OTPFieldStyle = {
    background: "#F6F9FC",
    border: "1px solid rgba(52, 75, 91, 0.1)",
    borderRadius: "3px",
    width: "40px",
    height: "55px",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    textAlign: "center",
    color: "#0D120B",
  };

  const resendOTP = () => {
    console.log("resend OTP==>>");
    let inputParam = {
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
    };
    dispatch(
      serviceProviderSignupAction.resedOTP({
        url: "service-provider/resend-otp/phone",
        data: inputParam,
      })
    );
    SetOTP("");
    autoFocusOnField();
  };

  const resendEmailOTP = () => {
    console.log("resend email OTP==>>");
    setEmailSpamMsg("");
    let inputParam = {
      user_id: getObjLength(createAccResponse)
        ? createAccResponse.data?.user_id
        : userIdFromSession,
    };
    dispatch(
      serviceProviderSignupAction.resedEmailOTP({
        url: "service-provider/resend-otp/email",
        data: inputParam,
      })
    );
    setEmailOTP("");
    autoFocusOnField();
  };

  const resendOTPButtonClick = (type) => {
    if (type === "email") {
      resendEmailOTP();
    } else {
      resendOTP();
    }
    startTimer(resendOTPSeconds);
  };
  const goNext = () => {
    console.log("go next step==>");
    if (props.from === "Admin") {
      props.setScreen();
    } else {
      props.onBtnClick();
    }
    stopTimer();
  };

  useEffect(() => {
    if (isMobileVerified === 1) {
      SetEmailScreen(true);
      setMobileVerified(true);
      stopTimer();
    }
  }, []);

  useEffect(() => {
    if (step1Mobile) {
      // props.onBtnClick();
      handleClose();
    }

    if (Object.keys(newPhoneOTP).length > 0) {
      if (newPhoneOTP?.success) {
        handleClose();
      } else {
        validated();
      }
    }
  }, [step1Mobile, newPhoneOTP]);

  useEffect(() => {
    if (step1Email) {
      // props.onBtnClick();
      handleCloseEmailLoader();
    }

    if (Object.keys(newEmailOTP).length > 0) {
      if (newEmailOTP?.success) {
        handleCloseEmailLoader();
      } else {
        validatedEmail();
      }
    }
  }, [step1Email, newEmailOTP]);

  useEffect(() => {
    if (
      Object.keys(otpVerificationResponse).length > 0 &&
      !otpVerificationResponse.success
    ) {
      validated();
    }
  }, [otpVerificationResponse]);

  useEffect(() => {
    if (
      Object.keys(emailOTPVerificationResponse).length > 0 &&
      !emailOTPVerificationResponse.success
    ) {
      validatedEmail();
    }
  }, [emailOTPVerificationResponse]);

  useEffect(() => {
    if (resendPhone_OTP) {
      resendOTP();
      resendEmailOTP();
    }
  }, [resendPhone_OTP]);
  useEffect(() => {
    if (resendEmail_OTP) {
      resendEmailOTP();
    }
  }, [resendEmail_OTP]);

  console.log(newPhoneOTP, "opt err==", otpVerificationResponse);
  let phn_num = spDataFromSession?.phone
    ? spDataFromSession?.phone
    : spSignupDetails?.data?.phone;
  let emailAdd = spDataFromSession?.email
    ? spDataFromSession?.email
    : spSignupDetails?.data?.email;
  var censorWord = function (str) {
    if (str.length > 4) {
      return str[0] + str[1] + "*".repeat(str.length - 4) + str.slice(-2);
    } else {
      return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
    }
  };

  var censorEmail = function (email) {
    if (email) {
      var arr = email?.split("@");
      return censorWord(arr[0]) + "@" + arr[1];
    } else {
      return email;
    }
  };

  console.log(phn_num, "email substring==>", censorEmail(emailAdd));
  const phn_num1 = phn_num?.substring(phn_num?.length - 4);
  console.log("isMobileVerified==>", isMobileVerified);
  return (
    <>
      <Box className="accountBoxWrapper">
        {!emailScreen ? (
          <form onSubmit={otpValidate}>
            <Typography align="center" variant="h3">
              Enter your personal token from Mobile number
            </Typography>
            <Typography align="center" variant="body1">
              Please enter the 6 digit security code we have sent to your mobile
              phone * *** *** {phn_num1 ? phn_num1 : "4321"}.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={otp}
                isInputNum
                onChange={handleChange}
                numInputs={6}
                shouldAutoFocus
                disabled={diableMobileBtn}
                inputStyle={OTPFieldStyle}
              />
              {otpErr?.length > 0 && otpSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  {otpErr}
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {otpSucess}
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={otpValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableMobileBtn}
              >
                Verify mobile number
              </Button>
            </div>
            <div className="text-center tokenCodeText">
              {isTimerActive ? (
                <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
              ) : (
                <>
                  Did not receive the code?
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => resendOTPButtonClick("phone")}
                  >
                    <Link className="text-blue text-decoration-none" to="">
                      Get a new one
                    </Link>
                  </Button>
                </>
              )}
            </div>
          </form>
        ) : (
          <form onSubmit={emailOTPValidate}>
            <Typography align="center" variant="h3">
              Enter your personal token from Email ID
            </Typography>
            <Typography align="center" variant="body1">
              Please enter the 6 digit security code we have sent to your Email
              ID &nbsp; {censorEmail(emailAdd)}.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={emailOTP}
                isInputNum
                onChange={handleChangeEmailOTP}
                numInputs={6}
                // shouldAutoFocus
                inputStyle={OTPFieldStyle}
                disabled={diableEmailBtn}
              />
              {emailOTPErr?.length > 0 && emailOTPSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  {emailOTPErr}
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {emailOTPSucess}
                </Typography>
              )}
              {emailSpamMsg?.length > 0 && (
                <Typography
                  variant="caption"
                  className="text-success 3 fw-500"
                  p={2}
                >
                  <b>{emailSpamMsg?.length > 0 && emailSpamMsg}</b>
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={emailOTPValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableEmailBtn}
              >
                Verify Email ID
              </Button>
            </div>
            <div className="text-center tokenCodeText">
              {isTimerActive ? (
                <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
              ) : (
                <>
                  Did not receive the code?
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => resendOTPButtonClick("email")}
                  >
                    <Link className="text-blue text-decoration-none" to="">
                      Get a new one
                    </Link>
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </Box>
      <Loader open={spSignupLoader} />

      {mobileVerified && step1Email && (
        <AlertDialog
          type="Success"
          openPopup={mobileVerified && step1Email}
          onClose={false}
          mesage={"Mobile number and Email Id verified successfully."}
          buttons={
            <div className="text-center stepBtn mt-4">
              <Button
                onClick={goNext}
                className="next_button "
                variant="contained"
              >
                Next Step
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default SPPersonalTokenScreen;
