import React, { lazy, Suspense } from "react";

import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

import step6Img from "../../../src/assets/howItWorks/draw.svg";
import step3Img from "../../../src/assets/howItWorks/live_help.svg";
import step4Img from "../../../src/assets/howItWorks/checklist.svg";
import step1Img from "../../../src/assets/howItWorks/person_add.svg";
import step2Img from "../../../src/assets/howItWorks/frame_inspect.svg";
import step5Img from "../../../src/assets/howItWorks/familiar_face_and_zone.svg";

import "../../styles/howItworks.scss";
import "../../styles/homepage.scss";

const Step1 = lazy(() =>
  import("../common/customSVGs/how-it-works-svgs/Step1")
);
const Step2 = lazy(() =>
  import("../common/customSVGs/how-it-works-svgs/Step2")
);
const Step3 = lazy(() =>
  import("../common/customSVGs/how-it-works-svgs/Step3")
);
const Step4 = lazy(() =>
  import("../common/customSVGs/how-it-works-svgs/Step4")
);
const Step5 = lazy(() =>
  import("../common/customSVGs/how-it-works-svgs/Step5")
);
const Step6 = lazy(() =>
  import("../common/customSVGs/how-it-works-svgs/Step6")
);

const SignupTimeline = () => {
  return (
    <>
      {/* in desktop view */}
      {/* <Box
    py={6}
    width="650px"
    // height="700px"
    className="d-none d-lg-inline-flex justify-content-center align-items-center"
  > */}
      <Timeline position="alternate" className="timeline">
        {/* start text */}
        <TimelineItem>
          <TimelineSeparator sx={{ minHeight: "100px" }}>
            {/* <TimelineConnector /> */}
            <TimelineDot
              className="timeline-start-end-content"
              sx={{ margin: "0 0 20px 0" }}
            >
              Simple Sign up Process
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent
            sx={{ py: "20px", px: 2 }}
            // className="content"
          ></TimelineContent>
        </TimelineItem>

        {/* step 1 */}
        <TimelineItem>
          {/* <TimelineOppositeContent
          // align="center"
          variant="body2"
          color="#0395FF"
          className="timelineStartOppositeContent"
        >
          <Box className="bg-white p-3 start-end-desktop-circle">
            Simple Signup Process
          </Box>
        </TimelineOppositeContent> */}

          <TimelineSeparator>
            {/* <TimelineConnector /> */}
            <TimelineDot className="timeline-dot">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <Step1 />
                </LazyLoadComponent>
              </Suspense>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent
            sx={{ py: "20px", px: 2 }}
            // className="content"
          >
            <Typography className="content-heading">
              1. Setup Account
            </Typography>
            <Typography
              className="content-description"
              // sx={{ width: "270px" }}
            >
              Only one account is needed for all properties. To start you will
              create your account. We will validate your account with both email
              and SMS pin to set up your two-factor protection.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* step 2 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className="timeline-dot">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <Step2 />
                </LazyLoadComponent>
              </Suspense>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}>
            <Typography className="content-heading">
              2. Property Search
            </Typography>
            <Typography className="content-description">
              You will then add your property or properties you want
              EquityProtect to monitor and protect for you.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* step 3 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className="timeline-dot">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <Step3 />
                </LazyLoadComponent>
              </Suspense>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}>
            <Typography className="content-heading">
              3. Ownership Validation
            </Typography>
            <Typography className="content-description">
              We will validate you are the owner of the property with challenge
              questions only you will know the answers to.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* step 4 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className="timeline-dot">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <Step4 />
                </LazyLoadComponent>
              </Suspense>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}>
            <Typography className="content-heading">
              4. Subscription Plan
            </Typography>
            <Typography className="content-description">
              Choose the subscription plan that best works for you and set up
              your payment method.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* step 5 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className="timeline-dot">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <Step5 />
                </LazyLoadComponent>
              </Suspense>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}>
            <Typography className="content-heading">
              5. Biometric Protection
            </Typography>
            <Typography className="content-description">
              You are your key to unlock. Next, we will use your computer camera
              or mobile device to take a headshot of you. This will be used
              later as a form of biometric protection so no one except for you
              can access your account.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* step 6 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className="timeline-dot">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyLoadComponent threshold={100}>
                  <Step6 />
                </LazyLoadComponent>
              </Suspense>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}>
            <Typography className="content-heading">6. Final Step</Typography>
            <Typography className="content-description">
              Your signature. Last, we will have you sign your name.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* end content */}
        <TimelineItem>
          <TimelineSeparator>
            {/* <TimelineConnector /> */}
            <TimelineDot
              className="timeline-start-end-content"
              sx={{ margin: "40px 0 0 0" }}
            >
              That’s it!
              <br /> Success!
            </TimelineDot>
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}></TimelineContent>
        </TimelineItem>
      </Timeline>
      {/* </Box> */}

      {/* in mobile view */}
      <Stack className="mobile-view" spacing={2.5}>
        {/* start message */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Stack sx={{ flexDirection: "column" }} position="relative">
              <Box
                mt={3}
                mb={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography className="start-end-message">
                  Simple Sign up Process
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                  m: "0rem 1rem 1rem 1.5rem",
                }}
              />
            </Stack>
          </Stack>
        </Box>

        {/* step 1 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="bg-white step-icon">
                <LazyLoadImage
                  src={step1Img}
                  className="mobile-icon-style"
                  alt="step-1 img"
                />
                {/* <img
                src={step1Img}
                alt="step-1 img"
                className="mobile-icon-style"
                loading="lazy"
              /> */}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                }}
              />
            </Box>
            <Box className="w-100">
              <Accordion
                className=""
                sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{ margin: 0 }}
                  expandIcon={<></>}

                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Stack alignItems="flex-start" spacing={2}>
                    <Typography className="content-heading">
                      1. Setup Account
                    </Typography>
                    <Typography className="content-description">
                      Only one account is needed for all properties. To start
                      you will create your account. We will validate your
                      account with both email and SMS pin to set up your
                      two-factor protection.
                    </Typography>
                  </Stack>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Stack>
        </Box>

        {/* step 2 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="bg-white step-icon">
                <LazyLoadImage
                  src={step2Img}
                  className="mobile-icon-style"
                  alt="step-2 img"
                />
                {/* <img
                src={step2Img}
                alt="step-2 img"
                className="mobile-icon-style"
                loading="lazy"
              /> */}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                }}
              />
            </Box>
            <Box className="w-100">
              <Accordion
                className=""
                sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{ margin: 0 }}
                  expandIcon={<></>}

                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Stack alignItems="flex-start" spacing={2}>
                    <Typography className="content-heading">
                      2. Property Search
                    </Typography>
                    <Typography className="content-description">
                      You will then add your property or properties you want
                      EquityProtect to monitor and protect for you.
                    </Typography>
                  </Stack>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Stack>
        </Box>

        {/* step 3 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="bg-white step-icon">
                <LazyLoadImage
                  src={step3Img}
                  className="mobile-icon-style"
                  alt="step-3 img"
                />
                {/* <img
                src={step3Img}
                alt="step-3 img"
                className="mobile-icon-style"
                loading="lazy"
              /> */}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                }}
              />
            </Box>
            <Box className="w-100">
              <Accordion
                className=""
                sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{ margin: 0 }}
                  expandIcon={<></>}

                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Stack alignItems="flex-start" spacing={2}>
                    <Typography className="content-heading">
                      3. Ownership Validation
                    </Typography>
                    <Typography className="content-description">
                      We will validate you are the owner of the property with
                      challenge questions only you will know the answers to.
                    </Typography>
                  </Stack>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Stack>
        </Box>

        {/* step 4 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="bg-white step-icon">
                <LazyLoadImage
                  src={step4Img}
                  className="mobile-icon-style"
                  alt="step-4 img"
                />
                {/* <img
                src={step4Img}
                alt="step-4 img"
                className="mobile-icon-style"
                loading="lazy"
              /> */}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                }}
              />
            </Box>
            <Box className="w-100">
              <Accordion
                className=""
                sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{ margin: 0 }}
                  expandIcon={<></>}

                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Stack alignItems="flex-start" spacing={2}>
                    <Typography className="content-heading">
                      4. Subscription Plan
                    </Typography>
                    <Typography className="content-description">
                      Choose the subscription plan that best works for you and
                      set up your payment method.
                    </Typography>
                  </Stack>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Stack>
        </Box>

        {/* step 5 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="bg-white step-icon">
                <LazyLoadImage
                  src={step5Img}
                  className="mobile-icon-style"
                  alt="step-5 img"
                />
                {/* <img
                src={step5Img}
                alt="step-5 img"
                className="mobile-icon-style"
                loading="lazy"
              /> */}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                }}
              />
            </Box>
            <Box className="w-100">
              <Accordion
                className=""
                sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{ margin: 0 }}
                  expandIcon={<></>}

                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Stack alignItems="flex-start" spacing={2}>
                    <Typography className="content-heading">
                      5. Biometric Protection
                    </Typography>
                    <Typography className="content-description">
                      You are your key to unlock. Next, we will use your
                      computer camera or mobile device to take a headshot of
                      you. This will be used later as a form of biometric
                      protection so no one except for you can access your
                      account.
                    </Typography>
                  </Stack>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Stack>
        </Box>

        {/* step 6 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="bg-white step-icon">
                <LazyLoadImage
                  src={step6Img}
                  className="mobile-icon-style"
                  alt="step-6 img"
                />
                {/* <img
                src={step6Img}
                alt="step-6 img"
                className="mobile-icon-style"
                loading="lazy"
              /> */}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{
                  height: "100% !important",
                  width: "2px",
                  borderColor: "#E8ECF0",
                }}
              />
            </Box>
            <Box className="w-100">
              <Accordion
                className=""
                sx={{ backgroundColor: "transparent", boxShadow: "none" }}
                expanded={true}
              >
                <AccordionSummary
                  sx={{ margin: 0 }}
                  expandIcon={<></>}

                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Stack alignItems="flex-start" spacing={2}>
                    <Typography className="content-heading">
                      6. Final Step
                    </Typography>
                    <Typography className="content-description">
                      Your signature. Last, we will have you sign your name.
                    </Typography>
                  </Stack>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Stack>
        </Box>

        {/* step 6 */}
        <Box className="w-100">
          <Stack direction="row" position="relative">
            <Box
              mt={3}
              mb={3}
              sx={{ display: "flex", flexDirection: "column" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography className="start-end-message">
                That’s it! Success!
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default SignupTimeline;
