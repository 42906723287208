import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { decryptPayload, getObjLength } from "../../helpers/utils";
import { MenuProps, ROLES } from "../../helpers/constants";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const initialValues = {
  houseInfo_1: "",
  houseInfo_2: "",
  houseInfo_3: "",
};

const validationSchema1 = Yup.object().shape({
  houseInfo_1: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_2: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_3: Yup.string().required(
    "Please select an option to proceed further."
  ),
});

const validationSchema2 = Yup.object().shape({
  houseInfo_1: Yup.string().required(
    "Please select an option to proceed further."
  ),
  houseInfo_2: Yup.string().required(
    "Please select an option to proceed further."
  ),
});

const SecurityQuestionsVerification = ({
  setSkippedorSubmitted,
  selectedData,
  callListingAPI = () => {},
  closePopup,
  uid = null,
}) => {
  const [alertMsg, setAlertMsg] = useState(false);

  const role = decryptPayload(localStorage.getItem("roles"));

  const dispatch = useDispatch();

  const { securityPropertiesQueData, propertyLoader, verifySecurityInfoData } =
    useSelector((state) => state?.properties);

  console.log("selectedData==>", selectedData);

  const verifyPropertyAPI = (data, isVerification) => {
    let payload = {
      property_id: selectedData?.property_id,
      state_code: selectedData?.state_code,
      user_id: uid,
      is_questions_verified_by_admin: isVerification,
    };
    console.log("answers object==>", { ...payload, answers: data });
    let url = `customer/verify-property/`;
    if (role === ROLES.admin || role === ROLES.subAdmin) {
      url = `admin/verify-answers/`;
    }
    dispatch(
      propertiesAction.verifySecurityInfoDetails({
        url: `${url + selectedData?.id}`,
        data: { ...payload, answers: data },
        token: true,
      })
    );
  };

  const onSubmit = (values, errors) => {
    const key1 = Object.values(securityPropertiesQueData?.data?.questions)[0]
      ?.question_code;
    const key2 = Object.values(securityPropertiesQueData?.data?.questions)[1]
      ?.question_code;
    const key3 = Object.values(securityPropertiesQueData?.data?.questions)[2]
      ?.question_code;

    let answer = {};
    answer[key1] = values?.houseInfo_1;
    answer[key2] = values?.houseInfo_2;
    if (
      Object.values(securityPropertiesQueData?.data?.questions)[2]
        ?.question_code
    ) {
      answer[key3] = values?.houseInfo_3;
    }
    // var data = {
    //   property_id: selectedData?.property_id,
    //   state_code: selectedData?.state_code,
    //   user_id: uid,
    //   answers: answer,
    //   is_questions_verified_by_admin: true,
    // };

    console.log("answers object==>", answer);
    verifyPropertyAPI(answer, false);
  };

  const questions =
    securityPropertiesQueData?.data !== undefined &&
    Object.values(
      //  Object.keys(securityPropertiesQueData?.data)?.length > 0
      securityPropertiesQueData?.success &&
        !securityPropertiesQueData?.data?.is_verified
        ? securityPropertiesQueData?.data?.questions
        : {}
    );

  const onCloseFun = (val) => {
    setAlertMsg(false);
    dispatch(propertiesAction.fetchVerifySecurityInfoData({}));
    if (val === "success") {
      closePopup(false);
      callListingAPI();
    }
  };

  useEffect(() => {
    if (getObjLength(verifySecurityInfoData)) {
      setAlertMsg(true);
      setSkippedorSubmitted(true);
    }
  }, [verifySecurityInfoData]);

  return (
    <>
      {!alertMsg && (
        <>
          <Button
            onClick={() => closePopup(false)}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
          <Box className="mt-3">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={initialValues}
              validationSchema={
                questions?.length > 2 ? validationSchema1 : validationSchema2
              }
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, touched, setTouched, errors }) => {
                return (
                  <Form>
                    <Box className="accountBoxWrapper infoForm_Wrapper">
                      <Typography align="center" variant="h3">
                        Let’s make sure{" "}
                        {role === ROLES.admin || role === ROLES.subAdmin
                          ? "the User is the real owner"
                          : "you are the owner"}{" "}
                      </Typography>
                      <Typography align="center" variant="body1">
                        Please answer the following questions about{" "}
                        {role === ROLES.admin || role === ROLES.subAdmin
                          ? "User's"
                          : "your"}{" "}
                        property so we are able to identify{" "}
                        {role === ROLES.admin || role === ROLES.subAdmin
                          ? "user"
                          : "you"}{" "}
                        as the owner.
                      </Typography>
                      <hr className="formSeparator" />
                      <Typography
                        variant="h4"
                        className="infoTitle text-start"
                        px={2}
                        mb={2}
                      >
                        {/* House information */}
                        {selectedData?.address}
                      </Typography>

                      <Box p={2}>
                        <Grid
                          container
                          spacing={2}
                          textAlign={"start"}
                          alignItems={"center"}
                        >
                          {securityPropertiesQueData?.success &&
                            Object.keys(securityPropertiesQueData).length >
                              0 && (
                              // questions.map((data, key) => (
                              <>
                                {questions[0]?.question && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      className="mb-0 mb-lg-3"
                                    >
                                      <Typography
                                        variant="body1"
                                        className="infoLabel"
                                      >
                                        {questions[0]?.question}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      className="mb-0 mb-lg-3"
                                    >
                                      <FormControl
                                        fullWidth
                                        className="sign-textField"
                                        error={
                                          touched.houseInfo_1 &&
                                          Boolean(errors.houseInfo_1)
                                        }
                                      >
                                        <InputLabel id="demo-simple-select-placeholder">
                                          Select
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-placeholder"
                                          id="demo-simple-select"
                                          value={values?.houseInfo_1}
                                          label="select"
                                          name="houseInfo_1"
                                          onChange={(event) => {
                                            setFieldValue(
                                              "houseInfo_1",
                                              event.target.value
                                            );
                                          }}
                                          MenuProps={MenuProps}
                                        >
                                          {questions[0]?.options?.map(
                                            (data, i) => (
                                              <MenuItem
                                                value={data !== "" ? data : " "}
                                                sx={{ whiteSpace: "normal" }}
                                              >
                                                {data !== "" ? data : "-"}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                        {touched.houseInfo_1 &&
                                          Boolean(errors.houseInfo_1) && (
                                            <FormHelperText
                                              sx={{ color: "#d32f2f" }}
                                            >
                                              {touched.houseInfo_1 &&
                                                errors.houseInfo_1}
                                            </FormHelperText>
                                          )}
                                      </FormControl>
                                    </Grid>
                                  </>
                                )}
                                {questions[1]?.question && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      className="mb-0 mb-lg-3"
                                    >
                                      <Typography
                                        variant="body1"
                                        className="infoLabel"
                                      >
                                        {questions[1]?.question}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      className="mb-0 mb-lg-3"
                                    >
                                      <FormControl
                                        fullWidth
                                        className="sign-textField"
                                        error={
                                          touched.houseInfo_2 &&
                                          Boolean(errors.houseInfo_2)
                                        }
                                      >
                                        <InputLabel id="demo-simple-select-placeholder">
                                          Select
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-placeholder"
                                          id="demo-simple-select"
                                          value={values?.houseInfo_2}
                                          label="select"
                                          name="houseInfo_2"
                                          onChange={(event) => {
                                            setFieldValue(
                                              "houseInfo_2",
                                              event.target.value
                                            );
                                          }}
                                          MenuProps={MenuProps}
                                        >
                                          {questions[1]?.options?.map(
                                            (data, i) => (
                                              <MenuItem
                                                value={data !== "" ? data : " "}
                                                sx={{ whiteSpace: "normal" }}
                                              >
                                                {data !== "" ? data : "-"}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                        {touched.houseInfo_2 &&
                                          Boolean(errors.houseInfo_2) && (
                                            <FormHelperText
                                              sx={{ color: "#d32f2f" }}
                                            >
                                              {touched.houseInfo_2 &&
                                                errors.houseInfo_2}
                                            </FormHelperText>
                                          )}
                                      </FormControl>
                                    </Grid>
                                  </>
                                )}
                                {questions[2]?.question && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      className="mb-0 mb-lg-3"
                                    >
                                      <Typography
                                        variant="body1"
                                        className="infoLabel"
                                      >
                                        {questions[2]?.question}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      className="mb-0 mb-lg-3"
                                    >
                                      <FormControl
                                        fullWidth
                                        className="sign-textField"
                                        error={
                                          touched.houseInfo_3 &&
                                          Boolean(errors.houseInfo_3)
                                        }
                                      >
                                        <InputLabel id="demo-simple-select-placeholder">
                                          Select
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-placeholder"
                                          id="demo-simple-select"
                                          value={values?.houseInfo_3}
                                          label="select"
                                          name="houseInfo_3"
                                          onChange={(event) => {
                                            setFieldValue(
                                              "houseInfo_3",
                                              event.target.value
                                            );
                                          }}
                                          MenuProps={MenuProps}
                                        >
                                          {questions[2]?.options?.map(
                                            (data, i) => (
                                              <MenuItem
                                                value={data !== "" ? data : " "}
                                                sx={{ whiteSpace: "normal" }}
                                              >
                                                {data !== "" ? data : "-"}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                        {touched.houseInfo_3 &&
                                          Boolean(errors.houseInfo_3) && (
                                            <FormHelperText
                                              sx={{ color: "#d32f2f" }}
                                            >
                                              {touched.houseInfo_3 &&
                                                errors.houseInfo_3}
                                            </FormHelperText>
                                          )}
                                      </FormControl>
                                    </Grid>
                                  </>
                                )}
                              </>
                              // ) : (
                              // ))
                            )}
                        </Grid>
                      </Box>

                      {/* <hr className="formSeparator" /> */}
                      <div className=" stepBtn d-flex justify-content-center px-2 px-sm-5 pb-3 mt-3">
                        {(role === ROLES.admin || role === ROLES.subAdmin) && (
                          <Button
                            className="mx-2 next_button other_popup_btn"
                            onClick={() => {
                              verifyPropertyAPI({}, true);
                            }}
                          >
                            Skip and Auto Verify
                          </Button>
                        )}

                        <Button
                          // onClick={() => onClickSubmit(errors)}
                          type="submit"
                          className="next_button success_popup_btn"
                        >
                          Answer the questions and Verify
                        </Button>
                      </div>
                    </Box>

                    <Loader open={propertyLoader} />
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </>
      )}
      {alertMsg &&
        (verifySecurityInfoData?.success ? (
          <PlainAlertMessage
            type="Success"
            openPopup={alertMsg}
            onClose={true}
            onCloseFun={() => {
              onCloseFun("success");
              setSkippedorSubmitted(false);
            }}
            message={verifySecurityInfoData?.message}
          />
        ) : (
          <PlainAlertMessage
            type="Error"
            openPopup={alertMsg}
            onClose={true}
            onCloseFun={() => {
              onCloseFun("error");
              setSkippedorSubmitted(false);
            }}
            message={verifySecurityInfoData?.data?.message}
          />
        ))}
    </>
  );
};

export default SecurityQuestionsVerification;
