import React, { Suspense, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useTheme, useMediaQuery } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import PropertyListing from "./PropertyListing";
import CustomTextField from "../common/CustomTextField";
import { MenuProps, MONTHS } from "../helpers/constants";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import {
  getDateInMMDDYYYYFormat,
  getDateInYYYYMMDDFormat,
  getObjLength,
  ordinalSuffixOf,
} from "../helpers/utils";

import "../common/common.scss";
import PlainAlertMessage from "../common/PlainAlertMessage";
import { requestDocumentsAction } from "../../redux/slices/admin/requestDocuments/requestDocumentsSlice";
import { serviceProviderSignupAction } from "../../redux/slices/serviceProvider/SPSignUpSlice";
import { useNavigate } from "react-router-dom";

const TerminateDocumentFormModal = ({
  open = false,
  selectedData,
  handleClose = () => {},
  onSubmit = () => {},
  loader = false,
  setSelectedItem = () => {},
  selectedItem = {},
  setPropertyList = () => {},
  propertyList,
  isEdit = false,
  callListAPI,
  setSelectedProperty,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // store data
  const { statesCodeList, statesLoader } = useSelector(
    (state) => state.signUpUser
  );
  const dispatch = useDispatch();
  const { requestedDocumentDetails, requestedDocumentLoader } = useSelector(
    (state) => state.requestDocuments
  );
  const navigate = useNavigate();
  const {
    requestAccessLoader,
    requestAccessDetails,
    validateUUIDDetails,
    validateUUIDLoader,
  } = useSelector((state) => state?.serviceProviderSignup);

  const selecetedProperty = (property) => {
    console.log("selected property==>", property);
    setSelectedItem(property);
    setPropertyList(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={
          requestedDocumentDetails?.success || requestAccessDetails?.success
            ? "sm"
            : "lg"
        }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
        className={`${
          isMobile &&
          !(
            requestedDocumentDetails?.success || requestAccessDetails?.success
          ) &&
          "zoom-modal-wrapper"
        }`}
      >
        {!requestedDocumentDetails?.success &&
          !requestAccessDetails?.success && (
            <>
              <DialogTitle id="alert-dialog-title">
                Termination - Sale
                <Button
                  onClick={() => handleClose()}
                  className="modal-close-btn"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                    background: "transparent",
                  }}
                >
                  <CloseIcon sx={{ color: "#9CB8A6" }} />
                </Button>
              </DialogTitle>
              <DialogContent dividers>
                {propertyList ? (
                  <Box className="accountBoxWrapper findPropertyBoxWrapper bg-white">
                    <Typography align="center" variant="h3">
                      Property List
                    </Typography>
                    <Typography align="center" variant="body1">
                      Please select your relevant property from the property
                      list.
                    </Typography>
                    {selectedData?.map((data, key) => (
                      <PropertyListing
                        data={data}
                        selectProperty={selecetedProperty}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box className="d-flex justify-content-center bg-white">
                    <Formik
                      enableReinitialize
                      validateOnChange
                      initialValues={{
                        recordingDate: selectedItem?.recording_date
                          ? getDateInYYYYMMDDFormat(
                              new Date(selectedItem?.recording_date)
                            )
                          : getDateInYYYYMMDDFormat(new Date()),
                        recordingInformation: isEdit
                          ? selectedItem?.recording_info
                          : "",
                        email:
                          selectedItem?.owner?.[0]?.email ||
                          selectedItem?.email,
                        streetAddress:
                          selectedItem?.address?.split(",")?.[0] || "",
                        city: selectedItem?.city || "",
                        state: selectedItem?.state?.toUpperCase() || "",
                        zip: selectedItem?.zip || "",
                        website: isEdit ? selectedItem?.website : "",
                        notice_date:
                          isEdit && selectedItem?.notice_date
                            ? getDateInYYYYMMDDFormat(
                                new Date(selectedItem?.notice_date)
                              )
                            : getDateInYYYYMMDDFormat(new Date()),
                        legalDescriptionShort: isEdit
                          ? selectedItem?.legal_description_short
                          : "",
                        legalDescriptionLong: selectedItem?.deed_info
                          ?.realPropertyDescription
                          ? selectedItem?.deed_info?.realPropertyDescription
                          : isEdit
                          ? selectedItem?.legal_description_long
                          : "",
                      }}
                      validationSchema={Yup.object().shape({
                        recordingDate: Yup.string(
                          "Please enter your recording date."
                        )
                          .trim()
                          .required("Please enter your recording date."),
                        recordingInformation: Yup.string(
                          "Please enter your recording information."
                        )
                          .trim()
                          .required("Please enter your recording information."),
                        email: Yup.string("Please enter your email.")
                          .trim()
                          .email("Please enter valid email")
                          .required("Please enter your email."),
                        streetAddress: Yup.string(
                          "Please enter your street address."
                        )
                          .trim()
                          .required("Please enter your street address."),
                        city: Yup.string("Please enter your city.")
                          .trim()
                          .required("Please enter your city."),
                        state: Yup.string("Please enter your state.")
                          .trim()
                          .required("Please enter your state."),
                        zip: Yup.string("Please enter your Zip code.")
                          .required("Zipcode is required")
                          .matches(
                            /^[0-9]{5}$/,
                            "Zipcode must be exactly 5 digits"
                          ),
                        website: Yup.string("Please enter your website.")
                          .trim()
                          .required("Please enter your website."),
                        notice_date: Yup.string("Please select notice date.")
                          .trim()
                          .required("Please select notice date."),
                        legalDescriptionShort: Yup.string(
                          "Please enter your short legal description."
                        )
                          .trim()
                          .required(
                            "Please enter your short legal description."
                          ),
                        legalDescriptionLong: Yup.string(
                          "Please enter your long legal description."
                        )
                          .trim()
                          .required(
                            "Please enter your long legal description."
                          ),
                      })}
                      onSubmit={(values) => {
                        let payload = JSON.parse(JSON.stringify(values));

                        // recording date
                        payload.recordingDate = getDateInMMDDYYYYFormat(
                          new Date(values?.recordingDate),
                          "/"
                        );

                        // notice date
                        payload.notice_date = getDateInMMDDYYYYFormat(
                          new Date(values?.notice_date),
                          "/"
                        );

                        // notice year
                        payload.noticeYear = `${new Date(
                          values?.notice_date
                        )?.getFullYear()}`?.slice(-2);

                        // notice day month
                        payload.noticeDayMonth =
                          ordinalSuffixOf(
                            parseInt(
                              `0${new Date(
                                values?.notice_date
                              )?.getDate()}`?.slice(-2)
                            )
                          ) +
                          " " +
                          MONTHS[new Date(values?.notice_date)?.getMonth()];

                        const { ...restPayload } = payload;

                        // return;
                        onSubmit(restPayload);
                      }}
                    >
                      {({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        initialValues,
                      }) => {
                        return (
                          <Form className="beneficiary-form w-100">
                            <DialogContent className="bg-white">
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                className="bg-white"
                              >
                                <>
                                  {/* recording date */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <FormControl className="sign-textField filter-search w-100">
                                      <CustomDateRangePicker
                                        rangeValue={values?.recordingDate || ""}
                                        onChange={(values) => {
                                          setFieldValue(
                                            "recordingDate",
                                            values
                                          );
                                        }}
                                        onClear={() => {
                                          setFieldValue("recordingDate", "");
                                        }}
                                        className=""
                                        sx={{ backgroundColor: "#f6f9fc" }}
                                        label="Recording Date"
                                        type="text"
                                        name="recordingDate"
                                        values={values?.recordingDate}
                                        touched={touched}
                                        errors={errors}
                                        settings={{
                                          singleDatePicker: true,
                                          // minDate: new Date(tomorrow.toString()),
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  {/* Recording Information */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="Recording Information"
                                      name="recordingInformation"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* Email */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="Email"
                                      name="email"
                                      type="email"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* Street Address */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="Street Address"
                                      name="streetAddress"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* city */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="City"
                                      name="city"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* state */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <FormControl
                                      fullWidth
                                      className="sign-textField "
                                      error={
                                        touched.state && Boolean(errors.state)
                                      }
                                    >
                                      <InputLabel label="State">
                                        State
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-placeholder"
                                        id="demo-simple-select"
                                        value={values?.state}
                                        label="State"
                                        name="state"
                                        variant="outlined"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "state",
                                            event.target.value
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        {Object.values(statesCodeList)?.map(
                                          (state) => (
                                            <MenuItem value={state}>
                                              {state}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {touched.state &&
                                        Boolean(errors.state) && (
                                          <FormHelperText
                                            sx={{ color: "#d32f2f" }}
                                          >
                                            {touched.state && errors.state}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  {/* Zip Code */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="Zip Code"
                                      name="zip"
                                      type="number"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* Notice Date */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <FormControl className="sign-textField filter-search w-100">
                                      <CustomDateRangePicker
                                        rangeValue={values?.notice_date || ""}
                                        onChange={(values) => {
                                          setFieldValue("notice_date", values);
                                        }}
                                        onClear={() => {
                                          setFieldValue("notice_date", "");
                                        }}
                                        className=""
                                        sx={{ backgroundColor: "#f6f9fc" }}
                                        label="Notice Date"
                                        type="text"
                                        name="notice_date"
                                        values={values?.notice_date}
                                        touched={touched}
                                        errors={errors}
                                        settings={{
                                          singleDatePicker: true,
                                          // minDate: new Date(tomorrow.toString()),
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  {/* Website */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="Website"
                                      name="website"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* Legal Description Short */}
                                  <Grid xs={12} md={6} px={1} pb={3}>
                                    <CustomTextField
                                      label="Legal Description Short"
                                      name="legalDescriptionShort"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                    />
                                  </Grid>
                                  {/* Legal Description Long */}
                                  <Grid xs={12} md={12} px={1} pb={3}>
                                    <CustomTextField
                                      label="Legal Description Long"
                                      name="legalDescriptionLong"
                                      validation={{
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors,
                                      }}
                                      otherProps={{
                                        multiline: true,
                                        rows: 4,
                                      }}
                                    />
                                  </Grid>
                                </>
                              </Grid>
                              {getObjLength(requestedDocumentDetails) &&
                                !requestedDocumentDetails?.success && (
                                  <p className="text-danger text-center p-2">
                                    {requestedDocumentDetails?.data?.message}
                                  </p>
                                )}
                            </DialogContent>

                            <DialogActions className="alert-modal-btn">
                              <Stack direction="row" spacing={2} pb={2}>
                                <>
                                  <Button
                                    className="popup_Btn other_popup_btn m-1"
                                    // variant="contained"
                                    onClick={() => {
                                      handleClose();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="popup_Btn success_popup_btn m-1"
                                    type="submit"
                                    disabled={
                                      JSON.stringify(initialValues) ===
                                      JSON.stringify(values)
                                    }
                                    variant="contained"
                                  >
                                    Save
                                  </Button>
                                </>
                              </Stack>
                            </DialogActions>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                )}
              </DialogContent>
            </>
          )}
        <Loader open={loader || statesLoader} />

        {getObjLength(requestedDocumentDetails) &&
        requestedDocumentDetails?.success ? (
          <PlainAlertMessage
            type={requestedDocumentDetails?.success && "Success"}
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              if (requestedDocumentDetails?.success) {
                console.log("entered...");
                setSelectedProperty(null);
                handleClose(false);
                callListAPI();
              }
              dispatch(requestDocumentsAction?.setRequestedDocumentDetails({}));
            }}
            message={
              requestedDocumentDetails?.success
                ? requestedDocumentDetails?.message
                : requestedDocumentDetails?.data?.message
            }
            footer={false}
          />
        ) : null}

        {getObjLength(requestAccessDetails) ? (
          requestAccessDetails?.success ? (
            <Suspense fallback={<div>Loading...</div>}>
              <PlainAlertMessage
                type="Success"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  dispatch(
                    serviceProviderSignupAction?.clearRequestAccessDetails()
                  );
                  dispatch(
                    serviceProviderSignupAction?.clearValidateRequestDetails()
                  );
                  // handleCloseBtn();
                  navigate("/documents");
                }}
                message={requestAccessDetails?.message}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading...</div>}>
              <PlainAlertMessage
                type="Error"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  dispatch(
                    serviceProviderSignupAction?.clearRequestAccessDetails()
                  );
                  // dispatch(
                  //   serviceProviderSignupAction?.clearValidateRequestDetails()
                  // );
                  // handleCloseBtn();
                }}
                message={
                  requestAccessDetails?.data?.message || "Something went wrong"
                }
              />
            </Suspense>
          )
        ) : null}
      </Dialog>
    </>
  );
};

export default TerminateDocumentFormModal;
