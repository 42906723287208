/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import {
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";

import CloseIcon from "@mui/icons-material/Close";
import DocumentTypes from "./DocumentTypes";
import DownloadFile from "./DownloadFile";

import "../../../styles/documentmodal.scss";

import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../common/loader";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import SecurePaymentPanel from "../../common/SecurePaymentPanel";
import { subscriptionAction } from "../../../redux/slices/subscription/subscriptionSlice";

export default function AdminOrderDocumnet({
  //   setDocActivStep,
  propertyId,
  resetStates,
  closeModal,
  onClose,
  setDefaultStep,
  userId = null,
}) {
  const [stepCount, setStepCount] = useState(setDefaultStep);
  const [docType, setDocType] = useState("");
  const [sendReqErr, setSendReqErr] = useState("");
  const [scroll, setScroll] = React.useState("paper");
  const [selectedCard, setSelectedCard] = useState(null);

  const dispatch = useDispatch();

  const { requestDoc, generateDoc, reqLoader } = useSelector(
    (state) => state.properties
  );
  const documentTypeList = useSelector(
    (state) => state.properties.documentTypes
  );

  console.log("Active taeps ==>.", stepCount);
  useEffect(() => {
    if (Object.keys(requestDoc).length > 0) {
      changeSteps("reqDoc");
    }
  }, [requestDoc]);
  useEffect(() => {
    console.log(generateDoc, "Active taeps ==>.", stepCount);
    if (Object.keys(generateDoc).length > 0) {
      changeSteps("generateDoc");
    }
  }, [generateDoc]);

  useEffect(() => {
    if (resetStates) {
      resetStateValues();
    }
  }, [resetStates]);

  const handleClose = (from) => {
    console.log("chlid close==>");
    setSendReqErr("");
    setDocType("");
    if (from !== "3dSecure") {
      closeModal(false);
    }
    dispatch(propertiesAction.clearOrderDoc());
  };
  const resetStateValues = () => {
    setDocType("");
    setSendReqErr("");
  };

  const handleNext = () => {
    console.log("active staeps===>", stepCount);
    console.log(propertyId, "Values from changeDocTypeStep==>", docType);
    if (Object.keys(docType).length === 0) {
      let price = documentTypeList.data.filter(
        (i) => (i.type || i.key) === documentTypeList?.data[0].key
      );
      console.log("filter Doc==>", price);
      let docData = {
        type: documentTypeList?.data[0].key,
        price: price[0].price,
      };
      console.log("doctypes==>>", docData);
      dispatch(
        propertiesAction.reqDocument({
          url: "customer/properties/" + propertyId + "/request-document",
          data: docData,
          token: true,
        })
      );
    } else {
      console.log("doctypes==>>", docType);
      dispatch(
        propertiesAction.reqDocument({
          url: "customer/properties/" + propertyId + "/request-document",
          data: docType,
          token: true,
        })
      );
    }
  };

  const changeSteps = (step) => {
    console.log("generateDoc==>", generateDoc);
    if (step === "reqDoc") {
      if (requestDoc?.success) {
        // setStepCount((prevActiveStep) => prevActiveStep + 1);
        setSendReqErr("");
        let requestedDocId = requestDoc?.data?.id;
        dispatch(
          propertiesAction.generateDocument({
            url:
              "customer/properties/" +
              propertyId +
              "/generate-document/" +
              requestedDocId,
            data: {},
            token: true,
          })
        );
      } else {
        setSendReqErr(requestDoc?.data?.message);
      }
    }
    if (step === "generateDoc") {
      if (generateDoc?.success) {
        setStepCount((prevActiveStep) => prevActiveStep + 1);
        setSendReqErr("");
      } else {
        setSendReqErr(generateDoc?.data?.message);
      }
    }
  };

  return (
    <div>
      <>
        {stepCount === 0 && (
          <Box sx={{ p: 4, position: "relative" }} className="dialog-header">
            <DialogTitle sx={{ p: 0 }}>Order a new document</DialogTitle>
            <Typography variant="body1">
              Please select a report type to generate your report
            </Typography>
            <DialogActions sx={{ p: 0 }} className="closeBtn ms-auto">
              <Button onClick={handleClose}>
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>
            </DialogActions>
          </Box>
        )}

        <DialogContent dividers={scroll === "paper"} sx={{ p: 5 }}>
          {stepCount === 0 ? (
            <DocumentTypes
              changeDocTypeStep={setDocType}
              sendReqErr={sendReqErr}
            />
          ) : (
            <>
            {/* 
              <Button
                onClick={handleClose}
                className="modal-close-btn"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  background: "transparent",
                }}
              >
                <CloseIcon sx={{ color: "#9CB8A6" }} />
              </Button>*/}
              {/* <PlainAlertMessage
                  type="Error"
                  onClose={false}
                  onCloseFun={() => {
                    handleClose();
                  }}
                  message={generateDoc?.message}
                  buttons={
                    <Button
                      className="popup_Btn error_popup_btn my-3"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      OK, Close
                    </Button>
                  }
                /> */}
              {generateDoc?.data?.card_3d_secured ? (
                <>
                  <SecurePaymentPanel
                    displayLabel={false}
                    paymentData={generateDoc}
                    onSuccessFun={() => handleClose("3dSecure")}
                    onFailedFun={handleClose}
                    cardList={false}
                    userId={userId}
                    clearOrderDoc={false}
                  />
                </>
              ) : (
                // <h1>{generateDoc?.message}</h1>
                <DownloadFile onClose={onClose} />
              )}
            </>
          )}
        </DialogContent>

        {sendReqErr.length > 0 && (
          <p className="p-2 m-0 text-danger text-center">{sendReqErr}</p>
        )}
        {stepCount === 0 && (
          <DialogActions sx={{ p: 4 }} className="dialog-footer">
            <div className="d-flex justify-content-between">
              <Button
                size="large"
                className="stepBtn ms-auto"
                variant="contained"
                onClick={handleNext}
              >
                Next Step
              </Button>
            </div>
          </DialogActions>
        )}
      </>
      <Loader open={reqLoader} />
    </div>
  );
}
