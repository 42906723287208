import React from "react";

import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";

import PropertyTable from "./PropertyTable";
import AlertDialog from "../../common/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";

const params = window.location.search;
let code = params?.split("=")[1];

const ProfessionalDashboard = () => {
  console.log("==valuOf profrssional dashboard ==>", code);

  const { isResetPasswordRequired } = useSelector(
    (state) => state?.userProfile
  );

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   let iosCode = "";
  //   if (code === "ios") {
  //     let cookieValue = document.cookie;
  //     // iosCode = source?.[2];
  //     iosCode = cookieValue?.replace(
  //       /(?:(?:^|.*;\s*)professioanlCookie\s*\=\s*([^;]*).*$)|^.*$/,
  //       "$1"
  //     );
  //     localStorage.setItem("preference", true);
  //     localStorage.setItem("isIos", true);
  //     if (iosCode) {
  //       dispatch(
  //         userSigInAction?.fetchUserData(JSON.parse(window.atob(iosCode)))
  //       );
  //       console.log("IOS response ==>", JSON.parse(window.atob(iosCode)));
  //     } else {
  //       navigate("/login", { replace: false });
  //     }
  //     // dispatch(
  //     //   userSigupAction.fetchResumeData(JSON.parse(window.atob(iosCode)))
  //     // );
  //   } else {
  //     localStorage.removeItem("isIos");
  //   }
  // }, []);

  // handle password Reset Required
  const passwordResetRequired = () => {
    localStorage.setItem(
      "isResetPasswordRequired",
      parseInt(isResetPasswordRequired) + 1
    );
    dispatch(userProfileAction.clearResetPasswordRequired());
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Assigned Properties
        </Typography>
      </Box>
      <Box>
        <PropertyTable />
      </Box>

      {/* Reset password popup  */}
      {isResetPasswordRequired === 1 ? (
        <AlertDialog
          type="Attention"
          openPopup={isResetPasswordRequired}
          onClose={true}
          onCloseFun={passwordResetRequired}
          mesage={
            <p className="fw-400 mt-3">
              Please change auto generated password for the better security of
              your account.
            </p>
          }
          buttons={
            <Button
              className="popup_Btn success_popup_btn"
              onClick={passwordResetRequired}
            >
              Ok, All Good
            </Button>
          }
        />
      ) : null}
    </>
  );
};

export default ProfessionalDashboard;
