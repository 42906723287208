import { useState } from "react";
import { useEffect } from "react";
import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import { ROLES } from "../../helpers/constants";
import { getObjLength } from "../../helpers/utils";
import PaymentFailed from "../Dashboard/PaymentFailed";
import ProfessionalListTable from "./ProfessionalListTable";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { userProfessionalAction } from "../../../redux/slices/user/userProfessionalSlice";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../../admin/AddLeads";

import "../../../styles/dashboard.scss";
import "../../Subscriptions/subscription.scss";

const columns = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
    minWidth: 50,
  },
  {
    id: "professional_name",
    disablePadding: true,
    label: "Assigned Professional",
    minWidth: 120,
  },
  {
    id: "professional_company",
    disablePadding: true,
    label: "Company",
    minWidth: 100,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
    minWidth: 180,
  },
  {
    id: "email",
    disablePadding: true,
    label: "Professional's Email",
    minWidth: 90,
  },
  {
    id: "phone",
    disablePadding: true,
    label: "Phone Number",
    minWidth: 160,
  },
  {
    id: "zip",
    disablePadding: true,
    label: "Zip Range",
    minWidth: 130,
  },
  {
    id: "type",
    disablePadding: true,
    label: "Professional Type",
    minWidth: 90,
  },

  // {
  //   id: "setting",
  //   disablePadding: true,
  //   label: "",
  // },
];

const Professionals = () => {
  const dispatch = useDispatch();

  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  // const [allTypes, setAllTypes] = useState("all");
  const [allTypes, setAllTypes] = useState("address");
  const [terms, setTerms] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);

  const { professionalLoader, userProfessionalDetails } = useSelector(
    (state) => state.userProfessional
  );

  const {
    paymnetDoneDetails,
    isPaymentPending,
    paymentLoader,
    loginAsSpouse: isSecondaryUser,
    parent_id,
  } = useSelector((state) => state?.userProfile);

  const selectColumn = (event) => {
    console.log("types==>", event.target.value);
    setAllTypes(event.target.value);
    setTerms("");
  };

  const filterOptions = () => {
    let data = {
      type: "all",
      term: terms,
    };
    console.log("==terms==", data);
    setCallApi(true);
    getUserProfessionalData(data);
  };

  // get url for customer or sub user
  const getUrl = useCallback(() => {
    let url;
    if (isSecondaryUser) {
      url = `property-professionals/${parent_id}`;
    } else {
      url = `property-professionals`;
    }
    return url;
  }, [isSecondaryUser, parent_id]);

  const getUserProfessionalData = useCallback(
    (data) => {
      console.log("data==>.", data);
      dispatch(
        userProfessionalAction.getUserProfessionalData({
          url: `${getUrl()}`,
          data: data,
          token: true,
        })
      );
    },
    [dispatch, getUrl]
  );

  const resetData = () => {
    resetFilterStates();
    console.log("setCallApi==>", callApi);
    if (callApi) {
      getUserProfessionalData({});
      setCallApi(false);
    }
  };

  const resetFilterStates = () => {
    console.log("Resetting filter states");
    setAllTypes("address");
    setTerms("");
    // setStatus("");
  };

  useEffect(() => {
    getUserProfessionalData({});
  }, [dispatch, getUserProfessionalData]);

  useEffect(() => {
    if (parseInt(localStorage.getItem("paymentPopup")) <= 2) {
      dispatch(
        userProfileAction.paymnetDoneDetails({
          url: "payment-status",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  return (
    <>
      <Box className="profile-wrapper subscription">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Typography variant="h6" className="title">
            Professionals
          </Typography>
        </Box>
        {/* Filtering Component Section */}
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>

        {/* Filtering Options */}
        <Box
          sx={{
            overflow: "hidden",
            overflowX: "auto",
            mb: 1.5,
          }}
        >
          {toggleFilterOptions && (
            <>
              {/* for mobile view */}
              <Box className="d-block d-md-none">
                <Grid
                  container
                  className="bg-white d-flex justify-content-around align-items-center px-4"
                  spacing={2}
                  pb={2}
                  pt={2}
                >
                  <Grid item xs={6} sm={6} md={4} lg={2}>
                    <Typography className="search-text">Search By</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        name="type"
                        onChange={(event) => {
                          selectColumn(event);
                        }}
                        value={allTypes}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.label !== "No." &&
                            cell.label !== "Zip Range" && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    {/* {allTypes !== "sub_date" && ( */}
                    <FormControl
                      className="filter-search w-100"
                      variant="outlined"
                    >
                      <InputLabel>Enter search term</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        }
                        label="Enter search term"
                        name="terms"
                        // type={allTypes === "sub_amount" ? "number" : ""}
                        onChange={(event) => {
                          setTerms(event.target.value);
                        }}
                        value={terms}
                      />
                    </FormControl>
                    {/* )} */}
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="w-100">
                      <Button
                        type="submit"
                        onClick={filterOptions}
                        sx={mobileFilterResultStyle}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Button
                      type="reset"
                      onClick={() => {
                        resetData();
                      }}
                      sx={mobileFilterResetBtnStyle}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              {/* for desktop devices */}
              <Card
                // sx={{ height: "150px !important" }}
                id="filter-options-card"
                className="d-none d-md-block"
              >
                <Box className="filter-options-box">
                  <div className="search-text">Search By</div>
                  <FormControl className="filter-select-one">
                    <TextField
                      id="outlined-select-currency"
                      disabled
                      select
                      label="All Types"
                      name="type"
                      onChange={(event) => {
                        selectColumn(event);
                      }}
                      value={allTypes}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.label !== "No." &&
                          cell.label !== "Zip Range" && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>

                  <FormControl className="filter-search" variant="outlined">
                    <InputLabel>Enter search term</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      }
                      label="Enter search term"
                      name="terms"
                      // type={allTypes === "sub_amount" ? "number" : ""}
                      onChange={(event) => {
                        setTerms(event.target.value);
                      }}
                      value={terms}
                    />
                  </FormControl>
                  <FormControl>
                    <Button
                      type="submit"
                      onClick={filterOptions}
                      className="filter-button"
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                  <Button
                    type="reset"
                    onClick={() => {
                      resetData();
                    }}
                    className="reset-filters"
                  >
                    Reset
                  </Button>
                </Box>
              </Card>
            </>
          )}
        </Box>

        <ProfessionalListTable
          // getPlanRenewed={getPlanRenewed}
          columns={columns}
          rowsData={
            userProfessionalDetails?.data?.length > 0
              ? userProfessionalDetails?.data
              : []
          }
          //   resetStates={resetFilterStates}
        />
      </Box>
      {/* Paymnet failed Popup */}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed isPaymentDonePopup={isPaymentDonePopup} />
        )}
      <Loader open={professionalLoader || paymentLoader} />
    </>
  );
};

export default Professionals;
