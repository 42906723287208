import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Divider,
  Menu,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import { PER_PAGE, ROLES } from "../helpers/constants";
import { documentsAction } from "../../redux/slices/userDocuments/documentsSlice";
import CustomPagination from "../common/customDesignedComponents/CustomPagination";
import {
  decryptPayload,
  getLocalFormatedDate,
  getObjLength,
} from "../helpers/utils";

const columns = [
  {
    id: "number",
    disablePadding: true,
    label: "No.",
    value: "no",
    minWidth: 60,
  },
  {
    id: "address",
    disablePadding: true,
    label: "Property",
    value: "property",
    minWidth: 60,
  },
  {
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 60,
  },
  {
    id: "county",
    disablePadding: true,
    label: "County",
    value: "county",
  },
  {
    id: "Zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 60,
  },
  {
    id: "transaction_id",
    disablePadding: true,
    label: "Transaction id",
    value: "transaction_id",
    minWidth: 60,
  },
  {
    id: "renewed_at",
    disablePadding: true,
    label: "Renewed at",
    value: "renewed_at",
    minWidth: 60,
  },
  // {
  //   id: "action",
  //   disablePadding: true,
  //   label: "Action",
  //   value: "action",
  //   minWidth: 70,
  // },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{
              color: "#0395FF",
              minWidth: headCell?.minWidth,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const RenewedProperties = () => {
  const dispatch = useDispatch();

  // states
  const [page, setPage] = useState(1);
  const [payload, setPayload] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  // store data
  const { terminationDocLoader, renewedPropertiesData } = useSelector(
    (state) => state?.documents
  );
  const { loginAsSpouse: isSecondaryUser, parent_id } = useSelector(
    (state) => state?.userProfile
  );

  const _DATA = renewedPropertiesData?.success
    ? renewedPropertiesData?.data
    : [];
  const count = Math.ceil(renewedPropertiesData?.count / PER_PAGE);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // handle page change
  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  //   get Documnets details
  const getRenwedPropertiesLists = useCallback(() => {
    let URL = `${
      decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.serviceProvider
      )
        ? "service-provider"
        : "customer"
    }/renewed-properties`;

    dispatch(
      documentsAction.getRenewedPropertiesData({
        url: URL,
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          user_id: isSecondaryUser ? parseInt(parent_id) : undefined,
        },
        token: true,
      })
    );
  }, [dispatch, isSecondaryUser, parent_id, payload?.offset]);

  useEffect(() => {
    getRenwedPropertiesLists();
  }, [getRenwedPropertiesLists]);

  return (
    <>
      {getObjLength(renewedPropertiesData) ? (
        renewedPropertiesData?.success ? (
          <>
            {/* mobile view */}
            <Box className="d-block d-lg-none mobile-table bg-white px-0 mb-3 py-3">
              {_DATA?.map((row, index) => {
                return (
                  <>
                    <Stack
                      direction="column"
                      spacing={1.2}
                      px={2}
                      className="position-relative"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={0}
                        mb={1}
                      >
                        <Typography
                          className="value fw-500"
                          sx={{ fontSize: "15px !important" }}
                        >
                          {row?.address || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">City: </Typography>
                        <Typography className="value">
                          {row?.city || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Country: </Typography>
                        <Typography className="value">
                          {row?.county || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">Zip: </Typography>
                        <Typography className="value">
                          {row?.zip || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key">
                          {" "}
                          Transaction id:
                        </Typography>
                        <Typography className="value">
                          {row?.transaction_id || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={0.5}>
                        <Typography className="key"> Renewed At:</Typography>
                        <Typography className="value">
                          {row?.renewed_date
                            ? getLocalFormatedDate(row?.renewed_date)
                            : "-"}
                        </Typography>
                      </Stack>
                    </Stack>

                    {index < _DATA?.length - 1 && (
                      <Divider
                        className="my-2 mt-3"
                        sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                      />
                    )}
                  </>
                );
              })}
              {!renewedPropertiesData?.data?.length > 0 &&
                !terminationDocLoader && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found
                  </Typography>
                )}
            </Box>
            {/* desktop view */}
            <Box sx={{ width: "100%" }} className="d-none d-lg-block">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {_DATA?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.id}
                            style={{ mineight: "20px" }}
                          >
                            <TableCell align="left">
                              {(page - 1) * 10 + index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {row?.address || "-"} asd
                            </TableCell>
                            <TableCell align="left">
                              {row?.city || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.county || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.zip || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.transaction_id || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.renewed_date
                                ? getLocalFormatedDate(row?.renewed_date)
                                : "-"}
                            </TableCell>

                            {/* <TableCell align="left">
                        <Tooltip
                          title={"Terminate this property."}
                          TransitionComponent={Zoom}
                          placement="top"
                          arrow
                        >
                          <Button
                            className="text-white error_popup_btn"
                            onClick={() => {
                              setSelectedProperty(row);
                              setPropertyTerminatePopup(true);
                            }}
                          >
                            Terminate
                          </Button>
                        </Tooltip>
                      </TableCell> 
                      <TableCell>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMenuClick(event);
                            setSelectedProperty(row);
                          }}
                          size="small"
                          className="settings-button"
                          aria-controls="settings-menu"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <SettingsIconSVG color={"#15BE53"} />
                        </IconButton>
                      </TableCell>*/}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!renewedPropertiesData?.data?.length > 0 &&
                    !terminationDocLoader && (
                      <Typography className="p-5 text-center" variant="h6">
                        No Data Found
                      </Typography>
                    )}
                </TableContainer>
              </Paper>
            </Box>
            {renewedPropertiesData?.count > PER_PAGE && (
              <Box id="pagination-container">
                <CustomPagination
                  count={count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </>
        ) : (
          <Typography className="w-100 p-5 text-center text-danger bg-white fs-6">
            {renewedPropertiesData?.data?.message || "Something went wrong!"}
          </Typography>
        )
      ) : null}

      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {/* <MenuItem
          className="setting-menu-item"
          onClick={() => uploadedPropertyDocuments()}
        >
          <Box className="setting-menu-box">
            <UploadFileOutlinedIcon />
            <Typography className="setting-menu-text">
              Upload Document
            </Typography>
          </Box>
        </MenuItem> */}
      </Menu>
      <Loader open={terminationDocLoader} />
    </>
  );
};

export default RenewedProperties;
