import React from "react";
import { useState } from "react";

import LockIcon from "@mui/icons-material/Lock";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import CreateProvidersForm from "./CreateProvidersForm";

const AddProviderComponent = ({ goBack, listingAPI = () => {} }) => {
  const [screen, setScreen] = useState("createAcc");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mb={3}
      >
        <Typography variant="h6" className="card-title fw-600">
          Create Provider Account
        </Typography>
        <Tooltip
          className="d-none d-sm-block"
          title="Go to Providers list"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <Button
            variant="contained"
            className="success-btn d-none d-sm-block"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to go back to  Providers list?"
                )
              ) {
                goBack();
              }
            }}
          >
            Go Back
          </Button>
        </Tooltip>
        <Tooltip
          className="d-flex d-sm-none"
          title="Go to Providers list"
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <IconButton
            className="success-btn d-flex d-sm-none"
            variant="outlined"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to go back to Providers list?"
                )
              ) {
                goBack();
              }
            }}
            sx={{ color: "#FFF" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box pb={3}>
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "white",
            width: "100%",
            boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
          }}
        >
          <Typography
            align="center"
            variant="subtitle1"
            className="py-1 formHeader"
          >
            <LockIcon sx={{ fontSize: 16 }} className="me-1" />
            Secure connection
          </Typography>
          <Box className="accountBoxWrapper">
            {/* signup form */}
            {screen === "createAcc" && (
              <>
                <Typography align="center" variant="body1">
                  We just need to gather some information to set up Provider's
                  digital profile.
                </Typography>
                <CreateProvidersForm goBack={goBack} listingAPI={listingAPI} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddProviderComponent;
