import React, { useState } from "react";
import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import SignatureLists from "./SignatureLists";
import CreateIcon from "@mui/icons-material/Create";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import SignPad from "./signaturePad";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { useDispatch } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography className="mb-0">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SignatureTab = ({
  signpad,
  signatureDone,
  userName,
  error,
  removeErrors,
  setSign,
}) => {
  const [value, setValue] = useState(1);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    console.log("tab value==>", newValue);
    // if (newValue === 2) {
    dispatch(userSigupAction.signBase64Value());
    setSign("");
    removeErrors();
    // }
    setValue(newValue);
  };

  const tabContent = [
    {
      label: "Select style",
      value: 1,
      icon: <HdrAutoIcon />,
      component: (
        <>
          <SignatureLists
            signatureDone={signatureDone}
            userName={userName}
            error={error}
            removeErrors={removeErrors}
          />
        </>
      ),
    },
    {
      label: "Draw Your Signature",
      value: 2,
      icon: <CreateIcon />,
      component: <>{signpad}</>,
      //   component: (
      //     <SignPad
      //       signatureDone={signatureDone}
      //       userName={userName}
      //       error={error}
      //     />
      //   ),
    },
  ];

  return (
    <Box className="accountBoxWrapper findPropertyBoxWrapper mb-0 pb-3">
      <Typography align="center" variant="h3">
        Your signature
      </Typography>
      <Typography
        sx={{ maxWidth: "100% !important" }}
        align="center"
        variant="body1"
        className="mb-1"
      >
        Please draw or select the box below. We require your signature for the
        documents to secure your property.
      </Typography>
      <Box className="d-block d-md-none">
        <Select
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          displayEmpty
          className="mobile-select-tabs w-100 mb-1"
          inputProps={{ "aria-label": "Without label" }}
        >
          {tabContent?.map((tabs, index) => (
            <MenuItem
              sx={{
                color: value === index + 1 ? "#0D120B" : "#0395FF",
                paddingLeft: value === index + 1 ? "30px" : "",
              }}
              value={tabs?.value}
            >
              {tabs?.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="d-flex justify-content-center align-items-center mb-0">
        <Tabs
          value={value}
          className="bg-white d-none d-md-block"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {tabContent?.map((tabs, index) => (
            <Tab
              icon={tabs?.icon}
              iconPosition="start"
              value={tabs?.value}
              label={tabs?.label}
              {...a11yProps(index + 1)}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        {tabContent?.map((tabs, index) => (
          <TabPanel value={value} index={index + 1}>
            {tabs.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default SignatureTab;
