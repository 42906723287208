import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import AlertDialog from "../common/AlertDialog";
import { autoFocusOnField, getObjLength } from "../helpers/utils";
import { Loader } from "./loader";

const SecurityPinGeneration = ({
  onCloseBtnFun,
  titleText,
  confirmText,
  subTitletext,
  apiCall,
  securityPinResponse,
  loader,
}) => {
  const [pin, SetPIN] = useState("");
  const [FinalPIN, SetFinalPIN] = useState("");
  const [FinalPINScreen, SetFinalPINScreen] = useState(false);
  const [pinErr, SetPINErr] = useState(undefined);
  const [pin2Err, SetPIN2Err] = useState(undefined);
  const [pinSucess, SetPINSucess] = useState("");
  const [pin2Sucess, SetPIN2Sucess] = useState("");
  const [diableMobileBtn, setdiableMobileBtn] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  console.log("securityPinResponse===>>", securityPinResponse);

  const handlePIN1Change = (pin) => {
    SetPINSucess("");
    if (pin.length === 5) {
      SetPINErr("");
    }
    console.log("pin handled==>>", pin);
    SetPIN(pin);
  };
  const handlePIN2Change = (pin) => {
    SetPIN2Sucess("");
    if (pin.length === 5) {
      SetPIN2Err("");
    }
    console.log("pin handled==>>", pin);
    SetFinalPIN(pin);
  };

  const pinValidate = (event) => {
    event.preventDefault();
    console.log("in pin validation", pin.length);
    SetPINSucess("");
    if (pin.length === 5) {
      SetFinalPINScreen(true);
      autoFocusOnField();
    } else {
      SetPINErr("Please Enter 5 digit Pin to continue further.");
    }
  };
  const matchPINValidate = (event) => {
    event.preventDefault();
    console.log("in pin validation", pin.length);
    SetPIN2Sucess("");
    // props.onBtnClick();
    if (FinalPIN.length === 5) {
      if (pin === FinalPIN) {
        SetPIN2Err("");
        apiCall(pin);
      } else {
        SetPIN2Err("Pin does not match. Please try again.");
      }
    } else {
      SetPIN2Err("Please Enter 5 digit Pin to continue further.");
    }
  };
  const retryPIN = () => {
    SetPIN("");
    SetFinalPIN("");
    SetFinalPINScreen(false);
    SetPIN2Err("");
    autoFocusOnField();
  };

  const onCloseFunction = () => {
    setAlertMsg(false);
    onCloseBtnFun();
  };

  useEffect(() => {
    if (getObjLength(securityPinResponse)) {
      setAlertMsg(true);
      if (securityPinResponse?.success) {
        setSuccessMsg(securityPinResponse?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(securityPinResponse?.data?.message);
      }
    }
  }, [securityPinResponse]);

  const PINFieldStyle = {
    background: "#F6F9FC",
    border: "1px solid rgba(52, 75, 91, 0.1)",
    borderRadius: "3px",
    width: "40px",
    height: "55px",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "55px",
    textAlign: "center",
    color: "#0D120B",
  };
  return (
    <Box className="accountBoxWrapper">
      <Typography align="center" variant="h3">
        {!FinalPINScreen ? titleText : confirmText}
      </Typography>
      {!FinalPINScreen ? (
        <>
          <Typography align="center" variant="body1">
            {subTitletext}
          </Typography>
          <form className="text-center otpBoxWrapper" onSubmit={pinValidate}>
            <OtpInput
              className="otp mx-1"
              value={pin}
              isInputNum
              onChange={handlePIN1Change}
              numInputs={5}
              shouldAutoFocus
              disabled={diableMobileBtn}
              inputStyle={PINFieldStyle}
            />
            {pinErr?.length > 0 && pinSucess?.length === 0 ? (
              <Typography variant="caption" className="text-danger 1" p={2}>
                {pinErr}
              </Typography>
            ) : (
              <Typography variant="caption" className="text-success 3" p={2}>
                {pinSucess}
              </Typography>
            )}
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={pinValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableMobileBtn}
              >
                Next
              </Button>
            </div>
          </form>
        </>
      ) : (
        <form onSubmit={matchPINValidate}>
          <Typography align="center" variant="body1">
            Generate 5 digits pin which you can use to connect to us.
          </Typography>
          <Box className="text-center otpBoxWrapper">
            <OtpInput
              className="otp mx-1"
              value={FinalPIN}
              isInputNum
              onChange={handlePIN2Change}
              numInputs={5}
              shouldAutoFocus
              disabled={diableMobileBtn}
              inputStyle={PINFieldStyle}
            />
            {pin2Err?.length > 0 && pin2Sucess?.length === 0 ? (
              <Typography variant="caption" className="text-danger 1" p={2}>
                {pin2Err}
              </Typography>
            ) : (
              <Typography variant="caption" className="text-success 3" p={2}>
                {pin2Sucess}
              </Typography>
            )}
          </Box>
          <div className="text-center stepBtn mt-2">
            <Button
              onClick={retryPIN}
              className="next_button mx-3 other_popup_btn"
              variant="contained"
              disabled={diableMobileBtn}
            >
              Retry
            </Button>
            <Button
              type="submit"
              onClick={matchPINValidate}
              className="next_button"
              variant="contained"
              disabled={diableMobileBtn}
            >
              submit
            </Button>
          </div>
        </form>
      )}

      {alertMsg && getObjLength(securityPinResponse) && (
        <>
          {getObjLength(securityPinResponse) && securityPinResponse?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                onCloseFunction();
              }}
              mesage={successMsg}
              buttons={
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => onCloseFunction()}
                >
                  ok, All Good
                </Button>
              }
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
              }}
              mesage={errMsg}
            />
          )}
        </>
      )}
      <Loader open={loader} />
    </Box>
  );
};

export default SecurityPinGeneration;
