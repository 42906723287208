import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Grid, Typography } from "@mui/material";

import { getUrlLink } from "../../helpers/utils";

const MortgageTab = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.properties?.userViewDetailsPropertyData
  );

  return (
    <>
      <Box className="modal-width">
        {propertyData?.property_details?.FinanceHistory?.length > 0 ? (
          propertyData?.property_details?.FinanceHistory?.map(
            (financeHistory, index) => (
              <>
                <Grid container spacing={2} mt={1}>
                  {/* 1st grid - col */}
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Row>
                      <Col className="label-text">
                        <Typography>Recording Date: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Loan Recording Date"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>TD Due Date: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Due Date"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Borrower Name(s): </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {propertyData?.property_details?.OwnerInfo?.[
                            "Owner Name"
                          ]?.trim() ||
                            propertyData?.property_details?.OwnerInfo?.[
                              "Owner Name 2"
                            ]?.trim() ||
                            "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Borrower Ownership: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Borrower Ownership"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Loan Type: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Loan Type"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Mortgage Term: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Loan Term"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Loan Flag's: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Loan Flag's"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                  </Grid>

                  {/* 2nd grid - col */}
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Row>
                      <Col className="label-text">
                        <Typography>Loan Amount: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {!isNaN(financeHistory?.["Loan Amount"])
                            ? financeHistory?.["Loan Amount"]
                            : "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Lender Name: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Lender"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Doc #: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {getUrlLink(
                            financeHistory?.["Doc #"],
                            financeHistory?.["Doc_link"]
                          )}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Borrower Occupancy: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Borrower Occupancy"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Type of Rate: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {financeHistory?.["Interest Rate Type"] || "-"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="label-text">
                        <Typography>Lender Address: </Typography>
                      </Col>
                      <Col className="value-text">
                        <Typography>
                          {propertyData?.property_details?.OwnerInfo?.[
                            "Mailing City & State"
                          ]?.trim()
                            ? [
                                propertyData?.property_details?.OwnerInfo?.[
                                  "Mailing Address"
                                ]?.trim(),
                                propertyData?.property_details?.OwnerInfo?.[
                                  "Mailing City & State"
                                ]?.trim(),
                              ]?.join(", ")
                            : propertyData?.property_details?.OwnerInfo?.[
                                "Mailing Address"
                              ]?.trim() || "-"}
                        </Typography>
                      </Col>
                    </Row>
                  </Grid>
                </Grid>
                {index <
                  propertyData?.property_details?.FinanceHistory?.length -
                    1 && <hr style={{ width: "97%" }} />}
              </>
            )
          )
        ) : (
          <Box className="w-100">
            <Typography
              variant="h6"
              className="p-5 bg-white text-black text-center"
            >
              No Mortgage Details Available
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MortgageTab;
