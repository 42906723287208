import { useEffect } from "react";
import React, { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";

import {
  Box,
  Button,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import { MenuProps } from "../../helpers/constants";
import CustomTextField from "../../common/CustomTextField";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import { promocodeStatusList, promocodeTypeList } from "./AddPromocodeModal";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDateForExpireDate,
  getObjLength,
} from "../../helpers/utils";

const fieldsValidations = (plans) => {
  return Yup.lazy((values) => {
    return Yup.object().shape({
      name: Yup.string("Please enter Name.")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true)
        .required("Name is required"),

      status: Yup.string("Please enter Status.")
        .trim("The Status cannot include leading and trailing spaces")
        .strict(true)
        .required("Status is required"),
      expire_date: Yup.string(
        "Select the expire date of the promo code"
      ).required("Select the expire date of the promo code"),

      m_SubscriptionValue: plans?.monthlyPlan
        ? values.m_SetupValue || values.m_RecordingValue
          ? Yup.number().moreThan(0, "The value should be more than Zero.")
          : Yup.number().required("At least one field is required")
        : Yup.string(),
      m_RecordingValue: plans?.monthlyPlan
        ? values.m_SubscriptionValue || values.m_SetupValue
          ? Yup.number().moreThan(0, "The value should be more than Zero.")
          : Yup.number().required("At least one field is required")
        : Yup.string(),
      m_SetupValue: plans?.monthlyPlan
        ? values.m_SubscriptionValue || values.m_RecordingValue
          ? Yup.string().trim().strict(true)
          : Yup.string().required("At least one field is required")
        : Yup.string(),

      y_SubscriptionValue: plans?.yearlyPlan
        ? values.y_SetupValue || values.y_RecordingValue
          ? Yup.number().moreThan(0, "The value should be more than Zero.")
          : Yup.number().required("At least one field is required")
        : Yup.string(),
      y_RecordingValue: plans?.yearlyPlan
        ? values.y_SubscriptionValue || values.y_SetupValue
          ? Yup.number().moreThan(0, "The value should be more than Zero.")
          : Yup.number().required("At least one field is required")
        : Yup.string(),

      max_redemptions: Yup.number(
        "Please enter how may times promocode can be used."
      ).when("is_promocode_unlimited", {
        is: (a) => !a,
        then: Yup.number("Please enter how may times promocode can be used.")
          .integer("Please enter integer value")
          .moreThan(0, `The Maximum Redemption should be more than Zero.`)
          .required(
            "Please fill maximum redeem field or make the promocode Unlimited by select the checkbox end of the form."
          ),
        otherwise: Yup.number(
          "Please enter how may times promocode can be used."
        )
          .optional()
          .nullable(),
      }),
      // is_promocode_unlimited: Yup.boolean().when("max_redemptions", {
      //   is: (a) => !a || a?.length === 0,
      //   then: Yup.boolean()
      //     .required()
      //     .oneOf(
      //       [true],
      //       "Please select the checkbox or fill maximum redeem field."
      //     ),
      //   otherwise: Yup.boolean(),
      // }),
    });
  });
};

const IndividualPlansForm = ({
  selectedItem,
  onSubmit = () => {},
  tomorrow,
  handleClose,
}) => {
  const [monthlyPlan, setMonthlyPlan] = useState(true);
  const [recurringMonthlyPlan, setMonthlyRecurringPlan] = useState(false);
  const [yearlyPlan, setYearlyPlan] = useState(true);
  const [recurringYearlyPlan, setYearlyRecurringPlan] = useState(false);

  const onMonthlyPlanChecked = (e, checked, setFieldValue) => {
    setFieldValue("m_SubscriptionValue", "");
    setFieldValue("m_RecordingValue", "");
    setFieldValue("m_SetupValue", "");
    setMonthlyPlan((prevState) => !prevState);
    if (!checked) {
      setFieldValue("m_SubscriptionType", "");
      setFieldValue("m_RecordingType", "");
      setFieldValue("m_SetupType", "");
    } else {
      setFieldValue("m_SubscriptionType", "fixed");
      setFieldValue("m_RecordingType", "fixed");
      setFieldValue("m_SetupType", "fixed");
    }
  };

  const onMonthlyRecurringPlanChecked = (e, checked, setFieldValue) => {
    setMonthlyRecurringPlan((prevState) => !prevState);
  };

  const onYearlyRecurringPlanChecked = (e, checked, setFieldValue) => {
    setYearlyRecurringPlan((prevState) => !prevState);
  };

  const onYearlyPlanChecked = (e, checked, setFieldValue) => {
    setFieldValue("y_SubscriptionValue", "");
    setFieldValue("y_RecordingValue", "");
    setYearlyPlan((prevState) => !prevState);
    console.log("!checked==>", !checked);
    if (!checked) {
      setFieldValue("y_SubscriptionType", "");
      setFieldValue("y_RecordingType", "");
    } else {
      setFieldValue("y_SubscriptionType", "fixed");
      setFieldValue("y_RecordingType", "fixed");
    }
  };

  const formSubmit = (values) => {
    console.log("into submit 0");
    if (monthlyPlan || yearlyPlan) {
      let yearly_values = {
        subscription: {
          type: values?.y_SubscriptionType,
          value: values?.y_SubscriptionValue,
        },
        "recording charge": {
          type: values?.y_RecordingType,
          value: values?.y_RecordingValue,
        },
      };

      if (!values.y_SubscriptionValue?.length > 0) {
        delete yearly_values?.subscription;
      }
      if (!values.y_RecordingValue?.length > 0) {
        delete yearly_values?.["recording charge"];
      }

      let monthly_values = {
        subscription: {
          type: values?.m_SubscriptionType,
          value: values?.m_SubscriptionValue,
        },
        "recording charge": {
          type: values?.m_RecordingType,
          value: values?.m_RecordingValue,
        },
        "setup fee": {
          type: values?.m_SetupType,
          value: values?.m_SetupValue,
        },
      };

      const yearly_Plan = getObjLength(yearly_values) ? yearly_values : null;
      const monthly_Plan = getObjLength(monthly_values) ? monthly_values : null;
      if (!values.m_SubscriptionValue?.length > 0) {
        delete monthly_values?.subscription;
      }
      if (!values.m_RecordingValue?.length > 0) {
        delete monthly_values?.["recording charge"];
      }
      if (!values.m_SetupValue?.length > 0) {
        delete monthly_values?.["setup fee"];
      }

      let data = {
        name: values?.name,
        expire_date: getDateInYYYYMMDDFormat(new Date(values.expire_date), "-"),
        status: values?.status,
        max_redemptions: values?.max_redemptions,
        is_individual: true,
        apply_on_monthly_recuring: recurringMonthlyPlan,
        apply_on_yearly_recuring: recurringYearlyPlan,
        yearly: yearly_Plan,
        monthly: monthly_Plan,
      };
      console.log("into submit", data);

      if (values?.max_redemptions?.length > 0) {
        values.is_promocode_unlimited = false;
      }
      const copiedPayload = JSON.parse(JSON.stringify(data));

      if (values?.expire_date) {
        copiedPayload.expire_date = getDateInYYYYMMDDFormat(
          new Date(values.expire_date),
          "-"
        );
      }

      if (!values?.max_redemptions) {
        delete copiedPayload.max_redemptions;
      }

      const { applied_on, ...payload } = copiedPayload;
      // return console.log("payload-->", payload);
      onSubmit(payload);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setMonthlyPlan(selectedItem?.monthly);
      setMonthlyRecurringPlan(selectedItem?.apply_on_monthly_recuring);
      setYearlyPlan(selectedItem?.yearly);
      setYearlyRecurringPlan(selectedItem?.apply_on_yearly_recuring);
    }
  }, [selectedItem]);

  const validationSchema = fieldsValidations({
    monthlyPlan,
    yearlyPlan,
  });

  return (
    <Box className="d-flex justify-content-center  m-0">
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          name: selectedItem?.name || "",
          status: selectedItem?.status || "",
          max_redemptions: selectedItem?.no_of_time_redeem || "",
          expire_date:
            selectedItem?.id && selectedItem?.expire_at
              ? getLocalFormatedDateForExpireDate(
                  selectedItem?.expire_at,
                  "23, 59, 59"
                )
              : "",
          // --- For Monthly ---
          m_SubscriptionType:
            selectedItem?.monthly?.["subscription"]?.type || "fixed",
          m_SubscriptionValue:
            selectedItem?.monthly?.["subscription"]?.value || "",
          m_RecordingType:
            selectedItem?.monthly?.["recording charge"]?.type || "fixed",
          m_RecordingValue:
            selectedItem?.monthly?.["recording charge"]?.value || "",
          m_SetupType: selectedItem?.monthly?.["setup fee"]?.type || "fixed",
          m_SetupValue: selectedItem?.monthly?.["setup fee"]?.value || "",
          //  --- For Yearly -----
          y_SubscriptionType:
            selectedItem?.yearly?.["subscription"]?.type || "fixed",
          y_SubscriptionValue:
            selectedItem?.yearly?.["subscription"]?.value || "",
          y_RecordingType:
            selectedItem?.yearly?.["recording charge"]?.type || "fixed",
          y_RecordingValue:
            selectedItem?.yearly?.["recording charge"]?.value || "",
          is_promocode_unlimited:
            selectedItem?.id && !selectedItem?.no_of_time_redeem ? true : false,
        }}
        validationSchema={validationSchema}
        onSubmit={formSubmit}
      >
        {({ values, setFieldValue, touched, errors, initialValues }) => {
          console.log("Submit==>", errors);
          return (
            <Form className="beneficiary-form w-100">
              <DialogContent className="bg-white">
                {/* {!addPromocodeDetails?.success && ( */}
                <Grid container spacing={2} className="bg-white">
                  <>
                    {/* Name */}
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label="Name"
                        name="name"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                      />
                    </Grid>

                    {/* Status */}
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        className="sign-textField"
                        error={touched.status && Boolean(errors.status)}
                      >
                        <InputLabel
                          style={{ color: "#00000075" }}
                          label="status"
                        >
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.status}
                          label="Status"
                          name="status"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("status", event.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {promocodeStatusList?.map((status) => (
                            <MenuItem value={status?.value}>
                              {status?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.status && Boolean(errors.status) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.status && errors.status}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* no. of times promocode can be used */}
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label="Max Redeem"
                        name="max_redemptions"
                        type="number"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        otherProps={{
                          onChange: (event) => {
                            if (event.target.value?.length > 0) {
                              setFieldValue("is_promocode_unlimited", false);
                            }
                            setFieldValue(
                              "max_redemptions",
                              event.target.value
                            );
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>

                    {/* set expire date of the promocode */}
                    <Grid item xs={12} md={6}>
                      <FormControl className="sign-textField filter-search w-100">
                        <CustomDateRangePicker
                          rangeValue={values?.expire_date || ""}
                          onChange={(values) => {
                            setFieldValue("expire_date", values);
                          }}
                          onClear={() => {
                            setFieldValue("expire_date", "");
                          }}
                          className=""
                          sx={{ backgroundColor: "#f6f9fc" }}
                          label="Expire Date"
                          type="text"
                          name="expire_date"
                          values={values?.expire_date}
                          touched={touched}
                          errors={errors}
                          settings={{
                            singleDatePicker: true,
                            minDate: new Date(tomorrow.toString()),
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {/* Selection if the promocode is unlimited */}
                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <CustomizedCheckbox
                            checked={values?.is_promocode_unlimited}
                            onChecked={(event) => {
                              if (event.target.checked) {
                                setFieldValue("max_redemptions", "");
                              }
                              setFieldValue(
                                "is_promocode_unlimited",
                                event.target.checked
                              );
                            }}
                            name="is_promocode_unlimited"
                            helperText={
                              touched?.is_promocode_unlimited &&
                              errors?.is_promocode_unlimited
                            }
                            error={
                              touched?.is_promocode_unlimited &&
                              Boolean(errors?.is_promocode_unlimited)
                            }
                            sx={{ ml: 1 }}
                          />
                        }
                        label="Do you want user to use this promocode unlimited?"
                        // Would you like to allow the user to use this promo code without any limitations?
                      />
                      {touched.is_promocode_unlimited &&
                        Boolean(errors.is_promocode_unlimited) && (
                          <FormHelperText sx={{ color: "#d32f2f", ml: 2 }}>
                            {touched.is_promocode_unlimited &&
                              errors.is_promocode_unlimited}
                          </FormHelperText>
                        )}
                    </Grid>

                    {/* choose plans */}
                    <Grid item xs={12} md={6} mb={3}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 10 }}
                        pt={2}
                      >
                        <b className="">Applied on Monthly Plan</b>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                          className={`${selectedItem && "cursor-disable"}`}
                        >
                          <div>
                            <CustomizedCheckbox
                              checked={monthlyPlan}
                              disabled={monthlyPlan || selectedItem}
                              onChecked={(e) =>
                                onMonthlyPlanChecked(e, 1, setFieldValue)
                              }
                            />
                            <span>Yes</span>
                          </div>
                          <div>
                            <CustomizedCheckbox
                              checked={!monthlyPlan}
                              disabled={!monthlyPlan || selectedItem}
                              onChecked={(e) => {
                                onMonthlyPlanChecked(e, 0, setFieldValue);
                              }}
                            />
                            <span>No</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6} mb={3}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 10 }}
                        pt={2}
                      >
                        <b className="">Applied on Yearly Plan</b>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                          className={`${selectedItem && "cursor-disable"}`}
                        >
                          <div>
                            <CustomizedCheckbox
                              checked={yearlyPlan}
                              disabled={yearlyPlan || selectedItem}
                              onChecked={(e) =>
                                onYearlyPlanChecked(e, 1, setFieldValue)
                              }
                            />
                            <span>Yes</span>
                          </div>
                          <div>
                            <CustomizedCheckbox
                              checked={!yearlyPlan}
                              disabled={!yearlyPlan || selectedItem}
                              onChecked={(e) =>
                                onYearlyPlanChecked(e, 0, setFieldValue)
                              }
                            />
                            <span>No</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Grid>
                    {monthlyPlan && (
                      //    || (!monthlyPlan && !yearlyPlan)
                      <>
                        <Grid xs={12} md={12} px={1} py={3}>
                          <Divider>
                            <span className="fw-600">For Monthly Plan</span>{" "}
                          </Divider>
                        </Grid>

                        {/* For Subscription Amount */}
                        <Grid item xs={12} md={6}>
                          <span className="fw-600">Subscription Amount</span>
                          <FormControl
                            fullWidth
                            className="sign-textField mt-2"
                            error={
                              touched.m_SubscriptionType &&
                              Boolean(errors.m_SubscriptionType)
                            }
                          >
                            <InputLabel
                              style={{ color: "#00000075" }}
                              label="type"
                            >
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              className={`${selectedItem && "cursor-disable"}`}
                              disabled={selectedItem?.id ? true : false}
                              value={values?.m_SubscriptionType}
                              label="Type"
                              name="m_SubscriptionType"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue(
                                  "m_SubscriptionType",
                                  event.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {promocodeTypeList?.map((type) => (
                                <MenuItem value={type?.value}>
                                  {type?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.m_SubscriptionType &&
                              Boolean(errors.m_SubscriptionType) && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {touched.m_SubscriptionType &&
                                    errors.m_SubscriptionType}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <span className="fw-600">&nbsp;</span>
                          <CustomTextField
                            classes="mt-2"
                            label="Value"
                            name="m_SubscriptionValue"
                            type="number"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              disabled: selectedItem?.id ? true : false,
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        {/* For recording fees */}
                        <Grid item xs={12} md={6}>
                          <span className="fw-600">
                            Deposit for recording fees
                          </span>
                          <FormControl
                            fullWidth
                            className="sign-textField mt-2"
                            error={
                              touched.m_RecordingType &&
                              Boolean(errors.m_RecordingType)
                            }
                          >
                            <InputLabel
                              style={{ color: "#00000075" }}
                              label="type"
                            >
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              disabled={selectedItem?.id ? true : false}
                              value={values?.m_RecordingType}
                              label="Type"
                              name="m_RecordingType"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue(
                                  "m_RecordingType",
                                  event.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {promocodeTypeList?.map((type) => (
                                <MenuItem value={type?.value}>
                                  {type?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.m_RecordingType &&
                              Boolean(errors.m_RecordingType) && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {touched.m_RecordingType &&
                                    errors.m_RecordingType}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <span className="fw-600">&nbsp;</span>
                          <CustomTextField
                            label="Value"
                            classes="mt-2"
                            name="m_RecordingValue"
                            type="number"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              disabled: selectedItem?.id ? true : false,
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        {/* For Setup Fees */}
                        <Grid item xs={12} md={6}>
                          <span className="fw-600">Setup Fees</span>
                          <FormControl
                            fullWidth
                            className="sign-textField mt-2"
                            error={
                              touched.m_SetupType && Boolean(errors.m_SetupType)
                            }
                          >
                            <InputLabel
                              style={{ color: "#00000075" }}
                              label="type"
                            >
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              disabled={selectedItem?.id ? true : false}
                              value={values?.m_SetupType}
                              label="Type"
                              name="m_SetupType"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue(
                                  "m_SetupType",
                                  event.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {promocodeTypeList?.map((type) => (
                                <MenuItem value={type?.value}>
                                  {type?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.m_SetupType &&
                              Boolean(errors.m_SetupType) && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {touched.m_SetupType && errors.m_SetupType}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <span className="fw-600">&nbsp;</span>
                          <CustomTextField
                            classes="mt-2"
                            label="Value"
                            name="m_SetupValue"
                            type="number"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              disabled: selectedItem?.id ? true : false,
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        {/* For Monthly Recurring */}
                        <Grid item xs={12} md={6} mb={3}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 10 }}
                            pt={2}
                          >
                            <b className="">On Subscription Renewal</b>
                            <Stack
                              direction="row"
                              spacing={{ xs: 1, sm: 1, md: 2 }}
                              alignItems="center"
                              className={`${selectedItem && "cursor-disable"}`}
                            >
                              <div>
                                <CustomizedCheckbox
                                  checked={recurringMonthlyPlan}
                                  disabled={
                                    recurringMonthlyPlan || selectedItem
                                  }
                                  onChecked={(e) =>
                                    onMonthlyRecurringPlanChecked(
                                      e,
                                      1,
                                      setFieldValue
                                    )
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div>
                                <CustomizedCheckbox
                                  checked={!recurringMonthlyPlan}
                                  disabled={
                                    !recurringMonthlyPlan || selectedItem
                                  }
                                  onChecked={(e) => {
                                    onMonthlyRecurringPlanChecked(
                                      e,
                                      0,
                                      setFieldValue
                                    );
                                  }}
                                />
                                <span>No</span>
                              </div>
                            </Stack>
                          </Stack>
                        </Grid>
                      </>
                    )}

                    {yearlyPlan && (
                      <>
                        <Grid xs={12} md={12} px={1} py={3}>
                          <Divider>
                            <span className="fw-600">For Yearly Plan</span>{" "}
                          </Divider>
                        </Grid>

                        {/* For Subscription Amount */}
                        <Grid item xs={12} md={6}>
                          <span className="fw-600">Subscription Amount</span>
                          <FormControl
                            fullWidth
                            className="sign-textField mt-2"
                            error={
                              touched.y_SubscriptionType &&
                              Boolean(errors.y_SubscriptionType)
                            }
                          >
                            <InputLabel
                              style={{ color: "#00000075" }}
                              label="type"
                            >
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              disabled={selectedItem?.id ? true : false}
                              value={values?.y_SubscriptionType}
                              label="Type"
                              name="y_SubscriptionType"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue(
                                  "y_SubscriptionType",
                                  event.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {promocodeTypeList?.map((type) => (
                                <MenuItem value={type?.value}>
                                  {type?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.y_SubscriptionType &&
                              Boolean(errors.y_SubscriptionType) && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {touched.y_SubscriptionType &&
                                    errors.y_SubscriptionType}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <span className="fw-600">&nbsp;</span>
                          <CustomTextField
                            classes="mt-2"
                            label="Value"
                            name="y_SubscriptionValue"
                            type="number"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              disabled: selectedItem?.id ? true : false,
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        {/* For recording fees */}
                        <Grid item xs={12} md={6}>
                          <span className="fw-600">
                            Deposit for recording fees
                          </span>
                          <FormControl
                            fullWidth
                            className="sign-textField mt-2"
                            error={
                              touched.y_RecordingType &&
                              Boolean(errors.y_RecordingType)
                            }
                          >
                            <InputLabel
                              style={{ color: "#00000075" }}
                              label="type"
                            >
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              disabled={selectedItem?.id ? true : false}
                              value={values?.y_RecordingType}
                              label="Type"
                              name="y_RecordingType"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue(
                                  "y_RecordingType",
                                  event.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {promocodeTypeList?.map((type) => (
                                <MenuItem value={type?.value}>
                                  {type?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.y_RecordingType &&
                              Boolean(errors.y_RecordingType) && (
                                <FormHelperText sx={{ color: "#d32f2f" }}>
                                  {touched.y_RecordingType &&
                                    errors.y_RecordingType}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <span className="fw-600">&nbsp;</span>
                          <CustomTextField
                            classes="mt-2"
                            label="Value"
                            name="y_RecordingValue"
                            type="number"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              disabled: selectedItem?.id ? true : false,
                              onWheel: (e) => {
                                e.target.blur();
                              },
                            }}
                          />
                        </Grid>

                        {/* For Yearly Recurring */}
                        <Grid item xs={12} md={6} mb={3}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 10 }}
                            pt={2}
                          >
                            <b className="">On Subscription Renewal</b>
                            <Stack
                              direction="row"
                              spacing={{ xs: 1, sm: 1, md: 2 }}
                              alignItems="center"
                              className={`${selectedItem && "cursor-disable"}`}
                            >
                              <div>
                                <CustomizedCheckbox
                                  checked={recurringYearlyPlan}
                                  disabled={recurringYearlyPlan || selectedItem}
                                  onChecked={(e) =>
                                    onYearlyRecurringPlanChecked(
                                      e,
                                      1,
                                      setFieldValue
                                    )
                                  }
                                />
                                <span>Yes</span>
                              </div>
                              <div>
                                <CustomizedCheckbox
                                  checked={!recurringYearlyPlan}
                                  disabled={
                                    !recurringYearlyPlan || selectedItem
                                  }
                                  onChecked={(e) => {
                                    onYearlyRecurringPlanChecked(
                                      e,
                                      0,
                                      setFieldValue
                                    );
                                  }}
                                />
                                <span>No</span>
                              </div>
                            </Stack>
                          </Stack>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={12}>
                      <p>
                        <b>Note: </b>We cannot update the plan details after
                        creating the promo code.
                        {/*<ul>
                          <li>
                            Any one Plan should be selected to create promocode
                            which applied on individual amounts.
                          </li>
                          <li>
                            After creating the promo code, we cannot update the
                            plan details.
                          </li>
                        </ul> */}
                      </p>
                    </Grid>
                  </>
                  {!yearlyPlan && !monthlyPlan && (
                    <FormHelperText
                      sx={{
                        color: "#d32f2f !important",
                        m: "0 !important",
                        fontSize: "14px !important",
                        fontWeight: 500,
                        width: "100%",
                        textAlign: "center",
                        maxWidth: "100% !important",
                        paddingTop: "10px",
                      }}
                    >
                      Please enable any of the Subscription plan
                    </FormHelperText>
                  )}
                </Grid>
              </DialogContent>

              {/* <DialogActions> */}

              <>
                <Stack
                  direction="row"
                  pb={2}
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Button
                    className="popup_Btn other_popup_btn"
                    // sx={{
                    //   padding: "10px 20px !important",
                    //   margin: "0 !important",
                    // }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn"
                    // sx={{
                    //   padding: "10px 20px !important",
                    //   margin: "0 !important",
                    // }}
                    type="submit"
                    disabled={
                      JSON.stringify(initialValues) === JSON.stringify(values)
                    }
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </>

              {/* </DialogActions> */}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default IndividualPlansForm;
