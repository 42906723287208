import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Button,
  Card,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import AlertDialog from "../../common/AlertDialog";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import RequestedDocumentListModal from "../../common/RequestedDocumentListModal";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../../admin/AddLeads";
import { documentCount } from "../../admin/requestDocumentList/RequestDocumnetsListTable";
import {
  PER_PAGE,
  REQUEST_ACCESS_ACTION,
  REQUESTED_DOCUMENT_STATUS,
} from "../../helpers/constants";
import {
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
  getObjLength,
  urlPatternValidation,
} from "../../helpers/utils";
import { serviceProviderProfileAction } from "../../../redux/slices/serviceProvider/servicerProfile/servicerProfileSlice";
import UploadRequestDocModal from "../../admin/AdminDashboard/UploadRequestDocModal";

const columns = [
  {
    key: 1,
    id: "no.",
    disablePadding: true,
    label: "No.",
    minWidth: 10,
  },
  {
    key: 2,
    id: "username",
    disablePadding: true,
    label: "User Name",
    minWidth: 110,
  },
  {
    key: 3,
    id: "doc_url",
    disablePadding: true,
    label: "Document",
    minWidth: 20,
    isCenter: true,
  },
  {
    key: 4,
    id: "address",
    disablePadding: true,
    label: "Property",
    minWidth: 165,
  },
  {
    key: 5,
    id: "request_action",
    disablePadding: true,
    label: "Request Action",
    minWidth: 90,
  },
  {
    key: 6,
    id: "transaction_id",
    disablePadding: true,
    label: "Transaction id",
    minWidth: 185,
  },
  {
    key: 7,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 80,
    showInfoIcon: true,
    // tooltipText: (
    //   <>
    //     <Stack direction="column">
    //       <Typography>Requested</Typography>
    //       <Typography>Accepted</Typography>
    //       <Typography>Rejected</Typography>
    //       <Typography> Completed</Typography>
    //     </Stack>
    //   </>
    // ),
    tooltipText: "Requested/ Accepted/ Rejected/ Completed/ Cancelled",
  },
  {
    key: 8,
    id: "created_at",
    disablePadding: true,
    label: "Created At ",
    minWidth: 120,
    sorting: true,
  },
  {
    key: 9,
    id: "updated_at",
    disablePadding: true,
    label: "Updated At ",
    minWidth: 120,
    sorting: true,
  },
  {
    key: 10,
    id: "action",
    disablePadding: true,
    label: "Actions",
    minWidth: 20,
  },
];

function Row({
  row,
  handleMenuClick,
  page,
  index,
  handleCollapsibleRow,
  isCollapsible = false,
  setIsDocumentListModalOpen = () => {},
  setSelectedDocument = () => {},
}) {
  return (
    <React.Fragment>
      <TableRow
        hover
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        // onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{ backgroundColor: isCollapsible ? "rgba(3, 149, 255, 0.07)" : "" }}
      >
        <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
        <TableCell align="left" className="text-capitalize">
          {row?.property?.id
            ? `${row?.property?.owner?.first_name?.trim()} ${row?.property?.owner?.last_name?.trim()}`
            : "-"}
        </TableCell>
        <TableCell align="center">
          {urlPatternValidation(row?.doc_url) ||
          urlPatternValidation(row?.uploaded_doc) ? (
            <span
              style={{ wordBreak: "break-all" }}
              className={`custom-link-wrapper text-break preview-url cursor-pointer p-2`}
              onClick={() => {
                setIsDocumentListModalOpen(true);
                setSelectedDocument(row);
              }}
            >
              {documentCount(row?.doc_url, row?.uploaded_doc)}
            </span>
          ) : (
            0
          )}
        </TableCell>
        <TableCell align="left">{row?.property?.address}</TableCell>
        <TableCell align="left" className="text-capitalize">
          {row?.request_action}
        </TableCell>
        <TableCell align="left">{row?.transaction_id || "-"}</TableCell>
        <TableCell align="left">{row?.status || "-"}</TableCell>
        <TableCell align="left" width={130}>
          {row?.created_at ? getLocalFormatedDate(row?.created_at) : "-"}
        </TableCell>
        <TableCell align="left" width={130}>
          {row?.updated_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                if (row?.status === "Requested" || row?.status === "Accepted") {
                  handleMenuClick(event, row);
                }
              }}
              size="small"
              className="settings-button"
              aria-controls="settings-menu"
              aria-haspopup="true"
              aria-expanded={"true"}
            >
              <SettingsIconSVG
                color={
                  row?.status === "Requested" || row?.status === "Accepted"
                    ? "#15BE53"
                    : "#8f9294"
                }
              />
            </IconButton>
            {/* <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton> */}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow
        className="collapsible-row"
        sx={{ backgroundColor: "rgba(3, 149, 255, 0.07)" }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column" spacing={1}>
                <Box>
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    User Name
                  </Typography>
                  <Typography
                    className="content-text m-0 text-capitalize"
                    component="div"
                  >
                    {row?.property?.id
                      ? `${row?.property?.owner?.first_name?.trim()} ${row?.property?.owner?.last_name?.trim()}`
                      : "-"}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={7}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column" spacing={1}>
                <Box>
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  ></Typography>
                </Box>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function DocumentsTable({ data }) {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [toggleFilterOptions, setToggleFilterOptions] = React.useState(false);
  const [selectedKey, setSelectedKey] = useState("all");
  const [selectedValue, setSelectedValue] = useState("");
  const [requestAction, setRequestAction] = useState("");
  const [requestedStatus, setRequestedStatus] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [payload, setPayload] = useState({});
  const [isFilterAPICalled, setIsFilterAPICalled] = useState(false);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(9);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCancelRequest, setIsCancelRequest] = useState(false);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [isDocumentListModalOpen, setIsDocumentListModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploadDocPopup, setUplaodDocPopup] = useState(false);

  const open = Boolean(anchorEl);

  const {
    requestDocumentsDetails,
    cancelDocumentReqDataDetails,
    uploadRequestedDocDetails,
    uploadRequestedDocLoader,
  } = useSelector((state) => state.servicerProfile);

  const count = Math.ceil(requestDocumentsDetails?.count / PER_PAGE);

  const _DATA = requestDocumentsDetails?.data
    ? requestDocumentsDetails?.data
    : [];

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
    // _DATA.jump(p);
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedCollapsibleRow(row);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  const handleCancelRequest = () => {
    setIsCancelRequest(true);
  };

  const handleCancelRequestAPI = () => {
    console.log("handleCancelRequest==>", selectedCollapsibleRow);
    setIsCancelRequest(false);
    dispatch(
      serviceProviderProfileAction.cancelDocumentReqData({
        url: `service-provider/cancel-requested-documents/${selectedCollapsibleRow?.id}`,
        data: {},
        token: true,
      })
    );
  };

  const onSuccessFun = () => {
    getRequestDocumentData();
    dispatch(serviceProviderProfileAction.fetchCancelledDocReqData({}));
  };

  const onFailedFun = () => {
    dispatch(serviceProviderProfileAction.fetchCancelledDocReqData({}));
  };

  // upload documentation
  const uploadedPropertyDocuments = (row) => {
    setUplaodDocPopup(true);
  };
  const handleCloseModal = () => {
    dispatch(serviceProviderProfileAction.setUploadRequestedDocDetails({}));
    setUplaodDocPopup(false);
  };

  const handleUploadDocuments = (values) => {
    const uploadedDocs = values.upload_doc;
    const docType = selectedCollapsibleRow?.request_action?.includes(
      "termination"
    )
      ? "terminate"
      : "waiver";
    const formData = new FormData();

    // formData.append("id", selectedProperty.id.toString());
    // uploadedDocs.map((doc, index) =>
    formData.append(`document`, uploadedDocs);
    // );
    // formData.append("property_id", selectedProperty?.property_id);

    // Display the values

    for (const value of formData.values()) {
      console.log(value);
    }

    dispatch(
      serviceProviderProfileAction.uploadRequestedDoc({
        url: `service-provider/upload-document/${selectedCollapsibleRow?.id}/${docType}`,
        data: formData,
        token: true,
        isFile: true,
      })
    );
  };

  // handle filter property in promocodes
  const handleFilterProperty = () => {
    if (
      selectedValue ||
      requestAction ||
      selectedDateRange ||
      requestedStatus
    ) {
      setIsFilterAPICalled(true);
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: selectedKey || undefined,
        term: selectedValue || undefined,
        document_type: requestAction || undefined,
        status: requestedStatus || undefined,
        from_date:
          selectedKey === "created_at" && selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          selectedKey === "created_at" && selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          selectedKey === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          selectedKey === "updated_at" && selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        limit: PER_PAGE,
        offset: 0,
      }));
    }
    // if (selectedValue.length > 0) {
    //   setPage(1);
    // }
  };

  // handle reset function in promocodes
  const resetFilterOption = () => {
    setSelectedKey("all");
    setSelectedValue("");
    setSelectedDateRange(null);
    setRequestAction("");
    setRequestedStatus("");
    if (isFilterAPICalled) {
      setPayload({ limit: PER_PAGE, offset: 0 });
      setPage(1);
      setIsFilterAPICalled(false);
    }
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);

    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  // const getPromocodeDetails = useCallback(() => {}, []);

  const getRequestDocumentData = useCallback(() => {
    dispatch(
      serviceProviderProfileAction.requestDocumnetsData({
        url: "service-provider/requested-documents",
        data: {
          limit: PER_PAGE,
          offset: payload?.offset || 0,
          type: payload?.type,
          term: payload?.term,
          document_type: payload?.document_type,
          status: payload?.status,
          from_date: payload?.from_date,
          to_date: payload?.to_date,
          modified_from_date: payload?.modified_from_date,
          modified_to_date: payload?.modified_to_date,
          order_by_column: payload?.order_by_column || "updated_at",
          sorting_order: payload?.sorting_order || "desc",
        },
        token: true,
      })
    );
  }, [
    dispatch,
    payload?.document_type,
    payload?.from_date,
    payload?.modified_from_date,
    payload?.modified_to_date,
    payload?.offset,
    payload?.order_by_column,
    payload?.sorting_order,
    payload?.status,
    payload?.term,
    payload?.to_date,
    payload?.type,
  ]);

  useEffect(() => {
    getRequestDocumentData();
  }, [dispatch, getRequestDocumentData]);

  useEffect(() => {
    console.log(
      "cancelDocumentReqDataDetails==>.",
      cancelDocumentReqDataDetails
    );
  }, [cancelDocumentReqDataDetails]);

  return (
    <>
      {/* Filtering Component Section */}
      <Tooltip title="Filter options" placement="right-start">
        <Box
          id="filter"
          onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
        >
          <h3 className="filter-text">Filter your results</h3>
          <ArrowDropDownIcon
            className="filter-arrow"
            sx={{ ms: 1, fontSize: "20px" }}
          />
        </Box>
      </Tooltip>
      {/* Filtering Options */}
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          mb: 1.5,
        }}
      >
        {toggleFilterOptions && (
          <>
            {/* for mobile view */}
            <Box className="d-block d-md-none">
              <Grid
                container
                className="bg-white d-flex justify-content-around align-items-center px-4"
                spacing={2}
                pb={2}
                pt={2}
              >
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography className="search-text">Search By</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <FormControl className="filter-select-one w-100">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="All Types"
                      value={selectedKey}
                      onChange={(e) => {
                        setSelectedKey(e.target.value);
                        setSelectedValue("");
                        setRequestAction("");
                        setRequestedStatus("");
                        setSelectedDateRange(null);
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {columns.map(
                        (cell) =>
                          cell.id !== "no." && (
                            <MenuItem key={cell.id} value={cell.id}>
                              {cell.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {selectedKey !== "request_action" &&
                  selectedKey !== "status" &&
                  selectedKey !== "updated_at" &&
                  selectedKey !== "created_at" && (
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <FormControl
                        className="filter-search w-100"
                        variant="outlined"
                      >
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {(selectedKey === "all" ||
                  selectedKey === "request_action") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Request Action"
                        value={requestAction}
                        onChange={(e) => setRequestAction(e.target.value)}
                      >
                        {REQUEST_ACCESS_ACTION?.map((action, key) => (
                          <MenuItem id={action?.value} value={action?.value}>
                            {action?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-select-one w-100">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={requestedStatus}
                        onChange={(e) => setRequestedStatus(e.target.value)}
                      >
                        {REQUESTED_DOCUMENT_STATUS?.map((status, key) => (
                          <MenuItem id={status?.value} value={status?.value}>
                            {status?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "created_at" ||
                  selectedKey === "updated_at") && (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl className="filter-search w-100">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormControl className="w-100">
                    <Button
                      type="submit"
                      onClick={handleFilterProperty}
                      sx={mobileFilterResultStyle}
                    >
                      Filter Results
                    </Button>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    type="reset"
                    onClick={resetFilterOption}
                    sx={mobileFilterResetBtnStyle}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* for desktop view */}
            <Card
              className="d-none d-md-block filter-options-carddiv"
              // id="filter-options-card"
            >
              <Grid container className="filter-options-box">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div className="search-text">Search By</div>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="All Types"
                        value={selectedKey}
                        onChange={(e) => {
                          setSelectedKey(e.target.value);
                          setSelectedValue("");
                          setRequestAction("");
                          setRequestedStatus("");
                          setSelectedDateRange(null);
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {columns.map(
                          (cell) =>
                            cell.id !== "no." && (
                              <MenuItem key={cell.id} value={cell.id}>
                                {cell.label}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </FormControl>
                  </Stack>
                </Grid>

                {selectedKey !== "request_action" &&
                  selectedKey !== "status" &&
                  selectedKey !== "updated_at" &&
                  selectedKey !== "created_at" && (
                    <Grid item>
                      <FormControl className="filter-search" variant="outlined">
                        <InputLabel>Enter search term</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          }
                          label="Enter search term"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                {(selectedKey === "all" ||
                  selectedKey === "request_action") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Request Action"
                        value={requestAction}
                        onChange={(e) => setRequestAction(e.target.value)}
                      >
                        {REQUEST_ACCESS_ACTION?.map((action, key) => (
                          <MenuItem value={action?.value}>
                            {action?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "all" || selectedKey === "status") && (
                  <Grid item>
                    <FormControl className="filter-select-one">
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        value={requestedStatus}
                        onChange={(e) => setRequestedStatus(e.target.value)}
                      >
                        {REQUESTED_DOCUMENT_STATUS?.map((status, key) => (
                          <MenuItem value={status?.value}>
                            {status?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}

                {(selectedKey === "created_at" ||
                  selectedKey === "updated_at") && (
                  <Grid item>
                    <FormControl className="filter-search">
                      <CustomDateRangePicker
                        rangeValue={selectedDateRange}
                        onChange={(values) => {
                          setSelectedDateRange(values);
                        }}
                        onClear={() => {
                          setSelectedDateRange(null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControl>
                      <Button
                        className="filter-button"
                        onClick={handleFilterProperty}
                      >
                        Filter Results
                      </Button>
                    </FormControl>
                    <Button
                      className="reset-filters"
                      onClick={resetFilterOption}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Box>

      {/* Property Listing Table */}
      {/* mobile view */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 py-4">
        {_DATA?.map((row, index) => {
          return (
            <>
              <Stack
                direction="column"
                spacing={1.2}
                px={2}
                className="position-relative"
              >
                <Typography
                  className="value fw-500"
                  sx={{ fontSize: "15px !important" }}
                >
                  {row?.property?.address || "-"}
                </Typography>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> User Name:</Typography>
                  <Typography className="value text-capitalize">
                    {row?.property?.id
                      ? `${row?.property?.owner?.first_name?.trim()} ${row?.property?.owner?.last_name?.trim()}`
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Document:</Typography>
                  <Typography className="value text-capitalize">
                    {urlPatternValidation(row?.doc_url) ||
                    urlPatternValidation(row?.uploaded_doc) ? (
                      <span
                        className={`custom-link-wrapper text-break preview-url cursor-pointer`}
                        style={{ wordBreak: "break-all", fontSize: "13px" }}
                        onClick={() => {
                          setIsDocumentListModalOpen(true);
                          setSelectedDocument(row);
                        }}
                      >
                        {documentCount(row?.doc_url, row?.uploaded_doc)}
                      </span>
                    ) : (
                      0
                    )}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Request Action:</Typography>
                  <Typography className="value text-capitalize">
                    {row?.request_action || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Transaction ID:</Typography>
                  <Typography className="value">
                    {row?.transaction_id || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key d-flex align-items-center justify-content-start">
                    Status{" "}
                    <Tooltip
                      title="Requested / Accepted / Rejected / Completed"
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                      sx={{ cursor: "pointer" }}
                    >
                      <InfoOutlinedIcon
                        sx={{ fontSize: "13px", cursor: "pointer" }}
                      />
                    </Tooltip>{" "}
                    :
                  </Typography>
                  <Typography className="value">
                    {row?.status || "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Created At:</Typography>
                  <Typography className="value">
                    {row?.created_at
                      ? getLocalFormatedDate(row?.created_at)
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={0.5}>
                  <Typography className="key"> Uploaded At:</Typography>
                  <Typography className="value">
                    {row?.updated_at
                      ? getLocalFormatedDate(row?.updated_at)
                      : "-"}
                  </Typography>
                </Stack>

                {row?.status === "Requested" ? (
                  <Stack direction="column" spacing={0.5}>
                    <Typography className="value">
                      <Button
                        className="popup_Btn error_popup_btn"
                        onClick={handleCancelRequest}
                      >
                        Cancel Request
                      </Button>
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>

              {index < _DATA?.length - 1 && (
                <Divider
                  className="my-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {getObjLength(requestDocumentsDetails) && !_DATA?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>

      {/* desktop view */}
      <Box sx={{ width: "100%" }} className="mt-3 d-none d-sm-block">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHeaderForSorting
                columns={columns}
                handleSorting={handleSorting}
                directionFlow={directionFlow}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
              />
              <TableBody>
                {_DATA?.map((row, index) => {
                  return (
                    <Row
                      key={(page - 1) * 10 + index + 1}
                      row={row}
                      open={open}
                      handleMenuClick={handleMenuClick}
                      page={page}
                      index={index}
                      handleCollapsibleRow={handleCollapsibleRow}
                      isCollapsible={
                        selectedCollapsibleRow?.id === row?.id &&
                        isCollapsibleRow
                      }
                      setIsDocumentListModalOpen={setIsDocumentListModalOpen}
                      setSelectedDocument={setSelectedDocument}
                    />
                  );
                })}
              </TableBody>
            </Table>
            {!data?.length > 0 && (
              <Typography className="p-5 text-center" variant="h6">
                No Data Found.
              </Typography>
            )}
          </TableContainer>
        </Paper>

        <Menu
          key="edit"
          anchorEl={anchorEl}
          id="settings-menu"
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          {selectedCollapsibleRow?.status === "Requested" && (
            <MenuItem
              className="setting-menu-item"
              onClick={handleCancelRequest}
            >
              <Box className="setting-menu-box">
                <CancelOutlinedIcon />
                <Typography className="setting-menu-text">
                  Cancel Requested Document
                </Typography>
              </Box>
            </MenuItem>
          )}

          {selectedCollapsibleRow?.status === "Accepted" && (
            <MenuItem
              className="setting-menu-item"
              onClick={() => uploadedPropertyDocuments()}
            >
              <Box className="setting-menu-box">
                <UploadFileOutlinedIcon />
                <Typography className="setting-menu-text">
                  Upload Document
                </Typography>
              </Box>
            </MenuItem>
          )}
        </Menu>

        {requestDocumentsDetails?.count > PER_PAGE && (
          <Box id="pagination-container">
            <CustomPagination
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
      {/* <Loader open={usersPromocodeLoader} /> */}

      {/* Cancel Request Waring popup */}
      {isCancelRequest && (
        <AlertDialog
          openPopup={isCancelRequest}
          type={"Warning"}
          onClose={true}
          onCloseFun={() => {
            setIsCancelRequest(false);
          }}
          buttons={
            <>
              <div className="text-center stepBtn mt-4 ">
                <Button
                  onClick={() => setIsCancelRequest(false)}
                  className="popup_Btn other_popup_btn"
                  variant="contained"
                >
                  No,Cancel
                </Button>
                <Button
                  onClick={handleCancelRequestAPI}
                  className="popup_Btn success_popup_btn mx-3"
                  variant="contained"
                >
                  Yes,Sure
                </Button>
              </div>
            </>
          }
          mesage={
            <>
              {`Are you sure you want to cancel the ${selectedCollapsibleRow?.request_action} for `}
              <b>{selectedCollapsibleRow?.property?.address}</b>
              {"?"}
            </>
          }
        />
      )}

      {getObjLength(cancelDocumentReqDataDetails) ? (
        cancelDocumentReqDataDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={onSuccessFun}
            mesage={cancelDocumentReqDataDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={onFailedFun}
            mesage={cancelDocumentReqDataDetails?.data?.message}
            footer={false}
          />
        )
      ) : null}

      {/* document list modal */}
      {isDocumentListModalOpen && (
        <RequestedDocumentListModal
          open={isDocumentListModalOpen}
          handleClose={() => {
            setIsDocumentListModalOpen(false);
            setSelectedDocument(null);
          }}
          requestedDocument={selectedDocument?.doc_url}
          uploadedDocument={selectedDocument?.uploaded_doc}
        />
      )}

      {/* Uplaod Document popup */}
      <UploadRequestDocModal
        from="TerminationDocument"
        fileFormates={{
          "image/png": [".png", ".jpeg", ".jpg"],
          "image/gif": [".gif"],
          "application/vnd.ms-powerpoint": [".ppt"],
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/csv": [".csv"],
        }}
        multiple={false}
        open={uploadDocPopup}
        handleClose={() => {
          handleCloseModal();
        }}
        caption={
          "Please upload Property Termination document for this property. "
        }
        isUploadSuccess={uploadRequestedDocDetails?.success}
        uploadedDocData={uploadRequestedDocDetails}
        successMessage={uploadRequestedDocDetails?.message}
        errorMessage={uploadRequestedDocDetails?.data?.message}
        uploadLoader={uploadRequestedDocLoader}
        handleUploadDoc={handleUploadDocuments}
        fetchPropertyListingData={() => {
          getRequestDocumentData();
        }}
      />
    </>
  );
}
