import React from "react";

import { Link } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Typography } from "@mui/material";

import { getObjLength } from "../../helpers/utils";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import Successbadge from "../../../assets/properties/success-badge.svg";

const DownloadCSV = ({ onClose, exportCsvData }) => {
  console.log("exportCsvData==>", exportCsvData);

  return (
    <Box sx={{ p: 2 }} className="step4 documentSteps successStep">
      {getObjLength(exportCsvData) && exportCsvData?.success && (
        <>
          <Button
            onClick={onClose}
            className="modal-close-btn mt-2"
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h5" className="title my-3">
                Success
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="body1"
                className="text-center description mb-3 mb-md-5"
              >
                This is a success message. Your requested payment details
                document is ready! You can download it below.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img src={Successbadge} alt="success badge" className="py-4" />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
          <Link href="#" className="downloadDocumentLink">
            {exportCsvData?.data?.csv_link}
          </Link>
        </Grid> */}
            <Grid item xs={12} sm={8} md={6}>
              <a
                className="custom-link-wrapper text-break"
                href={exportCsvData?.data?.csv_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  onClick={() => onClose()}
                  variant="contained"
                  className="popup_Btn success_popup_btn mx-2"
                >
                  Download your file
                </Button>
              </a>
            </Grid>
          </Grid>
        </>
      )}
      {getObjLength(exportCsvData)
        ? !exportCsvData?.success && (
            <PlainAlertMessage
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                onClose("Error");
              }}
              message={exportCsvData?.data?.message}
              footer={false}
            />
          )
        : null}
    </Box>
  );
};

export default DownloadCSV;
