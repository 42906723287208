import React, { useEffect, useState } from "react";
import "../../../styles/lennar-create-account.scss";
import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import PropertyListing from "../../userProfile/add-property/PropertyListing";
import { useDispatch, useSelector } from "react-redux";
import FindPropertyByAddress from "./FindPropertyByAddress";
import FindPropertyByParcel from "./FindPropertyByParcel";
import { getObjLength } from "../../helpers/utils";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProviderAddProperty = ({
  path,
  setScreen = () => {},
  user_id = null,
}) => {
  const [value, setValue] = useState(1);
  const [propertyValues, setPropertyValues] = useState({});
  const [showPropertyListing, setShowPropertyListing] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  localStorage.removeItem("resumeCount");

  const { lennarSignUpDetails, lennarPropertiesDetails, lennarLoader } =
    useSelector((state) => state?.lennarSignUp);

  const {
    getPropertyData,
    selectedProperty: selectedPropertyData,
    propertyLoader,
    signupLoader,
  } = useSelector((state) => state.signUpUser);

  const tabContent = [
    {
      label: "Address",
      value: 1,
      component: (
        <FindPropertyByAddress
          onBtnClick={() => console.log("clicked..")}
          setShowPropertyListing={setShowPropertyListing}
          propertyValues={propertyValues}
          setPropertyValues={setPropertyValues}
          open={open}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          setScreen={setScreen}
        />
      ),
      icon: <LocationOnIcon />,
    },
    {
      label: "Parcel/Land",
      value: 2,
      icon: <BrightnessLowIcon />,
      component: (
        <FindPropertyByParcel
          path={path}
          setErrMsg={setErrMsg}
          setPropertyValues={setPropertyValues}
          setScreen={setScreen}
        />
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    dispatch(lennarSignUpAction.fetchcreatedPropertiesData({}));
    dispatch(userSigupAction.fetchUserPropertyData({}));
    setPropertyValues("");
    setErrMsg("");
    setValue(newValue);
  };

  const selectProperty = (data) => {
    console.log("selectProperty==>", data);
    // setOpen(true);
    dispatch(userSigupAction.selectedUserPropertyData(data));
  };

  const redirectToOwnerScreen = () => {
    setScreen("owner-verified");
    // dispatch(lennarSignUpAction.fetchCreateLennarUserData({}));
    // let data = {
    //   user_id: user_id,
    // };
    // dispatch(
    //   userSigupAction.getPropertiesList({
    //     url: "customer/property-list",
    //     data: data,
    //   })
    // );
    dispatch(lennarSignUpAction.fetchcreatedPropertiesData({}));
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
  };

  const onBackClick = (data) => {
    console.log("selectProperty== >", data);
    setShowPropertyListing(false);
    // setOpen(true);
    dispatch(userSigupAction.selectedUserPropertyData({}));
    dispatch(userSigupAction.fetchUserPropertyData({}));
  };

  // set alert on the page relaod
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "Changes you made may not be saved. Are you sure you want to leave?";

      if (window.confirm(message)) {
        console.log("refreshed...1");
        // User clicked "OK", allow navigation
        return null;
      } else {
        // User clicked "Cancel", prevent navigation
        event.preventDefault();
        return (event.returnValue = message);
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component is unmounted
    return () => {
      console.log("refreshed stop...");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (getObjLength(getPropertyData)) {
      if (getPropertyData?.success) {
        setPropertyValues(getPropertyData?.data);
        setErrMsg("");
        if (getPropertyData?.data?.length === 1) {
          dispatch(
            userSigupAction.selectedUserPropertyData(getPropertyData?.data[0])
          );
        }
        if (getPropertyData?.data?.length > 1) {
          setShowPropertyListing(true);
          console.log("setPropertyValues ==>", getPropertyData);
        }
      } else {
        setPropertyValues({});
        setErrMsg(getPropertyData?.data?.message);
      }
    }
  }, [getPropertyData]);

  useEffect(() => {
    console.log(
      Object.keys(selectedPropertyData)?.length,
      "selected property 22 ==>",
      selectedPropertyData
    );
    if (Object.keys(selectedPropertyData)?.length > 0) {
      // onBtnClick();
      // handleToggle();
      let propertyData = selectedPropertyData?.json_response;
      console.log(selectedPropertyData, "security questions==>", propertyData);
      let inputParams = {
        state_code: propertyData.mm_fips_state_code,
        property_id: selectedPropertyData?.property_id,
        muni_code: propertyData.mm_fips_muni_code,
        sa_y_coord: propertyData.sa_y_coord,
        sa_x_coord: propertyData.sa_x_coord,
        user_id: user_id,
        owner_id: user_id,
        address: selectedPropertyData.address,
        country: selectedPropertyData.country || "USA",
        city: selectedPropertyData.city,
        state: selectedPropertyData.state,
        zip: selectedPropertyData.zip,
        latitude: selectedPropertyData.latitude,
        longitude: selectedPropertyData.longitude,
        bed_rooms: selectedPropertyData.bed_rooms,
        baths: selectedPropertyData.baths,
        sqft: selectedPropertyData.sqft,
        year_built: selectedPropertyData.year_built,
        json_response: propertyData,
        price: selectedPropertyData.price,
        county: selectedPropertyData.county,
        step: 2,
        step_key: "GENERATE_QUESTIONS",
      };
      console.log("properyu daya=>", inputParams);
      dispatch(
        lennarSignUpAction.createProperties({
          url: "providers/" + path + "/properties/ep/create",
          data: { domain: path, create_by_ep: true, properties: [inputParams] },
          toekn: true,
        })
      );
    }
  }, [selectedPropertyData]);

  return (
    <>
      {!showPropertyListing ? (
        <Box
          className="accountBoxWrapper findPropertyBoxWrapper"
          // className="d-flex justify-content-center align-items-center"
        >
          <Typography align="center" variant="h3">
            Property Search
          </Typography>
          <Typography align="center" variant="body1">
            Please provide us with the address of the property you would like to
            have monitored and protected.
          </Typography>
          <Box className="d-block d-md-none" margin="0 10px">
            <Select
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              displayEmpty
              className="mobile-select-tabs w-100"
              inputProps={{ "aria-label": "Without label" }}
            >
              {tabContent?.map((tabs, index) => (
                <MenuItem
                  sx={{
                    color: value === index + 1 ? "#0D120B" : "#0395FF",
                    paddingLeft: value === index + 1 ? "30px" : "",
                  }}
                  value={tabs?.value}
                >
                  {tabs?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="d-flex justify-content-center align-items-center ">
            <Tabs
              value={value}
              className="bg-white d-none d-md-block"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              {tabContent?.map((tabs, index) => (
                <Tab
                  icon={tabs?.icon}
                  iconPosition="start"
                  value={tabs?.value}
                  label={tabs?.label}
                  {...a11yProps(index + 1)}
                />
              ))}
            </Tabs>
          </Box>
          <Box>
            {tabContent?.map((tabs, index) => (
              <TabPanel value={value} index={index + 1}>
                {tabs.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
      ) : (
        // {/* )} */}
        // {/* {showPropertyListing &&  */}
        <Box className="accountBoxWrapper findPropertyBoxWrapper">
          <Typography align="center" variant="h3">
            Property List
          </Typography>
          <Typography align="center" variant="body1">
            Please select your relevant property from the property list.
          </Typography>
          {getPropertyData?.data?.map((data, key) => (
            <PropertyListing
              data={data}
              selectProperty={selectProperty}
              //   handleToggle={handleToggle}
            />
          ))}
          <div className="text-center stepBtn">
            <Button
              onClick={onBackClick}
              className="next_button my-3"
              variant="contained"
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          </div>
        </Box>
      )}

      {getObjLength(getPropertyData) && !getPropertyData?.success && (
        <AlertDialog
          type="Error"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(userSigupAction.fetchUserPropertyData({}));
            dispatch(lennarSignUpAction.fetchcreatedPropertiesData({}));
          }}
          mesage={getPropertyData?.data?.message}
          footer={false}
        />
      )}

      {getObjLength(lennarPropertiesDetails) ? (
        !lennarPropertiesDetails?.success ? (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(lennarSignUpAction.fetchcreatedPropertiesData({}));
              dispatch(userSigupAction.selectedUserPropertyData({}));
            }}
            mesage={lennarPropertiesDetails?.data?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={redirectToOwnerScreen}
            mesage={lennarPropertiesDetails?.message}
            footer={false}
          />
        )
      ) : null}

      <Loader open={propertyLoader || lennarLoader || signupLoader} />
    </>
  );
};

export default ProviderAddProperty;
