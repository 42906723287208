import { put, call, takeEvery } from "redux-saga/effects";
import { propertyListAction } from "../../../slices/admin/admin-dashboard/propertyListSlice";
import { postAPIPayload } from "../../../../apis/postApi";
import { getAPIPayload } from "../../../../apis/getApi";

export function* fetchAllPropertiesSaga({ payload }) {
  console.log("In sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("All properties response => ", response);
  yield put(propertyListAction.fetchPropertyListData(response));
}

export function* fetchPropertyDocumentSaga({ payload }) {
  console.log("In fetching property document -->", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("Request Property Document response", response);
  yield put(propertyListAction.fetchRequestPropertyDocument(response));
}

export function* fetchUploadedPropertyDocuments({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // Set document data in redux
  yield put(propertyListAction.fetchUploadedPropertyDocuments(response));
}
export function* fetchReplacedPropertyDocuments({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // Set document data in redux
  yield put(propertyListAction.fetchReplacedPropertyDocuments(response));
}
export function* fetchUploadedPropertyGeneralDocumentsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // Set document data in redux
  yield put(propertyListAction.fetchUploadedPropertyGeneralDocuments(response));
}
export function* bindProfessionalsToPropertySaga({ payload }) {
  console.log("bind payload", payload);
  const response = yield call(postAPIPayload, payload);

  // set datta to store
  yield put(propertyListAction.setBindProfessionalDetails(response));
}

export function* verifyAddressSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set datta to store
  yield put(propertyListAction.setVerifyAddressDetails(response));
}

export function* getAdminViewPropertyDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set datta to store
  yield put(propertyListAction.setAdminViewPropertyDetails(response));
}

export function* cancelSubscriptionSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyListAction.setCanceledSubscription(response));
}

export function* getChangeSubscriptionPlanListSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertyListAction.setChangeSubscriptionPlanList(response));
}

export function* changeSubscriptionPlanSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyListAction.setChangedSubscriptionPlan(response));
}

export function* cancelChangeSubscriptionRequestSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertyListAction.setCancelChangeSubscriptionRequest(response));
}

export function* sendRefundRecordingFeesSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyListAction.setRefundRecordingFeesDetails(response));
}

export function* resendPostcardSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);
  yield put(propertyListAction.setResendPostcardDetails(response));
}

export function* updateMailingAddressSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyListAction.fetchUpdateMailingAddressDetails(response));
}

export function* watchPropertyListsSagaAsync() {
  yield takeEvery(
    propertyListAction.getPropertyListData,
    fetchAllPropertiesSaga
  );
  yield takeEvery(
    propertyListAction.getRequestPropertyDocument,
    fetchPropertyDocumentSaga
  );
  yield takeEvery(
    propertyListAction.getUploadedPropertyDocuments,
    fetchUploadedPropertyDocuments
  );
  yield takeEvery(
    propertyListAction.getReplacedPropertyDocuments,
    fetchReplacedPropertyDocuments
  );
  yield takeEvery(
    propertyListAction.getUploadedPropertyGeneralDocuments,
    fetchUploadedPropertyGeneralDocumentsSaga
  );
  yield takeEvery(
    propertyListAction.bindProfessionalsToProperty,
    bindProfessionalsToPropertySaga
  );
  yield takeEvery(
    propertyListAction.getAdminViewPropertyDetails,
    getAdminViewPropertyDetailsSaga
  );
  yield takeEvery(propertyListAction.verifyAddress, verifyAddressSaga);
  yield takeEvery(
    propertyListAction.cancelSubscription,
    cancelSubscriptionSaga
  );
  yield takeEvery(
    propertyListAction.getChangeSubscriptionPlanList,
    getChangeSubscriptionPlanListSaga
  );
  yield takeEvery(
    propertyListAction.changeSubscriptionPlan,
    changeSubscriptionPlanSaga
  );
  yield takeEvery(
    propertyListAction.cancelChangeSubscriptionRequest,
    cancelChangeSubscriptionRequestSaga
  );
  yield takeEvery(
    propertyListAction.sendRefundRecordingFees,
    sendRefundRecordingFeesSaga
  );
  yield takeEvery(propertyListAction.resendPostcard, resendPostcardSaga);
  yield takeEvery(
    propertyListAction.getUpdateMailingAddress,
    updateMailingAddressSaga
  );
}
