import * as React from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import AddPromocodeModal from "./AddPromocodeModal";
import PromocodeListTable from "./PromocodeListTable";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { promocodeAction } from "../../../redux/slices/admin/promocodes/promocodesSlice";

import "../AdminDashboard/propertyTable.scss";
import { getObjLength } from "../../helpers/utils";
import { useEffect } from "react";

const Promocode = () => {
  const dispatch = useDispatch();
  const [isAddPromocodeModalOpen, setIsAddPromocodeModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { addPromocodeDetails } = useSelector((state) => state.promocodes);

  // handle addPromocode modal close
  const handleClose = () => {
    //for make the tab 1
    dispatch(userProfileAction.setDefaultSelectedTab(1));
    setIsAddPromocodeModalOpen(false);
  };

  // handle edit promocode in add promocode modal
  const handleEditPromocode = (item) => {
    setSelectedItem(item);
    setIsAddPromocodeModalOpen(true);
    //for set default tab
    dispatch(
      userProfileAction.setDefaultSelectedTab(item?.is_individual ? 2 : 1)
    );
  };

  //   handle add/edit save profesional
  const handleSubmitPromocodeForm = (values) => {
    // return console.log("values", values);
    if (selectedItem && selectedItem?.id) {
      // handle edit dispatch
      dispatch(
        promocodeAction?.addPromocodeDetails({
          url: `promocodes/${selectedItem?.id}/edit`,
          data: values,
          token: true,
        })
      );
    } else {
      // handle add dispatch
      dispatch(
        promocodeAction?.addPromocodeDetails({
          url: `promocodes/create`,
          data: values,
          token: true,
        })
      );
    }
  };

  // const getPromocodeTypes = useCallback(() => {
  //   dispatch(
  //     promocodeAction.fetchPromocodeTypes({
  //       url: `promocodes/types`,
  //       data: {},
  //       token: true,
  //     })
  //   );
  // }, [dispatch]);

  // useEffect(() => {
  //   getPromocodeTypes();
  // }, [getPromocodeTypes]);

  useEffect(() => {
    if (getObjLength(addPromocodeDetails)) {
      if (addPromocodeDetails?.success) {
        setIsAddPromocodeModalOpen(false);
      }
    }
  }, [dispatch, addPromocodeDetails]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h6" className="title">
          Promo Codes
        </Typography>
        <Button
          variant="contained"
          className="success-btn admin_buttons d-none d-sm-block"
          onClick={() => {
            setSelectedItem(null);
            setIsAddPromocodeModalOpen(true);
          }}
          style={{
            borderRadius: "20px",
          }}
        >
          Add Promocode
        </Button>
        <Button
          className="success-btn admin_buttons d-flex d-sm-none"
          variant="outlined"
          onClick={() => {
            setSelectedItem(null);
            setIsAddPromocodeModalOpen(true);
          }}
          startIcon={<AddOutlinedIcon />}
          sx={{ color: "#FFF" }}
        >
          Add
        </Button>
      </Box>
      <Box>
        <PromocodeListTable
          handleEditButton={handleEditPromocode}
          handleClose={handleClose}
        />
      </Box>
      <AddPromocodeModal
        open={isAddPromocodeModalOpen}
        handleClose={handleClose}
        selectedItem={selectedItem}
        onSubmit={handleSubmitPromocodeForm}
      />
    </>
  );
};

export default Promocode;
