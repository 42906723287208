import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { Box, Grid, Typography } from "@mui/material";

import NewMap from "../NewMap";

const GeneralComp = () => {
  // store data
  const { data: propertyData } = useSelector(
    (state) => state?.propertyLists?.viewPropertyDetails
  );
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container>
            <Grid item xs={12} sm={7} md={7.5} lg={7.5} mb={3}>
              <Row style={{ marginBottom: "16px" }}>
                <Col className="label-text">
                  <Typography>Subscription: </Typography>
                </Col>
                <Col className="value-text">
                  <Typography className="text-success">
                    {propertyData?.subscription?.id
                      ? `${propertyData?.subscription?.start_at} -
                        ${propertyData?.subscription?.end_at}`
                      : "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Owner: </Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.OwnerInfo?.[
                      "Owner Name"
                    ]?.trim() || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Type: </Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.GeneralInfo?.[
                      "Property Type"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>County: </Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.LocationInfo?.["County"] ||
                      "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Year Built: </Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.GeneralInfo?.[
                      "Year Built"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
            </Grid>
            <Grid item></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mb={2}>
              <Row>
                <Col className="label-text">
                  <Typography>Full Bedroom(s): </Typography>
                </Col>
                <Col className="value-text">
                  <Typography className="text-success">
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Full Bedroom(s)"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Full Bathroom(s):</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Full Bathroom(s)"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Partial Bathroom(s):</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Partial Bathroom(s)"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Total Rooms:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Total Rooms"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Stories:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Stories"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Garage:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.["Garage"] ||
                      "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Pool:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.["Pool"] ||
                      "-"}
                  </Typography>
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Row>
                <Col className="label-text">
                  <Typography>Structure Sqft: </Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Structure SqFt"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Ground Floor Saft:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Ground Floor Sqft"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Basement Sqft:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Basement Sqft"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Lot Size:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Lot Size"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Units:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.["Units"] ||
                      "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Fireplace:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Fireplace"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="label-text">
                  <Typography>Heating / AC:</Typography>
                </Col>
                <Col className="value-text">
                  <Typography>
                    {propertyData?.property_details?.PropertyInfo?.[
                      "Heating/AC"
                    ] || "-"}
                  </Typography>
                </Col>
              </Row>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box className="property-image modal-map">
            <NewMap
              className="map-style"
              center={{
                lat: propertyData?.latitude,
                lng: propertyData?.longitude,
              }}
              zoom={15}
              coordinates={[]}
              streetView={false}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralComp;
