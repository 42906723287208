import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { subscriptionAction } from "../../slices/subscription/subscriptionSlice";
import { userSigupAction } from "../../slices/user/userSignUpSlice";

export function* fetchSubscriptionSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fethSubscriptionDataDoc(response));
}

export function* fetchSubscriptionRenewedSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(getAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fethSubscriptionRenewedData(response));
}

export function* fetch3dSecurePaymentDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fetch3dSecurePaymentData(response));
}

export function* fetchBulkPlanRenewalDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fetchBulkPlanRenewalData(response));
}

export function* fetchRetryPaymentDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fetchRetryPaymentData(response));
}

export function* fetchTotalAmountDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fetchTotalAmountData(response));
  yield put(userSigupAction.fetchTotalPaymentDetails(response));
}

export function* fetchFailedPaymentDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fetchFailedPaymentData(response));
}

export function* fetchResumeSubscriptionDataSaga({ payload }) {
  console.log("in sagas--", payload);
  const response = yield call(postAPIPayload, payload);
  console.log("response==>.", response);
  yield put(subscriptionAction.fetchResumeSubscriptionData(response));
}

export function* watchSubscriptionSagaAsync() {
  yield takeLatest(
    subscriptionAction.getSubscriptionData,
    fetchSubscriptionSaga
  );
  yield takeLatest(
    subscriptionAction.getSubscriptionRenewed,
    fetchSubscriptionRenewedSaga
  );
  yield takeLatest(
    subscriptionAction.get3dSecurePaymentData,
    fetch3dSecurePaymentDataSaga
  );
  yield takeLatest(
    subscriptionAction.getBulkPlanRenewalData,
    fetchBulkPlanRenewalDataSaga
  );
  yield takeLatest(
    subscriptionAction.getRetryPaymentData,
    fetchRetryPaymentDataSaga
  );
  yield takeLatest(
    subscriptionAction.getTotalAmountData,
    fetchTotalAmountDataSaga
  );
  yield takeLatest(
    subscriptionAction.getFailedPaymentData,
    fetchFailedPaymentDataSaga
  );
  yield takeLatest(
    subscriptionAction.getResumeSubscriptionData,
    fetchResumeSubscriptionDataSaga
  );
}
