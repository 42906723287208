import { createSlice } from "@reduxjs/toolkit";

const intialLeadsState = {
  leadPageLoader: false,
  addLeadsDetails: {},
};

const tryLead = createSlice({
  name: "TryLeadPageDetails",
  initialState: intialLeadsState,
  reducers: {
    addLead: (state, action) => {
      state.leadPageLoader = true;
    },
    setAddLeadDetails: (state, action) => {
      state.addLeadsDetails = action.payload;
      state.leadPageLoader = false;
    },
    clearData: (state, action) => {
      state.addLeadsDetails = {};
      state.leadPageLoader = false;
    },
  },
});
export const tryPageLeadsAction = tryLead.actions;
export default tryLead.reducer;
