import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&.MuiInputBase-root .MuiInputBase-input": {
      boxShadow: "none",
      paddingBottom: "5px",
    },
    "& fieldset": {
      borderColor: "#949494",
      borderRadius: "0px",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      display: "flex",
      alignItems: "center",
    },
    "&:hover fieldset": {
      borderColor: "#949494",
      borderRadius: "0px",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1976D2 !important",
      borderRadius: "0px",
      border: "2px solid",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
    },
  },
}));

function StripeCardElement(props) {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  return <CardElement {...props} options={CARD_ELEMENT_OPTIONS} />;
}

const StanderedStripeCardElement = (props) => {
  return (
    <StyledTextField
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent: StripeCardElement,
      }}
      {...props}
    />
  );
};

export default StanderedStripeCardElement;
