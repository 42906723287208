import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useEffect } from "react";
import { Loader } from "../../common/loader";
import { useDispatch, useSelector } from "react-redux";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";
import {
  getAddressFromGoogleAutoComplete,
  getObjLength,
} from "../../helpers/utils";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { adminAction } from "../../../redux/slices/admin/admins/adminSlice";
import { PER_PAGE } from "../../helpers/constants";
import InternalAdminForm from "./InternalAdminForm";
import CustomTabComponent from "../../common/CustomTabComponent";
import CountyAdminForm from "./CountyAdminForm";

const FieldStyle = {
  borderRadius: "3px",
};

const AddAdminModal = ({ open, selectedItem, handleClose, onSubmit }) => {
  const dispatch = useDispatch();

  const {
    adminListLoader,
    adminListData,
    adminListDataCount,
    addAdminDetails,
    addCountyAdminDetails,
  } = useSelector((state) => state?.admins);

  const onSuccess = () => {
    if (adminListData?.data?.length === 1 && adminListDataCount > PER_PAGE) {
      dispatch(
        adminAction.fetchAdminListData({
          url: "admin",
          data: {},
          token: true,
        })
      );
      // setPage(page - 1);
    } else {
      dispatch(
        adminAction.fetchAdminListData({
          url: "admin",
          data: {},
          token: true,
        })
      );
    }
  };

  // clear add/edit/delete professional's existing data
  useEffect(() => {
    if (open) {
      dispatch(userSigupAction.getState({ url: "states", data: {} }));
      dispatch(professionalAction.clearAddProfessionalDetailsData());
    }
  }, [dispatch, open]);

  const tabContent = [
    {
      label: "Internal Admin",
      value: 1,
      component: (
        <InternalAdminForm
          onSubmit={onSubmit}
          selectedItem={selectedItem}
          handleClose={handleClose}
        />
      ),
    },
    {
      label: "County Admin",
      value: 2,
      component: (
        <CountyAdminForm
          onSubmit={onSubmit}
          selectedItem={selectedItem}
          handleClose={handleClose}
        />
      ),
    },
  ];
  console.log("selectedItem==>", selectedItem);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={
          !getObjLength(addAdminDetails) && !getObjLength(addCountyAdminDetails)
            ? "md"
            : "sm"
        }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!getObjLength(addAdminDetails) &&
          !getObjLength(addCountyAdminDetails) && (
            <>
              <DialogTitle id="alert-dialog-title">
                {selectedItem
                  ? selectedItem?.county_admin_id
                    ? "Edit County Admin"
                    : "Edit Internal Admin"
                  : "Add Admin"}
                <Button
                  onClick={() => handleClose()}
                  className="modal-close-btn"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                    background: "transparent",
                  }}
                >
                  <CloseIcon sx={{ color: "#9CB8A6" }} />
                </Button>
              </DialogTitle>
              <DialogContent dividers>
                <div className=" bg-white">
                  <CustomTabComponent
                    tabContent={tabContent}
                    hideTab={selectedItem ? true : false}
                    margin="0"
                    mClass="m-0"
                    minHeight="auto"
                    paddingTop="pt-4"
                  />
                  {/* {getObjLength(addPromocodeDetails) && !addPromocodeDetails?.success && (
              <p className="text-danger text-center p-2">{addPromocodeDetails?.data?.message}</p>
            )} */}
                </div>
              </DialogContent>
            </>
          )}

        <Loader open={open && adminListLoader} />

        {/* internal admin alerts */}
        {getObjLength(addAdminDetails) ? (
          addAdminDetails?.success ? (
            <PlainAlertMessage
              type="Success"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                onSuccess();
                handleClose();
                dispatch(adminAction.clearAddAdminDetailsData());
              }}
              message={addAdminDetails?.message}
              footer={false}
            />
          ) : (
            <PlainAlertMessage
              type="Error"
              openPopup={true}
              onClose={true}
              onCloseFun={() => {
                // setAlertMsg(false);
                dispatch(adminAction.clearAddAdminDetailsData());
              }}
              message={addAdminDetails?.data?.message}
              footer={false}
            />
          )
        ) : null}

        {/* county admin alerts */}
        {getObjLength(addCountyAdminDetails) ? (
          //  addCountyAdminDetails?.success ?
          <PlainAlertMessage
            type={addCountyAdminDetails?.success ? "Success" : "Error"}
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              if (addCountyAdminDetails?.success) {
                onSuccess();
                handleClose();
              }
              dispatch(adminAction.clearAddAdminDetailsData());
            }}
            message={
              addCountyAdminDetails?.message ||
              addCountyAdminDetails?.data?.message
            }
            footer={false}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default AddAdminModal;
