import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import { Loader } from "../../common/loader";

const FieldStyle = {
  borderRadius: "3px",
};

const AddPromoCode = ({ open, handleClose, onSubmit }) => {
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Promocode
          <Button
            onClick={() => handleClose()}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <Box className="d-flex justify-content-center bg-white">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{ promo_code: "" }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {({ values, setFieldValue, touched, errors, initialValues }) => {
                return (
                  <Form className="beneficiary-form w-100">
                    <DialogContent className="bg-white">
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        className="bg-white"
                      >
                        <Grid xs={12} md={12} px={1} pb={1}>
                          <TextField
                            className="sign-textField "
                            label="Promo Code"
                            name="promo_code"
                            value={values?.promo_code}
                            variant="outlined"
                            sx={FieldStyle}
                            InputLabelProps={{
                              style: {
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#00000075",
                              },
                              shrink: true,
                            }}
                            placeholder={"Promo Code"}
                            onChange={(event) => {
                              setFieldValue("promo_code", event.target.value);
                            }}
                            // helperText={touched.promo_code && errors.promo_code}
                            // error={touched.promo_code && Boolean(errors.promo_code)}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>

                    <>
                      <Stack
                        direction="row"
                        pb={2}
                        justifyContent="flex-end"
                        spacing={1}
                      >
                        <Button
                          className="popup_Btn other_popup_btn"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="popup_Btn success_popup_btn"
                          type="submit"
                          disabled={
                            JSON.stringify(initialValues) ===
                            JSON.stringify(values)
                          }
                          variant="contained"
                        >
                          submit
                        </Button>
                      </Stack>
                    </>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
        {/* <Loader open={promocodeListLoader} /> */}
      </Dialog>
    </>
  );
};

export default AddPromoCode;
