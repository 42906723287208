import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { Loader } from "../../common/loader";

import PlainAlertMessage from "../../common/PlainAlertMessage";

const FieldStyle = {
  borderRadius: "3px",
};

const EditBeneficiaryModal = ({
  open,
  selectedItem,
  handleClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { addBeneficiaryDetails, beneficiaryLoader } = useSelector(
    (state) => state.userProfile
  );

  useEffect(() => {
    if (open) {
      dispatch(userProfileAction.clearBeneficiaryData());
    }
  }, [dispatch, open]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!addBeneficiaryDetails?.data && (
          <DialogTitle id="alert-dialog-title">
            {selectedItem ? "Edit Beneficiary" : "Add Beneficiary"}
            <Button
              onClick={() => handleClose()}
              className="modal-close-btn"
              style={{
                position: "absolute",
                right: 10,
                top: 15,
                background: "transparent",
              }}
            >
              <CloseIcon sx={{ color: "#9CB8A6" }} />
            </Button>
          </DialogTitle>
        )}
        <Box className="d-flex justify-content-center bg-white">
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={{
              first_name: selectedItem?.first_name || "",
              last_name: selectedItem?.last_name || "",
              relation: selectedItem?.relation || "",
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string("Please enter your First Name.")
                .trim(
                  "The First name cannot include leading and trailing spaces"
                )
                .strict(true)
                .required("First Name is required"),
              last_name: Yup.string("Please enter your Last name.")
                .trim(
                  "The Last name cannot include leading and trailing spaces"
                )
                .strict(true)
                .required("Last Name is required"),
              relation: Yup.string("Please enter your Relation.")
                .trim("The Relation cannot include leading and trailing spaces")
                .strict(true)
                .required("Relation is required"),
            })}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              resetForm,
              initialValues,
            }) => {
              return (
                <Form className="beneficiary-form w-100">
                  {!addBeneficiaryDetails?.data && (
                    <DialogContent className="bg-white">
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        className="bg-white"
                      >
                        <>
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="First name"
                              name="first_name"
                              value={values?.first_name}
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              onChange={(event) => {
                                setFieldValue("first_name", event.target.value);
                              }}
                              helperText={
                                touched.first_name && errors.first_name
                              }
                              error={
                                touched.first_name && Boolean(errors.first_name)
                              }
                            />
                          </Grid>
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Last name"
                              name="last_name"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.last_name}
                              onChange={(event) => {
                                setFieldValue("last_name", event.target.value);
                              }}
                              helperText={touched.last_name && errors.last_name}
                              error={
                                touched.last_name && Boolean(errors.last_name)
                              }
                            />
                          </Grid>
                          <Grid xs={12} md={6} px={1} pb={3}>
                            <TextField
                              className="sign-textField "
                              label="Relation"
                              name="relation"
                              variant="outlined"
                              sx={FieldStyle}
                              InputLabelProps={{
                                style: {
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#00000075",
                                },
                              }}
                              value={values?.relation}
                              onChange={(event) => {
                                setFieldValue("relation", event.target.value);
                              }}
                              helperText={touched.relation && errors.relation}
                              error={
                                touched.relation && Boolean(errors.relation)
                              }
                            />
                          </Grid>
                        </>
                      </Grid>
                    </DialogContent>
                  )}

                  <DialogActions>
                    <Stack direction="row" spacing={2} pb={2}>
                      {addBeneficiaryDetails?.data ? null : (
                        // <Button
                        //   variant="contained"
                        //   onClick={() => {
                        //     handleClose();
                        //   }}
                        // >
                        //   Close
                        // </Button>
                        <>
                          <Button
                            className="popup_Btn other_popup_btn m-1"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            disabled={initialValues === values}
                            className="popup_Btn success_popup_btn m-1"
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </Stack>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <>
          {addBeneficiaryDetails?.success ? (
            <PlainAlertMessage
              type="Success"
              onClose={true}
              onCloseFun={() => {
                handleClose();
              }}
              message={addBeneficiaryDetails?.message}
              buttons={
                <Button
                  className="popup_Btn success_popup_btn"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </Button>
              }
            />
          ) : (
            addBeneficiaryDetails?.data && (
              <PlainAlertMessage
                type="Error"
                onClose={true}
                onCloseFun={() => {
                  handleClose();
                }}
                message={addBeneficiaryDetails?.data?.message}
                buttons={
                  <Button
                    className="popup_Btn error_popup_btn my-3"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                }
              />
            )
          )}
        </>
        <Loader open={beneficiaryLoader} />
      </Dialog>
    </>
  );
};

export default EditBeneficiaryModal;
