import { useRef } from "react";
import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Cropper } from "react-cropper";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
} from "@mui/material";

import { Loader } from "../../common/loader";
import FileUpload from "../../common/FileUpload";
import { getObjLength, roundOfNumIntrFloat } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import CustomTextField from "../../common/CustomTextField";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import CustomizedCheckbox from "../../common/customDesignedComponents/customCheckbox";
import { otherProvidersAction } from "../../../redux/slices/admin/other-providers/otherProvidersSlice";
import { SERVER_IP } from "../../helpers/constants";

export const closeDeleteIconStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  cursor: "pointer",
};

const fieldsValidations = (plans) => {
  return Yup.object()
    .shape({
      first_name: plans?.personalDetails
        ? Yup.string("Please enter your First Name.")
            .trim("The First name cannot include leading and trailing spaces")
            .strict(true)
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("First Name is required")
        : Yup.string(),
      last_name: plans?.personalDetails
        ? Yup.string("Please enter your Last name.")
            .trim("The Last name cannot include leading and trailing spaces")
            .strict(true)
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Last Name is required")
        : Yup.string(),
      email: plans?.personalDetails
        ? Yup.string("Please enter your Email Id.")
            .email("Invalid email format")
            .max(255)
            .required("Email id is required")
        : Yup.string(),
      phone: plans?.personalDetails
        ? Yup.string("Please enter your Phone number.")
            .typeError("That doesn't look like a phone number")
            // .positive()
            .required("Mobile number is required")
        : Yup.string(),
      domain: plans?.personalDetails
        ? Yup.string("Please enter your Domain name.")
            .max(25)
            .required("Domain name is required")
            .matches(
              /^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/,
              "Domain name cannot contain space or other special characters in between. Example: abc-efg or abc_efg."
            )
        : Yup.string(),
      redirect_url: plans?.personalDetails
        ? Yup.string()
            // .max(25)
            // .required("Redirection URL is required")
            .matches(
              /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}\//,
              "Invalid URL pattern"
            )
        : Yup.string(),
      webhook_url: plans?.personalDetails
        ? Yup.string()
            // .max(25)
            // .required("Redirection URL is required")
            .matches(
              /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}\//,
              "Invalid URL pattern"
            )
        : Yup.string(),
      logo: plans?.personalDetails
        ? Yup.string().required("Please upload Company logo.")
        : Yup.string(),

      // Monthly plan fields validations
      monthly_plan_price:
        plans?.plansDetails && plans?.monthlyPlan
          ? Yup.number("Please enter your Monthly Setup amount.")
              // .trim(
              //   "The Monthly Setup amount cannot include leading and trailing spaces"
              // )
              // .strict(true)
              .max(1000, "Too Long!")
              .required("Monthly Setup amount is required")
              .test(
                "not-allowed-chars",
                "Please enter postive amount for Monthly Subscription",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
          : Yup.number(),

      monthly_renewal_price:
        plans?.plansDetails && plans?.monthlyPlan
          ? Yup.number("Please enter your Monthly Plan Renewal amount.")
              // .trim(
              //   "The Monthly Plan Renewal amount cannot include leading and trailing spaces"
              // )
              // .strict(true)
              .max(1000, "Too Long!")
              .required("Monthly Plan Renewal amount is required")
              .test(
                "not-allowed-chars",
                "Please enter postive Monthly Plan Renewal amount",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
          : Yup.number(),

      monthly_free_trial:
        plans?.plansDetails && plans?.monthlyPlan
          ? Yup.number()
              .required("Free trial period is required")
              .integer(
                "Please note that the Free Trial period cannot be combined with decimal numbers."
              )
              .max(365, "Free trial period cannot exceed 365 days")
              .test(
                "not-allowed-chars",
                "Please enter postive numbers for Free trial period",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1 &&
                      value === Math.floor(value))
                    // Math.round(value) === value)
                  );
                }
              )
          : Yup.number(),

      setup_fees:
        plans?.plansDetails && plans?.monthlyFees
          ? Yup.number("Please enter your Setup fees.")
              // .trim("The Setup fees cannot include leading and trailing spaces")
              // .strict(true)
              .max(1000, "Too Long!")
              .required("Setup fees is required")
              .test(
                "not-allowed-chars",
                "Please enter postive amount for Setup fees",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
          : Yup.number(),

      // Yearly plan fields validations
      yearly_plan_price:
        plans?.plansDetails && plans?.yearlyPlan
          ? Yup.number("Please enter your Yearly Setup amount.")
              // .trim(
              //   "The Yearly Setup amount cannot include leading and trailing spaces"
              // )
              // .strict(true)
              .max(1000, "Too Long!")
              .required("Yearly Setup amount is required")
              .test(
                "not-allowed-chars",
                "Please enter postive amount for Yearly Subscription",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
          : Yup.number(),

      yearly_renewal_price:
        plans?.plansDetails && plans?.yearlyPlan
          ? Yup.number("Please enter your Yearly Plan Renewal amount.")
              // .trim(
              //   "The Yearly Plan Renewal amount cannot include leading and trailing spaces"
              // )
              // .strict(true)
              .max(1000, "Too Long!")
              .required("Yearly Plan Renewal amount is required")
              .test(
                "not-allowed-chars",
                "Please enter postive Yearly Plan Renewal amount",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
          : Yup.number(),

      yearly_free_trial:
        plans?.plansDetails && plans?.yearlyPlan
          ? Yup.number()
              .required("Free trial period is required")
              .integer(
                "Please note that the Free Trial period cannot be combined with decimal numbers."
              )
              .max(365, "Free trial period cannot exceed 365 days")
              .test(
                "not-allowed-chars",
                "Please enter postive numbers for Free trial period",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1 &&
                      value === Math.floor(value))
                  );
                }
              )
          : Yup.number(),

      recording_fees:
        plans?.plansDetails && plans?.recordingFees
          ? Yup.number("Please enter your Recording  fees.")
              .max(1000, "Too Long!")
              .test(
                "not-allowed-chars",
                "Please enter postive amount for Recording fees",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
              .required("Recording  fees is required")
          : Yup.number(),
    })
    .test("at-least-one", null, function (value) {
      const { webhook_url, redirect_url } = value;

      if (!webhook_url && !redirect_url) {
        return this.createError({
          path: "redirect_url", // You can choose any field to attach the error
          message:
            "At least one of Webhook URL or Redirection URL is required.",
        });
      }

      return true;
    });
};
const CreateProvidersForm = ({
  goBack = () => {},
  listingAPI,
  personalDetails = true,
  plansDetails = true,
  selectedData = {},
  plansData = {},
  closeDialogBox = () => {},
  isEdit = false,
}) => {
  const dispatch = useDispatch();
  const signUpFormRef = useRef();
  const cropperRef = useRef();

  const [openPopup, setOpenPopup] = useState(false);
  const [benutechPropertyChecked, setBenutechPropertyChecked] = useState(false);
  const [isCustomerEP_Checked, setIsCustomerEP_Checked] = useState(false);
  const [isSSO, setIsSSO] = useState(false);
  const [monthlyPlan, setMonthlyPlan] = useState(true);
  const [monthlyFees, setMonthlyFees] = useState(true);
  const [yearlyPlan, setYearlyPlan] = useState(true);
  const [yearlyFees, setYearlyFees] = useState(true);
  const [recordingFees, setRecordingFees] = useState(true);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showLogoError, setShowLogoError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [sendPostcard, setSendPostcard] = useState(false);
  const [isCustomDomain, setIsCustomDomain] = useState(false);

  const {
    otherProvidersLoader,
    createOtherProviders,
    editedPersonalDetailsData,
  } = useSelector((state) => state.otherProviders);

  const handleSubmit = async (
    e,
    validateForm,
    setFieldTouched,
    values
  ) => {
    e.preventDefault();
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      const errorField = Object.keys(errors)[0];
      Object.keys(errors).forEach((field) => setFieldTouched(field, true));
      document.getElementsByName(errorField)[0].focus();
    } else {
      if (!isValidPhoneNumber(values?.phone)) {
        document.getElementsByName("phone")[0].focus();
        return;
      }
      if (!monthlyPlan && !yearlyPlan) {
        setShowLogoError(true);
        return;
      } else {
        setShowLogoError(false);
      }
      onSubmit(values);
    }
  };

  const onSubmit = (values, errors) => {
    console.log("values=>", values);
    const formData = new FormData();
    let monthly_plans = {};
    let monthly_renewal_plans = {};
    let yearly_plans = {};
    let yearly_renewal_plans = {};

    if (monthlyPlan) {
      monthly_plans = {
        name: "monthly",
        amount: values?.monthly_plan_price,
        additional_charges: {
          "setup fees": values?.setup_fees,
          recording_fees: values.recording_fees,
        },
        is_default: 1,
        trial_period: {
          day: parseInt(values?.monthly_free_trial),
        },
      };
      monthly_renewal_plans = {
        name: "monthly",
        amount: values?.monthly_renewal_price,
        is_default: 0,
        additional_charges: {
          // "setup fees": values?.setup_fees,
          // recording_fees: values.recording_fees,
        },
        trial_period: {},
      };
    } else {
      monthly_plans = null;
      monthly_renewal_plans = null;
    }

    if (yearlyPlan) {
      yearly_plans = {
        name: "yearly",
        amount: values?.yearly_plan_price,
        additional_charges: {
          "setup fees": values?.setup_fees,
          recording_fees: values.recording_fees,
        },
        is_default: 1,
        trial_period: {
          day: parseInt(values?.yearly_free_trial),
        },
      };
      yearly_renewal_plans = {
        name: "yearly",
        amount: values?.yearly_renewal_price,
        is_default: 0,
        additional_charges: {
          // "setup fees": values?.setup_fees,
          // recording_fees: values.recording_fees,
        },
        trial_period: {},
      };
    } else {
      yearly_plans = null;
      yearly_renewal_plans = null;
    }

    let subscription_plans = [
      { ...monthly_plans },
      { ...monthly_renewal_plans },
      { ...yearly_plans },
      { ...yearly_renewal_plans },
    ];

    // for remove the empty objects
    const finalPlansList = subscription_plans.filter(
      (obj) => Object.keys(obj).length > 0
    );

    let data = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      phone: values?.phone,
      is_benutech_property: values?.is_benutech_property || 0,
      is_customer_ep: values?.is_customer_ep || 0,
      send_postcard: values?.send_postcard || 0,
      is_sso: values?.is_sso || 0,
      domain: !isCustomDomain ? values?.domain : undefined,
      custom_domain: isCustomDomain ? values?.domain : undefined,
      logo: values?.logo,
      redirect_url: values?.redirect_url || "",
      webhook_url: values?.webhook_url || "",
      // subscription_plans: finalPlansList,
    };

    if (personalDetails) {
      if (values?.logo === selectedData?.logo) {
        delete data?.logo;
      }

      if (isCustomDomain) {
        delete data?.domain;
      } else {
        delete data?.custom_domain;
      }
      // Append basic user data in form data
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      console.log("Data==>>", data);

      // for personal details
      if (personalDetails && !plansDetails) {
        dispatch(
          otherProvidersAction.editedPersonalDetailsData({
            url: `providers/${selectedData?.id}/update`,
            data: formData,
            isFile: true,
          })
        );
      }
    }

    if (plansDetails) {
      // Append plans array to FormData
      finalPlansList.forEach((plan, index) => {
        Object.keys(plan).forEach((key) => {
          // If it's an object, stringify it
          const value =
            typeof plan[key] === "object"
              ? JSON.stringify(plan[key])
              : plan[key];
          formData.append(`subscription_plans[${index}][${key}]`, value);
        });
      });

      // for edit plans Data
      if (!personalDetails && plansDetails) {
        dispatch(
          otherProvidersAction.editedPersonalDetailsData({
            url: `providers/${selectedData?.id}/update`,
            data: formData,
            isFile: true,
          })
        );
      }
    }

    if (personalDetails && plansDetails) {
      dispatch(
        otherProvidersAction.createOtherProvidersData({
          url: `providers/create`,
          data: formData,
          isFile: true,
        })
      );
    }
  };

  const onBenutechPropertyChecked = (e, checked, setFieldValue) => {
    setFieldValue("is_benutech_property", checked);

    if (!checked) {
      setBenutechPropertyChecked(false);
      setIsCustomerEP_Checked(false);
    } else {
      setBenutechPropertyChecked(true);
    }
  };

  const onCustomerEPChecked = (e, checked, setFieldValue) => {
    setFieldValue("is_customer_ep", checked);
    if (!checked) {
      setIsCustomerEP_Checked(false);
    } else {
      setIsCustomerEP_Checked(true);
    }
  };

  const onSendPostcardChecked = (e, checked, setFieldValue) => {
    setFieldValue("send_postcard", checked);
    if (!checked) {
      setSendPostcard(false);
    } else {
      setSendPostcard(true);
    }
  };

  const onSSOChecked = (e, checked, setFieldValue) => {
    setFieldValue("is_sso", checked);
    if (!checked) {
      setIsSSO(false);
    } else {
      setIsSSO(true);
    }
  };

  const isCustomDomainChecked = (e, checked, setFieldValue) => {
    setFieldValue("is_custom_domain", checked);
    if (!checked) {
      setIsCustomDomain(false);
    } else {
      setIsCustomDomain(true);
    }
  };

  const onMonthlyPlanChecked = (e, checked, setFieldValue) => {
    setFieldValue("monthly_plan_price", "");
    setFieldValue("monthly_renewal_price", "");
    setFieldValue("monthly_free_trial", "");
    setFieldValue("setup_fees", "");
    setMonthlyPlan((prevState) => !prevState);
    if (!checked) {
      setMonthlyFees(false);
    } else {
      setMonthlyFees(true);
    }
  };

  const onMonthlyFessChecked = (e, checked, setFieldValue) => {
    setMonthlyFees((prevState) => !prevState);
    setFieldValue("setup_fees", "");
  };

  const onYearlyPlanChecked = (e, checked, setFieldValue) => {
    setFieldValue("yearly_plan_price", "");
    setFieldValue("yearly_free_trial", "");
    setFieldValue("yearly_renewal_price", "");
    setYearlyPlan((prevState) => !prevState);
    // if (!checked) {
    //   setYearlyFees(false);
    // } else {
    //   setYearlyFees(true);
    // }
  };

  const onRecordingFeesChecked = (e, checked, setFieldValue) => {
    setFieldValue("recording_fees", "");
    if (!checked) {
      setRecordingFees(false);
    } else {
      setRecordingFees(true);
    }
  };

  const onYearlyFessChecked = (e, checked) => {
    setYearlyFees((prevState) => !prevState);
  };

  const validationSchema = fieldsValidations({
    monthlyPlan,
    monthlyFees,
    yearlyPlan,
    yearlyFees,
    recordingFees,
    personalDetails,
    plansDetails,
  });

  // when user select the image
  const onChange = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  // get cropped image
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      signUpFormRef?.current?.setFieldValue(
        "logo",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      console.log(
        "croppedImage: ",
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };

  const closeAlertPopup = () => {
    setOpenPopup(false);
    if (createOtherProviders?.success) {
      listingAPI();
      goBack();
    }
    if (editedPersonalDetailsData?.success) {
      listingAPI();
      closeDialogBox();
    }
    dispatch(otherProvidersAction.fetchCreatedProvidersData({}));
    dispatch(otherProvidersAction.fetchEditedPersonalDetailsData({}));
  };

  useEffect(() => {
    if (getObjLength(createOtherProviders)) {
      // if (createOtherProviders?.success) {
      setOpenPopup(true);
      // } else {
      //   setOpenPopup(true);
      // }
    }
  }, [createOtherProviders]);

  // for enable/disable the checkboxws for data update
  useEffect(() => {
    if (getObjLength(plansData)) {
      if (!plansData?.monthly_plans?.is_active) {
        setMonthlyFees(false);
        setMonthlyPlan(false);
      }
      if (!plansData?.monthly_plans?.setup_fees) {
        setMonthlyFees(false);
      }
      if (!plansData?.yearly_plans?.is_active) {
        setYearlyFees(false);
        setYearlyPlan(false);
      }

      if (!plansData?.recording_fees) {
        setRecordingFees(false);
      }
    }

    if (getObjLength(selectedData) && personalDetails) {
      if (selectedData?.is_benutech_property) {
        setBenutechPropertyChecked(true);
      }

      if (selectedData?.is_customer_ep) {
        setIsCustomerEP_Checked(true);
      }

      if (selectedData?.send_postcard) {
        setSendPostcard(true);
      }

      if (selectedData?.is_sso) {
        setIsSSO(true);
      }

      if (selectedData?.custom_domain) {
        setIsCustomDomain(true);
      }

      if (selectedData?.logo) {
        setCroppedImage(selectedData?.logo);
        signUpFormRef?.current?.setFieldValue("logo", selectedData?.logo);
      }
    }
  }, [personalDetails, plansData, selectedData]);

  return (
    <Box>
      <Formik
        enableReinitialize
        validateOnChange
        innerRef={signUpFormRef}
        initialValues={{
          first_name: selectedData?.first_name || "",
          last_name: selectedData?.last_name || "",
          email: selectedData?.email || "",
          phone_number: selectedData?.phone || "",
          phone: selectedData?.phone || "",
          domain: selectedData?.domain || selectedData?.custom_domain || "",
          monthly_plan_price:
            plansDetails && personalDetails
              ? ""
              : roundOfNumIntrFloat(
                  parseFloat(plansData?.monthly_plans?.amount || 0)
                  // +  parseFloat(plansData?.monthly_plans?.setup_fees || 0) // hide this as setup fees field is open
                ) || "",
          monthly_renewal_price:
            plansData?.monthly_plans?.monthly_renewal_plans || "",
          mpp: "",
          monthly_free_trial:
            plansData?.monthly_plans?.trial_period?.toString() || "",
          mft: "",
          setup_fees: plansData?.monthly_plans?.setup_fees || "",
          sf: "",
          yearly_renewal_price:
            plansData?.yearly_plans?.yearly_renewal_plans || "",
          yearly_plan_price: plansData?.yearly_plans?.amount || "",
          ypp: "",
          yearly_free_trial:
            plansData?.yearly_plans?.trial_period?.toString() || "",
          yft: "",
          recording_fees: plansData?.recording_fees || "",
          rf: "",
          logo: "",
          redirect_url: selectedData?.redirect_url || "",
          webhook_url: selectedData?.webhook_url || "",
          is_benutech_property: selectedData?.is_benutech_property || "",
          is_customer_ep: selectedData?.is_customer_ep || "",
          send_postcard: selectedData?.send_postcard || "",
          is_sso: selectedData?.is_sso || "",
          is_custom_domain: selectedData?.custom_domain || "",
        }}
        validationSchema={validationSchema}
        // onSubmit={(values, validation) => {
        //   console.log("entered");
        //   if (!isValidPhoneNumber(values?.phone)) {
        //     return;
        //   }
        //   if (!monthlyPlan && !yearlyPlan) {
        //     setShowLogoError(true);
        //     return;
        //   } else {
        //     setShowLogoError(false);
        //   }

        //   onSubmit(values);
        // }}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          validateForm,
          setFieldTouched,
        }) => {
          return (
            <Form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {personalDetails && (
                  <>
                    {" "}
                    <Grid xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="First Name"
                        placeholder="First Name"
                        name="first_name"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("first_name", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Last Name"
                        placeholder="Last Name"
                        name="last_name"
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("last_name", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        label="Email"
                        placeholder="Email"
                        name="email"
                        disabled={!plansDetails}
                        validation={{ values, setFieldValue, touched, errors }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("email", event.target.value);
                          },
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={1}>
                      <CustomPhoneNumberInput
                        name="phone"
                        values={values}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        disabled={!plansDetails}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("phone", event);
                            setFieldValue("phone_number", event);
                            if (!event) {
                              setFieldValue("phone", "");
                              setFieldValue("phone_number", "");
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={1}>
                      <OutlinedInput
                        className="textfield"
                        type={"text"}
                        placeholder="Webhook URL"
                        name="webhook_url"
                        sx={{ backgroundColor: "#f6f9fc" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm_password visibility"
                              edge="end"
                            >
                              <Tooltip
                                title="https://abcd.com/abc"
                                placement="bottom"
                                arrow
                              >
                                <Box
                                  onClick={() =>
                                    setToggleFilterOptions(!toggleFilterOptions)
                                  }
                                >
                                  <InfoIcon />
                                </Box>
                              </Tooltip>
                            </IconButton>
                          </InputAdornment>
                        }
                        value={values?.webhook_url}
                        fullWidth
                        onChange={(event) => {
                          setFieldValue("webhook_url", event.target.value);
                        }}
                        helperText={touched.webhook_url && errors.webhook_url}
                        error={
                          touched.webhook_url && Boolean(errors.webhook_url)
                        }
                      />
                      {touched.webhook_url && Boolean(errors.webhook_url) && (
                        <FormHelperText
                          sx={{
                            color: "rgb(211, 47, 47) !important",
                            fontSize: "12px !important",
                            m: "0 !important",
                            maxWidth: "100% !important",
                          }}
                        >
                          {touched.webhook_url && errors.webhook_url}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={1}>
                      <OutlinedInput
                        className="textfield"
                        type={"text"}
                        placeholder="Redirection URL"
                        name="redirect_url"
                        sx={{ backgroundColor: "#f6f9fc" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm_password visibility"
                              edge="end"
                            >
                              <Tooltip
                                title="https://abcd.com/abc"
                                placement="bottom"
                                arrow
                              >
                                <Box
                                  onClick={() =>
                                    setToggleFilterOptions(!toggleFilterOptions)
                                  }
                                >
                                  <InfoIcon />
                                </Box>
                              </Tooltip>
                            </IconButton>
                          </InputAdornment>
                        }
                        value={values?.redirect_url}
                        fullWidth
                        onChange={(event) => {
                          setFieldValue("redirect_url", event.target.value);
                        }}
                        helperText={touched.redirect_url && errors.redirect_url}
                        error={
                          touched.redirect_url && Boolean(errors.redirect_url)
                        }
                      />
                      {touched.redirect_url && Boolean(errors.redirect_url) && (
                        <FormHelperText
                          sx={{
                            color: "rgb(211, 47, 47) !important",
                            fontSize: "12px !important",
                            m: "0 !important",
                            maxWidth: "100% !important",
                          }}
                        >
                          {touched.redirect_url && errors.redirect_url}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid xs={12} md={12} px={1} pb={1}>
                      <Stack
                        direction="row"
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                        // alignItems="center"
                      >
                        <Grid xs={12} md={6}>
                          <Stack spacing={1} direction="column">
                            <>
                              <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={{ xs: 1, sm: 2, md: 13.5 }}
                                pt={3}
                              >
                                <span className="pe-4">Single Sign-On?</span>
                                <Stack
                                  direction="row"
                                  spacing={{ xs: 1, sm: 1, md: 2 }}
                                  alignItems="center"
                                >
                                  <div>
                                    <CustomizedCheckbox
                                      checked={isSSO}
                                      disabled={isSSO}
                                      onChecked={(e) =>
                                        onSSOChecked(e, 1, setFieldValue)
                                      }
                                    />
                                    <span>Enable</span>
                                  </div>
                                  <div>
                                    <CustomizedCheckbox
                                      checked={!isSSO}
                                      disabled={!isSSO}
                                      onChecked={(e) => {
                                        onSSOChecked(e, 0, setFieldValue);
                                      }}
                                    />
                                    <span>Disable</span>
                                  </div>
                                </Stack>
                              </Stack>
                            </>
                            <>
                              <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={{ xs: 1, sm: 2, md: 11 }}
                                pt={4}
                                alignItems={"center"}
                              >
                                <span className="">Property in benutech?</span>
                                <Stack
                                  direction="row"
                                  spacing={{ xs: 1, sm: 1, md: 2 }}
                                  alignItems="center"
                                >
                                  <div>
                                    <CustomizedCheckbox
                                      checked={benutechPropertyChecked}
                                      disabled={benutechPropertyChecked}
                                      onChecked={(e) =>
                                        onBenutechPropertyChecked(
                                          e,
                                          1,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <span>Enable</span>
                                  </div>
                                  <div>
                                    <CustomizedCheckbox
                                      checked={!benutechPropertyChecked}
                                      disabled={!benutechPropertyChecked}
                                      onChecked={(e) => {
                                        onBenutechPropertyChecked(
                                          e,
                                          0,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <span>Disable</span>
                                  </div>
                                </Stack>
                              </Stack>
                            </>
                            <>
                              <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={{ xs: 1, sm: 2, md: 8 }}
                                pt={4}
                                alignItems={"center"}
                              >
                                <span className="">
                                  Hosted by EquityProtect?
                                </span>
                                <Stack
                                  direction="row"
                                  spacing={{ xs: 1, sm: 1, md: 2 }}
                                  alignItems={"center"}
                                >
                                  <div
                                    className={`${
                                      !benutechPropertyChecked &&
                                      "cursor-disable"
                                    }`}
                                  >
                                    <CustomizedCheckbox
                                      checked={isCustomerEP_Checked}
                                      disabled={!benutechPropertyChecked}
                                      onChecked={(e) =>
                                        onCustomerEPChecked(e, 1, setFieldValue)
                                      }
                                    />
                                    <span>Enable</span>
                                  </div>
                                  <div
                                    className={`${
                                      !benutechPropertyChecked &&
                                      "cursor-disable"
                                    }`}
                                  >
                                    <CustomizedCheckbox
                                      checked={!isCustomerEP_Checked}
                                      disabled={!benutechPropertyChecked}
                                      onChecked={(e) => {
                                        onCustomerEPChecked(
                                          e,
                                          0,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                    <span>Disable</span>
                                  </div>
                                </Stack>
                              </Stack>
                            </>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {/* When cropper will crop the image then we will have data in the croppedImage state so code will transfer to else condition */}
                          {!croppedImage ? (
                            selectedFile ? (
                              <>
                                {/* When service provider selects the image then this cropper will be displayed */}
                                <Cropper
                                  ref={cropperRef}
                                  style={{ height: "280px", width: "100%" }}
                                  zoomTo={0}
                                  initialAspectRatio={1}
                                  src={selectedFile}
                                  viewMode={1}
                                  minCropBoxHeight={10}
                                  minCropBoxWidth={10}
                                  background={false}
                                  responsive={true}
                                  autoCropArea={1}
                                  checkOrientation={false}
                                  guides={false}
                                />
                                <Box
                                  className="d-flex justify-content-start"
                                  mt={2}
                                >
                                  <Button
                                    onClick={getCropData}
                                    variant="contained"
                                    className="success-btn"
                                    // color="primary"
                                  >
                                    Crop Logo
                                  </Button>
                                </Box>
                              </>
                            ) : (
                              <>
                                {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                                <span className="servicer-signup-form">
                                  <FileUpload
                                    name="upload_doc"
                                    accept={{
                                      "image/png": [".png", ".jpeg", ".jpg"],
                                    }}
                                    multiple={false}
                                    onFileUpload={async (file) => {
                                      onChange(file);
                                    }}
                                    dragDropText={`Drag and drop Logo or `}
                                    greenText="Upload a Logo"
                                    subText="Only .png, .jpeg, .jpg files are allowed"
                                  />
                                </span>
                              </>
                            )
                          ) : (
                            <>
                              {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                              <Box
                                className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                                sx={{
                                  backgroundColor: "#F6F9FC",
                                  border: croppedImage
                                    ? "1px solid #E2E8EB"
                                    : "",
                                }}
                              >
                                <img
                                  // height="100%"
                                  // width="100%"
                                  src={croppedImage}
                                  style={{
                                    minHeight: "150px",
                                    maxHeight: "230px",
                                  }}
                                  alt="cropped"
                                  id="croppedImage"
                                  className="rounded"
                                />

                                <IconButton
                                  sx={{
                                    ...closeDeleteIconStyle,
                                    color: "#FC2125",
                                    fontSize: "1.4rem",
                                  }}
                                  onClick={() => {
                                    setCroppedImage(null);
                                    setSelectedFile(null);
                                    signUpFormRef?.current?.setFieldValue(
                                      "logo",
                                      ""
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </>
                          )}
                          {/* //  {isSubmit && showLogoError === 0 && ( */}
                          {touched?.logo && Boolean(errors?.logo) && (
                            <FormHelperText
                              sx={{
                                color: "#d32f2f !important",
                                m: "0 !important",
                                fontSize: "12px !important",
                              }}
                            >
                              Please upload Company logo
                            </FormHelperText>
                          )}
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 13.5 }}
                        pt={3}
                      >
                        <span className="pe-4">Send Postcard?</span>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                        >
                          <div>
                            <CustomizedCheckbox
                              checked={sendPostcard}
                              disabled={sendPostcard}
                              onChecked={(e) =>
                                onSendPostcardChecked(e, 1, setFieldValue)
                              }
                            />
                            <span>Enable</span>
                          </div>
                          <div>
                            <CustomizedCheckbox
                              checked={!sendPostcard}
                              disabled={!sendPostcard}
                              onChecked={(e) => {
                                onSendPostcardChecked(e, 0, setFieldValue);
                              }}
                            />
                            <span>Disable</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={2}>
                      {/* <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 13.5 }}
                        pt={3}
                      >
                        <span className="">Single Sign-On?</span>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                        >
                          <div>
                            <CustomizedCheckbox
                              checked={isSSO}
                              disabled={isSSO}
                              onChecked={(e) =>
                                onSSOChecked(e, 1, setFieldValue)
                              }
                            />
                            <span>Enable</span>
                          </div>
                          <div>
                            <CustomizedCheckbox
                              checked={!isSSO}
                              disabled={!isSSO}
                              onChecked={(e) => {
                                onSSOChecked(e, 0, setFieldValue);
                              }}
                            />
                            <span>Disable</span>
                          </div>
                        </Stack>
                      </Stack> */}
                    </Grid>
                    <Grid xs={12} md={12} px={1} py={3}>
                      <Divider>
                        <span className="fw-600">Domain</span>
                      </Divider>
                    </Grid>
                    <Grid xs={12} md={12} px={1} pb={1}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 11 }}
                        py={2}
                        alignItems={"center"}
                      >
                        <b className="">Domain</b>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                        >
                          <div>
                            <CustomizedCheckbox
                              checked={!isCustomDomain}
                              disabled={!isCustomDomain}
                              onChecked={(e) =>
                                isCustomDomainChecked(e, 0, setFieldValue)
                              }
                            />
                            <span>Sub Domain</span>
                          </div>
                          <div>
                            <CustomizedCheckbox
                              checked={isCustomDomain}
                              disabled={isCustomDomain}
                              onChecked={(e) => {
                                isCustomDomainChecked(e, 1, setFieldValue);
                              }}
                            />
                            <span>Custom Domain</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid xs={12} md={6} px={1} pb={1} className="text-start">
                      <>
                        <CustomTextField
                          label="Domain Name"
                          placeholder="Domain Name"
                          name="domain"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue("domain", event.target.value);
                            },
                          }}
                        />
                        {isCustomDomain && (
                          <span style={{ fontSize: "14px" }}>
                            <b>Note:</b> For custom domain, you will need to ask
                            the customer to point the A-record of the domain to
                            our server's IP: [{SERVER_IP}]
                          </span>
                        )}
                      </>
                    </Grid>
                  </>
                )}

                {plansDetails && (
                  <>
                    {personalDetails && (
                      <Grid xs={12} md={12} px={1} py={3}>
                        <Divider>
                          <span className="fw-600">Subscription Plans</span>{" "}
                        </Divider>
                      </Grid>
                    )}

                    {/* Monthly Plan details */}
                    <>
                      <Grid xs={12} md={12} px={1} pb={2}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={{ xs: 1, sm: 2, md: 10 }}
                          pt={2}
                        >
                          <b className="">Monthly Plan</b>
                          <Stack
                            direction="row"
                            spacing={{ xs: 1, sm: 1, md: 2 }}
                            alignItems="center"
                          >
                            <div>
                              <CustomizedCheckbox
                                checked={monthlyPlan}
                                disabled={monthlyPlan}
                                onChecked={(e) =>
                                  onMonthlyPlanChecked(e, 1, setFieldValue)
                                }
                              />
                              <span>Enable</span>
                            </div>
                            <div>
                              <CustomizedCheckbox
                                checked={!monthlyPlan}
                                disabled={!monthlyPlan}
                                onChecked={(e) => {
                                  onMonthlyPlanChecked(e, 0, setFieldValue);
                                }}
                              />
                              <span>Disable</span>
                            </div>
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="Setup amount"
                          disabled={!monthlyPlan}
                          name={monthlyPlan ? "monthly_plan_price" : "mpp"}
                          type={"number"}
                          validation={
                            { values, setFieldValue, touched, errors }
                            // : {}
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span>$</span>
                              </InputAdornment>
                            ),
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "monthly_plan_price",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="Monthly Renewal amount"
                          disabled={!monthlyPlan}
                          name={monthlyPlan ? "monthly_renewal_price" : "mpp"}
                          type={"number"}
                          validation={
                            { values, setFieldValue, touched, errors }
                            // : {}
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span>$</span>
                              </InputAdornment>
                            ),
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "monthly_renewal_price",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          disabled={!monthlyPlan}
                          label="Free Trial"
                          name={monthlyPlan ? "monthly_free_trial" : "mft"}
                          type={"number"}
                          max={3}
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "monthly_free_trial",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                        {!(
                          touched?.monthly_free_trial &&
                          Boolean(errors?.monthly_free_trial)
                        ) && (
                          <FormHelperText
                            sx={{
                              color: "#00000075 !important",
                              m: "0 !important",
                              maxWidth: "100% !important",
                              textAlign: "end",
                            }}
                          >
                            (In days)
                          </FormHelperText>
                        )}
                      </Grid>

                      {/* Hide this field, uncomment when needed  */}
                      <Grid xs={12} md={12} px={1} pb={2}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={{ xs: 1, sm: 2, md: 2 }}
                          pt={2}
                        >
                          <b className="">Need to take setup fees?</b>
                          <Stack
                            direction="row"
                            spacing={{ xs: 1, sm: 1, md: 2 }}
                            alignItems="center"
                          >
                            <div
                              className={`${!monthlyPlan && "cursor-disable"}`}
                            >
                              <CustomizedCheckbox
                                disabled={!monthlyPlan || monthlyFees}
                                checked={monthlyFees}
                                onChecked={(e) =>
                                  onMonthlyFessChecked(e, 1, setFieldValue)
                                }
                              />
                              <span>Enable</span>
                            </div>
                            <div
                              className={`${!monthlyPlan && "cursor-disable"}`}
                            >
                              <CustomizedCheckbox
                                disabled={!monthlyPlan || !monthlyFees}
                                checked={!monthlyFees}
                                onChecked={(e) => {
                                  onMonthlyFessChecked(e, 0, setFieldValue);
                                }}
                              />
                              <span>Disable</span>
                            </div>
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          disabled={!monthlyFees || !monthlyPlan}
                          label="Setup Fees"
                          name={monthlyFees ? "setup_fees" : "sf"}
                          type={"number"}
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span>$</span>
                              </InputAdornment>
                            ),
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue("setup_fees", event.target.value);
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                      </Grid>
                    </>
                    <Grid xs={12} md={12} px={1} py={3}>
                      <Divider />
                    </Grid>
                    {/*  Yearly plan details */}
                    <>
                      <Grid xs={12} md={12} px={1} pb={2}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={{ xs: 1, sm: 2, md: 10 }}
                          pt={2}
                        >
                          <b className="">Yearly Plan</b>
                          <Stack
                            direction="row"
                            spacing={{ xs: 1, sm: 1, md: 2 }}
                            alignItems="center"
                          >
                            <div>
                              <CustomizedCheckbox
                                checked={yearlyPlan}
                                disabled={yearlyPlan}
                                onChecked={(e) =>
                                  onYearlyPlanChecked(e, 1, setFieldValue)
                                }
                              />
                              <span>Enable</span>
                            </div>
                            <div>
                              <CustomizedCheckbox
                                checked={!yearlyPlan}
                                disabled={!yearlyPlan}
                                onChecked={(e) =>
                                  onYearlyPlanChecked(e, 0, setFieldValue)
                                }
                              />
                              <span>Disable</span>
                            </div>
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="Setup amount"
                          disabled={!yearlyPlan}
                          name={yearlyPlan ? "yearly_plan_price" : "ypp"}
                          type={"number"}
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span>$</span>
                              </InputAdornment>
                            ),
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "yearly_plan_price",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="Yearly Renewal amount"
                          disabled={!yearlyPlan}
                          name={yearlyPlan ? "yearly_renewal_price" : "ypp"}
                          type={"number"}
                          max={3}
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span>$</span>
                              </InputAdornment>
                            ),
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "yearly_renewal_price",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          disabled={!yearlyPlan}
                          label="Free Trial"
                          name={yearlyPlan ? "yearly_free_trial" : "yft"}
                          type={"number"}
                          max={3}
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue(
                                "yearly_free_trial",
                                event.target.value
                              );
                            },
                            onWheel: (e) => {
                              e.target.blur();
                            },
                          }}
                        />
                        {!(
                          touched?.yearly_free_trial &&
                          Boolean(errors?.yearly_free_trial)
                        ) && (
                          <FormHelperText
                            sx={{
                              color: "#00000075 !important",
                              m: "0 !important",
                              maxWidth: "100% !important",
                              textAlign: "end",
                            }}
                          >
                            (In days)
                          </FormHelperText>
                        )}
                      </Grid>
                    </>
                    <Grid xs={12} md={12} px={1} py={3}>
                      <Divider />
                    </Grid>

                    <Grid xs={12} md={12} px={1} pb={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                        pt={2}
                      >
                        <b className="">Need to take recording fees?</b>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 2 }}
                          alignItems="center"
                        >
                          <div>
                            <CustomizedCheckbox
                              // disabled={!yearlyPlan || yearlyFees}
                              checked={recordingFees}
                              onChecked={(e) =>
                                onRecordingFeesChecked(e, 1, setFieldValue)
                              }
                            />
                            <span>Enable</span>
                          </div>
                          <div>
                            <CustomizedCheckbox
                              // disabled={!yearlyPlan || !yearlyFees}
                              checked={!recordingFees}
                              onChecked={(e) => {
                                onRecordingFeesChecked(e, 0, setFieldValue);
                              }}
                            />
                            <span>Disable</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid xs={12} md={6} px={1} pb={1}>
                      <CustomTextField
                        disabled={!recordingFees}
                        label="Recording Fees"
                        name={recordingFees ? "recording_fees" : "rf"}
                        type={"number"}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span>$</span>
                            </InputAdornment>
                          ),
                        }}
                        otherProps={{
                          onChange: (event) => {
                            setFieldValue("recording_fees", event.target.value);
                          },
                          onWheel: (e) => {
                            e.target.blur();
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid xs={12} md={12} px={1} pb={1}>
                  <div className="text-center createAcBtn mt-5">
                    <Button
                      type="submit"
                      className="next_button"
                      variant="contained"
                      onClick={(e) =>
                        handleSubmit(
                          e,
                          validateForm,
                          setFieldTouched,
                          values,
                        )
                      }
                    >
                      {isEdit ? `Update` : `  Create account`}
                    </Button>
                    {!monthlyPlan && !yearlyPlan && showLogoError && (
                      <FormHelperText
                        sx={{
                          color: "#d32f2f !important",
                          m: "0 !important",
                          fontSize: "14px !important",
                          fontWeight: 500,
                          width: "100%",
                          textAlign: "center",
                          maxWidth: "100% !important",
                          paddingTop: "10px",
                        }}
                      >
                        Please enable any of the Subscription plan
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {/* created provider alert */}
      {getObjLength(createOtherProviders) && openPopup ? (
        <AlertDialog
          type={createOtherProviders?.success ? "Success" : "Error"}
          openPopup={openPopup}
          onClose={true}
          mesage={
            createOtherProviders?.message || createOtherProviders?.data?.message
          }
          onCloseFun={closeAlertPopup}
        />
      ) : null}

      {/* edit provider alert */}
      {getObjLength(editedPersonalDetailsData) ? (
        <AlertDialog
          type={editedPersonalDetailsData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          mesage={
            editedPersonalDetailsData?.message ||
            editedPersonalDetailsData?.data?.message
          }
          onCloseFun={closeAlertPopup}
        />
      ) : null}
      <Loader open={otherProvidersLoader} />
    </Box>
  );
};

export default CreateProvidersForm;
