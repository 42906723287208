const setMeta = (title, description) => {
  // Helper function to set or create meta tags
  const updateMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (metaTag) {
      metaTag.setAttribute("content", content);
    } else {
      metaTag = document.createElement("meta");
      metaTag.name = name;
      metaTag.content = content;
      document.head.appendChild(metaTag);
    }
  };

  // Update title and description for all specified meta tags
  updateMetaTag("title", title);
  document.title = title;
  updateMetaTag("description", description);
  updateMetaTag("og:title", title);
  updateMetaTag("og:description", description);
  updateMetaTag("twitter:title", title);
  updateMetaTag("twitter:description", description);
};

export const setMetaDataForSEO = (pathname) => {
  switch (pathname) {
    case "/":
      // document.title = "Home Equity Protection | EquityProtect";
      setMeta(
        "Home Equity Protection | EquityProtect",
        "Protect your home equity with EquityProtect. Our solutions help secure your financial future by safeguarding your most valuable asset. Get expert guidance today."
      );
      break;
    case "/how-it-works":
      // document.title = "How Home Equity Protection Works | EquityProtect";
      setMeta(
        "How Home Equity Protection Works | EquityProtect",
        "Learn how EquityProtect secures your home equity with tailored protection plans. Understand the process and safeguard your financial future with trusted solutions."
      );
      break;
    case "/pricing":
      // document.title = "Home Equity Protection Pricing | EquityProtect";
      setMeta(
        "Home Equity Protection Pricing | EquityProtect",
        "Find competitive pricing for home equity protection plans at EquityProtect. Protect your home‚Äôs value with affordable solutions designed to secure your financial future."
      );
      break;
    case "/property-risk-management":
      // document.title = "Property Risk Management Services | EquityProtect";
      setMeta(
        "EquityProtect offers expert property risk management services to help you protect your home equity. Take control of your investments with our reliable risk management solutions."
      );
      break;
    case "/title-protection-real-estate-investors":
      // document.title = "Title Protection for Real Estate Investors | EquityProtect";
      setMeta(
        "Title Protection for Real Estate Investors | EquityProtect",
        "Real estate investors face increasing risks of title fraud. Guard your valuable equity with EquityProtect's patented system to protect your investments."
      );
      break;
    case "/title-protection-long-time-homeowners":
      // document.title = "Title Protection for Long-Time Homeowners | EquityProtect";
      setMeta(
        "Title Protection for Long-Time Homeowners | EquityProtect",
        "Home title fraud is on the rise, risking your equity. If you have significant equity in your home, safeguard it with EquityProtect's patented system."
      );
      break;
    case "/what-is-home-title-theft":
      // document.title = "What is Home Title Theft? What is It & Ways to Protect Yourself | EquityProtect";
      setMeta(
        "What is Home Title Theft? What is It & Ways to Protect Yourself | EquityProtect",
        "Title fraud is when thieves steal your home's title to borrow against or sell it. Safeguard your home with EquityProtect's patented authentication system."
      );
      break;

    case "/for-service-provider":
      // document.title = "Home Equity Service Providers | EquityProtect";
      setMeta(
        "Home Equity Service Providers | EquityProtect",
        "Partner with EquityProtect as a trusted service provider. Help clients safeguard their home equity with reliable protection plans tailored to meet individual needs."
      );
      break;
    default:
      // document.title = "EquityProtect";
      setMeta(
        "Home Lock - EquityProtect",
        "The First and Only True Home Title Lock - Prevents Home Stealing, Title Theft and Equity Protection"
      );
  }
};
