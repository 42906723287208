import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

import bro from "../../assets/SEO/bro.webp";
import city from "../../assets/SEO/City.webp";
import hacker from "../../assets/SEO/Hacker.webp";
import newHome from "../../assets/SEO/new-home.webp";
import CommonFirstSection from "./CommonFirstSection";
import CommonSecondSection from "./CommonSecondSection";
import CommonFourthSection from "./CommonFourthSection";
import multiHome from "../../assets/SEO/multi-home.webp";
import bro_hacker from "../../assets/SEO/bro_hacker.webp";
import home_theft from "../../assets/SEO/Home Theft.webp";
import ProtectionCTASection from "./ProtectionCTASection";
import realEstate from "../../assets/SEO/real-estate.webp";
import longtimeHome from "../../assets/SEO/longtime-home.webp";
import hacker_mobile from "../../assets/SEO/Hacker Mobile.webp";

import "./TitleProtectionForRealEstateInvestors.scss";

const WhatIsHomeTitleTheft = () => {
  return (
    <div className="title-protection-real-estate-investors">
      <CommonFirstSection
        topHeader={"What is home title theft"}
        topPara={`You're probably wondering, "What is home title theft?" In this fraud, a thief steals the title or deed to your home by placing a lien on it. They can foreclose on the house or sell their lien to another lender. This happens without your knowledge until someone begins the foreclosure process. You need a way to protect your title and ensure no one else can steal it. `}
        bgImg={hacker}
        bgImg_Mobile={hacker_mobile}
      />

      <CommonSecondSection
        image={city}
        title={`Can Someone Steal Your House Without You Knowing?`}
        content={[
          `The answer to your question, "Can someone steal your house without you knowing?" is yes. These bad actors steal your home by transferring the title to themselves. They will create paperwork that allows them to take ownership of the deed. Once they have ownership of the title, they are free to do whatever they like, from renting it to opening a line of credit against it. `,
        ]}
      />
      <CommonFourthSection
        reversColumn={true}
        image={bro_hacker}
        image_mobile={bro}
        title={"Signs of Home Title Theft"}
        content={[
          `You might believe that you own your home and have nothing to worry about, but there will be signs that title fraud has occurred. This includes: `,
        ]}
        listBelow={[
          `Changes in your mail. This can include a stoppage in the mail as well as bills for things you haven't bought or authorized.`,
          `You receive a letter about changes to your title or mortgage.`,
          `You receive a foreclosure notice. `,
          `Phone calls from creditors that you don't have an account with`,
        ]}
        lastContent={[
          "If something seems amiss, you need to follow up on it. ",
        ]}
      />
      <CommonSecondSection
        bgwhite={"bg-white"}
        image={home_theft}
        title={"How Common Is Home Title Theft?"}
        content={[
          `The home title theft of Graceland garnered attention for this growing threat. While the FBI places home title theft under a general heading with other homeowner issues, there is a rising problem with home title theft. It usually affects homeowners who don't live on the property, but thieves target properties with plenty of available equity. `,
        ]}
      />

      <section className="pt-5 home_title_theft">
        <Container fluid className="content___speacing ">
          <div className="header_speacing">
            <Typography className="header-text text-center">
              Who Can Be Affected by Home Title Theft?
            </Typography>
            <Typography className={"para-text text-center"}>
              You might be wondering, "Is title theft real?" It is real, and it
              can affect a variety of homeowners. There are many types of
              homeowners, but some are more attractive to would-be title thieves
              than others. They include:
            </Typography>
          </div>

          <Row className="mt-4 mb-2 text-start risk_points">
            <Col xs={12} sm={12} md={6} lg={6} className="content-spacing ">
              <img className="mb-3" src={longtimeHome} alt="Data Analysis" />
              <Typography className="htt_title">Longtime Homeowners</Typography>
              <Typography className="para-text">
                Longtime homeowners almost always have a lot of equity built
                into their homes. This additional equity can make your home
                attractive to someone who steals deeds. It offers them the
                chance to make more money with the same amount of work. 
              </Typography>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="content-spacing ">
              <img className="mb-3" src={multiHome} alt="Risk Indicators" />
              <Typography className="htt_title">Multi-Homeowners</Typography>
              <Typography className="para-text">
                When you own multiple homes, you have additional equity. You
                also might not pay as close attention to your title information
                as someone who only owns a single property. A thief might go
                after more than one of your properties using the same paperwork,
                saving them time while making more money. 
              </Typography>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="content-spacing">
              <img
                className="mb-3"
                src={realEstate}
                alt="Verification and Monitoring"
              />
              <Typography className="htt_title">
                Real Estate Investors
              </Typography>
              <Typography className="para-text">
                Real estate investors don't always live in the homes they own.
                This can make it easier for a thief to step in and change the
                title information. You might miss the signs that there's an
                issue with the title because you aren't living there. 
              </Typography>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="content-spacing">
              <img className="mb-3" src={newHome} alt="Risk Scoring" />
              <Typography className="htt_title">New Homeowners</Typography>
              <Typography className="para-text">
                While there might not be as much equity with a new homeowner,
                there is a lack of knowledge that can make their property an
                easy mark for title theft. New homeowners don't have the
                experience to recognize abnormal procedures, and they may fall
                for fraud more easily. 
              </Typography>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid className="fifth-section CTA-section">
        <ProtectionCTASection
          title_container2={
            "EquityProtect Partners With You to Stop Mortgage Fraud In Its Tracks"
          }
          content_containet2={
            <span>
              At{" "}
              <a href={"/"} target="_blank" rel="noreferrer">
                EquityProtect
              </a>
              , we know how common home title theft is, and we're ready to help.
              Our program blocks potential mortgage fraud by helping you place a
              lien on your property that future lien holders cannot supersede
              without your help. Contact us now to learn more. 
            </span>
          }
        />
      </Container>
    </div>
  );
};

export default WhatIsHomeTitleTheft;
