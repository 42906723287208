import { createSlice } from "@reduxjs/toolkit";

const intialPromocodesState = {
  promocodeData: {},
  promocodeDataCount: 0,
  addPromocodeDetails: {},
  deletedPromocodeDetails: {},
  promocodeListLoader: false,
  promocodeTypeList: {},
  promocodeTypeLoader: false,
};

const promocode = createSlice({
  name: "propertiesDetails",
  initialState: intialPromocodesState,
  reducers: {
    resetPromocodeState(state, action) {
      state.promocodeData = {};
      state.promocodeListLoader = false;
    },
    clearAddPromocodeDetailsData(state, action) {
      state.addPromocodeDetails = {};
      state.promocodeListLoader = false;
    },
    addPromocodeDetails(state, action) {
      state.promocodeListLoader = true;
    },
    deletePromocodeDetails(state, action) {
      state.promocodeListLoader = true;
    },
    setPromocodeDetails(state, action) {
      state.addPromocodeDetails = action.payload;
      state.promocodeListLoader = false;
    },
    setDeletedPromocodeDetails(state, action) {
      state.deletedPromocodeDetails = action.payload;
      state.promocodeListLoader = false;
    },
    fetchPromocodeListData(state, action) {
      state.promocodeListLoader = true;
    },
    setPromocodeListData(state, action) {
      state.promocodeData = action.payload;
      state.promocodeDataCount = action.payload.count;
      state.promocodeListLoader = false;
    },
    fetchPromocodeTypes(state, action) {
      state.promocodeTypeLoader = true;
    },
    setPromocodeTypes(state, action) {
      state.promocodeTypeList = action.payload;
      state.promocodeTypeLoader = true;
    },
    clearData(state, action) {
      console.log("Clear data");
      state.deletedPromocodeDetails = {};
      state.promocodeTypeLoader = false;
      state.promocodeListLoader = false;
    },
  },
});
export const promocodeAction = promocode.actions;
export default promocode.reducer;
