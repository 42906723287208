import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  Checkbox,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Zoom,
} from "@mui/material";

const TableHeaderForSorting = ({
  columns = [],
  handleSorting = () => {},
  directionFlow = "",
  selectedColumn = "",
  setSelectedColumn = () => {},
  numSelected,
  rowCount,
  onSelectAllClick = () => {},
  hideSelectAllCheckbox = false,
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell, key) =>
          headCell?.sorting ? (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? "center" : "left"}
              padding={"normal"}
              sortDirection={true}
              sx={{ minWidth: headCell?.minWidth }}
            >
              <TableSortLabel
                active={selectedColumn === key + 1 ? true : false}
                direction={
                  selectedColumn === key + 1
                    ? directionFlow
                      ? "desc"
                      : "asc"
                    : "desc"
                }
                onClick={() => {
                  setSelectedColumn(headCell?.key);
                  handleSorting(headCell.id);
                }}
                sx={{ color: "#0395FF !important" }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={headCell.isCenter ? "center" : "left"}
              padding={"normal"}
              sortDirection={false}
              sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
            >
              {headCell?.showInfoIcon ? (
                <>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <span>{headCell.label}</span>{" "}
                    <Tooltip
                      title={headCell?.tooltipText}
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                      sx={{ cursor: "pointer" }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </Stack>
                </>
              ) : (
                <>
                  {headCell?.isSelecetAll && hideSelectAllCheckbox && (
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  )}
                  {headCell.label}
                </>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderForSorting;
