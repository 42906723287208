import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Typography } from "@mui/material";

import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { OTPFieldStyle } from "../../user-signup/personalTokenScreen";
import { lennarSignUpAction } from "../../../redux/slices/lennar/signUp";
import {
  autoFocusOnField,
  censorEmail,
  getObjLength,
} from "../../helpers/utils";
import { resendOTPSeconds } from "../../helpers/constants";

const OTPVerificationScreen = ({ user_id, setScreen }) => {
  const dispatch = useDispatch();

  const [otp, SetOTP] = useState("");
  const [otpErr, SetOTPErr] = useState(undefined);
  const [otpSucess, SetOTPSucess] = useState("");
  const [emailScreen, SetEmailScreen] = useState(false);
  const [diableMobileBtn, setdiableMobileBtn] = useState(false);
  const [diableEmailBtn, setdiableEmailBtn] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [emailOTPErr, setEmailOTPErr] = useState(undefined);
  const [emailOTPSucess, setEmailOTPSucess] = useState("");
  const [emailSpamMsg, setEmailSpamMsg] = useState(
    " If you haven't received the email in your inbox, please check your email spam folder."
  );

  //initializes the timer states for hook
  const {
    timer,
    isTimerActive,
    startTimer,
    stopTimer,
    formatTimeForResendOTP,
  } = useTimer(120);

  const {
    lennarSignUpDetails,
    phoneVerificationDetails,
    emailVerificationDetails,
    resedPhoneOTPDetails,
    resedEmailOTPDetails,
    lennarLoader,
  } = useSelector((state) => state?.lennarSignUp);

  const userDataFromSession = localStorage.getItem("userSignupDetails")
    ? JSON.parse(localStorage.getItem("userSignupDetails"))
    : "";
  let phn_num = userDataFromSession?.phone
    ? userDataFromSession?.phone
    : lennarSignUpDetails?.data?.phone;
  let emailAdd = userDataFromSession?.email
    ? userDataFromSession?.email
    : lennarSignUpDetails?.data?.email;

  console.log(phn_num, "email substring==>", censorEmail(emailAdd));
  const phn_num1 = phn_num?.substring(phn_num?.length - 4);

  //   ---------- phone verification functions --------

  const handleChangePhoneOTP = (otp) => {
    SetOTPSucess("");
    if (otp.length === 6) {
      SetOTPErr("");
    }
    console.log("otp handled==>>", otp);
    SetOTP(otp);
  };

  const phoneOTPValidate = (event) => {
    event.preventDefault();
    let payload = {};
    SetOTPSucess("");
    // props.onBtnClick();
    if (otp.length === 6) {
      let inputParam = {
        user_id: user_id,
        verification_code: otp,
        step: 0,
        step_key: "VERIFY_PHONE_OTP",
      };
      dispatch(lennarSignUpAction.clearResendOTP());
      autoFocusOnField();

      payload = { url: "verify-otp/phone", data: inputParam };

      dispatch(lennarSignUpAction.phoneVerificationData(payload));
    } else {
      SetOTPErr("Please Enter Valid Token");
    }
  };

  const resendPhoneOTP = () => {
    let payload = {};
    let inputParam = {
      user_id: user_id,
    };

    payload = { url: "resend-otp/phone", data: inputParam };

    dispatch(lennarSignUpAction.resendPhoneOTP(payload));
    startTimer(resendOTPSeconds);
    SetOTP("");
    autoFocusOnField();
  };

  // --------- Email Verification functions --------------

  const handleChangeEmailOTP = (otp) => {
    setEmailOTPSucess("");
    if (otp.length === 6) {
      setEmailOTPErr("");
      setEmailSpamMsg("");
    }
    console.log("otp email handled==>>", otp);
    setEmailOTP(otp);
  };

  const emailOTPValidate = (event) => {
    event.preventDefault();
    let payload = {};
    setEmailOTPSucess("");
    if (emailOTP.length === 6) {
      setEmailSpamMsg("");
      autoFocusOnField();
      let inputParam = {
        user_id: user_id,
        verification_code: emailOTP,
        step: 1,
        step_key: "VERIFY_EMAIL_OTP",
      };
      dispatch(lennarSignUpAction.clearResendOTP());

      payload = { url: "verify-otp/email", data: inputParam };

      dispatch(lennarSignUpAction.emailVerificationData(payload));
      setOpenEmail(!openEmail);
    } else {
      setEmailOTPErr("Please Enter Valid Token");
    }
  };

  const resendEmailOTP = () => {
    let payload = {};
    setEmailSpamMsg("");
    let inputParam = {
      user_id: user_id,
    };
    payload = { url: "resend-otp/email", data: inputParam };

    dispatch(lennarSignUpAction.resendEmailOTP(payload));
    startTimer(resendOTPSeconds);
    setOpenEmail(!openEmail);
    setEmailOTP("");
    autoFocusOnField();
  };

  // set alert on the page relaod
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "Changes you made may not be saved. Are you sure you want to leave?";

      if (window.confirm(message)) {
        console.log("refreshed...1");
        // User clicked "OK", allow navigation
        return null;
      } else {
        // User clicked "Cancel", prevent navigation
        event.preventDefault();
        return (event.returnValue = message);
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component is unmounted
    return () => {
      console.log("refreshed stop...");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("phone_verified")) {
      SetEmailScreen(true);
      stopTimer();
    } else {
      SetEmailScreen(false);
    }
  }, []);

  useEffect(() => {
    if (getObjLength(phoneVerificationDetails)) {
      if (phoneVerificationDetails?.success) {
        SetEmailScreen(true);
        stopTimer();
      } else {
        SetEmailScreen(false);
        SetOTP("");
        SetOTPErr(phoneVerificationDetails?.data?.message);
      }
    }
  }, [phoneVerificationDetails]);

  useEffect(() => {
    if (getObjLength(emailVerificationDetails)) {
      if (emailVerificationDetails?.success) {
      } else {
        setEmailOTP("");
        setEmailOTPErr(emailVerificationDetails?.data?.message);
        stopTimer();
      }
    }
  }, [emailVerificationDetails]);

  useEffect(() => {
    if (getObjLength(resedPhoneOTPDetails)) {
      if (resedPhoneOTPDetails?.success) {
        SetOTPSucess(resedPhoneOTPDetails?.message);
        SetOTPErr();
      } else {
        SetOTPSucess();
        SetOTPErr(resedPhoneOTPDetails?.data?.message);
      }
    }
  }, [resedPhoneOTPDetails]);

  useEffect(() => {
    if (getObjLength(resedEmailOTPDetails)) {
      if (resedEmailOTPDetails?.success) {
        setEmailOTPSucess(
          resedEmailOTPDetails?.message +
            " If you haven't received the email in your inbox, please check your email spam folder."
        );
        setEmailOTPErr();
      } else {
        setEmailOTPSucess();
        setEmailOTPErr(resedEmailOTPDetails?.data?.message);
      }
    }
  }, [resedEmailOTPDetails]);

  console.log("resedPhoneOTPDetails==>", resedPhoneOTPDetails);
  console.log("resedEmailOTPDetails==>", resedEmailOTPDetails);

  return (
    <>
      <Box className={`accountBoxWrapper py-5`}>
        {!emailScreen ? (
          <form onSubmit={phoneOTPValidate}>
            <Typography align="center" variant="h3">
              Enter your personal token from Mobile number
            </Typography>
            <Typography align="center" variant="body1">
              Please enter the 6 digit security code we have sent to your mobile
              phone * *** *** {phn_num1 ? phn_num1 : "4321"}.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={otp}
                isInputNum
                onChange={handleChangePhoneOTP}
                numInputs={6}
                shouldAutoFocus
                disabled={diableMobileBtn}
                inputStyle={OTPFieldStyle}
              />
              {otpErr?.length > 0 && otpSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  {otpErr}
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {otpSucess}
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={phoneOTPValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableMobileBtn}
              >
                Verify mobile number
              </Button>
            </div>
            <div className="text-center tokenCodeText">
              {isTimerActive ? (
                <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
              ) : (
                <>
                  Did not receive the code?
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={resendPhoneOTP}
                  >
                    <Link className="text-blue text-decoration-none" to="">
                      Get a new one
                    </Link>
                  </Button>
                </>
              )}
            </div>
          </form>
        ) : (
          <form onSubmit={emailOTPValidate}>
            <Typography align="center" variant="h3">
              Enter your personal token from Email ID
            </Typography>
            <Typography align="center" variant="body1">
              Please enter the 6 digit security code we have sent to your Email
              ID &nbsp; {censorEmail(emailAdd)}.
            </Typography>
            <Box className="text-center otpBoxWrapper">
              <OtpInput
                className="otp mx-1"
                value={emailOTP}
                isInputNum
                onChange={handleChangeEmailOTP}
                numInputs={6}
                // shouldAutoFocus
                inputStyle={OTPFieldStyle}
                disabled={diableEmailBtn}
              />
              {emailOTPErr?.length > 0 && emailOTPSucess?.length === 0 ? (
                <Typography variant="caption" className="text-danger 1" p={2}>
                  <b>{emailOTPErr}</b>
                </Typography>
              ) : (
                <Typography variant="caption" className="text-success 3" p={2}>
                  {emailOTPSucess}
                </Typography>
              )}
              {emailSpamMsg?.length > 0 && (
                <Typography
                  variant="caption"
                  className="text-success 3 fw-500"
                  p={2}
                >
                  <b> {emailSpamMsg?.length > 0 && emailSpamMsg}</b>
                </Typography>
              )}
            </Box>
            <div className="text-center stepBtn mt-2">
              <Button
                type="submit"
                onClick={emailOTPValidate}
                className="next_button mb-3"
                variant="contained"
                disabled={diableEmailBtn}
              >
                Verify Email ID
              </Button>
            </div>
            <div className="text-center tokenCodeText">
              {isTimerActive ? (
                <b>Resend OTP in {formatTimeForResendOTP(timer)} seconds</b>
              ) : (
                <>
                  Did not receive the code?
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={resendEmailOTP}
                  >
                    <Link className="text-blue text-decoration-none" to="">
                      Get a new one
                    </Link>
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </Box>

      <Loader open={lennarLoader} />

      {getObjLength(emailVerificationDetails) &&
        emailVerificationDetails?.success && (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={false}
            mesage={
              "Your mobile phone number and email were successfully verified."
            }
            buttons={
              <div className="text-center stepBtn mt-4">
                <Button
                  onClick={() => {
                    dispatch(lennarSignUpAction.fetchResendEmailOTPData({}));
                    localStorage.setItem("currentStep", 1);
                    setScreen("add-property");
                    stopTimer();
                  }}
                  className="next_button "
                  variant="contained"
                >
                  Next Step
                </Button>
              </div>
            }
          />
        )}
    </>
  );
};

export default OTPVerificationScreen;
