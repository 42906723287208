import React from "react";
import { useState } from "react";

import {
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import AlertDialog from "./AlertDialog";
import StannupMailQR from "../../assets/dashboard/stannup_QR.png";
import StannupMailContent from "../../assets/dashboard/stannup_content.png";
import VerificationModal from "../../assets/dashboard/verification_modal.png";
import AddressVerificationScreen from "../../assets/dashboard/address_verification_list.png";

const steps = [
  {
    id: 1,
    content: (
      <>
        <Stack direction="column" className="w-100">
          <Typography className="mb-3">
            To verify the authenticity of the property in the property list,{" "}
            <b>
              you can scan the QR code and enter the provided token on the
              verification modal.
            </b>{" "}
            This will confirm that the property is genuine and legally
            registered, giving you peace of mind and ensuring a smooth
            transaction process.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <img
                src={StannupMailQR}
                className="stannup-mail-qr-img stannup-mail-img"
                width={320}
                alt="stannup mail img"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <img
                src={StannupMailContent}
                className="stannup-mail-content-img stannup-mail-img"
                width={500}
                alt="stannup mail img"
              />
            </Grid>
          </Grid>
        </Stack>
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        <Stack direction="column" className="w-100">
          <Typography className="mb-3">
            When you click on the Verify button,{" "}
            <b>
              a modal window will open where you will need to fill in a token
              sent to your mailing address.
            </b>{" "}
            Once you receive the postcard, open it and find the token. It should
            be a series of seven numbers that are unique to your verification
            request.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                src={AddressVerificationScreen}
                className="stannup-mail-qr-img stannup-mail-img"
                alt="address verification list img"
                width="100%"
                height="300px"
              />
            </Grid>
          </Grid>
        </Stack>
      </>
    ),
  },
  {
    id: 3,
    content: (
      <>
        <Stack direction="column" className="w-100">
          <Typography className="mb-3">
            Please, Look at the image below to find out how to enter the
            security code.
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              className="d-flex justify-content-center align-items-center"
            >
              <img
                src={VerificationModal}
                className="stannup-mail-qr-img3 stannup-mail-img"
                alt="address verification list img"
              />
            </Grid>
          </Grid>
        </Stack>
      </>
    ),
  },
];

const StannupInfoModal = ({
  openStanupPopup,
  setOpenStanupPopup = () => {},
  scrollToAddressVerification = () => {},
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showMobileStepper, setShowMobileStepper] = useState(0);

  // stepper to show how to verify address
  const stepper = () => {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h5" textAlign="left" mb={2}>
            <b>Property Verification Steps</b>
          </Typography>
          <Stepper activeStep={activeStep} nonLinear>
            {steps?.map((step) => (
              <Step key={step?.id}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box className="my-4" sx={{ textAlign: "left" }}>
            {steps[activeStep]?.content}
          </Box>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            padding={{ xs: 3 }}
            justifyContent="space-between"
          >
            {activeStep > 0 ? (
              <Button
                variant="contained"
                className="popup_Btn other_popup_btn mb-2"
                disabled={activeStep === 0}
                onClick={() => {
                  setActiveStep((prevStep) => prevStep - 1);
                }}
              >
                Back
              </Button>
            ) : (
              <p>&nbsp;</p>
            )}

            {activeStep + 1 < steps?.length && (
              <Button
                variant="contained"
                className="popup_Btn success_popup_btn mb-2"
                onClick={() => {
                  setActiveStep((prevStep) => prevStep + 1);
                }}
              >
                Next
              </Button>
            )}
            {activeStep + 1 === steps?.length && (
              <Button
                className="popup_Btn success_popup_btn mb-2"
                onClick={() => {
                  setOpenStanupPopup(false);
                  localStorage.removeItem("stanupPopupCount");
                  scrollToAddressVerification();
                }}
              >
                Go To Address Verification
              </Button>
            )}
          </Stack>
        </Box>
      </>
    );
  };
  return (
    <>
      {openStanupPopup && (
        <AlertDialog
          type={showMobileStepper ? "" : "Attention"}
          openPopup={openStanupPopup}
          onClose={activeStep + 1 === steps?.length}
          width={showMobileStepper ? "md" : "sm"}
          onCloseFun={setOpenStanupPopup}
          mesage={
            <>
              {showMobileStepper ? (
                stepper()
              ) : (
                <p className="fw-400 mt-3">
                  Your account has some properties which need to be verified by
                  you. We’ve sent a postcard to your mailing address after
                  successful creation of your account. Once you receive the
                  postcard please go to the <b>Address verification</b> section
                  and enter the code found on the postcard to verify the
                  respective property address.
                </p>
              )}
            </>
          }
          buttons={
            !showMobileStepper && (
              <Button
                className="popup_Btn success_popup_btn"
                onClick={() => {
                  setShowMobileStepper(true);
                }}
              >
                Ok, All Good
              </Button>
            )
          }
          hideButtondiv={!showMobileStepper}
        />
      )}
    </>
  );
};

export default StannupInfoModal;
