import React, { useEffect, useState } from "react";

import DateRangePicker from "react-bootstrap-daterangepicker";

import CloseIcon from "@mui/icons-material/Close";
import {
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import { getDateInMMDDYYYYFormat, getObjLength } from "../helpers/utils";

const CustomDateRangePicker = ({
  rangeValue,
  onChange,
  onClear,
  className,
  sx,
  label,
  type,
  name,
  touched,
  disabled = false,
  errors,
  settings,
}) => {
  const [initialSettings, setInitialSettings] = useState({
    autoUpdateInput: false,
    autoApply: false,
    showDropdowns: true,
    ...settings,
  });

  useEffect(() => {
    setInitialSettings((prevState) => ({
      ...prevState,
      startDate: rangeValue?.startDate
        ? getDateInMMDDYYYYFormat(new Date(rangeValue?.startDate), "/")
        : getDateInMMDDYYYYFormat(new Date()),
      endDate: rangeValue?.endDate
        ? getDateInMMDDYYYYFormat(new Date(rangeValue?.endDate), "/")
        : getDateInMMDDYYYYFormat(new Date()),
    }));
  }, [rangeValue]);

  return (
    <div className={disabled ? 'date-picker-disabled' : ''}>
      <DateRangePicker
        initialSettings={{ ...initialSettings }}
        onApply={(_, range) => {
          if (range?.singleDatePicker) {
            onChange(range?.startDate);
          } else {
            onChange({ startDate: range?.startDate, endDate: range?.endDate });
          }
        }}
      >
        <div>
          <InputLabel>{label ? label : "Select Dates"}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label={label ? label : "select Dates"}
            className={`${className ? className : ""} w-100`}
            sx={{ paddingRight: "35px", ...sx }}
            disabled={disabled}
            value={
              settings?.singleDatePicker && rangeValue
                ? getDateInMMDDYYYYFormat(new Date(rangeValue), "/")
                : getObjLength(rangeValue)
                ? `${getDateInMMDDYYYYFormat(
                    new Date(rangeValue?.startDate),
                    "/"
                  )} to ${getDateInMMDDYYYYFormat(
                    new Date(rangeValue?.endDate),
                    "/"
                  )}`
                : ""
            }
            inputProps={{
              autoComplete: "off",
            }}
            type={type ? type : ""}
            name={name ? name : ""}
            helperText={touched?.[name] && errors?.[name]}
            error={touched?.[name] && Boolean(errors?.[name])}
          />
          {touched?.[name] && Boolean(errors?.[name]) && (
            <FormHelperText sx={{ color: "#d32f2f" }}>
              {touched?.[name] && errors?.[name]}
            </FormHelperText>
          )}
        </div>
      </DateRangePicker>
      <IconButton
        aria-label="toggle password visibility"
        onClick={(e) => {
          if (!disabled) {
            onClear();
          }
          e.preventDefault();
          e.stopPropagation();
        }}
        sx={{ position: "absolute", top: "6px", right: 0 }}
      >
        <CloseIcon sx={{ color: "#9CB8A6" }} />
      </IconButton>
    </div>
  );
};

export default CustomDateRangePicker;
