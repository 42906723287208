import React from "react";

const FourIconSVG = () => {
  return (
    <>
      <svg
        width="37"
        height="49"
        viewBox="0 0 37 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="1"
          d="M25.23 49V38.78H0.94V35.07L23.2 0.139999H29.22V35.28H36.78V38.78H29.22V49H25.23ZM4.86 35.28H25.23V3.71H25.02L4.86 35.28Z"
          fill="#02a8e2"
        />
      </svg>
    </>
  );
};

export default FourIconSVG;
