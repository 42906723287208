import React, { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Popover, Typography } from "@mui/material";
import { Button } from "react-bootstrap";

const PopoverTexts = ({ id, open, content, anchorEl, setAnchorEl }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      // style={{ height: "400px" }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <div className="popover_modal">
        <Button
          onClick={handleClose}
          className="modal-close-btn"
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            border: 0,
            background: "transparent",
          }}
        >
          <CloseIcon sx={{ color: "#9CB8A6" }} />
        </Button>

        {/* <Typography
          className="county_captions text-start"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        /> */}
        <Typography sx={{whiteSpace:"pre-wrap", textAlign:"start"}}>
          {content}
        </Typography>
      </div>
    </Popover>
  );
};

export default PopoverTexts;
