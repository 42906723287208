import { createSlice } from "@reduxjs/toolkit";

import { ROLES } from "../../../components/helpers/constants";
import {
  encryptPayload,
  reactPixelEvent,
} from "../../../components/helpers/utils";

const intialUserSignInState = {
  userSignInDetails: {},
  forgotPasswordDetails: {},
  otpVerifyDetails: {},
  resetPasswordInfo: {},
  resetPasswordLoader: false,
  resetPasswordDetails: {},
  emailForResetPass: {},
  userCredentials: {},
  sendOTPResponse: {},
  optVerification: {},
  resendOTP: {},
  verifyFace: {},
  canUserLogin: false,
  selectedOption: "",
  ssoDetails: {},
  sso_Loader: false,
};

const signInUser = createSlice({
  name: "userSignInDetails",
  initialState: intialUserSignInState,
  reducers: {
    userSignInData(state, action) {
      console.log("login data==>>", action.payload);
      state.userCredentials = action.payload?.data;
    },
    setUserCredentials(state, action) {
      console.log("login data==>>", action.payload);
      state.userCredentials = action.payload;
    },
    fetchUserData(state, action) {
      console.log("In state slice...", action.payload);
      state.userSignInDetails = action.payload;
      if (action.payload?.success) {
        if (action.payload.data?.role?.includes(ROLES.customer)) {
          reactPixelEvent("SignIn", { email: state.userCredentials?.email });
        }
      }
      if (
        action.payload.data?.role?.includes(ROLES.admin) ||
        action.payload.data?.role?.includes(ROLES.subAdmin) ||
        action.payload.data?.role?.includes(ROLES.provider) ||
        action.payload.data?.role?.includes(ROLES.countyRecorder)
      ) {
        localStorage.setItem("authToken", action.payload.data?.AccessToken);
        localStorage.setItem("authorised", action.payload.success);
      }
      if (action.payload.data?.role === "Professional") {
        localStorage.setItem("authToken", action.payload.data?.AccessToken);
        localStorage.setItem("authorised", action.payload.success);
        // set count for failed payment in professinoal login
        localStorage.setItem("paymentPopup", 0);
        // set count for stannup info modal
        localStorage.setItem("stanupPopupCount", 1);
        document.cookie =
          "professioanlCookie =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      if (action.payload?.data?.role) {
        localStorage.setItem(
          "roles",
          encryptPayload(action.payload?.data?.role)
        );
      } else {
        localStorage.setItem(
          "roles",
          encryptPayload(action?.payload?.data?.user?.user_type?.[0])
        );
      }
      // localStorage.setItem("authToken", action.payload.data?.AccessToken);
    },
    forgotPassword(state, action) {
      console.log("forgotPass data==>>", action.payload);
      state.emailForResetPass = action.payload?.data;
      state.selectedOption = action.payload?.selectedOption;
    },
    forgotPasswordData(state, action) {
      console.log("In ForgotPass state slice...", action.payload);
      state.forgotPasswordDetails = action.payload;
      if (Object.keys(state.resetPasswordDetails).length > 0) {
        state.resetPasswordDetails = {};
      }
    },
    otpVerifyData(state, action) {
      console.log("In otpVerifyDetails state slice...", action.payload);
      state.otpVerifyDetails = action.payload;
    },
    resetPassword(state, action) {
      console.log("Reset password data==>>", action.payload);
      state.resetPasswordInfo = action.payload?.data;
      state.resetPasswordLoader = true;
      state.forgotPasswordDetails.message = "";
    },
    resetPasswordData(state, action) {
      console.log("In Reset Password state slice...", action.payload);
      if (action.payload?.success) {
        reactPixelEvent("Reset Password", {
          email: state.resetPasswordInfo?.email,
        });
      }
      state.resetPasswordDetails = action.payload;
      state.resetPasswordLoader = false;
    },
    clearResetPasswordData(state, action) {
      console.log("In Clear Reset Password state slice...");
      state.resetPasswordDetails = {};
      state.forgotPasswordDetails = {};
    },
    sendOTPData(state, action) {
      console.log("send OTP data==>>", action.payload);
      state.optVerification = {};
    },
    sendOTPResponse(state, action) {
      console.log("send OTP responese ==>>", action.payload);
      state.sendOTPResponse = action.payload;
    },
    userVerifyOTP(state, action) {
      console.log("send OTP data==>>", action.payload);
      state.optVerification = {};
    },
    verifyOTPResponse(state, action) {
      console.log("send OTP responese ==>>", action.payload);
      state.optVerification = action.payload;
      localStorage.setItem("authToken", action.payload.data?.AccessToken);
      localStorage.setItem("authorised", action.payload.success);
      localStorage.setItem("roles", encryptPayload(action.payload?.data?.role));
    },
    resendOTP(state, action) {
      console.log("send OTP data==>>", action.payload);
      // state.optVerification={};
    },
    resendResponse(state, action) {
      console.log("send OTP responese ==>>", action.payload);
      state.resendOTP = action.payload;
      state.optVerification = {};
    },
    successLogout(state, action) {
      state.userSignInDetails = {};
      state.forgotPasswordDetails = {};
      state.otpVerifyDetails = {};
      state.resetPasswordDetails = {};
      state.emailForResetPass = {};
      state.userCredentials = {};
      state.sendOTPResponse = {};
      state.optVerification = {};
      state.resendOTP = {};
    },
    getVerifyFace(state, action) {
      console.log("Verify-face==>>", action.payload);
    },
    fetchVerifyFace(state, action) {
      console.log("fetch verify Face slice..", action.payload);
      state.verifyFace = action.payload;
      localStorage.setItem("authToken", action.payload.data?.AccessToken);
      localStorage.setItem("authorised", action.payload.success);
      localStorage.setItem("roles", encryptPayload(action.payload?.data?.role));
    },
    clearStoreData(state, action) {
      state.userSignInDetails = {};
      state.forgotPasswordDetails = {};
      state.otpVerifyDetails = {};
      state.resetPasswordDetails = {};
      state.emailForResetPass = {};
      state.userCredentials = {};
      state.sendOTPResponse = {};
      state.optVerification = {};
      state.resendOTP = {};
      state.verifyFace = {};
      // localStorage.setItem("authToken", null);
    },
    getSSOData(state, action) {
      state.sso_Loader = true;
    },
    fetchSSODetails(state, action) {
      console.log("ssoDetails==>> slice", action.payload);
      state.sso_Loader = false;
      state.ssoDetails = action?.payload;
      if (action?.payload?.success) {
        localStorage.setItem("preference", true);
        localStorage.setItem("authToken", action.payload.data?.AccessToken);
        localStorage.setItem("authorised", action.payload.success);
        localStorage.setItem("roles", encryptPayload(ROLES.customer));
        localStorage.setItem("paymentPopup", 0);
        localStorage.setItem("stanupPopupCount", 1);
      }
    },
  },
});
export const userSigInAction = signInUser.actions;
export default signInUser.reducer;
