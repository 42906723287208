import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import logo from "../../assets/logo/equity.WebP";
import phoneTalk from "../../assets/phone_in_talk.svg";
import { Link, NavLink } from "react-router-dom";
import CallUsNow from "../common/CallUsNow";
import userAccount from "../../assets/account_circle.svg";
import { DEFAULT_URL, ROLES } from "../helpers/constants";
import { decryptPayload } from "../helpers/utils";

const TryDomainNavBar = () => {
  // handle My Account Button
  const handleMyAccountBtn = () => {
    let user = localStorage.getItem("authorised");
    let userRole = decryptPayload(localStorage.getItem("roles"));
    if (
      user === "true" &&
      (userRole?.includes(ROLES.admin) || userRole?.includes(ROLES.subAdmin))
    ) {
      window.location.href = `${DEFAULT_URL}admin-profile`;
    } else if (
      user === "true" &&
      (userRole?.includes(ROLES.customer) ||
        userRole?.includes(ROLES.secondaryUser))
    ) {
      window.location.href = `${DEFAULT_URL}user-profile`;
    } else if (user === "true" && userRole?.includes(ROLES.serviceProvider)) {
      window.location.href = `${DEFAULT_URL}servicer-profile`;
    } else {
      window.location.href = `${DEFAULT_URL}login`;
    }
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.getElementById("TryHeaderNavbar")?.classList) {
        if (
          (window.location.href.includes("privacy") ||
            window.location.href.includes("terms")) > -1
        ) {
          document.getElementById("TryHeaderNavbar")?.classList.add("sticky");
        } else {
          if (window.scrollY > 10) {
            document.getElementById("TryHeaderNavbar")?.classList.add("sticky");
          } else {
            document
              .getElementById("TryHeaderNavbar")
              ?.classList.remove("sticky");
          }
        }
      }
    });
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg="transparent"
        variant="dark"
        className="py-4 py-md-4 try-page-navbar"
        id="TryHeaderNavbar"
      >
        <Container fluid className="mx-150">
          <Navbar.Toggle
            className="mobile-menu"
            aria-controls="responsive-navbar-nav"
          ></Navbar.Toggle>
          <Link to="/" className="publicNav_logo">
            <Navbar.Brand className="p-0 ">
              <img src={logo} alt="logo" />
            </Navbar.Brand>
          </Link>
          <Nav className="MobAction_btn ms-auto d-flex flex-row d-lg-none">
            <CallUsNow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              renderText={
                <Button
                  className="footer_btn mx-1 phone_btn"
                  style={{ background: "#02a8e2" }}
                  variant="link"
                >
                  <img src={phoneTalk} alt="Phone Talk" />
                </Button>
              }
            />

            <Button
              className="footer_btn footer_green mx-1 my_account_btn"
              onClick={() => {
                window.location.href = `${DEFAULT_URL}login`;
              }}
              variant="link"
            >
              <img
                src={userAccount}
                alt="User Account"
                width="24px"
                height="24px"
              />
            </Button>
          </Nav>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navitems navbar-center justify-content-center">
              <NavLink
                className={() =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 active-on-hover`
                }
                to="#"
                onClick={() => {
                  window.location.href = `${DEFAULT_URL}`;
                }}
              >
                Home
              </NavLink>
              <NavLink
                className={() =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 active-on-hover`
                }
                to="#"
                onClick={() => {
                  window.location.href = `${DEFAULT_URL}how-it-works`;
                }}
              >
                How it Works
              </NavLink>
              <NavLink
                className={() =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 active-on-hover`
                }
                to="#"
                onClick={() => {
                  window.location.href = `${DEFAULT_URL}pricing`;
                }}
              >
                Pricing
              </NavLink>
              <NavLink
                className={() =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 active-on-hover`
                }
                to="#"
                onClick={() => {
                  window.location.href = `${DEFAULT_URL}for-service-provider`;
                }}
              >
                For Service Providers
              </NavLink>
              <NavLink
                className={() =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 active-on-hover d-block d-lg-none`
                }
                to="#"
                onClick={handleMyAccountBtn}
              >
                Login
              </NavLink>
            </Nav>
            <Nav className="action_btn mt-3 mt-md-0 d-none d-lg-block">
              <CallUsNow
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                tryPage={true}
              />
              <Button
                className="footer_btn footer_green my-2 my-lg-0 mx-0 mx-lg-1"
                variant="link"
                onClick={handleMyAccountBtn}
              >
                My Account
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default TryDomainNavBar;
